import * as layout from '../../layout'
import { ComponentBranding, ComponentBrandingBase } from '../components'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'

export default class WebTabBarBranding extends ComponentBrandingBase<WebTabBarSpec> {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: WebTabBarBranding | null,
  ) {
    super(delegate,parent, {
      background:      'alt',
      border:          'none',
      shape:           {rounded: layout.radius.m},
      depth:           0,
      iconColor:       'fg-dark-normal',
      activeIconColor: 'primary',
    })
  }

  public readonly previewSize = {width: 320, height: 56}

  public badge = new ComponentBranding(this.delegate, this.delegate.rootGuide.badge, {
    previewSize: {width: 24, height: 24},
  })

}

export interface WebTabBarSpec extends BrandedComponentSpec {
  iconColor:       string
  activeIconColor: string
}