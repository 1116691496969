import React from 'react'
import {
  createSortableContainer,
  SortableContainerChildProps,
  SortableItem,
} from 'react-dnd-sortable'
import { isFunction } from 'lodash'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import Scroller from '~/ui/components/scroller/Scroller'
import { useScrollIntoView } from '~/ui/hooks'
import { animation, layout } from '~/ui/styling'
import { isReactComponent } from '~/ui/util'
import { isListSection, ListProps, ListSection, ShowPlaceholderOption } from './types'

const SortableContainer = createSortableContainer<VBoxProps, any>(VBox)

const _List = <T extends {}>(props: ListProps<T>) => {

  const {
    data,
    renderItem,
    sectioned,

    HeaderComponent,
    FooterComponent,
    EmptyComponent,
    SeparatorComponent,

    renderSectionHeader,
    renderSectionFooter,

    scrollable,
    pageSize,
    onEndReached: props_onEndReached,
    scrollerProps,
    scrollManager,

    sortable,

    renderPlaceholder,
    showPlaceholder = 'always',

    contentPadding,
    itemGap,
    itemAlign,

    selectedKeyPath,

    flex = true,
  } = props

  const isSectioned = sectioned ?? (data.length > 0 && isListSection(data[0]))
  const isEmpty     = data.length === 0

  const sections    = isSectioned ? (data as ListSection<T>[]) : []

  const flatData = React.useMemo(
    () => isSectioned ? [] : (data as T[]),
    [data, isSectioned],
  )

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const visibleFlatData = React.useMemo(() => {
    if (pageSize == null) {
      return flatData
    } else {
      return flatData.slice(0, (currentPage + 1) * pageSize)
    }
  }, [currentPage, flatData, pageSize])

  const onEndReached = React.useMemo(() => {
    if (pageSize == null) {
      return props_onEndReached
    } else if (flatData.length > visibleFlatData.length) {
      return () => {
        setCurrentPage(currentPage + 1)
        props_onEndReached?.()
      }
    }
  }, [currentPage, flatData.length, pageSize, props_onEndReached, visibleFlatData.length])

  const props_onSortEnter = sortable?.onSortEnter
  const props_onSortLeave = sortable?.onSortLeave

  const scrollIntoView = useScrollIntoView({
    time: animation.durations.short,
    align: {
      top:       0.3,
      topOffset: layout.padding.inline.m,
    },
  })

  //------
  // Rendering

  function render() {
    if (scrollable) {
      return renderScroller()
    } else {
      return renderNonScrollable()
    }
  }

  function renderNonScrollable() {
    return (
      <VBox flex={flex} classNames={[props.classNames, props.contentClassNames]} padding={contentPadding}>
        {renderComponentOrElement(HeaderComponent)}
        {renderBody()}
        {renderComponentOrElement(FooterComponent)}
      </VBox>
    )
  }

  function renderScroller() {
    const classNames = [props.classNames, props.scrollerProps?.classNames]
    return (
      <Scroller
        {...scrollerProps}
        onEndReached={onEndReached}
        scrollManager={scrollManager}
        classNames={classNames}
        contentClassNames={props.contentClassNames}
        contentPadding={contentPadding}
        flex='both'
      >
        {renderComponentOrElement(HeaderComponent)}
        {renderBody()}
        {renderComponentOrElement(FooterComponent)}
      </Scroller>
    )
  }

  function renderBody() {
    if (props.sortable) {
      return renderSortableList()
    } else {
      return renderList()
    }
  }

  //------
  // List

  function renderList() {
    return (
      <VBox flex='both'>
        {isEmpty ? (
          renderComponentOrElement(EmptyComponent)
        ) : (
          renderSectionsOrRows()
        )}
      </VBox>
    )
  }

  function renderSectionsOrRows() {
    if (!isSectioned) {
      return (
        <VBox gap={itemGap} align={itemAlign}>
          {renderRows(visibleFlatData)}
        </VBox>
      )
    } else {
      return sections.map((section, index) => (
        <VBox key={section.name}>
          {renderSectionHeader?.(section, index)}
          <VBox gap={itemGap} align={itemAlign}>
            {renderRows(section.items)}
          </VBox>
          {renderSectionFooter?.(section, index)}
        </VBox>
      ))
    }
  }

  //------
  // Sortable list

  const [sortPlaceholderShown, setSortPlaceholderShown] = React.useState<boolean>()

  const onSortEnter = React.useCallback((item: SortableItem<any, T>) => {
    setSortPlaceholderShown(true)
    props_onSortEnter?.(item)
  }, [props_onSortEnter])

  const onSortLeave = React.useCallback((item: SortableItem<any, T>) => {
    setSortPlaceholderShown(false)
    props_onSortLeave?.(item)
  }, [props_onSortLeave])

  function renderSortableList() {
    if (sortable == null) { return null }
    if (isSectioned) {
      throw new Error("Cannot use sortable on list sections")
    }

    const rows  = renderRows(flatData)
    const empty = rows.length === 0 && !sortPlaceholderShown

    return (
      <SortableContainer
        gap={itemGap}
        align={empty ? 'stretch' : itemAlign}
        flex='both'
        onSortEnter={onSortEnter}
        onSortLeave={onSortLeave}
        {...sortable}
      >
        {props => renderSortableContent(rows, props)}
      </SortableContainer>
    )
  }

  function renderSortableContent(rows: React.ReactChild[], sortableProps: SortableContainerChildProps<T>) {
    const {hoverIndex, isOver, item, connectPlaceholder} = sortableProps
    const placeholderIndex = calculatePlaceholderIndex(item, hoverIndex)

    const copy = [...rows]

    if (item != null && placeholderIndex != null) {
      copy.splice(placeholderIndex, 0, (
        <React.Fragment key={Constants.placeholderKey}>
          {renderPlaceholder?.(connectPlaceholder, item, isOver)}
        </React.Fragment>
      ))
    }

    if (copy.length === 0) {
      return renderComponentOrElement(EmptyComponent)
    }

    return copy
  }

  const showPlaceholderForItem = React.useCallback((item: SortableItem<any, T>): ShowPlaceholderOption => {
    if (isFunction(showPlaceholder)) {
      return showPlaceholder(item)
    } else {
      return showPlaceholder
    }
  }, [showPlaceholder])

  const calculatePlaceholderIndex = React.useCallback((item: SortableItem<any, T> | null, dropIndex: number | null) => {
    if (item == null) { return null }

    // If the item comes from this list, and it's the only one, always show the placeholder, even if it's set to 'hover'.
    const isSameList = item.sourceList === sortable?.listID
    if (isSameList && data.length === 1) { return 0 }

    if (dropIndex == null) {
      return showPlaceholderForItem(item) === 'always'
        ? data.length
        : null
    }

    const isBefore = isSameList && item.index <= dropIndex
    return isBefore ? dropIndex + 1 : dropIndex
  }, [data.length, showPlaceholderForItem, sortable?.listID])

  //------
  // Data

  function renderRows(data: T[]) {
    return data.map((item, index) => {
      const key      = props.keyExtractor?.(item, index) ?? index
      const selected = key === selectedKeyPath?.[0]

      return (
        <React.Fragment key={key}>
          {index > 0 && renderComponentOrElement(SeparatorComponent)}
          <VBox ref={selected ? scrollIntoView : undefined}>
            {renderItem(item, index, selected)}
          </VBox>
        </React.Fragment>
      )
    })
  }

  function renderComponentOrElement(Component: React.ComponentType<{}> | React.ReactNode) {
    if (isReactComponent(Component)) {
      return <Component/>
    } else {
      return Component
    }
  }

  return render()

}

const List = memo('List', _List) as typeof _List
export default List

const Constants = {
  placeholderKey: '$$list.placeholder',
}