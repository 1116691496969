import manager from './manager'

let closeHooksCreated: boolean = false

export function ensureCloseHooks() {
  if (closeHooksCreated) { return }

  document.addEventListener('keydown', onKeyDown)
  document.addEventListener('mousedown', onMouseDown)
  document.addEventListener('touchstart', onMouseDown)
  closeHooksCreated = true
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key === 'Escape' && manager.topMostPortal?.closeOnEscape) {
    manager.topMostPortal.close()
  }
}

function onMouseDown(event: Event) {
  if (manager.topMostPortal == null) { return }

  if (manager.topMostPortal.shouldCloseOnClick(event)) {
    manager.topMostPortal.close()
  }
}