import { action, when } from 'mobx'
import { Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryTopic } from '~/models'
import bewizrStore from '../bewizrStore'
import BeWizrLibraryCategoriesEndpoint from './BeWizrLibraryCategoriesEndpoint'

export default class BeWizrLibraryTopicDocument extends Document<BeWizrLibraryTopic> {

  public get slug() {
    return this.id
  }

  public categoriesEndpoint: BeWizrLibraryCategoriesEndpoint | null = null

  @action
  protected async performFetch() {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('library_topic/', {
      params: {
        q: this.slug,
      },
    })
    if (response.status !== 200) { return response }

    const raw = response.data.items.find((it: any) => it.slug === this.slug)
    if (raw == null) { return null }

    const topic = BeWizrLibraryTopic.deserialize(raw)

    this.categoriesEndpoint = new BeWizrLibraryCategoriesEndpoint(bewizrStore.categories, {
      topic: topic.slug,
    })

    return {
      data: topic,
      meta: {},
    }
  }

}
