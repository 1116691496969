import { clamp, isFunction } from 'lodash'
import ScrollManager from './ScrollManager'
import {
  ScrollAnimationOptions,
  ScrollDuration,
  ScrollEasingFunction,
  ScrollEndCallback,
  ScrollPosition,
  ScrollStepCallback,
} from './types'

const DEFAULTS: Required<ScrollAnimationOptions> = {
  duration:   distance => Math.min(600, Math.sqrt(distance) * 10),
  easing:     easeInOutQuad,
  onStep:     null,
  onEnd: null,
}

const FRAME = 16
const requestAnimationFrame = tryGetWindow(['requestAnimationFrame', 'webkitRequestAnimationFrame'], ((fn: () => any) => window.setTimeout(fn, FRAME)))
const cancelAnimationFrame  = tryGetWindow(['cancelAnimationFrame', 'webkitCancelAnimationFrame'], ((handle: number) => window.clearTimeout(handle)))

function tryGetWindow<T>(names: string[], defaultValue: T): T {
  if (typeof window === 'undefined') { return defaultValue }

  for (const name of names) {
    if (name in window) {
      return (window as any)[name]
    }
  }

  return defaultValue
}

export default class ScrollAnimation {

  constructor(
    public readonly manager: ScrollManager,
    options: Partial<ScrollPosition> & ScrollAnimationOptions = {},
  ) {
    this.destPosition = this.clampPosition({
      left: options.left,
      top:  options.top,
    })

    const {duration, easing, onStep, onEnd} = {...DEFAULTS, ...options}
    this.duration   = duration
    this.easing     = easing
    this.onStep     = onStep
    this.onEnd      = onEnd
  }

  public readonly destPosition: Partial<ScrollPosition>

  public readonly duration: ScrollDuration
  public readonly easing:   ScrollEasingFunction

  private readonly onStep:     ScrollStepCallback | null
  private readonly onEnd: ScrollEndCallback | null

  private clampPosition(position: Partial<ScrollPosition>): Partial<ScrollPosition> {
    return {
      left: position.left == null ? undefined : clamp(position.left, 0, this.manager.maxScrollPosition.left),
      top: position.top == null ? undefined : clamp(position.top, 0, this.manager.maxScrollPosition.top),
    }
  }

  //------
  // Interface

  public start() {
    const {scrollingElements} = this.manager

    const distanceX = this.destPosition.left == null ? 0 : Math.abs(this.destPosition.left - scrollingElements[0].scrollLeft)
    const distanceY = this.destPosition.top == null ? 0 : Math.abs(this.destPosition.top - scrollingElements[0].scrollTop)
    const distance = distanceX === 0 ? distanceY : distanceY === 0 ? distanceX : Math.sqrt(distanceY ** 2 + distanceX ** 2)

    this.tx = isFunction(this.duration) ? this.duration(distance) : this.duration

    this.p0 = {
      left: scrollingElements[0].scrollLeft,
      top:  scrollingElements[0].scrollTop,
    }
    this.t0 = new Date().getTime()
    this.raf = requestAnimationFrame(this.onFrame)
  }

  public stop() {
    if (this.raf != null) {
      cancelAnimationFrame(this.raf)
      this.raf = null

      if (this.onEnd) {
        this.onEnd(false)
      }
    }
  }

  public get isRunning() {
    return this.raf != null
  }

  //------
  // Animation

  private p0: ScrollPosition = {left: 0, top: 0}

  private t0: number = new Date().getTime()
  private tx: number = 0
  private raf: number | null = null

  private onFrame = () => {
    const {scrollingElements} = this.manager

    this.raf = null

    const t = (new Date().getTime() - this.t0) / this.tx
    if (t >= 1) {
      if (this.destPosition.left != null) {
        scrollingElements.forEach(el =>  { el.scrollLeft = this.destPosition.left! })
      }
      if (this.destPosition.top != null) {
        scrollingElements.forEach(el =>  { el.scrollLeft = this.destPosition.top! })
      }
      if (this.onEnd) { this.onEnd(true) }
    } else {
      const f = this.easing(t)
      const position: Partial<ScrollPosition> = {}
      if (this.destPosition.left != null) {
        position.left = this.destPosition.left * f + this.p0.left * (1 - f)
        scrollingElements.forEach(el =>  { el.scrollLeft = position.left! })
      }
      if (this.destPosition.top != null) {
        position.top = this.destPosition.top * f + this.p0.top * (1 - f)
        scrollingElements.forEach(el =>  { el.scrollTop = position.top! })
      }

      if (this.onStep) { this.onStep(position) }
      this.raf = requestAnimationFrame(this.onFrame)
    }
  }

}

function easeInOutQuad(x: number) {
  x *= 2

  if (x < 1) {
    return 0.5 * x * x
  } else {
    return - 0.5 * (--x * (x - 2) - 1)
  }
}