import React from 'react'
import { forwardRef } from '~/ui/component'
import TextField, { Props as TextFieldProps } from './TextField'

export interface Props extends TextFieldProps {}

const MarkdownField = forwardRef('MarkdownField', (props: Props, ref: React.Ref<HTMLTextAreaElement>) => {

  //------
  // Rendering

  function render() {
    return(
      <TextField
        type='textarea'
        multiline
        mono
        resize='none'
        ref={ref}
        {...props}
      />
    )
  }

  return render()

})

export default MarkdownField