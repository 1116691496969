import React from 'react'
import useSortableDrag from './useSortableDrag'

export interface Options {
  axis?:     'vertical' | 'horizontal' | 'both'
  itemType?: string
}

export interface Props<PL = {}> {
  index:           number
  enabled?:        boolean
  itemType?:       string
  handleSelector?: string
  sourceList:      symbol
  payload:         PL

  onStartDrag?: (index: number) => any
  onEndDrag?:   (index: number) => any

  style?:    React.CSSProperties
  children?: React.ReactNode
}

export type SortableElementComponent<P> = React.ComponentType<P & {
  ref:   React.Ref<HTMLElement>
  style: React.CSSProperties | undefined
}> | string

export default function createSortableElement<PL = {}>(componentOrTag: string, options?: Options): React.ComponentType<React.HTMLAttributes<HTMLElement> & Props<PL>>
export default function createSortableElement<P, PL = {}>(componentOrTag: SortableElementComponent<P>, options?: Options): React.ComponentType<P & Props<PL>>
export default function createSortableElement<P, PL>(componentOrTag: SortableElementComponent<P>, options: Options = {}) {
  return (props: P & Props<PL>) => {
    const ref = React.useRef<HTMLElement | null>(null)

    const {
      sourceList,
      payload,
      style,
      index,
      itemType = options.itemType,
      enabled = true,
      handleSelector,
      onStartDrag,
      onEndDrag,
      ...rest
    } = props
    if (itemType == null) {
      throw new Error("No item type specified in options or props")
    }

    const [isDragging, setIsDragging] = React.useState<boolean>(false)

    const [connectDrag, connectHandle] = useSortableDrag({
      enabled: enabled,
      axis:    options.axis,

      item: {
        type:       itemType,
        index:      index,
        sourceList: sourceList,
        payload:    payload,
      },

      start: () => {
        setIsDragging(true)
        onStartDrag?.(index)
      },

      end: () => {
        onEndDrag?.(index)
        setIsDragging(false)
      },
    })

    const connect = (element: HTMLElement | null) => {
      connectDrag(element)

      const handle = element == null || handleSelector == null
        ? element
        : element.querySelector(handleSelector) as HTMLElement
      connectHandle(handle)
      ref.current = element
    }

    const combinedStyle = isDragging ? {
      ...style,
      display: 'none',
    } : style
    return React.createElement(componentOrTag, {ref: connect, style: combinedStyle, ...rest} as any)
  }
}