import '../init/app'
import './i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from '~/ui/app/App'
import jss from '~/ui/styling/jss'
import { HistoryProvider } from '../history'
import { augmentHistory } from './history'

const history = augmentHistory(createBrowserHistory())

ReactDOM.render(
  (
    <Router history={history}>
      <JssProvider jss={jss}>
        <HistoryProvider history={history}>
          <App />
        </HistoryProvider>
      </JssProvider>
    </Router>
  ),
  document.getElementById('root'),
)