import { CollectionFetchOptions, Database, Endpoint } from 'mobx-document'
import socket from 'socket.io-react'
import RankingDocument from './RankingDocument'

export default class RankingsEndpoint extends Endpoint<RankingDocument, RankingsParams, RankingsMeta> {

  constructor(
    public readonly competitionID: string,
    database: Database<RankingDocument>,
  ) {
    super(database, {})
  }

  public async fetchMore() {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextOffset == null) { return }

    await this.fetch({
      offset: this.meta.nextOffset,
      append: true,
    })
  }

  public async performFetch(params: RankingsParams, options: CollectionFetchOptions) {

    const response = await socket.fetch('participants:rankings', this.competitionID, options)
    if (!response.ok) { return null }

    return {
      data: response.body.data,
      meta: response.body.meta,
    }
  }
}

export interface RankingsParams {}

export interface RankingsMeta {
  nextOffset:         number | null
  competitionCaption: string
  ownIndexes:         number[]
}