import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { Chip, Dimple, HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useChat } from '../ChatContext'
import SendersList from './SendersList'

const ChannelInfo = observer('ChatInfo', () => {

  const [t]       = useTranslation('chat')
  const {channel} = useChat()

  const endpoint = channel?.sendersEndpoint?.()
  const total    = endpoint?.meta?.total ?? 0


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (endpoint == null) { return null }

    return (
      <VBox flex align='stretch'>
        <VBox classNames={$.infoTitle} gap={layout.padding.xs}>
          <HBox gap={layout.padding.inline.m}>
            <Label flex='shrink' h3>
              {t('channel_info.participants.title')}
            </Label>
            <Chip small dim>
              {total}
            </Chip>
          </HBox>
          <Dimple horizontal />
        </VBox>
        <SendersList endpoint={endpoint} />
      </VBox>
    )
  }

  return render()
})

export default ChannelInfo

const useStyles = createUseStyles({
  infoTitle: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})