import React from 'react'
import I18n from 'i18next'
import { isFunction, omit, pick } from 'lodash'
import { observer } from '~/ui/component'
import { ClearButton, HBox, PushButton, PushButtonProps, VBox } from '~/ui/components'
import ModalDialog, { Props as ModalDialogProps } from '~/ui/components/ModalDialog'
import { FormContext, SubmitResult } from '~/ui/form'
import { assignRef } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { focusFirstInvalidField } from './focus'
import { Props as FormProps } from './Form'
import { FormContainer } from './FormContext'
import { useFormContainer } from './hooks'
import { FormModel, isSuccessResult, SubmitButtonSpec, WellKnownSubmitButton } from './types'

export interface Props<M extends FormModel> extends Omit<ModalDialogProps, 'formRef'>, Omit<FormProps<M>, 'flex'> {
  renderButtons?:       (saveButton: React.ReactNode, cancelButton: React.ReactNode) => React.ReactNode
  submitButton?:        SubmitButtonSpec
  cancelButtonCaption?: string
  closeOnSuccess?:      boolean
  children?:            React.ReactNode | ((form: FormContext<M>) => React.ReactNode)
}

const _FormDialog = <M extends FormModel>(props: Props<M>) => {

  const {
    children,
    requestClose,
    afterSubmit,
    formRef,
    footer,
    width = 560,
    contentPadding = true,
    renderButtons: props_renderButtons,
    submitButton = WellKnownSubmitButton.SAVE,
    cancelButtonCaption,
    closeOnSuccess = true,
    model,
    onWillOpen,
    fieldGap = layout.padding.m,
    ...rest
  } = props

  const maybeCloseOnSuccess = React.useCallback((result: SubmitResult, model: M) => {
    if (closeOnSuccess && isSuccessResult(result)) {
      requestClose?.()
    }
    if (!isSuccessResult(result) && formElementRef.current != null) {
      focusFirstInvalidField(formElementRef.current)
    }
    afterSubmit?.(result, model)
  }, [afterSubmit, closeOnSuccess, requestClose])

  const modalDialogProps = pick(rest, modalDialogPropKeys)
  const formProps = omit(rest, modalDialogPropKeys)
  const form = useFormContainer({
    ...formProps,
    model:       model,
    afterSubmit: maybeCloseOnSuccess,
  })

  const maySubmit = 'maySubmit' in model ? !!(model as any).maySubmit : true

  const resetFormOnOpen = React.useCallback(() => {
    form.reset()
    onWillOpen?.()
  }, [form, onWillOpen])

  React.useEffect(() => {
    assignRef(formRef, form)
  }, [formRef, form])

  const formElementRef = React.useRef<HTMLFormElement>(null)

  const submitButtonProps = React.useMemo((): PushButtonProps => {
    switch (submitButton) {
      case WellKnownSubmitButton.SAVE:
        return {icon: 'check', caption: I18n.t('buttons:save')}
      case WellKnownSubmitButton.NEXT:
        return {icon: 'arrow-right', caption: I18n.t('buttons:next'), iconSide: 'right'}
      default:
        return submitButton
    }
  }, [submitButton])

  //------
  // Rendering

  function render() {
    return (
      <FormContainer form={form}>
        <ModalDialog
          {...modalDialogProps as ModalDialogProps}
          requestClose={requestClose}
          width={width}
          contentPadding={contentPadding}
          formProps={{onSubmit: form.submit}}
          formRef={formElementRef}
          footer={footer ?? renderButtons()}
          closeOnClickOutside={false}
          onWillOpen={resetFormOnOpen}
        >
          <VBox flex='both' gap={fieldGap}>
            {isFunction(children) ? (
              children(form)
            ) : (
              children
            )}
          </VBox>
        </ModalDialog>
      </FormContainer>
    )
  }

  function renderButtons() {
    if (props_renderButtons != null) {
      return props_renderButtons(renderSaveButton(), renderCancelButton())
    } else {
      return renderDefaultButtons()
    }
  }

  function renderDefaultButtons() {
    return (
      <HBox justify='right' gap={layout.padding.s}>
        {renderSaveButton()}
        {renderCancelButton()}
      </HBox>
    )
  }

  function renderSaveButton() {
    return (
      <PushButton
        {...submitButtonProps}
        working={form.submitting}
        enabled={maySubmit}
        submit
      />
    )
  }

  function renderCancelButton() {
    return (
      <ClearButton
        icon='cross'
        caption={cancelButtonCaption ?? I18n.t('buttons:cancel')}
        onTap={requestClose}
        dim
      />
    )
  }

  return render()

}

const modalDialogPropKeys: Array<keyof ModalDialogProps> = [
  'open',
  'requestClose',
  'header',
  'headerRight',
  'icon',
  'title',
  'footer',
  'semi',
  'height',
  'classNames',
  'onDidOpen',
  'onWillClose',
  'onDidClose',
  'renderDrawer',
  'drawerSide',
  'drawerWidth',
  'drawerExpanded',
  'drawerProps',
]

const FormDialog = observer('FormDialog', _FormDialog) as typeof _FormDialog
export default FormDialog