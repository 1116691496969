import React from 'react'
import { useTranslation } from 'react-i18next'
import { Answer, ChallengeTask } from '~/models'
import { component, memo } from '~/ui/component'
import { HBox, Label, Notice, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling, useTheme } from '~/ui/styling'

export interface Props {
  task:   ChallengeTask
  answer: Answer
}

const ChallengeTaskCompleteNotice = memo('ChallengeTaskCompleteNotice', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <Notice gap={layout.padding.s}>
        <ChallengeTaskCompleteNoticeBody {...props}/>
      </Notice>
    )
  }

  return render()

})

export default ChallengeTaskCompleteNotice

const ChallengeTaskCompleteNoticeBody = component('ChallengeTaskCompleteNoticeBody', (props: Props) => {

  const {task, answer} = props
  const {score, correct} = answer

  const inReview = task?.question?.review && (answer?.status === 'pending' || answer?.status === 'review')

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  const {guide, colors} = useStyling()
  const theme = useTheme()

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderHeader()}
        {!inReview && renderScore()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox justify='center' gap={layout.padding.s} classNames={$.header}>
        {!inReview && correct != null && renderCorrectnessIcon()}
        <VBox flex='shrink'>
          <Label small bold truncate={false} align='center'>
            {getLabel()}
          </Label>
          {inReview &&
            <Label small dim align='center'>
              {t('task.in_review')}
            </Label>
          }
        </VBox>
      </HBox>
    )
  }

  function renderCorrectnessIcon() {
    return (
      <SVG
        name={correct ? 'check' : 'cross'}
        size={layout.icon.xs}
      />
    )
  }

  function renderScore() {
    if (score == null) { return null }

    const maxScore        = task.maxScore
    const backgroundColor = theme.isDark ? colors.bg.dark.normal.alpha(0.2).string() : theme.bg.alt.string()
    const borderRadius    = Math.max(guide.notice.borderRadius(minHeight) - 2)

    return (
      <VBox classNames={[$.score, {backgroundColor, borderRadius}]} gap={layout.padding.inline.s}>
        <Label small bold align='center'>
          {inReview ? (
            t('task.status.in_review.summary')
          ) :
          maxScore != null && maxScore >= score.points ? (
            t('task.score_out_of.summary', {score: score.formatted, maxScore})
          ) : (
            t('task.score.summary', {score: score.formatted, maxScore})
          )}
        </Label>
      </VBox>
    )
  }

  function getLabel() {
    if (correct == null) {
      return t('task.status.completed.summary')
    } else if (correct) {
      return t('task.status.correct.summary')
    } else {
      return t('task.status.incorrect.summary')
    }
  }

  return render()

})

export const minHeight = 40

const useStyles = createUseStyles({
  header: {
    padding:   layout.padding.inline.m,
  },

  score: {
    margin:    2,
    marginTop: -layout.padding.inline.m - 2,
    padding:   layout.padding.inline.m,
  },
})