import React from 'react'
import { ScrollPosition, ScrollState, useScrollManager } from 'react-scroll-manager'
import { useTimer } from 'react-timer'
import { isPromise, objectEquals } from 'ytil'
import { memo } from '~/ui/component'
import { usePrevious } from '~/ui/hooks'

export interface Props {
  horizontal?: boolean

  endReachedThreshold?:  number
  onEndReached?:         () => Promise<number | false> | any

  scrollThrottle?:      number
  onScroll?:            (scrollPosition: ScrollPosition) => any

  onScrollStateChange?: (state: ScrollState) => any
  scrollStateKey?:      any

  children?: React.ReactNode
}

const ScrollerProxy = memo('ScrollerProxy', (props: Props) => {

  const {
    horizontal,
    endReachedThreshold = 100,
    onEndReached,
    scrollThrottle = 16,
    onScroll,
    onScrollStateChange,
    scrollStateKey,
    children,
  } = props

  const timer         = useTimer()
  const scrollManager = useScrollManager()

  //------
  // End reached

  const suspendEndReachedRef = React.useRef<boolean>(false)

  const detectEndReached = React.useCallback(() => {
    if (suspendEndReachedRef.current) { return }
    if (scrollManager == null) { return }
    if (onEndReached == null) { return }

    const endReached = scrollManager.isEndReached({
      threshold: endReachedThreshold,
    })
    if (!endReached) { return }

    const retval = onEndReached()
    if (isPromise(retval)) {
      suspendEndReachedRef.current = true
      timer.then(retval, maybeThrottle => {
        const throttle =
          typeof maybeThrottle === 'number' ? maybeThrottle :
          maybeThrottle === false ? null :
          500

        if (throttle == null) {
          suspendEndReachedRef.current = false
        } else {
          timer.setTimeout(() => {
            suspendEndReachedRef.current = false
            detectEndReached()
          }, throttle)
        }
      })
    }
  }, [endReachedThreshold, onEndReached, scrollManager, timer])

  React.useEffect(() => {
    timer.setTimeout(detectEndReached, 500)
  }, [detectEndReached, timer])

  //------
  // Scroll state

  const scrollStateRef = React.useRef<ScrollState>(scrollManager.getScrollState(horizontal || false))

  const updateScrollState = React.useCallback(() => {
    if (onScrollStateChange == null) { return }

    const nextScrollState = scrollManager.getScrollState(horizontal || false)
    if (!objectEquals(scrollStateRef.current, nextScrollState)) {
      scrollStateRef.current = nextScrollState
      onScrollStateChange(nextScrollState)
    }
  }, [horizontal, onScrollStateChange, scrollManager])

  //------
  // Component lifecycle

  const handleScroll = React.useCallback((scrollPosition: ScrollPosition) => {
    detectEndReached()
    updateScrollState()

    onScroll?.(scrollPosition)
  }, [detectEndReached, onScroll, updateScrollState])

  React.useEffect(() => {
    scrollManager.addScrollListener(handleScroll, {
      throttle: scrollThrottle,
    })

    return () => {
      scrollManager.removeScrollListener(handleScroll)
    }
  }, [detectEndReached, handleScroll, scrollManager, scrollThrottle, updateScrollState])

  const prevScrollStateKey = usePrevious(scrollStateKey)
  React.useLayoutEffect(() => {
    if (prevScrollStateKey !== undefined && scrollStateKey === prevScrollStateKey) { return }
    detectEndReached()
    updateScrollState()
  }, [detectEndReached, prevScrollStateKey, scrollStateKey, updateScrollState])

  return (
    <>
      {children}
    </>
  )

})

export default ScrollerProxy