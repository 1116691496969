import { create } from 'jss'
import camelCase from 'jss-plugin-camel-case'
import compose from 'jss-plugin-compose'
import defaultUnit from 'jss-plugin-default-unit'
import expand from 'jss-plugin-expand'
import extend from 'jss-plugin-extend'
import global from 'jss-plugin-global'
import nested from 'jss-plugin-nested'
import propsSort from 'jss-plugin-props-sort'
import functions from 'jss-plugin-rule-value-function'
import observable from 'jss-plugin-rule-value-observable'
import template from 'jss-plugin-template'
import vendorPrefixer from 'jss-plugin-vendor-prefixer'
import colors from './colors'

export default create({
  plugins: [
    functions(),
    observable(),
    template(),
    global(),
    extend(),

    colors(),

    nested(),
    compose(),
    camelCase(),
    defaultUnit(),
    expand(),

    vendorPrefixer(),
    propsSort(),
  ],
})