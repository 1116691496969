import { camelCase } from 'lodash'
import { modifyObject } from 'ytil'
import { Model } from '../Model'
import { PropertyInfo } from './types'

const registry: WeakMap<Constructor<Model>, ModelSerialization> = new WeakMap()

export default class ModelSerialization {

  constructor(
    public ctor: Constructor<Model>,
  ) {}

  private propertyInfos: Record<string, PropertyInfo> = {}

  public static for(arg: any) {
    const ctor = resolveConstructor(arg)
    const serialization = registry.get(ctor)
    if (serialization != null) { return serialization }

    const newSerialization = new ModelSerialization(ctor)
    registry.set(ctor, newSerialization)
    return newSerialization
  }

  public get super(): ModelSerialization | null {
    const superCtor = resolveSuperCtor(this.ctor)
    if (superCtor == null) { return null }

    return ModelSerialization.for(superCtor)
  }

  public propInfo(prop: string): PropertyInfo {
    if (prop in this.propertyInfos) {
      return this.propertyInfos[prop]
    } else {
      const info = this.super?.propInfo(prop) ?? {
        serializers: [],
      }
      this.propertyInfos[prop] = info
      return info
    }
  }

  //------
  // Property modification

  public modify(prop: string, modifier: (prop: PropertyInfo) => void) {
    const info = this.propInfo(prop)
    modifier(info)
  }

  //------
  // Field names

  public propertyName(field: string) {
    for (const [prop, info] of Object.entries(this.propertyInfos)) {
      if (info.field === field) {
        return prop
      }
    }

    return camelCase(field)
  }

  //------
  // Serialization

  public deserializeInto(model: Model, raw: AnyObject) {
    for (const [prop, value] of Object.entries(raw)) {
      const existing = Object.getOwnPropertyDescriptor(model, prop)
      Object.defineProperty(model, prop, {
        value:        this.deserializeProp(prop, value),
        writable:     existing?.writable ?? true,
        configurable: false,
      })
    }
  }

  public serialize(model: Model) {
    const serialized: AnyObject = {}
    for (const [prop, value] of Object.entries(model)) {
      serialized[prop] = this.deserializeProp(prop, value)
    }

    return serialized
  }

  public deserializeProp(prop: string, value: any) {
    const info = this.propInfo(prop)

    for (const {path, deserialize} of info.serializers) {
      value = modifyObject(value, path ?? '', value => (
        value == null ? null : deserialize(value)
      ))
    }

    return value
  }

  public serializeProp(prop: string, value: any) {
    const info = this.propInfo(prop)

    for (const {path, serialize} of info.serializers) {
      value = modifyObject(value, path ?? '', value => (
        value == null ? null : serialize(value)
      ))
    }

    return value
  }

}

function resolveConstructor(arg: any) {
  if (typeof arg === 'function' && arg.prototype != null) {
    return arg
  }
  if (typeof arg === 'object' && arg.constructor != null) {
    return arg.constructor
  }

  throw new Error(`Invalid ModelSerialization target: ${arg}`)
}

function resolveSuperCtor(ctor: Constructor<any>) {
  const superProto = ctor.prototype.__proto__
  if (superProto == null) { return null }

  return superProto.constructor
}