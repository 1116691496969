import { isFunction } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  DraggableItem,
  DragGestureHandlers,
  DropZone,
  DropZoneAccept,
  ItemLayout,
  SnapAction,
  SnapGuide,
} from './types'
import { rectContainsPoint } from './util'

let UID = 0

export default class DragDropMonitor<T extends DraggableItem = any> {

  constructor(
    item: T,
    public readonly sourceElement:   HTMLElement,
    public readonly itemLayout:      ItemLayout,
    public readonly startMousePoint: Point,
  ) {
    this.item = item
    this.center = {
      x: itemLayout.sourceRect.left + itemLayout.sourceRect.width / 2,
      y: itemLayout.sourceRect.top + itemLayout.sourceRect.height / 2,
    }

    makeObservable(this)
  }

  public readonly uid = UID++

  public delegates: DragDropMonitorDelegates<T> = {}

  //------
  // State

  @observable
  public item: T

  @observable
  public axis: 'vertical' | 'horizontal' | 'both' = 'both'

  @observable
  public dragging: boolean = false

  @observable
  public center: Point

  @observable
  public currentMousePoint: Point = this.startMousePoint

  @observable
  public snapGuides: SnapGuide[] = []

  @computed
  public get mouseDelta() {
    return {
      x: this.currentMousePoint.x - this.startMousePoint.x,
      y: this.currentMousePoint.y - this.startMousePoint.y,
    }
  }

  private get isSignificantMove() {
    const delta = this.mouseDelta
    return Math.abs(delta.x) > 0 || Math.abs(delta.y) > 0
  }

  //------
  // Data

  @action
  public updateItem(update: (item: T) => T) {
    this.item = update(this.item)
  }

  //------
  // Ghost

  @observable
  public ghostLayout: ItemLayout = this.itemLayout

  @observable
  public ghostVisible: boolean = true

  @action
  public showGhost() {
    this.ghostVisible = true
  }

  @action
  public hideGhost() {
    this.ghostVisible = false
  }

  @action
  public setGhostLayout(update: Partial<ItemLayout>) {
    this.ghostLayout = {...this.ghostLayout, ...update}
  }

  @action
  public resetGhostLayout() {
    this.ghostLayout = this.itemLayout
  }

  public cloneSourceElement() {
    return this.sourceElement.cloneNode(true)
  }

  //------
  // Drag handlers

  @action
  public move(point: Point) {
    this.currentMousePoint = point

    if (this.axis === 'vertical') {
      this.currentMousePoint.x = this.startMousePoint.x
    }
    if (this.axis === 'horizontal') {
      this.currentMousePoint.y = this.startMousePoint.y
    }

    if (this.delegates.shake != null) {
      this.recordGesturePoint(point)
    }

    // Activate upon first move.
    if (!this.dragging && this.isSignificantMove) {
      this.start()
    }
    if (!this.dragging) { return }

    this.center = this.calculateCenter()

    const dropZoneID = this.queryDropZone()
    if (dropZoneID != null) {
      this.delegates.hover?.(dropZoneID, this.item, point, this)
    }

    const result = this.delegates.drag?.(this.item, this)
    if (result === false) {
      this.cancel()
    }
  }

  @action
  public start() {
    const result = this.delegates.start?.(this.item, this)
    if (result === false) {
      this.cancel()
    } else {
      this.dragging = true
    }
  }

  @action
  public async drop() {
    if (this.dropZoneID != null) {
      await this.delegates.drop?.(this.dropZoneID, this.item, this.center, this)
    }
    this.end()
  }

  @action
  public cancel() {
    this.end()
  }

  @action
  private end() {
    if (this.dragging) {
      this.delegates.end?.(this.item, this)
    }

    this.dragging = false
    this.delegates.unbind?.()
  }

  //------
  // Gestures

  private gesturePoints: Point[] = []
  private gestureDerivatives: Point[] = []

  private recordGesturePoint(point: Point) {
    this.gesturePoints.push(point)
    if (this.gesturePoints.length > 1) {
      this.gestureDerivatives.push({
        x: point.x - this.gesturePoints[this.gesturePoints.length - 2].x,
        y: point.y - this.gesturePoints[this.gesturePoints.length - 2].y,
      })
    }

    while (this.gesturePoints.length > 20) {
      this.gesturePoints.shift()
    }
    while (this.gestureDerivatives.length > 20) {
      this.gestureDerivatives.shift()
    }

    if (this.detectShake()) {
      this.delegates.shake?.(this.item, this)
      this.gesturePoints = []
      this.gestureDerivatives = []
    }
  }

  private detectShake() {
    if (this.gestureDerivatives.length < 10) { return false }

    let signSwitchCount: number = 0
    let prevSign: number | null = null

    for (const derivative of this.gestureDerivatives) {
      const sign: number = derivative.x === 0
        ? (prevSign ?? 1)
        : (derivative.x > 0 ? 1 : -1)

      if (prevSign == null || sign !== prevSign) {
        signSwitchCount += 1
        prevSign = sign
      }

      if (signSwitchCount >= 5) {
        return true
      }
    }

    return false
  }

  //------
  // Drop zones

  @observable
  private dropZoneID: symbol | null = null

  private queryDropZone() {
    const id = this.findDropZone()
    this.setActiveDropZoneID(id)
    return id
  }

  @action
  private setActiveDropZoneID(id: symbol | null) {
    if (id === this.dropZoneID) { return }

    if (this.dropZoneID != null) {
      this.delegates.leave?.(this.dropZoneID, this.item, this)
    }
    if (id != null) {
      this.delegates.enter?.(id, this.item, this)
    }

    this.dropZoneID = id
  }

  private findDropZone() {
    const dropZones = this.delegates.getDropZones?.() ?? []

    const matching: Array<[symbol, number]> = []
    for (const [id, accept, bounds, zIndex] of dropZones) {
      if (!this.dropZoneAccepts(accept)) { continue }
      if (!rectContainsPoint(bounds, this.currentMousePoint)) { continue }

      matching.push([id, zIndex])
    }

    // Get the top most one.
    matching.sort((a, b) => b[1] - a[1])
    return matching[0]?.[0] ?? null
  }

  private dropZoneAccepts(accept: DropZoneAccept) {
    if (isFunction(accept)) {
      return accept(this.item)
    } else {
      return accept.includes(this.item.type)
    }
  }

  //------
  // Geometry

  private calculateCenter() {
    const {sourceRect} = this.itemLayout
    const center = {
      x: sourceRect.left + sourceRect.width / 2 + this.mouseDelta.x,
      y: sourceRect.top + sourceRect.height / 2 + this.mouseDelta.y,
    }

    if (this.dropZoneID != null && this.delegates.snap != null) {
      const snapped = this.delegates.snap(this.dropZoneID, center, this)
      if (snapped == null) { return center }

      this.snapGuides = snapped.guides
      return snapped.center
    } else {
      return center
    }
  }

}

export interface DragDropMonitorDelegates<T extends DraggableItem> extends DragGestureHandlers<T> {
  start?: (item: T, monitor: DragDropMonitor<T>) => boolean | void
  drag?:  (item: T, monitor: DragDropMonitor<T>) => boolean | void
  end?:   (item: T, monitor: DragDropMonitor<T>) => void

  getDropZones?: () => DropZone[]

  enter?: (id: symbol, item: T, monitor: DragDropMonitor<T>) => void
  leave?: (id: symbol, item: T, monitor: DragDropMonitor<T>) => void
  hover?: (id: symbol, item: T, point: Point, monitor: DragDropMonitor<T>) => void
  drop?:  (id: symbol, item: T, point: Point, monitor: DragDropMonitor<T>) => void | Promise<void>

  snap?:  (id: symbol, point: Point, monitor: DragDropMonitor<T>) => SnapAction | null

  unbind?: () => any
}