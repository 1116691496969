import React from 'react'
import { isPlainObject } from 'lodash'
import { memo } from '~/ui/component'
import { VBoxProps } from '~/ui/components'
import { colors, createUseStyles, layout } from '~/ui/styling'

export interface Props {
  horizontal?:     boolean
  vertical?:       boolean
  counterPadding?: VBoxProps['padding']
  classNames?:     React.ClassNamesProp
}

const Dimple = memo('Dimple', (props: Props) => {

  const {horizontal, vertical, counterPadding, classNames} = props

  const $ = useStyles(counterPadding)

  return (
    <div classNames={[$.dimple, {horizontal, vertical}, classNames]}>
      <div/>
      <div/>
    </div>
  )

})

export const HorizontalDimple = memo('HorizontalDimple', (props: Omit<Props, 'horizontal' | 'vertical'>) => {
  return <Dimple horizontal {...props}/>
})

export const VerticalDimple = memo('HorizontalDimple', (props: Omit<Props, 'horizontal' | 'vertical'>) => {
  return <Dimple horizontal {...props}/>
})

Object.assign(Dimple, {
  Horizontal: HorizontalDimple,
  Vertical:   VerticalDimple,
})

export default Dimple as typeof Dimple & {
  Horizontal: typeof HorizontalDimple
  Vertical:   typeof VerticalDimple
}

const useStyles = createUseStyles({
  dimple: (counterPadding?: VBoxProps['padding']) => ({

    '& > :first-child': {
      background: colors.dimple.dark,
    },
    '& > :last-child': {
      background: colors.dimple.light,
    },

    '&.horizontal': {
      ...layout.flex.column,

      ...layout.responsive(size => ({
        margin: [0, -(isPlainObject(counterPadding) ? (counterPadding as any)[size] : counterPadding ?? 0)],
      })),

      height: 2,
      '& > *': {height: 1},
    },
    '&.vertical': {
      ...layout.flex.row,

      ...layout.responsive(size => ({
        margin: [0, -(isPlainObject(counterPadding) ? (counterPadding as any)[size] : counterPadding ?? 0)],
      })),


      width:     2,
      minHeight: '1em',
      '& > *': {width: 1},
    },
  }),
})