import ComponentBrandingBase from '../components/ComponentBrandingBase'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'
import TimelineBarBranding from './TimelineBarBranding'

export default class TimelineBranding extends ComponentBrandingBase<TimelineSpec> {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: TimelineBranding | null,
    defaults: Partial<TimelineSpec> = {},
  ) {
    super(delegate,parent, {
      depth:           1,
      tickColor:       'fg-dark-dim',
      activeTickColor: 'primary',
      nowPlayingColor: 'primary',
      ...defaults,
    })
  }

  public readonly previewSize = {
    width:  480,
    height: 104,
  }

  public itemBar = new TimelineBarBranding(this, null)

  public deserializeFrom(spec: BrandedComponentSpec, variant: string | null) {
    super.deserializeFrom(spec, variant)

    const itemBarSpec = (spec as any).itemBar as BrandedComponentSpec
    this.itemBar.deserializeFrom(itemBarSpec, variant)
  }

}

export interface TimelineSpec {
  depth:           number
  tickColor:       string
  activeTickColor: string
  nowPlayingColor: string
}
