import React from 'react'
import { memo } from '~/ui/component'
import { HBox, SVG, Tappable, VBox } from '~/ui/components'
import { FieldChangeCallback, invokeFieldChangeCallback, useFieldChangeCallback } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { colors, createUseStyles, layout } from '~/ui/styling'
import TextField, { InputElement, Props as TextFieldProps } from './TextField'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange'>, Pick<Intl.NumberFormatOptions, 'useGrouping' | 'maximumFractionDigits'> {
  value:     number | null
  onChange?: FieldChangeCallback<number | null> | ((num: number | null) => any)

  locale?:  string
  invalid?: boolean
  enabled?: boolean
  light?:   boolean

  step?:    number
  minimum?: number | null
  maximum?: number | null
}

const defaultLocale = 'nl'

const NumberField = memo('NumberField', (props: Props) => {

  const {
    value,
    onChange,
    locale = defaultLocale,
    invalid,
    step,
    minimum = 0,
    maximum,
    showClearButton = 'always',
    inputAttributes: {
      onKeyDown: props_onKeyDown,
      onFocus:   props_onFocus,
      onBlur:    props_onBlur,
      ...inputAttributes
    } = {},
    classNames,
    useGrouping,
    maximumFractionDigits,
    ...rest
  } = props

  const options = React.useMemo(
    () => ({useGrouping, maximumFractionDigits}),
    [maximumFractionDigits, useGrouping],
  )

  const [text, setText] = React.useState<string>(formatNumber(value, locale, options, false))
  const [numberInvalid, setNumberInvalid] = React.useState<boolean>(false)

  //------
  // Callbacks

  const clamp = React.useCallback((value: number) => {
    if (minimum != null) { value = Math.max(minimum, value) }
    if (maximum != null) { value = Math.min(maximum, value) }
    return value
  }, [maximum, minimum])

  const onChangeText = useFieldChangeCallback(React.useCallback((text: string, partial?: boolean) => {
    text = autoFormat(text)

    setText(text)
    setNumberInvalid(false)

    const num = parseNumber(text, locale, options)
    if (num == null) {
      invokeFieldChangeCallback(onChange, null, partial)
    } else if (!isNaN(num)) {
      invokeFieldChangeCallback(onChange, clamp(num), partial)
    }
  }, [clamp, locale, onChange, options]))

  const setValueAndText = React.useCallback((value: number | null) => {
    if (value == null) {
      setText('')
    } else {
      setText(formatNumber(value, locale, options, false))
      onChange?.(value)
    }
  }, [locale, onChange, options])

  const stepUp = React.useCallback(() => {
    if (step == null) { return }

    const currentRounded = Math.floor((value ?? 0) / step) * step
    const nextValue      = clamp(currentRounded + step)
    setValueAndText(nextValue)
  }, [clamp, setValueAndText, step, value])

  const stepDown = React.useCallback(() => {
    if (step == null) { return }

    const currentRounded = Math.ceil((value ?? 0) / step) * step
    const nextValue      = clamp(currentRounded - step)
    setValueAndText(nextValue)
  }, [clamp, setValueAndText, step, value])

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<InputElement>) => {
    if (event.key === 'ArrowUp') {
      stepUp()
      event.preventDefault()
    }
    if (event.key === 'ArrowDown') {
      stepDown()
      event.preventDefault()
    }

    props_onKeyDown?.(event)
  }, [props_onKeyDown, stepUp, stepDown])

  const onFocus = React.useCallback((event: React.FocusEvent<InputElement>) => {
    setValueAndText(value)
    props_onFocus?.(event)
  }, [setValueAndText, value, props_onFocus])

  const onBlur = React.useCallback((event) => {
    if (text.trim() === '') {
      setNumberInvalid(false)
      onChange?.(null)
    } else {
      setNumberInvalid(false)

      const num = parseNumber(text, locale, options)
      if (typeof num === 'number' && isNaN(num)) {
        setNumberInvalid(true)
      } else {
        const clamped = num == null ? null : clamp(num)
        onChange?.(clamped)
        setText(formatNumber(clamped, locale, options))
      }
    }

    props_onBlur?.(event)
  }, [text, props_onBlur, onChange, locale, options, clamp])

  const prevValue = usePrevious(value ?? null)
  React.useEffect(() => {
    if (prevValue === undefined || prevValue === value) { return }

    const valueFromText = parseNumber(text, locale, options)
    if (value !== valueFromText) {
      setText(formatNumber(value, locale, options))
    }
  }, [locale, options, prevValue, text, value])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <TextField
        {...rest}
        value={text}
        onChange={onChangeText}

        invalid={invalid || numberInvalid}
        showClearButton={showClearButton}

        accessoryRight={renderSteppers()}
        inputAttributes={{...inputAttributes, onFocus, onBlur, onKeyDown}}
        classNames={classNames}
      />
    )
  }

  function renderSteppers() {
    const {step, readOnly, enabled = true} = props
    if (step == null || readOnly) { return null }

    return (
      <HBox gap={layout.padding.s}>
        {props.accessoryRight}
        <VBox flex justify='middle' gap={layout.padding.inline.xs}>
          <Tappable classNames={[$.stepper, {enabled}]} onTap={stepUp} tabIndex={-1} enabled={enabled}>
            <SVG name='chevron-up' size={{width: 10, height: 10}} dim/>
          </Tappable>
          <Tappable classNames={[$.stepper, {enabled}]} onTap={stepDown} tabIndex={-1} enabled={enabled}>
            <SVG name='chevron-down' size={{width: 10, height: 10}} dim/>
          </Tappable>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default NumberField

function autoFormat(text: string) {
  // Remove all junk.
  text = text.replace(/[^-0-9.,]/g, '')
  return text
}

function formatNumber(num: number | null, locale: string, options: Intl.NumberFormatOptions, pretty: boolean = true) {
  if (num == null || isNaN(num)) { return ''}

  let format: Intl.NumberFormat
  if (pretty) {
    format = new Intl.NumberFormat(locale, options)
  } else {
    format = new Intl.NumberFormat(locale, {
      maximumFractionDigits: options.maximumFractionDigits,
      currency:              undefined,
      useGrouping:           false,
    })
  }

  return format.format(num)
}

function parseNumber(text: string, locale: string, options: Intl.NumberFormatOptions): number | null {
  text = text.trim()
  if (text === '') { return null }

  const decimalSep = (1.1).toLocaleString(locale).match(/1(.*)1/)![1]
  text = text.split(decimalSep).join('.')

  text = text.replace(/[^-0-9.]/g, '')
  let num = parseFloat(text)

  if (options.maximumFractionDigits != null) {
    num = parseFloat(num.toFixed(options.maximumFractionDigits))
  }

  return num
}

const useStyles = createUseStyles(theme => ({
  stepper: {
    '&.enabled:hover': {
      ...colors.overrideForeground(theme.semantic.primary),
    },
    '&:first-child': {
      marginBottom: 2,
    },
  },
}))