import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { newsStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { useQueryParam } from '~/ui/hooks'
import { NewsProvider } from './NewsContext'

export interface Props {
  children?: React.ReactNode
}

const NewsContainer = observer('NewsContainer', (props: Props) => {

  const {children} = props

  const tabs    = projectStore.project?.tabs
  const hasNews = tabs?.find(it => it.link.href.startsWith('//news')) != null

  const [filteredFeedIDs]      = useQueryParam('feeds', 'string[]')
  const initialFilteredFeedIDs = React.useRef(filteredFeedIDs)

  const service = React.useMemo(() => {
    if (!hasNews) { return null }

    const feedIDs = initialFilteredFeedIDs.current ?? []
    return newsStore.newsService({
      initialFilteredFeedIDs: feedIDs.length === 0 ? undefined : feedIDs,
    })
  }, [hasNews, initialFilteredFeedIDs])

  useOnDemandService(service)

  //------
  // Rendering

  function render() {
    if (service == null) {
      return <>{children}</>
    } else {
      return (
        <NewsProvider service={service}>
          {children}
        </NewsProvider>
      )
    }
  }

  return render()

})

export default NewsContainer