import { some } from 'lodash'
import { makeAutoObservable } from 'mobx'
import socket from 'socket.io-react'
import { ClientTab, Project } from '~/models'
import { getRelatedAppHrefs, isAppHref, resolveAppHref } from '~/navigation'
import { BrandingManifest } from '~/ui/styling/branding'
import authenticationStore from './authenticationStore'
import { register } from './support'

export class ProjectStore {

  constructor() {
    makeAutoObservable(this)
  }

  public project: Project | null = null

  public branding: BrandingManifest | null = null

  public requestUpdate() {
    socket.emit('project:request')
  }

  public get tabs(): ClientTab[] {
    if (this.project == null) { return [] }
    return this.project.tabs.filter(tab => /^(?!\/\/(qrcamera|organiser)).*/.test(tab.link.href))
  }

  //------
  // Initialization

  public init() {
    socket.addEventListener('project:update', this.onProjectUpdate)
    socket.addEventListener('project:archived', this.onProjectArchived)
    authenticationStore.on('logout', this.onLogOut)
  }

  private onProjectUpdate = (json: ProjectUpdatePayload) => {
    this.project  = Project.deserialize(json.project)
    this.branding = json.branding
    this.urlSchemes = json.urlSchemes
  }

  private onProjectArchived = () => {
    this.project = null
    authenticationStore.logOut()
  }

  private onLogOut = () => {
    this.project = null
    this.branding = null
  }

  //------
  // URL schemes

  public urlSchemes: ProjectURLScheme[] = []

  public shouldResolveURLScheme(scheme: string) {
    return this.urlSchemes.some(s => s.scheme === scheme && s.resolve)
  }

  //------
  // Tabs

  public activeTabForPath(path: string): ClientTab | null {
    if (this.project == null) { return null }

    const matchingTabs = this.tabs.filter(tab => {
      const hrefsToMatch = isAppHref(tab.link.href)
        ? [tab.link.href, ...getRelatedAppHrefs(tab.link.href)].map(resolveAppHref)
        : [tab.link.href]
      return some(hrefsToMatch, href => path.startsWith(href))
    })
    matchingTabs.sort((a, b) => b.link.href.length - a.link.href.length)

    return matchingTabs.length === 0 ? null : matchingTabs[0]
  }

  public captionForPath(path: string): string | null {
    const tab = this.activeTabForPath(path)
    return tab?.caption ?? null
  }

}

export default register(new ProjectStore())

export interface ProjectUpdatePayload {
  project:    AnyObject
  branding:   BrandingManifest | null
  urlSchemes: ProjectURLScheme[]
}
export interface ProjectURLScheme {
  scheme:  string
  resolve: boolean
}