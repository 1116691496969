

import { isFunction } from 'lodash'
import Logger from 'logger'
import { SocketError } from 'socket.io-react'
import { SendOptions } from './types'

export interface Config {
  /** A logger to use. */
  logger: Logger

  /** Delay after which to auto-reconnect. */
  defaultReconnect: number

  /** A default timeout for `send`. */
  defaultSendTimeout: number

  /** Can be used to extract any custom error from JSON. */
  extractError: (json: any) => SocketError

  /** A general error handler for `send` operations. */
  errorHandler: (error: SocketError, options: SendOptions) => any
}

const config: Config = {
  logger:             new Logger('Socket.IO'),
  defaultReconnect:   5000,
  defaultSendTimeout: 5000,
  extractError:       json => SocketError.deserialize(json),
  errorHandler:       () => undefined,
}
export default config

export function configure(cfg: Partial<Config> | ((config: Config) => void)) {
  if (isFunction(cfg)) {
    cfg(config)
  } else {
    Object.assign(config, cfg)
  }
}