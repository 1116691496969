import React from 'react'

export function useRefMap<K, V>(): RefMap<K, V> {
  return React.useMemo((): RefMap<K, V> => {
    const map = new Map<K, V>()

    return {
      for: key => current => {
        if (current == null) {
          map.delete(key)
        } else {
          map.set(key, current)
        }
      },
      set: (key, current) => {
        if (current == null) {
          map.delete(key)
        } else {
          map.set(key, current)
        }
      },
      get: key => {
        return map.get(key)
      },
      clear:   () => {
        map.clear()
      },

      size:    () => map.size,
      all:     () => [...map.values()],
      entries: () => [...map.entries()],
    }
  }, [])
}

export interface RefMap<K, V> {
  size:    () => number
  for:     (key: K) => (current: V | null) => void
  set:     (key: K, value: V | null) => void
  clear:   () => void
  get:     (key: K) => V | undefined
  all:     () => V[]
  entries: () => Array<[K, V]>
}