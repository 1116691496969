import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  HBox,
  Label,
  PushButton,
  Shaker,
  Spinner,
  TextField,
  VBox,
} from '~/ui/components'
import { Form, FormContext, FormField, FormProps, useAutoFilledDetection } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import AuthForm from '../AuthForm'
import LoginFormModel from './LoginFormModel'
import PinCodeField from './PinCodeField'

export interface Props extends FormProps<LoginFormModel> {
  magicLogin?:        boolean
  emailAndPin?:       boolean
  otherLoginOptions?: React.ReactNode
  requestForgotPin?:  (email: string) => any
}

const LoginForm = observer('LoginForm', (props: Props) => {

  const {
    model: formModel,
    emailAndPin = true,
    magicLogin = false,
    otherLoginOptions,
    requestForgotPin,
  } = props

  const emailFieldRef = React.useRef<HTMLInputElement>(null)
  const autoFilled    = useAutoFilledDetection([emailFieldRef])

  const maySubmit = autoFilled || formModel.maySubmit

  const [t] = useTranslation('auth')

  const onForgotPinTap = React.useCallback(() => {
    requestForgotPin?.(formModel.email)
  }, [formModel.email, requestForgotPin])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Form model={formModel}>
        {form => (
          <Shaker shake={form.invalid}>
            <AuthForm classNames={$.LoginForm} preamble={emailAndPin && t('login.instruction')}>
              {magicLogin ? renderMagicLogin() : renderContent(form)}
            </AuthForm>
          </Shaker>
        )}
      </Form>
    )
  }

  function renderMagicLogin() {
    return (
      <Center>
        <Spinner/>
      </Center>
    )
  }

  function renderContent(form: FormContext<LoginFormModel>) {
    return (
      <>
        {renderEmailAndPin(form.submitting)}
        {renderOtherLoginOptions()}
      </>
    )
  }

  function renderEmailAndPin(submitting: boolean) {
    if (!emailAndPin) { return null }

    return(
      <>
        <VBox gap={layout.padding.s}>
          {renderEmailField()}
          {renderPinCodeField()}
        </VBox>
        {renderButtons(submitting)}
      </>
    )
  }

  function renderEmailField() {
    return (
      <FormField align='center' name='email'>
        {bind => (
          <TextField
            {...bind}
            ref={emailFieldRef}
            autoFocus
            placeholder={t('login.fields.email.placeholder')}
            inputAttributes={{
              autoCapitalize: 'off',
              autoCorrect:    'off',
              autoComplete:   'username',
            }}
            align='center'
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  function renderPinCodeField() {
    return (
      <FormField align='center' name='pin'>
        {bind => <PinCodeField {...bind}/>}
      </FormField>
    )
  }

  function renderButtons(submitting: boolean) {
    return (
      <VBox gap={layout.padding.s}>
        <PushButton
          icon='login'
          caption={t('login.submit')}
          enabled={maySubmit}
          working={submitting}
          submit
        />
        {requestForgotPin != null && (
          <Center>
            <ClearButton
              caption={t('login.forgot-pin')}
              onTap={onForgotPinTap}
              align='center'
              padding='horizontal'
              dim
            />
          </Center>
        )}
      </VBox>
    )
  }

  function renderOtherLoginOptions() {
    if (otherLoginOptions == null) { return null }

    return (
      <>
        {emailAndPin && (
          <HBox classNames={$.otherLoginOptionsHeader} justify='center'>
            <Label small bold dim>
              {t('login.other')}
            </Label>
          </HBox>
        )}
        <VBox>
          {otherLoginOptions}
        </VBox>
      </>
    )
  }

  return render()

})

export default LoginForm

const useStyles = createUseStyles(theme => ({
  LoginForm: {},

  otherLoginOptionsHeader: {
    '&::before, &::after': {
      content:    '""',
      width:      80,
      height:     1,
      background: theme.colors.fg.light.dimmer,
    },

    '&::before': {marginRight: layout.padding.inline.l},
    '&::after':  {marginLeft: layout.padding.inline.l},
  },
}))