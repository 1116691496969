import * as layout from '../../layout'
import { ComponentBranding } from '../components'
import { BrandingGuideDelegate } from '../types'

export default class CardBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: CardBranding | null,
  ) {
    super(delegate,parent, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
    })

    this.header = new ComponentBranding(delegate, parent?.header ?? null, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
    })
  }

  public header: ComponentBranding

}