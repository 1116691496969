import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, ThemeProvider } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import DrawerCommonLinks from './DrawerCommonLinks'
import { useDrawer } from './DrawerContext'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'

export interface Props {
  classNames?: React.ClassNamesProp
}

const Drawer = observer('Drawer', (props: Props) => {

  const {isOpen, close} = useDrawer()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const {project}     = projectStore
  const isDesktop     = useMediaQuery({minWidth: screenMinWidths.desktop})
  const drawerEnabled = project?.feature('drawer')?.enabled && !isDesktop

  React.useEffect(() => {
    if (!drawerEnabled) {
      close()
    }
  }, [drawerEnabled, close])

  const handleOutsideClick = React.useCallback((event: MouseEvent) => {
    if (!isOpen || containerRef.current == null) { return }

    if (event.target instanceof Node && !containerRef.current.contains(event.target)) {
      close()
    }
  }, [isOpen, close])


  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick)
    }
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [isOpen, handleOutsideClick])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <VBox flex classNames={[$.Drawer, props.classNames]} ref={containerRef}>
          <DrawerHeader/>
          <VBox flex justify='bottom'>
            <DrawerCommonLinks/>
            <DrawerFooter/>
          </VBox>
        </VBox>
      </ThemeProvider>
    )
  }

  return render()

})

export default Drawer

export const drawerWidth = 300

const useStyles = createUseStyles(theme => ({
  Drawer: {
    width: drawerWidth,
    backgroundColor: theme.colors.bg.dark.alt,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
  },
}))