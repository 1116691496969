import React from 'react'
import { useTimer } from 'react-timer'
import I18n from 'i18next'
import { cleanTextValue } from 'ytil'
import { Spinner, SVG } from '~/ui/components'
import { invokeFieldChangeCallback, useFieldChangeCallback } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import TextField, { Props as TextFieldProps } from './TextField'

export interface Props extends Omit<TextFieldProps, 'value'> {
  search?:  string | null
  onSearch: (query: string | null) => void | Promise<void>

  initialSearch?: string | null

  minimumQueryLength?: number
  searching?: boolean

  debounce?:          number
  searchWhileTyping?: boolean
}

const SearchField = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {

  const {
    search,
    onSearch,
    minimumQueryLength,
    searching,
    debounce,
    searchWhileTyping = true,
    placeholder = I18n.t('placeholders:search'),
    selectOnFocus = true,
    initialSearch,
    ...rest
  } = props

  const [value, setValue] = React.useState<string>(search ?? initialSearch ?? '')
  const [lastQuery, setLastQuery] = React.useState<string | null>(search ?? initialSearch ?? null)
  const prevInitialSearchRef = React.useRef<string>()

  const timer = useTimer()

  const prevSearch = usePrevious(search)
  React.useEffect(() => {
    if (prevSearch === undefined || search === undefined) { return }
    if (search === prevSearch) { return }

    const nextValue = cleanTextValue(search) ?? ''
    if (nextValue === value) { return }

    setValue(nextValue)
  }, [prevSearch, search, value])

  React.useEffect(() => {
    if (initialSearch == null) { return }

    // Only work when it's changing.
    const prevInitialSearch = prevInitialSearchRef.current
    if (prevInitialSearch === initialSearch) { return }
    prevInitialSearchRef.current = initialSearch

    // Don't update the value if not necessary.
    if (initialSearch === value) { return }
    setValue(initialSearch)
  }, [initialSearch, value])

  //------
  // Searching

  const performSearch = React.useCallback(async (value: string) => {
    let query = cleanTextValue(value, true)

    if (minimumQueryLength != null && query != null && query.length < minimumQueryLength) {
      query = null
    }
    if (query === lastQuery) { return }
    setLastQuery(query)

    await onSearch(query)
  }, [lastQuery, minimumQueryLength, onSearch])

  const debounceSearch = React.useCallback((timeout: number) => {
    timer.clearAll()

    timer.setTimeout(() => {
      performSearch(value)
    }, timeout)
  }, [performSearch, timer, value])

  const prevValue = usePrevious(value)
  React.useEffect(() => {
    if (value !== prevValue && searchWhileTyping) {
      if (debounce) {
        debounceSearch(debounce)
      } else {
        performSearch(value)
      }
    }
  })

  //------
  // Callbacks

  const onChange = useFieldChangeCallback(React.useCallback((text: string, partial?: boolean) => {
    setValue(text)
    invokeFieldChangeCallback(props.onChange, text, partial)
  }, [props.onChange]))

  const onCommit = React.useCallback((text: string, event: React.SyntheticEvent) => {
    performSearch(value)
    props.onCommit?.(text, event)

    // Note - search fields should never cause a form to be submitted.
    event.preventDefault()
  }, [performSearch, value, props])

  const onClear = React.useCallback(() => {
    performSearch('')
    props.onClear?.()
  }, [performSearch, props])

  const onCancel = React.useCallback((event: React.SyntheticEvent) => {
    setValue('')
    props.onCancel?.(event)
  }, [props])

  //------
  // Rendering

  function render() {
    return (
      <TextField
        {...rest}
        ref={ref}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        selectOnFocus={selectOnFocus}
        autoComplete='off'

        accessoryLeft={<SVG name='search' size={layout.icon.m} dim/>}
        accessoryRight={searching && <Spinner size={12}/>}
        showClearButton='notempty'

        onCommit={onCommit}
        onClear={onClear}
        onCancel={onCancel}
      />
    )
  }

  return render()

})

export default SearchField