import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrCourseItemStatus } from '~/models'
import { memo } from '~/ui/component'
import { ColorBullet } from '~/ui/components'
import { useStyling } from '~/ui/styling'

export interface Props {
  status?: BeWizrCourseItemStatus | null
}

const BeWizrCourseProgressBullet = memo('BeWizrCourseProgressBullet', (props: Props) => {

  const {status} = props

  const [t] = useTranslation('bewizr')

  const {colors} = useStyling()

  const color = React.useMemo(() => {
    switch (status) {
      case 'completed':
        return colors.semantic.positive
      case 'in_progress':
        return colors.semantic.warning
      case 'failed':
        return colors.semantic.warning
      default:
        return colors.grayScale.light[2]
    }
  }, [status, colors])

  //------
  // Rendering

  function render() {
    return (
      <ColorBullet
        color={color}
        caption={t(`courses.items.status.${status ?? 'not_started'}`)}
        hollow={status == null || status === 'in_progress'}
        large
      />
    )
  }


  return render()

})

export default BeWizrCourseProgressBullet