import React from 'react'
import { forwardRef } from '~/ui/component'
import { Form, FormProps, isSuccessResult } from '~/ui/form'
import { assignRef, useChainedCallback } from '~/ui/hooks'
import { FormContext, useForm } from './FormContext'
import { InlineFormContext } from './InlineFormContext'
import { FormModel } from './types'

export type Props<M extends FormModel> = FormProps<M>

interface InlineForm {
  setEditingField: (name: string | null) => any
}

const _InlineForm = <M extends FormModel>(props: Props<M>, ref: React.Ref<InlineForm>) => {

  const {formRef, ...rest} = props

  const [editingField, setEditingField] = React.useState<string | null>(null)

  const ownFormRef = React.useRef<FormContext<M>>(null)

  const connectForm = React.useCallback((form: FormContext<M> | null) => {
    assignRef(formRef, form)
    assignRef(ownFormRef, form)
  }, [formRef])

  React.useImperativeHandle(ref, () => ({
    setEditingField,
  }), [])

  //------
  // Rendering

  function render() {
    return (
      <Form formRef={connectForm} {...rest} afterSubmit={afterSubmit}>
        <InlineFormContent
          editingField={editingField}
          setEditingField={setEditingField}
          children={props.children}
        />
      </Form>
    )
  }

  const afterSubmit = useChainedCallback(props.afterSubmit, (upstream, result, model) => {
    if (isSuccessResult(result)) {
      setEditingField(null)
    }
    upstream?.(result, model)
  }, [])

  return render()

}

interface InlineFormContentProps {
  editingField:    string | null
  setEditingField: (field: string | null) => any
  children?:       React.ReactNode
}

const InlineFormContent = (props: InlineFormContentProps) => {

  const {editingField, setEditingField, children} = props
  const {submit, reset} = useForm()

  const startEditing = React.useCallback((field: string) => {
    if (field === editingField) { return }
    if (editingField != null) {
      submit().then(result => {
        if (result?.status === 'ok') {
          setEditingField(field)
          reset()
        }
      })
    } else {
      setEditingField(field)
      reset()
    }
  }, [editingField, reset, setEditingField, submit])

  const stopEditing = React.useCallback(() => {
    reset()
    setEditingField(null)
  }, [reset, setEditingField])

  const context = React.useMemo((): InlineFormContext => ({
    editingField,
    startEditing,
    stopEditing,
  }), [editingField, startEditing, stopEditing])

  //------
  // Rendering

  function render() {
    return (
      <InlineFormContext.Provider value={context}>
        {children}
      </InlineFormContext.Provider>
    )
  }

  return render()

}

const InlineForm = forwardRef('InlineForm', _InlineForm)
export default InlineForm