import { CollectionFetchResponse, Endpoint } from 'mobx-document'
import gcAPI from '~/apis/gc'
import FetchError from '../../lib/mobx-document/src/FetchError'
import AuthResetAccountDocument from './AuthResetAccountDocument'
import { AuthResetAccount } from './types'

export default class AuthResetAccountEndpoint extends Endpoint<AuthResetAccountDocument, AuthResetAccountParams> {

  public async performFetch(params: AuthResetAccountParams): Promise<CollectionFetchResponse<AuthResetAccount> | null> {
    const response = await gcAPI().get('auth-reset/preflight', {params})
    if (response.status !== 200) {
      return {
        error: new FetchError(response.status),
      }
   } else {
      return {
        data: response.data.accounts,
        meta: {},
      }
    }
  }

}

export interface AuthResetAccountParams {
  token: string
}