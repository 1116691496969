import * as layout from '../../layout'
import { ComponentBrandingBase } from '../components'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'

export default class OAuthButtonBranding extends ComponentBrandingBase<OAuthButtonSpec> {
  constructor(
    delegate: BrandingGuideDelegate,
    parent:   OAuthButtonBranding | null,
    defaults: Partial<OAuthButtonSpec> = {},
  ) {
    super(delegate, parent, {
      background: 'alt',
      shape:      {rounded: layout.radius.m},
      border:     'dark-thin',
      depth:       0,
      height:      40,
      ...defaults,
    })
  }

  public readonly previewSize = {width: 240, height: 60}

}

export interface OAuthButtonSpec extends BrandedComponentSpec {
  height: number
}