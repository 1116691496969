import React from 'react'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import { useStyling } from '~/ui/styling'
import { memo } from '../component'
import Tooltip from './Tooltip'

export interface Props {
  color:       Color | string
  caption?:    string
  small?:      boolean
  large?:      boolean
  hollow?:     boolean
  classNames?: React.ClassNamesProp
}

const ColorBullet = memo('ColorBullet', (props: Props) => {

  const {color, caption, small, large, hollow} = props

  const $ = useStyles()

  const {colors} = useStyling()

  const classNames: React.ClassNamesProp = [
    $.ColorBullet,
    $[small ? 'small' : large ? 'large' : 'normal'],
    props.classNames,
  ]

  const style: React.CSSProperties = {
    backgroundColor: hollow ? colors.bg.alt.toString() : color.toString(),
    borderColor:     color.toString(),
  }

  function render() {
    if (caption == null) {
      return renderContent()
    }
    return (
      <Tooltip small renderTooltip={caption}>
        {renderContent()}
      </Tooltip>
    )
  }

  function renderContent() {
    return (
      <div
        classNames={classNames}
        style={style}
      />
    )
  }

  return render()

})

export default ColorBullet

export const sizes = {
  small:  12,
  normal: 16,
  large:  20,
}

const useStyles = createUseStyles({
  ColorBullet: {
    borderRadius: '50%',
    borderWidth:   3,
    borderStyle:   'solid',
  },

  small: {
    width:  sizes.small,
    height: sizes.small,
  },

  normal: {
    width:  sizes.normal,
    height: sizes.normal,
  },

  large: {
    width:  sizes.large,
    height: sizes.large,
  },
})