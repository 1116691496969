import React from 'react'
import { useTranslation } from 'react-i18next'
import { Post } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, TextBlock, VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { layout } from '~/ui/styling'
import { findLinks } from '~/ui/util'
import CommentForm from './CommentForm'
import CommentFormModel from './CommentFormModel'
import CommentHeader from './CommentHeader'
import { useNewsService } from './NewsContext'

export interface Props {
  postID?:       string
  comment:       Post
  interactive?:  boolean

  editing?:         boolean
  requestEdit?:     (comment: Post) => any
  requestStopEdit?: (comment: Post) => any

  requestReply?: (comment: Post) => any
}

const CommentView = memo('CommentView', (props: Props) => {

  const {
    postID,
    comment,
    interactive,
    editing,
    requestEdit,
    requestReply,
    requestStopEdit,
  } = props

  const [t] = useTranslation('news')

  const service = useNewsService()
  const formModel = React.useMemo(() => {
    if (!editing) { return null }
    if (comment.root == null) { return null }

    return new CommentFormModel(service, comment.root, comment)
  }, [comment, editing, service])

  const reply = React.useCallback(() => {
    requestReply?.(comment)
  }, [comment, requestReply])

  const cancelEdit = React.useCallback(() => {
    requestStopEdit?.(comment)
  }, [comment, requestStopEdit])

  const hideOnSubmitSuccess = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      requestStopEdit?.(comment)
    }
  }, [comment, requestStopEdit])

  const links = React.useMemo(
    () => findLinks(comment.body ?? ''),
    [comment],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.xs}>
        <CommentHeader
          postID={postID}
          comment={comment}
          interactive={interactive}
          requestEdit={requestEdit}
        />
        {!editing && renderMain()}
        {editing && renderForm()}
      </VBox>
    )
  }

  function renderMain() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderBody()}
        {requestReply != null && (
          <ClearButton
            icon='reply'
            onTap={reply}
            small
          />
        )}
      </HBox>
    )
  }

  function renderForm() {
    if (formModel == null) { return null }

    return (
      <CommentForm
        model={formModel}
        requestCancel={cancelEdit}
        afterSubmit={hideOnSubmitSuccess}
      />
    )
  }

  function renderBody() {
    if (comment.body == null) { return null }

    return (
      <VBox flex>
        <span>
          <TextBlock links={links} markup tag='span'>
            {comment.body}
          </TextBlock>
          {comment.edited && (
            <Label tag='span' small dim>
              {` ${t('edited')}`}
            </Label>
          )}
        </span>
      </VBox>
    )
  }

  return render()

})

export default CommentView
