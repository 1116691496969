
import React from 'react'
import { createTheming, createUseStyles as ReactJSS_createUseStyles } from 'react-jss'
import Color from 'color'
import { merge } from 'lodash'
import { useStyling } from './StylingContext'
import { Theme } from './Theme'

const ThemingContext = React.createContext<Theme>(Theme.default)
const theming        = createTheming(ThemingContext)

export interface ThemeProviderProps {
  dark?:      boolean
  light?:     boolean
  dim?:       boolean
  branded?:   boolean
  primary?:   boolean
  secondary?: boolean
  contrast?:  Color | 'primary' | 'secondary'
  debugName?: string
  overrides?: DeepPartial<Theme>
  children?:  React.ReactNode
}

export function ThemeProvider(props: ThemeProviderProps) {
  const {guide} = useStyling()
  const parent  = useTheme()

  const theme = React.useMemo(() => {
    let theme = parent

    if (props.light) {
      theme = Theme.create(guide, 'light')
    }
    if (props.dark) {
      theme = Theme.create(guide, 'dark')
    }
    if (props.contrast) {
      const contrastColor =
        props.contrast === 'primary' ? guide.colors.semantic.primary :
        props.contrast === 'secondary' ? guide.colors.semantic.secondary :
        props.contrast

      theme = Theme.create(guide, guide.colors.isDark(contrastColor) ? 'dark' : 'light')
    }
    if (props.dim) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.fg.dim,
          link:   theme.fg.dim,
        },
      }
    }
    if (props.primary) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.colors.semantic.primary,
          dim:    theme.colors.semantic.primary.alpha(0.6),
          dimmer: theme.colors.semantic.primary.alpha(0.3),
        },
      }
    }
    if (props.secondary) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.colors.semantic.secondary,
          dim:    theme.colors.semantic.secondary.alpha(0.6),
          dimmer: theme.colors.semantic.secondary.alpha(0.3),
        },
      }
    }
    if (props.branded) {
      const tintColor = theme.colors.isDark(theme.colors.semantic.primary)
        ? theme.colors.semantic.primary
        : theme.colors.semantic.secondary

      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: tintColor,
          dim:    tintColor.alpha(0.6),
        },
      }
    }
    if (props.overrides != null) {
      theme = merge({}, theme, props.overrides)
    }

    return theme
  }, [guide, parent, props.branded, props.contrast, props.dark, props.dim, props.light, props.overrides, props.primary, props.secondary])

  return (
    <theming.ThemeProvider theme={theme}>
      {props.children}
    </theming.ThemeProvider>
  )
}

export const useTheme = theming.useTheme
export { theming }

type CreateUseStylesFunc =
  <C extends string = string>(
    styles: Record<C, AnyObject> | ((theme: Theme) => Record<C, AnyObject>)
  ) => (data?: unknown) => Record<C, string>

export const createUseStyles: CreateUseStylesFunc =
  styles => ReactJSS_createUseStyles(styles, {theming: theming})