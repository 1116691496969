export const INLINE_STYLES: Record<string, [string, string]> = {
  BOLD:   ['**', '**'],
  ITALIC: ['_', '_'],
  CODE:   ['`', '`'],
}

export const ENTITIES: Record<string, [RegExp, (match: RegExpMatchArray) => Record<string, any>]> = {
  VARIABLE: [/^\{\{(.+?)\}\}/, match => ({variable: match[1]})],
  LINK:     [/^\[(.*?)\]\((.+?)\)/, match => ({caption: match[1], url: match[2]})],
  IMAGE:    [/^!\[(.*?)\]\((.+?)\)/, match => ({alt: match[1], src: match[2]})],
}

export const ESCAPE = [...'*_`['.split(''), '![']
export const BLOCK_ESCAPE = ['-', '#']

export const BLOCK_STYLES: Record<string, {prefix: string, regexp: RegExp}> = {
  'header-one':   {prefix: '# ',      regexp: /^# (.*)/},
  'header-two':   {prefix: '## ',     regexp: /^## (.*)/},
  'header-three': {prefix: '### ',    regexp: /^### (.*)/},
  'header-four':  {prefix: '#### ',   regexp: /^#### (.*)/},
  'header-five':  {prefix: '##### ',  regexp: /^##### (.*)/},
  'header-six':   {prefix: '###### ', regexp: /^###### (.*)/},
  blockquote:     {prefix: '> ',      regexp: /^> (.*)/},
}