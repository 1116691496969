import './styles.css'
import React from 'react'
import { useTimer } from 'react-timer'
import BrowserNotificationManager from './BrowserNotificationManager'
import { useRefMap } from './hooks'
import hostRef from './hostRef'
import NotificationContainer from './NotificationContainer'
import { NotificationComponentType, NotificationKey, NotificationOptions } from './types'

export interface Props {
  defaultLifetime?: number
  interval?:        number

  padding?:   number | number[]
  className?: string
}

interface NotificationEntry {
  key:     NotificationKey
  element: React.ReactElement<any>
  options: NotificationOptions
}

interface NotificationHost {
  show<P extends {}>(Notification: NotificationComponentType<P>, key: NotificationKey, props: P, options?: NotificationOptions): void
}

const NotificationHost = (props: Props) => {

  const {
    padding         = 20,
    defaultLifetime = 5000,
    interval        = 1000,
  } = props

  const [pending, setPending] = React.useState<NotificationEntry[]>([])
  const [visible, setVisible] = React.useState<NotificationEntry[]>([])

  const pendingRef = React.useRef<NotificationEntry[]>(pending)
  const visibleRef = React.useRef<NotificationEntry[]>(visible)

  const notificationRefs = useRefMap<NotificationKey, NotificationContainer>()

  const browserNotifications = React.useMemo(
    () => new BrowserNotificationManager(),
    [],
  )

  //------
  // Shift

  const shiftTimer = useTimer()
  const shiftingRef = React.useRef<boolean>(false)

  const shift = React.useCallback(() => {
    const stack   = [...pendingRef.current]
    const current = stack.shift() ?? null

    setPending(pendingRef.current = stack)

    if (current != null) {
      setVisible(visibleRef.current = [
        ...visibleRef.current,
        current,
      ])
    }

    if (current != null) {
      shiftTimer.setTimeout(shift, interval)
    } else {
      shiftingRef.current = false
    }
  }, [interval, shiftTimer])

  const ensureShifting = React.useCallback(() => {
    if (!shiftingRef.current) {
      shiftingRef.current = true
      shiftTimer.setTimeout(shift, 0)
    }
  }, [shift, shiftTimer])

  //------
  // Show & hide

  const hide = React.useCallback((key: NotificationKey) => {
    const notification = notificationRefs.get(key)
    notification?.hide()
  }, [notificationRefs])

  const show = React.useCallback(<P extends {}>(Notification: NotificationComponentType<P>, key: NotificationKey, props: P, options: NotificationOptions = {}) => {
    const element = (
      <Notification
        key={key}
        {...props}
        hide={hide.bind(null, key)}
      />
    )

    const notification: NotificationEntry = {key, element, options}

    const pendingIndex = pendingRef.current.findIndex(it => it.key === key)
    const visibleIndex = visibleRef.current.findIndex(it => it.key === key)

    if (pendingIndex < 0 && visibleIndex < 0) {
      // The notification is not there yet. Add it to the end of the pending list.
      setPending(pendingRef.current = [...pendingRef.current, notification])
      ensureShifting()
    } else if (visibleIndex < 0) {
      // The notification is pending, but not visible. Replace it in the pending list.
      pendingRef.current = [...pendingRef.current]
      pendingRef.current.splice(pendingIndex, 1, notification)
      setPending(pendingRef.current)
      ensureShifting()
    } else {
      // The notification is visible. Replace it in the visible list.
      visibleRef.current = [...visibleRef.current]
      visibleRef.current.splice(visibleIndex, 1, notification)
      setVisible(visibleRef.current)
    }

    const documentVisible = document.visibilityState === 'visible'
    if (!documentVisible && options.browser != null) {
      browserNotifications.show(options.browser)
    }
  }, [browserNotifications, ensureShifting, hide])

  const remove = React.useCallback((key: NotificationKey) => {
    setVisible(visibleRef.current = visibleRef.current.filter(
      notification => notification.key !== key,
    ))
    setPending(pendingRef.current = pendingRef.current.filter(
      notification => notification.key !== key,
    ))
  }, [])

  React.useImperativeHandle(hostRef, () => ({
    show,
  }))

  //------
  // Rendering

  function render() {
    const className = [
      'react-notifications--NotificationHost',
      props.className,
    ].filter(Boolean).join(' ')

    return (
      <div className={className}>
        {visible.map(renderNotification)}
      </div>
    )
  }

  function renderNotification(notification: NotificationEntry) {
    const {key, element, options} = notification

    const lifetime = options.lifetime ?? defaultLifetime

    return (
      <NotificationContainer
        key={key}
        ref={notificationRefs.for(key)}
        padding={padding}
        lifetime={lifetime}
        notificationKey={key}
        requestRemove={remove}
        children={element}
      />
    )
  }

  return render()

}

export default NotificationHost