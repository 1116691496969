import React from 'react'
import I18n from 'i18next'
import {
  ClearButton,
  HBox,
  Label,
  ModalDialog,
  PushButton,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import hostRef from './hostRef'
import { InputBoxOptions, InputBoxProps } from './types'

function InputBox(props: InputBoxProps) {

  const {
    title,
    prompt,
    initialText = '',
    placeholder,
    validate = () => true,
    okCaption     = I18n.t('buttons:ok'),
    cancelCaption = I18n.t('buttons:cancel'),
    mono,
    resolve,
  } = props

  const [isOpen, , close] = useBoolean(true)

  const [text, setText] = React.useState<string>(initialText)
  const [error, setError] = React.useState<string | null>(null)

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ModalDialog
        open={isOpen}
        requestClose={close}
        onDidClose={requestPop}
        header={renderHeader()}
      >
        {renderBody()}
        {renderButtons()}
      </ModalDialog>
    )
  }

  function renderHeader() {
    return (
      <HBox>
        <Label h2>
          {title}
        </Label>
      </HBox>
    )
  }

  function renderBody() {
    return (
      <VBox flex='both' gap={layout.padding.s} classNames={$.body}>
        <TextBlock caption>
          {prompt}
        </TextBlock>
        <TextField
          value={text}
          onChange={setText}
          placeholder={placeholder}
          autoFocus
          selectOnFocus={true}
          mono={mono}
          onCommit={resolveAndClose}
        />
        {error != null && (
          <Label caption tiny color={colors.semantic.negative}>
            {error}
          </Label>
        )}
      </VBox>
    )
  }

  function renderButtons() {
    return (
      <HBox classNames={$.buttons} justify='right' gap={layout.padding.s}>
        {renderOkButton()}
        {renderCancelButton()}
      </HBox>
    )
  }

  function renderOkButton() {
    return (
      <PushButton
        icon='check'
        caption={okCaption}
        onTap={resolveAndClose}
      />
    )
  }

  function renderCancelButton() {
    return (
      <ClearButton
        icon='cross'
        caption={cancelCaption}
        onTap={cancel}
        dim
      />
    )
  }

  const resolveAndClose = React.useCallback(() => {
    const valid = validate(text)
    if (valid !== true) {
      setError(valid)
    } else {
      resolve(text)
      close()
    }
  }, [close, resolve, text, validate])

  const cancel = React.useCallback(() => {
    resolve(null)
    close()
  }, [close, resolve])

  const requestPop = React.useCallback(() => {
    hostRef.current?.pop()
  }, [])

  return render()

}

const InputBoxStatic = {
  show(props: InputBoxOptions): Promise<string | null> {
    return new Promise(resolve => {
      if (hostRef.current == null) {
        console.error("InputBox: No host registered")
      }
      hostRef.current?.push(<InputBox {...props} resolve={resolve}/>)
    })
  },
}

Object.assign(InputBox, InputBoxStatic)
export default InputBox as typeof InputBox & typeof InputBoxStatic

const useStyles = createUseStyles({
  body: {
    width: 480,

    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.m[size]],
    })),
  },

  buttons: {
    borderBottomLeftRadius:  layout.radius.m,
    borderBottomRightRadius: layout.radius.m,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
})