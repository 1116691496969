import React from 'react'
import { useTranslation } from 'react-i18next'
import { Post } from '~/models'
import { PostsEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { Center, ClearButton, HBox, Label, List, Spinner, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import CommentForm from './CommentForm'
import CommentFormModel from './CommentFormModel'
import CommentView from './CommentView'
import { useNewsService } from './NewsContext'

export interface Props {
  post:     Post
  endpoint: PostsEndpoint
}

const CommentList = observer('CommentList', (props: Props) => {

  const {post, endpoint} = props

  const service = useNewsService()

  const total     = endpoint.meta?.total ?? null
  const remaining = total == null ? null : total - endpoint.data.length
  const hasMore   = remaining != null && remaining > 0

  React.useEffect(() => {
    endpoint.resetAndFetch({limit: 3})
  }, [endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore({limit: 6})
  }, [endpoint])

  const [editingComment, setEditingComment] = React.useState<Post | null>(null)

  const commentsOpen = post.feed.commentsOpen

  const newCommentFormModel = React.useMemo(
    () => new CommentFormModel(service, post.id, null),
    [post.id, service],
  )

  const editComment = React.useCallback((comment: Post) => {
    setEditingComment(comment)
  }, [])

  const stopEditingComment = React.useCallback((comment: Post) => {
    if (comment.id !== editingComment?.id) { return }
    setEditingComment(null)
  }, [editingComment?.id])

  // TODO: Re-enable when we have threaded views.
  // const replyTo = React.useCallback((comment: Post) => {
  //   newCommentFormModel.replyTo(comment)
  // }, [newCommentFormModel])

  const [t] = useTranslation('news')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List
        data={endpoint.data}
        keyExtractor={keyExtractor}
        renderItem={renderComment}
        EmptyComponent={renderEmpty}
        HeaderComponent={renderHeader}
        FooterComponent={renderFooter}
        itemGap={layout.padding.inline.m}
        flex={false}
      />
    )
  }

  const keyExtractor = React.useCallback(
    (comment: Post) => comment.id,
    [],
  )

  const renderComment = React.useCallback((comment: Post) => {
    return (
      <CommentView
        postID={post.id}
        comment={comment}

        editing={editingComment?.id === comment.id}
        requestEdit={editComment}
        requestStopEdit={stopEditingComment}

        // TODO: Enable this later, we don't have threaded views yet.
        // requestReply={replyTo}
      />
    )
  }, [editComment, editingComment?.id, post.id, stopEditingComment])

  const renderCommentsClosed = React.useCallback(() => (
    <HBox justify='center' gap={layout.padding.inline.m} padding={layout.padding.s}>
      <SVG
        aria-label='notice'
        size={layout.icon.s}
        name='info'
      />
      <Label bold small>{t('comments.closed')}</Label>
    </HBox>
  ), [t])

  const renderHeader = React.useCallback(() => {
    // If we're editing a comment, show the form inline.
    if (editingComment != null) { return null }

    return (
      <VBox classNames={$.listHeader}>
        {commentsOpen ? (
           <CommentForm model={newCommentFormModel}/>
        ) : (
          renderCommentsClosed()
        )}
      </VBox>
    )
  }, [$.listHeader, newCommentFormModel, commentsOpen, editingComment, renderCommentsClosed])

  const renderFooter = React.useCallback(() => {
    if (!hasMore) { return null }

    return (
      <HBox>
        <ClearButton
          caption={t('comments.fetch_more', {count: remaining})}
          onTap={fetchMore}
        />
       </HBox>
    )
  }, [fetchMore, hasMore, remaining, t])

  const renderEmpty = React.useCallback(() => {
    if (endpoint.fetchStatus === 'fetching') {
      return (
        <Center>
          <Spinner size={12}/>
        </Center>
      )
    } else {
      return null
    }
  }, [endpoint.fetchStatus])

  return render()

})

export default CommentList

const useStyles = createUseStyles(theme => ({
  listHeader: {
    ...layout.responsiveProp({
      paddingBottom: layout.padding.s,
    }),
  },
}))