import React from 'react'
import { useTranslation } from 'react-i18next/'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { observer } from 'mobx-react'
import { BackButton, Badge, Chip, ClearButton, HBox, Label, VBox } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { useDebounced } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { Avatar } from '../media'
import { useChat } from './ChatContext'
import { ChatChannelController } from './types'

export interface Props {
  controller: ChatChannelController
}

const ChannelHeader = observer((props: Props) => {

  const {chat, showChannelInfo, setShowChannelInfo} = useChat()

  const hasMoreChannels = chat != null && chat.channels.length > 1

  const {controller} = props
  const channel = controller.channel

  const hasChannelInfo = channel.type !== 'participant'

  const backToChannels = React.useCallback(() => {
    chat?.requestSwitchChannel(null)
  }, [chat])

  const toggleInfo = React.useCallback(() => {
    setShowChannelInfo(!showChannelInfo)
  }, [setShowChannelInfo, showChannelInfo])

  const [t] = useTranslation('chat')

  const totalUnreadCount = React.useMemo(() => {
    return chat?.channelPreviews.reduce((total, preview) => total + preview.unreadCount, 0)
  }, [chat?.channelPreviews])

  //------
  // Typing

  const actualTypingNames = controller.typingNames
  const [typingNames, setTypingNames] = React.useState<string[]>(actualTypingNames)
  const typing = useDebounced(actualTypingNames.length > 0, 200)

  React.useEffect(() => {
    if (typing) { return }
    setTypingNames(actualTypingNames)
  }, [actualTypingNames, typing])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.m} classNames={$.channelHeader}>
        {(hasMoreChannels || showChannelInfo) && renderBackButton()}
        {renderTitle()}
      </HBox>
    )
  }

  function renderTitle() {
    if (showChannelInfo) {
      return (
        <Label bold>
          {t('channel_info.title')}
        </Label>
      )
    }
    else {
      return (
        <HBox flex justify='space-between'>
          <VBox>
            <HBox flex='shrink' gap={layout.padding.inline.m}>
              <Avatar
                source={channel.image}
                firstName={channel.name}
                size={avatarSize}
              />
              {renderMain()}
              {renderModerator()}
            </HBox>
          </VBox>
          {hasChannelInfo &&
            <ClearButton
              icon='info'
              onTap={toggleInfo} />
          }
        </HBox>
      )
    }
  }

  function renderModerator() {
    if (!channel.isModerator) { return null }

    return (
      <Chip small>
        {t('moderator')}
      </Chip>
    )
  }

  function renderMain() {
    return (
      <VBox flex='shrink'>
        <Label bold>
          {channel.name}
        </Label>
        {renderTyping()}
      </VBox>
    )
  }

  function renderTyping() {
    return (
      <TransitionGroup>
        {typing && (
          <CSSTransition timeout={animation.durations.short}>
            <Label classNames={$.typing} small dim italic>
              {t('typing', {names: typingNames, count: typingNames.length})}
            </Label>
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }

  function renderBackButton() {
    return (
      <HBox classNames={$.backButtonWrapper} align='middle'>
        <BackButton
          caption={showChannelInfo ? channel.name : null}
          onTap={showChannelInfo ? toggleInfo : backToChannels}
          padding='horizontal'
        />
        {renderBadge()}
      </HBox>
    )
  }

  function renderBadge() {
    if (totalUnreadCount == null) { return null }
    return (
      <Badge
        value={totalUnreadCount}
        small
        classNames={$.unreadBadge}
      />
    )
  }

  return render()

})

export default ChannelHeader

export const height = layout.barHeight.l

export const avatarSize = {
  width:  30,
  height: 30,
}

export const unreadBadgeSize = badgeSize.small

const useStyles = createUseStyles(theme => ({
  channelHeader: {
    height,
    padding: [
      layout.padding.inline.l,
      layout.padding.inline.l,
    ],
  },

  backButtonWrapper: {
    position: 'relative',
  },

  unreadBadge: {
    position: 'relative',
    top: unreadBadgeSize.height * -0.5,
  },

  typing: {
    '&.enter-active': {
      transition: animation.transition('height', animation.durations.short, animation.timings.popOut),
    },
    '&.exit-active': {
      transition: animation.transition('height', animation.durations.short, animation.timings.popIn),
    },
    '&.enter:not(.enter-active), &.exit-active': {
      height: 0,
    },
    '&.enter-active, &.exit:not(.exit-active)': {
      height: theme.fonts.small.size * theme.fonts.small.lineHeight,
    },
  },
}))