import { SortableItem } from './data'

interface Measurements {
  itemRects: DOMRect[]
}

const ITEM_HEIGHTS = new Map<SortableItem, number>()
const LISTS        = new Map<HTMLElement, HTMLElement | null>()
const MEASUREMENTS = new Map<HTMLElement, Measurements>()

export function registerItem(item: SortableItem, element: HTMLElement) {
  ITEM_HEIGHTS.set(item, element.offsetHeight)
}

export function unregisterItem(item: SortableItem) {
  ITEM_HEIGHTS.delete(item)
}

export function registerList(list: HTMLElement, placeholder: HTMLElement | null) {
  LISTS.set(list, placeholder)
}

export function unregisterList(list: HTMLElement) {
  LISTS.delete(list)
}

export function calculateMeasurements() {
  for (const [list, placeholder] of LISTS) {
    MEASUREMENTS.set(list, measureList(list, placeholder))
  }
}

export function cleanUpMeasurements() {
  MEASUREMENTS.clear()
}

function measureList(list: HTMLElement, placeholder: HTMLElement | null): Measurements {
  const measurements: Measurements = {
    itemRects: [],
  }

  for (const child of Array.from(list.children)) {
    const element = child as HTMLElement
    if (element === placeholder) { continue }

    measurements.itemRects.push(element.getBoundingClientRect() as DOMRect)
  }

  measurements.itemRects.sort((a, b) => a.top - b.top)

  return measurements
}

export function calculateDropIndex(list: HTMLElement, top: number, height: number) {
  const measurements = MEASUREMENTS.get(list)
  if (measurements == null) { return 0 }

  return findDropIndex(top, height, measurements.itemRects)
}

export function getItemHeight(item: SortableItem) {
  return ITEM_HEIGHTS.get(item) ?? 0
}

function findDropIndex(top: number, height: number, rects: LayoutRect[]) {
  for (const [index, rect] of rects.entries()) {
    if (top + height / 2 < rect.top) {
      return index - 1
    }
  }

  return rects.length
}