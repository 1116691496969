import { ComponentBrandingBase } from '../components'
import { BrandingGuideDelegate } from '../types'
import { AppHeaderBrandingSpec } from './types'

export default class AppHeaderBranding extends ComponentBrandingBase<AppHeaderBrandingSpec> {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: AppHeaderBranding | null,
  ) {
    super(delegate, parent, {
      background: 'primary',
      shape:      'rectangle',
      border:     'none',
      depth:      5,

      title:    null,
      subtitle: null,
    })

    this.variant('ios')
    this.variant('android')
  }

  public resolveTitle(flags: Record<string, boolean> = {}) {
    return this.resolve('title', flags)
  }

  public resolveSubtitle(flags: Record<string, boolean> = {}) {
    return this.resolve('subtitle', flags)
  }

  public previewSize = {
    width:  375,
    height: 64,
  }

}