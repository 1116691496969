import Socket from './Socket'
import { SendOptions, SendResponse } from './types'

/**
 * Utility class to quickly remove all listeners within a certain scope.
 */
export default class ScopedSocket {

  constructor(
    public readonly socket: Socket,
  ) {}

  //------
  // Prefixing

  public prefix: string | null = null

  private prefixEvent(event: string) {
    return `${this.prefix ?? ''}${event}`
  }


  //------
  // Emitting

  public emit(event: string, ...args: any[]) {
    if (this.socket == null) { return Promise.resolve() }
    this.socket.emit(this.prefixEvent(event), ...args)
  }

  public send<A extends any[] = any[], R = any>(event: string, ...args: A): Promise<SendResponse<R>> {
    return this.socket.send(this.prefixEvent(event), ...args)
  }

  public sendWithOptions<A extends any[] = any[], R = any>(options: SendOptions, event: string, ...args: A): Promise<SendResponse<R>> {
    return this.socket.sendWithOptions(options, this.prefixEvent(event), ...args)
  }

  public fetch<A extends any[] = any[], R = any>(event: string, ...args: A): Promise<SendResponse<R>> {
    return this.socket.fetch(this.prefixEvent(event), ...args)
  }

  //------
  // Listening

  private listeners: Array<[string, (...args: any[]) => any]> = []

  public addEventListener(event: string, listener: (...args: any[]) => any) {
    const prefixedEvent = this.prefixEvent(event)
    this.listeners.push([prefixedEvent, listener])
    this.socket.addEventListener(prefixedEvent, listener)

    return () => {
      this.listeners = this.listeners.filter(l => l[0] !== prefixedEvent && l[1] !== listener)
      this.socket.removeEventListener(prefixedEvent, listener)
    }
  }

  public on = this.addEventListener

  public unbindScope() {
    for (const [event, listener] of this.listeners) {
      this.socket.removeEventListener(event, listener)
    }
    this.listeners = []
  }

}
