import React from 'react'
import { useTranslation } from 'react-i18next'
import { connectionsStore } from '~/stores'
import { observer } from '~/ui/component'
import { Chip, Dimple, HBox, Label, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import ConnectionList from './ConnectionList'

const ConnectionRequestsHeader = observer('ConnectionRequestsHeader', () => {

  const [t] = useTranslation('connections')

  const endpoint     = connectionsStore.requests
  const requestCount = connectionsStore.requestCount

  React.useEffect(() => {
    endpoint.fetchIfNeeded()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    if (requestCount === 0) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <HBox gap={layout.padding.inline.m}>
          <Label flex='shrink' h3>
            {t('requests.caption')}
          </Label>
          <Chip>{requestCount}</Chip>
        </HBox>
        <ConnectionList
          endpoint={connectionsStore.requests}
          requests={true}
        />
        <Dimple horizontal/>
        <div/>
      </VBox>
    )
  }

  return render()

})

export default ConnectionRequestsHeader