import React from 'react'
import Timer from './Timer'

export default function useTimer() {
  const timer = React.useMemo(() => new Timer(), [])
  React.useEffect(() => {
    return () => { timer.dispose() }
  }, [timer])

  return timer
}