import React from 'react'
import { memo } from '~/ui/component'
import { Center, Label, SVG, Tappable } from '~/ui/components'
import { svgExists, SVGName } from '~/ui/components/SVG'
import { createUseStyles, shadows } from '~/ui/styling'

export interface Props {
  language: Language
  small?:   boolean

  onTap?: () => any
  renderFallback?: () => React.ReactNode
  interactive?: boolean
}

export interface Language {
  code: string
  name_en: string
  name_loc: string
}

const LanguageFlag = memo('LanguageFlag', (props: Props) => {

  const {
    language,
    small,
    onTap,
    renderFallback,
    interactive = onTap != null,
  } = props

  const flagSVGName = `lang.${language.code}` as SVGName
  const hasFlag = svgExists(flagSVGName)

  const $ = useStyles()

  function render() {
    if (onTap) {
      return <Tappable onTap={onTap} children={renderFlag()} noFeedback/>
    } else {
      return renderFlag()
    }
  }

  function renderFlag() {
    return (
      <Center classNames={[$.LanguageFlag, {small, interactive, hasFlag}]}>
        {hasFlag ? (
          <SVG name={flagSVGName} classNames={$.flag}/>
        ) : renderFallback != null ? (
          renderFallback()
        ) : (
          <Label small mono box>
            {language.code}
          </Label>
        )}
      </Center>
    )
  }

  return render()
})

export default LanguageFlag

export const flagSize = {
  normal: 24,
  small:  16,
}

const useStyles = createUseStyles(theme => ({
  LanguageFlag: {
    width:        flagSize.normal,
    height:       flagSize.normal,

    '&.small': {
      width:        flagSize.small,
      height:       flagSize.small,
    },

    '&.hasFlag': {
      borderRadius: 1000,
      borderWidth:  2,
    },

    '&.interactive:hover': {
      boxShadow: shadows.depth(2),
    },
  },

  flag: {
    width:        flagSize.normal,
    height:       flagSize.normal,
    borderRadius: flagSize.normal / 2,
    borderWidth:  2,

    '$LanguageFlag.small &': {
      width:        flagSize.small,
      height:       flagSize.small,
      borderRadius: flagSize.small / 2,
      borderWidth:  1,
    },

    border:       ['solid', theme.colors.fg.light.normal],
    overflow:     'hidden',

    '& > svg': {
      width:  '100%',
      height: '100%',
    },
  },
}))