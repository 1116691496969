import { action, makeObservable, observable, runInAction } from 'mobx'
import socket from 'socket.io-react'

export default class GroupSignup {

  constructor(
    public readonly signupID: string,
    public readonly groupID: string,
  ) {
    makeObservable(this)
  }

  @observable
  public status: GroupSignupStatus = 'idle'

  @observable
  public signedUp: boolean | null = null

  @observable
  public maxSignups: boolean | null = null

  @observable
  public closed: boolean | null = null

  @observable
  public pastDeadline: boolean | null = null

  @observable
  public placesLeft: number | null = null

  @observable
  public translations: Record<string, Record<string, string>> = {}

  @action
  public async check() {
    this.status  = 'checking'
    this.signedUp = null

    const response = await socket.fetch('group-signup:preflight', this.signupID, this.groupID)
    runInAction(() => {
      if (!response.ok) {
        this.status = response.error
        return
      }

      const result      = response.body as PreflightResponse
      this.signedUp     = result.signedUp
      this.maxSignups   = result.maxSignups
      this.closed       = result.closed
      this.pastDeadline = result.pastDeadline
      this.placesLeft   = result.placesLeft
      this.translations = result.translations
      this.status       = 'ready'
    })
  }

  @action
  public async signUp() {
    if (this.signedUp) { return }

    this.status = 'signing-up'

    const response = await socket.send('group-signup:sign-up', this.signupID, this.groupID)
    runInAction(() => {
      if (!response.ok) {
        this.status = response.error
        return
      }

      const result    = response.body as SignUpResponse
      this.signedUp   = result.status === 'ok'
      this.placesLeft = result.placesLeft ?? null
      this.status     = 'ready'
    })
  }

  @action
  public async signOut() {
    if (!this.signedUp) { return }

    this.status = 'signing-out'

    const response = await socket.send('group-signup:sign-out', this.groupID)
    runInAction(() => {
      if (!response.ok) {
        this.status = response.error
        return
      }

      const result    = response.body as SignOutResponse
      this.signedUp   = false
      this.placesLeft = result.placesLeft
      this.status     = 'ready'
    })
  }

}

export type GroupSignupStatus = 'idle' | 'checking' | 'ready' | 'signing-up' | 'signing-out' | Error

export interface PreflightResponse {
  signedUp:     boolean
  placesLeft:   number | null
  closed:       boolean
  maxSignups:   boolean
  pastDeadline: boolean
  translations: Record<string, Record<string, string>>
}

export interface SignUpResponse {
  status:      'already-in-group' | 'full' | 'max-signups' | 'ok'
  placesLeft?: number | null
}

export interface SignOutResponse {
  status:     'not-in-group' | 'ok'
  placesLeft: number | null
}