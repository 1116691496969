import { KeyStroke } from './types'
import { isMac, isWin, keyToKeyCode } from './util'

export default class KeyCombination {

  constructor(
    public readonly descriptor: string,
    public readonly strokes: KeyStroke[],
  ) {}

  private canonizedDescriptor!: string

  //------
  // Parsing

  public static parseCombinations(descriptor: string): KeyCombination[] {
    const combinations = descriptor.split('|')
    return combinations
      .map(combo => this.parseCombination(combo.trim()))
      .filter(Boolean) as KeyCombination[]
  }

  public static parseCombination(descriptor: string): KeyCombination | null {
    const keys = descriptor.toLowerCase().split(',').map(k => k.trim()).filter(Boolean)
    if (keys.length === 0) {
      console.warn(`Found empty key stroke: \`${descriptor}\``)
      return null
    }

    const combination = new KeyCombination(descriptor, [])
    const canonizedParts: string[] = []

    for (let key of keys) {
      const keyStroke: KeyStroke = {
        key:      null,
        shiftKey: false,
        altKey:   false,
        ctrlKey:  false,
        metaKey:  false,
      }

      // Support shortcuts.
      key = key.replace(/^\^⌘(\w)$/, `shortcut+$1`)
      key = key.replace(/^\^(\w)$/, `ctrl+$1`)
      key = key.replace(/^⎇(\w)$/, `opt+$1`)
      key = key.replace(/^⌘(\w)$/, `cmd+$1`)

      // Replace + with =, and - with _ to not get confused when splitting.
      key = key.replace(/[-+]\+/, '+=')
      key = key.replace(/[-+]-/, '+_')

      for (const part of key.split(/[-+]/)) {
        if (/(alt|opt(ion))/.test(part)) {
          keyStroke.altKey = true
          canonizedParts.push('option')
        } else if (/(shift)/.test(part)) {
          keyStroke.shiftKey = true
          canonizedParts.push('shift')
        } else if (/(meta|win|mac|cmd)/.test(part)) {
          keyStroke.metaKey = true
          canonizedParts.push('meta')
        } else if (/(ctrl|control)/.test(part)) {
          keyStroke.ctrlKey = true
          canonizedParts.push('control')
        } else if (/(shrt|short)/.test(part)) {
          keyStroke[isMac() ? 'metaKey' : 'ctrlKey'] = true
          canonizedParts.push('short')
        } else {
          // This is the actual keystroke.
          if (keyStroke.key != null) {
            // Cannot add two keystrokes.
            console.warn(`Found double keystroke: \`${descriptor}\``)
            return null
          } else {
            keyStroke.key = keyToKeyCode(part)
          }
          canonizedParts.push(part === '_' ? '–' : part.toUpperCase())
        }
      }

      combination.strokes.push(keyStroke)
    }

    combination.canonizedDescriptor = canonizedParts.join('+')
    return combination
  }

  //------
  // Matching

  public match(strokes: KeyStroke[]) {
    if (strokes.length !== this.strokes.length) { return false }
    for (let i = 0; i < strokes.length; i++) {
      if (!this.matchStroke(strokes[i], this.strokes[i])) {
        return false
      }
    }
    return true
  }

  private matchStroke(a: KeyStroke, b: KeyStroke) {
    if (a.key?.toUpperCase() !== b.key?.toUpperCase()) { return false }
    if (a.shiftKey !== b.shiftKey) { return false }
    if (a.altKey !== b.altKey) { return false }
    if (a.ctrlKey !== b.ctrlKey) { return false }
    if (a.metaKey !== b.metaKey) { return false }

    return true
  }

  //------
  // Description

  public description(options: DescriptionOptions = {}) {
    const {format = 'short-mac'} = options

    const translate = (part: string) => {
      if (format === 'long' || !isMac()) {
        switch (part) {
          case 'shift':   return 'Shift'
          case 'control': return 'Ctrl'
          case 'option':  return isMac() ? 'Opt' : 'Alt'
          case 'short':   return isMac() ? 'Cmd' : isWin() ? 'Win' : 'Meta'
          default:        return part
        }
      } else {
        switch (part) {
          case 'shift':   return '⬆'
          case 'control': return '^'
          case 'option':  return '⌥'
          case 'meta':    return '⌘'
          case 'short':   return '⌘'
          default:        return part
        }
      }
    }

    if (format === 'long') {
      const separator = isMac() ? '+' : '-'
      return this.canonizedDescriptor.split('+').map(translate).join(separator)
    } else {
      return this.canonizedDescriptor.split('+').map(translate).join('')
    }
  }

}

export interface DescriptionOptions {
  format?: 'long' | 'short' | 'short-mac'
}