import React from 'react'
import I18next from 'i18next'
import { clamp } from 'lodash'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, ClearButton, VBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { useLightbox } from './LightboxContext'
import {
  CustomLightboxContent,
  ImageLightboxContent,
  LightboxContent,
  VideoLightboxContent,
} from './types'

const Lightbox = memo('Lightbox', () => {

  const {contents, selectedIndex, setSelectedIndex, hide} = useLightbox()
  const content: LightboxContent | null = contents[selectedIndex] ?? null

  const clampIndex = React.useCallback((index: number) => {
    return clamp(index, 0, contents.length - 1)
  }, [contents.length])

  const cycleNext = React.useCallback(() => {
    let nextIndex = selectedIndex + 1
    if (nextIndex > contents.length - 1) {
      nextIndex = 0
    }
    setSelectedIndex(clampIndex(nextIndex))
  }, [clampIndex, contents.length, selectedIndex, setSelectedIndex])

  const cyclePrev = React.useCallback(() => {
    let nextIndex = selectedIndex - 1
    if (nextIndex < 0) {
      nextIndex = contents.length - 1
    }
    setSelectedIndex(clampIndex(nextIndex))
  }, [clampIndex, contents.length, selectedIndex, setSelectedIndex])

  React.useEffect(() => {
    setSelectedIndex(clampIndex(selectedIndex))
  }, [clampIndex, selectedIndex, setSelectedIndex])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <Center classNames={$.lightbox}>
          <div classNames={$.shim} onClick={hide}/>
          {renderContent()}
          {contents.length > 0 && renderCycleButtons()}
          {renderCloseButton()}
        </Center>
      </ThemeProvider>
    )
  }

  function renderContent() {
    if (content == null) { return null }

    switch (content.type) {
      case 'image': return renderImage(content)
      case 'video': return renderVideo(content)
      default: return renderCustom(content)
    }
  }

  function renderImage(content: ImageLightboxContent) {
    return (
      <ImageView
        classNames={$.media}
        source={content.image}
        alt={content.alt ?? undefined}
        objectFit='contain'
      />
    )
  }

  function renderVideo(content: VideoLightboxContent) {
    return (
      <video
        classNames={$.media}
        src={content.url}
        controls
      />
    )
  }

  function renderCustom(content: CustomLightboxContent) {
    return content.render()
  }

  function renderCloseButton() {
    return (
      <VBox classNames={$.closeButton} padding={layout.padding.m}>
        <ClearButton
          icon='cross'
          caption={I18next.t('buttons:close')}
          onTap={hide}
          padding='both'
          large
        />
      </VBox>
    )
  }

  function renderCycleButtons() {
    return (
      <>
        <Center classNames={$.prevButton} padding={layout.padding.m}>
          <ClearButton
            icon='chevron-left'
            onTap={cyclePrev}
            padding='both'
            large
          />
        </Center>
        <Center classNames={$.nextButton} padding={layout.padding.m}>
          <ClearButton
            icon='chevron-right'
            onTap={cycleNext}
            padding='both'
            large
          />
        </Center>
      </>
    )
  }

  return render()

})

export default Lightbox

const useStyles = createUseStyles({
  lightbox: {
    ...layout.overlay,
    position:   'fixed',
    zIndex:     layout.z.lightbox,
    background: colors.shim.dark,
  },

  shim: {
    ...layout.overlay,
  },

  media: {
    position: 'relative',
    ...layout.responsive(size => ({
      maxWidth:  `calc(100% - ${layout.padding.m[size]}px)`,
      maxHeight: `calc(100% - ${layout.padding.m[size]}px)`,
    })),
  },

  closeButton: {
    position: 'absolute',
    top:      0,
    right:    0,
  },

  prevButton: {
    position: 'absolute',
    top:      0,
    bottom:   0,
    left:    0,
  },

  nextButton: {
    position: 'absolute',
    top:      0,
    bottom:   0,
    right:    0,
  },
})