import React from 'react'
import { memo } from '~/ui/component'
import { FormContext } from './FormContext'
import { useFormDataSource } from './hooks'

export interface Props {
  dataSource: any
  children:   React.ReactNode
}

const FormDataSourceProvider = memo('FormDataSourceProvider', (props: Props) => {

  const {dataSource, children} = props

  const upstream   = React.useContext(FormContext)
  const overrides  = useFormDataSource(dataSource, upstream)
  const downstream = React.useMemo(() => ({...upstream, ...overrides}), [overrides, upstream])

  return (
    <FormContext.Provider value={downstream}>
      {children}
    </FormContext.Provider>
  )

})

export default FormDataSourceProvider