import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { participantsStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import ParticipantProfile from '../profile/ParticipantProfile'

export interface ParticipantParams {
  id: string
}

export type Props = RouteComponentProps<ParticipantParams>

const ParticipantScreen = observer('ParticipantScreen', (props: Props) => {

  const {id} = props.match.params

  const document    = participantsStore.participants.document(id)
  const fetchStatus = document.fetchStatus
  const participant = document.data

  const [t] = useTranslation('participants')

  const fetch = React.useCallback(async () => {
    await document.fetch()
  }, [document])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  //------
  // Rendering

  function render() {
    return (
      <Scroller>
        <AppLayoutConfig
          title={participant?.name ?? null}
          fetchStatus={fetchStatus}
        />
        <VBox flex='both' padding={layout.padding.inline.l} align='center'>
          {renderContent()}
        </VBox>
      </Scroller>
    )
  }

  function renderContent() {
    if (participant == null) {
      return renderEmpty()
    } else {
      return (
        <ParticipantProfile
          participant={participant}
        />
      )
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t('participant_not_found', {id})}
        flex
      />
    )
  }

  return render()

})

export default ParticipantScreen