import React from 'react'

/**
 * Returns true exactly once after signal has gone from false to true, and resets back to false immediately after.
 *
 * @param signal The signal.
 */
export function useOnceFlag(signal: boolean) {
  const [canceled, setCanceled] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (signal) {
      setCanceled(true)
    } else {
      setCanceled(false)
    }
  }, [canceled, signal])

  return signal && !canceled
}