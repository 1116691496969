import React from 'react'
import documentScrollManager from './documentScrollManager'
import ScrollManager from './ScrollManager'

const ScrollManagerContext = React.createContext<ScrollManager>(documentScrollManager)

export const ScrollManagerProvider = ScrollManagerContext.Provider

export function useScrollManager() {
  return React.useContext(ScrollManagerContext)
}