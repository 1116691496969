import { AxiosResponse } from 'axios'
import { SendResponse } from 'socket.io-react'

//------
// Form submission responses

export function submitResultForResponse<T = any>(response: SendResponse<any>, data?: (body: any) => T): SubmitResult<T> {
  if (response.ok) {
    return {
      status: 'ok',
      data:   data?.(response.body) as T ?? response.body?.data,
      meta:   response.body?.meta,
    }
  } else if (response.error.status === 422) {
    return {
      status: 'invalid',
      errors: (response.error.extra?.errors ?? []).map(validationErrorToFormError),
    }
  } else {
    return {
      status: 'error',
      error:  response.error,
    }
  }
}

export function submitResultForAxiosResponse<T = any>(response: AxiosResponse<any>, data?: (body: any) => T): SubmitResult<T> {
  if (response.status === 200 || response.status === 201) {
    return {
      status: 'ok',
      data:   data?.(response.data) as T ?? response.data,
      meta:   response.data?.meta,
    }
  } else if (response.status === 422) {
    return {
      status: 'invalid',
      errors: (response.data.error.errors ?? []).map(validationErrorToFormError),
    }
  } else {
    return {
      status: 'error',
      error:  new Error(`HTTP ${response.status}`),
    }
  }
}

export function validationErrorToFormError(error: any) {
  return {
    field:   error?.path ?? error?.field ?? error?.source?.pointer ?? null,
    message: error?.message ?? error?.detail ?? '',
  }
}

export type SubmitResult<T = never> = SubmitSuccess<T> | SubmitInvalid | SubmitError

export interface SubmitSuccess<T = never> {
  status: 'ok'
  data:   T
  meta?:  Record<string, any>
}

export interface SubmitInvalid {
  status: 'invalid'
  errors: FormError[]
}

export type FormError = {field: string, message: string}

export interface SubmitError {
  status: 'error'
  error:  Error
}