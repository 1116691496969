import React from 'react'
import { useTranslation } from 'react-i18next'
import { some } from 'lodash'
import {
  Answer,
  AnswerResult,
  ChoiceFeedback,
  feedbackValueToCaption,
  TalliedCount,
  TalliedResult,
} from '~/models'
import { memo } from '~/ui/component'
import { BarChart, HBox, Label, SVG, VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { AnswerOutcomeRenderUtil } from '../../tasks/ChallengeTaskAnswerOutcome'
import TextAnswerOutcome from '../text/TextAnswerOutcome'

export interface Props {
  feedback:       ChoiceFeedback
  answer:         Answer
  result:         AnswerResult | null
  correctAnswers: any[]
  renderUtil:     AnswerOutcomeRenderUtil
}

const ChoiceAnswerOutcome = memo('ChoiceAnswerOutcome', (props: Props) => {

  const {feedback, answer, result, correctAnswers, renderUtil} = props

  const theme = useTheme()

  const [t] = useTranslation('challenges')

  const isOwnAnswer = React.useCallback((item: TalliedCount) => {
    return item.answer === feedbackValueToCaption(feedback, answer.answer)
  }, [answer, feedback])

  const isCorrectAnswer = React.useCallback((item: TalliedCount) => {
    return some(correctAnswers, answer => item.answer === feedbackValueToCaption(feedback, answer))
  }, [correctAnswers, feedback])

  //------
  // Rendering

  function render() {
    return (
      <VBox>
        {result?.type === 'tallied' ? (
          renderAsBarChart(result)
        ) : (
          <TextAnswerOutcome {...props}/>
        )}
      </VBox>
    )
  }

  function renderAsBarChart(result: TalliedResult) {
    return (
      <>
        {renderUtil.renderCaption(t('task.results'), true)}
        {renderUtil.renderLine()}
        <VBox padding={layout.padding.inline.m}>
          <BarChart<TalliedCount>
            data={result.counts}
            axisLabelForPoint={point => point.answer.toString()}
            decorateLabel={decorateLabel}
          >
            <BarChart.Series<TalliedCount>
              name='counts'
              getValue={point => point.count}
            />
          </BarChart>
        </VBox>
      </>
    )
  }

  const decorateLabel = React.useCallback((item: TalliedCount, label: React.ReactNode) => {
    const correct = isCorrectAnswer(item)
    const own     = isOwnAnswer(item)
    const color   = correct ? theme.colors.semantic.positive : own ? theme.colors.semantic.negative : theme.fg.normal

    return (
      <HBox gap={layout.padding.inline.s}>
        <VBox flex='shrink'>
          <Label tiny bold color={color}>
            {item.answer}
          </Label>
        </VBox>
        {correct ? (
          <SVG name='correct' color={color}/>
        ) : own ? (
          <SVG name='incorrect' color={color}/>
        ) : (
          null
        )}
      </HBox>
    )
  }, [isCorrectAnswer, isOwnAnswer, theme.colors.semantic.negative, theme.colors.semantic.positive, theme.fg.normal])

  return render()

})

export default ChoiceAnswerOutcome