import { makeObservable, observable } from 'mobx'
import { authenticationStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class ForgotPinFormModel implements FormModel {

  constructor(
    initialEmail: string | null = null,
  ) {
    this.email = initialEmail ?? ''
    makeObservable(this)
  }

  @observable
  public email: string

  //------
  // Submit

  public get maySubmit() {
    return this.email.trim().length > 0
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await authenticationStore.requestAuthReset(this.email)
  }

}