import React from 'react'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { BrandedLogo, HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {}

const DrawerHeader = observer('DrawerHeader', () => {

  const project = projectStore.project

  const feature = project?.feature('drawer')

  const header   = feature?.options.header ?? 'hidden'
  const showLogo = header === 'logo-and-name' || header === 'logo-only'
  const showName = header === 'logo-and-name' || header === 'name-only'

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (project == null) { return null }
    if (header === 'hidden') { return null }

    return (
      <VBox classNames={$.DrawerHeader}>
        <HBox padding={layout.padding.s} gap={layout.padding.s} justify='center'>
          {showLogo && <BrandedLogo/>}
          {showName && (
            <VBox flex>
              <Label h2>
                {project.name}
              </Label>
            </VBox>
          )}
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default DrawerHeader

const useStyles = createUseStyles(theme => ({
  DrawerHeader: {
    borderBottomWidth: 1,
    borderBottomColor: theme.fg.normal.alpha(0.1),
  },
}))