import hostRef from './hostRef'
import {
  Notification,
  NotificationComponentType,
  NotificationInitFn,
  NotificationShowFn,
} from './types'

const registry = new Set<Notification<any>>()

function show<P extends {}>(Component: NotificationComponentType<P>): NotificationShowFn<P> {
  return (key, props, options) => {
    const host = hostRef.current
    if (host == null) {
      console.warn("No <NotificationHost/> element found, did you add one?")
      return
    }

    host.show(Component, key, props, options)
  }
}

export function registerNotification<P extends {}>(Component: NotificationComponentType<P>, init: NotificationInitFn<P>) {
  const deinit = init(show(Component))
  const entry: Notification<P> = {
    Component,
    init,
    deinit,
  }
  registry.add(entry)
  return () => { registry.delete(entry) }
}

export function notifications() {
  return Array.from(registry)
}