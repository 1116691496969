import { isFunction } from 'lodash'
import { DateTime } from 'luxon'
import { datetime, ModelSerialization } from './serialization'

export abstract class Model {

  constructor(
    public readonly raw: AnyObject,
  ) {}

  public copy() {
    return (this.constructor as any).deserialize(this.raw)
  }

  public id!: string

  @datetime()
  public createdAt!: DateTime

  @datetime()
  public updatedAt!: DateTime

  //------
  // Serialization

  public static deserialize<M extends Model>(this: Constructor<M>, raw: AnyObject): M {
    const model = new (this as any)(raw) as M
    model.deserialize()
    return model
  }

  public modify<M extends Model>(modifier: (raw: any) => any): M {
    const nextRaw = modifier(this.raw)
    return Model.deserialize.call(this.constructor as Constructor<any>, nextRaw) as M
  }

  protected deserialize() {
    const model = this as any
    const serialization = ModelSerialization.for(model)

    let raw = this.raw
    if ('beforeDeserialize' in model && isFunction(model.beforeDeserialize)) {
      raw = model.beforeDeserialize(raw)
    }

    serialization.deserializeInto(model, raw)

    if ('afterDeserialize' in model && isFunction(model.afterDeserialize)) {
      model.afterDeserialize()
    }
  }

}