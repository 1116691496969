import React from 'react'
import ReactPopup, { Props as ReactPopupProps } from 'react-popup'
import Color from 'color'
import { memo } from '~/ui/component'
import { VBoxProps } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { flexStyle } from './layout/styles'

export interface Props extends ReactPopupProps {
  flex?:            VBoxProps['flex']
  minSize?:         number
  backgroundColor?: Color
}

const Popup = memo('Popup', (props: Props) => {

  const {
    children,
    renderContent,
    align = 'vertical',
    flex,
    minSize,
    gap = layout.padding.inline.s,
    style,
    targetClassNames,
    backgroundColor,
    ...rest
  } = props

  const $ = useStyles()

  const renderContentWithTheme = React.useMemo(() => {
    if (renderContent == null) {
      return undefined
    }

    return () => (
      <ThemeProvider light>
        {renderContent()}
      </ThemeProvider>
    )
  }, [renderContent])

  const layoutStyle = React.useMemo(() => {
    const minSizeProp      = align === 'horizontal' ? 'minWidth' : 'minHeight'
    return {
      [minSizeProp]:      minSize,
    }
  }, [align, minSize])

  const inlineStyle = {
    ...flexStyle(flex),
    ...layoutStyle,
    ...style,
    backgroundColor: backgroundColor?.toString(),
  }

  return (
    <ReactPopup
      {...rest}
      tag='popup'
      zIndex={layout.z.popup}
      closeOnClickOutside={true}
      renderContent={renderContentWithTheme}
      align={align}
      classNames={[$.popup, props.classNames]}
      style={inlineStyle}
      gap={gap}
      targetClassNames={[$.target, targetClassNames]}
      screenPadding={layout.padding.inline.m}
      children={children}
    />
  )

})

export default Popup

export const borderRadius = layout.radius.s

const useStyles = createUseStyles(theme => ({
  target: {
    ...layout.flex.column,
  },

  popup: {
    background:   theme.colors.bg.light.alt,
    borderRadius: borderRadius,
    boxShadow:    shadows.popup,
  },
}))