import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrCourseItem } from '~/models'
import { memo } from '~/ui/component'
import { Accordion, Label, List, ListBar, ListItem, SVG, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import BeWizrCourseProgressBullet from './BeWizrCourseProgressBullet'

export interface Props {
  item:         BeWizrCourseItem
  child?:       boolean
  expanded?:    boolean
  interactive?: boolean
}

const BeWizrCourseListItem = memo('BeWizrCourseListItem', (props: Props) => {

  const {item, child, expanded, interactive = true} = props

  const [t] = useTranslation('bewizr')

  const [accordionOpen, openAccordion, closeAccordion] = useBoolean(expanded)

  const isLocked = item.isLocked || !interactive

  const items = item.items ?? []

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const itemProps = {
      classNames: [$.BeWizrCourseListItem, {child}],
      image:      renderImage(),
      caption:    renderCaption(),
    }

    if (child) {
      return (
        <ListItem
          href={isLocked ? undefined : item.links.elearning}
          {...itemProps}
        />
      )
    } else if (items.length > 0) {
      return (
        <Accordion
          open={accordionOpen}
          requestClose={closeAccordion}
          requestOpen={openAccordion}
          children={renderContent()}
          {...itemProps}
        />
      )
    } else {
      return (
        <ListBar
          href={isLocked ? undefined : item.links.elearning}
          {...itemProps}
        />
      )
    }
  }

  function renderImage() {
    return (
      <VBox classNames={$.image}>
        {isLocked ? (
          <SVG
            name='lock'
            size={layout.icon.l}
            dimmer
            aria-label={t('courses.items.locked')}
          />
        ) : (
          <BeWizrCourseProgressBullet
            status={item.stats?.status}
          />
        )}
      </VBox>
    )
  }

  function renderCaption() {
    return (
      <Label bold={!child} dimmer={!child && isLocked} dim={child && isLocked}>
        {item.title}
      </Label>
    )
  }

  function renderContent() {
    return (
      <List
        flex={false}
        itemGap={layout.padding.inline.m}
        data={items}
        renderItem={renderItem}
      />
    )
  }

  function renderItem(item: BeWizrCourseItem) {
    return (
      <BeWizrCourseListItem
        child
        item={item}
        interactive={interactive}
      />
    )
  }

  return render()

})

export default BeWizrCourseListItem

const useStyles = createUseStyles(theme => ({
  BeWizrCourseListItem: {
    '&.child': {
      backgroundColor: theme.bg.normal,
      padding: [layout.padding.inline.m, 0],
      borderRadius: 6,
    },
  },

  image: {
    width:        layout.icon.l,
    paddingRight: layout.padding.inline.m,
  },
}))