import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'
import ComponentBrandingBase from './ComponentBrandingBase'

export default class ComponentBranding extends ComponentBrandingBase<ComponentBrandingStyles> {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ComponentBrandingBase<ComponentBrandingStyles>,
    defaults?: Partial<ComponentBrandingStyles> & {previewSize?: Size}
  )
  constructor(
    delegate: BrandingGuideDelegate,
    parent: ComponentBrandingBase<ComponentBrandingStyles> | null,
    defaults: ComponentBrandingStyles & {previewSize?: Size}
  )
  constructor(
    delegate: BrandingGuideDelegate,
    parent: ComponentBrandingBase<ComponentBrandingStyles> | null,
    defaults: ComponentBrandingStyles & {previewSize?: Size},
  ) {
    super(delegate,parent, defaults)

    if (defaults?.previewSize != null) {
      this.previewSize = defaults.previewSize
    }
  }

  public previewSize: Size = {
    width:  160,
    height: 120,
  }

}

type ComponentBrandingStyles = BrandedComponentSpec