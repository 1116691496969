import React from 'react'
import { useTimer } from 'react-timer'
import { isFunction } from 'lodash'
import { forwardRef } from '~/ui/component'
import {
  Dimple,
  HBox,
  Popup,
  PopupProps,
  SVG,
  TextBlock,
  TextBlockProps,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { animation, colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  delay?:        number
  renderTooltip: React.ReactNode | (() => React.ReactNode)
  renderHeader?: React.ReactNode | (() => React.ReactNode)
  renderFooter?: React.ReactNode | (() => React.ReactNode)

  open?:        boolean
  enabled?:     boolean
  interactive?: boolean

  icon?:          SVGName
  align?:         PopupProps['align']
  preferredSide?: PopupProps['preferredSide']
  crossAlign?:    PopupProps['crossAlign']
  textAlign?:     TextBlockProps['align']

  onWillOpen?:  PopupProps['onWillOpen']
  onDidOpen?:   PopupProps['onDidOpen']
  onWillClose?: PopupProps['onWillClose']
  onDidClose?:  PopupProps['onDidClose']

  caption?:   TextBlockProps['caption']
  small?:     TextBlockProps['small']
  tiny?:      TextBlockProps['tiny']
  markup?:    TextBlockProps['markup']
  variables?: TextBlockProps['variables']

  fancyParagraphFormatting?: boolean

  targetClassNames?: React.ClassNamesProp
  flex?:             VBoxProps['flex']
  children?:         React.ReactNode
}

export type TooltipTrigger = 'tap' | 'hover'

interface Tooltip {
  show(): void
  hide(): void
}

const Tooltip = forwardRef('Tooltip', (props: Props, ref: React.Ref<Tooltip>) => {

  const {
    delay = 0,
    enabled = true,
    renderTooltip,
    renderHeader,
    renderFooter,
    children,
    icon,
    flex,
    textAlign = 'center',
    onWillOpen,
    onDidOpen,
    onWillClose,
    onDidClose,
    targetClassNames,
    interactive,
    caption = true,
    small,
    tiny,
    markup = true,
    variables = true,
    fancyParagraphFormatting = true,
  } = props

  const [openState, open, close] = useBoolean()
  const isOpen = (props.open ?? openState) && enabled

  const targetRef = React.useRef<HTMLDivElement>(null)

  const timer = useTimer()

  React.useImperativeHandle(ref, () => ({
    show: open,
    hide: close,
  }), [close, open])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (renderTooltip == null) { return renderTarget() }

    return (
      <Popup
        open={isOpen}
        renderContent={renderContent}
        targetClassNames={targetClassNames}
        flex={flex}
        align={props.align}
        preferredSide={props.preferredSide}
        crossAlign={props.crossAlign}
        classNames={[$.tooltip, {interactive}]}
        onWillOpen={onWillOpen}
        onDidOpen={onDidOpen}
        onWillClose={onWillClose}
        onDidClose={onDidClose}
        transitionName={$.transition}
        transitionDuration={transitionDuration}
        children={renderTarget()}
        closeOnClickOutside={false}
        backgroundColor={colors.shim.dark}
      />
    )
  }

  function renderTarget() {
    return (
      <VBox flex={flex} ref={targetRef}>
        {children}
      </VBox>
    )
  }

  function renderContent() {
    const header  = isFunction(renderHeader) ? renderHeader() : renderHeader
    const footer  = isFunction(renderFooter) ? renderFooter() : renderFooter
    const body    = isFunction(renderTooltip) ? renderTooltip() : renderTooltip

    return (
      <ThemeProvider contrast={colors.shim.dark}>
        <HBox>
          {icon && <SVG name={icon} size={layout.icon.l}/>}
          <VBox flex>
            {header && (
              <VBox classNames={$.header}>
                {header}
              </VBox>
            )}
            <VBox classNames={$.body}>
              {renderBody(body)}
            </VBox>
            {footer && (
              <VBox classNames={$.footer}>
                {footer}
              </VBox>
            )}
          </VBox>
        </HBox>
      </ThemeProvider>
    )
  }

  function renderBody(body: React.ReactNode) {
    if (!isReactText(body)) { return body }

    const paragraphs     = fancyParagraphFormatting ? `${body}`.split(/\n{2,}/) : []
    const firstParagraph = fancyParagraphFormatting ? paragraphs.shift() : body

    return (
      <VBox gap={layout.padding.inline.m}>
        {renderParagraph(firstParagraph, -1)}
        {paragraphs.length > 0 && <Dimple horizontal/>}
        {paragraphs.map((it, idx) => renderParagraph(it, idx, {align: 'left', tiny: true, dim: true, caption: false}))}
      </VBox>
    )
  }

  function renderParagraph(paragraph: React.ReactNode, index: number, props: Partial<TextBlockProps> = {}) {
    return (
      <TextBlock
        key={index}
        markup={markup}
        variables={variables}
        align={textAlign}
        caption={caption}
        small={small}
        tiny={tiny}
        convertNewlines
        children={paragraph}
        {...props}
      />
    )
  }

  //------
  // Mouse handling

  const onMouseEnter = React.useCallback((event: Event) => {
    if (event.target !== event.currentTarget) { return }

    if (delay > 0) {
      timer.clearAll()
      timer.setTimeout(open, delay)
    } else {
      open()
    }
  }, [delay, open, timer])

  const onMouseLeave = React.useCallback((event: Event) => {
    if (event.target !== event.currentTarget) { return }

    timer.clearAll()
    close()
  }, [close, timer])

  const setUpMouseHandlers = React.useCallback((target: HTMLDivElement) => {
    target.addEventListener('mouseenter', onMouseEnter)
    target.addEventListener('mouseleave', onMouseLeave)
  }, [onMouseEnter, onMouseLeave])

  const tearDownMouseHandlers = React.useCallback((target: HTMLDivElement) => {
    target.removeEventListener('mouseenter', onMouseEnter)
    target.removeEventListener('mouseleave', onMouseLeave)
  }, [onMouseEnter, onMouseLeave])

  React.useEffect(() => {
    const target = targetRef.current
    if (target == null) { return }
    if (props.open != null) { return }

    setUpMouseHandlers(target)
    return () => { tearDownMouseHandlers(target) }
  }, [props.open, setUpMouseHandlers, tearDownMouseHandlers])

  return render()

})

export default Tooltip

const transitionDuration = animation.durations.short

const useStyles = createUseStyles(theme => ({
  tooltip: {
    borderRadius:  layout.radius.m,
    maxWidth:      560,

    '&:not(.interactive)': {
      pointerEvents: 'none',
    },
  },

  body: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  header: {
    borderBottom: [1, 'solid', theme.fg.dim],
    padding:      [layout.padding.inline.s, layout.padding.inline.l],
  },

  footer: {
    borderTop: [1, 'solid', theme.fg.dim],
    padding:   [layout.padding.inline.s, layout.padding.inline.l],
  },

  transition: {
    ...animation.fadeFromAbove(transitionDuration, layout.padding.inline.m, 'open', 'close'),
  },
}))