import React from 'react'
import { DragDropContext } from './DragDropContext'
import DragDropMonitor from './DragDropMonitor'
import { DraggableItem, DragGestureHandlers, ElementConnect, ItemHandlers } from './types'
import { createItemLayout, getClientPoint, isRightMouse } from './util'

export function useDrag<T extends DraggableItem>(spec: UseDragSpec<T>): [ElementConnect, ElementConnect] {
  const {enabled = true, item, drag, axis = 'both'} = spec

  const elementRef = React.useRef<HTMLElement | null>(null)
  const handleRef  = React.useRef<HTMLElement | null>(null)

  /**
   * A monitor maintaining state throughout the operation.
   */
  const monitorRef = React.useRef<DragDropMonitor<T> | null>(null)

  const {
    setMonitor,
    getDropZones,
    setItemHandlers,
    start: context_start,
    end: context_end,
    enter,
    hover,
    leave,
    drop,
    snap,
  } = React.useContext(DragDropContext)

  // Use an effect to provide the context with the handler for this item.
  React.useEffect(() => {
    if (!enabled) { return }

    setItemHandlers(item, {
      enter: spec.enter,
      leave: spec.leave,
    })
    return () => { setItemHandlers(item, null) }
  }, [enabled, item, setItemHandlers, spec.enter, spec.leave])

  const start = React.useCallback((item: T, monitor: DragDropMonitor) => {
    spec.start?.(item, monitor)
    context_start(item, monitor)
  }, [context_start, spec])

  const end = React.useCallback((item: T, monitor: DragDropMonitor) => {
    spec.end?.(item, monitor)
    context_end(item, monitor)
  }, [context_end, spec])

  const configureMonitor = React.useCallback(() => {
    const monitor = monitorRef.current
    if (monitor == null) { return }

    monitor.axis                    = axis
    monitor.delegates.start         = start
    monitor.delegates.drag          = drag
    monitor.delegates.end           = end
    monitor.delegates.shake         = spec.shake
    monitor.delegates.getDropZones  = getDropZones
    monitor.delegates.enter         = enter
    monitor.delegates.hover         = hover
    monitor.delegates.leave         = leave
    monitor.delegates.snap          = snap
    monitor.delegates.drop          = drop
  }, [axis, drag, drop, end, enter, getDropZones, hover, leave, snap, spec.shake, start])

  React.useEffect(configureMonitor, [configureMonitor])

  //------
  // Start & stop events

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const monitor = monitorRef.current
    if (monitor == null) { return }

    const point = getClientPoint(event)
    if (point == null) { return }

    monitor.move(point)

    event.preventDefault()
  }, [])

  /**
   * Fired upon mouseup or touchend. Calls onDrop and resets everything.
   */
  const handleEnd = React.useCallback((event: MouseEvent | TouchEvent) => {
    const monitor = monitorRef.current
    if (monitor == null) { return }

    monitor.drop()
    event.preventDefault()
  }, [])

  const bind = React.useCallback(() => {
    document.addEventListener('mousemove', handleMove)
    document.addEventListener('touchmove', handleMove)
    document.addEventListener('mouseup', handleEnd)
    document.addEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove])

  const unbind = React.useCallback(() => {
    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove])

  /**
   * Fired upon mousedown or touchstart. Sets the start point and binds handlers.
   */
  const handleStart = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (isRightMouse(event)) { return }

    const element = elementRef.current
    if (!enabled || element == null) { return }

    const layout     = createItemLayout(element)
    const startPoint = getClientPoint(event)
    if (startPoint == null) { return }

    const monitor = new DragDropMonitor(item, element, layout, startPoint)

    monitorRef.current = monitor
    setMonitor(monitor)

    configureMonitor()
    bind()
    monitor.delegates.unbind = () => {
      unbind()
      monitorRef.current = null
      setMonitor(null)
    }

    event.preventDefault()
  }, [bind, configureMonitor, enabled, item, setMonitor, unbind])

  //------
  // Connect

  const connect = React.useCallback((element: HTMLElement | null) => {
    if (!enabled) { element = null }

    if (element === elementRef.current) { return }
    elementRef.current = element
  }, [enabled])

  const connectHandle = React.useCallback((handle: HTMLElement | null) => {
    if (!enabled) { handle = null }

    if (handle === handleRef.current) { return }

    if (handleRef.current) {
      handleRef.current.removeEventListener('mousedown', handleStart)
      handleRef.current.removeEventListener('touchstart', handleStart)
    }
    if (handle) {
      handle.addEventListener('mousedown', handleStart)
      handle.addEventListener('touchstart', handleStart)
    }

    handleRef.current = handle
  }, [enabled, handleStart])

  return [connect, connectHandle]
}

export interface UseDragSpec<T extends DraggableItem> extends DragGestureHandlers<T>, ItemHandlers<T> {
  /**
   * Set to `false` to disable this draggable.
   */
  enabled?: boolean

  /**
   * The axis along which can be dragged.
   */
  axis?: 'vertical' | 'horizontal' | 'both'

  /**
   * A data structure describing this draggable.
   */
  item: T

  /**
   * Called if the drag operation starts.
   */
  start?: (item: T, monitor: DragDropMonitor<T>) => void

  /**
   * Called while the item is being dragged.
   */
  drag?: (item: T, monitor: DragDropMonitor<T>) => void

  /**
   * Called if the drag operation has ended (either through drop or cancel).
   */
  end?: (item: T, monitor: DragDropMonitor<T>) => void

}