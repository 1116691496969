import React from 'react'
import { isPendingMessage, MessageListItem } from '~/models'
import renderWidget from '~/ui/app/widgets'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'widget'}
  inlineReply: MessageListItem | null
}

const WidgetBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const widget = isPendingMessage(message) ? null : message.widget
  const params = isPendingMessage(message) ? null : message.params

  //------
  // Rendering

  function render() {
    if (widget == null) { return null }

    return (
      <ChatBubble message={message} inlineReply={inlineReply}>
        {renderWidget(widget, params ?? {})}
      </ChatBubble>
    )
  }

  return render()

})

export default WidgetBubble