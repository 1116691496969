import Color from 'color'
import { SVGName } from '~/ui/components/SVG'
import { LabelProps } from '~/ui/components'

export type PopupMenuItem<T = any> = StandardMenuItem<T> | CustomMenuItem<T> | MenuSection

export interface MenuSection {
  section:          '-' | React.ReactNode
  textColor?:       Color
  backgroundColor?: Color
}

export interface StandardMenuItem<T = any, U = any> extends Omit<LabelProps, 'caption' | 'children'> {
  value?:           T
  icon?:            SVGName
  caption:          React.ReactNode
  detail?:          React.ReactNode
  accessory?:       React.ReactNode
  keyHint?:         string
  backgroundColor?: Color
  href?:            string
  enabled?:         boolean
  checked?:         boolean
  children?:        PopupMenuItem<U>[]
  onSelect?:        (value?: U) => void
}

export interface CustomMenuItem<T = any> {
  value?:    T
  render:    () => React.ReactNode
  href?:     string
  children?: PopupMenuItem[]
  onSelect?: () => void
}

export function isSection(item: PopupMenuItem): item is MenuSection {
  return 'section' in (item as any)
}

export function isStandardItem(item: PopupMenuItem): item is StandardMenuItem {
  return typeof item !== 'string'
}

export function isCustomItem(item: PopupMenuItem): item is CustomMenuItem {
  return 'render' in (item as any)
}