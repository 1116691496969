import { createUseStyles, fonts, layout } from '~/ui/styling'
import { themedBackgroundStyles } from '../styling/util/branding'

export default function createUseGlobalStyles(options: GlobalStylesOptions = {}) {
  const {transparent = false} = options

  return createUseStyles(theme => ({
    '@global': {
      'html': {
        height:   '100%',
      },

      'body': {
        height: '100%',
        margin: 0,
        ...layout.flex.column,

        ...fonts.responsiveFontStyle(theme.guide.fonts.body),
        ...!transparent && {
          ...themedBackgroundStyles(theme.guide, theme.guide.backgrounds.body),
        },
        color: theme.guide.backgrounds.body.theme === 'dark'
          ? theme.colors.fg.light.normal
          : theme.colors.fg.dark.normal,

        '-webkit-font-smoothing':  'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },

      '#root': {
        position: 'absolute',
        top:      0,
        left:     0,
        width:    '100%',
        height:   '100%',

        transformOrigin: [0, 0],
        ...layout.flex.column,

      },

      'h1, h2, h3': {
        fontSize: '100%',
        padding:  0,
        margin:   0,
      },

      'a, button': {
        color: 'inherit',
        textDecoration: 'none',
      },

      'figure': {
        margin: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        marginInlineStart: 0,
        marginInlineEnd: 0,
      },

      'img': {
        display:   'block',
        maxWidth:  '100%',
        maxHeight: '100%',
        overflow:  'hidden',
      },

      '.ModalPortal--root': {
        zIndex: layout.z.modal,
      },

      '*': {
        boxSizing: 'border-box',
        flex:      '0 0 auto',
        minWidth:  0,
        minHeight: 0,
      },
    },
  }))

}

export interface GlobalStylesOptions {
  transparent?: boolean
}