import React from 'react'
import { useTranslation } from 'react-i18next'
import { authenticationStore } from '~/stores'
import { memo } from '~/ui/component'
import { ClearButton, ConfirmBox, Label, Tappable, VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import LanguageSwitcher from './LanguageSwitcher'

const AppSideBarFooter = memo('AppSideBarFooter', () => {

  const [t] = useTranslation('auth')

  const theme = useTheme()

  const logOut = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('logout.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    authenticationStore.logOut()
  }, [t])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <LanguageSwitcher/>
        {renderLinks()}
        {renderLogoutButton()}
      </VBox>
    )
  }

  function renderLinks() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <AppSideBarFooterLink
          caption={t('app:nav.privacy')}
          href='https://groundcontrol.app/privacy'
        />
        <AppSideBarFooterLink
          caption={t('app:nav.terms')}
          href='https://groundcontrol.app/terms'
        />
      </VBox>
    )
  }

  function renderLogoutButton() {
    return (
      <ClearButton
        onTap={logOut}
        caption={t('auth:logout.caption')}
        icon='logout'
        color={theme.fg.normal}
        small
      />
    )
  }

  return render()

})

export default AppSideBarFooter

interface AppSideBarFooterLinkProps {
  caption: string
  href:    string
}

const AppSideBarFooterLink = memo('AppSideBarFooterLink', (props: AppSideBarFooterLinkProps) => {

  const {caption, href} = props

  //------
  // Rendering

  function render() {
    return (
      <Tappable href={href} noFeedback>
        <Label
          link
          children={caption}
          small
          dim
        />
      </Tappable>
    )
  }

  return render()

})