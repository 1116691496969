import { action, makeObservable, observable } from 'mobx'
import { Database } from 'mobx-document'
import socket from 'socket.io-react'
import { Connection, Participant } from '~/models'
import { authenticationStore, participantsStore } from '~/stores'
import { ConnectionDocument, ConnectionsEndpoint } from './connections'
import { register } from './support'

export class ConnectionsStore {

  constructor() {
    makeObservable(this)
  }

  public readonly connections = new Database<ConnectionDocument>({
    getID:         connection => connection.id,
    getDocument:   connection => new ConnectionDocument(connection.id, {initialData: connection}),
    emptyDocument: id         => new ConnectionDocument(id),
  })

  public readonly confirmed = new ConnectionsEndpoint('confirmed', this.connections)
  public readonly requests  = new ConnectionsEndpoint('requests', this.connections)

  @observable
  public requestCount: number = 0

  @observable
  public participantsPage: ParticipantsPageLink | null = null

  public getConnectionWith(participantID: string): Connection | null {
    const connections = this.connections.all()
    return connections.find(it => it.other.id === participantID) ?? null
  }

  //------
  // Socket

  public init() {
    socket.addEventListener('connections:request', this.onConnectionRequest)
    socket.addEventListener('connections:rescind', this.onConnectionRescind)
    socket.addEventListener('connections:update', this.onConnectionUpdate)
    socket.addEventListener('connections:disconnect', this.onDisconnect)
    socket.addEventListener('connections:request-count', this.onRequestCount)
    socket.addEventListener('connections:participants-page', this.onParticipantsPage)
    authenticationStore.on('logout', this.onLogOut)
  }

  @action
  private onConnectionRequest = (serialized: Connection) => {
    const connection = Connection.deserialize(serialized)
    this.emit('request', connection)
    this.storeConnection(connection)
    this.requests.fetch()
  }

  @action
  private onConnectionRescind = (connectionID: string) => {
    this.requests.remove([connectionID])
    this.confirmed.remove([connectionID])
  }

  @action
  private onConnectionUpdate = (serialized: AnyObject) => {
    const connection = Connection.deserialize(serialized)
    const existing   = this.connections.get(connection.id)

    if (existing?.incoming === false && existing.confirmedAt == null && connection.confirmedAt != null) {
      this.emit('confirm', connection)
    }
    if (existing?.incoming === false && existing.rejectedAt == null && connection.rejectedAt != null) {
      this.emit('reject', connection)
    }

    this.storeConnection(connection)
    this.confirmed.fetch()
  }

  @action
  private onDisconnect = (connectionID: string, otherSerialized: AnyObject) => {
    this.confirmed.remove([connectionID])

    const other = Participant.deserialize(otherSerialized)
    participantsStore.participants.store(other)
  }

  @action
  private onRequestCount = (count: number) => {
    this.requestCount = count
  }

  @action
  private onParticipantsPage = (page: ParticipantsPageLink) => {
    this.participantsPage = page
  }

  public storeConnection(connection: Connection) {
    this.connections.store(connection)

    const other = Participant.deserialize(connection.other)
    participantsStore.participants.store(other)
  }

  //------
  // Listeners

  private listeners = {
    request: new Set<ConnectionListener>(),
    confirm: new Set<ConnectionListener>(),
    reject:  new Set<ConnectionListener>(),
  }

  public addListener(event: ConnectEvent, listener: ConnectionListener) {
    this.listeners[event].add(listener)
    return () => {
      this.listeners[event].delete(listener)
    }
  }

  private emit(event: ConnectEvent, connection: Connection) {
    for (const listener of this.listeners[event]) {
      listener(connection)
    }
  }

  //------
  // Fetching

  @action
  public fetch() {
    this.confirmed.fetch()
    this.requests.fetch()
  }

  //------
  // Connecting

  @action
  public async sendRequest(participantID: string) {
    const response = await socket.send('connections:request', participantID)
    if (response.ok) {
      const connection = Connection.deserialize(response.body.connection)
      this.storeConnection(connection)
    }
    return response.ok
  }

  @action
  public async sendRescind(connectionID: string) {
    const response = await socket.send('connections:rescind', connectionID)
    return response.ok
  }

  @action
  public async confirmConnection(connectionID: string, answers: AnyObject): Promise<boolean> {
    const response = await socket.send('connections:confirm', connectionID, answers)
    if (response.ok) {
      this.requests.remove([connectionID])
    }
    return response.ok
  }

  @action
  public async rejectConnection(connectionID: string): Promise<boolean> {
    const response = await socket.send('connections:reject', connectionID)
    if (response.ok) {
      this.requests.remove([connectionID])
    }
    return response.ok
  }

  @action
  public async disconnect(participantID: string) {
    const response = await socket.send('connections:disconnect', participantID)
    return response.ok
  }

  //------
  // Log in / log out

  private onLogOut = () => {
    this.connections.clear()
    this.confirmed.clear()
    this.requests.clear()
    this.requestCount = 0
    this.participantsPage = null
  }

}

export type ConnectStatus = 'created' | 'existed'

export type ConnectEvent = 'request' | 'confirm' | 'reject'
export type ConnectionListener = (connection: Connection) => any

export interface ParticipantsPageLink {
  title: string
  path:  string
}

export interface ConnectRequester {
  id:       string
  name:     string
  photoURL: string | null
}

export default register(new ConnectionsStore())