import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollManager } from 'react-scroll-manager'
import { FetchStatus } from 'mobx-document'
import { Post } from '~/models'
import { PostsEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { Center, ClearButton, EmptyOrFetching, HBox, List } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useNewsService } from './NewsContext'
import PostView from './PostView'

export interface Props {
  endpoint:     PostsEndpoint
  fetchStatus?: FetchStatus

  newPostsCount?:       number
  requestShowNewPosts?: () => any

  requestEditPost?: (post: Post) => any
}

const PostList = observer('PostList', (props: Props) => {

  const {
    endpoint,
    fetchStatus,
    newPostsCount = 0,
    requestShowNewPosts,
    requestEditPost,
  } = props

  const [t] = useTranslation('news')

  const [expandedPostID, setExpandedPostID] = React.useState<string | null>(null)

  const toggleComments = React.useCallback((post: Post) => {
    if (expandedPostID === post.id) {
      setExpandedPostID(null)
    } else {
      setExpandedPostID(post.id)
    }
  }, [expandedPostID])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore()
  }, [endpoint])

  const service     = useNewsService()
  const hasFeeds    = service.feeds.length > 0
  const hasNewPosts = newPostsCount > 0
  const hadNewPosts = usePrevious(hasNewPosts)

  const scrollManager = React.useMemo(
    () => new ScrollManager(),
    [],
  )

  const didGetNewPosts = hadNewPosts === false && hasNewPosts === true
  React.useEffect(() => {
    if (didGetNewPosts) {
      scrollManager.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [didGetNewPosts, scrollManager])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List<Post>
        data={endpoint.data}
        keyExtractor={keyExtractor}
        renderItem={renderPost}
        classNames={$.listView}
        contentClassNames={$.listViewContent}
        scrollerProps={{shadows: false}}
        onEndReached={fetchMore}
        HeaderComponent={renderListHeader}
        EmptyComponent={renderEmpty}
        scrollManager={scrollManager}
        itemGap={layout.padding.s}
        scrollable
        flex
      />
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t(`empty.${hasFeeds ? 'with_feeds' : 'without_feeds'}`)}
        flex
      />
    )
  }

  const renderListHeader = React.useCallback(() => {
    if (newPostsCount === 0) { return null }
    if (requestShowNewPosts == null) { return null }

    return (
      <Center classNames={$.morePostsHeader}>
        <ClearButton
          caption={t('new_posts', {count: newPostsCount})}
          onTap={requestShowNewPosts}
          padding='both'
        />
      </Center>
    )

  }, [$.morePostsHeader, newPostsCount, requestShowNewPosts, t])

  const keyExtractor = React.useCallback(
    (post: Post) => post.id,
    [],
  )

  const renderPost = React.useCallback((post: Post, index: number) => {
    return (
      <HBox justify='center'>
        <PostView
          post={post}
          commentsExpanded={post.id === expandedPostID}
          requestToggleComments={toggleComments}
          requestEdit={requestEditPost}
        />
      </HBox>
    )
  }, [expandedPostID, toggleComments, requestEditPost])

  return render()

})

export default PostList

const useStyles = createUseStyles({
  listView: {
    flex: 1,
  },

  listViewContent: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
    })),
  },

  morePostsHeader: {
    ...layout.responsive(size => ({
      marginTop: -layout.padding.m[size],
      padding:   [layout.padding.s[size], 0],
    })),
  },
})