import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from '~/history'
import { Breadcrumbs } from '~/ui/app/navigation'
import { observer } from '~/ui/component'
import { BackButton, HBox, Label, Spinner, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import AppLayoutContext from './AppLayoutContext'

const AppSubHeader = observer('AppSubHeader', () => {

  const {breadcrumbs, fetchStatus, ActionsComponent} = React.useContext(AppLayoutContext)

  const history = useHistory()

  const goBack = React.useCallback(() => {
    history.goBack()
  }, [history])

  const parentBreadcrumb = React.useMemo(
    () => history.index > 0 ? breadcrumbs[history.index -1] : null,
    [breadcrumbs, history.index],
  )

  const backHref    = parentBreadcrumb?.href
  const backCaption = parentBreadcrumb?.caption

  const canGoBack = backHref != null || history.index > 0

  const caption = React.useMemo(() => {
    return breadcrumbs[history.index]?.caption
  }, [breadcrumbs, history])

  const isMobile  = !useMediaQuery({minWidth: screenMinWidths.tablet})

  const $ = useStyles()

  //------
  // Rendering

  function render() {
    return (
      <HBox classNames={$.AppSubHeader} paddingHorizontal={layout.padding.m} gap={isMobile ? layout.padding.inline.m : layout.padding.m}>
        {renderBackButton()}
        {isMobile ? renderCaption(): renderBreadcrumbs()}
        {ActionsComponent != null && <ActionsComponent/>}
      </HBox>
    )
  }

  function renderBackButton() {
    if (!canGoBack) { return null }

    return (
      <BackButton
        href={history.index > 0 ? undefined : backHref}
        caption={isMobile ? backCaption : null}
        onTap={goBack}
      />
    )
  }

  function renderCaption() {
    return (
      <Label bold small flex>
        {caption}
      </Label>
    )
  }

  function renderBreadcrumbs() {
    return (
      <VBox flex>
        {fetchStatus === 'fetching' ? (
          <Spinner size={8}/>
        ) : (
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
          />
        )}
      </VBox>

    )
  }

  return render()

})

export default AppSubHeader

export const height = layout.barHeight.l + 2

const useStyles = createUseStyles(theme => ({
  AppSubHeader: {
    height:   height,
    borderBottom: [2, 'solid', theme.border.dimmer],
  },
}))