import React from 'react'
import { forwardRef } from '~/ui/component'
import { Tappable } from '~/ui/components'
import { borderRadius as popupBorderRadius } from '~/ui/components/Popup'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { isCustomItem, isStandardItem, MenuSection, PopupMenuItem } from './types'

export interface Props {
  item:         Exclude<PopupMenuItem, MenuSection>
  highlighted?: boolean

  onItemTap:    (item: Exclude<PopupMenuItem, MenuSection>, event: React.SyntheticEvent<any>) => any
  onItemEnter?: (item: Exclude<PopupMenuItem, MenuSection>, event: React.SyntheticEvent<any>) => any
  onItemLeave?: (item: Exclude<PopupMenuItem, MenuSection>, event: React.SyntheticEvent<any>) => any

  children:  React.ReactNode
}

const PopupMenuItemButton = forwardRef('PopupMenuItemButton', (props: Props, ref: React.Ref<HTMLElement>) => {

  const {item, highlighted, onItemTap, onItemEnter, onItemLeave} = props
  const backgroundColor = isStandardItem(item) ? item.backgroundColor : undefined
  const enabled = isCustomItem(item) || item.enabled !== false

  const onTap = React.useCallback((event: React.SyntheticEvent<any>) => {
    onItemTap(item, event)
  }, [onItemTap, item])

  const onMouseEnter = React.useCallback((event: React.SyntheticEvent<any>) => {
    onItemEnter?.(item, event)
  }, [onItemEnter, item])

  const onMouseLeave = React.useCallback((event: React.SyntheticEvent<any>) => {
    onItemLeave?.(item, event)
  }, [onItemLeave, item])

  const $ = useStyles()

  function render() {
    return (
      <Tappable
        classNames={[$.itemButton, {highlighted, enabled}]}
        style={{backgroundColor: backgroundColor?.string()}}
        href={item.href}
        onTap={item.children == null ? onTap : undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        enabled={enabled}
        ref={ref}
        children={props.children}
      />
    )
  }

  return render()

})

export default PopupMenuItemButton

const useStyles = createUseStyles(theme => ({
  itemButton: {
    ...layout.flex.column,
    cursor: 'default',

    '&:first-child': {
      borderRadius: [popupBorderRadius, popupBorderRadius, 0, 0],
    },
    '&:last-child': {
      borderRadius: [0, 0, popupBorderRadius, popupBorderRadius],
    },
    '&:first-child:last-child': {
      borderRadius: popupBorderRadius,
    },
    '&.enabled.highlighted': {
      ...colors.overrideBackground(theme.guide.colors.semantic.primary),
      ...colors.overrideForeground(theme.guide.colors.contrast(theme.guide.colors.semantic.primary)),
    },
  },

}))