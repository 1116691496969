import React from 'react'
import { memo } from '~/ui/component'
import LightboxContext from './LightboxContext'
import { useBoolean } from '~/ui/hooks'
import Lightbox from './Lightbox'
import { useHotkey } from 'react-hotkeys'
import { LightboxContent } from './types'

export interface Props {
  children?: React.ReactNode
}

const LightboxHost = memo('LightboxHost', (props: Props) => {

  const {children} = props
  const [visible, show, hide] = useBoolean()
  const [contents, setContents] = React.useState<LightboxContent[]>([])
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1)

  useHotkey(visible ? 'Escape' : null, hide)

  //------
  // Context

  const showWithOptionalContent = React.useCallback((content?: LightboxContent | number) => {
    if (typeof content === 'number') {
      setSelectedIndex(content)
    } else if (content != null) {
      setSelectedIndex(0)
      setContents([content])
    }
    show()
  }, [show])

  const context = React.useMemo((): LightboxContext => ({
    show: showWithOptionalContent,
    hide,

    contents,
    setContents,

    selectedIndex,
    setSelectedIndex,
  }), [hide, selectedIndex, showWithOptionalContent, contents])

  //------
  // Rendering

  function render() {
    return (
      <LightboxContext.Provider value={context}>
        {children}
        {visible && <Lightbox/>}
      </LightboxContext.Provider>
    )
  }

  return render()

})

export default LightboxHost