import React from 'react'
import SocketOperation from '../SocketOperation'

export function useSocketOperation(operation: SocketOperation | null): [number, ...any[]] {
  const [progress, setProgress] = React.useState<[number, ...any[]]>([0])

  React.useEffect(() => {
    setProgress([0])

    return operation?.addProgressListener((progress, ...args) => {
      setProgress([progress, ...args])
    })
  }, [operation])

  return progress
}