import { BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class ProgressBarBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ProgressBarBranding | null,
  ) {
    super(delegate,parent, {
      shape:       'oval',
      background:  'alt',
      border:      'none',
      depth:       0,
      previewSize: {width: 160, height: 20},
    })
  }

  public inner = new ComponentBranding(this, null, {
    shape:      'rectangle',
    background: 'primary',
    border:     'none',
    depth:      0,
    previewSize: {width: 160, height: 20},
  })

}