import React from 'react'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props {
  title:     string
  children?: React.ReactNode
}

const BeWizrSection = memo('BeWizrSection', (props: Props) => {

  const {title, children} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox tag='section' gap={layout.padding.m}>
        <Label h2>{title}</Label>
        {children}
      </VBox>
    )
  }

  return render()

})

export default BeWizrSection