import React from 'react'
import ScrollManager from './ScrollManager'
import { ScrollState } from './types'

export function useScrollState(scrollManager: ScrollManager, horizontal: boolean = false) {
  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)

  const listener = React.useCallback(() => {
    const state = scrollManager.getScrollState(horizontal)
    setScrollState(state)
  }, [scrollManager, horizontal])

  React.useEffect(() => {
    scrollManager.addScrollListener(listener)

    return () => {
      scrollManager.removeScrollListener(listener)
    }
  }, [listener, scrollManager])

  return scrollState
}