import Timer from 'react-timer'

export default class ScrollHelper {

  constructor(
    private readonly containerRef: React.RefObject<HTMLDivElement>,
    private readonly stickyThreshold: number = 100,
    private readonly endReachedThreshold: number = 500,
  ) {}

  private timer = new Timer()

  private get container() {
    return this.containerRef.current ?? null
  }

  //------
  // Scroll to bottom

  public scrollToBottom(behavior: 'auto' | 'smooth' = 'auto') {
    if (this.container == null) { return }

    this.container.scrollTo({
      top:      this.container.scrollHeight - this.container.clientHeight,
      behavior: behavior,
    })
    this.snapshotOffset = 0
    this.suspendSnapshot = true
  }

  //------
  // End reached

  public endReachedHandler?: () => void

  public detectEndReached() {
    if (this.container == null) { return }
    if (this.endReachedHandler == null) { return }

    // Never do end-reached detection if the list is not scrolling.
    if (this.container.scrollHeight <= this.container.clientHeight) {
      return
    }

    if (this.container.scrollTop < this.endReachedThreshold) {
      this.endReachedHandler()
    }
  }

  //------
  // Snapshots

  private suspendSnapshot:  boolean = false
  private snapshotBehavior: 'auto' | 'smooth' = 'auto'
  private snapshotOffset:   number | null = null

  public scrollSmoothNext(value: boolean) {
    if (value) {
      this.snapshotBehavior = 'smooth'
    }
  }

  public startSnapshotListener: (() => any) | null = null
  public endSnapshotListener: (() => any) | null = null

  public takeSnapshot() {
    if (this.container == null) { return }

    if (this.suspendSnapshot) {
      this.suspendSnapshot = false
    } else {
      this.snapshotOffset = this.scrollTopToBottomOffset(this.container.scrollTop)
    }
  }

  public commitSnapshot() {
    if (this.container == null) { return }
    if (this.snapshotOffset == null) { return }

    if (this.startSnapshotListener != null) {
      const offset = this.scrollTopToBottomOffset(this.container.scrollTop)
      this.startSnapshotListener?.()

      const nextScrollTop = this.bottomOffsetToScrollTop(offset)
      this.container.scrollTo({top: nextScrollTop, behavior: 'auto'})
    }

    const offset = this.scrollTopToBottomOffset(this.container.scrollTop)
    if (offset === this.snapshotOffset) { return }

    if (this.snapshotOffset < this.stickyThreshold) {
      this.scrollToBottom(this.snapshotBehavior)
    } else {
      const nextScrollTop = this.bottomOffsetToScrollTop(this.snapshotOffset)
      this.container.scrollTo({
        top:      nextScrollTop,
        behavior: this.snapshotBehavior,
      })
    }

    this.timer.debounce(() => {
      this.snapshotBehavior = 'auto'
      this.endSnapshotListener?.()
      this.takeSnapshot()
    }, 500)
  }

  private scrollTopToBottomOffset(scrollTop: number) {
    if (this.container == null) { return 0 }

    const scrollBottom = scrollTop + this.container.clientHeight
    return this.container.scrollHeight - scrollBottom
  }

  private bottomOffsetToScrollTop(bottomOffset: number) {
    if (this.container == null) { return 0 }

    const scrollBottom = this.container.scrollHeight - bottomOffset
    return scrollBottom - this.container.clientHeight

  }

}