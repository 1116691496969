import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { CollectionFetchOptions, FetchStatus } from 'mobx-document'
import socket from 'socket.io-react'
import { IndexEntry, IndexPage, IndexWithItems, Participant } from '~/models'
import participantsStore from '../participantsStore'
import profileStore from '../profileStore'
import { IndexMeta } from './types'

export default class IndexEndpoint {

  constructor(
    public readonly page: IndexPage,
  ) {
    makeObservable(this)
  }

  @observable
  public fetchStatus: FetchStatus = 'idle'

  @observable.ref
  public data: IndexEntry[] = []

  @observable.ref
  public meta: IndexMeta | null = null

  @computed
  public get entriesWithItems() {
    const entries: Array<IndexEntry & {item: any}> = []
    for (const entry of this.data) {
      const item = this.getItem(entry.id)
      if (item == null) { continue }

      entries.push({...entry, item})
    }
    return entries
  }

  @observable
  public searchQuery: string | null = null

  @action
  public setSearchQuery(query: string | null) {
    if (query === this.searchQuery) { return }
    this.searchQuery = query
    this.fetch({force: true})
  }

  //------
  // Fetch

  @action
  public async fetch(options: FetchOptions) {
    const nextOffset = this.meta == null ? null : this.meta.nextOffset
    if (options.append && nextOffset == null) { return }
    if (!options.force && this.fetchStatus === 'fetching') { return }

    this.fetchStatus = 'fetching'

    const search   = this.searchQuery
    const offset   = options.append ? nextOffset : 0
    const response = await socket.fetch('pages:index', this.page.slug, {
      offset,
      search,
    })
    if (search !== this.searchQuery) { return response }

    runInAction(() => {
      if (!response.ok) {
        this.fetchStatus = response.error
      } else {
        this.fetchStatus = 'done'

        const data = options.append ? [...this.data] : []
        data.push(...this.storeEntries(response.body.entries))

        this.data = data
        this.meta = {
          total:      response.body.total,
          nextOffset: response.body.nextOffset,
        }
      }
    })

    return response.ok
  }

  public fetchNextPage() {
    return this.fetch({append: true})
  }

  public storeIndex(index: IndexWithItems) {
    this.data = this.storeEntries(index.entries)
    this.meta = {
      total:      index.total,
      nextOffset: index.nextOffset,
    }
  }

  protected storeEntries(entries: Array<IndexEntry & {item: any}>) {
    return entries.map(entry => {
      const {item, id: _, ...rest} = entry
      const id = this.storeItem(item)
      return {id, ...rest}
    })
  }

  private storeItem(raw: AnyObject) {
    // Store all items in the index in their respective databases.
    switch (this.page.itemType) {
    case 'Participant':
      // Skip the current profile - the index version will contain less info.
      if (raw.id === profileStore.participantID) { return raw.id }

      const participant = Participant.deserialize(raw)
      const document    = participantsStore.participants.store(participant)
      return document.id
    }
  }

  public getItem(id: string) {
    const document = this.getDocument(id)
    if (document == null) { return null }

    return document.data
  }

  public getDocument(id: string) {
    switch (this.page.itemType) {
      case 'Participant':
        return participantsStore.participants.document(id)
    }
  }

}

interface FetchOptions extends CollectionFetchOptions {
  force?: boolean
}