import Logger from 'logger'

export default class WindowChannelLogger {

  constructor(
    private readonly logger: Logger,
  ) {}

  public logIncoming(type: string, payload: any) {
    this.logger.info({
      text: `<- %c${type}`,
      styles: [{}, {
        background:   '#efefef',
        border:       '1px solid #ddd',
        borderRadius: '2px',
        padding:      '2px',
        margin:       '-2px 0',
      }],
    }, payload)
  }

  public logSend(type: string, payload: any) {
    this.logger.info({
      text: `-> %c${type}`,
      styles: [{}, {
        background:   '#efefef',
        border:       '1px solid #ddd',
        borderRadius: '2px',
        padding:      '2px',
        margin:       '-2px 0',
      }],
    }, payload)
  }

  public logBroadcast(type: string, payload: any) {
    this.logger.info({
      text: `=> %c${type}`,
      styles: [{}, {
        background:   '#efefef',
        border:       '1px solid #ddd',
        borderRadius: '2px',
        padding:      '2px',
        margin:       '-2px 0',
      }],
    }, payload)
  }

}