import React from 'react'
import { useTimer } from 'react-timer'
import cn from 'classnames'
import { memo } from '~/ui/component'
import { childFlex, VBox, VBoxProps } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { flexIgnoresContentSize } from './layout'

export interface Props<T> {
  type?:     TransitionType
  duration?: number
  flex?:     VBoxProps['flex']

  currentScene: T
  renderMode?:  RenderMode

  getExplodeOrigin?: () => Point | null

  children?:
    | React.ReactElement<TransitionSceneProps<T>>
    | React.ReactElement<TransitionSceneProps<T>>[]
    | null | undefined | false
}

export type TransitionType =
  | 'fade-slide' // Scenes slide left - right a bit, and fades out. (default)
  | 'slide'      // Scenes slide left - right like a StackNavigator.
  | 'fade'       // Scenes fade in / out.
  | 'explode'    // Scenes fade in / out with a scale.

export type RenderMode =
  | 'current-only' // Only the current scene is rendered (except during transition).
  | 'render-all'   // All scenes are rendered, but only the current scene is visible.
  | 'render-below' // The current scene, and all scenes below it are rendered.

const _Transition = <T extends {}>(props: Props<T>) => {

  const {
    type = 'fade-slide',
    duration = animation.durations.medium,
    currentScene,
    getExplodeOrigin,
    flex,
    renderMode = flexIgnoresContentSize(flex) ? 'current-only' : 'render-all',
    children,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const sceneFlex = childFlex(flex)

  const scenes = React.useMemo(
    () => React.Children.toArray(children) as TransitionElement<T>[],
    [children],
  )

  let currentIndex = scenes.findIndex(it => it.props.name === currentScene)
  if (currentIndex < 0) { currentIndex = 0 }

  const prevCurrentIndex  = usePrevious(currentIndex)
  const transitionStarted = prevCurrentIndex != null && currentIndex !== prevCurrentIndex

  const [state, setState] = React.useState<TransitionState>(TransitionState.idle)

  const transitionActiveRef = React.useRef<boolean>(false)
  const prevIndexRef = React.useRef<number | null>(null)
  const nextIndexRef = React.useRef<number | null>(null)

  // Mark the transition as active before the effect is run. The reason for this is that by the time
  // the effect runs, the current index has already been updated, and this will cause the next scene
  // to be briefly visible before the transition starts.
  if (transitionStarted) {
    prevIndexRef.current  = prevCurrentIndex
    nextIndexRef.current  = currentIndex
  }

  const prevIndex        = prevIndexRef.current
  const nextIndex        = nextIndexRef.current
  const transitionActive = prevIndex != null && nextIndex != null

  //------
  // Sizing

  const prevHeightRef = React.useRef<number | undefined>(undefined)
  const nextHeightRef = React.useRef<number | undefined>(undefined)

  const getSceneHeight = React.useCallback((index: number): number | undefined => {
    const container = containerRef.current
    if (container == null) { return undefined }

    const scene = container.children[index] as HTMLElement
    if (scene == null) { return undefined }

    const prevBottom = scene.style.bottom
    scene.style.bottom = 'auto'

    const height = Math.ceil(scene.offsetHeight)

    scene.style.bottom = prevBottom
    return height
  }, [])

  // Mark the transition as active before the effect is run. The reason for this is that by the time
  // the effect runs, the current index has already been updated, and this will cause the next scene
  // to be briefly visible before the transition starts.
  if (transitionStarted) {
    prevHeightRef.current = getSceneHeight(prevCurrentIndex)
    nextHeightRef.current = getSceneHeight(currentIndex)
  }

  const transitionHeight = (() => {
    if (!transitionActive) { return undefined }
    if (flex === true || typeof flex === 'number') { return undefined }

    return state === 'commit' ? nextHeightRef.current : prevHeightRef.current
  })()

  const explodeOriginRef = React.useRef<Point | null>(null)

  const direction: TransitionDirection | null = React.useMemo(() => {
    if (prevIndex == null || nextIndex == null) { return null }
    return nextIndex > prevIndex ? TransitionDirection.forward : TransitionDirection.backward
  }, [nextIndex, prevIndex])

  const stateClassName = transitionActive && state === TransitionState.idle ? 'prepare' : state

  //------
  // Transition effect

  const timer = useTimer()

  React.useEffect(() => {
    if (prevCurrentIndex == null) { return }
    if (prevCurrentIndex === currentIndex) { return }

    setState(TransitionState.prepare)

    explodeOriginRef.current = getExplodeOrigin?.() ?? null

    timer.clearAll()
    timer.requestAnimationFrameAfter(() => {
      setState(TransitionState.commit)
    }, 16)
    timer.requestAnimationFrameAfter(() => {
      transitionActiveRef.current = false
      prevIndexRef.current  = null
      nextIndexRef.current  = null
      prevHeightRef.current = undefined
      nextHeightRef.current = undefined
      setState(TransitionState.idle)
    }, Math.max(32, duration))
  }, [currentIndex, duration, getExplodeOrigin, prevCurrentIndex, prevIndex, timer])

  //------
  // Scenes

  const $ = useStyles(duration)

  const shouldRenderScene = React.useCallback((index: number) => {
    // During transition, make sure to always render the scenes in transition.
    if (transitionActive && index === prevIndex) { return true }
    if (transitionActive && index === nextIndex) { return true }

    // Otherwise, use the render mode to check.
    if (renderMode === 'current-only' && index !== currentIndex && index !== prevCurrentIndex) { return false }
    if (renderMode === 'render-below' && index > currentIndex && index !== prevCurrentIndex) { return false }

    return true
  }, [currentIndex, nextIndex, prevCurrentIndex, prevIndex, renderMode, transitionActive])

  const scenesToRender = React.useMemo(() => {
    return scenes.map((scene, index) => {
      if (!shouldRenderScene(index)) { return null }

      // Determine the next and previous scenes in the transition.
      const next = transitionActive && index === nextIndex
      const prev = transitionActive && index === prevIndex

      // If the transition is not active, determine which is the current scene.
      // Note that if the state is still 'idle', we use the previous current index to prevent
      // briefly showing the next scene before the transition starts.
      const current = !transitionActive && index === currentIndex

      // Hide the scene if it's not the current scene, and it's not in the current transition.
      const hidden  = !prev && !next && !current

      const explodeOrigin = explodeOriginRef.current
      const applyExplodeOrigin = type === 'explode' && (direction === 'forward' ? prev : next)

      return React.cloneElement(scene, {
        classNames: cn($.scene, scene.props.classNames, {
          next,
          prev,
          current,
          hidden,
        }),
        flex:  sceneFlex,
        style: applyExplodeOrigin && explodeOrigin != null ? {
          ...scene.props.style,
          transformOrigin: `${explodeOrigin.x}px ${explodeOrigin.y}px`,
        } : {
          ...scene.props.style,
        },
      })
    })
  }, [$.scene, currentIndex, direction, nextIndex, prevIndex, sceneFlex, scenes, shouldRenderScene, transitionActive, type])

  //------
  // Rendering

  function render() {
    const style: React.CSSProperties = {
      height: transitionHeight,
    }

    return (
      <VBox classNames={[$.Transition, stateClassName, direction, type]} style={style} flex={flex} ref={containerRef}>
        {scenesToRender}
      </VBox>
    )
  }

  return render()

}

interface TransitionSceneProps<T> {
  name:      T

  flex?:       VBoxProps['flex']
  classNames?: React.ClassNamesProp
  style?:      React.CSSProperties
  children?:   React.ReactNode
}

const _TransitionScene = <T extends {}>(props: TransitionSceneProps<T>) => {
  const $ = useStyles()

  return (
    <VBox flex={props.flex} classNames={[$.scene, props.classNames]} style={props.style}>
      {props.children}
    </VBox>
  )
}

type TransitionElement<T> = React.ReactElement<TransitionSceneProps<T>>

enum TransitionState {
  idle    = 'idle',
  prepare = 'prepare',
  commit  = 'commit',
}

enum TransitionDirection {
  forward  = 'forward',
  backward = 'backward',
}

const TransitionScene = memo('TransitionScene', _TransitionScene) as typeof _TransitionScene
const Transition      = memo('Transition', _Transition) as typeof _Transition
Object.assign(Transition, {Scene: TransitionScene})

export default Transition as typeof Transition & {Scene: typeof TransitionScene}

const useStyles = createUseStyles({
  Transition: (duration: number) => ({
    position:   'relative',
    willChange: 'height',

    '& > :not(.next)': {
      zIndex: 0,
    },
    '& > .next': {
      zIndex: 1,
    },
    '& > .hidden': {
      opacity:       0,
      ...layout.overlay,
      overflow: 'hidden',

      '&, & *': {
        pointerEvents: 'none !important',
      },
    },

    '&:not(.idle)': {
      overflow:   'hidden',
      transition: animation.transition('height', duration),

      '& > :not(.next)': {
        ...layout.overlay,
      },
    },

    '&.slide': {
      '& > *': {
        willChange: 'transform',
      },
      '&.forward.prepare > .next, &.backward.commit > .prev': {
        transform: 'translateX(100%)',
      },
      '&.backward.prepare > .next, &.forward.commit > .prev': {
        transform: 'translateX(-100%)',
      },
      '&.commit > *': {
        transition: animation.transition('transform', duration),
      },
    },

    '&.fade-slide': {
      '& > *': {
        willChange: 'transform',
      },
      '&.forward.prepare > .next, &.backward.commit > .prev': {
        transform: 'translateX(20%)',
        opacity:   0,
      },
      '&.backward.prepare > .next, &.forward.commit > .prev': {
        transform: 'translateX(-20%)',
        opacity:   0,
      },
      '&.commit > *': {
        transition: animation.transition(['transform', 'opacity'], duration),
      },
    },

    '&.fade': {
      '& > *': {
        willChange: 'opacity',
      },
      '&.forward.prepare > .next, &.backward.commit > .prev': {
        opacity: 0,
      },
      '&.backward.prepare > .next, &.forward.commit > .prev': {
        opacity: 0,
      },
      '&.commit > *': {
        transition: animation.transition('opacity', duration),
      },
    },

    '&.explode': {
      '& > *': {
        willChange: ['transform', 'opacity'],
      },
      '&.forward.prepare > .next, &.backward.commit > .prev': {
        opacity: 0,
      },
      '&.backward.prepare > .next, &.forward.commit > .prev': {
        opacity:   0,
        transform: 'scale(1.5)',
      },
      '&.forward.commit > *': {
        transition: [
          ...animation.transition('transform', duration),
          ...animation.transition('opacity', duration * 0.75),
        ],
      },
      '&.backward.commit > *': {
        transition: [
          ...animation.transition('transform', duration),
          ...animation.transition('opacity', duration),
        ],
      },
    },
  }),

  scene: {
    '$Transition:not(.idle)': {
      ...layout.overlay,
    },
  },
})