import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Center, Label, Panel, TextBlock } from '~/ui/components'
import { AuthLogo } from '~/ui/layouts'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  preamble?:   React.ReactNode
  children?:   React.ReactNode
  classNames?: React.ClassNamesProp
}

const AuthForm = memo('AuthForm', (props: Props) => {

  const [t] = useTranslation()

  const {
    preamble,
    children,
    classNames,
  } = props

  const {guide} = useStyling()
  const branding = guide.web.authPanel

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={[$.AuthForm, classNames]} padding={layout.padding.l} gap={layout.padding.m} branding={branding}>
        {renderHeader()}
        {children}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <Center gap={layout.padding.m}>
        <AuthLogo/>
        <Label h1>
          {guide.text('title') ?? t('app:title')}
        </Label>
        {renderPreamble()}
      </Center>
    )
  }

  function renderPreamble() {
    if (!isReactText(preamble)) { return preamble }

    return (
      <TextBlock small dim align='center'>
        {preamble}
      </TextBlock>
    )
  }


  return render()

})

export default AuthForm

const useStyles = createUseStyles({
  AuthForm: {
    ...layout.responsiveProp({margin: layout.padding.l}),
    ...layout.breakpoint({minWidth: 360 + 2 * layout.padding.l.desktop})({
      width:  360,
      margin: [0, 'auto'],
    }),
  },
})