import React from 'react'
import { memo } from '~/ui/component'

export interface TappableContext {
  /**
   * Allows the consumer to specify what the `target` attribute for a given HREF shouold be. Note that this
   * is the original HREF, before it was resolved through {@link resolveHref}.
   *
   * @param href The HREF to check.
   * @returns The intended target for the given HREF.
   */
  targetForHref: (href: string) => string | undefined

  /**
   * Resolves any HREF to a valid URL. Useful for resolving app links.
   *
   * @param href The HREF to check.
   * @returns The resolved URL.
   */
  resolveHref: (href: string) => string

  /**
   * Navigation handler. This is invoked when the user taps on a link, and {@link shouldUseDefault} returns
   * `false`. The default implementation does nothing, so make sure to implement this method if
   * {@link shouldUseDefault} ever returns `false`.
   *
   * @param href
   * @param target
   * @returns
   */
  navigate?: (href: string, target: string | undefined, event: React.SyntheticEvent) => void
}

const defaults: TappableContext = {
  targetForHref: () => undefined,
  resolveHref:   href => href,
}

export const TappableContext = React.createContext<TappableContext>(defaults)

export interface TappableContainerProps {
  targetForHref: TappableContext['targetForHref']
  resolveHref:   TappableContext['resolveHref']
  navigate:      TappableContext['navigate']

  children?: React.ReactNode
}

export const TappableContainer = memo('TappableContainer', (props: TappableContainerProps) => {

  const {
    targetForHref,
    resolveHref,
    navigate,
    children,
  } = props

  const context = React.useMemo((): TappableContext => ({
    targetForHref,
    resolveHref,
    navigate,
  }), [navigate, resolveHref, targetForHref])

  return (
    <TappableContext.Provider value={context}>
      {children}
    </TappableContext.Provider>
  )

})