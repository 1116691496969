import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { animation, createUseStyles, themedBackgroundStyles } from '~/ui/styling'
import Drawer, { drawerWidth } from './Drawer'
import DrawerContext from './DrawerContext'

export interface Props {
  children?: React.ReactNode
}

const DrawerContainer = observer('DrawerContainer', (props: Props) => {

  const [isOpen, open, close, toggle] = useBoolean()

  const drawerContext = React.useMemo(() => ({
    open,
    close,
    toggle,
    isOpen,
  }), [open, close, toggle, isOpen])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <DrawerContext.Provider value={drawerContext}>
        <VBox flex classNames={$.DrawerContainer}>
          {renderDrawer()}
          {renderContent()}
        </VBox>
      </DrawerContext.Provider>
    )
  }

  function renderDrawer() {
    return (
      <TransitionGroup>
        {isOpen && (
          <CSSTransition timeout={drawerTransitionDuration}>
            <Drawer classNames={$.drawer}/>
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }

  function renderContent() {
    return (
      <VBox flex classNames={[$.content, {open: isOpen}]}>
        {props.children}
      </VBox>
    )
  }

  return render()

})

export default DrawerContainer

const drawerTransitionDuration = animation.durations.short

const useStyles = createUseStyles(theme => ({
  DrawerContainer: {
    width: '100%',
    overflow: 'hidden',
  },

  drawer: {
    '&.enter-active > *': {
      transition: animation.transition('transform', drawerTransitionDuration, animation.timings.popOut),
    },
    '&.exit-active > *': {
      transition: animation.transition('transform', drawerTransitionDuration, animation.timings.popIn),
    },
    '&.enter:not(.enter-active) > *, &.exit-active > *': {
      transform: 'scale(0.8)',
    },
    '&.enter-active > *, &.exit:not(.exit-active) > *': {
      transform: 'scale(1)',
    },
  },

  content: {
    ...themedBackgroundStyles(theme.guide, theme.guide.backgrounds.body),
    transform: 'translateX(0)',
    transition: animation.transition('transform', drawerTransitionDuration),
    '&.open': {
      pointerEvents: 'none',
      transform: `translateX(${drawerWidth}px)`,
    },
  },
}))