import React from 'react'
import Color from 'color'
import { memo } from '~/ui/component'
import { ComponentBranding, createUseStyles, ThemeProvider, useStyling } from '~/ui/styling'
import { BrandedComponent } from './'
import { flexStyle, VBox, VBoxProps } from './layout'

export interface Props extends VBoxProps {
  branding?:        ComponentBranding
  backgroundColor?: Color
}

const Notice = memo('Notice', (props: Props) => {

  const {guide} = useStyling()

  const {
    flex = false,
    classNames,
    className,
    branding = guide.notice,
    backgroundColor,
    style,
    ...rest
  } = props

  const allStyles = React.useMemo(() => ({
    ...style,
    background: backgroundColor?.string(),
  }), [backgroundColor, style])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent
        classNames={[$.notice, flexStyle(flex), classNames, className]}
        branding={branding}
        height={heightForBorderRadius}
        style={allStyles}
      >
        {backgroundColor == null ? (
          <VBox {...rest} flex='both'/>
        ) : (
          <ThemeProvider contrast={backgroundColor}>
            <VBox {...rest} flex='both'/>
          </ThemeProvider>
        )}
      </BrandedComponent>
    )
  }

  return render()

})

export default Notice

export const heightForBorderRadius = 32

const useStyles = createUseStyles({
  notice: {
    overflow:  'hidden',
  },
})