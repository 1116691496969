import React from 'react'
import { memo } from '~/ui/component'
import activity from 'react-activity'
import Color from 'color'
import { createUseStyles, useTheme } from '~/ui/styling'
import 'react-activity/dist/react-activity.css'

export interface Props {
  classNames?: React.ClassNamesProp
  shown?:      boolean
  size?:       number
  color?:      Color
  dim?:        boolean
}

const Spinner = memo('Spinner', ({shown = true, size = 16, classNames, ...props}: Props) => {
  const $     = useStyles()
  const theme = useTheme()
  const color = props.color ?? (props.dim ? theme.fg.dim : theme.fg.normal)

  return (
    <div classNames={[$.spinner, !shown && $.hidden, classNames]}>
      <activity.Spinner
        size={size}
        {...props}
        color={color.string()}
      />
    </div>
  )
})

export default Spinner

const useStyles = createUseStyles({
  spinner: {
    '& > *': {
      display: 'block !important',
    },
  },

  hidden: {
    visibility: 'hidden',
  },
})