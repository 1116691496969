import * as layout from '../../layout'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class AccordionBranding extends ComponentBranding {

  constructor(
    delegate:  BrandingGuideDelegate,
    parent:    AccordionBranding | null,
    defaults?: BrandedComponentSpec,
  ) {
    super(delegate,parent, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
      ...defaults,
    })
  }

}