import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import socket from 'socket.io-react'
import { Participant } from '~/models'
import { ParticipantDocument } from '../participants'
import { SearchParams } from '../search'

export default class ParticipantsEndpoint extends Endpoint<ParticipantDocument> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  protected async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    const response = await socket.fetch('participants:list', {
      ...params,
      ...options,
    })
    if (!response.ok) { return null }

    const data = response.body.data.map(
      (raw: any) => Participant.deserialize(raw),
    )

    return {
      data: data,
      meta: response.body.meta,
    }
  }
}