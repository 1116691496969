import React from 'react'
import { useTranslation } from 'react-i18next'
import { Post } from '~/models'
import { observer } from '~/ui/component'
import { HBox, PushButton, VBox } from '~/ui/components'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { AppLayoutConfig } from '~/ui/layouts'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { NewsProvider, useNewsService } from './NewsContext'
import NewsFeedFilter from './NewsFeedFilter'
import PostForm from './PostForm'
import PostList from './PostList'

const NewsScreen = observer('NewsScreen', () => {

  const [t] = useTranslation('news')

  const service = useNewsService()

  const feedCount     = service.feeds.length
  const openFeedCount = service.openFeeds.length

  const endpoint    = service.allPosts
  const fetchStatus = service.starting ? 'fetching' : endpoint.fetchStatus

  const prevEndpoint = usePrevious(endpoint)
  React.useEffect(() => {
    if (prevEndpoint === undefined || endpoint === prevEndpoint) { return }
    endpoint.fetch()
  }, [endpoint, prevEndpoint])

  const showNewPosts = React.useCallback(() => {
    service.showNewPosts()
  }, [service])

  const [formOpen, showForm, hideForm] = useBoolean()
  const [editedPost, setEditedPost] = React.useState<Post | null>(null)

  const editPost = React.useCallback((post: Post) => {
    setEditedPost(post)
    showForm()
  }, [showForm])

  const addPost = React.useCallback(() => {
    setEditedPost(null)
    showForm()
  }, [showForm])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.NewsScreen}>
        <AppLayoutConfig
          title={t('title')}
          fetchStatus={endpoint.fetchStatus}
          ActionsComponent={renderActions}
        />
        <PostList
          endpoint={endpoint}
          fetchStatus={fetchStatus}
          newPostsCount={service.newPosts.length}
          requestShowNewPosts={showNewPosts}
          requestEditPost={editPost}
        />
        <PostForm
          open={formOpen}
          post={editedPost}
          requestClose={hideForm}
        />
      </VBox>
    )
  }

  const renderActions = React.useCallback(() => (
    <NewsProvider service={service}>
      <HBox gap={layout.padding.inline.m}>
        {feedCount > 0 && <NewsFeedFilter/>}
        {openFeedCount > 0 && (
          <PushButton
            icon='plus'
            caption={t('new_post')}
            onTap={addPost}
            small
          />
        )}
      </HBox>
    </NewsProvider>
  ), [addPost, feedCount, openFeedCount, service, t])

  return render()

})

export default NewsScreen

export const formTransitionDuration = animation.durations.short

const useStyles = createUseStyles(theme => ({
  NewsScreen: {
  },
}))