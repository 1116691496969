import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrLibraryTopic } from '~/models'
import { memo } from '~/ui/component'
import { Chip } from '~/ui/components'
import { colors } from '~/ui/styling'
import BeWizrTile from '../BeWizrTile'

export interface Props {
  topic: BeWizrLibraryTopic
}

const BeWizrLibraryTopicTile = memo('BeWizrLibraryTopicTile', (props: Props) => {

  const {topic} = props

  const [t] = useTranslation('bewizr')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={topic.title}
        image={topic.links.image}
        href={`//bewizr-library-topic/${topic.slug}`}
        detail={renderDetail()}
        buttonCaption={t('library.topics.view')}
      />
    )
  }

  function renderDetail() {
    return (
      <Chip
        icon='folder'
        children={t('library.categories.count', {count: topic.itemCount})}
        backgroundColor={colors.white}
        small
      />
    )
  }

  return render()

})

export default BeWizrLibraryTopicTile