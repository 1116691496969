import React from 'react'
import { useTranslation } from 'react-i18next'
import { QAndA } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { VBox, TextField } from '~/ui/components'
import { FormDialog, FormField, FormProps } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import QuestionFormModel from './QuestionFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any

  qAndA:            QAndA
  titleSuggestion?: string | null

  afterSubmit?:     FormProps<any>['afterSubmit']
}

const QuestionForm = memo('QuestionForm', (props: Props) => {

  const {open, requestClose, afterSubmit, qAndA, titleSuggestion = null} = props

  const [t] = useTranslation('q-and-as')

  const formModel = React.useMemo(
    () => new QuestionFormModel(qAndA),
    [qAndA],
  )

  const prevTitleSuggestion = usePrevious(titleSuggestion)
  React.useEffect(() => {
    if (titleSuggestion == null) { return }
    if (titleSuggestion === prevTitleSuggestion) { return }
    formModel.title = titleSuggestion
  }, [formModel, prevTitleSuggestion, titleSuggestion])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        afterSubmit={afterSubmit}
        icon='question'
        title={t('create.title')}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='title' required caption={t('fields.title.caption')}>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='body' renderAsLabel={false} caption={t('fields.body.caption')} instruction={t('fields.body.instruction')}>
          {bind => <RichTextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default QuestionForm