import React from 'react'
import { memo } from '~/ui/component'
import { NumberField as NumberFieldComponent } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { NumberField } from '../types'

export interface Props {
  field: NumberField
  bind:  FormFieldBindProps<number | null>
}

const NumberAllowance = memo('NumberAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <NumberFieldComponent
        {...bind}
        minimum={field.min}
        maximum={field.max}
        step={field.integer ? 1 : undefined}
      />
    )
  }

  return render()

})

export default NumberAllowance