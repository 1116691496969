import React from 'react'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedLogo } from '~/ui/components'
import { useStyling } from '~/ui/styling'

const AuthLogo = memo('AuthLogo', () => {

  const {guide} = useStyling()
  const logo = guide.image('logo', 'web')

  function render() {
    return logo != null ? (
      <BrandedLogo size={logoSize}/>
    ) : (
      <ImageView
        source='/images/authlogo.png'
        densities={[1, 2, 3]}
        size={logoSize}
        objectFit='contain'
      />
    )
  }

  return render()

})

export default AuthLogo

export const logoSize = {
  width:  128,
  height: 128,
}