import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, Tappable, TappableProps, Tooltip, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, presets, shadows, ThemeProvider, useTheme } from '~/ui/styling'

export interface Props<T> {
  segments: ButtonSegment<T>[]
  small?:   boolean

  selectedValue: T | null
  onChange:      (value: T) => any
}

export interface ButtonSegment<T> extends TappableProps {
  value:    T
  icon?:    SVGName
  caption:  string
  tooltip?: string
}

const _SegmentedButton = <T extends any>(props: Props<T>) => {

  const {segments, selectedValue, onChange, small = false} = props

  const theme = useTheme()

  const context = React.useMemo(() => ({
    small,
    selectedValue,
    selectValue: onChange,
  }), [onChange, selectedValue, small])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <SegmentedButtonContext.Provider value={context}>
        <HBox classNames={[$.segmentedButton, {small}]} align='stretch' gap={layout.padding.inline.s}>
          <ThemeProvider primary={!theme.isDark}>
            {segments.map(renderSegment)}
          </ThemeProvider>
        </HBox>
      </SegmentedButtonContext.Provider>
    )
  }

  function renderSegment(segment: ButtonSegment<T>) {
    return (
      <SegmentedButtonItem
        key={`${segment.value}`}
        {...segment}
      />
    )
  }

  return render()

}

export default memo('SegmentedButton', _SegmentedButton) as typeof _SegmentedButton

const SegmentedButtonItem = memo('SegmentedButtonItem', <T extends any>(props: ButtonSegment<T>) => {

  const {value, icon, caption, tooltip, href} = props
  const {small, selectedValue, selectValue} = React.useContext(SegmentedButtonContext)

  const selected = value === selectedValue

  const onTap = React.useCallback((event: React.SyntheticEvent) => {
    selectValue(value)
    props.onTap?.(event)
  }, [selectValue, value, props])

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark={selected}>
        {tooltip != null ? (
          <Tooltip renderTooltip={tooltip}>
            {renderButton()}
          </Tooltip>
        ) : (
          renderButton()
        )}
      </ThemeProvider>
    )
  }

  function renderButton() {
    const classNames = [
      $.segmentedButtonItem,
      {selected},
      {small},
    ]

    return (
      <Tappable classNames={classNames} onTap={onTap} href={href} noFeedback>
        <HBox flex='grow' gap={layout.padding.inline.m}>
          {icon && (
            <SVG
              name={icon}
              size={layout.icon.s}
            />
          )}
          <VBox flex='both'>
            <Label caption small={small} align='center'>
              {caption}
            </Label>
          </VBox>
        </HBox>
      </Tappable>
    )
  }

  return render()

})

interface SegmentedButtonContext<T> {
  small:         boolean
  selectedValue: T | null
  selectValue:   (value: T) => any
}

const SegmentedButtonContext = React.createContext<SegmentedButtonContext<any>>({
  small:         false,
  selectedValue: null,
  selectValue:   () => void 0,
})

export const segmentedButtonHeight = {
  normal: 40,
  small:  32,
}

const useStyles = createUseStyles(theme => ({
  segmentedButton: {
    '&:not(.small)': {
      height:       segmentedButtonHeight.normal,
      borderRadius: segmentedButtonHeight.normal / 2,
    },
    '&.small': {
      height:       segmentedButtonHeight.small,
      borderRadius: segmentedButtonHeight.small / 2,
    },

    ...theme.isDark ? {
      background: theme.inverse.bg.normal.alpha(0.2),
    } : {
      background: theme.bg.alt,
      boxShadow:  shadows.depth(1),
    },
  },

  segmentedButtonItem: {
    position: 'relative',
    ...presets.overlayBefore({}),

    flex:    [1, 1, 'auto'],
    padding: [layout.padding.inline.xs, layout.padding.inline.xl],

    ...theme.isDark ? {
    } : {
      background: theme.bg.alt,
    },

    '&:not(.small)': {
      '&, &::before': {
        height:       segmentedButtonHeight.normal,
        borderRadius: segmentedButtonHeight.normal / 2,
      },
    },
    '&.small': {
      '&, &::before': {
        height:       segmentedButtonHeight.small,
        borderRadius: segmentedButtonHeight.small / 2,
      },
    },

    '&:hover::before': {
      background: theme.bg.hover,
    },
    '&:focus': {
      boxShadow: shadows.focus.bold(theme),
    },

    '&.selected::before, &.selected:hover::before': {
      ...theme.isDark ? {
        background: theme.bg.active,
      } : {
        background: theme.semantic.primary,
      },
    },
  },
}))