import React from 'react'
import { Participant } from '~/models'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'
import { memo } from '~/ui/component'
import { register } from './registry'

export interface Props {
  item: Participant
}

const ParticipantSearchResult = memo('ParticipantSearchResult', (props: Props) => {

  const {item} = props

  //------
  // Rendering

  function render() {
    return (
      <ParticipantBar participant={item} />
    )
  }

  return render()

})

register('participants', ParticipantSearchResult)