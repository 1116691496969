import React from 'react'
import { isPlainObject } from 'lodash'
import Binding from './Binding'
import KeyCombination from './KeyCombination'
import {
  HotkeyOptions,
  KeyCombo,
  KeyComboMap,
  KeyCombos,
  KeyHandler,
  KeyHandlerMap,
  UseHotkeyHook,
} from './types'
import { isMac, isUnix, isWin, useContinuousRef } from './util'

export function useHotkey(combos: KeyCombos | null, handler: KeyHandler | KeyHandlerMap, options: HotkeyOptions = {}): UseHotkeyHook {
  const {
    bindWindow = true,
  } = options

  const combo      = getComboForPlatform(combos)
  const optionsRef = useContinuousRef(options)

  const currentBindingRef = React.useRef<Binding | null>(null)

  const bind = React.useCallback((element: HTMLElement | Window) => {
    const combinations = combo == null ? [] : KeyCombination.parseCombinations(combo)
    if (combinations.length === 0) { return null }

    const binding = new Binding(combinations, handler, optionsRef.current)
    binding.bind(element)
    return binding
  }, [combo, handler, optionsRef])

  React.useEffect(() => {
    if (!bindWindow) { return }

    const binding = bind(window)
    return () => {
      binding?.unbind()
    }
  }, [bind, bindWindow, combo, handler, options])

  const connect = React.useCallback((element: HTMLElement | Window | null) => {
    const binding = currentBindingRef.current
    if (binding?.element === element) { return }

    binding?.unbind()

    if (element != null) {
      currentBindingRef.current = bind(element)
    } else {
      currentBindingRef.current = null
    }
  }, [bind])

  const disconnect = React.useCallback((element: HTMLElement | Window | null) => {
    if (element != null && element !== currentBindingRef.current?.element) { return }

    currentBindingRef.current?.unbind()
    currentBindingRef.current = null
  }, [])

  return [
    connect,
    disconnect,
  ]
}

function getComboForPlatform(input: KeyCombos | null): KeyCombo | null {
  if (!isComboMap(input)) { return input }

  if (isWin()) {
    return input.win ?? input.other ?? null
  } else if (isMac()) {
    return input.mac ?? input.other ?? null
  } else if (isUnix()) {
    return input.unix ?? input.other ?? null
  } else {
    return input.other ?? null
  }
}

function isComboMap(input: KeyCombos | null): input is KeyComboMap {
  return isPlainObject(input)
}