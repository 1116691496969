import React from 'react'
import { createUseStyles, useStyling } from '~/ui/styling'
import { Label, Center, BrandedComponent } from '~/ui/components'

export interface Props {
  initials: string
  size:     Size

  classNames?: React.ClassNamesProp
}

export default function InitialsAvatar(props: Props) {

  const {initials, size, classNames} = props
  const {guide} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {

    const labelSize = Math.max(12, Math.min(size.width, size.height) * 0.4)
    const labelStyle: React.CSSProperties = {
      fontSize:   labelSize,
      fontWeight: 700,
      lineHeight: `${labelSize}px`,
    }

    return (
      <BrandedComponent branding={guide.avatar} height={size.height} classNames={[$.initialsAvatar, classNames]} style={{...size}}>
        <Center flex>
          <Label classNames='--no-override-fg' style={labelStyle}>
            {initials.toUpperCase()}
          </Label>
        </Center>
      </BrandedComponent>
    )
  }

  return render()

}

const useStyles = createUseStyles(theme => ({
  initialsAvatar: {
    // As this is considered an 'image' rather than a text, but the image cannot be dragged anyway, let's just disable
    // all pointer events, to prevent a caret showing up.
    pointerEvents: 'none',
    overflow:      'hidden',
  },
}))