import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextFeedback } from '~/models'
import { HBox, Label, TextField, VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  feedback: TextFeedback

  value?:    string
  onChange?: (value: string) => any
  onCommit?: () => any
}

export default function TextAnswerEntry(props: Props) {

  const {feedback, onChange, onCommit} = props

  const theme = useTheme()

  const [t] = useTranslation('challenges')

  let value = props.value ?? ''
  if (feedback.maxLength != null) {
    value = value.slice(0, feedback.maxLength)
  }

  //------
  // Rendering

  function render() {
    return (
      <VBox flex={feedback.multiline} gap={layout.padding.inline.xs}>
        {renderField()}
        {renderRemainingCharacters()}
      </VBox>
    )
  }

  function renderField() {
    return (
      <TextField
        type='flat'
        value={value}
        onChange={onChange}
        onCommit={onCommit}
        multiline={feedback.multiline}
        placeholder={feedback.placeholder ?? t('answer.text.default_placeholder')}
      />
    )
  }

  function renderRemainingCharacters() {
    if (feedback.maxLength == null) { return null }

    const remaining = feedback.maxLength - value.length
    const color     = remaining <= 0 ? theme.colors.semantic.negative : theme.fg.dim

    return (
      <HBox justify='right'>
        <Label tiny color={color}>
          {t('answers.text.characters_remaining', {count: remaining})}
        </Label>
      </HBox>
    )
  }

  return render()

}