import React from 'react'
import { NumericFeedback, numericFeedbackCaption } from '~/models'
import { memo } from '~/ui/component'
import { Label, Panel, Slider } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  feedback: NumericFeedback

  value?:    number
  onChange?: (value: number) => any
  onCommit?: () => any
}

const NumericAnswerEntry = memo('NumericAnswerEntry', (props: Props) => {

  const {feedback, value, onChange} = props

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} classNames={$.numericAnswerEntry} gap={layout.padding.s} justify='middle'>
        {renderSlider()}
        {renderLabel()}
      </Panel>
    )
  }

  function renderSlider() {
    return (
      <Slider
        value={value ?? null}
        min={feedback.min}
        max={feedback.max}
        step={feedback.step}
        onChange={onChange}
      />
    )
  }

  function renderLabel() {
    if (feedback.step == null) { return null }

    const label = value == null ? null : numericFeedbackCaption(feedback, value)
    return (
      <Label bold align='center'>
        {label ?? '\u00A0'}
      </Label>
    )
  }

  return render()

})

export default NumericAnswerEntry

const useStyles = createUseStyles({
  numericAnswerEntry: {
    padding: layout.padding.inline.l,
  },
})