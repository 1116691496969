/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'

export function useChainedCallback<A extends any[], R>(
  upstream: ((...args: A) => R) | undefined,
  callback: (upstream: ((...args: A) => R) | undefined, ...args: A) => R,
  deps:     any[],
): (...args: A) => R {
  return React.useCallback((...args: A) => {
    return callback(upstream, ...args)
  }, [upstream, ...deps])
}
