import * as layout from '../../layout'
import { BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class ChatBubbleBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ChatBubbleBranding | null,
  ) {
    super(delegate,parent, {
      background:  'alt',
      border:      'none',
      shape:       {rounded: layout.radius.l},
      depth:       1,
      previewSize: {width: 160, height: 64},
    })

    this.variant('own', {
      background: 'secondary',
    })
    this.variant('feedback', {
      background: 'secondary',
    })
  }

  public date = new ComponentBranding(this, null, {
    shape:      {rounded: layout.radius.m},
    background: 'alt',
    border:     'none',
    depth:      0,
  })

  public minHeight: number = 36
  public maxWidth: number = 270

  public sharpOuterCorner: boolean = false
  public noticeAlignment: NoticeAlignment = 'center'

}

export type NoticeAlignment = 'center' | 'stretch'