import React from 'react'
import { memo } from '~/ui/component'
import { HBox, KeyHint, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { colors, createUseStyles, layout, useStyling, useTheme } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { StandardMenuItem } from './types'

export interface Props {
  item:         StandardMenuItem
  checked?:     boolean
  hasKeyHints?: boolean
}

const StandardMenuItemButton = memo('StandardMenuItemButton', (props: Props) => {

  const {item, checked, hasKeyHints = false} = props
  const {caption, detail, icon, backgroundColor, accessory, keyHint, children = [], enabled = true, ...rest} = item

  const {colors} = useStyling()
  const theme    = useTheme()

  const defaultColor = backgroundColor && colors.isDark(backgroundColor)
    ? colors.fg.light.normal
    : colors.fg.dark.normal

  const color = item.color ?? defaultColor

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const mainAlign = detail == null ? 'middle' : 'top'

    return (
      <HBox classNames={[$.standardItemButton, {enabled}]} gap={layout.padding.inline.m}>
        <HBox flex='both' classNames={$.main} gap={layout.padding.inline.m} align={mainAlign}>
          {renderCheck()}
          {renderIcon()}
          {renderLabels()}
          {renderKeyHint()}
          {accessory}
        </HBox>
        {children.length > 0 && renderChevron()}
      </HBox>
    )
  }

  function renderCheck() {
    if (checked === undefined) { return null }

    return (
      <SVG
        name={checked ? 'check' : 'empty'}
        size={layout.icon.m}
        color={color}
      />
    )
  }

  function renderIcon() {
    if (typeof icon !== 'string') { return icon }

    return (
      <SVG
        classNames={[$.icon, detail != null && 'shift']}
        name={icon as SVGName}
        size={layout.icon.s}
        color={color}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex='both' gap={layout.padding.inline.s}>
        {isReactText(caption) ? (
          <Label color={color} truncate caption {...rest}>
            {caption}
          </Label>
        ) : (
          caption
        )}
        {isReactText(detail) ? (
          <Label color={color} small dim truncate={false}>
            {detail}
          </Label>
        ) : (
          detail
        )}
      </VBox>
    )
  }

  function renderKeyHint() {
    if (!hasKeyHints) { return null }

    return (
      <KeyHint
        keyHint={keyHint ?? null}
        dim
      />
    )
  }

  function renderChevron() {
    return (
      <SVG
        name='chevron-right'
        size={layout.icon.s}
        color={theme.fg.dim}
      />
    )
  }

  return render()

})

export default StandardMenuItemButton

const useStyles = createUseStyles(theme => ({
  standardItemButton: {
    padding:   [0, layout.padding.inline.l],

    '&:not(.enabled)': {
      ...colors.overrideForeground(theme.fg.dim),
    },
  },

  main: {
    minHeight: layout.barHeight.s,
    padding:   [layout.padding.inline.m, 0],
  },

  icon: {
    '&.shift': {
      marginTop:    '0.2em',
      marginBottom: '-0.2em',
    },
  },
}))