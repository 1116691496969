import { SVGName } from '~/ui/components/SVG'
import { Model } from './Model'

export class Media extends Model {

  public name!:        string
  public contentType!: string
  public url!:         string
  public fileSize!:    number

}

export function iconForMedia(media: Media): SVGName {
  if (/^image\//.test(media.contentType)) {
    return 'image'
  } else if (/^video\//.test(media.contentType)) {
    return 'video'
  } else {
    return 'page'
  }
}