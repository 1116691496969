import React from 'react'
import { isPendingMessage, Message, MessageListItem } from '~/models'
import { VideoView } from '~/ui/app/media'
import { useObjectURL } from '~/ui/hooks'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'video'}
  inlineReply: MessageListItem | null
}

const VideoBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const pendingVideoURL = useObjectURL(isPendingMessage(message) ? message.video.binary : null)
  const videoURL        = pendingVideoURL ?? (message instanceof Message ? message.asVideo.video.url : null)

  //------
  // Rendering

  function render() {
    return (
      <ChatBubble message={message} inlineReply={inlineReply}>
        {videoURL != null && (
          <VideoView
            source={videoURL}
            lightbox
          />
        )}
      </ChatBubble>
    )
  }

  return render()

})

export default VideoBubble