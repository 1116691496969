import { switchRegExp } from 'ytil'

export default class HrefResolver {

  private static resolvers: Map<RegExp, (match: RegExpMatchArray) => string> = new Map()

  public static register(regExp: RegExp, resolver: (match: RegExpMatchArray) => string) {
    HrefResolver.resolvers.set(regExp, resolver)
  }

  public static unregister(regExp: RegExp) {
    HrefResolver.resolvers.delete(regExp)
  }

  public static shouldResolve(href: string) {
    for (const regExp of HrefResolver.resolvers.keys()) {
      if (regExp.test(href)) { return true }
    }
    return false
  }

  public static resolve(href: string) {
    return switchRegExp(href, Array.from(HrefResolver.resolvers), href)
  }
}