import Clipboard from './Clipboard'
import config from './config'
import { ClipboardItem, ClipboardQueryOptions, PreviewListener } from './types'

export default class ClipboardQuery<T> {

  constructor(
    private readonly clipboard: Clipboard,
    private readonly type: string,
    private readonly options: ClipboardQueryOptions = {},
  ) {}

  private pollTimer: NodeJS.Timer | null = null
  private pollInterval = this.options.pollInterval ?? config.query.pollInterval

  public preview: ClipboardItem<T> | null = null

  public getData(): T | null {
    const item = this.clipboard.getData<T>(this.type, true)
    return item?.data ?? null
  }

  public start(listener?: PreviewListener<T>) {
    if (this.pollTimer != null) { return }

    this.pollTimer = setInterval(this.poll, this.pollInterval)
    this.poll()

    if (listener != null) {
      this.addListener(listener)
    }

    return () => {
      this.stop()
    }
  }

  public stop() {
    if (this.pollTimer == null) { return }
    clearInterval(this.pollTimer)
  }

  private poll = () => {
    this.preview = this.clipboard.getData(this.type, false)
    this.listeners.forEach(listener => listener(this, this.preview))
  }

  //------
  // Listeners

  private listeners = new Set<PreviewListener<T>>()

  public addListener(listener: PreviewListener<T>) {
    this.listeners.add(listener)
    return () => { this.listeners.delete(listener) }
  }

}