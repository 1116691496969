import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'moment'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { Panel, Timeline } from '~/ui/components'
import { TimelineItem } from '~/ui/components/Timeline'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  challengeID: string
}

const ChallengeTimeline = observer('ChallengeTimeline', (props: Props) => {

  const {challengeID} = props

  const [t] = useTranslation('challenges')

  const document = challengesStore.challenges.document(challengeID)

  const state     = document.state
  const challenge = document.data

  const publishedAt  = state?.publishedAt
  const startedAt    = state?.startedAt
  const completedAt  = state?.completedAt
  const abandonedAt  = state?.abandonedAt
  const reviewedAt   = state?.reviewedAt
  const deadline     = state?.deadline
  const pastDeadline = deadline == null ? false : new Date(deadline) < new Date()

  const items = React.useMemo(() => {
    const items: TimelineItem[] = []

    items.push({
      completed: true,
      caption:   t('timeline.published'),
      detail:    publishedAt == null ? null : formatDate(publishedAt),
    })

    items.push({
      completed: startedAt != null,
      caption:   t('timeline.started'),
      detail:    startedAt == null ? null : formatDate(startedAt),
    })

    if (pastDeadline) {
      items.push({
        completed: false,
        caption:   t('timeline.deadline'),
        detail:    deadline == null ? null : formatDate(deadline),
      })
    } else {
      if (abandonedAt == null) {
        items.push({
          completed: completedAt != null,
          caption: t('timeline.completed'),
          detail: completedAt == null ? null : formatDate(completedAt),
        })
        if (challenge?.needsReview) {
          items.push({
            completed: reviewedAt != null,
            caption: t('timeline.reviewed'),
            detail: reviewedAt == null ? null : formatDate(reviewedAt),
          })
        }
      } else {
        items.push({
          completed: abandonedAt != null,
          caption: t('timeline.abandoned'),
          detail: abandonedAt == null ? null : formatDate(abandonedAt),
        })
      }
    }

    return items
  }, [abandonedAt, challenge?.needsReview, completedAt, deadline, pastDeadline, publishedAt, reviewedAt, startedAt, t])

  //------
  // Rendering

  const theme = useTheme()
  const $ = useStyles()

  function render() {
    return (
      <Panel backgroundColor={theme.bg.alt} classNames={$.panel}>
        <Timeline items={items} />
      </Panel>

    )
  }

  function formatDate(date: Date | string) {
    return Moment(date).format('D MMM, HH:mm')
  }

  return render()

})

const useStyles = createUseStyles({
  panel: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})

export default ChallengeTimeline