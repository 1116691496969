import { BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class SliderBranding {

  constructor(
    public readonly delegate: BrandingGuideDelegate,
    private readonly parent: SliderBranding | null = null,
  ) {
    this.track = new ComponentBranding(delegate, this.parent?.thumb ?? null, {
      background: 'alt',
      border:     'none',
      shape:      'oval',
      depth:      0,
    })

    this.thumb = new ComponentBranding(delegate, this.parent?.thumb ?? null, {
      background: 'alt',
      border:     'none',
      shape:      'oval',
      depth:      1,
    })
  }

  public track: ComponentBranding
  public thumb: ComponentBranding
  public thumbSize: number = 16

}