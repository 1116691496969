import React from 'react'
import { StringStream } from 'unicode'
import { SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'

//------
// Markup

const buildMatchers = (variables: boolean) => {
  const matchers: BoundaryMatcher[] = [
    ['em',       '***', '***', true,  content => <em>{content}</em>],
    ['strong',   '**',  '**',  true,  content => <strong>{content}</strong>],
    ['italic',   '*',   '*',   true,  content => <span classNames='italic'>{content}</span>],
    ['italic',   '_',   '_',   true,  content => <span classNames='italic'>{content}</span>],
    ['variable', '``',   '``', false, content => <var>{content}</var>],
    ['mono',     '`',   '`',   true,  content => <span classNames='mono'>{content}</span>],
    ['box',      '[',   ']',   true,  content => <span classNames='box'>{content}</span>],
    ['icon',     '!ic[', ']',  false, name    => <SVG name={name as SVGName} inline size='0.8em'/>],
  ]
  if (variables) {
    matchers.push(['variable','{{', '}}', false, content => <var>{content}</var>])
  }

  return matchers
}

export function parseMarkup(text: string, $: Record<string, string>, variables: boolean): React.ReactNode[] {

  let key = 0

  const matchers = buildMatchers(variables)
  const openings = matchers.map(matcher => matcher[1])

  const parse = (text: string, offset: number, wrapInNode: boolean): React.ReactNode => {
    const stream = new StringStream(text, {
      escapeChar: '\\',
    })
    stream.markStart()
    stream.advanceTo(offset)

    const startFound = stream.eatUntil(openings)
    if (!startFound) {
      if (wrapInNode) {
        return [<span key={key++}>{stream.current()}</span>]
      } else {
        return stream.current()
      }
    }

    const nodes: React.ReactNode[] = []

    const pre = stream.current()
    if (pre.length > 0) {
      nodes.push(<span key={key++}>{pre}</span>)
    }

    const matcher = matchers.find(m => stream.match(m[1]))!

    // Eat the boundary and mark a new start.
    stream.advance(matcher[1].length)
    stream.markStart()

    // Eat all content until the end boundary. Will eat all the way until the end if not found.
    const endFound = stream.eatUntil(matcher[2])
    if (!endFound) {
      nodes.push(<span key={key++}>{stream.current()}</span>)
      return nodes
    }

    const content = matcher[3]
      ? parse(stream.current(true), !matcher[3] ? matcher[1].length : 0, false)
      : stream.current(true)

    const node = matcher[4](content, $)
    if (React.isValidElement(node)) {
      nodes.push(React.cloneElement(node, {key: key++}))
    } else {
      nodes.push(node)
    }

    // Continue.
    stream.advance(matcher[2].length)
    stream.markStart()
    stream.eatUntilEos()

    const remainder = stream.current(true)
    if (remainder.length > 0) {
      nodes.push(...parse(remainder, 0, true) as React.ReactNode[])
    }

    return nodes
  }

  return parse(text, 0, true) as React.ReactNode[]
}

type BoundaryMatcher = [
  /*name:*/    string,
  /*opening:*/ string,
  /*closing:*/ string,
  /*recurse:*/ boolean,
  /*resolve:*/ (content: React.ReactNode, styles: Record<string, string>) => React.ReactNode,
]