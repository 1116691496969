import React from 'react'
import { memo } from '~/ui/component'
import { Center, flexStyle, SVG, Tappable, useLightbox, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  source:   string
  caption?: string

  size?:           Size
  flex?:           VBoxProps['flex']
  height?:         number
  objectFit?:      React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']

  lightbox?:   boolean
  controls?:   boolean
  overlay?:    boolean
  classNames?: React.ClassNamesProp
}

const VideoView = memo('Video', (props: Props) => {

  const {
    source,
    caption,
    size,
    flex,
    height,
    objectFit = 'cover',
    objectPosition,
    lightbox,
    overlay = lightbox,
    classNames,
    controls = !overlay,
  } = props

  const {show} = useLightbox()

  const showInLightbox = React.useCallback((event: React.SyntheticEvent) => {
    if (!lightbox) { return }
    show({
      type:    'video',
      url:     source,
      caption: caption,
    })
  }, [lightbox, source, caption, show])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (lightbox) {
      return (
        <Tappable flex={flex} onTap={showInLightbox}>
          {renderVideo()}
        </Tappable>
      )
    } else {
      return renderVideo()
    }
  }

  function renderVideo() {
    const style: React.CSSProperties = {
      ...size,
      objectFit,
      objectPosition,
    }
    if (height != null) {
      style.height = height
    }
    Object.assign(style, flexStyle(flex))

    return (
      <VBox flex={flex} classNames={[$.VideoView, classNames]}>
        <video
          src={source}
          style={style}
          controls={controls && !lightbox}
        />
        {renderOverlay()}
      </VBox>
    )
  }

  function renderOverlay() {
    if (!overlay) { return null }
    return (
      <Center flex className={$.overlay}>
        <ThemeProvider dark>
          <SVG name='play' size={layout.icon.xxl}/>
        </ThemeProvider>
      </Center>
    )
  }

  return render()

})

export default VideoView

const useStyles = createUseStyles({
  VideoView: {
  },

  overlay: {
    ...layout.overlay,
  },
})