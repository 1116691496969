import React from 'react'
import { memo } from '~/ui/component'
import { Center, Dimple, HBox, Label, SVG, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  status?:   number
  title:     React.ReactNode
  detail?:   React.ReactNode | null
  flex?:     VBoxProps['flex']
  children?: React.ReactNode
}

const ErrorDisplay = memo('ErrorDisplay', (props: Props) => {

  const {status, title, detail, flex = true, children} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} justify='middle' gap={layout.padding.l}>
        <Center>
          <SVG name='logo' size={logoSize} classNames={$.logo}/>
        </Center>
        <HBox gap={layout.padding.inline.l} align='stretch' justify='center'>
          {renderStatus()}
          {status != null && <Dimple vertical/>}
          <VBox flex='shrink' gap={layout.padding.s} align='left'>
            {renderLabels()}
            {children}
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderStatus() {
    if (status == null) { return null }

    return (
      <Center>
        <Label bold classNames={$.errorStatus}>
          {status}
        </Label>
      </Center>
    )
  }

  function renderLabels() {
    const align = status == null ? 'center' : 'left'

    return (
      <VBox gap={layout.padding.inline.s}>
        {isReactText(title) ? <Label h3 align={align}>{title}</Label> : title}
        {isReactText(detail) ? <Label small dim truncate={false} align={align}>{detail}</Label> : detail}
      </VBox>
    )
  }

  return render()

})

export default ErrorDisplay

const logoSize = {
  width:  96,
  height: 96,
}

const useStyles = createUseStyles(theme => ({
  logo: {
    animation: '$spin 10s linear',
    transform: 'rotate(360deg) scale(0.2)',
    color:     theme.semantic.primary.desaturate(1).lighten(0.5),
    opacity:   0,
  },

  errorStatus: {
    fontSize: 40,
  },

  '@keyframes spin': {
    '0%':   {
      transform: 'rotate(0deg)',
      color:     theme.semantic.primary,
      opacity:   1,
    },
    '50%':  {
      transform: 'rotate(180deg) scale(0.6)',
      color:     theme.semantic.primary.desaturate(0.7),
      opacity:   0.8,
    },
    '100%': {
      transform: 'rotate(360deg) scale(0.2)',
      color:     theme.semantic.primary.desaturate(1),
      opacity:   0,
    },
  },
}))