import { ElementConnect, useDrag, UseDragSpec } from 'react-dnd'
import { SortableItem } from './data'
import { useElementConnect } from './hooks'
import {
  calculateMeasurements,
  cleanUpMeasurements,
  registerItem,
  unregisterItem,
} from './measurements'

export default function useSortableDrag<TItem extends SortableItem>(
  spec: UseDragSpec<TItem>,
): [ElementConnect, ElementConnect] {
  const [connect, connectHandle] = useDrag<TItem>({
    ...spec,

    start: (item, monitor) => {
      const element = getElement()
      if (element != null) {
        registerItem(item, element)
      }

      calculateMeasurements()
      spec.start?.(item, monitor)
    },

    end: (item, monitor) => {
      spec.end?.(item, monitor)

      unregisterItem(item)
      cleanUpMeasurements()
    },
  })

  const [connectDragSource, getElement] = useElementConnect(connect)
  const [connectDragHandle]             = useElementConnect(connectHandle)
  return [connectDragSource, connectDragHandle]
}