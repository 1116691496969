import React from 'react'
import { BrandingGuide, ColorGuide } from './branding'
import FontGuide from './branding/FontGuide'
import { Theme } from './Theme'
import { theming } from './ThemeContext'

export interface StylingContext {
  guide:  BrandingGuide
  colors: ColorGuide
  fonts:  FontGuide
}

export function createStylingContext(guide: BrandingGuide): StylingContext {
  return {
    guide:  guide,
    colors: guide.colors,
    fonts:  guide.fonts,
  }
}

const defaultContext = createStylingContext(new BrandingGuide())
export const StylingContext = React.createContext<StylingContext>(defaultContext)

export interface StylingProviderProps {
  guide:     BrandingGuide
  children?: React.ReactNode
}

export const StylingProvider = (props: StylingProviderProps) => {
  const {guide, children} = props

  const context = React.useMemo(
    (): StylingContext => createStylingContext(guide),
    [guide],
  )

  const theme = React.useMemo(
    () => Theme.create(guide, guide.colors.darkMode ? 'dark' : 'light'),
    [guide],
  )

  return (
    <StylingContext.Provider value={context}>
      <theming.ThemeProvider theme={theme}>
        {children}
      </theming.ThemeProvider>
    </StylingContext.Provider>
  )
}

export function useStyling() {
  return React.useContext(StylingContext)
}