import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ChannelHeader from './ChannelHeader'
import { useChat } from './ChatContext'

const ChatHeader = observer(() => {

  const {channel} = useChat()

  const [t] = useTranslation('chat')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.ChatHeader}>
        {channel == null ? (
          renderDefaultHeader()
        ) : (
          <ChannelHeader
            controller={channel}
          />
        )}
      </VBox>
    )
  }

  function renderDefaultHeader() {
    return (
      <HBox gap={layout.padding.inline.m} classNames={$.defaultHeader}>
        <SVG
          name='chat'
          size={layout.icon.m}
        />
        <Label bold>
          {t('title')}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default ChatHeader

export const height = layout.barHeight.l

const useStyles = createUseStyles(theme => ({
  ChatHeader: {
    borderBottom: [2, 'solid', theme.border.dimmer],
  },

  defaultHeader: {
    height,
    padding: [
      layout.padding.inline.m,
      layout.padding.inline.l,
    ],
  },
}))