import BrandingGuide from '../BrandingGuide'
import { ComponentBranding } from '../components'
import OAuthButtonBranding from './OAuthButtonBranding'
import PanelBranding from './PanelBranding'
import TimelineBranding from './TimelineBranding'
import WebTabBarBranding from './WebTabBarBranding'

export default class WebBrandingGuide {

  constructor(
    public readonly rootGuide: BrandingGuide,
  ) {}

  public readonly brandingContainer = true

  public header = new ComponentBranding(this, null, {
    background:  'primary',
    border:      'none',
    shape:       'rectangle',
    depth:       5,
    previewSize: {width: 480, height: 56},
  })

  public tabBar = new WebTabBarBranding(this, null)

  public panel        = new PanelBranding(this, null)
  public summaryPanel = new PanelBranding(this, this.panel)

  public schedule = new TimelineBranding(this, null)

  public pushButton = new ComponentBranding(this, null, {
    background: 'alt',
    border:     'none',
    shape:      'oval',
    depth:      1,
  })

  public authPanel = new PanelBranding(this, this.panel, {
    depth: 2,
  })

  public registrationPanel = new PanelBranding(this, this.panel, {
    depth: 2,
  })

  public login = {
    oAuthButton: new OAuthButtonBranding(this, null),
  }

}