import { computed, makeObservable, observable } from 'mobx'
import { Message } from '~/models'
import { isPendingMessage, PendingMessage } from './PendingMessage'

export type MessageListItem = Message | PendingMessage

/**
 * Message list data structure, allowing for prepending of new messages, and appending of older messages.
 * Messages are stored in reverse chronological order in this structure.
 */
export class MessageList {

  constructor(
    messagesDescending: MessageListItem[] = [],
    map?:             Map<string, MessageListItem>,
  ) {
    this.messagesDescending = [...messagesDescending]
    this.map           = map ?? deriveMap(messagesDescending)

    makeObservable(this)
  }

  private map: Map<string, MessageListItem>

  @observable.shallow
  public messagesDescending: MessageListItem[]

  @computed
  public get messageIDsDescending() {
    return this.messagesDescending.map(msg => msg.id)
  }

  @computed
  public get messagesAscending() {
    return [...this.messagesDescending].reverse()
  }

  public get(id: string) {
    return this.map.get(id) ?? null
  }

  public get count(): number {
    return this.messagesAscending.length
  }

  //------
  // Advanced retrieval

  /**
   * Receives the most recent message, skipping any pending messages.
   */
  public get mostRecentMessage(): Message | null {
    for (let i = 0; i < this.messagesDescending.length; i++) {
      const message = this.messagesDescending[i]
      if (!isPendingMessage(message)) {
        return message
      }
    }

    return null
  }

  public newMessagesSince(prev: MessageList) {
    const lastID = prev.mostRecentMessage?.id
    if (lastID == null) { return this.messagesAscending }

    const newMessages: MessageListItem[] = []
    for (const message of this.messagesDescending) {
      if (message.id === lastID) { break }
      newMessages.unshift(message)
    }

    return newMessages
  }

  public getAscendingIndex(messageID: string) {
    const index = this.messagesAscending.findIndex(msg => msg.id === messageID)
    if (index === -1) { return null }
    return index
  }

  public getDescendingIndex(messageID: string) {
    const index = this.messagesDescending.findIndex(msg => msg.id === messageID)
    if (index === -1) { return null }
    return index
  }

  public prependMessages(messages: MessageListItem[]): MessageList {
    return this.insertMessages(messages, (messages, toInsert) => [...toInsert, ...messages])
  }

  public appendMessages(messages: MessageListItem[]): MessageList {
    return this.insertMessages(messages, (messages, toInsert) => [...messages, ...toInsert])
  }

  public insertMessages(newMessages: MessageListItem[], insert: (messages: MessageListItem[], newMessages: MessageListItem[]) => MessageListItem[]) {
    const newMap = new Map(this.map)
    const toReplace: MessageListItem[] = []
    const toInsert: MessageListItem[] = []
    for (const message of newMessages) {
      if (newMap.has(message.id)) {
        toReplace.push(message)
      } else {
        newMap.set(message.id, message)
        toInsert.push(message)
      }
    }

    let newList = new MessageList(
      insert(this.messagesDescending, toInsert),
      newMap,
    )

    for (const message of toReplace) {
      newList = newList.updateMessage(message.id, () => message)
    }

    return newList
  }

  public updateMessage<M extends MessageListItem>(id: string, update: (message: M) => M): MessageList {
    const messages = this.messagesDescending.map(message => {
      if (message.id === id) {
        return update(message as M)
      } else {
        return message
      }
    })

    return new MessageList(messages)
  }

  public updatePendingMessage(id: string, update: (message: PendingMessage) => MessageListItem): MessageList {
    const messages = this.messagesDescending.map(message => {
      if (message.id === id && isPendingMessage(message)) {
        return update(message)
      } else {
        return message
      }
    })

    return new MessageList(messages)
  }

  public removeMessage(id: string) {
    const messages = this.messagesDescending.filter(it => it.id !== id)
    return new MessageList(messages)
  }

  public slice(start: number, end?: number) {
    const messages = this.messagesDescending.slice(start, end)
    return new MessageList(messages)
  }

  public [Symbol.iterator]() {
    return this.messagesDescending[Symbol.iterator]()
  }

  //------
  // Serialization

  public static deserialize(raw: MessageListItem[]) {
    return new MessageList(raw)
  }

  public serialize(): MessageListItem[] {
    return this.messagesDescending
  }


}

function deriveMap(messages: MessageListItem[]) {
  const map = new Map()
  for (const message of messages) {
    map.set(message.id, message)
  }
  return map
}