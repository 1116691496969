import React from 'react'
import { isArray } from 'lodash'
import {
  ChoiceFeedback,
  FeedbackChoice,
  feedbackChoiceValue,
  isMultiSelectChoiceFeedback,
} from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, RadioButton, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props {
  feedback: ChoiceFeedback

  value:    Primitive | Primitive[]
  onChange: (value: Primitive | Primitive[]) => any
}

const ChoiceAnswerEntry = memo('ChoiceAnswerEntry', (props: Props) => {

  const {feedback, value, onChange} = props

  const values = React.useMemo(
    () => isArray(value) ? value : [value],
    [value],
  )

  const toggleChoice = React.useCallback((choice: FeedbackChoice) => {
    const value = feedbackChoiceValue(choice)

    if (!isMultiSelectChoiceFeedback(feedback)) {
      onChange(value)
    } else if (values.includes(value)) {
      onChange(values.filter(val => val !== value))
    } else {
      onChange([...values, value])
    }
  }, [feedback, onChange, values])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.s}>
        {feedback.choices.map(renderChoice)}
      </VBox>
    )
  }

  function renderChoice(choice: FeedbackChoice) {
    const value = feedbackChoiceValue(choice)

    return (
      <ChoiceRow
        key={choice.value ?? choice.caption}
        choice={choice}
        selected={values.includes(value)}
        onSelect={toggleChoice}
      />
    )
  }

  return render()

})

export default ChoiceAnswerEntry

interface ChoiceHBoxProps {
  choice:   FeedbackChoice
  selected: boolean
  onSelect: (choice: FeedbackChoice) => any
}

const ChoiceRow = memo('ChoiceRow', (props: ChoiceHBoxProps) => {

  const {choice, selected, onSelect} = props
  const {guide} = useStyling()

  const select = React.useCallback(() => {
    onSelect(choice)
  }, [onSelect, choice])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent classNames={$.ChoiceRow} branding={guide.choiceRow} variant={{selected}} height={choiceRowMinHeight}>
        <Tappable flex='grow' onTap={select} classNames={$.button}>
          {renderContent()}
        </Tappable>
      </BrandedComponent>
    )
  }

  function renderContent() {
    return (
      <HBox gap={layout.padding.inline.m} flex='grow'>
        <RadioButton
          checked={selected}
          branding={guide.feedback.choiceRowRadioButton}
        />
        <VBox flex>
          <Label bold truncate={false}>
            {choice.caption}
          </Label>
        </VBox>
      </HBox>
    )
  }

  return render()

})

const choiceRowMinHeight = 54

const useStyles = createUseStyles({
  ChoiceRow: {
    minHeight: choiceRowMinHeight,
  },

  button: {
    padding:      [layout.padding.inline.m, layout.padding.inline.l],
    borderRadius: 'inherit',
  },
})