import socket from 'socket.io-react'
import {
  CollectionFetchOptions,
  CollectionFetchResponse,
  Database,
  Endpoint,
  EndpointOptions,
} from '~/lib/mobx-document'
import { Challenge } from '~/models'
import ChallengeDocument from './ChallengeDocument'

export default class ChallengesEndpoint extends Endpoint<ChallengeDocument, ChallengesParams, ChallengesMeta> {

  public constructor(
    public readonly scope: ChallengeScope,
    database: Database<ChallengeDocument>,
    options:  EndpointOptions<ChallengeDocument, ChallengesMeta> = {},
  ) {
    super(database, {}, options)
  }

  public async fetchNextPage() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  public async performFetch(
    params: ChallengesParams,
    options: CollectionFetchOptions,
  ): Promise<CollectionFetchResponse<Challenge, ChallengesMeta> | null> {
    const response = await socket.fetch(`challenges:list:${this.scope}`, {
      ...params,
      ...options,
    })

    if (!response.ok) {return response}

    const pack = response.body
    const data = pack.data
    const meta = pack.meta

    return {data, meta}
  }

}

export type ChallengeScope = 'all' | 'open' | 'completed' | 'results'

export interface ChallengesParams {
  search?: string | null
}

export interface ChallengesMeta {
  total:      number
  nextOffset: number | null
}