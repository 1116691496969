import React from 'react'
import { JssContext } from 'react-jss'
import { StyleSheet } from 'jss'
import { isArray, isPlainObject } from 'lodash'
import { sparse } from 'ytil'
import { layout } from '~/ui/styling'
import jss from '~/ui/styling/jss'
import { ResponsiveProp } from '~/ui/styling/layout'

const cache: Map<string, StyleSheet> = new Map()

//------
// Padding

export function usePaddingStyles(padding: number | ResponsiveProp<number> | undefined, paddingHorizontal: number | ResponsiveProp<number> | undefined, paddingVertical: number | ResponsiveProp<number> | undefined) {
  const {registry} = React.useContext(JssContext)
  if (padding == null && paddingHorizontal == null && paddingVertical == null) { return {} }

  const count = (padding ? 1 : 0) + (paddingHorizontal ? 1 : 0) + (paddingVertical ? 1 : 0)
  if (count > 1) { throw new Error("Specify one of padding, paddingHorizontal or paddingVertical") }

  const side  = padding != null ? 'both' : paddingHorizontal != null ? 'horizontal' : 'vertical'
  const value = (padding ?? paddingHorizontal ?? paddingVertical)!

  const namespace  = padding != null ? 'padding' : paddingHorizontal != null ? 'padding-horizontal' : 'padding-vertical'
  const key        = styleSheetKey(namespace, [value])
  const styleSheet = cache.get(key) ?? createStyleSheetForPadding(key, side, value)

  styleSheet.attach()
  registry?.add(styleSheet)
  cache.set(key, styleSheet)
  return styleSheet.classes
}

function createStyleSheetForPadding(key: string, side: 'horizontal' | 'vertical' | 'both', padding: number | ResponsiveProp<number>) {
  if (typeof padding === 'number' || isArray(padding)) {
    return jss.createStyleSheet({
      padding: {
        padding: side === 'both' ? padding : side === 'horizontal' ? [0, padding] : [padding, 0],
      },
    }, {
      generateId: () => key,
    })
  } else {
    return jss.createStyleSheet({
      padding: {
        ...layout.responsive(size => {
          const value = padding[size] ?? padding.tablet
          return {
            padding: side === 'both' ? value : side === 'horizontal' ? [0, value] : [value, 0],
          }
        }),
      },
    }, {
      generateId: () => key,
    })
  }
}

//------
// Gap (column / row)

export function useGapStyles(direction: 'row' | 'column', gap: number | ResponsiveProp<number> | undefined, wrap: boolean) {
  const {registry} = React.useContext(JssContext)
  if (gap == null) { return {} }

  const key = styleSheetKey(direction, [gap], wrap ? 'w' : '')
  const styleSheet = cache.get(key) ?? createStyleSheetForGap(key, direction, gap, wrap)
  styleSheet.attach()
  registry?.add(styleSheet)
  cache.set(key, styleSheet)
  return styleSheet.classes
}

function createStyleSheetForGap(key: string, direction: 'row' | 'column', gap: number | ResponsiveProp<number>, wrap: boolean) {
  const marginProp = direction === 'row' ? 'marginLeft' : 'marginTop'

  if (typeof gap === 'number') {
    return jss.createStyleSheet({
      gap: wrap ? {
        marginRight:  -gap,
        marginBottom: -gap,
        '& > *': {
          marginRight:  gap,
          marginBottom: gap,
        },
      } : {
        '& > :not(:first-child)': {
          [marginProp]: gap,
        },
      },
    }, {
      generateId: () => key,
    })
  } else {
    return jss.createStyleSheet({
      gap: wrap ? {
        ...layout.responsive(size => ({
          marginRight:  -(gap[size] ?? 0),
          marginBottom: -(gap[size] ?? 0),
        })),
        '& > *': {
          ...layout.responsive(size => ({
            marginRight:  gap[size],
            marginBottom: gap[size],
          })),
        },
      } : {
        '& > :not(:first-child)': {
          ...layout.responsive(size => ({
            [marginProp]: gap[size] ?? gap.tablet,
          })),
        },
      },
    }, {
      generateId: () => key,
    })
  }
}

//------
// Grid gap

export function useGridGapStyles(columnGap: number | ResponsiveProp<number> | undefined, rowGap: number | ResponsiveProp<number> | undefined) {
  const {registry} = React.useContext(JssContext)
  if (rowGap == null && columnGap == null) { return {} }

  const key = styleSheetKey('grid', [columnGap, rowGap])
  const styleSheet = cache.get(key) ?? createStyleSheetForGridGap(key, columnGap, rowGap)
  styleSheet.attach()
  registry?.add(styleSheet)
  cache.set(key, styleSheet)
  return styleSheet.classes
}

function createStyleSheetForGridGap(key: string, columnGap: number | ResponsiveProp<number> | undefined, rowGap: number | ResponsiveProp<number> | undefined) {
  return jss.createStyleSheet({
    gap: {
      columnGap: typeof columnGap === 'number' ? `${columnGap}px` : '',
      rowGap:    typeof rowGap === 'number' ? `${rowGap}px` : '',

      ...layout.responsive(size => {
        const styles: Record<string, any> = {}
        if (isPlainObject(columnGap)) {
          styles.columnGap = `${(columnGap as any)[size]}px`
        }
        if (isPlainObject(rowGap)) {
          styles.rowGap = `${(rowGap as any)[size]}px`
        }
        return styles
      }),
    },
  }, {
    generateId: () => key,
  })
}

//------
// Dynamic keys

function styleSheetKey(namespace: string, values: Array<number | ResponsiveProp<number> | undefined>, suffix?: string) {
  const parts: Array<string | undefined> = [namespace]
  for (const value of values) {
    if (value == null) {
      parts.push('*')
    } else if (typeof value === 'number') {
      parts.push(`${value}`)
    } else {
      parts.push(Object.entries(value).map(([size, value]) => `${size}-${value}`).join(':'))
    }
  }
  parts.push(suffix)

  return sparse(parts).join(':')
}

//------
// Flex style

export type FlexProp = number | boolean | FlexShorthand | FlexSpec | string
export type FlexShorthand = 'shrink' | 'grow' | 'both'
export interface FlexSpec {
  grow?:   number | boolean
  shrink?: number | boolean
  basis?:  number | null
}

export function flexStyle(flex: FlexProp | undefined) {
  if (!flex) { return {} }

  if (flex === 'shrink') {
    return {flex: '0 1 auto'}
  } else if (flex === 'grow') {
    return {flex: '1 0 auto'}
  } else if (flex === 'both') {
    return {flex: '1 1 auto'}
  } else if (flex === true) {
    return {flex: '1 0 0px'}
  } else if (typeof flex === 'number') {
    return {flex: `${flex} 0 0px`}
  } else if (typeof flex === 'string') {
    return {flex: flex}
  } else {
    return {
      flex: [
        flex.grow === true ? 1 : (flex.grow ?? 0),
        flex.shrink === true ? 1 : (flex.shrink ?? 0),
        flex.basis == null ? 'auto' : `${flex.basis}px`,
      ].join(' '),
    }
  }
}

export function shrinkFrom(basis: number, shrink: number = 1) {
  return `0 ${shrink} ${basis}px`
}

export function growFrom(basis: number, grow: number = 1) {
  return `${grow} 0 ${basis}px`
}

export function growShrinkFrom(basis: number, grow: number = 1, shrink: number = 1) {
  return `${grow} ${shrink} ${basis}px`
}

export function flexIgnoresContentSize(flex: FlexProp | undefined) {
  return flex === true || typeof flex === 'number'
}

/**
 * Derives a sensible flex value for a child element based on the parent's flex value, given that the child
 * element should occupy the remaining space, and behave as its parent.
 *
 * @param parentFlex The parent flex value.
 */
export function childFlex(parentFlex: FlexProp | undefined) {
  // 1. If the parent is explicitly set to shrink, grow or both, the children should also follow this.
  if (parentFlex === 'shrink' || parentFlex === 'grow' || parentFlex === 'both') {
    return parentFlex
  }

  // 2. If the parent flex is another string, parse it and follow the same rules above. If there is no
  //    basis set, or if it's set to "auto" (which is the default), the children should also be set to
  //    the most permissive 'both'.
  if (typeof parentFlex === 'string') {
    const [grow, shrink, basis] = parentFlex.split(' ')
    if (basis == null || basis === 'auto') { return 'both' }
    return !!grow && !!shrink ? 'both' : !!grow ? 'grow' : 'shrink'
  }

  // 3. In any other case, the children should be set to 'both'.
  return 'both'
}