import { merge } from 'lodash'

export interface Config {
  getRoot: () => HTMLElement | null
}

const config: Config = {
  getRoot: () => null,
}

export default config

export function configure(cfg: Partial<Config>) {
  merge(config, cfg)
}