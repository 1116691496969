import React from 'react'
import { useTranslation } from 'react-i18next'
import * as MIME from 'mime'
import { Answer, MediaFeedback } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { AnswerOutcomeRenderUtil } from '../../tasks/ChallengeTaskAnswerOutcome'
import { mediaAnswerEntrySize } from './MediaAnswerEntry'

export interface Props {
  feedback:   MediaFeedback
  answer:     Answer
  renderUtil: AnswerOutcomeRenderUtil
}

const MediaAnswerOutcome = memo('MediaAnswerOutcome', (props: Props) => {

  const {answer, renderUtil} = props

  const mimeType = answer == null ? null : (MIME.getType(answer.answer) ?? 'image/png')

  const [t] = useTranslation('challenges')

  function render() {
    if (answer == null || mimeType == null) { return null }

    return (
      <VBox>
        {renderUtil.renderCaption(t('task.your_answer'), true)}
        {renderUtil.renderLine()}
        {renderMedia()}
      </VBox>
    )
  }

  function renderMedia() {
    if (answer == null || mimeType == null) { return null }

    if (/^image\//.test(mimeType)) {
      return (
        <ImageView
          source={answer.answer}
          size={mediaAnswerEntrySize}
          objectFit='contain'
        />
      )
    } else {
      return null
      // TODO
      // return (
      //   <Video
      //     source={{uri: answer}}
      //     style={[$.media, imageStyle]}
      //   />
      // )
    }
  }

  return render()

})

export default MediaAnswerOutcome