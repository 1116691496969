/* eslint-disable no-console */
import { some } from 'lodash'

const ignore = [
  /`shouldComponentUpdate`.*`observer`.*mobx-react/,
  /componentWillMount has been renamed/,
  /Attempted import error: /,
  /export '.*' \(reexported as '.*'\)/,
  /no-redeclare/,
  /DevTools failed to load SourceMap:/,
  /Critical dependency: the request of a dependency is an expression/,
]

const origWarn = console.warn
console.warn = function (...args: [any?, ...any[]]) {
  if (typeof args[0] === 'string' && some(ignore, pattern => !!pattern.test(args[0]))) {
    return
  }

  origWarn.apply(this, args)
}