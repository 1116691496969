import { omit } from 'lodash'
import { Database, Document, DocumentOptions } from 'mobx-document'
import { ScopedSocket } from 'socket.io-react'
import { Feed, Media, Post } from '~/models'
import { submitResultForResponse } from '../support'
import CommentsEndpoint from './CommentsEndpoint'

export default class PostDocument extends Document<Post> {

  constructor(
    private readonly socket: ScopedSocket,
    private readonly database: Database<PostDocument>,
    id: string,
    options: DocumentOptions<Post> = {},
  ) {
    super(id, options)
  }

  public comments = new CommentsEndpoint(
    this.socket,
    this.database,
    this.id,
  )

  //------
  // Comments

  public async addComment(postID: string, data: AnyObject) {
    const response = await this.socket.send('comments:add', postID, data)
    if (!response.ok) { return submitResultForResponse(response) }

    const {
      data: commentRaw,
      meta: {
        author,
      },
    } = response.body

    const comment = Post.deserialize({
      ...commentRaw,
      author,
    })
    this.comments.insert(comment, 0)
    this.comments.updateMeta({total: (this.comments.meta?.total ?? 0) + 1})

    if (this.data != null) {
      this.set(this.data.modify<Post>(raw => ({
        ...raw,
        commentCount: raw.commentCount + 1,
      })))
    }

    return submitResultForResponse(response)
  }

  public async updateComment(commentID: string, data: AnyObject) {
    const response = await this.socket.send('comment:update', commentID, data)
    if (!response.ok) { return submitResultForResponse(response) }

    const {
      data: commentRaw,
      meta: {
        author,
      },
    } = response.body

    const comment = Post.deserialize({
      ...commentRaw,
      author,
    })
    this.database.store(comment)

    return submitResultForResponse(response)
  }

  public async removeComment(commentID: string): Promise<boolean> {
    const response = await this.socket.send('comment:remove', commentID)
    if (!response.ok) { return false }

    this.database.delete(commentID)
    this.comments.remove([commentID])
    this.comments.updateMeta({total: Math.max((this.comments.meta?.total ?? 0) - 1)})

    if (this.data != null) {
      this.set(this.data.modify<Post>(raw => ({
        ...raw,
        commentCount: Math.max(0, raw.commentCount - 1),
      })))
    }

    return true
  }

  //------
  // Reactions

  public async toggleReaction(reaction: string) {
    if (this.data == null) { return }

    if (this.data.myReaction === reaction) {
      await this.removeReaction()
    } else {
      await this.addReaction(reaction)
    }
  }

  public async addReaction(reaction: string) {
    if (this.data == null) { return }

    const original = this.data
    this.data = this.data?.modify<Post>(raw => ({
      ...raw,
      reactions: {
        ...raw.reactions,
        [reaction]: (raw.reactions[reaction] ?? 0) + 1,
      },
      myReaction: reaction,
    }))

    const response = await this.socket.send('reactions:add', this.id, reaction)
    const success  = response.ok && response.body
    if (!success) {
      this.data = original
    }

    return success
  }

  public async removeReaction() {
    if (this.data == null) { return }

    const reaction = this.data.myReaction
    if (reaction == null) { return }

    const original = this.data
    this.data = this.data?.modify<Post>(raw => {
      const count = raw.reactions[reaction]
      if (count == null || count === 1) {
        return {...raw, myReaction: null, reactions: omit(raw.reactions, reaction)}
      } else {
        return {...raw, myReaction: null, reactions: {...raw.reactions, [reaction]: count - 1}}
      }
    })

    const response = await this.socket.send('reactions:remove', this.id)
    const success  = response.ok && response.body
    if (!success) {
      this.data = original
    }

    return success
  }

  private enrichPost(raw: Record<string, any>, meta: Record<string, any>) {
    const {author, feed, media} = meta

    const post = Post.deserialize({
      ...raw,
      author: author,
      feed: Feed.deserialize(feed),
      media: media.map((it: any) => Media.deserialize(it)),
    })

    return {data: post}
  }

  protected async performFetch() {
    const response = await this.socket.fetch('post:fetch', this.id)
    if (!response.ok) { return response }

    const {data, meta} = response.body
    return this.enrichPost(data, meta)
  }

}