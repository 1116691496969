import React from 'react'
import { useTranslation } from 'react-i18next'
import { notificationsStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { layout } from '~/ui/styling'
import DrawerLink from './DrawerLink'

export interface Props {}

const DrawerCommonLinks = observer('DrawerCommonLinks', () => {

  const {project}            = projectStore
  const drawer               = project?.feature('drawer')
  const notificationsEnabled = drawer?.options.notifications
  const searchEnabled        = drawer?.options.search

  const [t] = useTranslation('app')

  //------
  // Rendering

  function render() {
    return (
      <VBox>
        {notificationsEnabled && renderLink('notifications', '/notifications', 'bell', notificationsStore.unseenNotificationCount)}
        {searchEnabled && renderLink('search', '/search', 'search')}
        {renderLink('profile', '/profile', 'user')}
        <VBox paddingVertical={layout.padding.inline.l}>
          {renderLink('privacy', 'https://groundcontrol.app/privacy')}
          {renderLink('terms', 'https://groundcontrol.app/terms')}
        </VBox>
      </VBox>
    )
  }

  function renderLink(name: string, href: string, icon?: SVGName, badge?: number | string | null) {
    return (
      <DrawerLink
        href={href}
        icon={icon}
        caption={t(`nav.${name}`)}
        badge={badge}
      />
    )
  }

  return render()

})

export default DrawerCommonLinks