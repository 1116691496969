import { when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryTopic } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrLibraryTopicDocument from './BeWizrLibraryTopicDocument'

export default class BeWizrLibraryTopicsEndpoint extends Endpoint<BeWizrLibraryTopicDocument, SearchParams> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({
      offset: nextOffset,
      append: true,
    })
  }

  protected async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('library_topic/', {
      params: {
        q: params.query,
      },
    })
    if (response.status !== 200) { return null }

    let data: BeWizrLibraryTopic[] = response.data.items.map(
      (raw: any) => BeWizrLibraryTopic.deserialize(raw),
    )

    const total  = data.length
    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    if (offset > 0) {
      data.splice(0, offset)
    }
    if (data.length > limit) {
      data.splice(limit)
    }
    const nextOffset = offset + limit > total ? null : offset + limit

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}