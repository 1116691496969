import React from 'react'
import { DragDropContext } from './DragDropContext'
import { DraggableItem, DropZoneAccept, DropZoneHandlers, ElementConnect } from './types'

export function useDrop<T extends DraggableItem = DraggableItem>(spec: UseDropSpec<T>): [ElementConnect] {
  const implicitID = React.useMemo(() => Symbol(), [])

  const {active = true, id = implicitID, accept, snap, start, end, enter, hover, leave, drop} = spec
  const {setDropZoneHandlers, setDropZoneAccept} = React.useContext(DragDropContext)

  //------
  // Layout

  const elementRef = React.useRef<HTMLElement | null>(null)

  const getZIndex = React.useCallback(() => {
    if (elementRef.current == null) { return 0 }

    const style = window.getComputedStyle(elementRef.current)
    const zIndex = parseInt(style.zIndex)
    if (isNaN(zIndex)) { return 0 }

    return zIndex
  }, [])

  const getBounds = React.useCallback(() => {
    return elementRef.current?.getBoundingClientRect() ?? null
  }, [])

  // Use an effect to provide the context with the handler for this drop zone.
  React.useEffect(() => {
    if (!active) { return }

    setDropZoneHandlers(id, {
      getBounds,
      getZIndex,

      start,
      end,
      enter,
      hover,
      leave,
      drop,
      snap,
    })
    return () => { setDropZoneHandlers(id, null) }
  }, [drop, setDropZoneHandlers, enter, leave, id, hover, snap, start, end, getBounds, getZIndex, active])

  React.useEffect(() => {
    setDropZoneAccept(id, accept)
  }, [accept, getZIndex, id, setDropZoneAccept])

  //------
  // Connect

  const connect = React.useCallback((element: HTMLElement | null) => {
    elementRef.current = element
  }, [])

  return [connect]
}

export interface UseDropSpec<T extends DraggableItem> extends Omit<DropZoneHandlers<T>, 'getBounds' | 'getIndex'> {
  id?:     symbol
  accept:  DropZoneAccept<T>
  active?: boolean
}