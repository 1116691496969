import { ItemLayout } from './types'

export function getClientPoint(event: MouseEvent | TouchEvent): Point | null {
  if (event instanceof MouseEvent) {
    return {
      x: event.clientX,
      y: event.clientY,
    }
  } else if (event.touches.length > 0) {
    return {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    }
  } else {
    return null
  }
}

export function rectContainsPoint(rect: LayoutRect, point: Point) {
  if (point.x < rect.left || point.x > rect.left + rect.width) { return false }
  if (point.y < rect.top || point.y > rect.top + rect.height) { return false }
  return true
}

export function isRightMouse(event: MouseEvent | TouchEvent) {
  if (isTouchEvent(event)) { return false }
  return event.button !== 0
}

export function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  if (!('TouchEvent' in window)) { return false }
  return event instanceof TouchEvent
}

export function createItemLayout(element: HTMLElement): ItemLayout {
  const sourceRect = element.getBoundingClientRect()
  const offsetSize = {width: element.offsetWidth, height: element.offsetHeight}
  const scale      = Math.min(sourceRect.width / offsetSize.width, sourceRect.height / offsetSize.height)

  return {sourceRect, offsetSize, scale}
}