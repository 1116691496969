import React from 'react'
import { range } from 'lodash'
import { memo } from '~/ui/component'
import { HBox, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  value:     number | null
  max?:      number
}

const RatingStars = memo('RatingStars', (props: Props) => {

  const {value, max = 5} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.ratingStars}>
        {range(1, max + 1).map(renderStar)}
      </HBox>
    )
  }

  function renderStar(stars: number) {
    const active =
      value == null ? false :
      value >= stars ? true :
      value >= stars - 0.5 ? 'half' :
      false

    return (
      <StarView key={stars} active={active}/>
    )

  }

  return render()

})

export default RatingStars

interface StarViewProps {
  active: boolean | 'half'
}

const StarView = memo('StarView', (props: StarViewProps) => {

  const {active} = props

  const iconName: SVGName =
    active === true   ? 'star-full' :
    active === 'half' ? 'star-half' :
    'star-empty'

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.star, {active}]}>
        <SVG name={iconName}/>
      </VBox>
    )
  }

  return render()

})

export const gap      = layout.padding.inline.xs
export const starSize = {width: 24, height: 24}

const useStyles = createUseStyles({
  ratingStars: {
    alignItems: 'center',
  },

  container: {
    flexDirection:  'row',
    alignItems:     'center',
    justifyContent: 'center',
  },

  star: {
    '& svg': {
      ...starSize,
    },
  },
})