import { makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import { RankingDocument, RankingsEndpoint } from './rankings'
import { register } from './support'

export class RankingsStore {

  constructor() {
    makeAutoObservable(this)
  }

  public competitionID: string | null = null

  private readonly allRankings = new Database<RankingDocument>({
    getDocument:   item => new RankingDocument(item.player.id),
    emptyDocument: id => new RankingDocument(id),
    getID:         doc => doc.player.id,
  })

  public get rankings() {
    if (this.competitionID == null) { return null }
    return new RankingsEndpoint(this.competitionID, this.allRankings)
  }

  public fetch(competitionID: string) {
    this.competitionID = competitionID
    return this.rankings?.fetch({})
  }

}

const rankingsStore = register(new RankingsStore())
export default rankingsStore