import React from 'react'
import { isFunction, isPlainObject } from 'lodash'
import { HBox, InfoIcon, Label, Switch, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export type Props =
  | CommonProps & {tri?: false, value: boolean, onChange?: (value: boolean) => any}
  | CommonProps & {tri: true, value: boolean | null, onChange?: (value: boolean | null) => any}

export interface CommonProps {
  label?: SwitchFieldLabelProp
  info?:  React.ReactNode

  enabled?:  boolean
  readOnly?: boolean
  small?:    boolean
}

export type SwitchFieldLabelProp =
  | ((value: boolean | null) => React.ReactNode)
  | {on: React.ReactNode, off: React.ReactNode}
  | React.ReactNode

export default function SwitchField(props: Props) {

  const {
    value,
    onChange,
    label,
    enabled = true,
    small,
    info,
    readOnly,
  } = props

  const tap = React.useCallback(() => {
    if (props.tri && value === false) {
      onChange?.(null as any)
    } else {
      onChange?.(!value)
    }
  }, [onChange, props.tri, value])

  const change = React.useCallback((value: boolean | null) => {
    onChange?.(value as any)
  }, [onChange])

  const $ = useStyles()

  function render() {
    return (
      <Tappable onTap={tap} enabled={enabled && !readOnly} focusable={false} noFeedback>
        <HBox flex='shrink' gap={layout.padding.s} classNames={$.switchField}>
          <Switch
            isOn={value}
            enabled={enabled && !readOnly}
            onChange={change}
            small={small}
          />
          <VBox flex='shrink'>
            {renderLabel()}
          </VBox>
          {info != null && <InfoIcon renderTooltip={info}/>}
        </HBox>
      </Tappable>
    )
  }

  function renderLabel() {
    const resolved = resolveLabel()
    if (!isReactText(resolved)) { return resolved }

    const active = !!value && enabled
    return (
      <Label dim={!active} bold={!!value} truncate={false} caption={small} small={small}>
        {resolved}
      </Label>
    )
  }

  function resolveLabel() {
    if (isFunction(label)) {
      return label(value)
    } else if (isPlainObject(label) && !React.isValidElement(label)) {
      return value ? (label as any).on : (label as any).off
    } else {
      return label
    }
  }

  return render()

}

const useStyles = createUseStyles({
  switchField: {
    padding: [2, 0],
  },
})