import React from 'react'
import { isFunction, isPlainObject } from 'lodash'

export function childrenOfType<P>(children: React.ReactNode, ...types: React.ComponentType<P>[]): Array<React.ReactElement<P>> {
  const allChildren = React.Children.toArray(children) as Array<React.ReactElement<any>>

  return allChildren.filter(child => {
    if (!React.isValidElement(child)) { return false }
    return types.includes((child as any).type)
  })
}

export function childrenNotOfType<P>(children: React.ReactNode, types: React.ComponentType<P>[]): Array<React.ReactElement<P>> {
  const allChildren = React.Children.toArray(children) as Array<React.ReactElement<any>>

  return allChildren.filter(child => {
    if (!React.isValidElement(child)) { return true }
    return !types.includes((child as any).type)
  })
}

export function isReactText(children: React.ReactNode): children is React.ReactText {
  return typeof children === 'string' || typeof children === 'number'
}

export function isReactComponent<P>(arg: any): arg is React.ComponentType<P> {
  if (isPlainObject(arg) && arg.$$typeof != null) {
    return true
  }
  if (isFunction(arg)) {
    return true
  }

  return false
}

export function renderElementOrComponent<P>(
  arg:    React.ReactElement<P> | React.ComponentType<P>,
  props?: P,
): React.ReactElement<P> {
  if (React.isValidElement<P>(arg)) {
    return arg
  } else {
    return React.createElement<any>(arg, props)
  }
}