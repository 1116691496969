import React from 'react'
import { useTranslation } from 'react-i18next'
import { stringHash } from 'ytil'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Chip, HBox, Label, List, ListItem, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from './ChatContext'
import { ChannelPreview, ChatChannelController } from './types'

const ChannelList = observer('ChannelList', () => {

  const {chat}   = useChat()
  const previews = chat?.channelPreviews ?? []

  //------
  // Rendering

  function render() {
    return (
      <List
        data={previews}
        keyExtractor={preview => preview.channel.id}
        renderItem={renderPreview}
        scrollable
      />
    )
  }

  function renderPreview(preview: ChannelPreview) {
    const controller = chat?.channelController(preview.channelID)
    if (controller == null) { return null }

    return (
      <ChannelPreviewItem
        preview={preview}
        controller={controller}
      />
    )
  }

  return render()

})

interface ChannelPreviewItemProps {
  preview:    ChannelPreview
  controller: ChatChannelController
}

const ChannelPreviewItem = (props: ChannelPreviewItemProps) => {

  const {preview, controller} = props
  const {mostRecentMessage, typingNames} = preview
  const {chat} = useChat()

  const [t] = useTranslation('chat')

  const {colors} = useStyling()

  const goToChannel = React.useCallback(() => {
    chat?.requestSwitchChannel?.(preview.channelID)
  }, [chat, preview.channelID])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        image={renderAvatar()}
        caption={renderCaption()}
        detail={renderDetail()}
        onTap={goToChannel}
        accessory={renderUnreadCount()}
        contentClassNames={$.ChannelPreviewItem}
      />
    )
  }

  function renderAvatar() {
    return (
      <Avatar
        source={preview.image}
        firstName={preview.name}
        size={avatarSize}
      />
    )
  }

  function renderCaption() {
    return (
      <Label children={preview.channel.name} />
    )
  }

  function renderDetail() {
    if (typingNames.length > 0) {
      return renderTyping()
    } else {
      return renderMostRecentMessage()
    }
  }

  function renderTyping() {
    return (
      <Label small dim italic>
        {t('typing', {names: typingNames, count: typingNames.length})}
      </Label>
    )
  }

  function renderMostRecentMessage() {
    if (mostRecentMessage == null) { return null }

    const mine   = mostRecentMessage.from === chat?.sender?.id
    const sender = controller.senders.get(mostRecentMessage.from)
    const text   = sender == null ? null : mostRecentMessage.notificationText(mine ? 'sent' : 'received', sender)

    const colorIndex    = stringHash(sender?.id ?? '')
    const emphasisColor = sender == null ? undefined : colors.palette('default', colorIndex)

    return (
      <HBox gap={layout.padding.inline.s}>
        {!mine && sender?.photoURL != null && (
          <Avatar
            {...sender}
            source={sender.photoURL}
            size={senderAvatarSize}
          />
        )}
        <VBox flex>
          <Label dim small markup truncate flex='both' emphasisColor={emphasisColor}>
            {text}
          </Label>
        </VBox>
      </HBox>
    )
  }

  function renderUnreadCount() {
    if (preview.unreadCount === 0) { return null }

    return (
      <Chip small>
        {preview.unreadCount}
      </Chip>
    )
  }

  return render()

}

export default ChannelList

export const avatarSize = {
  width:  55,
  height: 55,
}

export const senderAvatarSize = {
  width:  16,
  height: 16,
}

export const itemPaddingVertical = layout.padding.inline.m

const useStyles = createUseStyles({
  ChannelPreviewItem: {
    paddingTop:    itemPaddingVertical,
    paddingBottom: itemPaddingVertical,
    '&:first-child': {paddingTop: itemPaddingVertical},
    '&:last-child':  {paddingBottom: itemPaddingVertical},
  },
})