import { ElementConnect, SortableContainerProps, SortableItem } from 'react-dnd-sortable'
import { ScrollManager } from 'react-scroll-manager'
import { isArray, isPlainObject } from 'lodash'
import { VBoxProps } from '~/ui/components'
import { Props as ScrollerProps } from '~/ui/components/scroller/Scroller'

export interface ListProps<T> {

  //-------
  // Basics

  /**
   * The items in the list.
   */
  data: T[] | ListSection<T>[]

  /**
   * The list will figure out if it's receiving list sections or a flat list of items. You can
   * use this property to specify this.
   */
  sectioned?: boolean

  /**
   * A function to retrieve the key for each item. If unspecified, the index is used.
   */
  keyExtractor?: (item: T, index: number) => Key


  /**
   * A function to render each item at the given index.
   */
  renderItem: (item: T, index: number, selected: boolean) => React.ReactNode

  /**
   * Optionally an index for the item to select.
   */
  selectedKeyPath?: KeyPath

  //------
  // Header / footer

  /**
   * A header component. Scrolls with the list if the list is scrollable.
   */
  HeaderComponent?:    React.ComponentType<{}> | React.ReactNode
  FooterComponent?:    React.ComponentType<{}> | React.ReactNode
  EmptyComponent?:     React.ComponentType<{}> | React.ReactNode
  SeparatorComponent?: React.ComponentType<{}> | React.ReactNode

  //------
  // Sections

  renderSectionHeader?: (section: ListSection<T>, index: number) => React.ReactNode
  renderSectionFooter?: (section: ListSection<T>, index: number) => React.ReactNode

  //------
  // Scrollable

  /**
   * Whether the list is scrollable. If so, it will also get a `flex: 1` styling, meaning it will adjust to its parent rather
   * than to its children.
   */
  scrollable?: boolean

  /**
   * Specify to incrementally show results. This will automatically use onEndReached to increase the visible results,
   * preventing large render times. Ignored if data is specified in sections.
   */
  pageSize?: number

  /**
   * Callback to invoke when the end of the list has been reached. Use for fetching more items.
   */
  onEndReached?: ScrollerProps['onEndReached']

  /**
   * Additional props for the scroller component.
   */
  scrollerProps?: Omit<ScrollerProps, 'onEndReached'>

  /**
   * An optional scroll manager to use.
   */
  scrollManager?: ScrollManager

  //------
  // Sortable

  /**
   * If the items in the list are sortable, pass in sortable props.
   */
  sortable?: Omit<SortableContainerProps<T>, 'children'>

  /**
   * Renders a placeholder for the given sortable item.
   */
  renderPlaceholder?: (connect: ElementConnect, item: SortableItem<any, T>, isOver: boolean) => React.ReactNode

  /**
   * Whether the placeholder should be shown while an acceptable item is being
   * dragged, or only if it's hovered over the list. If set to `'always'`, the
   * placeholder is shown at the end of the list if the item is not over the list.
   */
  showPlaceholder?: ShowPlaceholderOption | ((item: SortableItem<any, T>) => ShowPlaceholderOption)

  //------
  // Layout & styling

  contentPadding?: VBoxProps['padding']

  /**
   * The gap between items.
   */
  itemGap?: VBoxProps['gap']

  /**
   * The alignment of the items.
   */
  itemAlign?: VBoxProps['align']

  /**
   * Whether to flex.
   */
  flex?: VBoxProps['flex']

  /**
   * Class names.
   */
  classNames?: React.ClassNamesProp

  /**
   * Class names for the content container.
   */
  contentClassNames?: React.ClassNamesProp

}

export type KeyPath = Key[]
export type Key = string | number

export type ShowPlaceholderOption = 'always' | 'hover'

export interface ListSection<T> {
  name:  string
  items: T[]
}

export function isListSection<T>(arg: T | ListSection<T>): arg is ListSection<T> {
  if (!isPlainObject(arg)) { return false }
  if (typeof (arg as any).name !== 'string') { return false }
  if (!isArray((arg as any).items)) { return false }

  return true
}