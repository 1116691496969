import React from 'react'
import { useTranslation } from 'react-i18next'
import { Sender } from '~/models'
import { observer } from '~/ui/component'
import { Center, EmptyOrFetching, List, Spinner, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { SendersEndpoint } from '../types'
import SendersRow from './SendersRow'

interface Props {
  endpoint: SendersEndpoint | null
}

const SendersList = observer('SendersList', (props: Props) => {

  const {endpoint} = props

  const [t] = useTranslation('chat')

  React.useEffect(() => {
    if (endpoint?.fetchStatus === 'idle') {
      endpoint?.fetch()
    }
  }, [endpoint])

  const handleEndReached = React.useCallback(() => {
    endpoint?.fetchMore()
  }, [endpoint])

  const loading = endpoint?.fetchStatus === 'fetching'
  const senders = endpoint?.data ?? []

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex>
        <List
          data={senders}
          contentClassNames={$.content}
          keyExtractor={keyExtractor}
          EmptyComponent={Empty}
          renderItem={renderSender}
          onEndReached={handleEndReached}
          itemGap={layout.padding.inline.s}
          scrollable
        />
        {loading && renderLoading()}
      </VBox>
    )
  }

  const Empty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        flex
        {...t('channel_info.participants.empty')}
      />
    )
  }, [t])

  const keyExtractor = React.useCallback(
    (sender: Sender) => sender.id,
    [],
  )

  const renderSender = React.useCallback((sender: Sender) => (
    <SendersRow sender={sender} />
  ), [])

  function renderLoading() {
    return <Center children={<Spinner />} />
  }

  return render()
})

export default SendersList

const useStyles = createUseStyles({
  content: {
    padding: layout.padding.inline.l,
    paddingTop: 0,
  },
})