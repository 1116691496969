import React from 'react'
import { CustomImage, isCustomImage } from '~/models'
import { memo } from '~/ui/component'
import {
  AccordionBranding,
  animation,
  createUseStyles,
  layout,
  shadows,
  useStyling,
} from '~/ui/styling'
import { ImageView } from '../app/media'
import { isReactText } from '../util'
import BrandedComponent from './BrandedComponent'
import { Label } from './label'
import { Center, HBox, VBox } from './layout'
import { gap, imageSize, paddingHorizontal, paddingVertical } from './ListItem'
import SVG, { SVGName } from './SVG'

export interface Props {
  open:          boolean
  requestOpen?:  () => any
  requestClose?: () => any

  image?:     SVGName | CustomImage | React.ReactNode
  caption:    React.ReactNode
  accessory?: React.ReactNode

  branding?: AccordionBranding

  classNames?:        React.ClassNamesProp
  contentClassNames?: React.ClassNamesProp

  children?: React.ReactNode
}

const Accordion = memo('Accordion', (props: Props) => {

  const {guide, colors} = useStyling()

  const {
    open,
    requestOpen,
    requestClose,
    image,
    caption,
    branding = guide.accordion,
    classNames,
    contentClassNames,
    children,
  } = props

  const toggle = React.useCallback((event: React.SyntheticEvent<any>) => {
    if (open) {
      requestClose?.()
    } else {
      requestOpen?.()
    }
    event.preventDefault()
  }, [open, requestClose, requestOpen])

  //------
  // Rendering

  const $ = useStyles(branding.resolve('depth'))

  function render() {
    return (
      <BrandedComponent tag='details' classNames={[$.Accordion, classNames]} branding={branding} height={height} {...{open} as any}>
        {renderSummary()}
        {renderContent()}
      </BrandedComponent>
    )
  }

  function renderSummary() {
    return (
      <HBox tag='summary' classNames={[$.summary, contentClassNames]} gap={gap.normal} align='middle' onClick={toggle}>
        {image != null && (
          <Center>
            {renderImage()}
          </Center>
        )}
        <VBox flex='both'>
          {renderCaption()}
        </VBox>
        {renderChevron()}
      </HBox>
    )
  }

  function renderImage() {
    if (image == null) { return null }

    return (
      <Center style={{overflow: 'hidden'}}>
        {typeof image === 'string' ? (
          <SVG name={image as SVGName} size={layout.icon.m} primary/>
        ) : isCustomImage(image) ? (
          <ImageView source={image} size={imageSize}/>
        ) : (
          image
        )}
      </Center>
    )
  }

  function renderCaption() {
    return isReactText(caption) ? (
      <Label bold>{caption}</Label>
    ) : (
      caption
    )
  }

  function renderChevron() {
    return (
      <SVG
        name={open ? 'chevron-up': 'chevron-down'}
        size={layout.icon.m}
        color={colors.fg.dim}
      />
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content}>
        {children}
      </VBox>
    )
  }

  return render()

})

export default Accordion

export const height = layout.barHeight.l

const useStyles = createUseStyles({
  Accordion: (depth: number) =>  ({
    transition: animation.transitions.short('box-shadow'),
    '&:hover': {
      boxShadow: shadows.depth(Math.ceil(depth * 1.5)),
    },
  }),

  summary: {
    cursor:    'pointer',
    minHeight: height,

    padding:         [paddingVertical.normal / 2, paddingHorizontal.normal],
    '&:first-child': {paddingTop: paddingVertical.normal},
    '&:last-child':  {paddingBottom: paddingVertical.normal},
  },

  content: {
    padding:       [paddingVertical.normal, paddingHorizontal.normal],
    paddingBottom: layout.padding.inline.l,
  },
})