import React from 'react'
import { safeParseFloat } from 'ytil'
import { Message, MessageListItem, RatingFeedback } from '~/models'
import { RatingStars } from '~/ui/components'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'text'}
  feedback:    RatingFeedback
  inlineReply: MessageListItem | null
}

const RatingBubble = React.memo((props: Props) => {

  const {message, feedback, inlineReply} = props

  const stars = safeParseFloat(message.text)

  //------
  // Rendering

  function render() {
    return (
      <ChatBubble padded message={message} inlineReply={inlineReply}>
        <RatingStars value={stars} max={feedback.maxRating}/>
      </ChatBubble>
    )
  }

  return render()

})

const shouldUse = (text: string, replyTo: Message | null): replyTo is Message & {feedback: RatingFeedback} => {
  if (replyTo?.feedback?.type !== 'rating') { return false }
  if (replyTo.feedback.style !== 'stars') { return false }
  if (safeParseFloat(text) == null) { return false }

  return true
}

Object.assign(RatingBubble, {shouldUse})
export default RatingBubble as typeof RatingBubble & {
  shouldUse: typeof shouldUse
}