import React from 'react'
import { Post } from '~/models'
import { memo } from '~/ui/component'
import { Tappable } from '~/ui/components'
import { createUseStyles, layout, presets } from '~/ui/styling'
import { transitions } from '~/ui/styling/animation'
import { useNewsService } from './NewsContext'

export interface Props {
  post:     Post
  reaction: string
}

const PostReactionButton = memo('PostReactionButton', (props: Props) => {

  const {post, reaction} = props

  const service = useNewsService()

  const selected = post.myReaction === reaction
  const disabled = !selected && post.myReaction != null

  const toggleReaction = React.useCallback(() => {
    const document = service.posts.document(post.id)
    document.toggleReaction(reaction)
  }, [post.id, reaction, service])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable
        classNames={[$.postReactionButton, {selected, disabled}]}
        enabled={!disabled}
        onTap={toggleReaction}
      >
        <span>{reaction}</span>
      </Tappable>
    )
  }

  return render()

})

export default PostReactionButton

export const postReactionButtonSize = {
  height: 32,
}

const useStyles = createUseStyles(theme => ({
  postReactionButton: {
    ...postReactionButtonSize,
    ...layout.flex.center,
    fontSize: 13,
    transition: transitions.short('font-size'),

    '&.disabled': {
      opacity: 0.6,
    },
    '&.selected': {
      fontSize: 18,
      '&::before': {
        background: theme.bg.hover,
      },
    },

    position: 'relative',
    ...presets.overlayBefore({
      ...postReactionButtonSize,
    }),
    '&:not(.disabled):hover::before': {
      background: theme.bg.hover,
    },
    '&:not(.disabled):active::before': {
      background: theme.bg.active,
    },

    '& span': {
      display: 'block',
      userSelect: 'none',
      marginTop: 2,
      marginBottom: -2,
    },
  },
}))