import { action, computed, when } from 'mobx'
import { Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLearningTrack } from '~/models'
import bewizrStore from '../bewizrStore'

export default class BeWizrLearningTrackDocument extends Document<BeWizrLearningTrack, string, {}> {

  public async enroll() {
    const api = bewizrAPI()
    if (api == null) { return }

    const endpoint = this.data?.links.enroll
    if (endpoint == null) { return }

    return api.post(endpoint)
  }

  @computed
  public get enrolled() {
    return this.data?.links.enroll == null
  }

  @action
  protected async performFetch() {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get(`learning_track/${this.id}/`)
    if (response.status !== 200) { return response }

    const data = BeWizrLearningTrack.deserialize(response.data)

    return {
      data,
      meta: {},
    }
  }
}