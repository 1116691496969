import React from 'react'

export function assignRef<T>(ref: React.Ref<T> | null | undefined, value: T) {
  if (ref == null) { return }

  if (isRefObject(ref)) {
    (ref as React.MutableRefObject<T>).current = value
  } else {
    ref(value)
  }
}

export function releaseRef<T>(ref: React.Ref<T> | null | undefined) {
  assignRef(ref, null)
}

export function isRefObject<T>(ref: React.Ref<T>): ref is React.RefObject<T> {
  if (ref != null && typeof ref === 'object') {
    return 'current' in ref
  } else {
    return false
  }
}

export function useRefMap<K, V>(): RefMap<K, V> {
  return React.useMemo((): RefMap<K, V> => {
    const map = new Map<K, V>()

    return {
      for: key => current => {
        if (current == null) {
          map.delete(key)
        } else {
          map.set(key, current)
        }
      },
      set:     (key, current) => {
        if (current == null) {
          map.delete(key)
        } else {
          map.set(key, current)
        }
      },
      clear:   () => { map.clear() },

      size:    () => map.size,
      get:     key => map.get(key),
      all:     () => [...map.values()],
      entries: () => [...map.entries()],
    }
  }, [])
}

export interface RefMap<K, V> {
  size:    () => number
  for:     (key: K) => (current: V | null) => void
  set:     (key: K, value: V | null) => void
  clear:   () => void
  get:     (key: K) => V | undefined
  all:     () => V[]
  entries: () => Array<[K, V]>
}