import { isPlainObject } from 'lodash'

export type CustomImage = SVGImage | RemoteImage

export interface SVGImage {
  type:   'svg'
  base64: string
}

export interface RemoteImage {
  type: 'remote'
  url:  string
}

export function isCustomImage(img: any): img is CustomImage {
  if (!isPlainObject(img)) { return false }

  if (img.type === 'svg') {
    return 'base64' in img && typeof img.base64 === 'string'
  } else if (img.type === 'remote') {
    return 'url' in img && typeof img.url === 'string'
  } else {
    return false
  }
}