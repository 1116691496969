import { debounce, omit, throttle } from 'lodash'
import ScrollAnimation from './ScrollAnimation'
import {
  ScrollingElement,
  ScrollListener,
  ScrollOptions,
  ScrollPosition,
  ScrollToOptions,
  Size,
} from './types'

export interface ScrollManagerOptions {
  horizontal?: boolean
  sync?:       boolean
}

export interface EndReachedOptions {
  threshold?: number
}

export default class ScrollManager {

  public constructor(
    container: ScrollingElement | null = null,
    public readonly options: ScrollManagerOptions = {},
  ) {
    if (container != null) {
      this.containers.push(container)
    }
    if (this.options.sync) {
      this.sync()
    }
  }

  public containers: ScrollingElement[] = []

  public dispose() {
    this.disposeListener()
  }

  private activeScrollAnimation: ScrollAnimation | null = null

  //------
  // Containers

  public setContainer(container: ScrollingElement) {
    this.setContainers([container])
    return () => {
      this.setContainers([])
    }
  }

  public addContainer(container: ScrollingElement) {
    this.setContainers([...this.containers, container])
    return () => {
      this.removeContainer(container)
    }
  }

  public removeContainer(container: ScrollingElement) {
    this.setContainers(this.containers.filter(it => it !== container))
  }

  public setContainers(containers: ScrollingElement[]) {
    this.disposeListener()
    this.containers = containers
    if (this.listeners.size > 0) {
      this.ensureListening()
    }
    if (this.containers.length > 0) {
      this.emitScroll(containers[0])
    }
  }

  //------
  // Interface

  public get isScrolling() {
    return this.activeScrollAnimation != null && this.activeScrollAnimation.isRunning
  }

  public stopSimulatingScrollPosition() {
    this.scrollPositionOverride = {}
  }

  public getScrollState(horizontal: boolean) {
    const position    = horizontal ? this.scrollLeft : this.scrollTop
    const maxPosition = horizontal ? this.maxScrollPosition.left : this.maxScrollPosition.top
    const container   = this.scrollingElements[0]

    return {
      atStart:        position <= 0,
      atEnd:          position >= maxPosition,
      scrollbarWidth: container == null ? 0 : container.offsetWidth - container.clientWidth,
    }
  }

  //------
  // Metrics

  public get eventSources(): Array<Element | Document> {
    if (this.containers.length === 0) {
      return [document]
    } else {
      return this.containers
    }
  }

  public get scrollingElements(): ScrollingElement[] {
    if (this.containers.length === 0) {
      return [document.scrollingElement ?? document.documentElement] as HTMLElement[]
    } else {
      return this.containers as ScrollingElement[]
    }
  }

  public get scrollSize(): Size {
    return {
      width:  Math.max(...this.scrollingElements.map( el => el.scrollWidth)),
      height: Math.max(...this.scrollingElements.map( el => el.scrollHeight)),
    }
  }

  public get availableSize(): Size {
    if (this.containers.length === 0) {
      return {
        width:  window.innerWidth,
        height: window.innerHeight,
      }
    } else {
      return {
        width:  Math.max(...this.scrollingElements.map(el => el.clientWidth)),
        height: Math.max(...this.scrollingElements.map(el => el.clientHeight)),
      }
    }
  }

  public get maxScrollPosition(): ScrollPosition {
    return {
      left: this.scrollSize.width - this.availableSize.width,
      top:  this.scrollSize.height - this.availableSize.height,
    }
  }

  //------
  // Scroll position

  private scrollPositionOverride: Partial<ScrollPosition> = {}

  public get scrollPosition(): ScrollPosition {
    return {
      left: this.scrollLeft,
      top:  this.scrollTop,
    }
  }

  public get scrollLeft() {
    if (this.scrollPositionOverride.left != null) {
      return this.scrollPositionOverride.left
    } else {
      return Math.min(...this.scrollingElements.map(el => el.scrollLeft))
    }
  }

  public get scrollTop() {
    if (this.scrollPositionOverride.top != null) {
      return this.scrollPositionOverride.top
    } else {
      return Math.min(...this.scrollingElements.map(el => el.scrollTop))
    }
  }

  public isEndReached(options: EndReachedOptions = {})  {
    const {threshold = 0} = options
    if (this.options.horizontal) {
      if (this.maxScrollPosition.left <= 0) { return false }
      return this.scrollLeft >= this.maxScrollPosition.left - threshold
    } else {
      if (this.maxScrollPosition.top <= 0) { return false }
      return this.scrollTop >= this.maxScrollPosition.top - threshold
    }
  }

  //------
  // Scroll interface

  public scrollTo(options: ScrollToOptions) {
    let elements = this.scrollingElements
    if (options.except != null) {
      elements = elements.filter(el => !options.except!.includes(el))
    }

    if (options.behavior === 'instant') {
      if (options.top != null) {
        elements.forEach(el => { el.scrollTop = options.top! })
      }
      if (options.left != null) {
        elements.forEach(el => { el.scrollLeft = options.left! })
      }
    } else {
      this.activeScrollAnimation = new ScrollAnimation(this, {
        ...omit(options, 'behavior') as Omit<ScrollToOptions, 'behavior'>,

        onEnd: complete => {
          this.activeScrollAnimation = null
          if (options.onEnd != null) {
            options.onEnd(complete)
          }
        },
      })
      this.activeScrollAnimation.start()
    }
  }

  public stopScrolling() {
    if (this.activeScrollAnimation) {
      this.activeScrollAnimation.stop()
    }
  }

  public scrollToEnd(options: ScrollOptions) {
    this.scrollTo({
      ...options,
      ...this.maxScrollPosition,
    })
  }

  //------
  // Listeners

  private listeners: Map<ScrollListener, ScrollListener> = new Map()
  private listening: boolean = false

  public addScrollListener(listener: ScrollListener, options: ScrollListenerOptions = {}) {
    this.ensureListening()
    this.listeners.set(listener, createScrollListenerWithOptions(listener, options))
  }

  public removeScrollListener(listener: ScrollListener) {
    this.listeners.delete(listener)
    if (this.listeners.size === 0) {
      this.disposeListener()
    }
  }

  private ensureListening() {
    if (this.listening) { return }

    for (const source of this.eventSources) {
      source.addEventListener('scroll', this.onScroll)
    }
    this.listening = true
  }

  private disposeListener() {
    if (!this.listening) { return }

    for (const source of this.eventSources) {
      source.removeEventListener('scroll', this.onScroll)
    }
    this.listening = false
  }

  protected emitScroll(source: ScrollingElement) {
    const scrollPosition = {
      top:  source.scrollTop,
      left: source.scrollLeft,
    }
    for (const [, listener] of this.listeners) {
      listener(scrollPosition, source)
    }
  }

  private onScroll = (event: Event) => {
    this.emitScroll(event.currentTarget as ScrollingElement)
  }

  //------
  // Sync

  private sync() {
    this.addScrollListener(this.handleScrollSync)
  }

  private handleScrollSync = (position: ScrollPosition, source: ScrollingElement) => {
    this.scrollTo({
      ...position,
      behavior: 'instant',
      except:   [source],
    })
  }

}

interface ScrollListenerOptions {
  throttle?: number
  debounce?: number
  raf?:      boolean
}

function createScrollListenerWithOptions(listener: ScrollListener, options: ScrollListenerOptions) {
  const {raf = true} = options

  if (options.throttle == null && options.debounce == null && !raf) {
    return listener
  }

  let withRAF: ScrollListener
  if (raf) {
    withRAF = (position, source) => {
      window.requestAnimationFrame(() => {
        listener(position, source)
      })
    }
  } else {
    withRAF = listener
  }

  if (options.throttle != null) {
    return throttle(withRAF, options.throttle)
  } else if (options.debounce != null) {
    return debounce(withRAF, options.debounce)
  } else {
    return withRAF
  }
}