import { Database } from 'mobx-document'
import socket from 'socket.io-react'
import { Page } from '~/models'
import authenticationStore from './authenticationStore'
import { MenuPageItemDocument, MenuPageItemsEndpoint, PageDocument } from './pages'
import searchStore from './searchStore'
import { register } from './support'

export class InfoStore {

  public readonly pages = new Database<PageDocument>({
    getDocument:   page => new PageDocument(page.slug, {initialData: page}),
    emptyDocument: slug => new PageDocument(slug),
    getID:         page => page.slug,
  })

  public readonly menuItems = new Database<MenuPageItemDocument>({
    getDocument:   item => new MenuPageItemDocument(item.link.href, {initialData: item}),
    emptyDocument: href => new MenuPageItemDocument(href),
    getID:         item => item.link.href,
  })

  //------
  // Initialization

  public init() {
    socket.addEventListener('pages:menu:update', this.onRootMenuUpdate)
    authenticationStore.on('login', this.onLogIn)
    authenticationStore.on('logout', this.onLogOut)

    searchStore.registerEndpoint('menu-items', new MenuPageItemsEndpoint(this.menuItems, {}))
  }

  private onRootMenuUpdate = (json: AnyObject) => {
    const page = Page.deserialize(json.page)
    this.pages.store(page)
  }

  private onLogIn = async () => {
    const rootMenu = this.pages.document('')
    await rootMenu.fetch()
  }

  private onLogOut = () => {
    this.pages.clear()
  }

}

const infoStore = register(new InfoStore())
export default infoStore