import React from 'react'
import { ToastItemProps } from 'react-toast'
import Color from 'color'
import { forwardRef } from '~/ui/component'
import { Dimple, HBox, Label, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export type Type = 'info' | 'success' | 'warning' | 'error'

export type Props = ToastItemProps

const ToastItem = forwardRef('ToastItem', (props: ToastItemProps, ref: React.Ref<HTMLDivElement>) => {

  const $ = useStyles()
  const {title, detail, children, dismiss} = props

  function render() {
    return (
      <HBox justify='center' padding={layout.padding.xl} ref={ref}>
        {renderItem()}
      </HBox>
    )
  }

  function renderItem() {
    return (
      <Tappable classNames={$.ToastItem} onTap={dismiss}>
        <ThemeProvider dark>
          <VBox gap={layout.padding.xs}>
            <VBox gap={layout.padding.s}>
              <Label bold align='center'>
                {title}
              </Label>
              {detail && (
                <Label markup small dim align='center' truncate={false}>
                  {detail}
                </Label>
              )}
            </VBox>
            {children && <Dimple horizontal counterPadding={layout.padding.xl}/>}
            {children}
          </VBox>
        </ThemeProvider>
      </Tappable>
    )
  }

  return render()

})

export default ToastItem

export const height = 64
export const backgroundColor = new Color('black').alpha(0.8)

const useStyles = createUseStyles({
  ToastItem: {
    flex: [0, 1, '560px'],

    pointerEvents: 'auto',

    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.xl[size]],
    })),

    borderRadius: layout.radius.l,

    minHeight:  height,
    background: backgroundColor,
  },
})