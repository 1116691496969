import React from 'react'
import { sparse } from 'ytil'
import { AuthResetAccount } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo, observer } from '~/ui/component'
import { HBox, Label, List, SVG, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  availableAccounts: AuthResetAccount[]
  onSelectAccount:   (account: AuthResetAccount) => any
}

const AuthResetAccountList = observer('AuthResetAccountList', (props: Props) => {

  const {
    availableAccounts,
    onSelectAccount,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List
        classNames={$.AuthResetAccountList}
        data={availableAccounts}
        keyExtractor={keyExtractor}
        renderItem={renderAccountItem}
        SeparatorComponent={renderSeparator}
        flex={false}
      />
    )
  }

  const keyExtractor = React.useCallback(
    (account: AuthResetAccount) => account.participantID,
    [],
  )

  const renderSeparator = React.useCallback(() => {
    return <div classNames={$.separator}/>
  }, [$.separator])

  const renderAccountItem = React.useCallback((account: AuthResetAccount) => {
    return (
      <AuthResetAccountListItem
        account={account}
        onSelect={onSelectAccount}
      />
    )
  }, [onSelectAccount])


  return render()

})

interface AuthResetAccountListItemProps {
  account:   AuthResetAccount
  onSelect?: (account: AuthResetAccount) => any
}

const AuthResetAccountListItem = memo('AuthResetAccountListItem', (props: AuthResetAccountListItemProps) => {

  const {account, onSelect} = props
  const fullName  = sparse([account.firstName, account.lastName]).join(' ')

  const select = React.useCallback(() => {
    onSelect?.(account)
  }, [account, onSelect])

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={$.AuthResetAccountListItem} onTap={select} enabled={onSelect != null}>
        <HBox flex='shrink' gap={layout.padding.inline.m}>
          {renderAvatar()}
          {renderMain()}
          <SVG name='chevron-right' size={layout.icon.l} dimmer/>
        </HBox>
      </Tappable>
    )
  }

  function renderAvatar() {
    return (
      <Avatar
        source={account.photoURL}
        firstName={account.firstName}
        lastName={account.lastName}
        size={avatarSize}
      />
    )
  }

  function renderMain() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        <Label h3>
          {account.projectName}
        </Label>
        <Label small dim>
          {fullName}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default AuthResetAccountList

export const avatarSize = {
  width:  32,
  height: 32,
}

const useStyles = createUseStyles(theme => ({
  AuthResetAccountList: {
    maxHeight: 260,

    border:      [1, 'solid', theme.fg.dimmer],
    borderWidth: [1, 0],
  },

  separator: {
    height:     0.5,
    background: theme.fg.dimmer,
  },

  AuthResetAccountListItem: {
    ...layout.responsive(size => ({
      padding: [
        layout.padding.inline.m,
        layout.padding.l[size],
      ],
      paddingRight: layout.padding.inline.l,
    })),

    '&:hover': {
      background: theme.bg.hover,
    },
    '&:active': {
      background: theme.bg.active,
    },
  },

}))