import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageFeedback, TextFeedback } from '~/models'
import { TextField } from '~/ui/components'
import { useChat } from '../ChatContext'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & TextFeedback
}

const TextFeedbackBubble = React.memo((props: Props) => {

  const {messageID, feedback} = props

  const {channel} = useChat()
  const [text, setText] = React.useState<string>('')

  const [t] = useTranslation('chat')

  //------
  // Callbacks

  const submit = React.useCallback(() => {
    if (messageID == null) { return }

    channel?.sendMessage({
      type:    'text',
      text:    text,
      replyTo: messageID,
    })
    setText('')
  }, [channel, messageID, text])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackBubble messageID={messageID} feedback={feedback} maySubmit={text.trim().length > 0} requestSubmit={submit}>
        <TextField
          value={text}
          onChange={setText}
          placeholder={feedback.placeholder ?? t('feedback.text.default_placeholder')}
          onCommit={submit}
          inputStyle='light'
        />
      </FeedbackBubble>
    )
  }

  return render()

})

export default TextFeedbackBubble