import React from 'react'
import Logger from 'logger'

export function useMountTrace(tag: string) {

  const logger = React.useMemo(
    () => new Logger(tag),
    [tag],
  )

  React.useEffect(() => {
    logger.debug("mount")
    return () => { logger.debug("unmount") }
  }, [logger])
}