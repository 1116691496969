import ComponentBranding from './ComponentBranding'

export default class TileBranding extends ComponentBranding {

  public readonly previewSize = {width: 120, height: 120}

  public info = new ComponentBranding(this, null, {
    background: 'alt',
    border:     'none',
    shape:      'rectangle',
    depth:      0,

    previewSize: {
      width:  160,
      height: 24,
    },
  })

  public accessory = new ComponentBranding(this, null, {
    background: 'primary',
    border:     'none',
    shape:      'rectangle',
    depth:      0,

    previewSize: {
      width:  160,
      height: 24,
    },
  })

}