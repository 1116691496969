import React from 'react'
import { MessageFeedback } from '~/models'
import ButtonFeedbackBubble from './ButtonFeedbackBubble'
import ChoiceFeedbackBubble from './ChoiceFeedbackBubble'
import MediaFeedbackBubble from './MediaFeedbackBubble'
import NumericFeedbackBubble from './NumericFeedbackBubble'
import RatingFeedbackBubble from './RatingFeedbackBubble'
import TextFeedbackBubble from './TextFeedbackBubble'

export default function renderFeedbackBubble(messageID: string | null, feedback: MessageFeedback) {
  switch (feedback.type) {
    case 'text':
      return (
        <TextFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )

    case 'button':
      return (
        <ButtonFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )

    case 'choice':
      return (
        <ChoiceFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )

    case 'media':
      return (
        <MediaFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )

    case 'numeric':
      return (
        <NumericFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )

    case 'rating':
      return (
        <RatingFeedbackBubble
          messageID={messageID}
          feedback={feedback}
        />
      )
  }
}