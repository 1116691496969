import React from 'react'
import Color from 'color'
import { clamp } from 'lodash'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling, useTheme } from '~/ui/styling'

export interface Props {
  progress:  number
  max?:      number

  ticks?:    Tick[]
  regions?:  Region[]

  caption?: string

  barColor?: Color

  height?: number
}

export interface Tick {
  at:     number
  color?: Color
}

export interface Region {
  from:  number
  to:    number
  color: Color
}

const ProgressBar = memo('ProgressBar', (props: Props) => {

  const {guide} = useStyling()

  const {
    progress,
    max     = 1,
    ticks   = [],
    regions = [],
    caption,
    barColor,
    height = barHheight,
  } = props

  const width = `${clamp(progress / max, 0, 1) * 100}%`

  //------
  // Rendering

  const $ = useStyles(height)

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderCaption()}
        <BrandedComponent classNames={$.ProgressBar} branding={guide.progressBar} height={height}>
        <HBox flex align='stretch'>
          {renderBar()}
          {ticks.map(renderTick)}
          {regions.map(renderRegion)}
        </HBox>
      </BrandedComponent>
      </VBox>
    )
  }

  function renderCaption() {
    if (caption == null) { return null }

    return (
      <Label
        small
        bold
        light
        children={caption}
      />
    )
  }

  function renderBar() {
    return (
      <BrandedComponent
        branding={guide.progressBar.inner}
        style={{width, backgroundColor: barColor?.string()}}
        classNames={$.barInner}
        height={height}
      />
    )
  }

  function renderTick(tick: Tick) {
    return <TickView key={tick.at} tick={tick} max={max}/>
  }

  function renderRegion(region: Region) {
    return <RegionView key={`${region.from}-${region.to}`} region={region} max={max}/>
  }

  return render()

})

export default ProgressBar

interface RegionViewProps {
  region: Region
  max:    number
}

const RegionView = memo('RegionView', (props: RegionViewProps) => {

  const $ = useStyles()

  const {region, max} = props
  const {from, to, color} = region

  const left  = `${clamp(from / max, 0, 1) * 100}%`
  const width = `${clamp((to / max) - (from / max), 0, 1) * 100}%`

  return (
    <div
      classNames={$.tick}
      style={{left, width, backgroundColor: color.string()}}
    />
  )

})

interface TickViewProps {
  tick: Tick
  max:  number
}

const TickView = memo('TickView', (props: TickViewProps) => {

  const theme = useTheme()
  const $     = useStyles()

  const {tick, max} = props
  const {at, color = theme.fg.normal} = tick
  const left = `${clamp(at / max, 0, 1) * 100}%`

  return (
    <div
      classNames={$.tick}
      style={{left, backgroundColor: color.string()}}
    />
  )

})

export const barHheight = 8

const useStyles = createUseStyles(theme => {
  return {
    ProgressBar: (height: number) => ({
      height,
      overflow: 'hidden',
    }),

    barInner: (height: number) => ({
      height,
    }),

    tick: {
      position:   'absolute',
      top:        0,
      bottom:     0,
      width:      1,
      marginLeft: -0.5,
    },
  }
})