import React from 'react'
import { NewsService } from '~/stores'
import { memo } from '~/ui/component'

interface NewsContext {
  service: NewsService | null
}

const NewsContext = React.createContext<NewsContext>({
  service: null,
})

export interface NewsProviderProps {
  service:   NewsService
  children?: React.ReactNode
}

export const NewsProvider = memo('NewsProvider', (props: NewsProviderProps) => {

  const {service, children} = props

  const context = React.useMemo((): NewsContext => ({
    service,
  }), [service])

  return (
    <NewsContext.Provider value={context}>
      {children}
    </NewsContext.Provider>
  )

})

export function useNews() {
  return React.useContext(NewsContext)
}

export function useNewsService() {
  const {service} = useNews()
  if (service == null) {
    throw new Error("This components needs to be placed in a NewsProvider")
  }

  return service
}