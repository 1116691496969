import React from 'react'
import Color from 'color'
import { memo } from '~/ui/component'
import { Center, SVG, Tappable } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { animation, createUseStyles, layout, shadows, useTheme } from '~/ui/styling'

export interface Props {
  isOn:      boolean | null
  onChange?: (on: boolean | null) => void

  tri?:       boolean
  onLabel?:   React.ReactNode
  offLabel?:  React.ReactNode
  onColor?:   Color
  offColor?:  Color
  nullColor?: Color

  enabled?: boolean
  small?:   boolean

  classNames?: React.ClassNamesProp
}

const Switch = memo('Switch', (props: Props) => {

  const theme = useTheme()
  const {
    isOn,
    onChange,

    tri,
    onLabel,
    offLabel,
    onColor,
    offColor,
    nullColor,

    enabled = true,
    small   = false,
  } = props

  const triState = !tri
    ? (isOn ? 'on' : 'off')
    : (isOn == null ? 'null' : isOn ? 'on' : 'off')

  const change = React.useCallback(() => {
    const {isOn, tri} = props
    if (tri && isOn === false) {
      onChange?.(null)
    } else {
      onChange?.(!isOn)
    }
  }, [onChange, props])

  //------
  // Rendering

  const $ = useStyles()

  function render() {

    const classNames = [
      $.switch,
      {tri, small},
      !enabled && 'disabled',
      triState,
      props.classNames,
    ]

    if (onChange != null) {
      return (
        <Tappable
          classNames={classNames}
          enabled={enabled}
          onTap={change}
          focusable={false} // The focus should go to the hidden checkbox.
          children={renderContent()}
        />
      )
    } else {
      return renderContent(classNames)
    }
  }

  function renderContent(classNames: React.ClassNamesProp = null) {
    return (
      <div classNames={classNames} style={colorStyle}>
        {renderLabel('on')}
        {renderLabel('off')}
        {renderThumb()}
        <input
          type='checkbox'
          classNames={$.hideCheckBox}
          checked={isOn !== undefined && isOn !== false}
          onChange={change}
        />
      </div>
    )
  }

  const colorStyle = React.useMemo((): React.CSSProperties => {
    const colorStyle: React.CSSProperties = {}
    if (isOn && onColor != null) {
      colorStyle.backgroundColor = onColor.string()
    }
    if (isOn === false && offColor != null) {
      colorStyle.backgroundColor = offColor.string()
    }
    if (tri && isOn == null && nullColor != null) {
      colorStyle.backgroundColor = nullColor.string()
    }

    return colorStyle
  }, [isOn, nullColor, offColor, onColor, tri])

  function renderLabel(which: 'on' | 'off') {
    const label = which === 'on' ? onLabel : offLabel

    return (
      <Center classNames={[$.label, which]}>
        {label ?? (
          <SVG
            name={`switch-${which}` as SVGName}
            size={labelSize[small ? 'small' : 'normal']}
            color={theme.colors.fg.light.normal}
          />
        )}
      </Center>
    )
  }

  function renderThumb() {
    return (
      <div
        classNames={[$.thumb, {tri}, triState]}
      />
    )
  }

  return render()

})

export default Switch

export const size = {
  small: {
    width:  32,
    height: 20,
  },
  normal: {
    width:  40,
    height: 24,
  },
}

const thumbSize = {small: 12, normal: 16}
const padding   = {
  small:  (size.small.height - thumbSize.small) / 2,
  normal: (size.normal.height - thumbSize.normal) / 2,
}
const labelSize = {
  small:  {width: 8, height: 8},
  normal: {width: 10, height: 10},
}

const switchShadow = ['inset', 0, 1, 3, 0, shadows.shadowColor]

const white = new Color('white')

const useStyles = createUseStyles(theme => ({
  switch: {
    ...layout.flex.row,
    position: 'relative',
    overflow: 'hidden',

    '&.small': {
      ...size.small,
      borderRadius: size.small.height / 2,
    },

    '&:not(.small)': {
      ...size.normal,
      borderRadius: size.normal.height / 2,
    },

    boxShadow: switchShadow,

    '&:focus, &:focus-within': {
      boxShadow: [
        switchShadow,
        shadows.focus.bold(theme),
      ],
    },

    '&.disabled': {
      opacity: 0.4,
      cursor: 'default',

      '&:focus': {boxShadow: 'none'},
    },

    willChange: 'background-color',
    transition: animation.transitions.short('background-color'),

    '&.on': {
      backgroundColor: theme.colors.named.lightGreen,
    },

    '&.null': {
      backgroundColor: theme.colors.named.lightBlue,
    },

    '&.off': {
      backgroundColor: theme.colors.named.lightRed,
    },
  },

  thumb: {
    position:   'absolute',
    willChange: 'transform',
    transition: animation.transitions.fast('transform', 'ease-out'),

    '$switch.small &': {
      top:          padding.small,
      left:         padding.small,
      width:        thumbSize.small,
      height:       thumbSize.small,
      borderRadius: thumbSize.small / 2,

      '&.off': {
        transform: 'translateX(0)',
      },

      '&.null': {
        transform: `translateX(${thumbSize.small / 2}px)`,
      },

      '&.on': {
        transform: `translateX(${thumbSize.small}px)`,
      },

      boxShadow:  [0, 1, 1, 0, shadows.shadowColor.alpha(0.3)],
    },
    '$switch:not(.small) &': {
      top:          padding.normal,
      left:         padding.normal,
      width:        thumbSize.normal,
      height:       thumbSize.normal,
      borderRadius: thumbSize.normal / 2,

      '&.off': {
        transform: 'translateX(0)',
      },

      '&.null': {
        transform: `translateX(${thumbSize.normal / 2}px)`,
      },

      '&.on': {
        transform: `translateX(${thumbSize.normal}px)`,
      },

      boxShadow:  [0, 1, 2, 0, shadows.shadowColor],
    },

    background: white,
  },

  label: {
    position: 'absolute',
    top:      0,
    bottom:   0,

    '$switch.small &':  {width: thumbSize.small},
    '$switch:not(.small) &': {width: thumbSize.normal},
    ...layout.flex.center,

    '&.on': {
      left:   2,
    },

    '&.off': {
      right:  2,
    },
  },

  hideCheckBox: {
    position: 'absolute',
    width:    0,
    height:   0,
    top:      -20,
    left:     -20,
  },
}))