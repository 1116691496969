export interface TappableState {
  focused: boolean
  hover:   boolean
  active:  boolean
}

export const TappableState: {
  empty: TappableState
} = {
  empty: {
    focused: false,
    hover:   false,
    active:  false,
  },
}