import React from 'react'
import { MessageListItem } from '~/models'
import { observer } from '~/ui/component'
import { ChatBubbleBranding } from '~/ui/styling'
import { ChatChannelController, ChatController } from './types'

interface ChatContext {
  chat:    ChatController | null
  channel: ChatChannelController | null

  showChannelInfo:    boolean
  setShowChannelInfo: (value: boolean) => void

  brandingForMessage: (message: MessageListItem) => ChatBubbleBranding | null
  onMessageTap?:      (message: MessageListItem) => any
}

const ChatContext = React.createContext<ChatContext>({
  chat:    null,
  channel: null,

  showChannelInfo:    false,
  setShowChannelInfo: () => void 0,

  brandingForMessage: () => null,
})

export interface Params {
  channelID?: string
}

export interface ChatContextProviderProps {
  chat:     ChatController | null
  channel:  ChatChannelController | null
  children: React.ReactNode

  brandingForMessage?: (message: MessageListItem) => ChatBubbleBranding | null
  onMessageTap?:       (message: MessageListItem) => any
}

export const ChatContextProvider = observer('ChatContextProvider', (props: ChatContextProviderProps) => {

  const {chat, channel, children, brandingForMessage, onMessageTap} = props
  const [showChannelInfo, setShowChannelInfo] = React.useState<boolean>(false)

  const context = React.useMemo(() => ({
    chat:               chat,
    channel:            channel,
    showChannelInfo:    showChannelInfo,
    setShowChannelInfo: setShowChannelInfo,
    brandingForMessage: brandingForMessage ?? (() => null),
    onMessageTap:       onMessageTap,
  }), [chat, channel, showChannelInfo, brandingForMessage, onMessageTap])

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  )
})

export function useChat() {
  return React.useContext(ChatContext)
}

export default ChatContextProvider