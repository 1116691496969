import { isPlainObject, merge } from 'lodash'
import Logger from 'logger'
import { action, computed, makeObservable, observable } from 'mobx'
import Database from './Database'
import Document from './Document'
import { Fetch } from './Fetch'
import {
  AppendOptions,
  CollectionFetchOptions,
  CollectionFetchResponse,
  DocumentData,
  EndpointOptions,
  FetchStatus,
  isErrorResponse,
} from './types'
import { objectContains, objectEquals } from './util'

const logger = new Logger('mobx-document')

export default abstract class Endpoint<D extends Document<any, any, any, any>, P extends Record<string, any> = any, M = any> {

  constructor(
    public readonly database: Database<D>,
    defaultParams: P,
    private readonly options: EndpointOptions<D, M> = {},
  ) {
    this.defaultParams = {...defaultParams}
    this.params        = {...defaultParams}

    if (options.initialMeta != null) {
      this.meta = options.initialMeta
    }

    makeObservable(this)

    if (options.initialData != null) {
      this.replace(options.initialData)
      this.fetchStatus = 'done'
    }
  }

  private readonly defaultParams: P

  @observable.ref
  protected params: P = {} as P

  public param<K extends keyof P>(name: K): P[K] {
    return this.params[name]
  }

  @action
  public setParams(params: Partial<P>, fetch: boolean = true) {
    if (this.fetchStatus === 'done' && objectContains(this.params, params)) { return }

    this.params = merge(this.params, params)

    // If requested, perform a re-fetch unless we haven't started fetching yet.
    if (fetch && this.fetchStatus !== 'idle') {
      this.fetch()
    }
  }

  @action
  public async fetchWithParams(params: Partial<P>, options: CollectionFetchOptions = {}) {
    this.params = {
      ...this.params,
      ...params,
    }

    await this.fetch(options)
  }

  @action
  public resetAndFetch(params?: Partial<P>) {
    this.params = {...this.defaultParams, ...params}
    this.fetch()
  }

  @observable.ref
  public ids: Array<D['id']> = []

  @computed
  public get documents() {
    return this.database.listDocuments(this.ids)
  }

  @computed
  public get data() {
    return this.database.list(this.ids)
  }

  @computed
  public get count(): number {
    return this.ids.length
  }

  @computed
  public get empty() {
    return this.data.length === 0
  }

  @observable.shallow
  public meta: M | null = null

  @computed
  public get asFetch(): Fetch<DocumentData<D>[]> {
    if (this.fetchStatus !== 'done') {
      return {status: this.fetchStatus}
    } else {
      return {status: 'done', data: this.data}
    }

  }

  //------
  // Fetch

  @observable
  public fetchStatus: FetchStatus = 'idle'

  private lastFetchPromise: Promise<void> | null = null
  private lastFetchParams: Record<string, any> | null = null

  @action
  public markFetched() {
    this.fetchStatus = 'done'
  }

  @action
  public async fetchIfNeeded(options: CollectionFetchOptions = {}): Promise<void> {
    if (this.fetchStatus === 'done') { return }
    await this.fetch(options)
  }

  @action
  public fetch(options: CollectionFetchOptions<P> = {}): Promise<void> {
    const {params, lastFetchParams} = this

    if (this.lastFetchPromise != null && lastFetchParams != null && objectEquals(params, lastFetchParams)) {
      return this.lastFetchPromise
    }

    const promise: Promise<void> = this
      .performFetch({...params, ...options.extraParams}, options)
      .then(
        response => this.onFetchSuccess(promise, response, options),
        response => this.onFetchError(promise, response, options),
      )

    this.fetchStatus      = 'fetching'
    this.lastFetchParams  = {...params}
    this.lastFetchPromise = promise

    return promise
  }

  protected abstract performFetch(params: P, options: CollectionFetchOptions): Promise<CollectionFetchResponse<DocumentData<D>, M> | null>

  private onFetchSuccess = action((promise: Promise<any>, response: CollectionFetchResponse<DocumentData<D>, M> | null, options: CollectionFetchOptions) => {
    if (promise !== this.lastFetchPromise) { return }

    this.lastFetchPromise = null
    this.lastFetchParams = null

    if (response == null) { return }

    if (isErrorResponse(response)) {
      this.fetchStatus = response.error
      this.meta        = this.options.initialMeta ?? null
    } else if (options.append) {
      this.fetchStatus = 'done'
      this.append(response.data, response.meta)
    } else {
      this.fetchStatus = 'done'
      this.replace(response.data, response.meta)
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onFetchError = action((promise: Promise<any>, error: Error, options: CollectionFetchOptions) => {
    if (promise !== this.lastFetchPromise) { return }

    this.lastFetchPromise = null
    this.lastFetchParams  = null
    this.fetchStatus      = error
    logger.error(`Error while fetching collection: ${error.message}`, error)
  })

  //------
  // Updates

  @action
  public replace(data: DocumentData<D>[], meta?: M) {
    this.ids = []
    this.append(data, meta)
    this.fetchStatus = 'done'
  }

  @action
  public replaceMeta(meta: M) {
    this.meta = meta
  }

  @action
  public updateMeta(meta: Partial<M>) {
    if (this.meta == null) { return }
    this.meta = {...this.meta, meta}
  }

  @action
  public append(data: DocumentData<D>[], meta?: M) {
    for (const item of data) {
      if (isPlainObject(item) && 'data' in (item as any) && 'meta' in (item as any)) {
        this.add(item.data, item.meta)
      } else {
        this.add(item)
      }
    }

    if (meta !== undefined) {
      this.meta = meta
    }
  }

  @action
  public add(item: DocumentData<D>, meta?: D['meta'], replaceMeta: boolean = false) {
    const document = this.store(item)
    if (meta != null) {
      if (document.meta != null && !replaceMeta) {
        document.mergeMeta(meta)
      } else {
        document.setMeta(meta)
      }
    }
    this.ids = [...this.ids, document.id]
  }

  @action
  public insert(item: DocumentData<D>, index: number) {
    const document = this.store(item)
    this.ids = [
      ...this.ids.slice(0, index),
      document.id,
      ...this.ids.slice(index),
    ]
  }

  @action
  public appendIDs(ids: string[], options: AppendOptions = {}) {
    for (const id of ids) {
      this.appendID(id, options)
    }
  }

  @action
  public appendID(id: string, options: AppendOptions = {}) {
    const {
      ignoreIfExists = true,
    } = options

    if (ignoreIfExists && this.ids.includes(id)) { return }
    this.ids = [...this.ids, id]
  }

  @action
  public remove(ids: string[]) {
    this.ids = this.ids.filter(id => !ids.includes(id))

    for (const id of ids) {
      this.database.delete(id)
    }
  }

  @action
  public clear() {
    this.ids         = []
    this.meta        = this.options.initialMeta ?? null
    this.fetchStatus = 'idle'
  }

  @action
  protected store(item: DocumentData<D>): D {
    return this.database.store(item)
  }

}