import React from 'react'
import cn from 'classnames'
import { forwardRef } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { ResponsiveProp } from '~/ui/styling/layout'
import { FlexProp, flexStyle, useGapStyles, usePaddingStyles } from './styles'

export interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  padding?:           number | ResponsiveProp<number>
  paddingHorizontal?: number | ResponsiveProp<number>
  paddingVertical?:   number | ResponsiveProp<number>

  gap?: number | ResponsiveProp<number>

  align?:   'stretch' | 'top' | 'middle' | 'bottom' | 'baseline'
  justify?: 'left' | 'center' | 'right' | 'space-between' | 'space-around'

  flex?: FlexProp
  wrap?: boolean

  tag?:        string
  classNames?: React.ClassNamesProp
}

const HBox = forwardRef('HBox', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    align,
    justify,
    gap,
    padding,
    paddingHorizontal,
    paddingVertical,
    flex,
    wrap = false,
    tag = 'div',
    classNames,
    ...rest
  } = props

  const $        = useStyles(props)
  const gap$     = useGapStyles('row', gap, wrap)
  const padding$ = usePaddingStyles(padding, paddingHorizontal, paddingVertical)

  const className = cn([
    $.HBox,
    $[align ?? 'middle'],
    $[justify ?? 'left'],
    gap$?.gap,
    padding$.padding,
    {wrap},
    classNames,
  ])

  const style = {
    ...flexStyle(flex),
    ...props.style,
  }

  return React.createElement(tag, {
    ...rest,
    className,
    style,
    ref,
  }, props.children)

})

export default HBox

const useStyles = createUseStyles({
  HBox: {
    ...layout.flex.row,

    '&.wrap': {
      flexWrap: 'wrap',
    },
  },

  stretch: {
    alignItems: 'stretch',
  },

  top: {
    alignItems: 'flex-start',
  },

  middle: {
    alignItems: 'center',
  },

  baseline: {
    alignItems: 'baseline',
  },

  bottom: {
    alignItems: 'flex-end',
  },

  left: {
    justifyContent: 'flex-start',
  },

  center: {
    justifyContent: 'center',
  },

  right: {
    justifyContent: 'flex-end',
  },

  'space-between': {
    justifyContent: 'space-between',
  },

  'space-around': {
    justifyContent: 'space-around',
  },
})