import { ComponentBranding } from './components'
import { BrandedComponentSpec, BrandingGuideDelegate } from './types'

export default class ActionStateLabelBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ActionStateLabelBranding | null,
    defaults: Partial<BrandedComponentSpec> = {},
  ) {
    super(delegate,parent, {
      background: 'alt',
      border:     'none',
      shape:      'oval',
      depth:      0,
      ...defaults,
    })

    this.variant('active', {
      background: 'primary',
    })

    this.button = new ComponentBranding(delegate, parent?.button ?? null, {
      background: 'alt',
      border:     'none',
      shape:      'oval',
      depth:      1,
    })
  }

  public button: ComponentBranding

}