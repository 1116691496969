import React from 'react'
import { useTranslation } from 'react-i18next'
import { Answer, AnswerResult, NumericFeedback, numericFeedbackCaption } from '~/models'
import { VBox, Label, Slider } from '~/ui/components'
import { layout } from '~/ui/styling'
import { AnswerOutcomeRenderUtil } from '../../tasks/ChallengeTaskAnswerOutcome'

export interface Props {
  feedback:       NumericFeedback
  answer:         Answer
  result:         AnswerResult | null
  correctAnswers: any[]
  renderUtil:     AnswerOutcomeRenderUtil
}

const NumericAnswerOutcome = React.memo((props: Props) => {

  const {feedback, answer, correctAnswers, renderUtil} = props
  const labelForValue = (value: any) => value == null ? null : numericFeedbackCaption(feedback, value)

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  function render() {
    return (
      <VBox>
        {renderMyAnswer()}
        {renderCorrectAnswer()}
      </VBox>
    )
  }

  function renderMyAnswer() {
    return (
      <>
        {renderUtil.renderCaption(t('task.your_answer'), true)}
        {renderValue(answer.answer)}
      </>
    )
  }

  function renderCorrectAnswer() {
    if (correctAnswers.length === 0) { return null }
    return (
      <>
        {renderUtil.renderLine()}
        {renderUtil.renderCaption(t('task.correct_answer'))}
        {renderValue(correctAnswers[0])}
      </>
    )
  }

  function renderValue(value: number) {
    const label = labelForValue(value)

    return (
      <VBox flex='grow' padding={layout.padding.inline.m} gap={layout.padding.inline.xs} justify='middle'>
        <Slider
          value={answer.answer}
          min={feedback.min}
          max={feedback.max}
          step={feedback.step}
          readOnly={true}
        />
        {label != null && renderLabel(label)}
      </VBox>
    )
  }

  function renderLabel(label: string) {
    if (feedback.step == null) { return null }

    return (
      <Label bold align='center'>
        {label}
      </Label>
    )
  }

  return render()

})

export default NumericAnswerOutcome