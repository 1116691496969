import React from 'react'
import { ErrorBoundary as ErrorBoundaryComponent, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { memo } from '~/ui/component'
import { Empty } from '~/ui/components'

export interface Props {
  title?:    string
  detail?:   string
  children?: React.ReactNode
  onError?:  (error: any) => any
}

const ErrorBoundary = memo('ErrorBoundary', (props: Props) => {

  const {title, detail, children, onError} = props

  const [t] = useTranslation('app')

  //------
  // Rendering

  function render() {
    return (
      <ErrorBoundaryComponent
        FallbackComponent={renderFallback}
        onError={onError}
        children={children}
      />
    )
  }

  const renderFallback = React.useCallback((fallbackProps: FallbackProps) => (
    <ErrorBoundaryFallback
      title={title ?? t('error.title')}
      detail={detail ?? t('error.detail')}
      {...fallbackProps}
    />
  ), [title, detail, t])

  return render()

})

export default ErrorBoundary

export interface ErrorBoundaryFallbackProps extends FallbackProps {
  title:  string
  detail: string
}

const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {

  const {title, detail, resetErrorBoundary} = props

  const {pathname} = useLocation()
  const previousPathname = React.useRef<string>(pathname)

  React.useEffect(() => {
    if (previousPathname.current === pathname) { return }

    // Reset the error boundary upon navigation
    resetErrorBoundary()
  }, [pathname, resetErrorBoundary])

  return (
    <Empty
      title={title}
      detail={detail}
      flex
    />
  )
}