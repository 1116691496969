import React from 'react'
import { observer as mobx_observer } from 'mobx-react'

export function observer<T extends React.ComponentType<any>>(name: string, Component: T): T {
  Object.assign(Component, {displayName: name})
  return mobx_observer(Component)
}

export function memo<T extends React.ComponentType<any>>(
  name:           string,
  Component:      T,
  propsAreEqual?: (prevProps: Readonly<React.ComponentProps<T>>, nextProps: Readonly<React.ComponentProps<T>>) => boolean,
) {
  Object.assign(Component, {displayName: name})
  return React.memo(Component, propsAreEqual)
}