import React from 'react'
import { useSize } from 'react-measure'
import { useTimer } from 'react-timer'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'

export interface Props extends VBoxProps {
  transitionKey:   any
  duration:        number
  timingFunction?: string

  onStartTransition?: () => any
  onEndTransition?:   () => any

  children?: React.ReactNode
}

const SizeTransitioner = memo('SizeTransitioner', (props: Props) => {

  const {
    transitionKey,
    duration,
    timingFunction = 'cubic-bezier(0.22, 0.61, 0.36, 1)',
    onStartTransition,
    onEndTransition,
    ...rest
  } = props

  const prevTransitionKey = usePrevious(transitionKey)
  const elementRef = React.useRef<HTMLDivElement>(null)

  // Continuously measure the size of the element.
  const sourceSizeRef = React.useRef<Size>({width: 0, height: 0})

  useSize(elementRef, {debounce: 1}, size => {
    if (prevTransitionKey !== undefined && prevTransitionKey !== transitionKey) { return }
    sourceSizeRef.current = size
  })

  //------
  // Transitioning

  const timer = useTimer()

  // Keep track of whether we're currently transitioning.
  const transitioningRef = React.useRef<boolean>(false)
  // const [transitioning, setTransitioning] = React.useState<boolean>(false)

  const targetSizeRef = React.useRef<Size>({width: 0, height: 0})

  const measure = React.useCallback((which: 'target' | 'source') => {
    if (elementRef.current == null) { return }

    if (which === 'target') {
      targetSizeRef.current = {
        width:  elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      }
    } else {
      sourceSizeRef.current = {
        width:  elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      }
    }
  }, [])

  const setSize = React.useCallback((size: Size, animated: boolean) => {
    if (elementRef.current == null) { return }

    Object.assign(elementRef.current.style, {
      width:     `${size.width}px`,
      height:    `${size.height}px`,
      minWidth:  0,
      minHeight: 0,
      maxWidth:  'auto',
      maxHeight: 'auto',

      transitionDuration:       animated ? `${duration}ms` : '',
      transitionTimingFunction: animated ? timingFunction : '',
    })
  }, [duration, timingFunction])

  const reset = React.useCallback(() => {
    if (elementRef.current == null) { return }

    Object.assign(elementRef.current.style, {
      width:     '',
      height:    '',
      minWidth:  '',
      minHeight: '',
      maxWidth:  '',
      maxHeight: '',

      transitionDuration:       '',
      transitionTimingFunction: '',
    })
  }, [])

  // When the transitionKey changes, fix the size first. Then, without allowing a reflow:
  //
  // 1. Unfix the size
  // 2. Measure the new size
  // 3. Fix the size again
  // 4. Start the transition.
  React.useLayoutEffect(() => {
    if (prevTransitionKey === undefined || transitionKey === prevTransitionKey) { return }

    transitioningRef.current = true
    onStartTransition?.()

    measure('target')
    setSize(sourceSizeRef.current, false)

    timer.setTimeout(() => {
      setSize(targetSizeRef.current, true)
    }, FRAME)

    timer.setTimeout(() => {
      reset()
      measure('source')
      transitioningRef.current = false
      onEndTransition?.()
    }, FRAME + duration)
  }, [duration, setSize, measure, timer, timingFunction, transitionKey, reset, prevTransitionKey, onStartTransition, onEndTransition])

  return (
    <VBox
      ref={elementRef}
      {...rest}
    />
  )

})

const FRAME = 16

export default SizeTransitioner