import React from 'react'
import { useTimer } from 'react-timer'
import { DateTime } from 'luxon'
import { clockStore } from '~/stores'
import { observer } from '~/ui/component'

export interface ClockContext {
  serverDelta:    number | null
  getCurrentTime: () => DateTime | null
}

export const ClockContext = React.createContext<ClockContext>({
  serverDelta:    null,
  getCurrentTime: () => null,
})

interface ClockContainerProps {
  children?: React.ReactNode
}

export const ClockContainer = observer('ClockContainer', (props: ClockContainerProps) => {
  const serverDelta = clockStore.serverDelta

  const getCurrentTime = React.useCallback(() => {
    if (serverDelta == null) { return null }
    return DateTime.local().plus(serverDelta)
  }, [serverDelta])

  const context = React.useMemo((): ClockContext => ({
    serverDelta,
    getCurrentTime,
  }), [getCurrentTime, serverDelta])

  return (
    <ClockContext.Provider value={context}>
      {props.children}
    </ClockContext.Provider>
  )
})

export function useClock(options: UseClockOptions = {}) {
  const {interval = 'minute', active = true} = options

  const {getCurrentTime, serverDelta} = React.useContext(ClockContext)
  const [currentTime, setCurrentTime] = React.useState<DateTime | null>(getCurrentTime())

  const running = active && serverDelta != null

  const timer = useTimer()

  const nextInterval = React.useCallback((time: DateTime) => {
    if (interval === 'once') { return null }

    const periodMS = interval === 'minute' ? 60_000 : 1000
    return periodMS - time.valueOf() % periodMS
  }, [interval])

  const tick = React.useCallback(() => {
    const currentTime = getCurrentTime()
    setCurrentTime(currentTime)

    const interval = currentTime == null ? null : nextInterval(currentTime)
    if (interval != null) {
      timer.setTimeout(tick, interval)
    }
  }, [getCurrentTime, nextInterval, timer])

  React.useEffect(() => {
    if (!running) { return }

    timer.setTimeout(tick, 0)
    return () => { timer.clearAll() }
  }, [running, tick, timer])

  return {
    currentTime,
    getCurrentTime,
    tick,
  }
}

export interface UseClockOptions {
  active?:   boolean
  interval?: 'once' | 'minute' | 'second'
}

export interface UseClockHook {
  currentTime:    DateTime | null
  getCurrentTime: () => DateTime | null
  tick:           () => any
}