import React from 'react'
import { DropzoneRef } from 'react-dropzone'
import { useTimer } from 'react-timer'
import { forwardRef } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'
import Dropzone, { DropzoneState, Props as DropzoneProps } from '~/ui/components/Dropzone'
import { colors, createUseStyles, layout } from '~/ui/styling'

export interface Props<T> extends Omit<DropzoneProps, 'dropzoneRef' | 'onDrop' | 'renderContent'> {
  upload: (file: File) => Promise<T | null>

  onUploadStart?:       (file: File) => any
  onUploadComplete?:    (result: T) => any
  onUploadNotComplete?: (file: File) => any

  renderContent:  (state: UploaderState) => React.ReactNode
  renderPreview?: (previewURLs: string[] | null) => React.ReactNode

  createPreviewURL?: (file: File) => string | Promise<string>
}

interface Uploader {
  browse(): void
}

export interface UploaderState extends DropzoneState {
  uploading:       boolean
  uploadedFiles:   File[]
  renderPreview:   () => React.ReactNode
  renderUploading: () => React.ReactNode
}

export interface UploadData {
  contentType: string
  base64:      string
  prefix?:     string
  filename?:   string
}

const noop = () => null

const _Uploader = <T extends {}>(props: Props<T>, ref: React.Ref<Uploader>) => {

  const {
    upload,
    onUploadStart,
    onUploadComplete,
    onUploadNotComplete,
    renderContent,
    renderPreview: props_renderPreview = noop,
    createPreviewURL,
    ...rest
  } = props

  //------
  // Uploading

  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([])
  const [previewURLs, setPreviewURLs] = React.useState<string[] | null>(null)
  const uploading = uploadedFiles.length > 0

  const timer = useTimer()

  const handleDrop = React.useCallback(async (files: File[]) => {
    if (files.length === 0) { return }

    const uploadedFiles = new Set(files)
    setUploadedFiles([...uploadedFiles])

    try {
      const promises = files.map(async file => {
        onUploadStart?.(file)

        const result = await timer.await(upload(file))
        uploadedFiles.delete(file)
        setUploadedFiles([...uploadedFiles])

        if (result != null) {
          onUploadComplete?.(result)
        } else {
          onUploadNotComplete?.(file)
        }
      })

      await timer.await(Promise.all(promises))
    } finally {
      setUploadedFiles([])
    }
  }, [onUploadComplete, onUploadNotComplete, onUploadStart, timer, upload])

  const getPreviewURLs = React.useCallback(async (files: File[]): Promise<string[]> => {
    const promises = files.map(async (file): Promise<string> => {
      if (createPreviewURL != null) {
        return await createPreviewURL(file)
      } else {
        return URL.createObjectURL(file)
      }
    })

    return await Promise.all<string[]>(promises as any) // TypeScript is really weird with this.
  }, [createPreviewURL])

  React.useEffect(() => {
    let canceled: boolean = false
    let mostRecentURLs: string[] = []

    if (uploadedFiles == null) {
      setPreviewURLs(null)
    } else {
      getPreviewURLs(uploadedFiles).then(urls => {
        if (canceled) { return }
        setPreviewURLs(mostRecentURLs = urls)
      })
    }

    return () => {
      for (const url of mostRecentURLs) {
        URL.revokeObjectURL(url)
      }
      canceled = true
    }
  }, [createPreviewURL, getPreviewURLs, uploadedFiles])

  const dropzoneRef = React.useRef<DropzoneRef>(null)

  const browse = React.useCallback(() => {
    dropzoneRef.current?.open()
  }, [])

  React.useImperativeHandle(ref, () => ({
    browse,
  }), [browse])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Dropzone
        {...rest}
        dropzoneRef={dropzoneRef}
        onDrop={handleDrop}
        renderContent={renderDropzoneContent}
      />
    )
  }

  function renderDropzoneContent(state: DropzoneState) {
    return renderContent({
      ...state,
      uploading,
      uploadedFiles,
      renderPreview,
      renderUploading,
    })
  }

  const renderPreview = React.useCallback(() => {
    return props_renderPreview?.(previewURLs)
  }, [previewURLs, props_renderPreview])

  const renderUploading = React.useCallback(() => {
    if (!uploading) { return null }

    return (
      <Center flex='grow' classNames={$.uploading}>
        <Spinner/>
      </Center>
    )
  }, [$.uploading, uploading])

  return render()

}

type UploaderType = <T extends {}>(props: Props<T> & {ref?: React.Ref<Uploader>}) => React.ReactElement | null

const Uploader = forwardRef('Uploader', _Uploader) as UploaderType
export default Uploader

const useStyles = createUseStyles(theme => ({
  preview: {
    ...layout.overlay,
    width:  '100%',
    height: '100%',

    borderRadius: layout.radius.s,

    '&.contain': {objectFit: 'contain'},
    '&.cover':   {objectFit: 'cover'},
  },

  uploading: {
    ...layout.overlay,
    background: colors.white.alpha(0.6),
  },
}))