import { RawDraftContentState } from 'draft-js'
import * as SM from 'simple-markdown'
import MarkdownToDraftJSBuilder from './MarkdownToDraftJSBuilder'
import { Scope } from './types'

export default function markdownToDraftJS(markdown: string | null, rules: SM.ParserRules, scope: Scope): RawDraftContentState {
  const state  = {inline: scope === 'inline'}
  const parser = SM.parserFor(rules)

  const source = scope === 'block' ? markdown + '\n\n' : markdown?.replace(/\n/g, ' ')
  const tree   = parser(source ?? '', state)

  const builder = new MarkdownToDraftJSBuilder(rules, scope, state)
  return builder.buildState(tree)
}