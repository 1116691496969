import React from 'react'
import { pick } from 'lodash'
import { memo } from '~/ui/component'
import { Center, Label, LabelProps, SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'children'> {
  keyHint: string | null
}

const KeyHint = memo('KeyHint', (props: Props) => {

  const {
    keyHint,
    mono = true,
    ...rest
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (keyHint == null) { return null }

    return (
      <Center classNames={$.KeyHint}>
        {renderContent()}
      </Center>
    )
  }

  function renderContent() {
    if (keyHint == null) {
      return renderIcon('empty')
    } else if (keyHint === 'click') {
      return renderIcon('click')
    } else if (keyHint === 'doubleclick') {
      return renderIcon('doubleclick')
    } else {
      return (
        <Label mono={mono} {...rest}>
          {translateKeyHint(keyHint)}
        </Label>
      )
    }
  }

  function renderIcon(icon: SVGName) {
    return (
      <SVG
        name={icon}
        size={props.tiny ? layout.icon.xs : props.small ? layout.icon.s : layout.icon.m}
        {...pick(rest, 'color', 'dim')}
      />
    )

  }

  return render()

})

export default KeyHint

const isMac = 'navigator' in global && navigator.userAgent.toUpperCase().includes('MAC')
const isWin = 'navigator' in global && navigator.userAgent.toUpperCase().includes('WINDOWS')

export function translateKeyHint(keyHint: string) {
  const parts = keyHint.split(/[-+]/)

  const translate = (part: string) => {
    part = part.toLowerCase()

    if (part === 'alt' || part === 'opt' || part === 'option') {
      return isMac ? '⌥' : 'Alt+'
    } else if (part === 'meta') {
      return isMac ? '⌘' : isWin ? 'Win+' : 'Meta+'
    } else if (part === 'short') {
      return isMac ? '⌘' : '^'
    } else if (part === 'control') {
      return '^'
    } else if (part === 'delete' || part === 'backspace') {
      return isMac ? '⌫' : '␡'
    } else if (part === 'return' || part === 'enter') {
      return '⏎'
    } else {
      return part.toUpperCase()
    }
  }

  return parts.map(translate).join('')
}

const useStyles = createUseStyles(theme => ({
  KeyHint: {
    display:      'inline-block',
    padding:      [layout.padding.inline.xs, layout.padding.inline.s],
    background:   theme.bg.hover,
    borderRadius: layout.radius.xs,
  },
}))