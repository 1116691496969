import {
  AtomicBlockUtils,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  convertToRaw,
  DraftBlockType,
  DraftInlineStyle,
  DraftInlineStyleType,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RawDraftEntity,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { draftJSToMarkdown, markdownToDraftJS } from 'draftjs-markdown'
import { pick } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import * as SM from 'simple-markdown'
import { Link, Media } from '~/models'
import { formatWidgetParams } from '~/ui/app/widgets'
import buildRules from '~/ui/components/markdown/rules'
import { SubmitResult } from '~/ui/form'
import { RichTextNodeType, RichTextScope } from '../types'

function buildMarkdownRules(scope: RichTextScope, allowedNodes: RichTextNodeType[] | '*') {
  const nodeTypes: string[] = ['text', 'paragraph', 'newline']
  if (allowedNodes !== '*') {
    nodeTypes.push(...allowedNodes)
  } else {
    nodeTypes.push('em', 'strong', 'link', 'variable')
  }

  if (allowedNodes === '*' && scope === 'block') {
    nodeTypes.push('heading', 'list', 'image', 'youtube', 'vimeo', 'widget')
  }

  if (nodeTypes.includes('link')) {
    nodeTypes.push('autolink', 'url', 'mailto')
  }

  return pick(buildRules(), nodeTypes)
}

export default class DraftJSBackend {

  constructor(
    value: string,
    public readonly scope: RichTextScope,
    private readonly onChange: (value: string, commit: boolean) => any,
    private readonly commit: (value: string) => any,
    allowedNodes: RichTextNodeType[] | '*' = '*',
  ) {
    this.markdownRules = buildMarkdownRules(scope, allowedNodes)

    this.value       = value
    this.editorState = this.editorStateFromMarkdown(value)

    makeObservable(this)
  }

  private readonly decorator = new CompositeDecorator([])

  private readonly markdownRules: SM.ParserRules

  @observable
  public value: string

  @observable
  public editorState: EditorState

  @computed
  public get currentInlineStyle(): DraftInlineStyle {
    return this.editorState.getCurrentInlineStyle()
  }

  @computed
  public get currentBlockType() {
    return RichUtils.getCurrentBlockType(this.editorState)
  }

  //------
  // Updates

  @action
  public updateFromValue(value: string) {
    if (value === this.value) { return }

    this.value       = value
    this.editorState = this.editorStateFromMarkdown(value)
  }

  public handleChange(state: EditorState) {
    this.setEditorState(state)
  }

  @action
  private setEditorState(state: EditorState) {
    // This is a hack to prevent the cursor from resetting when Backspace is pressed.
    // See issues https://github.com/facebook/draft-js/issues/1198 and https://github.com/facebook/draft-js/issues/2431.
    // We assume that whenever some edit action happens, the editor should have focus.
    let selection = state.getSelection()
    if (selection.getHasFocus() === false) {
      selection = new SelectionState(selection.set('hasFocus', true))
      state = EditorState.acceptSelection(state, selection)
    }

    this.editorState = state

    const raw      = convertToRaw(state.getCurrentContent())
    const markdown = draftJSToMarkdown(raw, this.scope, {
      formatEntity: entity => {
        if (entity.type === 'WIDGET') {
          return formatWidgetEntity(entity)
        }
      },
    })
    if (this.value === markdown) { return }

    this.value = markdown
    this.onChange(markdown, false)
  }

  //------
  // Editing

  public modifyState(modify: (state: EditorState) => EditorState | null) {
    const nextState = modify(this.editorState)
    if (nextState != null) {
      this.setEditorState(nextState)
    }
  }

  public keyBindingFn = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey) {
      return 'newline'
    } if (event.key === 'Enter' && KeyBindingUtil.hasCommandModifier(event)) {
      return 'commit'
    } else {
      return getDefaultKeyBinding(event)
    }
  }

  public keyCommandHandler = (command: string) => {
    if (command === 'newline') {
      this.insertSoftNewline()
    } else if (command === 'commit') {
      this.commit(this.value)
      return 'handled'
    } else {
      const state = RichUtils.handleKeyCommand(this.editorState, command)
      if (state != null) {
        this.setEditorState(state)
        return 'handled'
      }
    }

    return 'not-handled'
  }

  public toggleBlockType(type: DraftBlockType) {
    this.modifyState(state => {
      let current = RichUtils.getCurrentBlockType(state)
      if (current === 'unstyled') {
        current = 'paragraph'
      }

      if (current !== type) {
        return RichUtils.toggleBlockType(state, type)
      } else if (type !== 'paragraph') {
        return RichUtils.toggleBlockType(state, 'paragraph')
      } else {
        return state
      }
    })
  }

  public toggleInlineStyle(style: DraftInlineStyleType) {
    this.modifyState(state => RichUtils.toggleInlineStyle(state, style))
  }

  public selectAll() {
    const currentContent    = this.editorState.getCurrentContent()
    const firstBlock        = currentContent.getBlockMap().first()
    const lastBlock         = currentContent.getBlockMap().last()
    const firstBlockKey     = firstBlock.getKey()
    const lastBlockKey      = lastBlock.getKey()
    const lengthOfLastBlock = lastBlock.getLength()

    const selection = new SelectionState({
      anchorKey:    firstBlockKey,
      anchorOffset: 0,
      focusKey:     lastBlockKey,
      focusOffset: lengthOfLastBlock,
    })

    this.setEditorState(EditorState.acceptSelection(this.editorState, selection))
  }

  //------
  // Text

  public insertSoftNewline() {
    this.modifyState(state => RichUtils.insertSoftNewline(state))
  }

  //------
  // Media & links

  public insertMedia(media: Media) {
    if (!(media instanceof Media)) { return }

    this.modifyState(state => {
      const content   = state.getCurrentContent().createEntity('IMAGE', 'IMMUTABLE', {src: media.url})
      const key       = content.getLastCreatedEntityKey()
      const nextState = EditorState.set(state, {
        currentContent: this.processContent(content),
      })

      return AtomicBlockUtils.insertAtomicBlock(nextState, key, ' ')
    })
  }

  public insertLink(link: Link, caption: string | null): Promise<SubmitResult | undefined> {
    if (link == null) { return Promise.resolve(undefined) }

    this.modifyState(state => {
      const content = state.getCurrentContent().createEntity('LINK', 'MUTABLE', {
        url: link.href,
      })
      state = EditorState.set(state, {
        currentContent: this.processContent(content),
      })

      const key = content.getLastCreatedEntityKey()

      let selection = state.getSelection()
      if (selection.getStartKey() === selection.getEndKey() && selection.getStartOffset() === selection.getEndOffset()) {
        const text        = caption ?? link.href
        const nextContent = Modifier.insertText(content, selection, text)
        state = EditorState.push(state, nextContent, 'insert-characters')

        selection = SelectionState.createEmpty(selection.getAnchorKey()).merge({
          anchorOffset: selection.getAnchorOffset(),
          focusOffset:  selection.getAnchorOffset() + text.length,
        })

        state = EditorState.acceptSelection(state, selection)
      }

      state = RichUtils.toggleLink(state, selection, key)
      return state
    })

    return Promise.resolve({status: 'ok'})
  }

  public removeLink(blockKey: string, start: number, end: number) {
    this.modifyState(state => {
      const selection = new SelectionState({
        anchorOffset: start,
        anchorKey:    blockKey,
        focusOffset:  end,
        focusKey:     blockKey,
        isBackward:   false,
        hasFocus:     state.getSelection().getHasFocus(),
      })

      return RichUtils.toggleLink(state, selection, null)
    })
  }

  //------
  // Widgets

  public insertWidget(widget: string, params: Record<string, any> = {}) {
    this.modifyState(state => {
      const content   = state.getCurrentContent().createEntity('WIDGET', 'IMMUTABLE', {widget, params})
      const key       = content.getLastCreatedEntityKey()
      const nextState = EditorState.set(state, {
        currentContent: this.processContent(content),
      })

      return AtomicBlockUtils.insertAtomicBlock(nextState, key, ' ')
    })
  }

  //------
  // Conversions

  private editorStateFromMarkdown(markdown: string) {
    const raw = markdownToDraftJS(markdown, this.markdownRules, this.scope)
    const content = this.processContent(convertFromRaw(raw))
    return EditorState.createWithContent(content, this.decorator)
  }

  private processContent(state: ContentState) {
    state = this.ensureEmptyBlockAfterLastAtomicBlock(state)
    return state
  }

  private ensureEmptyBlockAfterLastAtomicBlock(state: ContentState) {
    const lastBlock = state.getLastBlock()
    if (lastBlock?.getType() !== 'atomic') { return state }

    const blankLine = new ContentBlock({
      key:  lastBlock.getKey() + '_',
      text: '',
      type: 'unstyled',
    });

    const newBlockArray = state.getBlockMap()
      .set(blankLine.getKey(), blankLine)
      .toArray()

    return ContentState.createFromBlockArray(newBlockArray)
  }

}

function formatWidgetEntity(entity: RawDraftEntity) {
  const {widget, params = {}} = entity.data ?? {}
  if (widget == null) { return }

  const paramsString = formatWidgetParams(params, {
    multiline: true,
    indent: 2,
  })

  if (paramsString.length === 0) {
    return `$[${widget}]()`
  } else {
    return `$[${widget}](\n${paramsString}\n)`
  }
}