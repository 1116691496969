import React from 'react'
import { useTimer } from 'react-timer'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { animation, createUseStyles } from '~/ui/styling'

export interface Props extends VBoxProps {
  shake:     boolean
  duration?: number
}

const Shaker = memo('Shaker', (props: Props) => {

  const {shake, duration = animation.durations.medium, classNames, ...rest} = props

  const timer = useTimer()
  const [shaking, setShaking] = React.useState<boolean>(false)

  const prevShake = usePrevious(shake)
  React.useEffect(() => {
    if (prevShake === shake) { return }

    timer.clearAll()
    if (shake) {
      setShaking(true)
      timer.setTimeout(() => {
        setShaking(false)
      }, duration)
    } else {
      setShaking(false)
    }
  }, [duration, prevShake, shake, timer])

  const $ = useStyles()

  return (
    <VBox
      classNames={[$.shaker, {shaking}, classNames]}
      {...rest}
    />
  )


})

export default Shaker

const useStyles = createUseStyles({
  shaker: {
    '&.shaking': {
      animation: `$shake ${animation.durations.medium}ms`,
    },
  },

  '@keyframes shake': {
    '0%':    {transform: 'translateX(0)', animationTimingFunction: 'ease-out'},
    '12.5%': {transform: 'translateX(-8px)', animationTimingFunction: 'ease-in'},
    '25%':   {transform: 'translateX(0)', animationTimingFunction: 'ease-out'},
    '37.5%': {transform: 'translateX(8px)', animationTimingFunction: 'ease-in'},
    '50%':   {transform: 'translateX(0)', animationTimingFunction: 'ease-out'},
    '62.5%': {transform: 'translateX(-8px)', animationTimingFunction: 'ease-in'},
    '75%':   {transform: 'translateX(0)', animationTimingFunction: 'ease-out'},
    '87.5%': {transform: 'translateX(8px)', animationTimingFunction: 'ease-in'},
    '100%':  {transform: 'translateX(0)', animationTimingFunction: 'ease-out'},
  },
})