import { Model } from './Model'
import { Participant } from './Participant'
import { datetime } from './serialization'

export class Connection extends Model {
  public incoming!: boolean

  public sender!:   Participant
  public receiver!: Participant

  public status!:   ConnectionStatus

  @datetime() public confirmedAt!: Date | null
  @datetime() public rejectedAt!: Date | null

  public get other() {
    if (this.incoming) {
      return this.sender
    } else {
      return this.receiver
    }
  }
}

export type ConnectionStatus = 'requested' | 'confirmed' | 'rejected'