import React from 'react'

interface ChallengeTaskNavigationContext {
  currentTaskIndex: number
  hasPrevious:      boolean
  hasNext:          boolean

  previousTask: () => any
  completeTask: () => Promise<boolean>
}

const ChallengeTaskNavigationContext = React.createContext<ChallengeTaskNavigationContext>({
  currentTaskIndex: 0,
  hasPrevious:      false,
  hasNext:          false,

  previousTask: () => void 0,
  completeTask: () => Promise.resolve(false),
})

export default ChallengeTaskNavigationContext

export function useChallengeTaskNavigation() {
  return React.useContext(ChallengeTaskNavigationContext)
}