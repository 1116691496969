/**
 * Calculates the prefix of `array` compared to `base`, i.e. all items that are in `array` before the first item that's in `base`.
 */
export function arrayPrefix<T>(base: T[], array: T[]): T[] {
  if (base.length === 0) { return array }

  const index = array.indexOf(base[0])
  return array.slice(0, index)
}

export function arrayDiff<T>(prev: T[], next: T[], equals: (a: T, b: T) => boolean = (a, b) => a === b): ArrayDiff<T> {
  if (prev.length === 0)  {
    return next.length === 0 ? 'none' : {replace: next}
  }

  // First, find the first item in the previous array in the next array. If it's not found, it's a completely
  // new array.
  let i = 0
  let j = next.findIndex(it => equals(it, prev[0]))

  const prepend: T[] = next.slice(0, j)
  const remove: T[]  = []

  while (i < prev.length && j < next.length) {
    const prevItem = prev[i]
    const nextItem = next[j]

    if (equals(prevItem, nextItem)) {
      i++
      j++
    } else {
      remove.push(prevItem)
      i++
    }
  }

  remove.push(...prev.slice(i))

  const append = next.slice(j)

  if (prepend.length === 0 && remove.length === 0 && append.length === 0) {
    return 'none'
  }
  if (prepend.length > 0 && remove.length === 0 && append.length === 0) {
    return {prepend}
  }
  if (remove.length > 0 && prepend.length === 0 && append.length === 0) {
    return {remove}
  }
  if (append.length > 0 && prepend.length === 0 && remove.length === 0) {
    return {append}
  }

  return {replace: next}
}

export type ArrayDiff<T> =
  | 'none'           // Arrays are equal.
  | {prepend: T[]} // Items have been prepended (new messages).
  | {append:  T[]} // Items have been appended (older messages, when doing pagination).
  | {remove:  T[]} // Certain keys have been removed.
  | {replace: T[]} // Any other change. Value is the new array.

export const ArrayDiff: {
  isPrepend: <T>(diff: ArrayDiff<T>) => diff is {prepend: T[]}
  isAppend: <T>(diff: ArrayDiff<T>) => diff is {append: T[]}
  isRemove: <T>(diff: ArrayDiff<T>) => diff is {remove: T[]}
  isReplace: <T>(diff: ArrayDiff<T>) => diff is {replace: T[]}
} = {
  isPrepend: <T>(diff: ArrayDiff<T>): diff is {prepend: T[]} => {
    return diff !== 'none' && 'prepend' in diff
  },
  isAppend:  <T>(diff: ArrayDiff<T>): diff is {append: T[]} => {
    return diff !== 'none' && 'append' in diff
  },
  isRemove:  <T>(diff: ArrayDiff<T>): diff is {remove: T[]} => {
    return diff !== 'none' && 'remove' in diff
  },
  isReplace: <T>(diff: ArrayDiff<T>): diff is {replace: T[]} => {
    return diff !== 'none' && 'replace' in diff
  },

}