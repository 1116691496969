import React from 'react'
import { useTranslation } from 'react-i18next'
import { Post } from '~/models'
import { profileStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Chip, ConfirmBox, HBox, KebabMenu, LinkLabel, PopupMenuItem, VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { useNewsService } from './NewsContext'

export interface Props {
  post: Post
  interactive?: boolean
  requestEdit?: (post: Post) => any
}

const PostHeader = memo('PostHeader', (props: Props) => {

  const {post, interactive = true, requestEdit} = props
  const theme = useTheme()

  const service = useNewsService()

  const [t] = useTranslation('news')

  const participantID = profileStore.participantID
  const ownPost       = post.author.id === participantID
  const isModerator   = post.feed.moderator

  const edit = React.useCallback(() => {
    requestEdit?.(post)
  }, [post, requestEdit])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('post.remove.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    service.removePost(post.id)
  }, [post.id, service, t])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (ownPost || isModerator) {
      items.push({
        icon:    'pencil',
        caption:  t('post.edit.caption'),
        onSelect: edit,
      }, {
        icon:    'trash',
        caption:  t('post.remove.caption'),
        onSelect: remove,
      })
    }

    return items
  }, [edit, ownPost, isModerator, remove, t])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.l} align='top'>
        <VBox flex gap={layout.padding.inline.s}>
          {renderAuthor()}
          {renderMeta()}
        </VBox>
        {interactive && kebabMenuItems.length > 0 && (
          <KebabMenu
            items={kebabMenuItems}
            button={{small: true, padding: 'vertical'}}
          />
        )}
      </HBox>
    )
  }

  function renderAuthor() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Avatar
          source={post.author.photoURL}
          firstName={post.author.firstName}
          size={avatarSize}
        />
        <LinkLabel h3 href={`/participants/-/${post.author.id}`} color={theme.fg.normal}>
          {post.author.name}
        </LinkLabel>
      </HBox>
    )
  }

  function renderMeta() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderFeed()}
        {renderDate()}
      </HBox>
    )
  }

  function renderFeed() {
    return (
      <Chip backgroundColor={post.feed.color} small>
        {post.feed.title}
      </Chip>
    )
  }

  function renderDate() {
    if (post.publishedAt == null) { return null }

    return (
      <LinkLabel tiny dim href={`/posts/-/${post.id}`}>
        {post.publishedAt?.toFormat('MMM d, HH:mm')}
      </LinkLabel>
    )
  }


  return render()

})

export default PostHeader

export const avatarSize = {
  width:  24,
  height: 24,
}