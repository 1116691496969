import { Document } from 'mobx-document'
import socket from 'socket.io-react'
import { Participant, Question } from '~/models'
import { participantsStore, qAndAsStore } from '~/stores'
import { QuestionMeta } from './types'

export default class QuestionDocument extends Document<Question, string, {}, QuestionMeta> {

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('q-and-as:questions:show', this.id)
    if (!response.ok) { return response }

    const pack  = response.body
    const qAndA = Question.deserialize(pack.data)

    if (pack.meta.participant != null) {
      const participant = Participant.deserialize(pack.meta.participant)
      participantsStore.participants.store(participant)
    }

    return {
      data: qAndA,
      meta: pack.meta,
    }
  }

  public async delete() {
    const response = await socket.send('q-and-as:questions:delete', this.id)
    if (response.ok) {
      qAndAsStore.questions.delete(this.id)
    }
    return response.ok
  }

  //------
  // Upvoting

  public async upvote(): Promise<boolean> {
    return await this.performOptimisticUpdate({
      prepare: question => {
        const copy = question.copy()
        copy.voteCount += 1
        copy.voted = true
        return copy
      },

      update: async () => {
        const response = await socket.send('q-and-as:questions:upvote', this.id)
        if (response.ok) {
          return {
            data: Question.deserialize(response.body.data),
            meta: response.body.meta,
          }
        } else {
          return {error: response.error}
        }
      },
    })
  }

  public async downvote() {
    return await this.performOptimisticUpdate({
      prepare: question => {
        const copy = question.copy()
        copy.voteCount = Math.max(0, copy.voteCount - 1)
        copy.voted = false
        return copy
      },

      update: async () => {
        const response = await socket.send('q-and-as:questions:downvote', this.id)
        if (response.ok) {
          return {
            data: Question.deserialize(response.body.data),
            meta: response.body.meta,
          }
        } else {
          return {error: response.error}
        }
      },
    })
  }

}