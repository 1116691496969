import { escapeRegExp } from 'lodash'
import { action, when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrCourse } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrCourseDocument from './BeWizrCourseDocument'

export default class BeWizrCoursesEndpoint extends Endpoint<BeWizrCourseDocument> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  @action
  public async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('course/')
    if (response.status !== 200) { return response }

    let data: BeWizrCourse[] = response.data.map(
      (raw: any) => BeWizrCourse.deserialize(raw),
    )

    if (params.query != null) {
      const regExp = new RegExp(escapeRegExp(params.query), 'gmi')
      data = data.filter(course => regExp.test(course.title))
    }

    const total  = data.length
    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    if (offset > 0) {
      data.splice(0, offset)
    }
    if (data.length > limit) {
      data.splice(limit)
    }
    const nextOffset = offset + limit > total ? null : offset + limit

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}