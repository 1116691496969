import React from 'react'
import { isPlainObject, mapValues, some } from 'lodash'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'

export interface Props<K extends string> {
  data:     Record<K, React.ReactNode | HoverShimNode>
  hover?:   boolean

  flex?:    VBoxProps['flex']
  children: (current: Record<K, React.ReactNode>) => React.ReactNode
}

export interface HoverShimNode {
  normal: React.ReactNode
  hover:  React.ReactNode
}

const HoverShim = memo('HoverShim', <K extends string>(props: Props<K>) => {

  const {data, flex, hover: props_hover, children} = props
  const needsHover = some(Object.values(data), isHoverShimNode)

  const [state_hover, startHover, stopHover] = useBoolean()
  const hover = props_hover ?? state_hover

  const deriveState = React.useCallback((hover: boolean) => {
    return mapValues(data, node => {
      if (isHoverShimNode(node)) {
        return node[hover ? 'hover' : 'normal']
      } else {
        return node
      }
    })
  }, [data])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!needsHover) {
      return (
        <>
          {children(data)}
        </>
      )
    } else {
      return renderHoverShim()
    }
  }

  function renderHoverShim() {
    return (
      <VBox flex={flex} classNames={[$.content, {hover}]} onMouseEnter={startHover} onMouseLeave={stopHover}>
        <VBox flex={flex} classNames={$.normal}>
          {children(deriveState(false))}
        </VBox>
        <VBox classNames={$.hover}>
          {children(deriveState(true))}
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default HoverShim

export function isHoverShimNode(arg: any): arg is HoverShimNode {
  if (!isPlainObject(arg)) { return false }
  return 'normal' in arg && 'hover' in arg
}

const useStyles = createUseStyles({
  content: {
    position: 'relative',
    overflow: 'hidden',
  },

  normal: {
    position: 'relative',

    willChange: ['transform', 'opacity'],
    transition: animation.transitions.short(['transform', 'opacity']),

    '$content.hover &': {
      transform: 'translateY(-20%)',
      opacity:   0,
    },
  },

  hover: {
    ...layout.overlay,

    willChange: ['transform', 'opacity'],
    transition: animation.transitions.short(['transform', 'opacity']),

    '$content:not(.hover) &': {
      transform: 'translateY(+20%)',
      opacity:   0,
    },
  },
})