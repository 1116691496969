import React from 'react'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, PushButton, SVG } from '~/ui/components'
import { height as pushButtonHeight, Props as PushButtonProps } from '~/ui/components/PushButton'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props extends PushButtonProps {
  active?: boolean
  icon?:   SVGName | null
  label:   string
}

const ActionStateLabel = memo('ActionStateLabel', (props: Props) => {

  const {icon, label, active = false, ...button} = props
  const {guide, colors} = useStyling()

  const background = guide.actionStateLabel.background({active})
  const labelColor = background.theme === 'dark' ? colors.fg.light.normal : colors.semantic.primary

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent branding={guide.actionStateLabel} height={minHeight} variant={{active}}>
        <HBox gap={layout.padding.s} classNames={$.actionStateLabel}>
          <HBox flex gap={layout.padding.inline.m}>
            {icon && (
              <SVG
                name={icon}
                size={layout.icon.s}
                color={colors.semantic.primary}
              />
            )}

            <Label flex small bold color={labelColor} truncate={false}>
              {label}
            </Label>
          </HBox>

          <PushButton small {...button}/>
        </HBox>
      </BrandedComponent>
    )
  }

  return render()

})

export default ActionStateLabel

export const minHeight = pushButtonHeight.small + layout.padding.inline.s * 2

const useStyles = createUseStyles({
  actionStateLabel: {
    minHeight: minHeight,

    ...layout.responsive(size => ({
      padding:      [layout.padding.inline.s, layout.padding.m[size]],
      paddingRight: layout.padding.inline.s,
    })),
  },
})