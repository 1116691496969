import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { QAndA } from '~/models'
import { qAndAsStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class QuestionFormModel implements FormModel {

  constructor(
    public readonly qAndA: QAndA,
  ) {
    makeObservable(this)
  }

  @observable
  public title: string = ''

  @observable
  public body: string = ''

  public async submit(): Promise<SubmitResult | undefined> {
    const data = {
      title: this.title.trim(),
      body:  cleanTextValue(this.body, true),
    }

    const document = qAndAsStore.qAndAs.document(this.qAndA.id)
    return await document.addQuestion(data)
  }

}