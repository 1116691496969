import React from 'react'
import { observer } from '~/ui/component'
import ChatContextProvider from './ChatContext'
import ChatView, { Props as ChatViewProps } from './ChatView'
import { ChatController } from './types'

export interface Props {
  controller:    ChatController
  header?:       boolean
  subheader?:    React.ReactNode
  transparent?:  boolean
  forceOpen?:    boolean
  previewing?:   boolean
  noChannel?:    ChatViewProps['noChannel']
  emptyChannel?: ChatViewProps['emptyChannel']
}

const Chat = observer('Chat', (props: Props) => {

  const {
    controller,
    header     = true,
    subheader  = null,
    previewing = false,
    noChannel,
    emptyChannel,
    forceOpen,
  } = props

  //------
  // Render

  function render() {
    if (controller == null) { return null }

    return (
      <ChatContextProvider chat={controller} channel={controller.currentChannelController}>
        <ChatView
          header={header}
          subheader={subheader}
          previewing={previewing}
          forceOpen={forceOpen}
          noChannel={noChannel}
          emptyChannel={emptyChannel}
        />
      </ChatContextProvider>
    )
  }

  return render()

})

export default Chat