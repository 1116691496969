import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { useTimer } from '~/lib/react-timer'
import { BeWizrCourse, RemoteImage } from '~/models'
import { bewizrStore } from '~/stores'
import { observer } from '~/ui/component'
import { Hero, Markdown, PushButton, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import BeWizrSection from '../BeWizrSection'
import BeWizrCourseItemList from './BeWizrCourseItemList'
import BeWizrCourseProgressPanel from './BeWizrCourseProgressPanel'

export interface Props {
  course: BeWizrCourse
}

const BeWizrCourseView = observer('BeWizrCourseView', (props: Props) => {

  const [t] = useTranslation('bewizr')

  const {course} = props

  const document = bewizrStore.courses.document(course.slug)
  const lastVisitedCourseItem = document.lastVisitedCourseItem

  const enrolled = document.enrolled
  const [enrolling, setEnrolling] = React.useState<boolean>(false)

  const timer = useTimer()

  const enroll = React.useCallback(async () => {
    if (enrolling) { return }

    setEnrolling(true)

    const result = await timer.await(document.enroll())
    if (result?.status === 200) {
      await document.fetch()
      Toast.show({
        type:   'success',
        title:  t('courses.enroll.notification.title', {course}),
        detail: t('courses.enroll.notification.detail', {course}),
        lifetime: 20_000,
      })
    }

    setEnrolling(false)
  }, [document, timer, enrolling, t, course])

  const expandedItemIndex = React.useMemo(() => {
    if (lastVisitedCourseItem == null || !enrolled) { return }
    return course.items?.findIndex(it => it === lastVisitedCourseItem || it.items?.includes(lastVisitedCourseItem))
  }, [course.items, lastVisitedCourseItem, enrolled])

  const description = React.useMemo(() => {
    if (course.descriptionHtml == null || course.descriptionHtml.length === 0) { return null }
    // A little bit ridiculous, but this way we can use the styling from the <Markdown/> component
    return NodeHtmlMarkdown.translate(course.descriptionHtml)
  }, [course.descriptionHtml])

  const image: RemoteImage | undefined = course.links.image == null ? undefined : {
    type: 'remote',
    url:   course.links?.image,
  }

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.BeWizrCourseView}>
        <Hero
          title={course.title}
          image={image}
          backgroundImage={image}
        >
          <VBox gap={layout.padding.l}>
            {renderDescription()}
            {renderEnroll()}
          </VBox>
        </Hero>
        <VBox align='center' padding={layout.padding.m}>
          {renderContent()}
        </VBox>
      </VBox>
    )
  }

  function renderDescription() {
    if (description == null) { return null }

    return (
      <Markdown>
        {description}
      </Markdown>
    )
  }

  function renderEnroll() {
    if (enrolled) { return null }

    return (
      <PushButton
        caption={t('courses.enroll.caption')}
        working={enrolling}
        onTap={enroll}
      />
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content} gap={layout.padding.xl} paddingVertical={layout.padding.l}>
        {enrolled && (
          <BeWizrSection title={t('courses.progress.overview.title')}>
            <BeWizrCourseProgressPanel
              slug={course.slug}
            />
          </BeWizrSection>
        )}
        {course.items != null && course.items.length > 0 && (
          <BeWizrSection title={t('courses.items.title')}>
            <BeWizrCourseItemList
              items={course.items}
              expandedItemIndex={expandedItemIndex}
              interactive={enrolled}
            />
          </BeWizrSection>
        )}
      </VBox>
    )
  }

  return render()

})

export default BeWizrCourseView

const useStyles = createUseStyles({
  BeWizrCourseView: {
  },

  content: {
    width:    layout.contentWidth,
    maxWidth: '100%',
  },
})