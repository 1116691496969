import type {BrandingGuideDelegate} from '../types'
import { isFunction } from 'lodash'
import { borderRadiusForShape, themeOptionsForBackground } from '../../util'
import { BackgroundSpec, BorderSpec, BrandedComponentSpec, ComponentShape } from '../types'

export default class ComponentBrandingBase<S extends AnyObject> implements BrandingGuideDelegate {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ComponentBrandingBase<S>,
    defaults?: Partial<S>
  )
  constructor(
    delegate: BrandingGuideDelegate,
    parent: ComponentBrandingBase<S> | null,
    defaults: S
  )
  constructor(
    public readonly delegate: BrandingGuideDelegate,
    public readonly parent: ComponentBrandingBase<S> | null,
    defaults: S,
  ) {
    this.defaults = {...defaults}
  }

  public get rootGuide() {
    return this.delegate.rootGuide
  }

  public readonly brandingContainer = true

  public copy(): ComponentBrandingBase<S> {
    const Branding = this.constructor as typeof ComponentBrandingBase
    return new Branding(this.delegate, this.parent, this.defaults)
  }

  public readonly defaults: S

  public deserializeFrom(spec: BrandedComponentSpec, variant: string | null) {
    if (variant == null) {
      Object.assign(this.defaults, spec)
    } else {
      this.variant(variant, spec as any)
    }
  }

  //------
  // Variants

  public get variantNames() {
    return Object.keys(this.variants)
  }

  private variants: Record<string, Partial<S>> = {}

  public default(styles?: Partial<S> | ((styles: Partial<S>) => any)) {
    return this.variant('default', styles)
  }

  public variant(name: string, styles?: Partial<S> | ((styles: Partial<S>) => any)) {
    const variant: Partial<S> = name === 'default'
      ? this.defaults
      : (this.variants[name] = this.variants[name] ?? {})

    if (isFunction(styles)) {
      styles(variant)
    } else {
      Object.assign(variant, styles)
    }
    return variant
  }

  //------
  // Resolution

  public background(flags: Record<string, boolean> = {}): BackgroundSpec {
    const background: BackgroundSpec | string = this.resolve('background' as keyof S, flags) as any

    if (typeof background !== 'string') {
      return background
    } else if (this.rootGuide.backgrounds[background] != null) {
      return this.rootGuide.backgrounds[background]
    } else {
      console.warn(`Named background "${background}" not found`)
      return this.rootGuide.backgrounds.normal
    }
  }

  public border(flags: Record<string, boolean> = {}) {
    const border: BorderSpec | string = this.resolve('border' as keyof S, flags) as any
    if (typeof border !== 'string') {
      return border
    } else if (this.rootGuide.borders[border] != null) {
      return this.rootGuide.borders[border]
    } else {
      console.warn(`Named border "${border}" not found`)
      return this.rootGuide.borders.none
    }
  }

  public themeProps(flags: Record<string, boolean> = {}) {
    const background = this.background(flags)
    return themeOptionsForBackground(this.rootGuide, background)
  }

  public borderRadius(height: number, flags: Record<string, boolean> = {}) {
    if (!('shape' in this.defaults) && (this.parent == null || !('shape' in this.parent.defaults))) {
      return 0
    }
    const shape: ComponentShape = this.resolve('shape' as keyof S, flags) as any
    return borderRadiusForShape(shape, height)
  }

  public resolve<K extends keyof S>(key: K, flags: Record<string, boolean> = {}): S[K] {
    const variantNames = Object.entries(flags).filter(entry => entry[1]).map(entry => entry[0])

    for (const name of variantNames) {
      const value = this.variants[name]?.[key]
      if (value !== undefined) { return value! }
    }

    if (key in this.defaults) {
      return this.defaults[key]!
    } else if (this.parent != null) {
      return this.parent.resolve(key, flags)
    } else {
      throw new Error(`Cannot resolve \`${key.toString()}\` for ${this.constructor.name}`)
    }
  }

}