import React from 'react'
import DraftJSBackend from './backend/DraftJSBackend'
import MarkdownBackend from './backend/MarkdownBackend'

export type RichTextFieldContext = MarkdownBackend | DraftJSBackend | null

export const RichTextFieldContext = React.createContext<RichTextFieldContext>(null)

export function useRichTextBackend() {
  return React.useContext(RichTextFieldContext)
}