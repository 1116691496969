import React from 'react'

export interface DrawerContext {
  open:   () => any
  close:  () => any
  toggle: () => any
  isOpen: boolean
}

const DrawerContext = React.createContext<DrawerContext>({
  open:   () => void 0,
  close:  () => void 0,
  toggle: () => void 0,
  isOpen: false,
})

export default DrawerContext

export function useDrawer() {
  return React.useContext(DrawerContext)
}