import React from 'react'
import { memo } from '~/ui/component'
import { ClearButton, ClearButtonProps, PopupMenu, PopupMenuProps } from '~/ui/components'

export interface Props<T> extends Omit<PopupMenuProps<T>, 'children'> {
  small?:  boolean
  button?: ClearButtonProps
}

const _KebabMenu = <T extends any = any>(props: Props<T>) => {

  return (
    <PopupMenu {...props}>
      {open => (
        <ClearButton
          icon='kebab'
          small={props.small}
          onTap={open}
          padding='both'
          {...props.button}
        />
      )}
    </PopupMenu>
  )
}

const KebabMenu = memo('KebabMenu', _KebabMenu) as typeof _KebabMenu
export default KebabMenu