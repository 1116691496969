import React from 'react'
import OnDemandService from '../OnDemandService'

export function useOnDemandService<S extends OnDemandService>(service: S): S
export function useOnDemandService(service: null): null
export function useOnDemandService<S extends OnDemandService>(service: S | null): S | null
export function useOnDemandService(service: OnDemandService | null) {

  React.useEffect(() => {
    if (service == null) { return }

    service.start()
    return () => { service.stop() }
  }, [service])

  return service

}