import { isFunction } from 'lodash'
import Logger from 'logger'
import { action } from 'mobx'
import resizeImage, { ResizeSpec } from 'resize-image'
import { humanFileSize, patternToRegExp } from 'ytil'
import gcAPI from '~/apis/gc'
import { Media } from '~/models'
import { register } from './support'

const logger = new Logger('MediaStore')

export class MediaStore {

  @action
  public async storeMedia(name: string, blob: Blob, options: StoreMediaOptions = {}): Promise<MediaUploadResult | null> {
    const {
      resize: resizeOpt = defaultResize,
      convert,
      ...rest
    } = options

    const resize    = isFunction(resizeOpt) ? resizeOpt(blob.type) : resizeOpt
    const converted = convert == null ? blob : await this.convertImage(blob, convert)
    const resized   = resize === false ? converted : await resizeImage(converted, resize)

    if (blob.type !== converted.type) {
      logger.info(`Image converted from ${blob.type} to ${converted.type}.`)
    }
    if (converted.size !== resized.size) {
      logger.info(`Image resized from ${humanFileSize(converted.size)} to ${humanFileSize(resized.size)}.`)
    }

    const response = await gcAPI().post('/web/media', resized, {
      headers: {
        'Content-Type': resized.type,
        'X-Form-Data':  btoa(JSON.stringify({name, ...rest})),
      },
    })

    if (response.status !== 200) {
      const reason = reasonForXHRStatus(response.status)
      return reason == null ? {status: 'error'} : {status: 'invalid', reason}
    }

    const {data} = response.data
    const media  = Media.deserialize(data)
    return {status: 'ok', media}
  }

  private async convertImage(blob: Blob, convert: ConvertMedia) {
    const toType = resolveConvert(convert, blob.type)
    if (toType == null) { return blob }
    if (toType === blob.type) { return blob }

    const url = URL.createObjectURL(blob)

    try {
      return await new Promise<Blob>((resolve, reject) => {
        const canvas  = document.createElement('canvas')
        const context = canvas.getContext("2d")
        if (context == null) {
          reject("unable to obtain 2D context")
          return
        }

        const image = new Image()
        image.onload = async () => {
          canvas.width  = image.width
          canvas.height = image.height
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
          canvas.toBlob(imageOrNull => {
            if (imageOrNull == null) {
              reject("Unable to generate image")
            } else {
              resolve(imageOrNull)
            }
          }, toType)
        }
        image.onerror = error => {
          reject(error)
        }
        image.src = url
      })
    } catch (error) {
      throw new Error("Cannot convert image: " + error)
    } finally {
      URL.revokeObjectURL(url)
    }
  }

}

function resolveConvert(convert: ConvertMedia, type: string) {
  if (isFunction(convert)) {
    return convert(type)
  } else {
    return Object.entries(convert).find(entry => patternToRegExp(entry[0], {wildcards: true}).test(type))?.[1] ?? null
  }
}

export interface StoreMediaOptions {
  prefix?:  string
  resize?:  ResizeSpec | false | ((type: string) => ResizeSpec | false)
  convert?: ConvertMedia
}

export type ConvertMedia = Record<string, string> | ((from: string) => string | undefined)

const defaultResize = (type: string): ResizeSpec | false => {
  if (type === 'image/gif') {
    return false
  } else {
    return {fileSize: 768 * 1024}
  }
}

function reasonForXHRStatus(status: number) {
  switch (status) {
    case 413: return 'too_large'
    default:  return null
  }
}

export type MediaUploadResult =
  | {status: 'ok', media: Media}
  | {status: 'invalid', reason: string}
  | {status: 'canceled'}
  | {status: 'error'}

export default register(new MediaStore())