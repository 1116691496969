export { default as Socket } from './Socket'
export { default as ScopedSocket } from './ScopedSocket'
export { default as SocketOperation } from './SocketOperation'
export { default as SocketError } from './SocketError'
export { default as OnDemandService } from './OnDemandService'
export { default } from './defaultSocket'

export * from './hooks'
export * from './types'

export { configure } from './config'