import { ParserRule, ReactOutputRule } from 'simple-markdown'
import { merge } from 'lodash'

export interface Config {
  extraRules: Record<string, Partial<ParserRule & ReactOutputRule & {[key: string]: any}>>
}

const config: Config = {
  extraRules: {},
}

export default config

export function configure(cfg: Partial<Config>) {
  merge(config, cfg)
}