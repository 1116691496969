import React from 'react'
import { useSize } from 'react-measure'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout, PaletteKey, useStyling } from '~/ui/styling'

export interface Props<T> {
  data:          T[]
  children:      React.ReactElement<SeriesProps<T>> | React.ReactElement<SeriesProps<T>>[]

  axisLabelForPoint: (point: T) => string

  decorateLabel?: (point: T, label: React.ReactNode, index: number) => React.ReactNode
  palette?:       PaletteKey

  classNames?:    React.ClassNamesProp
}

export interface SeriesProps<T> {
  name:     string
  caption?: string | null
  getValue: (point: T) => number
}

const _BarChart = <T extends {}>(props: Props<T>) => {
  const {data, children, axisLabelForPoint, palette = 'default', classNames} = props

  const series = React.useMemo(() => {
    return React.Children.toArray(children) as React.ReactElement<SeriesProps<T>>[]
  }, [children])

  const maxValue = React.useMemo(() => {
    let maxValue: number = 0
    for (const point of data) {
      for (const serie of series) {
        const value = serie.props.getValue(point)
        if (value > maxValue) { maxValue = value }
      }
    }

    return maxValue
  }, [data, series])

  const firstLabelRef = React.useRef<HTMLDivElement>(null)
  const [labelsWidth, setLabelsWidth] = React.useState<number | null>(null)

  const {colors} = useStyling();

  useSize(firstLabelRef, React.useCallback(size => {
    setLabelsWidth(size.width)
  }, []))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.barChart, classNames]}>
        {renderItems()}
        {renderAxis()}
      </VBox>
    )
  }

  function renderItems() {
    return (
      <VBox gap={layout.padding.s}>
        {data.map(renderPoint)}
      </VBox>
    )
  }

  function renderPoint(point: T, index: number) {
    const label = axisLabelForPoint(point)

    return (
      <HBox key={label}>
        {renderLabel(point, index)}
        {renderBars(point)}
      </HBox>
    )
  }

  function renderLabel(point: T, index: number) {
    let label: React.ReactNode = (
      <Label bold small truncate>
        {axisLabelForPoint(point)}
      </Label>
    )

    if (props.decorateLabel) {
      label = props.decorateLabel(point, label, index)
    }

    return (
      <VBox flex={4} classNames={$.labelContainer} ref={index === 0 ? firstLabelRef : undefined}>
        {label}
      </VBox>
    )
  }

  function renderBars(point: T) {
    return (
      <VBox flex={6} gap={layout.padding.inline.xs}>
        {series.map(series => (
          <React.Fragment key={series.props.name}>
            {renderBar(point, series.props)}
          </React.Fragment>
        ))}
      </VBox>
    )
  }

  function renderBar(point: T, serie: SeriesProps<T>) {
    const seriesIndex = series.findIndex(s => s.props.name === serie.name)
    const value       = serie.getValue(point)
    const color       = colors.palette(palette, seriesIndex, series.length)

    const width = value === 0 ? 2 : (value / maxValue * 100) + '%'
    const style = {
      backgroundColor: color.string(),
      width,
    }

    return (
      <HBox gap={layout.padding.inline.m}>
        <HBox flex>
          <div classNames={$.bar} style={style}/>
        </HBox>
        {renderValue(point, serie)}
      </HBox>
    )
  }

  function renderValue(point: T, series: SeriesProps<T>) {
    const value = series.getValue(point)

    return (
      <Label bold small align='right'>
        {value.toString()}
      </Label>
    )
  }

  function renderAxis() {
    if (labelsWidth == null) { return null }

    return (
      <div
        classNames={$.axis}
        style={{left: labelsWidth + layout.padding.inline.s}}
      />
    )
  }

  return render()
}

function Series<T>(props: SeriesProps<T>) {
  return null
}

const BarChart = memo('BarChart', _BarChart) as typeof _BarChart
Object.assign(BarChart, {Series})

export default BarChart as typeof BarChart & {Series: typeof Series}

export const barHeight = 16
export const barRadius = layout.radius.s

const useStyles = createUseStyles(theme => ({
  barChart: {
    position: 'relative',
  },

  labelContainer: {
    marginRight: layout.padding.inline.s + 2,
  },

  axis: {
    position: 'absolute',
    top:      0,
    bottom:   0,
    width:    1,

    backgroundColor: theme.fg.dimmer,
    marginRight:     1,
  },

  bar: {
    height:                  barHeight,
    borderTopRightRadius:    barRadius,
    borderBottomRightRadius: barRadius,
  },

  value: {
    marginLeft: layout.padding.s,
  },
}))