export type Serialized = any

export interface Extra {
  [key: string]: any
}

export default class SocketError extends Error {

  constructor(
    public status: number = 500,
    message: string,
    extra: Extra = {},
  ) {
    super(message)

    this.status = status
    this.extra = extra
    this.stack = (extra.stack ?? '') + `\n\n======= ↑SERVER↑ =======\n\n` + this.stack ?? ''
  }

  public extra: Extra

  public static deserialize(data: Serialized) {
    if (typeof data === 'string') {
      data = {status: null, message: data}
    }

    const {status, message, ...extra} = data
    const certainStatus  = typeof status === 'number' ? status : 500
    const certainMessage = typeof message === 'string' ? message : "Unknown error"

    return new SocketError(certainStatus, certainMessage, extra)
  }

}