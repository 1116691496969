import { some } from 'lodash'
import { DateTime } from 'luxon'
import { action, makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import socket from 'socket.io-react'
import { Notification } from '~/models'
import { NotificationDocument, NotificationsEndpoint } from './notifications'
import projectStore from './projectStore'
import { register } from './support'

export class NotificationsStore {

  constructor() {
    makeAutoObservable(this)
  }

  private readonly notifications = new Database<NotificationDocument>({
    getDocument:   item => new NotificationDocument(item.id),
    emptyDocument: id => new NotificationDocument(id),
    getID:         doc => doc.id,
  })

  public readonly myNotifications = new NotificationsEndpoint(
    this.notifications,
    {},
  )

  public get defaultTitle() {
    return projectStore.project?.name ?? 'GroundControl'
  }

  public get unseenNotificationCount() {
    return this.notifications.all().reduce(
      (total, notification) => total + (notification.seen ? 0 : 1),
      0,
    )
  }

  //------
  // Lifecycle

  public init() {
    socket.addEventListener('notification', this.handleNotification)
    this.myNotifications.fetch()
  }

  public deinit() {
    socket.removeEventListener('notification', this.handleNotification)
  }

  //------
  // Notifications

  private listeners = new Set<NotificationListener>()

  public addListener(listener: NotificationListener) {
    this.listeners.add(listener)
    return () => this.listeners.delete(listener)
  }

  private handleNotification = (raw: AnyObject) => {
    const notification = Notification.deserialize(raw)
    this.myNotifications.insert(notification, 0)
    for (const listener of this.listeners) {
      listener(notification)
    }
  }

  //------
  // Seen

  public get hasUnseenNotifications() {
    return some(this.myNotifications.data, it => !it.seen)
  }

  public async markAsSeen(id: string) {
    const revert = this.optimisticallyAsSeen(id)

    const response = await socket.send('notifications:mark_as_seen', id)
    if (!response.ok) {
      revert()
    }
    return response.ok
  }

  public async markAllAsSeen() {
    const revert = this.optimisticallyAsSeen()

    const response = await socket.send('notifications:mark_as_seen')
    if (!response.ok) {
      revert()
    }
    return response.ok
  }

  private optimisticallyAsSeen(id?: string) {
    const originals: Notification[] = []
    const seenAt = DateTime.now()

    for (const notification of this.myNotifications.data) {
      if (id != null && notification.id !== id) { continue }
      if (notification.seen) { continue }

      originals.push(notification)
      this.notifications.store(Notification.deserialize({
        ...notification.raw,
        seenAt,
      }))
    }

    return action(() => {
      for (const original of originals) {
        this.notifications.store(original)
      }
    })
  }

}

export interface NotificationAvatar {
  firstName: string
  lastName:  string | null
  photoURL:  string | null
}

export type NotificationListener = (notification: Notification) => any

const notificationsStore = new NotificationsStore()
register(notificationsStore)

export default notificationsStore