import React from 'react'
import { ContentBlock, ContentState } from 'draft-js'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  block:        ContentBlock
  contentState: ContentState
}

const ImageBlock = memo('ImageBlock', (props: Props) => {

  const {block, contentState} = props

  const key    = block.getEntityAt(0)
  const entity = key == null ? null : contentState.getEntity(key)
  const type   = entity?.getType()
  const data   = entity?.getData()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (type !== 'IMAGE') { return null }

    const source = data.src as string | null
    const alt    = data.alt as string | null
    const title  = data.title as string | null
    const style  = (data.style ?? {}) as Record<string, any>
    if (source == null) { return null }

    const classNames = [
      $.imageBlock,
      {fullWidth: style.fullWidth},
    ]

    return (
      <figure classNames={classNames} title={title ?? undefined}>
        <ImageView
          source={source}
          alt={alt ?? source}
        />
      </figure>
    )
  }

  return render()

})

export default ImageBlock

const useStyles = createUseStyles({
  imageBlock: {
    '&.fullWidth': {
      margin: [0, -layout.padding.inline.l],
    },
  },
})