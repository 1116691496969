import { AxiosResponse } from 'axios'
import I18next from 'i18next'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Fetch } from 'mobx-document'
import gcAPI from '~/apis/gc'
import config from '~/config'
import { ClientApp } from '~/models'
import { SubmitResult } from '~/ui/form'
import { BrandingGuide, BrandingManifest } from '~/ui/styling'
import { Translations } from '../../models/Translations'

import type {
  RegistrationWidgetModel,
  RegistrationWidgetModelStatus,
  ProjectInfo,
  GroupInfo,
  RegistrationData,
  RegistrationTranslationKey,
  PreflightResponse,
  PreflightErrorReason,
} from '~/ui/app/registration/widget/types'
export default class RegistrationManager implements RegistrationWidgetModel {

  constructor(
    public readonly token: string | null,
    public readonly origin: string | null,
  ) {
    makeObservable(this)
  }

  private code: string | null = null

  @observable
  public status: RegistrationWidgetModelStatus = 'preflight'

  @observable
  public errorReason: PreflightErrorReason = 'other'

  @observable
  public brandingManifest: BrandingManifest | null = null

  @observable
  public requireCode: boolean = false

  @computed
  public get brandingGuide() {
    return new BrandingGuide(guide => {
      if (this.brandingManifest != null) {
        guide.deserializeFrom(this.brandingManifest)
      }
    })
  }

  @observable
  public _availableProjects: ProjectInfo[] = []

  @observable
  public _availableGroups: GroupInfo[] = []

  public availableProjects(): Fetch<ProjectInfo[]> {
    return {
      status: 'done',
      data: this._availableProjects,
    }
  }

  public availableGroups(projectID: string): Fetch<GroupInfo[]> {
    return {
      status: 'done',
      data:   this._availableGroups.filter(it => it.projectID === projectID),
    }
  }

  //------
  // Initialization

  private preflightPromise: Promise<PreflightResponse> | null = null

  @action
  public async preflight(code: string | null = null) {
    this.code = code

    // If we have no token, we can't do the preflight until a code is given. Go to the code
    // step immediately.
    if (this.token == null && this.code == null) {
      this.brandingManifest = await this.resolveBrandingByHostname()
      this.status = 'code'
      return
    }

    this.status = 'preflight'

    return this.preflightPromise ??= action(async () => {
      const params   = this.buildParams()
      const response = await gcAPI().get('/registration/preflight', {params})
      this.preflightPromise = null

      return this.processPreflightResponse(response)
    })()
  }

  @action
  private processPreflightResponse(response: AxiosResponse<PreflightData>): PreflightResponse {
    if (response.status === 200) {
      const data = response.data as PreflightSuccess
      this.requireCode        = data.requireCode
      this.status             = (data.requireCode && this.code == null) ? 'code' : 'register'
      this.errorReason        = 'other'
      this.brandingManifest   = data.branding
      this._availableProjects = data.availableProjects.map(deserializeProjectInfo)
      this._availableGroups   = data.availableGroups.map(deserializeGroupInfo)

      return 'ok'
    } else {
      const data = response.data as RegistrationError<PreflightErrorReason>

      this.status             = data.error.reason.includes('code') ? 'code' : 'error'
      this.errorReason        = data?.error?.reason ?? 'other'
      this._availableProjects = []
      this._availableGroups   = []
      this.brandingManifest   = null
      return this.errorReason
    }
  }

  //------
  // Registration

  @action
  public async register(data: RegistrationData): Promise<SubmitResult | undefined> {
    if (this.status !== 'register') { return }

    const params = this.buildParams()
    const {status, data: {error}} = await gcAPI().post('/registration/register', data, {params})
    return runInAction(() => {
      if (status === 422) {
        return {
          status: 'invalid',
          errors: error?.errors.map(validationErrorToFormError),
        }
      } else if (status !== 200) {
        this.status = error?.reason ?? 'other'
        return {
          status: 'error',
          error:  new Error(`HTTP Error: ${status}`),
        }
      } else {
        this.status = 'registered'
        return {status: 'ok'}
      }
    })
  }

  private buildParams() {
    const params = new URLSearchParams()
    if (this.token != null) {
      params.set('token', this.token)
    }
    if (this.code != null) {
      params.set('code', this.code)
    }
    if (this.origin != null) {
      params.set('origin', this.origin)
    }

    return params
  }

  //------
  // Images & translations

  public image(name: string, variant: string | null = null) {
    return this.brandingGuide?.image(name, variant) ?? null
  }

  public translate(key: RegistrationTranslationKey, language: string = I18next.language) {
    if (this.brandingGuide != null) {
      const translation = this.brandingGuide.text(`registration.${key}`, language)
      if (translation != null) { return translation }
    }

    return I18next.t(`registration:${key}`, {lng: language})
  }

  //------
  // Branding

  private async resolveBrandingByHostname(): Promise<BrandingManifest | null> {
    if (config.domain == null) { return null }

    const params = {domain: config.domain}
    const response = await gcAPI().get('client-app', {params})
    if (response.status !== 200) { return null }

    const app = ClientApp.deserialize(response.data.clientApp)
    return app.branding
  }

}

export function validationErrorToFormError(error: any) {
  return {
    field:   error.path ?? null,
    message: error.message,
  }
}

type PreflightData = PreflightSuccess | RegistrationError<PreflightErrorReason>

interface PreflightSuccess {
  requireCode:       boolean
  branding:          BrandingManifest | null
  availableProjects: ProjectInfo[]
  availableGroups:   GroupInfo[]
  notices:           Translations<RegistrationTranslationKey>
}

interface RegistrationError<R> {
  error: {
    reason: R
  }
}

function deserializeProjectInfo(raw: any): ProjectInfo {
  return {
    id:   raw.id,
    name: raw.name,
  }
}

function deserializeGroupInfo(raw: any): GroupInfo {
  return {
    id:        raw.id,
    projectID: raw.projectID,
    name:      raw.name,
  }
}