import React from 'react'
import { MessageListItem } from '~/models'
import { LinkTappable } from '~/ui/app/navigation'
import { memo } from '~/ui/component'
import { Center, Notice, TextBlock } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props {
  message:   MessageListItem & {type: 'notice'}
}

const NoticeBubble = memo('NoticeBubble', (props: Props) => {

  const {message} = props
  const {guide} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Center>
        {message.link == null ? (
          renderNotice()
        ) : (
          <LinkTappable link={message.link} noFeedback>
            {renderNotice()}
          </LinkTappable>
        )}
      </Center>
    )
  }

  function renderNotice() {
    return (
      <Notice classNames={$.notice} branding={guide.chat.noticeBubble}>
        <TextBlock small bold markup align='center'>
          {message.text}
        </TextBlock>
      </Notice>
    )
  }

  return render()

})

export default NoticeBubble

const useStyles = createUseStyles({
  notice: {
    padding: [layout.padding.inline.s, layout.padding.inline.xl],
    minWidth: '30%',
  },
})