import { Database } from 'mobx-document'
import { ScopedSocket } from 'socket.io-react'
import { EndpointOptions } from '~/lib/mobx-document'
import PostDocument from './PostDocument'
import PostsEndpoint, { PostsMeta, PostsParams } from './PostsEndpoint'

export default class CommentsEndpoint extends PostsEndpoint {

  constructor(
    socket: ScopedSocket,
    database: Database<PostDocument>,
    private readonly postID: string,
    feedIDs?: string[],
    options: EndpointOptions<PostDocument, PostsMeta> = {},
  ) {
    super(socket, database, feedIDs, options)
  }

  protected async performFetch(params: PostsParams) {
    const response = await this.socket.fetch('comments:fetch', this.postID, params)
    if (response.ok) {
      return this.enrichPosts(response.body.data, response.body.meta)
    } else {
      return response
    }
  }

}