import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import BeWizrTile from '../BeWizrTile'

export interface Props {
  title:       string
  slug:        string
  startDate:   DateTime | null
  endDate:     DateTime | null
  image:       string | null
  horizontal?: boolean
  locked?:     boolean
}

const BeWizrMeetingTile = memo('BeWizrMeetingTile', (props: Props) => {

  const {title, slug, startDate, image, horizontal, locked} = props

  const [t, i18next] = useTranslation('bewizr')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={title}
        icon='calendar'
        href={`/bewizr-events/-/${slug}`}
        image={image}
        subtitle={startDate?.toFormat('d-M-yyyy', {locale: i18next.language})}
        buttonCaption={t('meetings.view')}
        horizontal={horizontal}
        locked={locked}
      />
    )
  }

  return render()

})

export default BeWizrMeetingTile