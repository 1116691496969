import { VBoxProps } from '~/ui/components'
import { TextAlign } from './Label'

export function textAlignForAlign(align: VBoxProps['align']): TextAlign {
  switch (align) {
    case 'left':    return 'left'
    case 'center':  return 'center'
    case 'right':   return 'right'
    case 'stretch': return 'justify'
    default:        return 'left'
  }
}