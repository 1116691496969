import { initReactI18next } from 'react-i18next'
import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import config from '~/config'
import * as locales from '~/locales'

I18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng:   config.i18n.defaultLanguage,
    resources:     locales,
    returnObjects: true,
    interpolation: {escapeValue: false},

    detection: {
      order: ['navigator'],
      convertDetectedLanguage: (language: string) => language.replace(/-.*/, ''),
    },
  })