import React from 'react'
import { Message } from '~/models'
import { useClock } from '~/socket/useClock'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import ChatMessageFeedbackCountdown from './ChatMessageFeedbackCountdown'
import renderFeedbackBubble from './feedback/index'

export interface Props {
  message:  Message
  onChange: () => any
}

const ChatMessageFeedback = memo('ChatMessageFeedback', (props: Props) => {

  const {message, onChange} = props

  const {currentTime, tick} = useClock({interval: 'once'})

  const showFeedback  = currentTime == null ? false : message.showFeedbackAt(currentTime)
  const showCountdown = currentTime == null ? false : showFeedback && !message.feedbackExpiredAt(currentTime)

  const prevShowFeedback  = usePrevious(showFeedback)
  const prevShowCountdown = usePrevious(showCountdown)

  React.useEffect(() => {
    const feedbackChanged  = prevShowFeedback != null && prevShowFeedback !== showFeedback
    const countdownChanged = prevShowCountdown != null && prevShowCountdown !== showCountdown

    if (feedbackChanged || countdownChanged) {
      onChange()
    }
  }, [onChange, prevShowCountdown, prevShowFeedback, showCountdown, showFeedback])

  //------
  // Rendering

  function render() {
    if (message.feedback == null) { return null }
    if (!showFeedback) { return null }

    const bubble = renderFeedbackBubble(message.id, message.feedback)
    if (bubble == null) { return null }

    return (
      <VBox align='right' gap={layout.padding.s}>
        {bubble}
        {showCountdown && (
          <ChatMessageFeedbackCountdown
            message={message}
            requestRemove={tick}
          />
        )}
      </VBox>
    )
  }

  return render()

})

export default ChatMessageFeedback