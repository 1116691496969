import { BorderSpec, BrandedComponentSpec, BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class CheckBoxBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: CheckBoxBranding | null,
    defaults: BrandedComponentSpec,
  ) {
    super(delegate,parent, defaults)

    this.default({
      border: BorderSpec.solid(delegate.rootGuide.colors.fg.dimmer, 1),
    })
    this.variant('checked', {
      background: 'solid',
      border:     'none',
    })
  }

}