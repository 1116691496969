import React from 'react'
import { Binding } from 'react-hotkeys'
import ModalPortal, { manager, ModalPortalProps } from 'react-modal-portal'
import I18next from 'i18next'
import { isFunction } from 'lodash'
import { memo } from '~/ui/component'
import {
  ClearButton,
  HBox,
  HBoxProps,
  Label,
  Scroller,
  Spinner,
  SVG,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { animation, colors, createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { flexStyle } from './layout/styles'

export interface Props extends ModalPortalProps {
  open:          boolean
  requestClose?: () => any

  formProps?: React.HTMLAttributes<HTMLFormElement>
  formRef?:   React.Ref<HTMLFormElement>

  width?:     number | 'max'
  height?:    number | 'max'

  contentPadding?: boolean | number | null

  header?:      React.ReactNode
  icon?:        SVGName
  title?:       React.ReactNode
  headerRight?: React.ReactNode | WellKnownHeaderRights | ((renderCloseButton: (caption?: string) => React.ReactNode) => React.ReactNode)
  showSpinner?: boolean

  footer?:      React.ReactNode
  children?:    React.ReactNode

  drawerWidth?:    number
  drawerExpanded?: boolean
  drawerSide?:     'left' | 'right'
  drawerProps?:    VBoxProps
  renderDrawer?:   () => React.ReactNode

  semi?:       boolean
  classNames?: React.ClassNamesProp
}

export type WellKnownHeaderRights = '$close' | '$save'

const ModalDialog = memo('ModalDialog', (props: Props) => {

  const {
    classNames,
    children,
    header,
    icon,
    title,
    headerRight,
    showSpinner,
    footer,
    width,
    height,
    formProps,
    formRef,
    semi = true,
    contentPadding,
    closeOnEscape = true,
    drawerWidth,
    drawerSide = 'right',
    drawerExpanded = false,
    drawerProps = {},
    renderDrawer,
    ...rest
  } = props

  const flex     = modalDialogFlex(height)
  const bodyFlex = modalDialogBodyFlex(height)

  //------
  // Spinner

  const [spinnerVisibleState, setSpinnerVisibleState] = React.useState<boolean>(false)
  const spinnerVisible = showSpinner || spinnerVisibleState

  const context = React.useMemo((): ModalDialogContext => ({
    showSpinner: (show: boolean = true) => setSpinnerVisibleState(show),
    hideSpinner: () => setSpinnerVisibleState(false),
  }), [])

  const open = props.open
  React.useEffect(() => {
    if (open) {
      return Binding.addLayer()
    }
  }, [open])

  //------
  // Rendering

  const $ = useStyles(drawerWidth)

  function render() {
    return (
      <ModalPortal
        classNames={[$.portal, {center: width !== 'max'}]}
        shimClassNames={$.shim}
        zIndex={layout.z.modal}
        closeOnEscape={closeOnEscape}
        {...rest}

        onWillOpen={onWillOpen}
        transitionName={$.transition}
        transitionDuration={animation.durations.medium}
      >
        <ModalDialogContext.Provider value={context}>
          {renderDialogContainer()}
        </ModalDialogContext.Provider>
      </ModalPortal>
    )
  }

  function renderDialogContainer() {
    const withDrawer = renderDrawer != null && drawerWidth != null && drawerExpanded
      ? `withDrawer-${drawerSide}`
      : null

    return (
      <VBox classNames={[$.dialogContainer, withDrawer]} flex={flex}>
        {renderAuxiliary()}
        {renderFormOrDialog()}
      </VBox>
    )
  }

  function renderAuxiliary() {
    if (renderDrawer == null) { return null }
    if (drawerWidth == null) { return null }

    const expanded   = drawerExpanded
    const side       = drawerSide
    const withHeader = header != null || title != null
    const {classNames, ...rest} = drawerProps

    return (
      <VBox classNames={[$.drawer, side, {withHeader, expanded}, classNames]} {...rest}>
        {renderDrawer()}
      </VBox>
    )
  }

  function renderFormOrDialog() {
    if (formProps != null) {
      return (
        <form {...formProps} classNames={[$.form, $.dialog, {semi}, classNames]} style={{...formProps.style, ...flexStyle(flex)}} ref={formRef}>
          {renderDialog(null)}
        </form>
      )
    } else {
      return renderDialog([$.dialog, classNames])
    }
  }

  function renderDialog(classNames: React.ClassNamesProp) {
    return (
      <ThemeProvider light>
        <VBox flex={flex} classNames={classNames} style={{width, height}}>
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderHeader() {
    if (header == null && title == null) { return null }

    return (
      <Header
        icon={icon}
        caption={title}
        right={headerRight}
        children={header}
        requestClose={props.requestClose}
        spinnerVisible={spinnerVisible}
      />
    )
  }

  function renderBody() {
    const padding =
      contentPadding === true ? layout.padding.m :
      contentPadding == null || contentPadding === false ? undefined :
      contentPadding

    return (
      <Scroller flex={bodyFlex} contentClassNames={$.content}>
        <VBox flex={bodyFlex} padding={padding}>
          {children}
        </VBox>
      </Scroller>
    )
  }

  function renderFooter() {
    if (footer == null) { return null }

    return (
      <Footer>
        {footer}
      </Footer>
    )
  }

  const onWillOpen = React.useCallback(() => {
    manager.closeAll('popup')
    props.onWillOpen?.()
  }, [props])

  return render()

})

export interface HeaderProps extends HBoxProps {
  caption: React.ReactNode | undefined
  icon:    SVGName | undefined
  right:   React.ReactNode

  requestClose?:  () => any
  spinnerVisible: boolean
}

export const Header = memo('ModalDialog.Header', (props: HeaderProps) => {

  const {
    classNames,
    justify = 'space-between',
    caption,
    icon,
    spinnerVisible,
    right,
    children,
    requestClose,
    ...rest
  } = props

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider contrast='secondary'>
        <HBox classNames={[$.header, classNames]} justify={justify} {...rest}>
          {renderContent()}
          {children}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderContent() {
    if (caption == null) { return null }

    return (
      <>
        <HBox flex gap={layout.padding.inline.m}>
          {icon && (
            <SVG
              name={icon}
              size={layout.icon.m}
            />
          )}
          <VBox flex>
            {isReactText(caption) ? (
              <Label h2>
                {caption}
              </Label>
            ) : caption}
          </VBox>
        </HBox>
        <HBox gap={layout.padding.inline.m}>
          {spinnerVisible && <Spinner size={12}/>}
          {renderHeaderRight()}
        </HBox>
      </>
    )
  }

  function renderHeaderRight() {
    if (right === '$close') {
      return renderCloseButton(I18next.t('buttons:close'))
    } else if (right === '$save') {
      return renderCloseButton(I18next.t('buttons:save'))
    } else if (isFunction(right)) {
      return right(renderCloseButton)
    } else {
      return right
    }
  }

  function renderCloseButton(caption?: string) {
    return (
      <ClearButton
        icon='cross'
        caption={caption ?? I18next.t('buttons:close')}
        onTap={requestClose}
        padding='horizontal'
      />
    )
  }

  return render()

})

export type FooterProps = HBoxProps

export const Footer = memo('ModalDialog.Footer', (props: FooterProps) => {
  const {classNames, justify = 'right', ...rest} = props
  const $ = useStyles()

  return (
    <HBox classNames={[$.footer, classNames]} justify={justify} {...rest}>
      {props.children}
    </HBox>
  )
})

Object.assign(ModalDialog, {
  Header,
  Footer,
})

export default ModalDialog as typeof ModalDialog & {
  Header: typeof Header
  Footer: typeof Footer
}

export interface ModalDialogContext {
  showSpinner(show?: boolean): void
  hideSpinner(): void
}

const ModalDialogContext = React.createContext<ModalDialogContext>({
  showSpinner: () => void 0,
  hideSpinner: () => void 0,
})

export function useModalDialog() {
  return React.useContext(ModalDialogContext)
}

export function modalDialogFlex(height: number | 'max' | undefined): VBoxProps['flex'] | undefined {
  if (height === 'max') {
    return true
  } else if (height != null) {
    return undefined
  } else {
    return 'shrink'
  }
}

export function modalDialogBodyFlex(height: number | 'max' | undefined): VBoxProps['flex'] {
  if (height === 'max') {
    return true
  } else if (height != null) {
    return 'both'
  } else {
    return 'shrink'
  }
}

export const headerHeight = layout.barHeight.m

const useStyles = createUseStyles(theme => ({
  portal: {
    ...layout.responsiveProp({
      padding: layout.padding.xl,
    }),

    ...layout.flex.column,
    justifyContent: 'center',
    '&.center': {
      alignItems: 'center',
    },
  },

  shim: {
    background: colors.shim.light,
  },

  dialogContainer: (auxWidth: number) => ({
    maxWidth: '100%',
    position: 'relative',

    willChange: 'transform',
    transition: animation.transitions.medium('transform'),
    '&.withDrawer-left': {
      transform: `translateX(${(auxWidth - layout.radius.l) / 2}px)`,
    },
    '&.withDrawer-right': {
      transform: `translateX(${(-auxWidth - layout.radius.l) / 2}px)`,
    },
  }),

  form: {
    '& > *': {
      maxWidth: '100%',
    },
  },

  dialog: {
    ...layout.flex.column,
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',

    '&.semi': {
      background: theme.colors.bg.light.semi,
    },
    '&:not(.semi)': {
      background: theme.colors.bg.light.normal,
    },
    borderRadius:    layout.radius.l,
    boxShadow:       shadows.depth(5),
  },

  drawer: (width: number) => ({
    position:     'absolute',
    top:          layout.radius.l,
    bottom:       layout.radius.l,

    maxWidth:     '100%',
    width:        width + layout.radius.l,

    '&.withHeader': {
      top: headerHeight + layout.padding.inline.m,
    },

    '&.left': {
      left:    -width,
      '& > *': {paddingRight: layout.radius.l},
    },

    '&.right': {
      right:   -width,
      '& > *': {paddingLeft: layout.radius.l},
    },

    willChange: 'transform',
    transition: animation.transitions.medium('transform'),

    '&.left:not(.expanded)': {
      transform: `translateX(${width + layout.radius.l}px)`,
    },
    '&.right:not(.expanded)': {
      transform: `translateX(${-width - layout.radius.l}px)`,
    },

    '& > *': {
      overflow:     'hidden',
      background:   theme.colors.bg.light.semi,
      borderRadius: layout.radius.l,
      boxShadow:    shadows.depth(5),
    },
  }),

  transition: {
    '&-open': {
      opacity:    0,
      transform:  'translateY(-20px)',
    },
    '&-open-active': {
      opacity:    1,
      transform: 'translateY(0)',
    },
    '&-close': {
      opacity:    1,
      transform:  'translateY(0)',
    },
    '&-close-active': {
      opacity:    0,
      transform: 'translateY(-20px)',
    },
    '&-open-active, &-close-active': {
      transition: animation.transitions.medium(['transform', 'opacity'], 'cubic-bezier(0, 0.6, 0.6, 1)'),
    },
  },

  header: {
    height:     headerHeight,
    background: theme.guide.colors.semantic.secondary,

    borderTopLeftRadius:  layout.radius.l,
    borderTopRightRadius: layout.radius.l,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },

  footer: {
    borderBottomLeftRadius:  layout.radius.l,
    borderBottomRightRadius: layout.radius.l,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },

  content: {
    minWidth:  240,
    minHeight: 96,
  },
}))