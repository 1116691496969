import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { authenticationStore } from '~/stores'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {}

const DrawerFooter = memo('DrawerFooter', () => {

  const theme = useTheme()
  const [t] = useTranslation()

  const logOut = React.useCallback(() => {
    authenticationStore.logOut()
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.DrawerFooter} padding={layout.padding.s} justify='space-between'>
        {renderVersion()}
        {renderLogOut()}
      </HBox>
    )
  }

  function renderVersion() {
    return (
      <Label dim tiny>
        {`v${config.version} (${config.environment})`}
      </Label>
    )
  }

  function renderLogOut() {
    return (
      <VBox>
        <ClearButton
          icon='logout'
          caption={t('auth:logout.caption')}
          color={theme.fg.dim}
          onTap={logOut}
          small
        />
      </VBox>
    )
  }

  return render()

})

export default DrawerFooter

const useStyles = createUseStyles(theme => ({
  DrawerFooter: {
    backgroundColor: theme.fg.normal.alpha(0.05),
    borderTopWidth:  1,
    borderTopColor:  theme.fg.normal.alpha(0.1),
  },
}))