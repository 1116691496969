import React from 'react'
import { AutofocusProvider } from 'react-autofocus'
import { memo } from '~/ui/component'
import {
  childFlex,
  HBox,
  SegmentedButton,
  Transition,
  TransitionProps,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { SVGName } from '~/ui/components/SVG'
import { useRefMap } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { isReactComponent } from '~/ui/util'

export interface Props<T> {
  tabs:      Tab<T>[]

  currentTab?: T
  requestTab?: (tab: T) => any
  tabsShown?:  boolean
  renderMode?: TransitionProps<T>['renderMode']

  compact?: boolean
  flex?:    VBoxProps['flex']

  onShowTab?: (tab: T) => any

  children?:    (tab: T) => React.ReactNode
}

export interface Tab<T> {
  icon?:    SVGName
  name:     T
  caption:  string
  enabled?: boolean
  render?:  TabContent
}

type TabContent = React.ComponentType<EmptyObject> | React.ReactNode

const _TabPanel = <T extends {} = string>(props: Props<T>) => {

  const {
    tabs,
    currentTab: props_currentTab,
    requestTab: props_requestTab,
    tabsShown = props_requestTab != null,
    compact = false,
    renderMode,
    onShowTab,
    flex,
    children,
  } = props

  const tabRefs     = useRefMap<any, HTMLDivElement>()
  const contentFlex = childFlex(flex)

  //------
  // Tab switching

  const [state_currentTab, state_setCurrentTab] = React.useState<T | null>(tabs[0]?.name ?? null)

  const currentTab = React.useMemo(
    () => props_currentTab ?? state_currentTab ?? null,
    [props_currentTab, state_currentTab],
  )

  React.useEffect(() => {
    if (currentTab == null) { return }
    onShowTab?.(currentTab)
  }, [currentTab, onShowTab])

  const switchTab = React.useCallback((name: T) => {
    if (props_requestTab) {
      props_requestTab(name)
    } else {
      state_setCurrentTab(name)
    }
  }, [props_requestTab])

  //------
  // Buttons

  const buttonSegments = React.useMemo((): ButtonSegment<T>[] => tabs.map(tab => ({
    value:   tab.name,
    icon:    tab.icon,
    caption: tab.caption,
    enabled: tab.enabled,
  })), [tabs])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} classNames={$.TabPanel} gap={compact ? layout.padding.s : layout.padding.l}>
        {renderButton()}
        {renderBody()}
      </VBox>
    )
  }

  function renderButton() {
    if (!tabsShown) { return }
    return (
      <HBox justify='center'>
        <VBox flex='shrink'>
          <SegmentedButton
            segments={buttonSegments}

            selectedValue={currentTab}
            onChange={switchTab}
            small={compact}
          />
        </VBox>
      </HBox>
    )
  }

  function renderBody() {
    return (
      <VBox flex={contentFlex} classNames={$.body}>
        <Transition flex={contentFlex} type='fade-slide' currentScene={currentTab ?? tabs[0].name} duration={transitionDuration} renderMode={renderMode}>
          {tabs.map(renderTab)}
        </Transition>
      </VBox>
    )
  }

  function renderTab(tab: Tab<T>) {
    return (
      <Transition.Scene key={`${tab.name}`} name={tab.name}>
        <AutofocusProvider
          key={`${tab.name}`}
          enabled={tab.name === currentTab}
          defaultFocus={{buttons: false}}
          trap='exclude'
        >
          <VBox flex={contentFlex} ref={tabRefs.for(tab.name)}>
            {renderTabContent(tab)}
          </VBox>
        </AutofocusProvider>
      </Transition.Scene>
    )
  }

  function renderTabContent(tab: Tab<T>) {
    if (isReactComponent(tab.render)) {
      return <tab.render/>
    } else if (tab.render != null) {
      return tab.render
    } else {
      return children?.(tab.name)
    }
  }

  return render()

}

const TabPanel = memo('TabPanel', _TabPanel) as typeof _TabPanel
export default TabPanel

const transitionDuration = animation.durations.medium

const useStyles = createUseStyles({
  TabPanel: {
  },

  body: {
  },
})