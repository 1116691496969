import { ResizeHandler, ResizeSpec } from './types'

export default async function resizeImage(image: Blob, spec: ResizeSpec): Promise<Blob> {
  if (!/^image\//.test(image.type)) {
    console.warn("resizeImage: given Blob is not an image, returning unchanged")
    return image
  }

  const handler =
    'scale' in spec ? resizeBy(spec.scale) :
    'fitIn' in spec ? resizeToFit(spec.fitIn) :
    'fileSize' in spec ? resizeToFileSize(spec.fileSize) :
    spec.custom

  return withCanvasContext(image, handler)
}

async function withCanvasContext(blob: Blob, handler: ResizeHandler): Promise<Blob> {
  const url = URL.createObjectURL(blob)

  try {
    return await new Promise<Blob>((resolve, reject) => {
      const canvas  = document.createElement('canvas')
      const context = canvas.getContext("2d")
      if (context == null) {
        reject("unable to obtain 2D context")
        return
      }

      const image = new Image()
      image.onload = async () => {
        const result = await handler(image, canvas, context, blob)
        if (result === false) {
          resolve(blob)
          return
        }

        canvas.toBlob(imageOrNull => {
          if (imageOrNull == null) {
            reject("Unable to generate image")
          } else {
            resolve(imageOrNull)
          }
        }, blob.type)
      }
      image.onerror = error => {
        reject(error)
      }
      image.src = url
    })
  } catch (error: any) {
    throw new Error("Cannot resize image: " + error)
  } finally {
    URL.revokeObjectURL(url)
  }
}

function resizeBy(scale: number): ResizeHandler {
  return (image, canvas, context) => {
    canvas.width = image.width * scale
    canvas.height = image.height * scale
    context.drawImage(image, 0, 0, canvas.width, canvas.height)
  }
}

function resizeToFit(size: Size): ResizeHandler {
  return (image, canvas, context) => {
    const scale = Math.min(size.width / image.width, size.height / image.height)
    if (scale > 1) { return false }

    canvas.width = image.width * scale
    canvas.height = image.height * scale
    context.drawImage(image, 0, 0, canvas.width, canvas.height)
  }
}

function resizeToFileSize(fileSize: number): ResizeHandler {
  return async (image, canvas, context, blob): Promise<void | false> => {
    const factor = fileSize / blob.size
    if (factor > 1) { return false }

    const tryWithScale = (scale: number): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        canvas.width  = image.width * scale
        canvas.height = image.height * scale
        context.drawImage(image, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(imageOrNull => {
          if (imageOrNull == null) {
            reject("Unable to generate image")
          } else {
            resolve(imageOrNull.size <= fileSize)
          }
        }, blob.type)
      })
    }

    // Given that an image is 2D, and given the quality of encodings, we can approximate a required scale by taking the fifth order root
    // of the file size factor. Then, use an approximation algorithm to get the largest size resulting in a proper file size.

    let scale: number = factor ** (1 / 5)
    let iteration: number = 0

    // Phase 1 - scale down with large steps until it fits.
    while (iteration < 20) {
      if (await tryWithScale(scale)) { break }
      scale *= 0.75
    }

    // If phase1 didn't complete within 20 iterations, we give up.
    if (iteration > 20) {
      throw new Error("Could not resize image to target size within 20 iterations")
    }

    // Phase 2 - scale up with small steps until it doesn't fit anymore.
    let lastFitted = false
    iteration = 0
    while (iteration < 20) {
      scale *= 1.05
      lastFitted = await tryWithScale(scale)
      if (!lastFitted) { break }
    }

    // If we went over, go one back.
    if (!lastFitted) {
      await tryWithScale(scale / 1.05)
    }
  }

}