import React from 'react'
import { useTranslation } from 'react-i18next'
import { arrayEquals } from 'ytil'
import { Feed } from '~/models'
import { memo, observer } from '~/ui/component'
import {
  Badge,
  Center,
  Checkbox,
  ClearButton,
  Dimple,
  HBox,
  Label,
  List,
  Panel,
  Popup,
  PushButton,
  VBox,
} from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { useBoolean, useQueryParam } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useNewsService } from './NewsContext'

export interface Props {}

const NewsFeedFilter = observer('NewsFeedFilter', (props: Props) => {

  const service  = useNewsService()
  const endpoint = service.allPosts

  const [popupOpen, openPopup, closePopup] = useBoolean()
  const [t] = useTranslation('news')

  const [filteredFeedIDs, setFilteredFeedIDs] = useQueryParam('feeds', 'string[]')
  const [currentFilteredFeedIDs, setCurrentFilteredFeedIDs] = React.useState<string[]>(filteredFeedIDs)

  React.useEffect(() => {
    if (arrayEquals(endpoint.param('feedIDs') ?? [], filteredFeedIDs)) { return }

    if (filteredFeedIDs.length === 0) {
      endpoint.setParams({feedIDs: undefined})
    } else {
      endpoint.setParams({feedIDs: filteredFeedIDs})
    }
  }, [endpoint, filteredFeedIDs])

  const prime = React.useCallback(() => {
    setCurrentFilteredFeedIDs(filteredFeedIDs)
  }, [filteredFeedIDs])

  const commit = React.useCallback(() => {
    setFilteredFeedIDs(currentFilteredFeedIDs)
  }, [currentFilteredFeedIDs, setFilteredFeedIDs])

  const toggleFeed = React.useCallback((feed: Feed) => {
    if (currentFilteredFeedIDs.includes(feed.id)) {
      setCurrentFilteredFeedIDs(currentFilteredFeedIDs.filter(id => id !== feed.id))
    } else {
      setCurrentFilteredFeedIDs([...currentFilteredFeedIDs, feed.id])
    }
  }, [currentFilteredFeedIDs])

  const commitAndClose = React.useCallback(() => {
    commit()
    closePopup()
  }, [closePopup, commit])

  const clearFilter = React.useCallback(() => {
    setCurrentFilteredFeedIDs([])
    setFilteredFeedIDs([])
    closePopup()
  }, [closePopup, setFilteredFeedIDs])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Popup
        open={popupOpen}
        requestClose={commitAndClose}
        renderContent={renderPopupContent}
        onWillOpen={prime}
        flex='grow'
        children={renderButton()}
      />
    )
  }

  function renderButton() {
    return (
      <Center classNames={$.popupButton}>
        <ClearButton
          icon='filter'
          onTap={openPopup}
          large
        />
        {filteredFeedIDs.length > 0 && (
          <Badge
            classNames={$.badge}
            value={filteredFeedIDs.length}
            tiny
          />
        )}
      </Center>
    )
  }

  function renderPopupContent() {
    return (
      <VBox classNames={$.popupContent} gap={layout.padding.s}>
        <List
          data={service.feeds}
          keyExtractor={keyExtractor}
          renderItem={renderFeedButton}
          itemGap={layout.padding.xs}
          flex={false}
        />
        <Dimple horizontal/>
        <HBox gap={layout.padding.inline.m}>
          <VBox flex>
            <PushButton
              caption={t('filter.commit')}
              onTap={commitAndClose}
              small
            />
          </VBox>
          <ClearButton
            icon='cross'
            caption={t('filter.clear')}
            onTap={clearFilter}
            padding='both'
            align='center'
            small
          />
        </HBox>
      </VBox>
    )
  }

  const keyExtractor = React.useCallback(
    (feed: Feed) => feed.id,
    [],
  )

  const renderFeedButton = React.useCallback(
    (feed: Feed) => (
      <NewsFeedFilterButton
        feed={feed}
        selected={currentFilteredFeedIDs.includes(feed.id)}
        requestToggle={toggleFeed}
      />
    ),
    [currentFilteredFeedIDs, toggleFeed],
  )

  return render()

})

export default NewsFeedFilter

interface NewsFeedFilterButtonProps {
  feed:          Feed
  selected:      boolean
  requestToggle: (feed: Feed) => any
}

const NewsFeedFilterButton = memo('NewsFeedFilterButton', (props: NewsFeedFilterButtonProps) => {

  const {feed, selected, requestToggle} = props

  const toggle = React.useCallback(() => {
    requestToggle(feed)
  }, [feed, requestToggle])

  const $ = useStyles()

  function render() {
    return (
      <Panel onTap={toggle}>
        <HBox classNames={$.NewsFeedFilterButton} gap={layout.padding.inline.m}>
          <Center
            classNames={$.colorSwatch}
            style={{backgroundColor: feed.color.string()}}
          />
          <Label flex caption>
            {feed.title}
          </Label>
          <Checkbox
            checked={selected}
          />
        </HBox>
      </Panel>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  popupButton: {
    position: 'relative',
  },

  badge: {
    position: 'absolute',
    top:      -badgeSize.tiny.height / 6,
    right:    -badgeSize.tiny.minWidth / 3,
  },

  popupContent: {
    width:  320,
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),

    background: theme.bg.semi,
  },

  NewsFeedFilterButton: {
    height:  layout.barHeight.s,
    padding: [layout.padding.inline.xs, layout.padding.inline.m],
  },

  colorSwatch: {
    width:        16,
    height:       16,
    borderRadius: '50%',
  },
}))