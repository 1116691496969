import { Document } from 'mobx-document'
import socket from 'socket.io-react'
import { QAndA, Question } from '~/models'
import { SubmitResult } from '~/ui/form'
import qAndAsStore from '../qAndAsStore'
import { submitResultForResponse } from '../support'
import QuestionsEndpoint from './QuestionsEndpoint'
import { QAndAMeta } from './types'

export default class QAndADocument extends Document<QAndA, string, {}, QAndAMeta> {

  public questionsEndpoint = new QuestionsEndpoint(qAndAsStore.questions, this)

  protected onDidChange() {
    if (this.meta != null) {
      this.questionsEndpoint.replaceFromMeta(this.meta)
    }
  }

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('q-and-as:show', this.id)
    if (!response.ok) { return response }

    const pack  = response.body
    const qAndA = QAndA.deserialize(pack.data)

    return {
      data: qAndA,
      meta: pack.meta,
    }
  }

  //------
  // Questions

  public async addQuestion(data: AnyObject): Promise<SubmitResult> {
    const response = await socket.send('q-and-as:questions:create', this.id, data)
    if (response.ok) {
      const question = Question.deserialize(response.body.data)
      if (this.questionsEndpoint.param('sort') === 'date') {
        this.questionsEndpoint.insert(question, 0)
      } else {
        this.questionsEndpoint.add(question)
      }
    }

    return submitResultForResponse(response)
  }

}