import React from 'react'
import DragDropMonitor from './DragDropMonitor'
import { useRefMap } from './hooks'
import {
  DraggableItem,
  DropZone,
  DropZoneAccept,
  DropZoneHandlers,
  ItemHandlers,
  SnapAction,
} from './types'

export interface DragDropContext {
  setMonitor: (monitor: DragDropMonitor | null) => void

  setItemHandlers:     (item: any, handlers: ItemHandlers | null) => void
  setDropZoneHandlers: (id: symbol, handlers: DropZoneHandlers | null) => void
  setDropZoneAccept:   (id: symbol, accept: DropZoneAccept) => void
  getDropZones:        () => DropZone[]

  start: (item: DraggableItem, monitor: DragDropMonitor) => void
  end:   (item: DraggableItem, monitor: DragDropMonitor) => void
  enter: (id: symbol, item: DraggableItem, monitor: DragDropMonitor) => void
  leave: (id: symbol, item: DraggableItem, monitor: DragDropMonitor) => void
  hover: (id: symbol, item: DraggableItem, point: Point, monitor: DragDropMonitor) => void
  drop:  (id: symbol, item: DraggableItem, point: Point, monitor: DragDropMonitor) => void | Promise<void>
  snap:  (id: symbol, point: Point, monitor: DragDropMonitor) => SnapAction | null
}

export interface DragDropMonitorContext {
  monitor: DragDropMonitor | null
}

export const DragDropMonitorContext = React.createContext<DragDropMonitorContext>({
  monitor: null,
})

export function useDragDropMonitor<T extends DraggableItem>() {
  const {monitor} = React.useContext(DragDropMonitorContext)
  return monitor as DragDropMonitor<T>
}

export const DragDropContext = React.createContext<DragDropContext>({
  setMonitor: () => void 0,

  setItemHandlers:     () => void 0,
  setDropZoneHandlers: () => void 0,
  setDropZoneAccept:   () => void 0,
  getDropZones:        () => [],

  start:               () => void 0,
  end:                 () => void 0,
  enter:               () => void 0,
  hover:               () => void 0,
  leave:               () => void 0,
  snap:                () => null,
  drop:                () => void 0,
})

export interface DragDropContainerProps {
  children?: React.ReactNode
}

export function DragDropContainer(props: DragDropContainerProps) {

  const [monitor, setMonitor] = React.useState<DragDropMonitor | null>(null)

  const dropZoneAccepts  = useRefMap<symbol, DropZoneAccept>()
  const dropZoneHandlers = useRefMap<symbol, DropZoneHandlers>()
  const itemHandlers     = useRefMap<DraggableItem, ItemHandlers>()

  const getDropZones = React.useCallback(() => {
    const dropZones: Array<[symbol, DropZoneAccept, LayoutRect, number]> = []
    for (const [id, accept] of dropZoneAccepts.entries()) {
      const bounds = dropZoneHandlers.get(id)?.getBounds()
      const zIndex = dropZoneHandlers.get(id)?.getZIndex?.() ?? 0
      if (bounds == null) { continue }

      dropZones.push([id, accept, bounds, zIndex])
    }
    return dropZones
  }, [dropZoneAccepts, dropZoneHandlers])

  const context = React.useMemo((): DragDropContext => ({
    setMonitor,

    setDropZoneAccept: (id, accept) => {
      dropZoneAccepts.set(id, accept)
    },
    getDropZones,

    setItemHandlers: (item, handlers) => {
      itemHandlers.set(item, handlers)
    },
    setDropZoneHandlers: (id, handlers) => {
      dropZoneHandlers.set(id, handlers)
    },

    start: (item, monitor) => {
      for (const [id] of getDropZones()) {
        const handlers = dropZoneHandlers.get(id)
        handlers?.start?.(item, monitor)
      }
    },
    end: (item, monitor) => {
      for (const [id] of getDropZones()) {
        const handlers = dropZoneHandlers.get(id)
        handlers?.end?.(item, monitor)
      }
    },
    enter: (id, item, monitor) => {
      const itHandlers = itemHandlers.get(item)
      itHandlers?.enter?.(item, id, monitor)

      const zoneHandlers = dropZoneHandlers.get(id)
      zoneHandlers?.enter?.(item, monitor)
    },
    leave: (id, item, monitor) => {
      const itHandlers = itemHandlers.get(item)
      itHandlers?.leave?.(item, id, monitor)

      const zoneHandlers = dropZoneHandlers.get(id)
      zoneHandlers?.leave?.(item, monitor)
    },
    hover: (id, item, point, monitor) => {
      const handlers = dropZoneHandlers.get(id)
      handlers?.hover?.(item, point, monitor)
    },
    drop: (id, item, point, monitor) => {
      const handlers = dropZoneHandlers.get(id)
      return handlers?.drop?.(item, point, monitor)
    },
    snap: (id, point, monitor) => {
      const handlers = dropZoneHandlers.get(id)
      if (handlers?.snap == null) { return null }

      return handlers.snap(point, monitor)
    },
  }), [dropZoneAccepts, dropZoneHandlers, getDropZones, itemHandlers])

  const monitorContext = React.useMemo(() => ({
    monitor,
  }), [monitor])

  return (
    <DragDropContext.Provider value={context}>
      <DragDropMonitorContext.Provider value={monitorContext}>
        {props.children}
      </DragDropMonitorContext.Provider>
    </DragDropContext.Provider>
  )

}