import React from 'react'
import { useTimer } from 'react-timer'
import I18n from 'i18next'
import { isFunction, some } from 'lodash'
import { memo } from '~/ui/component'
import { Center, Label, Popup, PopupMenuHeader, PopupProps, Spinner, VBox } from '~/ui/components'
import { borderRadius as popupBorderRadius } from '~/ui/components/Popup'
import {
  assignRef,
  KeyboardNavigationData,
  KeyboardNavigationNode,
  KeyPath,
  useContinuousRef,
  useKeyboardNavigation,
  useRefMap,
} from '~/ui/hooks'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import PopupMenuItemButton from './PopupMenuItemButton'
import StandardMenuItemButton from './StandardMenuItemButton'
import { isCustomItem, isSection, isStandardItem, MenuSection, PopupMenuItem } from './types'

export interface Props<T> extends Omit<PopupProps, 'open' | 'requestClose' | 'content'> {
  items: Array<PopupMenuItem<T>>

  open?:         boolean
  requestClose?: () => any

  loading?:         boolean
  emptyLabel?:      string

  header?:   React.ReactNode
  children?: React.ReactNode | ChildFunction

  value?:         T
  onValueSelect?: (value: T, index: number) => void
  onItemTap?:     (item: Exclude<PopupMenuItem<T>, MenuSection>, event: React.SyntheticEvent) => void
  onCancel?:      () => void

  indexPathPrefix?:         number[]
  highlightedIndexPath?:    number[]
  setHighlightedIndexPath?: (keyPath: number[]) => any

  popupAttributes?:  React.HTMLAttributes<HTMLDivElement>
  buttonClassNames?: React.ClassNamesProp
}

type ChildFunction = (
  toggle: () => void,
  open: boolean
) => React.ReactNode

const _PopupMenu = <T extends any = any>(props: Props<T>) => {

  const {colors} = useStyling()

  const {
    items,
    value,
    onValueSelect,
    indexPathPrefix,
    highlightedIndexPath: highlightedIndexPath_prop,
    setHighlightedIndexPath: setHighlightedIndexPath_prop,
    onItemTap,
    onCancel,
    open,
    requestClose,
    buttonClassNames,
    crossAlign = 'near',
    loading,
    header,
    matchTargetSize = false,
    children,

    onWillOpen:  props_onWillOpen,
    onWillClose: props_onWillClose,
    onDidClose:  props_onDidClose,

    ...popupProps
  } = props

  const [openFromInside, setOpenFromInside] = React.useState<boolean>(false)

  const hasKeyHints    = some(items, item => isStandardItem(item) && item.keyHint != null)
  const itemButtonRefs = useRefMap<number, HTMLElement>()

  //------
  // Sub menu

  const isSubMenu = indexPathPrefix != null
  const level     = indexPathPrefix?.length ?? 0

  const [highlightedIndexPath_state, setHighlightedIndexPath_state] = React.useState<KeyPath<number>>([])
  const highlightedIndexPath    = highlightedIndexPath_prop ?? highlightedIndexPath_state
  const setHighlightedIndexPath = setHighlightedIndexPath_prop ?? setHighlightedIndexPath_state

  const highlightedIndex = highlightedIndexPath[level] ?? null
  const setHighlightedIndex = React.useCallback((index: number | null) => {
    if (index != null) {
      setHighlightedIndexPath([...indexPathPrefix ?? [], index])
    }
  }, [indexPathPrefix, setHighlightedIndexPath])

  const highlightedItemRef = useContinuousRef(highlightedIndex == null ? null : items[highlightedIndex])
  const highlightedItem    = highlightedItemRef.current

  const subMenuParentRef = React.useRef<HTMLElement | null>(null)

  const [subMenuParentItem, setSubMenuParentItem] = React.useState<Exclude<PopupMenuItem, MenuSection> | null>(null)

  const subMenuCloseTimer = useTimer()

  const subMenuPrefix = React.useMemo(() => {
    if (subMenuParentItem == null) { return null }

    const subMenuParentIndex = items.indexOf(subMenuParentItem)
    if (subMenuParentIndex == null) { return null }

    return [...indexPathPrefix ?? [], subMenuParentIndex]
  }, [indexPathPrefix, items, subMenuParentItem])

  //------
  // Callbacks

  const close = React.useCallback(() => {
    setOpenFromInside(false)
    setHighlightedIndexPath_state([])
    setHighlightedIndex(null)
    setSubMenuParentItem(null)
    requestClose?.()
  }, [requestClose, setHighlightedIndex])

  const cancel = React.useCallback(() => {
    onCancel?.()
    close()
  }, [close, onCancel])

  const toggle = React.useCallback(() => {
    setOpenFromInside(!openFromInside)
  }, [openFromInside])

  const shouldCloseOnClick = React.useCallback((event: Event) => {
    if (!(event.target instanceof Element)) { return true }
    if (subMenuParentRef.current?.contains(event.target)) { return false }
  }, [])

  const openSubMenu = React.useCallback((item: Exclude<PopupMenuItem, MenuSection>) => {
    const index = items.indexOf(item)
    assignRef(subMenuParentRef, itemButtonRefs.get(index))

    setSubMenuParentItem(item)
  }, [itemButtonRefs, items])

  const closeSubMenu = React.useCallback(() => {
    setSubMenuParentItem(null)
  }, [])

  const highlightItem = React.useCallback((item: Exclude<PopupMenuItem, MenuSection>) => {
    const index = items.indexOf(item)
    setHighlightedIndex(index)
  }, [items, setHighlightedIndex])

  const unhighlightItem = React.useCallback(() => {
    setHighlightedIndexPath([])
  }, [setHighlightedIndexPath])

  const onSubMenuEnter = React.useCallback(() => {
    subMenuCloseTimer.clearAll()
  }, [subMenuCloseTimer])

  const selectItem = React.useCallback((item: Exclude<PopupMenuItem, MenuSection>) => {
    const index       = items.indexOf(item)
    const hasChildren = item.children != null && item.children.length > 0

    item.onSelect?.()
    if (!hasChildren || item.value != null) {
      onValueSelect?.(item.value, index)
      close()
    }
  }, [items, onValueSelect, close])

  const handleItemTap = React.useCallback((item: Exclude<PopupMenuItem, MenuSection>, event: React.SyntheticEvent) => {
    onItemTap?.(item, event)
    selectItem(item)
  }, [onItemTap, selectItem])

  React.useEffect(() => {
    if (highlightedItem == null) { return }
    if (isSection(highlightedItem)) { return }
    if (highlightedItem.children == null) { return }

    subMenuCloseTimer.clearAll()
    openSubMenu(highlightedItem)

    return () => {
      subMenuCloseTimer.debounce(closeSubMenu, 200)
    }
  }, [closeSubMenu, highlightItem, highlightedItem, highlightedItemRef, openSubMenu, subMenuCloseTimer])

  //------
  // Keyboard navigation

  const keyboardNavigationData = React.useMemo((): KeyboardNavigationData<any, number> => {
    const iterate = (items: PopupMenuItem[]) => {
      const nodes: KeyboardNavigationNode<any, number>[] = []
      for (const [index, item] of items.entries()) {
        if (isSection(item)) { continue }

        nodes.push({
          key:      index,
          item:     item,
          children: item.children == null ? undefined : iterate(item.children),
        })
      }

      return nodes
    }

    return iterate(items)
  }, [items])

  const [connectKeyboard, disconnectKeyboard] = useKeyboardNavigation(keyboardNavigationData, highlightedIndexPath_state, setHighlightedIndexPath_state, {
    mode:     'menu',
    onSelect: selectItem,
  })

  const onWillOpen = React.useCallback(() => {
    if (!isSubMenu) {
      connectKeyboard(window)
    }
    props_onWillOpen?.()
  }, [connectKeyboard, isSubMenu, props_onWillOpen])

  const onWillClose = React.useCallback(() => {
    if (!isSubMenu) {
      disconnectKeyboard(window)
    }

    props_onWillClose?.()
  }, [disconnectKeyboard, isSubMenu, props_onWillClose])

  const handleRootMenuClose = React.useCallback(() => {
    if (!isSubMenu) {
      disconnectKeyboard(window)
      unhighlightItem()
    }
  }, [disconnectKeyboard, isSubMenu, unhighlightItem])

  const onDidClose = React.useCallback(() => {
    handleRootMenuClose()
    props_onDidClose?.()
  }, [handleRootMenuClose, props_onDidClose])

  React.useEffect(() => handleRootMenuClose, [handleRootMenuClose])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Popup
        crossAlign={crossAlign}
        matchTargetSize={matchTargetSize}
        {...popupProps}

        open={open ?? openFromInside}
        requestClose={cancel}
        renderContent={renderContent}
        children={renderButton()}

        onWillOpen={onWillOpen}
        onWillClose={onWillClose}
        onDidClose={onDidClose}
      />
    )
  }

  function renderButton() {
    if (isFunction(children)) {
      return children(toggle, open ?? openFromInside)
    } else {
      return children
    }
  }

  function renderContent() {
    return (
      <ThemeProvider light>
        <VBox classNames={[$.popupMenu, {matchTargetSize}]} {...props.popupAttributes}>
          {renderSubMenu()}
          {loading && renderLoading()}
          {!loading && items.length === 0 && renderEmpty()}
          {!loading && items.length > 0 && renderHeader()}
          {!loading && items.map((item, index) => renderItem(item, index))}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderHeader() {
    if (isReactText(header)) {
      return (
        <PopupMenuHeader
          caption={header.toString()}
        />
      )
    } else {
      return header
    }
  }

  function renderLoading() {
    return (
      <Center classNames={$.loading}>
        <Spinner size={12}/>
      </Center>
    )
  }

  function renderEmpty() {
    const emptyLabel = props.emptyLabel || I18n.t('popup_menu:no_items')

    return (
      <Center classNames={$.empty}>
        <Label small dim>
          {emptyLabel}
        </Label>
      </Center>
    )
  }

  function renderItem(item: PopupMenuItem, index: number) {
    if (isSection(item)) {
      return renderSection(item, index)
    } else {
      return (
        <PopupMenuItemButton
          key={index}
          item={item}
          ref={itemButtonRefs.for(index)}
          highlighted={item === highlightedItem || index === highlightedIndex}
          onItemTap={handleItemTap}
          onItemEnter={highlightItem}
        >
          {isCustomItem(item) ? (
            item.render()
          ) : (
            <StandardMenuItemButton
              item={item}
              checked={item.checked ?? (value === undefined ? undefined : item.value === value)}
              hasKeyHints={hasKeyHints}
            />
          )}
        </PopupMenuItemButton>
      )
    }
  }

  function renderSection(item: MenuSection, index: number) {
    const isSeparator = item.section === '-'
    const style: React.CSSProperties = {}
    if (item.backgroundColor != null) {
      style.backgroundColor = item.backgroundColor.string()
    }

    const defaultColorLabel = item.backgroundColor == null && item.textColor == null
    const labelColor        = item.textColor ?? (item.backgroundColor == null ? undefined : colors.contrast(item.backgroundColor))

    return (
      <VBox key={index} classNames={isSeparator ? $.separator : $.section} style={style}>
        {isSeparator ? null : isReactText(item.section) ? (
          <Label small bold dim={defaultColorLabel} color={labelColor}>
            {item.section}
          </Label>
        ) : item.section}
      </VBox>
    )
  }

  function renderSubMenu() {
    if (subMenuParentItem == null) { return null }
    if (subMenuPrefix == null) { return null }

    return (
      <PopupMenu
        open={true}
        requestClose={closeSubMenu}

        indexPathPrefix={subMenuPrefix}
        highlightedIndexPath={highlightedIndexPath}
        setHighlightedIndexPath={setHighlightedIndexPath}

        align='horizontal'
        crossAlign='near'
        items={subMenuParentItem.children ?? []}

        layoutKey={highlightedIndex}
        targetRef={subMenuParentRef}
        gap={layout.padding.inline.s}

        shouldCloseOnClick={shouldCloseOnClick}
        popupAttributes={{onMouseEnter: onSubMenuEnter, onTouchStart: onSubMenuEnter}}

        onItemTap={handleItemTap}
      />
    )
  }

  return render()

}

const PopupMenu = memo('PopupMenu', _PopupMenu) as typeof _PopupMenu
export default PopupMenu

export const minWidth = 192
export const maxWidth = 260

const useStyles = createUseStyles(theme => ({
  popupMenu: {
    '&:not(.matchTargetSize)': {
      minWidth: minWidth,
      maxWidth: maxWidth,
    },
  },

  defaultButton: {
    padding: layout.padding.inline.l,
    ...layout.row(layout.padding.inline.l),
  },

  loading: {
    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),
  },

  empty: {
    padding: layout.padding.inline.s,
    height:  layout.barHeight.m,
  },

  section: {
    ...layout.responsiveProp({
      padding: layout.padding.s,
    }),
    color:   theme.colors.fg.dark.normal,

    '&:first-child': {
      borderTopLeftRadius: popupBorderRadius,
      borderTopRightRadius: popupBorderRadius,
    },
    '&:last-child': {
      borderBottomLeftRadius: popupBorderRadius,
      borderBottomRightRadius: popupBorderRadius,
    },
  },

  separator: {
    margin:     [layout.padding.inline.s, 0],
    height:     1,
    background: theme.colors.fg.dark.normal.alpha(0.1),
  },
}))