import React from 'react'
import { getClientPoint } from 'react-dnd'
import { clamp, range } from 'lodash'
import { BrandedComponent, VBox } from '~/ui/components'
import { ChangeCallback, FieldChangeCallback, invokeFieldChangeCallback } from '~/ui/form'
import { colors, createUseStyles, shadows, useStyling } from '~/ui/styling'

export interface Props {
  value:     number | null
  onChange?: FieldChangeCallback<number> | ChangeCallback<number>

  min:    number
  max:    number
  step?:  number | null
  emoji?: string | null

  logarithmic?: boolean
  readOnly?:    boolean
}

export default function Slider(props: Props) {

  const {value, onChange, step, logarithmic = false, emoji = null, readOnly = false} = props

  const {toValue, toLinear} = React.useMemo(() => {
    if (!logarithmic) {
      return {
        toValue:  (val: number) => val,
        toLinear: (val: number) => val,
      }
    } else {
      return {
        toValue:  (val: number) => Math.pow(Math.E, val),
        toLinear: (val: number) => Math.log(val),
      }
    }
  }, [logarithmic])

  const min      = toLinear(props.min)
  const max      = toLinear(props.max)
  const hasValue = value != null

  const innerRef = React.useRef<HTMLDivElement>(null)

  //------
  // Rendering

  const $       = useStyles()
  const {guide} = useStyling()

  function render() {
    return (
      <VBox classNames={[$.slider, {hasValue}]}>
        <div classNames={$.inner} ref={innerRef} {...handlers}>
          {renderTrack()}
          {renderThumb()}
        </div>
      </VBox>
    )
  }

  function renderTrack() {
    return (
      <div classNames={$.track}>
        {renderTicks()}
      </div>
    )
  }

  function renderTicks() {
    if (step == null) { return null }

    const count = Math.ceil((max - min) / step)
    if (count > 20) { return null }

    return (
      <div classNames={$.ticks}>
        {range(min, max, step).map(val => (
          <div
            key={val}
            classNames={$.tick}
            style={{left: `${(toLinear(val) - min) / (max - min) * 100}%`}}
          />
        ))}
        <div classNames={$.tick} style={{left: '100%'}}/>
      </div>
    )
  }

  function renderThumb() {
    const left = value != null
      ? `${clamp((toLinear(value) - min) / (max - min), 0, 1) * 100}%`
      : '50%'

    return (
      <div classNames={$.thumbContainer}>
        <BrandedComponent
          classNames={[$.thumb, {emoji}]}
          branding={guide.slider.thumb}
          style={{left}}
          variant={{hasValue}}
          height={guide.slider.thumbSize}
          tabIndex={0}
          children={emoji && <div classNames={$.emoji}>{emoji}</div>}
        />
      </div>
    )
  }

  //------
  // Handlers

  const valueRef = React.useRef<number | null>(value)

  const calculateValueFromPoint = React.useCallback((point: Point) => {
    if (innerRef.current == null) { return 0 }

    const innerRect = innerRef.current.getBoundingClientRect()
    const width     = innerRect.width - 2 * margin(guide.slider.thumbSize)
    const offset    = point.x - innerRect.left - margin(guide.slider.thumbSize)

    let value = (offset / width) * (max - min) + min
    if (step != null) {
      value = Math.round(value / step) * step
    }

    return toValue(clamp(value, min, max))
  }, [guide.slider.thumbSize, max, min, step, toValue])

  const move = React.useCallback((event: TouchEvent | MouseEvent) => {
    if (onChange == null) { return }

    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    valueRef.current  = calculateValueFromPoint(clientPoint)
    invokeFieldChangeCallback(onChange, valueRef.current, true)
    event.preventDefault()
  }, [calculateValueFromPoint, onChange])

  const end = React.useCallback((event: TouchEvent | MouseEvent) => {
    if (valueRef.current != null) {
      invokeFieldChangeCallback(onChange, valueRef.current, false)
    }

    window.removeEventListener('mousemove', move)
    window.removeEventListener('touchmove', move)
    window.removeEventListener('mouseup', end)
    window.removeEventListener('touchend', end)

    event.preventDefault()
  }, [move, onChange])

  const start = React.useCallback((event: React.SyntheticEvent<TouchEvent | MouseEvent>) => {
    const clientPoint = getClientPoint(event.nativeEvent as TouchEvent | MouseEvent)
    if (clientPoint == null) { return }

    valueRef.current = calculateValueFromPoint(clientPoint)

    window.addEventListener('mousemove', move)
    window.addEventListener('touchmove', move)
    window.addEventListener('mouseup', end)
    window.addEventListener('touchend', end)

    event.preventDefault()
  }, [calculateValueFromPoint, end, move])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (step == null) { return }

    let start = value == null ? (max + min) / 2 : value
    start = Math.round(start / step) * step

    switch (event.key) {
      case 'ArrowLeft':
        return onChange?.(clamp(start - step, min, max))
      case 'ArrowRight':
        return onChange?.(clamp(start + step, min, max))
    }
  }, [max, min, onChange, step, value])

  const handlers = React.useMemo((): React.HTMLAttributes<any> => readOnly ? {} : {
    onMouseDown:  start,
    onTouchStart: start,
    onKeyDown:    handleKeyDown,
  }, [handleKeyDown, readOnly, start])

  return render()

}

const emojiSize = 24
const trackThickness  = 2
const margin    = (thumbSize: number) => thumbSize / 2 - trackThickness

const useStyles = createUseStyles(theme => ({
  slider: {
  },

  inner: {
    position: 'relative',
    padding:  margin(theme.guide.slider.thumbSize),
  },

  track: {
    position:   'relative',
    height:     trackThickness,
    boxShadow: [
      ['inset', 0, 1, 0, 0, colors.dimple.dark],
      ['inset', 0, -1, 0, 0, colors.dimple.light],
    ],
  },

  ticks: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      -2,
    bottom:   -2,
  },

  tick: {
    position:   'absolute',
    top:        0,
    bottom:     0,
    width:      1,
    marginLeft: -0.5,
    background: theme.inverse.bg.alt.alpha(0.6),
  },

  thumbContainer: {
    position: 'absolute',
    left:     margin(theme.guide.slider.thumbSize),
    top:      margin(theme.guide.slider.thumbSize),
    right:    margin(theme.guide.slider.thumbSize),
    bottom:   margin(theme.guide.slider.thumbSize),
  },

  thumb: {
    position:    'absolute',
    top:         trackThickness / 2,
    marginTop:   -theme.guide.slider.thumbSize / 2 + 1,
    marginLeft:  -theme.guide.slider.thumbSize / 2 + 1,
    width:       theme.guide.slider.thumbSize,
    height:      theme.guide.slider.thumbSize,

    '&.emoji': {
      background: 'none',
      boxShadow:  'none',
    },

    '$slider:not(.hasValue) &': {
      opacity: 0.6,
    },

    '&:focus, &:focus-within': {
      boxShadow: shadows.focus.bold(theme),
    },
  },

  emoji: {
    position:      'absolute',
    width:         emojiSize,
    height:        emojiSize,
    left:          (theme.guide.slider.thumbSize - emojiSize) / 2,
    top:           (theme.guide.slider.thumbSize - emojiSize) / 2,
    pointerEvents: 'none',

    textAlign:  'center',
    fontSize:   24,
    lineHeight: 1,

    filter: `drop-shadow(0 1px 2px ${shadows.shadowColor})`,
  },
}))