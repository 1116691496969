import React from 'react'
import { range, some } from 'lodash'
import { forwardRef } from '~/ui/component'
import { HBox, Tappable, TextFieldProps } from '~/ui/components'
import { assignRef, useRefMap } from '~/ui/hooks'
import { createUseStyles, layout, presets, useTheme } from '~/ui/styling'
import { focusNext } from '~/ui/util'

export interface Props {
  value:     string
  onChange?: (value: string) => any

  enabled?:    boolean
  inputStyle?: TextFieldProps['inputStyle']
}

interface PinCodeField {
  focus: () => any
  blur:  () => any
}

const PinCodeField = forwardRef('PinCodeField', (props: Props, ref: React.Ref<PinCodeField>) => {

  const {
    value,
    onChange,
    enabled,
    inputStyle,
  } = props

  const pinRef = React.useRef<string>('')

  const digitRefs = useRefMap<number, HTMLInputElement>()

  //------
  // Callbacks

  const mergePin = React.useCallback((index: number, digits: string[]) => {
    return range(0, 5).map(idx => {
      if (idx < index) {
        return pinRef.current.charAt(idx) ?? ''
      } else if (digits[idx - index] === ' ') {
        return ''
      } else {
        return digits[idx - index] ?? pinRef.current.charAt(idx) ?? ''
      }
    }).join('')
  }, [pinRef])

  const goToDigit = React.useCallback((index: number) => {
    if (index < 0 || index > pinRef.current.length) { return }
    digitRefs.get(index)?.focus()
  }, [digitRefs, pinRef])

  const handleDigitChange = React.useCallback((index: number, digits: string[]) => {
    const nextPin = mergePin(index, digits)
    onChange?.(pinRef.current = nextPin)
  }, [mergePin, onChange])

  //------
  // Imperative methods

  const focus = React.useCallback(() => {
    const digitInputs = digitRefs.all()
    const hasFocus    = some(digitInputs, it => it === document.activeElement)
    if (hasFocus) { return }

    const firstEmpty   = digitInputs.find(it => it.value === '')
    const inputToFocus = firstEmpty ?? digitInputs[0]
    inputToFocus?.focus()
  }, [digitRefs])

  const blur = React.useCallback(() => {
    const digitInputs  = digitRefs.all()
    const focusedInput = digitInputs.find(it => it === document.activeElement)
    focusedInput?.blur()
  }, [digitRefs])

  const focusAfterLastDigit = React.useCallback(() => {
    const lastDigitInput = digitRefs.get(4)
    if (lastDigitInput == null) { return }
    focusNext(lastDigitInput)
  }, [digitRefs])

  React.useImperativeHandle(ref, () => ({
    focus,
    blur,
  }))


  //------
  // Rendering

  function render() {
    return (
      <HBox justify='space-between' gap={layout.padding.s}>
        {range(0, 5).map(index => (
          <Digit
            key={index}
            ref={digitRefs.for(index)}
            index={index}
            value={value.charAt(index)}
            enabled={enabled}
            onChange={handleDigitChange}
            requestGoToDigit={goToDigit}
            requestFocusNext={focusAfterLastDigit}
            inputStyle={inputStyle}
          />
        ))}
      </HBox>
    )
  }

  return render()

})

interface DigitProps {
  index:            number
  value:            string
  enabled?:         boolean
  onChange:         (index: number, digits: string[]) => any
  requestGoToDigit: (index: number) => any
  requestFocusNext:      () => any
  inputStyle:       TextFieldProps['inputStyle']
}

const Digit = React.forwardRef((props: DigitProps, ref: React.Ref<HTMLInputElement>) => {

  const {
    index,
    value,
    enabled = true,
    onChange,
    requestGoToDigit,
    requestFocusNext,
    inputStyle,
  } = props

  const theme  = useTheme()
  const dark   = inputStyle === 'dark' || !theme.isDark
  const filled = value !== ''

  //------
  // Callbacks

  const inputRef   = React.useRef<HTMLInputElement>(null)
  const keyDownRef = React.useRef<boolean>(false)
  const changedRef = React.useRef<boolean>(false)

  const goTo = React.useCallback((index: number) => {
    if (index < 5) {
      requestGoToDigit(index)
    } else {
      setTimeout(requestFocusNext, 0)
    }
  }, [requestFocusNext, requestGoToDigit])

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    const digits = value.replace(/[^0-9]/g, '').split('')
    if (digits.length === 0) { return }

    onChange(index, digits)
    goTo(index + digits.length)

    changedRef.current = true
  }, [goTo, index, onChange])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    keyDownRef.current = true
    changedRef.current = false

    if (event.key === 'Backspace') {
      if (index === 4 && value !== '') {
        onChange(index, [' '])
      } else {
        onChange(index - 1, [' '])
        requestGoToDigit(index - 1)
      }
    } else if (event.key === 'ArrowLeft') {
      requestGoToDigit(index - 1)
    } else if (event.key === 'ArrowRight') {
      requestGoToDigit(index + 1)
    }
  }, [index, value, onChange, requestGoToDigit])

  const handleKeyUp = React.useCallback((event: React.KeyboardEvent) => {
    if (!keyDownRef.current || changedRef.current) { return }

    keyDownRef.current = false
    changedRef.current = false

    if (/^\d$/.test(event.key)) {
      goTo(index + 1)
    }
  }, [goTo, index])

  const handleBlur = React.useCallback(() => {
    keyDownRef.current = false
    changedRef.current = false
  }, [])

  const handleTap = React.useCallback((event: React.SyntheticEvent<any, any>) => {
    inputRef.current?.focus()
    inputRef.current?.select()
    event.preventDefault()
  }, [])

  const select = React.useCallback(() => {
    if (document.activeElement !== inputRef.current) { return }
    inputRef.current?.select()
  }, [])

  const connect = React.useCallback((element: HTMLInputElement) => {
    assignRef(inputRef, element)
    assignRef(ref, element)
  }, [ref])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={[$.digit, {enabled, filled, dark}]} tabIndex={-1} onTap={handleTap}>
        <span>{value === '' ? null : '•'}</span>
        {renderInput()}
      </Tappable>
    )
  }

  function renderInput() {
    return (
      <input
        type='number'
        classNames={$.hideInput}
        disabled={!enabled}
        autoComplete='off'
        ref={connect}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onFocus={select}
        onBlur={handleBlur}
      />
    )
  }

  return render()

})

export default PinCodeField

export const digitSize = {
  width:  48,
  height: 56,
}

const useStyles = createUseStyles(theme => ({
  digit: {
    ...presets.clearInput(theme),
    ...presets.field(theme),
    ...digitSize,

    ...layout.flex.center,
    ...layout.responsive(() => ({
      fontSize:  digitSize.height / 1.6,
    })),

    overflow:   'hidden',
    textAlign:  'center',
    cursor:     'pointer',

    '&:not(.enabled)': {
      opacity: 0.6,
    },

    '& span': {
      lineHeight:   1,
      marginTop:    '0.05em',
      marginBottom: '-0.05em',
    },

    '&.filled': {
      // ...colors.overrideBackground(theme.semantic.secondary),
      // ...colors.overrideForeground(theme.colors.contrast(theme.semantic.secondary)),
    },
  },

  hideInput: {
    position: 'absolute',
    top:      -1000,
    left:     -1000,
  },
}))