import * as layout from '../../layout'
import { ComponentBranding } from '../components'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'

export default class ListBarBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ListBarBranding | null,
    defaults: Partial<BrandedComponentSpec> = {},
  ) {
    super(delegate,parent, {
      background:  'alt',
      shape:       {rounded: layout.radius.m},
      border:      'none',
      depth:       1,
      previewSize: {width: 160, height: 64},
      ...defaults,
    })

    this.variant('selected', {
      background: 'primary',
    })
  }

}