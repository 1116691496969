import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageFeedback, RatingFeedback } from '~/models'
import { HBox, Label, Slider, StarsSlider, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useChat } from '../ChatContext'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & RatingFeedback
}

const RatingFeedbackBubble = React.memo((props: Props) => {

  const {messageID, feedback} = props

  const {channel} = useChat()
  const [value, setValue] = React.useState<number | null>(null)

  const [t] = useTranslation('chat')

  const [lowerLabel, upperLabel] = React.useMemo(() => {
    if (feedback.style !== 'slider') { return [null, null] }
    if (feedback.lowerLabel != null) {
      return [feedback.lowerLabel, feedback.upperLabel]
    } else if (feedback.scale != null) {
      return t(`feedback.rating.scales.${feedback.scale}`)
    } else {
      return [null, null]
    }
  }, [feedback.upperLabel, feedback.scale, feedback.lowerLabel, feedback.style, t])

  //------
  // Callbacks

  const submit = React.useCallback(() => {
    if (messageID == null) { return }
    if (value == null) { return }

    channel?.sendMessage({
      type:    'text',
      text:    value?.toString(),
      replyTo: messageID,
    })
    setValue(null)
  }, [channel, messageID, value])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackBubble
        messageID={messageID}
        feedback={feedback}
        maySubmit={value != null}
        requestSubmit={submit}
        stretch={feedback.style === 'slider'}
        bubbleLayout={feedback.style === 'stars' && !feedback.skippable ? 'row' : 'column'}
      >
        {feedback.style === 'slider' ? (
          renderAsSlider()
        ) : (
          renderAsStars()
        )}
      </FeedbackBubble>
    )
  }

  function renderAsSlider() {
    return (
      <HBox padding={layout.padding.inline.xs}>
        {lowerLabel && renderLabel(lowerLabel)}
        <VBox flex>
          <Slider
            value={value}
            onChange={setValue}
            min={1}
            emoji={feedback.emoji ?? undefined}
            max={feedback.maxRating}
            step={1}
          />
        </VBox>
        {upperLabel && renderLabel(upperLabel)}
      </HBox>
    )
  }

  function renderAsStars() {
    return (
      <StarsSlider
        value={value}
        onChange={setValue}
        max={feedback.maxRating}
        halfStars={feedback.halfStars}
      />
    )
  }

  function renderLabel(label: string) {
    return (
      <Label tiny>
        {label}
      </Label>
    )
  }

  return render()

})

export default RatingFeedbackBubble