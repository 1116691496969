import React from 'react'
import Color from 'color'
import { isArray } from 'lodash'
import { CustomImage, isCustomImage } from '~/models'
import { AvatarProps, ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, HBox, HoverShim, Label, SVG, Tappable, TappableProps, VBox } from '~/ui/components'
import { HoverShimNode } from '~/ui/components/HoverShim'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  image?:         SVGName | CustomImage | React.ReactNode
  caption:        React.ReactNode | HoverShimNode
  detail?:        React.ReactNode | HoverShimNode
  accessory?:     React.ReactNode
  accessoryLeft?: React.ReactNode
  children?:      React.ReactNode

  onTap?:       TappableProps['onTap']
  href?:        TappableProps['href']
  target?:      TappableProps['target']
  interactive?: boolean
  selected?:    boolean

  color?:      Color
  avatar?:     Pick<AvatarProps, 'firstName' | 'lastName'>

  height?:            number
  imageBorderRadius?: number
  spacious?:          boolean
  compact?:           boolean
  classNames?:        React.ClassNamesProp
  contentClassNames?: React.ClassNamesProp
}

const ListItem = memo('ListItem', (props: Props) => {

  const {
    image,
    caption,
    detail,
    accessory,
    accessoryLeft,
    children,
    selected,
    onTap,
    href,
    target,
    interactive = onTap != null || href != null,
    spacious,
    height,
    imageBorderRadius,
    compact,
    contentClassNames,
  } = props

  const [hover, startHover, stopHover] = useBoolean()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const classNames = [
      $.listItem,
      {selected, interactive, spacious, compact},
      props.classNames,
    ]
    const style = {
      height,
    }

    if (onTap != null || href != null) {
      return (
        <Tappable classNames={classNames} style={style} onTap={onTap} href={href} target={target}>
          {renderBody()}
          {children}
        </Tappable>
      )
    } else {
      return (
        <VBox classNames={classNames} style={style}>
          {renderBody()}
          {children}
        </VBox>
      )
    }
  }

  function renderBody() {
    const gapForSize = gap[compact ? 'compact' : spacious ? 'spacious' : 'normal']

    return (
      <HBox flex='grow' classNames={[$.content, contentClassNames]} gap={gapForSize} align='stretch' onMouseEnter={startHover} onMouseLeave={stopHover}>
        {accessoryLeft && (
          <HBox>
            {accessoryLeft}
          </HBox>
        )}
        {image != null && (
          <Center>
            {renderImage()}
          </Center>
        )}
        {renderMain()}
        {accessory && (
          <HBox>
            {accessory}
          </HBox>
        )}
      </HBox>
    )
  }

  function renderImage() {
    if (image == null) { return null }

    return (
      <Center style={{overflow: 'hidden', borderRadius: imageBorderRadius}}>
        {typeof image === 'string' ? (
          <SVG name={image as SVGName} size={layout.icon.m} primary/>
        ) : isCustomImage(image) ? (
          <ImageView source={image} size={imageSize}/>
        ) : (
          image
        )}
      </Center>
    )
  }

  function renderMain() {
    return (
      <VBox flex='both'>
        <HoverShim flex='grow' data={{caption, detail}} hover={hover}>
          {current => (
            <VBox flex='grow' justify='middle'>
              {renderCaption(current.caption)}
              {renderDetails(current.detail)}
            </VBox>
          )}
        </HoverShim>
      </VBox>
    )
  }

  function renderCaption(caption: React.ReactNode) {
    return isReactText(caption) ? (
      <Label bold small={compact}>{caption}</Label>
    ) : (
      caption
    )
  }

  function renderDetails(details: React.ReactNode) {
    if (isArray(details)) {
      return (
        <HBox classNames={$.detailsRow} gap={layout.padding.inline.s}>
          {details.map((detail, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Label dim>•</Label>}
              {renderDetail(detail)}
            </React.Fragment>
          ))}
        </HBox>
      )
    } else {
      return renderDetail(details)
    }
  }

  function renderDetail(detail: React.ReactNode) {
    if (!isReactText(detail)) { return detail }

    return (
      <Label dim small markup truncate={false}>
        {detail}
      </Label>
    )
  }

  return render()

})

export default ListItem

export const gap = {
  compact:  layout.padding.inline.s,
  normal:   layout.padding.inline.m,
  spacious: layout.padding.inline.l,
}

export const paddingVertical = {
  compact:  layout.padding.inline.xs,
  normal:   layout.padding.inline.s,
  spacious: layout.padding.inline.m,
}

export const paddingHorizontal = {
  compact:  layout.padding.inline.m,
  normal:   layout.padding.inline.l,
  spacious: layout.padding.inline.xl,
}

export const imageSize       = layout.icon.xxl

const useStyles = createUseStyles(theme => ({
  listItem: {
    '&.selected, &.interactive:hover': {
      ...colors.overrideBackground(theme.bg.hover),
    },
  },

  content: {
    padding:         [paddingVertical.normal / 2, paddingHorizontal.normal],
    '&:first-child': {paddingTop: paddingVertical.normal},
    '&:last-child':  {paddingBottom: paddingVertical.normal},

    '$listItem.compact &': {
      padding:         [paddingVertical.compact / 2, paddingHorizontal.compact],
      '&:first-child': {paddingTop: paddingVertical.compact},
      '&:last-child':  {paddingBottom: paddingVertical.compact},
    },

    '$listItem.spacious &': {
      padding:         [paddingVertical.spacious / 2, paddingHorizontal.spacious],
      '&:first-child': {paddingTop: paddingVertical.spacious},
      '&:last-child':  {paddingBottom: paddingVertical.spacious},
    },
  },

  detailsRow: {
    overflow: 'hidden',
  },
}))