import * as layout from '../../layout'
import { ComponentBranding } from '../components'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'

export default class TimelineBarBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: TimelineBarBranding | null,
    defaults: Partial<BrandedComponentSpec> = {},
  ) {
    super(delegate,parent, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
      ...defaults,
    })

    this.variant('nowplaying')
  }

}