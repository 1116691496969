import React from 'react'
import documentScrollManager from './documentScrollManager'
import { useScrollManager } from './ScrollManagerContext'
import { ScrollState } from './types'

export interface Props {
  document?:   boolean
  horizontal?: boolean

  monitorStart?:    boolean
  monitorEnd?:      boolean
  monitorPosition?: boolean

  active?:   boolean
  throttle?: number

  onScrollStateChange?: (scrollState: ScrollState) => any

  children?: (props: ScrollState) => React.ReactNode
}

export default function ScrollQuery(props: Props) {

  const {
    document,
    horizontal,

    monitorStart    = true,
    monitorEnd      = true,
    monitorPosition = false,

    active = true,
    throttle = 16,

    onScrollStateChange,
  } = props

  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)

  const contextScrollManager = useScrollManager()
  const scrollManager        = document ? documentScrollManager : contextScrollManager

  //------
  // Binding

  const shouldUpdate = React.useCallback((position: number, atStart: boolean, atEnd: boolean) => {
    if (monitorPosition && position !== scrollState.position) { return true }
    if (monitorStart && atStart !== scrollState.atStart) { return true }
    if (monitorEnd && atEnd !== scrollState.atEnd) { return true }
    return false
  }, [monitorEnd, monitorPosition, monitorStart, scrollState])

  const update = React.useCallback(() => {
    const pos     = horizontal ? scrollManager.scrollLeft : scrollManager.scrollTop
    const maxPos  = horizontal ? scrollManager.maxScrollPosition.left : scrollManager.maxScrollPosition.top
    const atStart = pos <= 0
    const atEnd   = pos >= maxPos

    if (!shouldUpdate(pos, atStart, atEnd)) { return }
    const scrollState = {position: pos, atStart, atEnd}
    setScrollState(scrollState)
    if (onScrollStateChange) {
      onScrollStateChange(scrollState)
    }
  }, [horizontal, onScrollStateChange, scrollManager, shouldUpdate])

  const bindScroll = React.useCallback(() => {
    scrollManager.addScrollListener(update, {throttle})
  }, [scrollManager, throttle, update])

  const unbindScroll = React.useCallback(() => {
    scrollManager.removeScrollListener(update)
  }, [scrollManager, update])

  //------
  // Rendering / lifecycle

  React.useEffect(() => {
    if (!active) { return }

    scrollManager.addScrollListener(update, {throttle})
    update()

    return () => {
      scrollManager.removeScrollListener(update)
    }
  }, [active, bindScroll, scrollManager, throttle, unbindScroll, update])

  if (props.children == null) {
    return null
  } else {
    return (
      <>
        {props.children(scrollState)}
      </>
    )
  }

}