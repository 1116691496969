import socket from 'socket.io-react'
import { NewsService, NewsServiceOptions } from './news'
import { register } from './support'

export class NewsStore {

  public newsService(options: NewsServiceOptions): NewsService {
    return new NewsService(socket, options)
  }

}

const newsStore = register(new NewsStore())
export default newsStore