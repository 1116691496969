import React from 'react'
import { Media } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import PostAttachmentButton from './PostAttachmentButton'

export interface Props {
  media: Media[]
  requestRemoveMedia?: (media: Media) => any
}

const PostAttachmentList = memo('PostAttachmentList', (props: Props) => {

  const {media, requestRemoveMedia} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.s}>
        {media.map(media => (
          <PostAttachmentButton
            key={media.id}
            media={media}
            requestRemove={requestRemoveMedia}
          />
        ))}
      </VBox>
    )
  }

  return render()

})

export default PostAttachmentList