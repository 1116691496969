import { Database } from 'mobx-document'
import authenticationStore from './authenticationStore'
import { ParticipantDocument } from './participants'
import ParticipantsEndpoint from './participants/ParticipantsEndpoint'
import searchStore from './searchStore'
import { register } from './support'

export class ParticipantsStore {

  public readonly participants = new Database<ParticipantDocument>({
    getDocument:   participant => new ParticipantDocument(participant.id, {initialData: participant}),
    emptyDocument: id => new ParticipantDocument(id),
    getID:         participant => participant.id,
  })

  public init() {
    authenticationStore.on('logout', this.onLogOut)
    searchStore.registerEndpoint('participants', new ParticipantsEndpoint(this.participants, {}))
  }

  private onLogOut = () => {
    this.participants.clear()
  }

}

const participantsStore = register(new ParticipantsStore())
export default participantsStore