import React from 'react'
import I18n from 'i18next'
import { isFunction } from 'lodash'
import { memo } from '~/ui/component'
import {
  ClearButton,
  HBox,
  Label,
  ModalDialog,
  Panel,
  PushButton,
  SVG,
  TextBlock,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, shadows, useStyling, useTheme } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import hostRef from './hostRef'
import { MessageBoxButtonProps, MessageBoxOptions, MessageBoxProps } from './types'

const _MessageBox = <T extends Primitive>(props: MessageBoxProps<T>) => {

  const {colors} = useStyling()
  const {
    icon,
    iconColor = icon?.includes('warning') ? colors.semantic.warning : undefined,
    title,
    message,
    children,
    buttons = [{caption: I18n.t('buttons:ok')}] as Array<MessageBoxButtonProps<T>>,
    resolve,
    cancelResult,
  } = props

  const [isOpen, , close] = useBoolean(true)

  //------
  // Callbacks

  const resolveAndClose = React.useCallback((result: T) => {
    resolve(result)
    close()
  }, [close, resolve])

  const cancel = React.useCallback(() => {
    resolve(cancelResult as T)
    close()
  }, [cancelResult, close, resolve])

  const requestPop = React.useCallback(() => {
    hostRef.current?.pop()
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ModalDialog
        open={isOpen}
        requestClose={cancel}
        onDidClose={requestPop}
        header={renderHeader()}
        autoFocus='input, textarea, select, [role="button"]:last-child, button:last-child'
      >
        {renderBody()}
        {renderButtons()}
      </ModalDialog>
    )
  }

  function renderHeader() {
    return (
      <HBox>
        <Label h2>
          {title}
        </Label>
      </HBox>
    )
  }

  function renderBody() {
    return (
      <HBox flex='both' align='top' gap={layout.padding.inline.m + 8} classNames={$.body}>
        <SVG name='logo' size={layout.icon.xxl} primary/>
        <VBox flex gap={layout.padding.m}>
          <Panel flex='both' semi={false}>
            <HBox classNames={$.message} gap={layout.padding.inline.m} align='top'>
              {icon != null && (
                <SVG
                  classNames={$.icon}
                  name={icon}
                  color={iconColor?.alpha(0.5)}
                  size={layout.icon.xl}
                />
              )}
              {isReactText(message) ? (
                <TextBlock classNames={$.messageText} flex small bold markup>
                  {message}
                </TextBlock>
              ) : isFunction(message) ? (
                message(resolveAndClose)
              ) : (
                message
              )}
            </HBox>
          </Panel>
          {children}
        </VBox>
        <div classNames={$.arrow}/>
      </HBox>
    )
  }

  function renderButtons() {
    return (
      <div classNames={$.buttons}>
        {buttons.map(renderButton)}
      </div>
    )
  }

  function renderButton(button: MessageBoxButtonProps<T>) {
    return (
      <MessageBoxButton
        key={button.result?.toString() ?? ''}
        {...button}
        onTap={resolveAndClose}
      />
    )
  }

  return render()

}

export const _MessageBoxButton = <T extends Primitive | null>(props: MessageBoxButtonProps<T> & {onTap: (result: T) => any}) => {

  const {
    onTap,
    result,
    clear,
    destructive,
    ...rest
  } = props

  const theme = useTheme()
  const color = destructive ? theme.semantic.negative : undefined

  function render() {
    if (clear) {
      return (
        <ClearButton
          onTap={tap}
          color={color}
          {...rest}
        />
      )
    } else {
      return (
        <PushButton
          onTap={tap}
          color={color}
          {...rest}
        />
      )
    }
  }

  const tap = React.useCallback(() => {
    onTap(result)
  }, [onTap, result])

  return render()

}

const MessageBoxStatic = {
  show<T extends Primitive>(props: MessageBoxOptions<T>): Promise<T> {
    return new Promise(resolve => {
      if (hostRef.current == null) {
        console.error("MessageBox: No host registered")
      }
      hostRef.current?.push(
        <MessageBox
          {...props}
          resolve={resolve}
        />,
      )
    })
  },
}

const MessageBox       = memo('MessageBox', _MessageBox) as typeof _MessageBox
const MessageBoxButton = memo('MessageBoxButton', _MessageBoxButton) as typeof _MessageBoxButton

Object.assign(MessageBox, MessageBoxStatic)
export default MessageBox as typeof MessageBox & typeof MessageBoxStatic

const useStyles = createUseStyles(theme => ({
  body: {
    position: 'relative',
    width:    560,
    maxWidth: '100%',

    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.m[size]],
    })),
  },

  arrow: {
    position: 'absolute',
    top:      layout.icon.xxl.height / 2 + 4,
    left:     layout.icon.xxl.width - 8 + layout.padding.inline.m,

    width:  0,
    height: 0,
    border: [8, 'solid', 'transparent'],
    borderRightColor: theme.colors.bg.light.alt,
    filter: `drop-shadow(-1px 1px 1px ${shadows.shadowColor.alpha(0.1)})`,
  },

  message: {
    position: 'relative',
    padding:  [layout.padding.inline.m, layout.padding.inline.l],
  },

  icon: {
    position: 'absolute',
    top:      layout.padding.inline.m,
    right:    layout.padding.inline.m,
  },

  messageText: {
    position: 'relative',
  },

  buttons: {
    borderBottomLeftRadius:  layout.radius.m,
    borderBottomRightRadius: layout.radius.m,

    textAlign: 'center',

    ...layout.responsive(size => ({
      ...size === 'desktop' ? layout.row(layout.padding.inline.m) : layout.column(0, 'center'),
      ...size === 'desktop' ? {justifyContent: 'flex-end'} : {},
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
}))