import React from 'react'
import { ElementConnect } from 'react-dnd'
import { isPlainObject } from 'lodash'

export function useElementConnect(upstream?: ElementConnect): [ElementConnect, () => HTMLElement | null] {
  const ref = React.useRef<HTMLElement | null>(null)

  const connect = React.useCallback((element: HTMLElement | null) => {
    ref.current = element
    return upstream?.(element) ?? null
  }, [upstream])

  return [connect, () => ref.current]
}

export function isRef(arg: any): arg is React.RefObject<any> {
  return isPlainObject(arg) && 'current' in arg
}