import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import socket from 'socket.io-react'
import { SearchParams } from '../search'
import MenuPageItemDocument from './MenuPageItemDocument'

export default class MenuPageItemsEndpoint extends Endpoint<MenuPageItemDocument> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  protected async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    const response = await socket.fetch('pages:menu-items', {
      ...params,
      ...options,
    })
    if (!response.ok) { return null }

    return {
      data: response.body.data,
      meta: response.body.meta,
    }
  }
}