import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Media, Post } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  HBox,
  Label,
  PushButton,
  SelectField,
  Spinner,
  SwitchField,
  Uploader,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormField } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import { MediaUploaderState } from '../media/MediaUploader'
import { useNewsService } from './NewsContext'
import PostAttachmentList from './PostAttachmentList'
import PostFormMediaGallery from './PostFormMediaGallery'
import PostFormModel from './PostFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  post: Post | null
}

const PostForm = observer('PostForm', (props: Props) => {

  const {post, open, requestClose} = props

  const [t] = useTranslation('news')

  const service = useNewsService()

  const model = React.useMemo(
    () => new PostFormModel(service, post),
    [post, service],
  )

  const isTablet  = useMediaQuery({minWidth: screenMinWidths.tablet})

  const title = React.useMemo(
    () => post == null ? t('post_form.title.add') : t('post_form.title.edit'),
    [post, t],
  )

  //------
  // Feeds

  const feeds     = service?.feeds
  const openFeeds = service?.openFeeds

  const feedChoices = React.useMemo(
    (): Choice[] => (feeds ?? []).map(feed => ({
      value:   feed.id,
      caption: feed.title,
      enabled: openFeeds.find(it => it.id === feed.id) ? true : false,
    })),
    [feeds, openFeeds],
  )

  const showFeedField = post == null && feeds.length > 1

  const mayNotify = React.useMemo(() => {
    if (post != null || model.feed == null) { return false }

    const feed = openFeeds.find(it => it.id === model.feed)
    return feed?.moderator
  }, [model.feed, openFeeds, post])

  //------
  // Media

  const uploaderRef = React.useRef<Uploader>(null)
  const media = model.media

  const galleryMedia = React.useMemo(
    () => media.filter(it => /^(image|video)\//.test(it.contentType)),
    [media],
  )

  const attachmentMedia = React.useMemo(
    () => media.filter(it => !/^(image|video)\//.test(it.contentType)),
    [media],
  )

  const addMedia = React.useCallback((media: Media) => {
    model.addMedia(media)
  }, [model])

  const removeMedia = React.useCallback((media: Media) => {
    model.removeMedia(media)
  }, [model])

  const browseForMedia = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <FormDialog
        open={open}
        model={model}
        requestClose={requestClose}
        title={title}
        headerRight='$close'
        footer={renderFooter()}
        width={630}
      >
        <MediaUploader
          accept='*'
          renderContent={renderForm}
          onUploadComplete={addMedia}
          uploaderRef={uploaderRef}
          enabled={model.mayAddMedia}
          maxFiles={4 - model.media.length}
          noClick
        />
      </FormDialog>
    )
  }

  function renderForm(state: MediaUploaderState) {
    return (
      <VBox gap={layout.padding.s}>
        {renderFields()}
        {renderMedia(state)}
      </VBox>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        {showFeedField && (
          <FormField name='feed' required caption={t('post_form.feed.caption')}>
            {bind => (
              <SelectField
                {...bind}
                placeholder={t('post_form.feed.placeholder')}
                choices={feedChoices}
              />
            )}
          </FormField>
        )}

        <FormField name='body'>
          {bind => (
            <RichTextField
              {...bind}
              scope='block'
              placeholder={t('post_form.body')}
              height={160}
              allowedNodes={['em', 'strong']}
              autoFocus
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderMedia(state: MediaUploaderState) {
    if (model == null) { return null }

    return (
      <VBox classNames={$.media} gap={layout.padding.s}>
        {galleryMedia.length > 0 && (
          <PostFormMediaGallery
            media={galleryMedia}
            requestRemoveMedia={removeMedia}
          />
        )}
        {attachmentMedia.length > 0 && (
          <PostAttachmentList
            media={attachmentMedia}
            requestRemoveMedia={removeMedia}
          />
        )}
        {state.isDragActive && renderDropZone()}
        {state.uploadedFiles.length > 0 && renderUploading()}
        {model.mayAddMedia && (
          <HBox>
            <ClearButton
              icon='image'
              caption={t('post_form.add_media')}
              onTap={browseForMedia}
            />
          </HBox>
        )}
      </VBox>
    )
  }

  function renderDropZone() {
    return (
      <Center classNames={$.dropZone}>
        <Label small dim>
          {t('post_form.drop_zone')}
        </Label>
      </Center>
    )
  }

  function renderUploading() {
    return (
      <Center classNames={$.uploading}>
        <Spinner size={12}/>
      </Center>
    )
  }

  function renderFooter() {
    if (post != null) { return null }

    return (
      <VBox gap={layout.padding.s} flex>
        {isTablet ? (
          <HBox justify='space-between' gap={layout.padding.s}>
            {mayNotify ? renderNotify() : <div/>}
            {renderSubmit()}
          </HBox>
        ) : (
          <VBox gap={layout.padding.s}>
            {mayNotify && renderNotify()}
            <HBox justify='right'>
              {renderSubmit()}
            </HBox>
          </VBox>
        )}
      </VBox>
    )
  }

  function renderNotify() {
    if (!mayNotify) { return null }
    return (
      <VBox flex='shrink'>
        <FormField name='notify'>
          {bind => (
            <SwitchField
              {...bind}
              label={t('post_form.notify')}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderSubmit() {
    return (
      <PushButton
        caption={t('post_form.add')}
        submit
      />
    )
  }

  return render()

})

export default PostForm

const useStyles = createUseStyles(theme => ({
  dropZone: {
    height:       layout.barHeight.m,
    border:       [2, 'dashed', theme.fg.dimmer],
    borderRadius: layout.radius.l,
    padding:      [0, layout.padding.inline.l],
  },

  media: {
    padding: [0, layout.radius.m / 2],
  },

  uploading: {
    height: layout.barHeight.m,
  },
}))