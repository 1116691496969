import React from 'react'
import { ContentPage } from '~/models'
import { memo } from '~/ui/component'
import { Markdown, Scroller } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  page: ContentPage
}

const ContentPageView = memo('ContentPageView', (props: Props) => {

  const {page} = props

  const body = page.body

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Scroller contentClassNames={$.scrollerContent}>
        <Markdown classNames={$.body}>
          {body}
        </Markdown>
      </Scroller>
    )
  }

  return render()

})

export default ContentPageView

const useStyles = createUseStyles({
  scrollerContent: {
    padding: layout.padding.inline.l,
    alignItems: 'center',
  },

  body: {
    width: layout.contentWidth,
    maxWidth: '100%',
  },
})