import React from 'react'
import { isPendingMessage, Message, MessageListItem } from '~/models'
import { useLightbox } from '~/ui/components/lightbox'
import { useObjectURL } from '~/ui/hooks'
import { createUseStyles } from '~/ui/styling'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'image'}
  inlineReply: MessageListItem | null
}

const ImageBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const pendingImageURL = useObjectURL(isPendingMessage(message) ? message.image.binary : null)
  const imageURL        = pendingImageURL ?? (message instanceof Message ? message.asImage.image.url : null)

  const {show} = useLightbox()

  const showInLightbox = React.useCallback(() => {
    if (imageURL == null) { return null }
    show({
      type: 'image',
      image: {type: 'remote', url: imageURL},
      alt: message.caption ?? null,
    })
  }, [imageURL, message.caption, show])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChatBubble message={message} inlineReply={inlineReply} onTap={showInLightbox}>
        {imageURL != null && (
          <img
            classNames={$.image}
            src={imageURL}
            alt=''
          />
        )}
      </ChatBubble>
    )
  }

  return render()

})

export default ImageBubble

const useStyles = createUseStyles(theme => ({
  image: {
    width:     theme.guide.chat.bubble.maxWidth,
    height:    theme.guide.chat.bubble.maxWidth / 16 * 9,
    objectFit: 'cover',
  },
}))