import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import Toast from 'react-toast'
import { observer } from '~/ui/component'
import { PushButton, TextBlock, VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean, useQueryParam } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import AuthForm from '../AuthForm'
import AuthResetForm from './AuthResetForm'
import AuthResetFormModel from './AuthResetFormModel'

export interface Props extends RouteComponentProps {}

const AuthResetScreen = observer('AuthResetScreen', (props: Props) => {

  const {history} = props
  const [token]   = useQueryParam('token', 'string')
  const [source]  = useQueryParam('source', 'string')

  const [t] = useTranslation('auth')

  const [noticeShown, showNotice] = useBoolean()

  const isMobileDevice = /android/i.test(navigator.userAgent) || /ipad|iphone|ipod/i.test(navigator.userAgent)

  //------
  // Form model

  const formModel = React.useMemo(() => {
    return new AuthResetFormModel(token)
  }, [token])

  const openApp = React.useCallback(() => {
    window.location.replace(`${source}://`)
  }, [source])

  const goBack = React.useCallback(() => {
    if (source === 'web') {
      history.replace('/login')
    } else if (isMobileDevice) {
      showNotice()
      openApp()
    } else {
      showNotice()
    }
  }, [history, isMobileDevice, source, showNotice, openApp])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      Toast.show({
        type: 'success',
        ...t('auth_reset.success'),
      })
      goBack()
    }
  }, [goBack, t])

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return noticeShown ? renderNotice() : renderForm()
  }

  function renderForm() {
    return (
      <AuthResetForm
        model={formModel}
        afterSubmit={afterSubmit}
        onBackTap={source !== 'web' ? undefined : goBack}
      />
    )
  }

  function renderNotice() {
    return (
      <AuthForm>
        <VBox gap={layout.padding.s}>
          <TextBlock align='center' small children={t('auth_reset.notice.detail')}/>
          {isMobileDevice && (
            <PushButton
              onTap={openApp}
              icon='iphone'
              caption={t('auth_reset.notice.cta')}
            />
          )}
        </VBox>
      </AuthForm>
    )
  }

  return render()

})

export default AuthResetScreen