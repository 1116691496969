import React from 'react'
import { MessageListItem } from '~/models'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { createUseStyles, fonts, layout, useTheme } from '~/ui/styling'
import { findLinks } from '~/ui/util'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'text'}
  inlineReply: MessageListItem | null
}

const TextBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const text  = message.text ?? ''
  const links = findLinks(text)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChatBubble padded message={message} inlineReply={inlineReply} link={message.link}>
        <HBox gap={layout.padding.inline.s}>
          <VBox flex='shrink'>
            <Label classNames={$.label} truncate={false} markup links={links}>
              {text}
            </Label>
          </VBox>
          {message.link != null && <LinkIcon/>}
        </HBox>
      </ChatBubble>
    )
  }

  return render()

})

export default TextBubble

const LinkIcon = () => {
  const theme = useTheme()

  return (
    <SVG
      name='chevron-right'
      size={layout.icon.s}
      primary={!theme.isDark}
    />
  )
}

const useStyles = createUseStyles(theme => ({
  label: {
    ...fonts.responsiveFontStyle(theme.fonts.chat),
    overflow:     'hidden',
    textOverflow: 'ellipsis',
  },
}))