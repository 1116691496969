import React from 'react'
import { memo } from '~/ui/component'
import {
  ClearButton,
  ClearButtonProps,
  HBox,
  HBoxProps,
  Panel,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'

export interface Props extends Omit<HBoxProps, 'style'> {
  style?:    ToolbarStyle
  children?: React.ReactNode
}

export type ToolbarStyle = 'panel' | 'primary'

export interface ToolbarButtonProps extends Omit<ClearButtonProps, 'caption'> {
  caption?: string | {on: string, off: string}
  active?:  boolean
}

export interface ToolbarSpacerProps {
  flex?: VBoxProps['flex']
}

const Toolbar = memo('Toolbar', (props: Props) => {

  const {style, children, justify='space-between', ...rest} = props

  const $ = useStyles()

  if (style === 'panel') {
    return (
      <Panel semi={false}>
        <HBox justify={justify} {...rest} classNames={[$.panelToolbar, rest.classNames]}>
          {children}
        </HBox>
      </Panel>
    )
  } else {
    return (
      <ThemeProvider contrast='primary'>
        <HBox {...rest} justify={justify} classNames={[$.primaryToolbar, rest.classNames]}>
          {children}
        </HBox>
      </ThemeProvider>
    )
  }

})

const ToolbarButton = memo('Toolbar.Button', (props: ToolbarButtonProps) => {

  const {
    active,
    caption: props_caption,
    ...rest
  } = props

  const caption = React.useMemo(() => {
    if (props_caption == null) { return null }

    if (typeof props_caption === 'string') {
      return props_caption
    } else {
      return active ? props_caption.on : props_caption.off
    }
  }, [active, props_caption])

  const $ = useStyles()

  function render() {
    return (
      <ClearButton
        classNames={[$.toolbarButton, {active}]}
        caption={caption}
        padding='both'
        {...rest}
      />
    )
  }

  return render()

})

const ToolbarSpacer = memo('Toolbar.Spacer', (props: ToolbarSpacerProps) => {
  const {
    flex = true,
  } = props

  return (
    <VBox flex={flex}/>
  )
})


Object.assign(Toolbar, {
  Button: ToolbarButton,
  Spacer: ToolbarSpacer,
})

export default Toolbar as typeof Toolbar & {
  Button: typeof ToolbarButton
  Spacer: typeof ToolbarSpacer
}

export { ToolbarButton }

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  panelToolbar: {
    height,
    padding: [0, layout.padding.inline.s],
  },

  primaryToolbar: {
    height,
    padding:    [0, layout.padding.inline.m],
    background: theme.semantic.primary,
  },

  toolbarButton: {
    '&.active': {
      background: theme.inverse.bg.subtle,
      boxShadow:  ['inset', 1, 1, 1, shadows.shadowColor.alpha(0.2)],
    },
  },
}))