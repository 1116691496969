import React from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useTimer } from 'react-timer'
import Color from 'color'
import { isPromise } from 'ytil'
import { memo } from '~/ui/component'
import {
  BrandedComponent,
  Center,
  HBox,
  Label,
  Spinner,
  SVG,
  Tappable,
  TappableProps,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { ThemeProviderProps } from '../styling/ThemeContext'

export interface Props extends Omit<TappableProps, keyof React.HTMLAttributes<any>> {
  icon?:     SVGName | null
  caption?:  string
  iconSide?: 'left' | 'right'

  submit?:  boolean
  working?: boolean

  small?:   boolean
  hanging?: boolean

  backgroundColor?: Color
  color?:           Color
  dim?:             boolean

  classNames?:      React.ClassNamesProp
}

const PushButton = memo('PushButton', (props: Props) => {

  const {guide, colors} = useStyling()
  const $        = useStyles()

  const {
    icon,
    iconSide = 'left',
    caption,
    working: props_working,
    small,
    hanging,
    backgroundColor,
    color: props_color,
    dim     = false,
    submit  = false,
    enabled:    props_enabled = true,
    classNames: props_classNames,
    flex,
    onTap,
    ...rest
  } = props

  const isDark = backgroundColor != null
    ? backgroundColor.isDark()
    : guide.web.pushButton.background().theme === 'dark'

  const color = props_color ?? (!dim
    ? (isDark ? colors.fg.light.normal : colors.semantic.primary)
    : (isDark ? colors.fg.light.dim : colors.fg.dark.dim))

  //------
  // Tap

  const timer = useTimer()
  const [state_working, startWorking, stopWorking] = useBoolean()
  const [state_spinnerVisible, showSpinner, hideSpinner] = useBoolean()

  const working        = props_working ?? state_working
  const enabled        = props_enabled && !working
  const spinnerVisible = props_working ?? state_spinnerVisible

  const tap = React.useCallback((event: React.SyntheticEvent<any>) => {
    const retval = onTap?.(event)
    if (!isPromise(retval)) { return }

    startWorking()
    timer.setTimeout(showSpinner, 200)

    retval.finally(() => {
      if (timer.isDisposed) { return }
      timer.clearAll()

      unstable_batchedUpdates(() => {
        stopWorking()
        hideSpinner()
      })
    })
  }, [hideSpinner, onTap, showSpinner, startWorking, stopWorking, timer])

  //------
  // Rendering

  function render() {
    const classNames = [
      $.PushButton,
      {iconOnly: caption == null && icon != null},
      {withLabel: caption != null},
      {small, normal: !small},
      {hanging, disabled: !enabled},
      {working},
      {dim},
      props_classNames,
    ]

    const style = {
      backgroundColor: backgroundColor?.string(),
    }

    if (submit) {
      return (
        <BrandedComponent classNames={classNames} style={style} flex={flex} branding={guide.web.pushButton} height={small ? height.small : height.normal}>
          <button type='submit' disabled={!enabled}>
            {renderContent()}
            {renderSpinner()}
          </button>
        </BrandedComponent>
      )
    } else {
      return (
        <BrandedComponent classNames={classNames} style={style} flex={flex} branding={guide.web.pushButton} height={small ? height.small : height.normal}>
          <Tappable enabled={enabled} onTap={tap} {...rest} noFeedback>
            {renderContent()}
            {renderSpinner()}
          </Tappable>
        </BrandedComponent>
      )
    }
  }

  function renderContent() {
    return (
      <ThemeProvider {...themeProps}>
        <HBox flex classNames={[$.content, {spinnerVisible}]} gap={layout.padding.inline.m} justify='center'>
          {iconSide === 'left' && renderIcon()}
          {renderCaption()}
          {iconSide === 'right' && renderIcon()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderSpinner() {
    if (!spinnerVisible) { return }

    return (
      <Center flex classNames={$.spinner}>
        <Spinner
          color={color?.alpha(0.6)}
          size={small ? 8 : 12}
        />
      </Center>
    )
  }

  const themeProps = React.useMemo((): ThemeProviderProps => {
    return {
      overrides: {fg: {normal: color}},
    }
  }, [color])

  function renderIcon() {
    if (icon == null) { return null }

    const size = small ? layout.icon.xs : layout.icon.s
    return <SVG name={icon} size={size} color={color}/>
  }

  function renderCaption() {
    if (caption == null) { return null }

    return (
      <VBox flex='shrink'>
        <Label caption align='center' color={color}>
          {props.caption}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default PushButton

export const height = {
  normal: 40,
  small:  32,
}
export const minWidth = {
  normal: 96,
  small:  40,
}

const useStyles = createUseStyles(theme => ({
  PushButton: {
    position: 'relative',

    '& > *': {
      border:   'none',
    },

    '&:not(.disabled) > *': {
      cursor: 'pointer',
    },

    '& > button': {
      ...layout.flex.column,
      background: 'none',
    },

    '&.normal > *': {
      height:   height.normal,
      minWidth: height.normal,
      padding:  [0, layout.padding.inline.l],
    },

    '&.small > *': {
      height:   height.small,
      minWidth: height.small,
      padding:  [0, layout.padding.inline.l],
    },

    '& > ::after': {
      content:       '""',
      pointerEvents: 'none',
      ...layout.overlay,
    },

    '&, & > *, & > ::after': {
      borderRadius: 1000,
    },

    '&.hanging': {
      '&, & > ::after': {
        borderRadius:         layout.radius.l,
        borderTopLeftRadius:  0,
        borderTopRightRadius: 0,
      },

      '&.normal > *': {height: height.normal - 4},
      '&.small > *':  {height: height.small - 4},
    },

    '&:focus-within > *': {
      outline:   'none',
      boxShadow: [
        shadows.depth(theme.guide.web.pushButton.resolve('depth') ?? 1),
        shadows.focus.bold(theme),
      ],
    },

    '&.disabled': {
      opacity: 0.6,
    },

    '&.iconOnly > *': {
      padding: 0,
    },

    '&:not(.disabled):hover > ::after': {
      background: theme.colors.bg.light.hover,
    },

    '&:not(.disabled):active > ::after': {
      background: theme.colors.bg.light.active,
    },

    '&.withLabel > *': {
      minWidth: minWidth.normal,
    },
    '&.withLabel.small > *': {
      minWidth: minWidth.small,
    },
  },

  content: {
    '&.spinnerVisible': {
      opacity: 0,
    },
  },

  spinner: {
    ...layout.overlay,
  },
}))