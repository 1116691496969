export type Transition = [string, string, string]

export function transitionBuilder(duration: number) {
  return (property: string | string[], timing: string = 'ease-in-out') => {
    return transition(property, duration, timing)
  }
}

export function transition(property: string | string[], duration: number, timing: string = 'ease-in-out'): Transition[] {
  const properties = Array.isArray(property) ? property : [property]

  if (properties.length === 0) {
    return [['', `${duration}ms`, timing]]
  } else {
    return properties.map((prop: string): Transition => [prop, `${duration}ms`, timing])
  }
}

export const durations = {
  fast:      100,
  short:     200,
  medium:    400,
  long:      600,
  extraLong: 1000,
}

export const timings = {
  popIn:   'cubic-bezier(0.150, 1, 0.575, 1)',
  popOut:  'ease-out',
  explode: 'cubic-bezier(0.150, 1, 0.85, 1)',
}

export const transitions = {
  fast:   transitionBuilder(durations.fast),
  short:  transitionBuilder(durations.short),
  medium: transitionBuilder(durations.medium),
  long:   transitionBuilder(durations.long),
}

export function fade(duration: number) {
  const transition = transitionBuilder(duration)

  return {
    '&-enter': {
      opacity:   0,
    },
    '&-enter-active': {
      opacity:   1,
      transition: transition(['opacity']),
    },
    '&-exit': {
      opacity:   1,
    },
    '&-exit-active': {
      opacity:   0,
      transition: transition(['opacity']),
    },
  }
}

export function pop(duration: number) {
  const transition = transitionBuilder(duration)

  return {
    willChange: ['transform', 'opacity'],

    '&-enter': {
      opacity:   0,
      transform: 'scale(0.3)',
    },
    '&-enter-active': {
      opacity:   1,
      transform: 'scale(1)',
      transition: transition(['opacity', 'transform'], timings.popIn),
    },
    '&-exit': {
      opacity:   1,
      transform: 'scale(1)',
    },
    '&-exit-active': {
      opacity:   0,
      transform: 'scale(0.3)',
      transition: transition(['opacity', 'transform'], timings.popOut),
    },
  }
}

export function slideFromLeft(duration: number) {
  const transition = transitionBuilder(duration)

  return {
    willChange: ['transform'],

    '&-enter': {
      transform: 'translateX(-100%)',
    },
    '&-enter-active': {
      transform: 'translateX(0)',
      transition: transition(['transform']),
    },
    '&-exit': {
      transform: 'translateX(0)',
    },
    '&-exit-active': {
      transform: 'translateX(-100%)',
      transition: transition(['transform']),
    },
  }
}

export function slideFromRight(duration: number) {
  const transition = transitionBuilder(duration)

  return {
    willChange: ['transform'],

    '&-enter': {
      transform: 'translateX(100%)',
    },
    '&-enter-active': {
      transform: 'translateX(0)',
      transition: transition(['transform']),
    },
    '&-exit': {
      transform: 'translateX(0)',
    },
    '&-exit-active': {
      transform: 'translateX(100%)',
      transition: transition(['transform']),
    },
  }
}

export function slideAndFade(duration: number, distance: number | string, timingFunction: string = 'ease-in-out', enterName: string = 'enter', exitName: string = 'exit') {
  const transition = transitionBuilder(duration)

  return {
    willChange: ['transform', 'opacity'],

    [`&-${enterName}`]: {
      opacity:   0,
      transform: `translateX(${typeof distance === 'number' ? (-distance + 'px') : ('-' + distance)})`,
    },
    [`&-${enterName}-active`]: {
      opacity:   1,
      transform: 'translateX(0)',
      transition: transition(['opacity', 'transform'], timingFunction),
    },
    [`&-${exitName}`]: {
      opacity:   1,
      transform: 'translateX(0)',
    },
    [`&-${exitName}-active`]: {
      opacity:   0,
      transform: `translateX(${typeof distance === 'number' ? (-distance + 'px') : ('-' + distance)})`,
      transition: transition(['opacity', 'transform'], timingFunction),
    },
  }
}

export function fadeFromAbove(duration: number, distance: number | string, enterName: string = 'enter', exitName: string = 'exit') {
  const transition = transitionBuilder(duration)

  return {
    willChange: ['transform', 'opacity'],

    [`&-${enterName}`]: {
      opacity:   0,
      transform: `translateY(${typeof distance === 'number' ? (-distance + 'px') : ('-' + distance)})`,
    },
    [`&-${enterName}-active`]: {
      opacity:   1,
      transform: 'translateY(0)',
      transition: transition(['opacity', 'transform'], timings.popIn),
    },
    [`&-${exitName}`]: {
      opacity:   1,
      transform: 'translateY(0)',
    },
    [`&-${exitName}-active`]: {
      opacity:   0,
      transform: `translateY(${typeof distance === 'number' ? (-distance + 'px') : ('-' + distance)})`,
      transition: transition(['opacity', 'transform'], timings.popOut),
    },
  }
}

export function explode(duration: number, enterName: string = 'enter', exitName: string = 'exit') {
  const explodeTransition = transitionBuilder(duration)

  return {
    willChange: ['transform', 'opacity'],

    [`&-${enterName}`]: {
      opacity:   0,
      transform: `scale(1.2)`,
    },
    [`&-${enterName}-active`]: {
      opacity:   1,
      transform: 'scale(1)',
      transition: explodeTransition(['opacity', 'transform'], timings.explode),
    },
    [`&-${exitName}`]: {
      opacity:   1,
      transform: 'scale(1)',
    },
    [`&-${exitName}-active`]: {
      opacity:   0,
      transform: `scale(1.2)`,
      transition: [
        ...explodeTransition('opacity'),
        ...transition('transform', duration * 1.5, timings.explode),
      ],
    },
  }
}