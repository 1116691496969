import React from 'react'
import { memo } from '~/ui/component'
import { TextField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { StringField } from '../types'

export interface Props {
  field: StringField
  bind:  FormFieldBindProps<string>
}

const StringAllowance = memo('StringAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <TextField
        {...bind}
        maxLength={field.maxLength}
        multiline={field.multiline}
        placeholder={field.placeholder}
      />
    )
  }

  return render()

})

export default StringAllowance