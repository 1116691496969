import React from 'react'
import { Participant } from '~/models'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ListBar, SVG } from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props {
  participant: Participant
  accessory?:  React.ReactNode
}

const ParticipantBar = memo('ParticipantBar', (props: Props) => {

  const {participant, accessory} = props

  const href = React.useMemo(
    () => `/participants/-/${participant.id}`,
    [participant.id],
  )

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={renderAvatar()}
        caption={participant.name}
        detail={participant.headline}
        accessory={accessory ?? <SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        href={href}
      />
    )
  }

  function renderAvatar() {
    return (
      <Avatar
        source={participant.photoURL}
        firstName={participant.firstName}
        lastName={participant.lastName}
        size={avatarSize}
      />
    )
  }

  return render()

})

export const avatarSize = {
  width:  40,
  height: 40,
}

export default ParticipantBar