import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { combinedFetchStatus } from 'mobx-document'
import { ContentPage, IndexPage, MenuPage } from '~/models'
import { infoStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { Breadcrumb } from '../navigation/types'
import ContentPageView from './content/ContentPageView'
import IndexPageView from './index/IndexPageView'
import MenuPageView from './menu/MenuPageView'

export type Props = RouteComponentProps

const InfoScreen = observer('InfoScreen', (props: RouteComponentProps) => {

  const path = props.match.url.replace(/^\/-\//, '').split('/')
  const slug = path[path.length -1] ?? ''

  const [t] = useTranslation('info')

  const document    = infoStore.pages.document(slug)
  const fetchStatus = document.fetchStatus
  const page        = document.data

  const title = page?.title

  const fetch = React.useCallback(async () => {
    await document.fetch()
  }, [document])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  //------
  // Breadcrumbs

  const breadCrumbDocuments = React.useMemo(
    () => path.map(slug => infoStore.pages.document(slug)),
    [path],
  )

  React.useEffect(() => {
    for (const document of breadCrumbDocuments) {
      document.fetchIfNeeded()
    }
  }, [breadCrumbDocuments])

  const breadCrumbsFetchStatus = combinedFetchStatus(...breadCrumbDocuments.map(it => it.fetchStatus))

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    if (page == null) { return [] }

    if (breadCrumbsFetchStatus === 'fetching' || path.length < 2) {
      return [{
        caption: page.title,
      }]
    }

    return breadCrumbDocuments.map((document, index): Breadcrumb => ({
      caption: document.data?.title ?? document.id,
      href:    document.id === slug ? null : `/-/${path.slice(1, index + 1).join('/')}`,
    }))
  }, [page, path, slug, breadCrumbDocuments, breadCrumbsFetchStatus])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <AppLayoutConfig
          title={title}
          fetchStatus={fetchStatus}
          defaultBreadcrumbs={breadcrumbs}
        />
        {renderContent()}
      </VBox>
    )
  }

  function renderContent() {
    if (page == null || fetchStatus === 'fetching') {
      return renderEmpty()
    }

    if (page instanceof MenuPage) {
      return <MenuPageView page={page}/>
    } else if (page instanceof IndexPage) {
      return <IndexPageView page={page}/>
    } else if (page instanceof ContentPage) {
      return <ContentPageView page={page}/>
    } else {
      console.warn(`Unknown page type: ${page.constructor.name}`)
      return null
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t('page_not_found', {slug})}
        flex
      />
    )
  }

  return render()

})

export default InfoScreen