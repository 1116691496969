import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { borderRadius } from '../Popup'

export interface Props {
  icon?:     SVGName
  caption?:  string
  children?: React.ReactNode
}

const PopupMenuHeader = memo('PopupMenuHeader', (props: Props) => {

  const {icon, caption, children} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider contrast='secondary'>
        <VBox classNames={$.popupMenuHeader} gap={layout.padding.s}>
          {renderHeader()}
          {children}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderHeader() {
    if (icon == null && caption == null) { return null }

    return (
      <HBox flex='grow' gap={layout.padding.inline.m}>
        {icon != null && (
          <SVG
            name={icon}
            size={layout.icon.m}
          />
        )}
        {caption != null && (
          <Label caption>
            {caption}
          </Label>
        )}
      </HBox>
    )
  }

  return render()

})

export default PopupMenuHeader

export const minHeight = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  popupMenuHeader: {
    minHeight:    minHeight,
    borderRadius: [borderRadius, borderRadius, 0, 0],
    padding:      [layout.padding.inline.m, layout.padding.inline.l],
    background:   theme.semantic.secondary,
  },
}))