import { camelCase } from 'lodash'
import { DateTime } from 'luxon'
import { Model } from '../Model'

export abstract class BeWizrModel extends Model {

  // BeWizr models don't have these attributes
  public createdAt = DateTime.now()
  public updatedAt = DateTime.now()

  public constructor(raw: Record<string, any>) {
    super(convertJsonToCamelCase({
      ...raw,
      id: raw.id?.toString(),
    }))
  }

}

function convertJsonToCamelCase(json: any): any {
  if (Array.isArray(json)) {
      return json.map(convertJsonToCamelCase)
  } else if (json != null && typeof json === 'object') {
    return Object.keys(json).reduce((result, key) => ({
      ...result,
      [camelCase(key)]: convertJsonToCamelCase(json[key]),
    }), {})
  }
  return json
}