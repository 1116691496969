import React from 'react'
import {
  DropzoneOptions,
  DropzoneRef,
  DropzoneState as RDZDropzoneState,
  useDropzone,
} from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { component } from '~/ui/component'
import { Center, flexStyle, Label, SVG, VBox, VBoxProps } from '~/ui/components'
import { assignRef, releaseRef } from '~/ui/hooks'
import { colors, createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props extends Omit<DropzoneOptions, 'onDrop'> {
  enabled?:    boolean
  onDrop:      (files: File[]) => any

  showIdleHint?: boolean
  dropHint?:     string
  activeHint?:   string
  acceptHint?:   string
  rejectHint?:   string

  renderContent: (state: DropzoneState) => React.ReactNode

  dropzoneRef?: React.Ref<DropzoneRef>
  flex?:        VBoxProps['flex']
  classNames?:  React.ClassNamesProp
}

export interface DropzoneState extends Omit<RDZDropzoneState, 'getInputProps' | 'getRootProps' | 'open'> {
  renderDropHint: () => React.ReactNode
}

const Dropzone = component('Dropzone', (props: Props) => {

  const {
    enabled = true,
    accept,
    onDrop,
    showIdleHint = true,
    dropHint,
    activeHint,
    acceptHint,
    rejectHint,
    dropzoneRef,
    classNames,
    noClick = false,
    noKeyboard = false,
    noDrag,
    renderContent,
    onDropRejected,
    flex,
    ...options
  } = props

  const [t] = useTranslation('dropzone')

  const handleDropRejected = React.useCallback(() => {
    Toast.show({
      title: rejectHint ?? t('drop_reject'),
      type: 'error',
    })
  }, [t, rejectHint])

  const {getRootProps, getInputProps, open, ...state} = useDropzone({
    ...options,
    accept:         accept !== '*' ? accept : undefined,
    onDrop:         onDrop,
    noClick:        !enabled || noClick,
    noKeyboard:     !enabled || noKeyboard,
    noDrag:         !enabled,
    onDropRejected: onDropRejected ?? handleDropRejected,
  })

  const theme = useTheme()

  const showDropHint = state.isDragActive || showIdleHint

  React.useEffect(() => {
    if (!dropzoneRef) { return }

    assignRef(dropzoneRef, {open})
    return () => releaseRef(dropzoneRef)
  }, [dropzoneRef, open])


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={[$.Dropzone, {noClick, noKeyboard}, classNames]} style={flexStyle(flex)}>
        {renderDropzone()}
      </div>
    )
  }

  function renderDropzone() {
    return (
      <VBox flex='both' classNames={classNames} {...getRootProps()} tabIndex={undefined}>
        {renderDropzoneContent()}
        <input {...getInputProps()}/>
      </VBox>
    )
  }

  function renderDropzoneContent() {
    return renderContent({
      ...state,
      renderDropHint,
    })
  }

  const renderDropHint = React.useCallback(() => {
    if (!showDropHint) { return null }

    const statusClassNames = {
      active: state.isDragActive,
      accept: state.isDragAccept,
      reject: state.isDragReject,
    }

    const color =
      state.isDragAccept ? theme.semantic.positive :
      state.isDragReject ? theme.semantic.negative :
      theme.isDark ? theme.fg.normal : theme.semantic.primary

    return (
      <VBox justify='middle' classNames={[$.dropHint, statusClassNames]} gap={layout.padding.s}>
        <Center>
          <SVG name='upload' size={layout.icon.l} color={color}/>
        </Center>

        <Label caption small color={color} align='center' truncate={false}>
          {
            state.isDragReject ? (rejectHint ?? t('drop_reject')) :
            state.isDragAccept ? (acceptHint ?? t('drop_accept')) :
            state.isDragActive ? (activeHint ?? t(['drop_active', 'drop_hint'])) :
            dropHint ?? t('drop_hint')
          }
        </Label>
      </VBox>
    )
  }, [$.dropHint, acceptHint, activeHint, dropHint, rejectHint, showDropHint, state.isDragAccept, state.isDragActive, state.isDragReject, t, theme.fg.normal, theme.isDark, theme.semantic.negative, theme.semantic.positive, theme.semantic.primary])

  return render()

})

export default Dropzone

const useStyles = createUseStyles(theme => ({
  Dropzone: {
    ...layout.flex.column,
    flex:     [1, 1, 'auto'],
    position: 'relative',

    '&:not(.noClick)': {
      cursor: 'pointer',
    },
  },

  tappable: {
    position: 'relative',
  },

  dropHint: {
    ...layout.overlay,
    borderRadius: layout.radius.m,
    padding:      layout.padding.inline.l,

    '&.active': {
      '&, &:hover': {
        ...colors.overrideBackground(theme.semantic.focus.alpha(0.6)),
        ...colors.overrideForeground(theme.colors.contrast(theme.semantic.focus)),
      },
    },

    '&.accept': {
      '&, &:hover': {
        ...colors.overrideBackground(theme.semantic.positive.alpha(0.6)),
        ...colors.overrideForeground(theme.colors.contrast(theme.semantic.positive)),
      },
    },

    '&.reject': {
      '&, &:hover': {
        ...colors.overrideBackground(theme.semantic.negative.alpha(0.6)),
        ...colors.overrideForeground(theme.colors.contrast(theme.semantic.negative)),
      },
    },
  },
}))
