import React from 'react'
import { observer as mobx_observer } from 'mobx-react'

export function component<T extends React.ComponentType<any>>(name: string, Component: T): T {
  Object.assign(Component, {displayName: name})
  return Component
}

export function forwardRef<T, P = {}>(name: string, render: React.ForwardRefRenderFunction<T, P>): React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<T>> {
  const Component = React.forwardRef(render)
  Object.assign(Component, {displayName: name})
  return Component
}

export function observer<T extends React.ComponentType<any>>(name: string, Component: T): T {
  Object.assign(Component, {displayName: name})
  return mobx_observer(Component)
}

export function memo<T extends React.ComponentType<any>>(
  name:           string,
  Component:      T,
  propsAreEqual?: (prevProps: Readonly<React.ComponentProps<T>>, nextProps: Readonly<React.ComponentProps<T>>) => boolean,
) {
  Object.assign(Component, {displayName: name})
  return React.memo(Component, propsAreEqual)
}

export function createSuperContainer<P extends {} = {}>(...wrappers: SuperContainerWrapper<P>[]): React.ComponentType<SuperContainerProps<P>> {
  return (props: SuperContainerProps<P>) => {
    const {children, ...params} = props
    let current = children
    for (const wrapper of [...wrappers].reverse()) {
      current = wrapper({children: current}, params as P)
    }
    return React.createElement(React.Fragment, {}, current)
  }
}

export type SuperContainerInherentProps = {children?: React.ReactNode}
export type SuperContainerProps<P extends {}> = P & SuperContainerInherentProps
export type SuperContainerWrapper<P extends {}> = (props: {children?: React.ReactNode}, params: P) => React.ReactNode