import React from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'
import { profileStore } from '~/stores'
import { observer } from '~/ui/component'
import { ActionStateLabel, PushButton } from '~/ui/components'
import { register, WidgetProps } from './registry'

export interface Params {
  group:  string
  signup: string
}

export type Props = WidgetProps<Params>

const SignupWidget = observer('SignupWidget', (props: Props) => {

  const [t, i18n] = useTranslation('widgets')

  const {params} = props

  const signup = React.useMemo(
    () => profileStore.groupSignup(params.signup, params.group),
    [params.group, params.signup],
  )

  const signupTranslations = signup.translations

  const translations = React.useMemo(() => merge(
    {},
    t('signup'),
    signupTranslations?.[i18n.language] ?? signupTranslations?.en,
  ), [i18n.language, signupTranslations, t])

  //------
  // Actions

  const check = React.useCallback(() => {
    signup.check()
  }, [signup])

  React.useEffect(check, [check])

  const signUp = React.useCallback(() => {
    signup.signUp()
  }, [signup])

  const signOut = React.useCallback(() => {
    signup.signOut()
  }, [signup])

  const {status, signedUp, closed, placesLeft, maxSignups} = signup
  const showCapacity = !signedUp && placesLeft != null

  const label = React.useMemo(() => {
    if (showCapacity) {
      return (
        placesLeft === 0 ? translations.places_left_0 :
        placesLeft === 1 ? translations.places_left_1 :
        translations.places_left_n
      ).replace(/\{\{\}\}/g, placesLeft)
    } else if (signedUp) {
      return translations.signed_up
    } else if (closed) {
      return translations.closed
    } else if (maxSignups) {
      return translations.max_signups
    } else {
      return null
    }
  }, [showCapacity, signedUp, closed, maxSignups, placesLeft, translations.places_left_0, translations.places_left_1, translations.places_left_n, translations.signed_up, translations.closed, translations.max_signups])

  const caption = signedUp ? translations.sign_out : translations.sign_up
  const onTap   = signedUp ? signOut : signUp
  const enabled = signedUp || (!closed && !maxSignups && (placesLeft == null || placesLeft > 0))

  //------
  // Rendering

  function render() {
    if (label != null) {
      return (
        <ActionStateLabel
          label={label}
          caption={caption}
          active={signedUp ?? false}
          working={status !== 'ready'}
          enabled={enabled}
          onTap={onTap}
        />
      )
    } else {
      return (
        <PushButton
          caption={caption}
          working={status !== 'ready'}
          enabled={enabled}
          onTap={onTap}
        />
      )
    }
  }

  return render()

})

register('signup', SignupWidget)