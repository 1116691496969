import { isPlainObject, set } from 'lodash'
import { DateTime } from 'luxon'
import { StringStream } from 'unicode'

export const WIDGET_REGEXP = /^\s*\$\[([-_a-z][-_a-z0-9]*)\]\([\s\n]*([\w\W]*?)[\s\n]*\)/

export function formatWidgetParams(params: Record<string, any>, options: FormatWidgetParamsOptions = {}) {
  const {multiline = false, indent = 0} = options
  const indentation = Array(indent + 1).join(' ')

  const lines: string[] = []

  const formatParam = (name: string, value: any) => {
    if (isPlainObject(value)) {
      for (const [n, v] of Object.entries(value)) {
        formatParam(`${name}.${n}`, v)
      }
    } else if (value instanceof DateTime) {
      lines.push(`${indentation}${name}=${JSON.stringify(value.toISO())}`)
    } else {
      lines.push(`${indentation}${name}=${JSON.stringify(value)}`)
    }
  }

  for (const [n, v] of Object.entries(params)) {
    formatParam(n, v)
  }

  return lines.join(multiline ? '\n' : ' ')
}

export function parseWidgetParams(string: string) {
  const stream = new StringStream(string)
  const params: Record<string, any> = {}

  while (!stream.eos) {
    stream.eatWhile(/[\s\n]/)
    stream.markStart()
    if (!stream.eatWhile(/[_.\w\d]/)) { break }

    const name = stream.current()
    stream.eatWhile(/[\s\n]/)
    if (!stream.eat('=')) { break }

    stream.eatWhile(/[\s\n]/)

    let value: any
    if (stream.eat('"')) {
      stream.markStart()
      stream.eatUntil('"')
      value = stream.current()
      stream.eat('"')
    } else if (stream.eat('\'')) {
      stream.markStart()
      stream.eatUntil('\'')
      value = stream.current()
      stream.eat('\'')
    } else {
      stream.markStart()
      if (!stream.eatUntil(/[\s\n,]/)) {
        stream.eatUntilEos()
      }
      value = stream.current().replace(/\n$/, '')
    }

    stream.eat(/,/)

    try {
      if (/^\d{4}-\d{2}-\d{2}/.test(value)) {
        set(params, name, DateTime.fromISO(value, {zone: 'utc'}))
      } else {
        set(params, name, JSON.parse(value))
      }
    } catch {
      set(params, name, value)
    }
  }

  return params
}

export interface FormatWidgetParamsOptions {
  multiline?: boolean
  indent?:    number
}