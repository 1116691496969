import './notifications'
import React from 'react'
import Helmet from 'react-helmet'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { ClockContainer } from '~/socket/useClock'
import { appStore, authenticationStore, PreviewStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Center, LightboxHost, Spinner, ToastItem, VBox } from '~/ui/components'
import { MessageBoxHost } from '~/ui/components/message-box'
import { BrandingGuide, createUseStyles, layout } from '~/ui/styling'
import { createSuperContainer } from '../component'
import { StylingProvider } from '../styling/StylingContext'
import ConfigDialog from './config/ConfigDialog'
import DrawerContainer from './drawer/DrawerContainer'
import createUseGlobalStyles from './globalStyles'
import AppNavigationContainer from './navigation/AppNavigationContainer'
import AppNavigator from './navigator/AppNavigator'
import NewsContainer from './news/NewsContainer'

const App = observer('App', () => {

  const [t] = useTranslation('app')

  React.useEffect(() => {
    appStore.init()
    return () => { appStore.deinit() }
  }, [])

  //------
  // Branding

  const branding = projectStore.branding ?? appStore.app?.branding

  const brandingGuide = React.useMemo(() => {
    let guide: BrandingGuide
    if (branding != null) {
      guide = BrandingGuide.deserialize(branding)
    } else {
      guide = new BrandingGuide()
    }
    return guide
  }, [branding])

  //------
  // Hotkeys

  const closeConfigDialog = React.useCallback(() => {
    appStore.closeConfigDialog()
  }, [])

  useHotkey('Cmd+Alt+Shift+C', () => {
    appStore.openConfigDialog()
  })

  const loading = !appStore.ready

  //------
  // Rendering

  const $ = useStyles()

  const title = React.useMemo(() => {
    if (loading) { return null }
    return brandingGuide.text('title') ?? t('title')
  }, [loading, brandingGuide, t])

  const Container = React.useMemo(() => createSuperContainer<{brandingGuide: BrandingGuide}>(
    props => <ClockContainer {...props}/>,
    props => <LightboxHost {...props}/>,
    (props, params) => <StylingProvider {...props} guide={params.brandingGuide}/>,
    props => <AppNavigationContainer {...props}/>,
    props => <DrawerContainer {...props}/>,
    props => <NewsContainer {...props}/>,
  ), [])

  function render() {
    return (
      <Container brandingGuide={brandingGuide}>
        <VBox flex='grow'>
          <Helmet
            defaultTitle={title ?? ''}
            titleTemplate={`%s | ${title}`}
          />
          <GlobalStyles/>

          {loading ? (
            renderLoading()
          ) : PreviewStore.previewing && authenticationStore.loginStatus === 'logging-in' ? (
            renderLoading()
          ) : (
            <AppNavigator/>
          )}
          <MessageBoxHost/>

          <Toast
            className={$.toast}
            ItemComponent={ToastItem}
            duration={layout.z.toast}
          />

          <ConfigDialog
            open={appStore.configDialogOpen}
            requestClose={closeConfigDialog}
          />
        </VBox>
      </Container>
    )
  }

  function renderLoading() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

const GlobalStyles = memo('GlobalStyles', () => {
  useGlobalStyles()
  return null
})

export default App

const useGlobalStyles = createUseGlobalStyles()
const useStyles = createUseStyles({
  toast: {
    ...layout.overlay,
    position:      'fixed',
    zIndex:        layout.z.toast,
    pointerEvents: 'none',
  },
})