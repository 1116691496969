import { isEqual } from 'lodash'

export function objectContains(left: AnyObject, right: AnyObject, equals: (a: any, b: any) => boolean = isEqual): boolean {
  for (const key of Object.keys(right)) {
    if (!equals(left[key], right[key])) {
      return false
    }
  }

  return true
}

export function objectEquals(left: AnyObject, right: AnyObject, equals: (a: any, b: any) => boolean = isEqual): boolean {
  if (Object.keys(left).length !== Object.keys(right).length) { return false }

  for (const key of Object.keys(left)) {
    if (!equals(left[key], right[key])) {
      return false
    }
  }

  return true
}