import React from 'react'
import Color from 'color'
import { flatMap } from 'ytil'
import { memo } from '~/ui/component'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import { applyLinks, LinkRange } from '~/ui/util'
import { flexStyle, VBoxProps } from '../layout'
import { parseMarkup } from './markup'

export interface Props {
  children:    React.ReactNode
  classNames?: React.ClassNamesProp
  color?:      Color

  h1?:      boolean
  h2?:      boolean
  h3?:      boolean
  caption?: boolean
  small?:   boolean
  tiny?:    boolean
  light?:   boolean
  noFont?:  boolean

  dim?:      boolean
  dimmer?:   boolean
  truncate?: boolean

  italic?:   boolean
  bold?:     boolean
  mono?:     boolean
  hand?:     boolean
  link?:     boolean
  links?:    LinkRange[]

  markup?:          boolean
  emphasisColor?:   Color
  variables?:       boolean
  box?:             boolean
  convertNewlines?: boolean

  align?: TextAlign
  tag?:   'div' | 'span'

  flex?:  VBoxProps['flex']
  style?: React.CSSProperties
}

export type TextAlign = 'left' | 'center' | 'right' | 'justify'

const Label = memo('Label', (props: Props) => {

  const {
    dim,
    dimmer,
    link,
    bold,
    light,
    italic,
    color,
    truncate        = true,
    align           = 'left',
    markup          = false,
    variables       = false,
    box             = false,
    convertNewlines = true,
    emphasisColor,
    links,
    tag = 'div',
    style,
    flex,
    children,
  } = props

  const styles = React.useMemo(
    () => ({...style, color: color?.string(), ...flexStyle(flex)}),
    [color, flex, style],
  )

  const text  = React.useMemo(
    () => React.Children.toArray(children).join(' '),
    [children],
  )


  const lines = React.useMemo(
    () => convertNewlines ? text.split('\n') : [text.replace(/\n+/g, ' ')],
    [convertNewlines, text],
  )
  const lineNodes = React.useMemo((): Array<string | React.ReactNode[]> => {
    if (links != null) {
      const nodes: Array<string | React.ReactNode[]> = []
      let lineOffset = 0
      for (const line of lines) {
        const linksForThisLine = links
          // eslint-disable-next-line no-loop-func
          .filter(it => it.offset >= lineOffset && it.offset < lineOffset + line.length)
          // eslint-disable-next-line no-loop-func
          .map(link => ({
            ...link,
            offset: link.offset - lineOffset,
            length: Math.min(link.length, line.length - (link.offset - lineOffset)),
          }))

        nodes.push(applyLinks(line, linksForThisLine))
        lineOffset += line.length + 1
      }
      return nodes
    } else {
      return lines
    }
  }, [lines, links])

  //------
  // Rendering

  const $ = useStyles(emphasisColor)

  function render() {
    const classNames = [
      $.label,
      {dim, dimmer, link},
      {bold, light, italic},
      {small: props.small, tiny: props.tiny},
      {truncate, box},
    ]

    if (props.h1) {
      classNames.push($.h1)
    } else if (props.h2) {
      classNames.push($.h2)
    } else if (props.h3) {
      classNames.push($.h3)
    } else if (props.hand) {
      classNames.push(props.tiny ? $.handTiny : props.small ? $.handSmall : $.hand)
    } else if (props.mono) {
      classNames.push(props.tiny ? $.monoTiny : props.small ? $.monoSmall : $.mono)
    } else if (props.caption) {
      classNames.push(props.tiny ? $.captionTiny : props.small ? $.captionSmall : $.caption)
    } else if (!props.noFont) {
      classNames.push(props.tiny ? $.tiny : props.small ? $.small : $.normal)
    }

    classNames.push(`align-${align}`)
    classNames.push(props.classNames)

    const Component = tag

    return (
      <Component classNames={classNames} style={styles}>
        {lineNodes.map((line, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br/>}
            {(markup || variables) ? (
              renderWithMarkup(line)
            ) : (
              line
            )}
          </React.Fragment>
        ))}
      </Component>
    )
  }

  function renderWithMarkup(line: string | React.ReactNode[]) {
    const nodes = typeof line === 'string' ? [line] : line

    return flatMap(nodes, node => {
      if (typeof node === 'string') {
        return parseMarkup(node, $, variables)
      } else {
        return node
      }
    })
  }

  return render()

})

export default Label

//------
// Styles

const useStyles = createUseStyles(theme => ({
  label: (emphasisColor: Color | undefined) => ({
    lineHeight: '1.2em',
    overflowWrap: 'break-word',
    position: 'relative',

    '&:not($mono):not($monoSmall):not($monoTiny)': {
      top: '0.1em',
    },

    color:      theme.fg.normal,
    '&.link:not(.dim):not(.dimmer)':   {color: theme.fg.link},
    '&.dim':    {color: theme.fg.dim},
    '&.dimmer': {color: theme.fg.dimmer},

    '& a[href]': {
      textDecoration: 'none',
      fontWeight:     500,
      color:          theme.fg.link,

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '&.align-left': {
      textAlign: 'left',
    },
    '&.align-center': {
      textAlign: 'center',
    },
    '&.align-right': {
      textAlign: 'right',
    },
    '&.align-justify': {
      textAlign: 'justify',
    },

    '&.light':   {fontWeight: 400},

    '&.bold, strong': {
      fontWeight: 700,
    },
    '&.italic, .italic': {
      fontStyle:    'italic',
      paddingRight: '0.2em', // The italic font causes overflow
    },
    '&.em, em': {
      fontWeight: 700,
      fontStyle:  'normal',
      color:      emphasisColor ?? theme.fg.highlight,
    },
    '& var': {
      extend:    'box',
      fontStyle: 'normal',
      ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
    },
    '&.small var': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.monoSmall),
    },
    '& .mono': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
    },
    '&.small .mono': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.monoSmall),
    },
    '&.tiny .mono': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.monoTiny),
    },

    '&.link:hover':   {
      textDecoration: 'underline',
    },

    '&.truncate': {
      whiteSpace:   'nowrap',
      overflow:     'hidden',
      textOverflow: 'ellipsis',
      minWidth:     0,
    },

    '&.box, & .box': {
      extend: 'box',
    },
  }),

  box: {
    display:      'inline-block',
    background:   theme.bg.subtle,
    boxShadow:    ['inset', 0, 0, 0, 1, theme.fg.dimmer],
    borderRadius: layout.radius.s,
    padding:      layout.padding.inline.xs,
  },

  h1:           {...fonts.responsiveFontStyle(theme.guide.fonts.h1)},
  h2:           {...fonts.responsiveFontStyle(theme.guide.fonts.h2)},
  h3:           {...fonts.responsiveFontStyle(theme.guide.fonts.h3)},
  caption:      {...fonts.responsiveFontStyle(theme.guide.fonts.caption)},
  captionSmall: {...fonts.responsiveFontStyle(theme.guide.fonts.captionSmall)},
  captionTiny:  {...fonts.responsiveFontStyle(theme.guide.fonts.captionTiny)},
  small:        {...fonts.responsiveFontStyle(theme.guide.fonts.small)},
  tiny:         {...fonts.responsiveFontStyle(theme.guide.fonts.tiny)},
  mono:         {...fonts.responsiveFontStyle(theme.guide.fonts.mono)},
  monoSmall:    {...fonts.responsiveFontStyle(theme.guide.fonts.monoSmall)},
  monoTiny:     {...fonts.responsiveFontStyle(theme.guide.fonts.monoTiny)},
  hand:         {...fonts.responsiveFontStyle(theme.guide.fonts.hand)},
  handSmall:    {...fonts.responsiveFontStyle(theme.guide.fonts.handSmall)},
  handTiny:     {...fonts.responsiveFontStyle(theme.guide.fonts.handTiny)},
  normal:       {...fonts.responsiveFontStyle(theme.guide.fonts.body)},
}))