import React from 'react'
import { useTranslation } from 'react-i18next'
import { combinedFetchStatus } from '~/lib/mobx-document'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import ChallengesTileGrid from './ChallengesTileGrid'

const ChallengesScreen = observer('ChallengesScreen', () => {

  const openEndpoint      = challengesStore.openChallenges
  const completedEndpoint = challengesStore.completedChallenges
  const resultsEndpoint   = challengesStore.resultsChallenges

  const hasOpenChallenges      = openEndpoint.data.length > 0
  const hasCompletedChallenges = completedEndpoint.data.length > 0
  const hasResultsChallenges   = resultsEndpoint.data.length > 0

  const fetch = React.useCallback(() => {
    openEndpoint.fetch({limit: 6})
    completedEndpoint.fetch({limit: 6})
    resultsEndpoint.fetch({limit: 6})
  }, [completedEndpoint, openEndpoint, resultsEndpoint])

  const totalOpen      = openEndpoint.meta?.total ?? 0
  const totalCompleted = completedEndpoint.meta?.total ?? 0
  const totalResults   = resultsEndpoint.meta?.total ?? 0

  const openFetchStatus = openEndpoint.fetchStatus
  const completedFetchStatus = completedEndpoint.fetchStatus
  const resultsFetchStatus = resultsEndpoint.fetchStatus

  const fetchStatus = combinedFetchStatus(openFetchStatus, completedFetchStatus, resultsFetchStatus)

  React.useEffect(fetch, [fetch])

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  function render() {
    return(
      <>
        <AppLayoutConfig
          title={t('title')}
        />
        {!hasOpenChallenges && !hasCompletedChallenges ? renderEmpty() : renderGrid()}
      </>
    )
  }

  function renderGrid() {
    return (
      <Scroller>
        <VBox gap={layout.padding.l} padding={layout.padding.inline.l}>
          {hasOpenChallenges && (
            <ChallengesTileGrid
              caption={t('title.open')}
              endpoint={openEndpoint}
              detailHref={totalOpen > 6 ? '/challenges/open' : undefined}
            />
          )}
          {hasCompletedChallenges && (
            <ChallengesTileGrid
              caption={t('title.completed')}
              endpoint={completedEndpoint}
              detailHref={totalCompleted > 6 ? '/challenges/completed' : undefined}
            />
          )}
          {hasResultsChallenges && (
            <ChallengesTileGrid
              caption={t('title.results')}
              endpoint={resultsEndpoint}
              detailHref={totalResults > 6 ? '/challenges/results' : undefined}
            />
          )}
        </VBox>
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t('empty.all')}
        flex
      />
    )
  }

  return render()

})

export default ChallengesScreen