import React from 'react'
import { useTranslation } from 'react-i18next'
import { Answer, AnswerResult, Feedback, feedbackValueToCaption } from '~/models'
import { memo } from '~/ui/component'
import { VBox, Label } from '~/ui/components'
import { layout } from '~/ui/styling'
import { AnswerOutcomeRenderUtil } from '../../tasks/ChallengeTaskAnswerOutcome'

export interface Props {
  feedback:       Feedback
  answer:         Answer
  result:         AnswerResult | null
  correctAnswers: any[]
  renderUtil:     AnswerOutcomeRenderUtil
}

const TextAnswerOutcome = memo('TextAnswerOutcome', (props: Props) => {

  const {answer, feedback, renderUtil, correctAnswers} = props

  const [t] = useTranslation('challenges')

  const caption   = feedbackValueToCaption(feedback, answer.answer)
  const multiline = feedback.type === 'text' && feedback.multiline

  //------
  // Rendering

  function render() {
    return (
      <VBox>
        {renderMyAnswer()}
        {renderCorrectAnswer()}
      </VBox>
    )
  }

  function renderMyAnswer() {
    return (
      <>
        {renderUtil.renderCaption(t('task.your_answer'), true)}
        <VBox padding={layout.padding.inline.m}>
          <Label h2={!multiline} align={multiline ? 'left' : 'center'}>
            {caption}
          </Label>
        </VBox>
      </>
    )
  }

  function renderCorrectAnswer() {
    if (correctAnswers.length === 0) { return null }

    return (
      <>
        {renderUtil.renderLine()}
        {renderUtil.renderCaption(t('task.correct_answer'))}
        <VBox padding={layout.padding.inline.m}>
          <Label h2={!multiline} align={multiline ? 'left' : 'center'}>
            {correctAnswers[0]}
          </Label>
        </VBox>
      </>
    )
  }

  return render()

})

export default TextAnswerOutcome