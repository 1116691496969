import React from 'react'
import { memo } from '~/ui/component'
import { SVG, Tooltip, TooltipProps } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props extends Omit<TooltipProps, 'children'> {
  tooltipRef?: React.Ref<Tooltip>
}

const InfoIcon = memo('InfoIcon', (props: Props) => {

  const {targetClassNames, tooltipRef, ...rest} = props

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <Tooltip targetClassNames={[$.infoTooltip, targetClassNames]} ref={tooltipRef} {...rest}>
        <SVG
          name='info'
          size={layout.icon.s}
          color={colors.semantic.secondary.alpha(0.6)}
        />
      </Tooltip>
    )
  }

  return render()

})

export default InfoIcon

const useStyles = createUseStyles({
  infoTooltip: {
    cursor: 'help',
  },
})