import React from 'react'
import { useTranslation } from 'react-i18next'
import { Connection, Participant } from '~/models'
import { connectionsStore, profileStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Label, Notice, PushButton, SVG, VBox } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  participant: Participant
}

const ParticipantConnection = observer('ParticipantConnection', (props: Props) => {

  const {participant} = props
  const isMe          = participant.id === profileStore.participantID
  const connection    = isMe ? null : connectionsStore.getConnectionWith(participant.id)

  const {colors} = useStyling()
  const [t] = useTranslation('connections')

  //------
  // Actions

  const sendConnectionRequest = React.useCallback(() => {
    connectionsStore.sendRequest(participant.id)
  }, [participant.id])

  const rescindRequest = React.useCallback(() => {
    if (connection == null) { return }
    connectionsStore.sendRescind(connection.id)
  }, [connection])

  const confirmConnection = React.useCallback(() => {
    if (connection == null) { return }
    connectionsStore.confirmConnection(connection.id, {})
  }, [connection])

  const rejectConnection = React.useCallback(() => {
    if (connection == null) { return }
    connectionsStore.rejectConnection(connection.id)
  }, [connection])

  const disconnect = React.useCallback(() => {
    connectionsStore.disconnect(participant.id)
  }, [participant.id])

  //------
  // Rendering

  function render() {
    if (isMe) { return null }

    return (
      <VBox>
        {connection == null || connection.status === 'rejected' ? (
          renderRequestButton()
        ) : connection.confirmedAt != null ? (
          renderConfirmedConnection(connection)
        ) : connection.incoming ? (
          renderIncomingRequest(connection)
        ) : (
          renderPendingConnection(connection)
        )}
      </VBox>
    )
  }

  function renderRequestButton() {
    return (
      <PushButton
        icon='handshake'
        caption={t('actions.request', participant)}
        onTap={sendConnectionRequest}
      />
    )
  }

  function renderIncomingRequest(connection: Connection) {
    return (
      <VBox gap={layout.padding.inline.m}>
        <Label caption small align='center' dim>
          {t('incoming', {...participant, when: connection.createdAt.toRelative()})}
        </Label>
        <HBox gap={layout.padding.inline.m}>
          <VBox flex>
            <PushButton
              icon='check'
              caption={t('actions.confirm', participant)}
              color={colors.semantic.positive}
              onTap={confirmConnection}
            />
          </VBox>
          <VBox flex>
            <PushButton
              icon='cross'
              caption={t('actions.reject', participant)}
              color={colors.semantic.negative}
              onTap={rejectConnection}
            />
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderPendingConnection(connection: Connection) {
    return (
      <VBox gap={layout.padding.inline.m}>
        <Label caption small align='center' dim>
          {t('requested', {...participant, when: connection.createdAt.toRelative()})}
        </Label>
        <HBox gap={layout.padding.inline.m}>
          <VBox flex>
            <PushButton
              icon='handshake'
              caption={t('actions.remind', participant)}
              onTap={sendConnectionRequest}
            />
          </VBox>
          <ClearButton
            icon='cross'
            caption={t('actions.rescind', participant)}
            onTap={rescindRequest}
            dim
          />
        </HBox>
      </VBox>
    )
  }

  function renderConfirmedConnection(connection: Connection) {
    return (
      <Notice padding={layout.padding.inline.l}>
        <HBox gap={layout.padding.inline.m}>
          <SVG name='handshake' size={layout.icon.m}/>
          <Label flex caption>
            {t('connected', {...participant, when: connection.createdAt.toRelative()})}
          </Label>
          <ClearButton
            icon='cross'
            caption={t('actions.disconnect', participant)}
            onTap={disconnect}
            padding='none'
            dim
          />
        </HBox>
      </Notice>
    )
  }

  return render()

})

export default ParticipantConnection