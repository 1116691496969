import React from 'react'
import I18next from 'i18next'
import { isPendingMessage, Link, MessageListItem } from '~/models'
import { LinkTappable } from '~/ui/app/navigation'
import { BrandedComponent, Label, Tappable, TappableProps, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from '../ChatContext'

export interface Props {
  message:     MessageListItem
  inlineReply: MessageListItem | null

  link?:  Link | null
  onTap?: TappableProps['onTap']

  padded?:   boolean
  children?: React.ReactNode
}

const ChatBubble = (props: Props) => {

  const {
    children,
    message,
    inlineReply,
    padded,
    link,
    onTap,
  } = props

  const {
    chat,
    brandingForMessage,
    onMessageTap,
  } = useChat()

  const mine        = isPendingMessage(message) || message.from === chat?.sender?.id
  const interactive = link != null || onTap != null || onMessageTap != null

  const scrollToInlineReply = React.useCallback(() => {
    if (inlineReply == null) { return null }
    // list.scrollTo({key: inlineReply.id})
  }, [inlineReply])

  const handleTap = React.useMemo(() => {
    if (onMessageTap == null && onTap == null) {
      return undefined
    }

    return (event: React.SyntheticEvent<any, any>) => {
      if (onMessageTap != null) {
        onMessageTap(message)
      } else {
        onTap?.(event)
      }
    }
  }, [message, onMessageTap, onTap])

  //------
  // Rendering

  const $        = useStyles()
  const {guide}  = useStyling()
  const branding = brandingForMessage?.(message) ?? guide.chat.bubble

  const {sharpOuterCorner} = branding

  function render() {
    if (interactive) {
      return (
        <LinkTappable classNames={$.chatBubble} link={link} onTap={handleTap} noFeedback>
          {renderContent()}
        </LinkTappable>
      )
    } else {
      return (
        <VBox classNames={$.chatBubble}>
          {renderContent()}
        </VBox>
      )
    }
  }

  function renderContent() {
    return (
      <BrandedComponent classNames={[$.content, {sharpOuterCorner}]} branding={branding} variant={{own: mine}} height={guide.chat.bubble.minHeight}>
        {renderInlineReply()}
        <VBox classNames={[$.body, {padded}]}>
          {children}
        </VBox>
      </BrandedComponent>
    )
  }

  function renderInlineReply() {
    if (inlineReply == null) { return null }

    return (
      <Tappable classNames={$.inlineReply} onTap={scrollToInlineReply}>
        <Label small truncate={false}>
          {replyTextFor(inlineReply)}
        </Label>
      </Tappable>
    )
  }

  return render()

}

function replyTextFor(message: MessageListItem) {
  return I18next.t(`chat:messages.reply.${message.type}`, {message})
}

export default ChatBubble

const useStyles = createUseStyles(theme => ({
  chatBubble: {
  },

  inlineReply: {
    background:   theme.colors.bg.dark.normal.alpha(0.2),
    borderRadius: [
      theme.guide.chat.bubble.borderRadius(theme.guide.chat.bubble.minHeight) - 2,
      theme.guide.chat.bubble.borderRadius(theme.guide.chat.bubble.minHeight) - 2,
      layout.radius.s,
      layout.radius.s,
    ],
    margin:       2,
    padding:      [layout.padding.inline.m, layout.padding.inline.m],
    marginBottom: layout.padding.inline.s - layout.padding.inline.m,
  },

  content: {
    overflow: 'hidden',
    '&.sharpOuterCorner': {
      borderBottomLeftRadius: 0,
    },
  },

  body: {
    overflow:  'hidden',
    maxWidth:  theme.guide.chat.bubble.maxWidth,
    minHeight: theme.guide.chat.bubble.minHeight,

    '&.padded': {
      padding: [layout.padding.inline.m, theme.guide.chat.bubble.minHeight / 3],
    },
  },
}))