import React from 'react'
import { LightboxContent } from './types'

interface LightboxContext {
  show: (content?: LightboxContent | number) => any
  hide: () => any

  contents:    LightboxContent[]
  setContents: (contents: LightboxContent[]) => any

  selectedIndex:    number
  setSelectedIndex: (index: number) => any
}

const LightboxContext = React.createContext<LightboxContext>({
  show: () => void 0,
  hide: () => void 0,

  contents:    [],
  setContents: () => void 0,

  selectedIndex:    -1,
  setSelectedIndex: () => void 0,
})

export default LightboxContext

export function useLightbox() {
  return React.useContext(LightboxContext)
}