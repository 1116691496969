import React from 'react'
import { observer } from '~/ui/component'
import { ClearButton, HBox, HBoxProps, PushButton, VBox, VBoxProps } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { autoFocusFirst } from '~/ui/util'
import { useForm } from './FormContext'
import FormErrors from './FormErrors'
import FormFieldHeader from './FormFieldHeader'
import { useFormField } from './hooks/useFormField'
import { InlineFormContext } from './InlineFormContext'

export interface Props<T> {
  name:      string
  required?: boolean
  caption?:  string

  flex?:    VBoxProps['flex']
  align?:   VBoxProps['align']

  renderView: (value: T) => React.ReactNode
  renderEdit: (bind: BindProps<T>) => React.ReactNode
}

export interface BindProps<T> extends FormFieldBindProps<T> {
  onCommit: (value: T, event: React.SyntheticEvent) => any
}

const _InlineFormField = <T extends {} = any>(props: Props<T>) => {

  const {name, required, flex, align, renderView, renderEdit} = props
  const [value, onChange, errors, form] = useFormField<T>(name)

  const caption = props.caption ?? null

  const {submit, submitting} = useForm()

  const bind = React.useMemo((): BindProps<T> => ({
    value,
    onChange,
    invalid:     errors.length > 0,
    enabled:     form.submitting ? false : undefined,
    onCommit:    (value, event) => {
      event.preventDefault()
      submit()
    },
    errors,
    form,
  }), [errors, form, onChange, submit, value])

  const inlineForm   = React.useContext(InlineFormContext)

  const [editingState, startEditingState, stopEditingState] = useBoolean()
  const editing = inlineForm
    ? inlineForm.editingField === name
    : editingState

  const startEditing = React.useCallback(() => {
    if (inlineForm) {
      inlineForm.startEditing(name)
    } else {
      startEditingState()
    }
  }, [inlineForm, name, startEditingState])

  const stopEditing = React.useCallback(() => {
    if (inlineForm) {
      inlineForm.stopEditing()
    } else {
      stopEditingState()
    }
  }, [inlineForm, stopEditingState])

  const cancel = React.useCallback(() => {
    stopEditing()
  }, [stopEditing])

  const commit = React.useCallback(() => {
    submit()
  }, [submit])

  //------
  // Autofocus

  const containerRef = React.useRef<HTMLDivElement>(null)

  const prevEditing = usePrevious(editing)

  const autoFocusFirstField = React.useCallback(() => {
    if (containerRef.current == null) { return }
    autoFocusFirst(containerRef.current, {
      select: true,
    })
  }, [])

  React.useLayoutEffect(() => {
    if (editing && !prevEditing) {
      autoFocusFirstField()
    }
  }, [autoFocusFirstField, editing, prevEditing])


  //------
  // Rendering

  function render() {
    return (
      <VBox flex={flex} gap={layout.padding.inline.xs} ref={containerRef}>
        <FormFieldHeader
          caption={caption}
          required={required}
        />
        {editing ? (
          renderEditing()
        ) : (
          renderViewing()
        )}
      </VBox>
    )
  }

  //------
  // Viewing

  const bodyFlex = React.useMemo(() => {
    return align === 'stretch' ? 'both' : 'shrink'
  }, [align])

  const rowJustify = React.useMemo((): HBoxProps['justify'] => {
    switch (align) {
      case 'left':    return 'left'
      case 'center':  return 'center'
      case 'right':   return 'right'
      case 'stretch': return 'left'
    }
  }, [align])

  function renderViewing() {
    return (
      <HBox flex={flex} justify={rowJustify} gap={layout.padding.inline.m}>
        <VBox flex={bodyFlex}>
          {renderView(value)}
        </VBox>
        {renderEditButton()}
      </HBox>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        onTap={startEditing}
        small
      />
    )
  }

  //------
  // Editing

  function renderEditing() {
    return (
      <VBox flex={flex} gap={layout.padding.inline.s}>
        {renderBody()}
        {renderErrors()}
      </VBox>
    )
  }

  function renderBody() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex>
          {renderEdit(bind)}
        </VBox>
        <HBox gap={layout.padding.inline.m}>
          {renderCommitButton()}
          {renderCancelButton()}
        </HBox>
      </HBox>
    )
  }

  function renderCommitButton() {
    return (
      <PushButton
        icon='check'
        onTap={commit}
        working={submitting}
        small
      />
    )
  }

  function renderCancelButton() {
    return (
      <ClearButton
        icon='cross'
        onTap={cancel}
        enabled={!submitting}
        small
        dim
      />
    )
  }

  function renderErrors() {
    if (errors.length === 0) { return null }

    return (
      <FormErrors errors={errors}/>
    )
  }

  return render()

}

const InlineFormField = observer('InlineFormField', _InlineFormField) as typeof _InlineFormField
export default InlineFormField