import { BrandingManifest } from '~/ui/styling'
import { Model } from './Model'
import { Translations } from './Translations'

export class ClientApp extends Model {
  public auth!: {
    emailAndPincode: boolean
    oAuthProviders:  OAuthProvider[]
  }

  public branding!: BrandingManifest
}

export interface OAuthProvider {
  name:          string
  translations:  Translations
  logo:          OAuthProviderLogo | null
}

export type OAuthProviderLogo  =
  | {type: 'svg', data: {attributes: Record<string, any>, content: string}}
  | {type: 'png', base64: string}