import BrandingGuide from './branding/BrandingGuide'
import ColorGuide, {
  BackgroundColors,
  BorderColors,
  ForegroundColors,
  SemanticColors,
} from './branding/ColorGuide'
import FontGuide from './branding/FontGuide'

export interface Theme {
  bg:       BackgroundColors
  fg:       ForegroundColors
  semantic: SemanticColors
  border:   BorderColors

  inverse: Theme
  isDark:  boolean

  guide:  BrandingGuide
  colors: ColorGuide
  fonts:  FontGuide
}

function createTheme(
  guide: BrandingGuide,
  which: 'light' | 'dark',
  inverse?: Theme,
) {
  const {colors} = guide

  const semantic = which === 'light' ? {...colors.semantic.dark} : {...colors.semantic.light}
  const bg       = which === 'light' ? {...colors.bg.light} : {...colors.bg.dark}
  const fg       = which === 'light' ? {...colors.fg.dark} : {...colors.fg.light}
  const border   = which === 'light' ? {...colors.border.dark} : {...colors.border.light}

  const theme = {
    semantic,
    bg,
    fg,
    border,
    guide,
    colors: guide.colors,
    fonts:  guide.fonts,
    isDark: which === 'dark',
    inverse,
  } as Theme

  if (theme.inverse == null) {
    theme.inverse = createTheme(guide, which === 'dark' ? 'light' : 'dark', theme)
  }
  return theme
}

export const Theme: {
  create:  (guide: BrandingGuide, which: 'light' | 'dark') => Theme,
  default: Theme
} = {
  create:  createTheme,
  default: createTheme(new BrandingGuide(), 'light'),
}
