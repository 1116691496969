import { DateTime } from 'luxon'
import { action, makeObservable, observable } from 'mobx'
import socket from 'socket.io-react'
import { register } from './support'

export class ClockStore {

  constructor() {
    makeObservable(this)
  }

  public async init() {
    socket.addEventListener('clock:sync', this.sync)
  }

  public async deinit() {
    socket.removeEventListener('clock:sync', this.sync)
  }

  @observable
  public serverDelta: number | null = null

  @action
  private sync = (timestamp: number) => {
    this.serverDelta = timestamp - Date.now()
  }

  public serverTime(clientTime: DateTime): DateTime | null {
    if (this.serverDelta == null) { return null }
    return clientTime.plus(this.serverDelta)
  }

}

const clockStore = register(new ClockStore())
export default clockStore