import React from 'react'
import { useTranslation } from 'react-i18next'
import { Notification } from '~/models'
import { NotificationsEndpoint } from '~/stores/notifications'
import { observer } from '~/ui/component'
import { Dimple, EmptyOrFetching, List } from '~/ui/components'
import NotificationListItem from './NotificationListItem'

export interface Props {
  endpoint: NotificationsEndpoint
}

const NotificationsList = observer('NotificationsList', (props: Props) => {

  const {endpoint} = props

  const [t] = useTranslation('notifications')

  const fetchMore = React.useCallback(() => {
    return endpoint.fetchMore()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    return (
      <List
        data={endpoint.data}
        keyExtractor={keyExtractor}
        SeparatorComponent={separator}
        renderItem={renderItem}
        onEndReached={fetchMore}
        EmptyComponent={renderEmpty}
        flex
        scrollable
      />
    )
  }

  const keyExtractor = React.useCallback(
    (notification: Notification) => notification.id,
    [],
  )

  const separator = React.useCallback(
    () => <Dimple horizontal/>,
    [],
  )

  const renderItem = React.useCallback((item: Notification) => {
    return (
      <NotificationListItem
        notification={item}
      />
    )
  }, [])

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('list.empty')}
        status={endpoint.fetchStatus}
        flex
      />
    )

  }, [endpoint.fetchStatus, t])

  return render()

})

export default NotificationsList