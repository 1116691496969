import React from 'react'
import { omit } from 'lodash'
import * as SimpleMarkdown from 'simple-markdown'
import { memo } from '~/ui/component'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import buildRules from './rules'

export interface Props {
  tag?:        string
  classNames?: React.ClassNamesProp
  style?:      React.CSSProperties

  children?:   React.ReactNode
  block?:      boolean

  shouldFollowLink?: (href: string) => boolean
}

const Markdown = memo('Markdown', (props: Props) => {

  const $ = useStyles()
  const {tag = 'div', classNames = [], block = true, ...attributes} = props

  const rules = buildRules()
  const parser = SimpleMarkdown.parserFor(rules)
  const output = SimpleMarkdown.outputFor(rules, 'react')

  const children = React.Children.toArray(props.children)

  const source = React.useMemo(() => block
    ? children.join('\n\n') + '\n\n'
    : children.join('').replace(/\n/g, ' ')
  , [block, children])

  const tree = React.useMemo(
    () => parser(source, {inline: !block}),
    [block, parser, source],
  )

  function render() {
    return React.createElement(tag, {
        classNames: [$.markdown, classNames],
        ...omit(attributes, 'shouldFollowLink'),
        children: output(tree),
        onClickCapture: onClick,
    })
  }

  const {shouldFollowLink} = props
  const onClick = React.useCallback((event: React.MouseEvent<any>) => {
    if (shouldFollowLink == null) { return }

    const target = event.target as HTMLElement
    const url = target.getAttribute('href')
    if (target.tagName.toLowerCase() !== 'a' || url == null) { return }

    if (!shouldFollowLink(url)) {
      event.preventDefault()
    }
  }, [shouldFollowLink])

  return render()

})

export default Markdown

export const useStyles = createUseStyles(theme => ({
  markdown: {
    color: theme.fg.normal,
    ...fonts.responsiveFontStyle(theme.guide.fonts.body),

    overflowWrap: 'word-break',

    '& h1': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.h1),
      marginBottom: '0.2em',
    },

    '& h2': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.h2),
      marginBottom: '0.2em',
    },

    '& h3': {
      ...fonts.responsiveFontStyle(theme.guide.fonts.h3),
      marginBottom: '0.2em',
    },

    '& > :not(:first-child):not(ol):not(ul)': {
      marginTop: '1.2em',
    },

    '& > h1 + *, & > h2 + *': {
      marginTop: 0,
    },

    '& .paragraph, & ul, & ol': {
      fontSize:   '1em',
      lineHeight: 1.6,
    },

    '& ul, & ol': {
      paddingInlineStart: '1.2em',
    },

    '& ul li': {
      display:   'list-item',
      listStyle: 'disc outside',
    },

    '& ol li': {
      display:   'list-item',
      listStyle: 'decimal outside',
    },

    '& a[href]': {
      color: theme.fg.link,
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '& var': {
      display:      'inline-block',
      background:   theme.bg.subtle,
      boxShadow:    ['inset', 0, 0, 0, 1, theme.fg.dimmer],
      borderRadius: layout.radius.s,
      padding:      layout.padding.inline.xs,
      fontStyle:    'normal',
      ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
    },

    '& figure': {
      '&.fullWidth': {
        margin: [0, -layout.padding.inline.l],
      },
    },
  },
}))