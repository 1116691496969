import { Model } from './Model'
import { ProfileDatum, ProfileScore, SocialLinks } from './Profile'
import { datetime } from './serialization'

export class Participant extends Model {

  public name!:      string
  public firstName!: string
  public lastName!:  string | null

  public photoURL!: string | null

  public bio!:      string | null
  public headline!: string | null

  public phone!:    string | null
  public email!:    string | null

  public profileData?: ProfileDatum[]
  public social?:      SocialLinks
  public scores?:      ProfileScore[]

  @datetime()
  public connectedAt!: Date | null

  public externalReference!: string | null

  public currentLocation!: string | null

  public hasLinkedUser!: boolean

  public language!: string

}
