export interface Config {
  query: {
    pollInterval: number
  }
}

const config: Config = {
  query: {
    pollInterval: 500,
  },
}

export default config

export function configure(cfg: (config: Config) => void) {
  cfg(config)
}