import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { Challenge, ChallengeState } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, Notice, ProgressBar, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  challenge: Challenge
  state:     ChallengeState
}

const ChallengeStatusNotice = memo('ChallengeStatusNotice', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <Notice gap={layout.padding.s}>
        <ChallengeStatusNoticeBody {...props}/>
      </Notice>
    )
  }

  return render()

})

export default ChallengeStatusNotice

const ChallengeStatusNoticeBody = memo('ChallengeStatusNoticeBody', (props: Props) => {

  const {challenge, state} = props
  const [t] = useTranslation('challenges')

  const now          = DateTime.local()
  const deadline     = state.deadline == null ? null : DateTime.fromISO(state.deadline)
  const pastDeadline = deadline != null && deadline < now
  const completed    = state.completedAt != null
  const inReview     = !pastDeadline && challenge.needsReview && state.reviewedAt == null
  const totalScore   = state.totalScore
  const passed       = state.passed
  const passingScore = challenge.passingScore
  const maxScore     = challenge.maximumScore

  const showNotice = pastDeadline || completed

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!showNotice) { return null }

    const score = renderScore()

    return (
      <VBox>
        {renderHeader()}
        {score != null && <Dimple horizontal/>}
        {score}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox justify='center' gap={layout.padding.s} classNames={$.header}>
        {!pastDeadline && passed !== false && (
          <SVG name='check' size={layout.icon.s}/>
        )}
        <VBox flex='shrink'>
          <Label small bold truncate={false} align='center'>
            {getLabel()}
          </Label>
          {pastDeadline ? (
            <Label small dim truncate={false} align='center'>
              {t('status_notice.past_deadline.detail', {when: deadline?.toRelative()})}
            </Label>
          ) : inReview ? (
            <Label small dim truncate={false} align='center'>
              {t('status_notice.in_review')}
            </Label>
          ) : null}
        </VBox>
      </HBox>
    )
  }

  function renderScore() {
    if (pastDeadline || !completed) { return null }
    if (totalScore == null || maxScore === 0) { return null }

    const barColor        = passed === false ? theme.semantic.negative : undefined

    return (
      <VBox classNames={$.score} gap={layout.padding.inline.s}>
        <VBox>
          <Label small bold align='center'>
            {maxScore == null ? (
              t('status_notice.score', {score: totalScore.formatted})
              ) : (
              t('status_notice.score_out_of', {score: totalScore.formatted, maxScore})
            )}
          </Label>
          {passingScore != null && (
            <Label tiny dim align='center'>
              {t('status_notice.passing_score', {passingScore: passingScore.formatted})}
            </Label>
          )}
        </VBox>
        {totalScore != null && (
          <ProgressBar
            progress={totalScore.points}
            max={maxScore}
            regions={passingScore == null ? [] : [{
              from: 0,
              to:   passingScore.points,
              color: theme.semantic.negative.alpha(0.1),
            }, {
              from:  passingScore.points,
              to:    maxScore,
              color: theme.semantic.positive.alpha(0.1),
            }]}
            barColor={barColor}
          />
        )}
      </VBox>
    )
  }

  function getLabel() {
    if (pastDeadline) {
      return t('status_notice.past_deadline.title')
    } else if (passed == null) {
      return t('status_notice.completed')
    } else if (passed) {
      return t('status_notice.passed')
    } else {
      return t('status_notice.failed')
    }
  }

  return render()

})

export const minHeight = 40

const useStyles = createUseStyles({
  header: {
    padding: layout.padding.inline.m,
  },

  score: {
    padding: layout.padding.inline.m,
  },
})