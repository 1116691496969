import I18n from 'i18next'

export function formatList(items: string[], options: ListOptions = {}) {
  if (items.length === 0) { return '' }
  if (items.length === 1) { return items[0] }
  if (options.collapseFrom != null && options.collapseKey != null && items.length >= options.collapseFrom) {
    return I18n.t(options.collapseKey, {count: items.length})
  }

  const {
    headSeparator = ', ',
    tailSeparator = I18n.t('list:and'),
  } = options

  const head = [...items]
  const tail = head.pop()

  return head.join(headSeparator) + tailSeparator + tail
}

export interface ListOptions {
  headSeparator?: string
  tailSeparator?: string

  collapseFrom?: number
  collapseKey?:  string
}

