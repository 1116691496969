import * as layout from '../../layout'
import { ComponentBranding } from '../components'
import { BrandingGuideDelegate } from '../types'
import OAuthButtonBranding from '../web/OAuthButtonBranding'
import AppHeaderBranding from './AppHeaderBranding'
import AppTabBarBranding from './AppTabBarBranding'
import CardBranding from './CardBranding'
import ListBarBranding from './ListBarBranding'

export default class AppBrandingGuide {

  constructor(
    public readonly delegate: BrandingGuideDelegate,
  ) {}

  public readonly brandingContainer = true

  public get rootGuide() {
    return this.delegate.rootGuide
  }

  //------
  // App-only screen components.

  public header = new AppHeaderBranding(this, null)
  public tabBar = new AppTabBarBranding(this, null)

  //------
  // App-only components (web versions to be ported)

  public card = new CardBranding(this, null)

  public pushButton  = new ComponentBranding(this, null, {
    shape:       'oval',
    background:  'primary',
    border:      'none',
    depth:       1,
    previewSize: {width: 160, height: 40},
  })

  public flatButton  = new ComponentBranding(this, null, {
    shape:       'oval',
    background:  'alt',
    border:      'none',
    depth:       0,
    previewSize: {width: 160, height: 40},
  })

  public textInput = new ComponentBranding(this, null, {
    shape:       {rounded: layout.radius.m},
    background:  'alt',
    border:      'none',
    depth:       1,
    previewSize: {width: 160, height: 48},
  })

  public selectInput = new ComponentBranding(this, null, {
    shape:       {rounded: layout.radius.m},
    background:  'alt',
    border:      'none',
    depth:       1,
    previewSize: {width: 160, height: 48},
  })

  public listBar = new ListBarBranding(this, null)

  public tooltip    = new ComponentBranding(this, null, {
    shape: 'rectangle',
    background: {
      type:  'solid',
      color: 'yellow',
      theme: 'light',
    },
    border: 'none',
    depth:  2,
  })

  //------
  // App-specific screen overrides

  public login = {
    button: new ComponentBranding(this, this.pushButton, {
      background: {type: 'solid', color: 'primary', theme: 'dark'},
    }),
    emailEntry:  new ComponentBranding(this, this.textInput),
    pinEntry:    new ComponentBranding(this, this.textInput),
    oAuthButton: new OAuthButtonBranding(this, null, {
      border: 'none',
    }),
  }

  public rankings = {
    listBar: new ListBarBranding(this, this.listBar, {
      shape: 'oval',
    }),
  }

}