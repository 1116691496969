import { CustomImage } from '~/models'
import { ResultStyle } from './Answers'
import { Score } from './Competition'
import { ButtonFeedback, Feedback, RatingFeedback } from './Feedback'
import { Model } from './Model'
import { RichText } from './types'

export class Challenge extends Model {

  /** A name for the challenge. */
  public name!: string

  /** Tags. */
  public tags!: string[]

  /** The title of the challenge. */
  public title!: string

  /** A big image URL for the challenge. */
  public image!: CustomImage | null

  /** The introduction text for this challenge. */
  public introduction!: string | null

  /** The pages that make up this challenge. */
  public tasks!: ChallengeTask[]

  /** An optional score that considers the participant to have passed the challenge. */
  public passingScore!: Score | null

  /** The maximum score you can get for this challenge. */
  public maximumScore!: number

  /** Whether this challenge needs a review. */
  public needsReview!: boolean

}

export interface ChallengeTask {
  uuid:     string
  title:    string
  subtitle: string | null
  body:     RichText

  question:     ChallengeQuestion | null
  resultStyle:  ResultStyle | null

  correctAnswer: any | null
  maxScore:      number
}

export type ChallengeQuestion = ChallengeQuestionCommon & Exclude<Feedback, ButtonFeedback | RatingFeedback>

interface ChallengeQuestionCommon {
  variable:  string | null
  skippable: boolean
  review: boolean
}

export interface ChallengeState {
  publishedAt: string
  startedAt:   string | null
  deadline:    string | null
  completedAt: string | null
  abandonedAt: string | null
  reviewedAt:  string | null

  totalScore:  Score | null
  passed:      boolean | null
}