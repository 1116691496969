import Logger from 'logger'
import { pack, unpack } from 'msgpackr'
import { GuestWindowControllerOptions, Listener, ReadyCallback } from './types'
import WindowChannelLogger from './WindowChannelLogger'

const logger = new Logger('WindowChannel.GuestWindowController')

export default class GuestWindowController<Events extends Record<string, any> = {}> {

  constructor(
    public readonly hostWindow: Window,
    public readonly namespace: string,
    options: GuestWindowControllerOptions = {},
  ) {
    this.hostOrigin = options.hostOrigin ?? this.hostWindow.origin
    this.bind()
  }

  public dispose() {
    this.unbind()
  }

  private readonly hostOrigin: string

  private readonly logger = new WindowChannelLogger(logger)

  //------
  // Listeners

  private readonly listeners = new Map<string, Set<Listener<any>>>()

  public addListener<E extends string & keyof Events>(type: E, listener: Listener<Events[E]>) {
    let listeners = this.listeners.get(type)
    if (listeners == null) {
      this.listeners.set(type, listeners = new Set())
    }
    listeners.add(listener)

    return this.removeListener.bind(this, type as any, listener as any)
  }

  public removeListener<E extends string & keyof Events>(type: E, listener: Listener<Events[E]>) {
    const listeners = this.listeners.get(type)
    if (listeners == null) { return }

    listeners.delete(listener)
    if (listeners.size === 0) {
      this.listeners.delete(type)
    }
  }

  //------
  // Bind / unbind

  private bind() {
    global.window.addEventListener('message', this.handleMessage)
    this.sendToHost('$ready', null as any)
  }

  private unbind() {
    this.sendToHost('$disconnect', null as any)
    global.window.removeEventListener('message', this.handleMessage)
  }

  //------
  // Connecting

  private readyCallbacks = new Set<ReadyCallback>()

  public ready(callback: (window: Window) => any) {
    this.readyCallbacks.add(callback)
  }

  //------
  // Messages

  public sendToHost<E extends string & keyof Events>(type: E, payload: Events[E]) {
    const packed = pack({
      namespace: this.namespace,
      type:      type,
      payload:   payload,
    })

    this.logger.logSend(type, payload)
    this.hostWindow.postMessage(packed, this.hostOrigin)
  }

  private handleMessage = (event: MessageEvent) => {
    try {
      if (event.origin !== this.hostOrigin) { return }

      const {namespace, type, payload} = unpack(event.data)
      if (namespace !== this.namespace) { return }

      this.logger.logIncoming(type, payload)

      const listeners = this.listeners.get(type)
      for (const listener of listeners ?? []) {
        listener(payload, window)
      }
    } catch (error: any) {
      console.warn("Unable to unpack message: " + error)
      return
    }
  }

}