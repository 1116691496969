import React from 'react'
import { useLocation } from 'react-router-dom'
import { FetchStatus } from 'mobx-document'
import { useHistory } from '~/history'
import { resolveHref } from '~/navigation'
import { projectStore } from '~/stores'
import { Breadcrumb } from '~/ui/app/navigation/types'
import { observer } from '~/ui/component'
import AppLayoutContext from './AppLayoutContext'

export interface Props {
  title?:              string | null
  defaultBreadcrumbs?: Breadcrumb[]
  fetchStatus?:        FetchStatus
  ActionsComponent?:   React.ComponentType<{}>
}

const AppLayoutConfig = observer('AppLayoutConfig', (props: Props) => {

  const {
    defaultBreadcrumbs,
    fetchStatus = 'idle',
    title: props_title,
    ActionsComponent,
  } = props

  const location  = useLocation()
  const history   = useHistory()
  const path      = location.pathname
  const activeTab = projectStore.activeTabForPath(path)

  const title = React.useMemo(() => {
    if (activeTab != null && resolveHref(activeTab?.link.href) === path) {
      return activeTab.caption
    } else if (props_title != null) {
      return props_title
    } else if (defaultBreadcrumbs?.[defaultBreadcrumbs.length -1] != null) {
      return defaultBreadcrumbs[defaultBreadcrumbs.length -1].caption
    } else {
      return null
    }
  }, [activeTab, path, props_title, defaultBreadcrumbs])

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    if (history.stack.length < 2 && defaultBreadcrumbs != null) {
      return defaultBreadcrumbs
    }

    const breadcrumbs: Breadcrumb[] = []
    history.stack.slice(0, history.index + 1).forEach((entry, index) => {
      const current = index === history.index
      const caption = current? title : entry.title

      breadcrumbs.push({
        caption: caption ?? '',
        href:    current ? null : entry.pathname,
      })
    })

    return breadcrumbs
  }, [title, defaultBreadcrumbs, history.stack, history.index])

  const {configure} = React.useContext(AppLayoutContext)

  React.useEffect(() => {
    configure({
      title,
      breadcrumbs,
      fetchStatus,
      ActionsComponent,
    })
  }, [title, breadcrumbs, fetchStatus, ActionsComponent, configure])

  return null

})

export default AppLayoutConfig