import React from 'react'
import { useTimer } from 'react-timer'
import { useContinuousRef } from '~/ui/hooks'

export function useScrollbarWidth(ref: React.RefObject<HTMLElement>, options: UseScrollbarWidthOptions = {}) {
  const {interval = 200} = options

  const [width, setWidth] = React.useState<number>(0)
  const widthRef = useContinuousRef(width)
  const timer = useTimer()

  const measure = React.useCallback(() => {
    if (ref.current == null) { return }

    const width = ref.current.offsetWidth - ref.current.clientWidth
    if (width === widthRef.current) { return }

    setWidth(width)
  }, [ref, widthRef])


  React.useEffect(() => {
    timer.clearAll()
    if (interval == null) { return }

    timer.setInterval(measure, interval)
  }, [interval, measure, timer])

  return width
}

export interface UseScrollbarWidthOptions {
  interval?: number | null
}