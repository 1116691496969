import React from 'react'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { DynamicField } from '../types'
// @index: import ${variable} from ${relpath}
import BooleanAllowance from './BooleanAllowance'
import ChoiceAllowance from './ChoiceAllowance'
import NumberAllowance from './NumberAllowance'
import StringAllowance from './StringAllowance'

// /index

export default function renderDynamicFieldAllowance(field: DynamicField, bind: FormFieldBindProps<any>) {
  switch (field.type) {
    case 'string':
      return <StringAllowance field={field} bind={bind}/>
    case 'choice':
      return <ChoiceAllowance field={field} bind={bind}/>
    case 'number':
      return <NumberAllowance field={field} bind={bind}/>
    case 'boolean':
      return <BooleanAllowance field={field} bind={bind}/>
    }
}