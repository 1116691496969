import React from 'react'
import { useTranslation } from 'react-i18next'
import { notificationsStore } from '~/stores'
import { observer } from '~/ui/component'
import { Badge, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useDrawer } from './DrawerContext'

const DrawerToggle = observer('DrawerToggle', () => {

  const [t] = useTranslation('app')

  const drawer = useDrawer()

  const notificationCount = notificationsStore.unseenNotificationCount

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={$.DrawerToggle} onTap={drawer.toggle} aria-label={t('nav.more')} aria-expanded={drawer.isOpen}>
        <SVG
          name='hamburger'
          size={layout.icon.l}
        />
        {renderBadge()}
      </Tappable>
    )
  }

  function renderBadge() {
    if (notificationCount == null) { return null }
    return (
      <Badge
        value={notificationCount}
        classNames={$.badge}
        small
      />
    )
  }

  return render()

})

export default DrawerToggle

const useStyles = createUseStyles({
  DrawerToggle: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
  },
})