import React from 'react'
import { MessageBoxElement } from './types'
import hostRef from './hostRef'

export default function MessageBoxHost() {

  const [messageBoxes, setMessageBoxes] = React.useState<MessageBoxElement[]>([])

  //------
  // Interface

  const push = React.useCallback((messageBox: MessageBoxElement) => {
    setMessageBoxes([...messageBoxes, messageBox])
  }, [messageBoxes])

  const pop = React.useCallback(() => {
    if (messageBoxes.length === 0) { return }
    setMessageBoxes(messageBoxes.slice(0, -2))
  }, [messageBoxes])

  React.useImperativeHandle(hostRef, () => ({
    push,
    pop,
  }), [push, pop])

  return (
    <>
      {React.Children.map(messageBoxes, (box, index) => (
        React.cloneElement(box, {key: index})
      ))}
    </>
  )

}