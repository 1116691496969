import React from 'react'
import { useTimer } from 'react-timer'
import { usePrevious } from '~/ui/hooks'
import { animation } from '~/ui/styling'

export interface SizeTransitionOptions {
  duration?:       number
  timingFunction?: string
}

export function useSizeTransition<T extends Primitive>(ref: React.RefObject<HTMLDivElement>, input: T, options: SizeTransitionOptions = {}): T {

  const {
    duration       = animation.durations.long,
    timingFunction = 'cubic-bezier(0.22, 0.61, 0.36, 1)',
  } = options

  const timer = useTimer()
  const node  = ref.current

  const [phase, setPhase] = React.useState<TransitionPhase>('idle')

  const previousInput = usePrevious(input)
  const outputRef     = React.useRef<T>(input)

  //------
  // Transitions

  const measure = React.useCallback((): Size | null => {
    if (node == null) { return null }

    const prevWidth = node.style.width
    const prevHeight = node.style.height
    const prevOpacity = node.style.opacity

    node.style.opacity = '0'
    node.style.width  = ''
    node.style.height = ''

    const width = node.offsetWidth
    const height = node.offsetHeight

    node.style.opacity = prevOpacity
    node.style.width  = prevWidth
    node.style.height = prevHeight

    return {width, height}
  }, [node])

  const commitTransition = React.useCallback(() => {

    // Measure the final size.
    const size = measure()
    if (node == null || size == null) { return }

    timer.clearAll()

    timer.setTimeout(() => {
      node.style.overflow   = 'hidden'
      node.style.width      = `${size.width}px`
      node.style.height     = `${size.height}px`
      node.style.transition = ['width', 'height']
        .map(prop => `${prop} ${duration}ms ${timingFunction}`)
        .join(', ')
    }, 16)

    timer.setTimeout(() => {
      node.style.overflow = ''
      node.style.width = ''
      node.style.height = ''
      node.style.transition = ''
      setPhase('idle')
    }, duration)

  }, [node, duration, measure, timer, timingFunction])

  const prepareTransition = React.useCallback(() => {
    if (node == null) { return null }

    const width  = node.offsetWidth
    const height = node.offsetHeight

    // Fix the size now.
    node.style.width  = `${width}px`
    node.style.height = `${height}px`
  }, [node])

  React.useLayoutEffect(() => {
    if (previousInput !== undefined && previousInput !== input) {
      setPhase('prepare')
      prepareTransition()
      outputRef.current = input
    } else if (phase === 'prepare') {
      setPhase('commit')
      commitTransition()
    }
  }, [commitTransition, node, phase, prepareTransition, input, previousInput])

  return outputRef.current

}

type TransitionPhase = 'idle' | 'prepare' | 'commit'