import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js'
import { UnicodeString } from 'unicode'
import { BLOCK_ESCAPE, BLOCK_STYLES, ESCAPE, INLINE_STYLES } from './constants'
import { DraftJSToMarkdownOptions, Scope } from './types'

export default function draftJSToMarkdown(raw: RawDraftContentState, scope: Scope, options: DraftJSToMarkdownOptions = {}): string {
  const paragraphs: string[] = []

  for (const [index, block] of raw.blocks.entries()) {
    const previous = index > 0 ? raw.blocks[index - 1] : null

    if (scope === 'inline') {
      paragraphs.push(processBlockText(block))
    } else if (block.type in BLOCK_STYLES) {
      paragraphs.push(BLOCK_STYLES[block.type].prefix + processBlockText(block))
    } else if (block.type === 'unordered-list-item') {
      processListItem('- ', 'unordered-list-item', block, previous)
    } else if (block.type === 'ordered-list-item') {
      processListItem('1. ', 'ordered-list-item', block, previous)
    } else if (block.type === 'atomic') {
      processAtomicBlock(block)
    } else {
      paragraphs.push(processBlockText(block))
    }
  }

  function processListItem(marker: string, type: string, block: RawDraftContentBlock, previous: RawDraftContentBlock | null) {
    if (previous?.type === type) {
      // Continue the list.
      paragraphs[paragraphs.length - 1] += `\n${marker}${processBlockText(block)}`
    } else {
      // Create a new list.
      paragraphs.push(`${marker}${processBlockText(block)}`)
    }
  }

  function processBlockText(block: RawDraftContentBlock) {
    const [text, inlineStyleRanges, entityRanges] = escapeMarkdown(block)

    let markdown = text
    markdown = applyRanges(markdown, inlineStyleRanges, entityRanges)

    return markdown.replace(/\n/g, '  \n')
  }

  function escapeMarkdown(block: RawDraftContentBlock): [string, RawDraftInlineStyleRange[], RawDraftEntityRange[]] {
    let {
      inlineStyleRanges,
      entityRanges,
    } = block

    const string = new UnicodeString(block.text)

    let text: string = ''
    let startOfLine: boolean = true
    let variable: boolean = false

    const escape = () => {
      if (variable) { return }

      text += '\\'

      inlineStyleRanges = inlineStyleRanges.map(range => range.offset >= text.length ? {...range, offset: range.offset + 1} : range)
      entityRanges      = entityRanges.map(range => range.offset >= text.length ? {...range, offset: range.offset + 1} : range)
    }

    for (const [index, char] of string.characters.entries()) {
      if (ESCAPE.includes(char) || (startOfLine && BLOCK_ESCAPE.includes(char))) {
        escape()
      }
      if (char === '\n') {
        startOfLine = true
      } else {
        startOfLine = false
      }
      if (char === '{' && string.characters[index + 1] === '{') {
        variable = true
      }
      if (char === '}' && string.characters[index - 1] === '}') {
        variable = false
      }
      text += char
    }

    return [text, inlineStyleRanges, entityRanges]
  }

  function applyRanges(text: string, inlineStyleRanges: RawDraftInlineStyleRange[], entityRanges: RawDraftEntityRange[]) {
    const ranges = sortRanges([...inlineStyleRanges, ...entityRanges])
    const string = new UnicodeString(text)

    const parts: string[] = []

    const apply = (start: number, end: number) => {
      if (ranges.length === 0 || ranges[0].offset >= end) {
        parts.push(string.slice(start, end).string)
      } else {
        const next = ranges.shift()!
        parts.push(string.slice(start, next.offset).string)

        if ('style' in next) {
          parts.push(INLINE_STYLES[next.style]?.[0] ?? '')
          apply(next.offset, next.offset + next.length)
          parts.push(INLINE_STYLES[next.style]?.[1] ?? '')
        } else {
          const entity     = raw.entityMap[next.key]
          const entityText = string.slice(next.offset, next.offset + next.length).string

          if (entity != null) {
            parts.push(applyEntity(entity, entityText))
          } else {
            parts.push(entityText)
          }
        }

        apply(next.offset + next.length, end)
      }
    }

    apply(0, string.length)
    return parts.join('')
  }

  function applyEntity(entity: RawDraftEntity, text: string): string {
    // Special case - format a link with the same text as just the URL, if autolinks is on.
    if (entity.type === 'LINK' && options.autolinks === false && text === entity.data.url) {
      return entity.data.url
    }

    // Try a custom applyEntity function.
    const custom = options.applyEntity?.(entity, text)
    if (custom !== undefined) { return custom }

    // Defaults.
    switch (entity.type) {
      case 'LINK':     return `[${text}](${entity.data.url})`
      case 'VARIABLE': return text
      case 'YOUTUBE':  return text
      case 'VIMEO':    return text
      default:         return ''
    }
  }

  function sortRanges<R extends RawDraftInlineStyleRange | RawDraftEntityRange>(ranges: R[]) {
    return ranges
      .map((it, idx): [R, number] => [it, idx])
      .sort(([a, ai], [b, bi]) => {
        if (a.offset === b.offset) {
          // Same offset - apply the last range first.
          return bi - ai
        } else {
          // Different offset.
          return a.offset - b.offset
        }
      })
      .map(it => it[0])
  }

  function processAtomicBlock(block: RawDraftContentBlock) {
    const markdown = formatEntity(block)
    if (markdown == null) { return }

    paragraphs.push(markdown)
  }

  function formatEntity(block: RawDraftContentBlock) {
    const entityRange = block.entityRanges[0]
    const entity      = entityRange == null ? null : raw.entityMap[entityRange.key]
    if (entity == null) { return null }

    const customFormat = options.formatEntity?.(entity)
    if (customFormat !== undefined) {
      return customFormat
    }

    switch (entity.type) {
      case 'IMAGE':
        if (entity.data.style?.fullWidth) {
          return `![[${entity.data.alt ?? ''}]](${entity.data.src})`
        } else {
          return `![${entity.data.alt ?? ''}](${entity.data.src})`
        }
      default:
        return null
    }
  }

  return paragraphs.join(scope === 'block' ? '\n\n' : ' ')
}