import React from 'react'
import { useTranslation } from 'react-i18next'
import { sparse } from 'ytil'
import * as locales from '~/locales'
import { languagesStore, profileStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, Label, LanguageFlag, PopupMenu, PopupMenuItem, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  small?: boolean
}

const LanguageSwitcher = observer('LanguageSwitcher', (props: Props) => {

  const {small = false} = props

  const {i18n}         = useTranslation('app')
  const languageCodes  = projectStore.project?.languages ?? Object.keys(locales)

  const participant = profileStore.participant

  const languages = React.useMemo(
    () => sparse(languageCodes.map(code => languagesStore.getLanguage(code, true))),
    [languageCodes],
  )
  const currentLanguage = React.useMemo(
    () => languagesStore.getLanguage(participant?.language ?? i18n.language, true),
    [i18n.language, participant?.language],
  )

  // This line is here to make sure the component updates if the languages are loaded.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = languagesStore.status

  const $ = useStyles()

  const items = React.useMemo((): PopupMenuItem[] => languages.map(lng => ({
    value: lng.code,
    render: () => (
      <HBox gap={layout.padding.inline.m} classNames={$.languageItem}>
        <LanguageFlag small language={lng}/>
        <VBox flex>
          <Label truncate small bold>
            {lng.name_loc}
          </Label>
        </VBox>
      </HBox>
    ),
  })), [$.languageItem, languages])

  const switchToLanguage = React.useCallback((language: string) => {
    profileStore.switchLanguage(language)
  }, [])

  //------
  // Rendering

  function render() {
    if (items.length < 2) { return null }

    return (
      <PopupMenu targetClassNames={$.LanguageSwitcher} items={items} onValueSelect={switchToLanguage}>
        {toggle => (
          <LanguageFlag
            language={currentLanguage}
            onTap={toggle}
            small={small}
          />
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default LanguageSwitcher

const useStyles = createUseStyles(theme => ({
  LanguageSwitcher: {},

  languageItem: {
    minHeight: 40,
    padding:   [layout.padding.inline.s, layout.padding.inline.m],
  },
}))