import React from 'react'
import { safeParseInt } from 'ytil'
import Vimeo from '@u-wave/react-vimeo'
import { MessageListItem } from '~/models'
import { createUseStyles } from '~/ui/styling'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'text'}
  inlineReply: MessageListItem | null
}

const VimeoBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const text  = message.text ?? ''
  const videoID = React.useMemo(() => extractVimeoVideoID(text), [text])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChatBubble message={message} inlineReply={inlineReply}>
        {videoID != null && (
          <Vimeo
            video={videoID}
            className={$.vimeo}
            {...videoSize}
          />
        )}
      </ChatBubble>
    )
  }

  return render()

})

const videoSize = {
  width: 260,
  height: 146,
}

const shouldUse = (message: MessageListItem): message is MessageListItem & {type: 'text'} => {
  const id = extractVimeoVideoID(message.text ?? '')
  return id != null
}


export function extractVimeoVideoID(text: string) {
  const match = text.trim().match(/^https:\/\/[^/]*vimeo\.com\/(\d+)/i)
  return safeParseInt(match?.[1])
}

Object.assign(VimeoBubble, {shouldUse})
export default VimeoBubble as typeof VimeoBubble & {
  shouldUse: typeof shouldUse
}

const useStyles = createUseStyles({
  vimeo: {
    ...videoSize,

    '& iframe': {
      display: 'block',
    },
  },
})