import { action, makeObservable, observable } from 'mobx'
import { authenticationStore, AuthResetAccount } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class AuthResetFormModel implements FormModel {

  constructor(
    public readonly token: string | null,
  ) {
    makeObservable(this)
  }

  @observable
  public account: AuthResetAccount | null = null

  @action
  public selectAccount(account: AuthResetAccount) {
    this.account = account
  }

  @action
  public clearAccount() {
    this.account = null
  }

  @observable
  public pin: string = ''

  //------
  // Submit

  public get maySubmit() {
    return isValidPin(this.pin)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    if (this.token == null) { return }
    if (this.account == null) { return }
    if (!isValidPin(this.pin)) { return }

    return await authenticationStore.resetAuth(
      this.token,
      this.account?.participantID,
      this.pin,
    )
  }

}

function isValidPin(pin: string) {
  return /^\d{5}$/.test(pin)
}