import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, themedBackgroundStyles, ThemeProvider, useStyling } from '~/ui/styling'
import AuthFooter from './AuthFooter'

export interface Props {
  footer?:   boolean
  children?: React.ReactNode
}

const AuthLayout = memo('AuthLayout', (props: Props) => {

  const {footer = true, children} = props
  const {guide} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark={guide.backgrounds.launch.theme === 'dark'}>
        <VBox flex='grow' classNames={$.AuthLayout}>
          {renderContent()}
          {footer && <AuthFooter/>}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderContent() {
    return (
      <VBox flex='grow' justify='middle' classNames={$.content}>
        {children}
      </VBox>
    )
  }

  return render()

})

export default AuthLayout

const useStyles = createUseStyles(theme => ({
  '@keyframes bganim': {
    '0%': {
      backgroundPosition: ['50%', '0%'],
    },
    '25%': {
      backgroundPosition: ['100%', '50%'],
    },
    '50%': {
      backgroundPosition: ['50%', '100%'],
    },
    '75%': {
      backgroundPosition: ['0%', '50%'],
    },
    '100%': {
      backgroundPosition: ['50%', '0%'],
    },
  },

  AuthLayout: {
    ...themedBackgroundStyles(theme.guide, theme.guide.backgrounds.launch),
    backgroundSize: ['150%', '150%'],
    animation:      '$bganim 8s ease infinite',
  },

  content: {
    overflow:   'hidden',
  },
}))