import { times } from 'lodash'
import { Key, RowRange } from './types'
import { arrayDiff, ArrayDiff } from './util/arrays'

/**
 * This class caches all layout for the list.
 *
 * This class is optimized to understand that the most recent message is supposed to be at the bottom. It stores row heights
 * from the bottom up, rather than from the top down. When new messages are inserted, row heights are not recalculated. Instead,
 * all row heights are shifted up by the number of new messages.
 */
export default class LayoutHelper {

  constructor(
    private readonly listener: LayoutListener,
  ) {}

  //------
  // Configuration

  /**
   * The initial number of rows to render.
   */
  public initialPageSize: number = 40

  /**
   * The estimated row height, used for determining the list height beyond known rows.
   */
  public estimatedRowHeight: number = 32

  /**
   * The number of rows to render incrementally.
   */
  public pageSize: number = 10

  /**
   * The number of rows to render beyond the visual boundary in descending order. This number is low as waiting for messages to appear
   * is a lesser evil than a long render delay.
   */
  public overscanEnd: number = 10

  /**
   * The number of rows to render beyond the visual boundary in ascending order. This number is higher than `overscanEnd` because
   * we typically will want to return quickly to the newest message.
   */
  public overscanStart: number = 50

  /**
   * Padding to apply to the content.
   */
  public padding: number = 0

  //------
  // Key management

  /**
   * The current set of keys in the list.
   */
  public keys: Key[] = []

  /**
   * A map of keys to their index in the list.
   */
  private keyIndexes = new Map<Key, number>()

  /**
   * Updates the list of keys. Should happen on each render.
   * @returns Whether new items have been prepended.
   */
  public updateKeys(keys: Key[]): ArrayDiff<Key> {
    const diff = arrayDiff(this.keys, keys)
    if (diff === 'none') { return diff }

    if (ArrayDiff.isPrepend(diff)) {
      // We can quickly shift all row heights - this is cheaper than recalculating everything.
      this.shiftRowHeights(diff.prepend.length)
    } else if (ArrayDiff.isRemove(diff)) {
      // Remove the row heights at the given indexes.
      const indices = diff.remove
        .map(it => this.keyIndexes.get(it))
        .filter(Boolean) as number[]

      for (const idx of indices.sort().reverse()) {
        this.rowHeights.splice(idx, 1)
      }
      this.rowOffsets.splice(0)
    } else {
      // When replacing, invalidate all layout.
      this.recalculateRowHeightsOnNextCommit = true
      this.rowOffsets.splice(0)
    }

    this.keys = keys
    this.layoutModified = true
    this.rebuildKeyIndexMap()

    return diff
  }

  private rebuildKeyIndexMap() {
    this.keyIndexes.clear()
    for (const [index, key] of this.keys.entries()) {
      this.keyIndexes.set(key, index)
    }
  }

  //------
  // Row layout

  /**
   * The required list height.
   */
  public listHeight: number = 0

  /**
   * The known row heights.
   */
  private rowHeights: Array<number | undefined> = []

  private recalculateRowHeightsOnNextCommit: boolean = false

  /**
   * Known row offsets. Can be derived from row heights.
   */
  private rowOffsets: number[] = []

  /**
   * Retrieves the row height for the given index.
   */
  public rowHeight(index: number) {
    return this.rowHeights[index]
  }

  /**
   * Retrieves the row offset for the given index.
   */
  public rowOffset(index: number) {
    if (index >= this.rowOffsets.length) {
      this.calculateRowIndexesUpTo(index)
    }
    return this.rowOffsets[index]
  }

  /**
   * Retrieves the row style for the given index.
   *
   * @param index The index of the message.
   */
  public rowStyle(index: number) {
    const height = this.rowHeight(index)
    const offset = this.rowOffset(index)

    if (height == null) {
      // Hide the messsage, but allow it to size itself.
      return {opacity: 0}
    }

    return {
      height:    height,
      transform: `translateY(-${offset}px)`,
    }
  }

  /**
   * Shifts the row heights by the given number of places.
   */
  public shiftRowHeights(count: number) {
    const heights   = times(count, () => undefined)
    this.rowHeights = [...heights, ...this.rowHeights]
    this.rowOffsets.splice(0)
  }

  /**
   * Updates the row height for the given key.
   *
   * @param index The index of the row to update.
   * @param height The new row height.
   */
  public updateRowHeight(key: Key, height: number) {
    const index = this.keyIndexes.get(key)
    if (index == null) { return }

    this.rowHeights[index] = height
    this.rowOffsets.splice(index)
    this.layoutModified = true
  }

  private calculateRowIndexesUpTo(index: number) {
    if (index < this.rowOffsets.length) {
      // Nothing to do.
      return
    }

    if (index > this.rowHeights.length) {
      // We need to have at least the heights up until the index before the given one to calculate the offset.
      return
    }

    const lastOffset = this.rowOffsets.length === 0 ? this.padding : this.rowOffsets[this.rowOffsets.length - 1]
    const lastHeight = this.rowOffsets.length === 0 ? 0 : this.rowHeights[this.rowOffsets.length - 1] ?? 0

    let current = lastOffset + lastHeight
    while (this.rowOffsets.length <= index) {
      this.rowOffsets.push(current)
      current += this.rowHeights[this.rowOffsets.length - 1] ?? 0
    }
  }

  public recalculateRowHeights(commit: boolean = true) {
    for (const key of this.keys) {
      this.recalculateRowHeight(key, false)
    }
    if (commit) {
      this.commit()
    }
  }

  public recalculateRowHeight(key: Key, commit: boolean = true) {
    const index = this.keyIndexes.get(key)
    if (index == null) { return }

    const element = this.rowElements.get(key)
    if (element == null) {
      this.rowHeights[index] = undefined
    } else {
      this.rowHeights[index] = this.recalculateHeight(element)
    }
    this.rowOffsets.splice(index)

    this.layoutModified = true
    if (commit) {
      this.commit()
    }
  }

  private recalculateHeight(element: HTMLElement) {
    const oldHeight = element.style.height
    element.style.height = ''

    const offsetHeight = element.offsetHeight
    element.style.height = oldHeight

    return offsetHeight
  }

  //------
  // Refs

  private rowRefs     = new Map<Key, (element: HTMLElement | null) => void>()
  private rowElements = new Map<Key, HTMLElement>()

  public refFor(key: Key) {
    let ref = this.rowRefs.get(key)
    if (ref == null) {
      this.rowRefs.set(key, ref = this.createRowRef(key))
    }
    return ref
  }

  private createRowRef(key: Key) {
    return (element: HTMLElement | null) => {
      if (element != null) {
        this.rowElements.set(key, element)
        this.updateRowHeight(key, element.offsetHeight)
      } else {
        this.rowElements.delete(key)
      }
    }
  }

  //------
  // Layout

  public initialLayout:   boolean = true
  private layoutModified: boolean = false

  public containerHeight: number = -1
  public scrollTop:      number = 0

  public renderRange: RowRange = {start: 0, end: this.initialPageSize}

  public setContainerHeight(height: number) {
    if (height === this.containerHeight) { return }

    this.layoutModified = true
    this.containerHeight = height
  }

  public setScrollTop(scrollTop: number) {
    if (scrollTop === this.scrollTop) { return }

    this.layoutModified = true
    this.scrollTop = scrollTop
  }

  public commit(): boolean {
    if (!this.layoutModified) { return false }
    if (this.containerHeight === -1) { return false }

    if (this.recalculateRowHeightsOnNextCommit) {
      this.recalculateRowHeights(false)
      this.recalculateRowHeightsOnNextCommit = false
    }

    if (this.rowHeights.length === 0) { return false }

    const renderRange        = this.calculateRenderRange()
    const renderRangeChanged = renderRange.start !== this.renderRange.start || renderRange.end !== this.renderRange.end
    this.renderRange         = renderRange

    const listHeight         = this.calculateListHeight()
    const listHeightChanged  = listHeight - this.listHeight
    this.listHeight          = listHeight

    this.initialLayout  = false
    this.layoutModified = false

    if (listHeightChanged || renderRangeChanged) {
      this.listener(this)
      return true
    } else {
      return false
    }
  }

  private calculateListHeight() {
    let height = 0
    for (let row = 0; row < this.keys.length; row++) {
      height += this.rowHeight(row) ?? this.estimatedRowHeight
    }
    return 2 * this.padding + height
  }

  private calculateRenderRange(): RowRange {
    const scrollTop    = this.scrollTop
    const scrollBottom = this.scrollTop + this.containerHeight

    const offsetEnd   = this.initialLayout ? this.containerHeight : this.listHeight - scrollTop
    const offsetStart = this.initialLayout ? 0 : this.listHeight - scrollBottom

    let start: number = 0
    let end:   number | null = null
    for (const [index] of this.rowHeights.entries()) {
      const offset = this.rowOffset(index)

      if (start == null && offset >= offsetStart) {
        start = Math.max(0, index - this.overscanStart)
      }
      if (end == null && offset > offsetEnd) {
        end = Math.min(this.keys.length, index + this.overscanEnd)
        break
      }
    }

    return {
      start: this.roundToPageSize(start ?? 0, Math.floor),
      end:   this.roundToPageSize(end ?? this.keys.length, Math.ceil),
    }
  }

  private roundToPageSize(value: number, roundFn: (value: number) => number) {
    return roundFn(value / this.pageSize) * this.pageSize
  }

}

export type LayoutListener = (layout: LayoutHelper) => any