import { ContentBlock, ContentState } from 'draft-js'
import renderWidget from '~/ui/app/widgets'
import { memo } from '~/ui/component'

export interface Props {
  block:        ContentBlock
  contentState: ContentState
}

const Widget = memo('Widget', (props: Props) => {

  const {block, contentState} = props

  const key    = block.getEntityAt(0)
  const entity = key == null ? null : contentState.getEntity(key)
  const type   = entity?.getType()
  const data   = entity?.getData()

  //------
  // Rendering

  function render() {
    if (type !== 'WIDGET') { return null }

    const widget = data.widget as string
    const params = data.params as Record<string, any>

    return renderWidget(widget, params)
  }

  return render()

})

export default Widget