import Color from 'color'
import { Model } from './Model'
import { color } from './serialization'

export class Feed extends Model {

  public title!: string

  @color()
  public color!: Color

  public moderation!: boolean
  public moderator!:  boolean

  public open!: boolean
  public commentsOpen!: boolean

}