import React from 'react'
import * as SM from 'simple-markdown'
import renderWidget, { parseWidgetParams, WIDGET_REGEXP } from '~/ui/app/widgets'
import { configure, WidgetNode } from '~/ui/components/markdown'

configure({
  extraRules: {
    widget: {
      order: SM.defaultRules.link.order - 1,

      match: (source: string) => WIDGET_REGEXP.exec(source),

      parse: (capture: SM.Capture, parse: any, state: SM.State) => {
        return {
          type:   'widget',
          widget: capture[1],
          params: parseWidgetParams(capture[2]),
        }
      },

      react: (node: SM.SingleASTNode, output: any, state: SM.State) => {
        const {widget, params} = node as WidgetNode
        return React.createElement(React.Fragment, {
          key: state.key,
        }, renderWidget(widget, params))
      },
    },
  },
})