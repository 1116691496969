import React from 'react'
import { Answer, AnswerResult, ChallengeQuestion } from '~/models'
import { AnswerOutcomeRenderUtil } from '../tasks/ChallengeTaskAnswerOutcome'
// @index(f:!): import * as ${variable} from ${relpath}
import * as choice from './choice'
import * as media from './media'
import * as numeric from './numeric'
import * as text from './text'

// /index

//------
// Answer entry

export interface AnswerEntryProps {
  question: ChallengeQuestion

  value:    any
  onChange: (value: any) => any
  onCommit: () => any
}

export function renderAnswerEntry(props: AnswerEntryProps) {
  switch (props.question.type) {
    case 'text':
      return <text.TextAnswerEntry {...props} feedback={props.question} />
    case 'choice':
      return <choice.ChoiceAnswerEntry {...props} feedback={props.question} />
    case 'media':
      return <media.MediaAnswerEntry {...props} feedback={props.question} />
    case 'numeric':
      return <numeric.NumericAnswerEntry {...props} feedback={props.question} />
    default:
      return null
  }
}

//------
// Answer outcome

export interface AnswerOutcomeProps {
  question:       ChallengeQuestion
  answer:         Answer
  result:         AnswerResult | null
  correctAnswers: any[]
  renderUtil:     AnswerOutcomeRenderUtil
}

export function renderAnswerOutcome(props: AnswerOutcomeProps) {
  switch (props.question.type) {
    case 'text':
      return <text.TextAnswerOutcome {...props} feedback={props.question} />
    case 'choice':
      return <choice.ChoiceAnswerOutcome {...props} feedback={props.question} />
    case 'media':
      return <media.MediaAnswerOutcome {...props} feedback={props.question} />
    case 'numeric':
      return <numeric.NumericAnswerOutcome {...props} feedback={props.question} />
    default:
      return null
  }
}