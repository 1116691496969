import { action, computed, makeObservable, observable } from 'mobx'
import { Document, DocumentOptions } from 'mobx-document'
import socket, { SendResponse } from 'socket.io-react'
import { Answer, AnswerResult, Challenge, ChallengeState } from '~/models'

export default class ChallengeDocument extends Document<Challenge, string, {}, ChallengeMeta> {

  constructor(
    id:      string,
    options: DocumentOptions<Challenge, ChallengeMeta> = {},
  ) {
    super(id, options)
    makeObservable(this)
  }

  //------
  // Data

  @computed
  public get state(): ChallengeState | null {
    return this.meta?.state ?? null
  }

  @computed
  public get answers(): Record<string, Answer> {
    const answers: Record<string, any> = {}
    for (const answer of this.meta?.answers ?? []) {
      answers[answer.question.uuid] = answer
    }
    return answers
  }

  @computed
  public get results(): AnswerResult[] {
    return this.meta?.results ?? []
  }

  @computed
  public get correctAnswers(): Record<string, any[]> {
    return this.meta?.correctAnswers ?? {}
  }

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('challenges:show', this.id)
    if (!response.ok) { return response }

    const pack      = response.body
    const challenge = Challenge.deserialize(pack.data)

    return {
      data: challenge,
      meta: pack.meta,
    }
  }

  //------
  // Starting

  @action
  public startChallenge() {
    socket.emit('challenges:start', this.id)
  }

  //------
  // Answering

  @observable
  public completingChallenge: boolean = false

  @action
  public completeChallengeWithAnswers(answers: Record<string, any>) {
    this.completingChallenge = true

    const promise = socket.send('challenges:complete', this.id, answers)
    return promise.then(this.onCompleteChallengeComplete)
  }

  @action
  private onCompleteChallengeComplete = (response: SendResponse<{data: AnyObject, meta: ChallengeMeta}>) => {
    this.completingChallenge = false
    if (!response.ok) { return false }

    const challenge = Challenge.deserialize(response.body.data)
    this.set(challenge, response.body.meta)
    return true
  }

}

export interface ChallengeMeta {
  state?:          ChallengeState
  mayAnswer:       boolean
  answers?:        Answer[]
  results?:        AnswerResult[]
  correctAnswers?: Record<string, any[]>
}