import React from 'react'
import { CustomImage, isCustomImage } from '~/models'
import { memo } from '~/ui/component'
import { Center, flexStyle, SVG, Tappable, VBoxProps } from '~/ui/components'
import { useLightbox } from '~/ui/components/lightbox'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  source:     string | CustomImage | {icon: SVGName}
  alt?:       string
  densities?: number[]

  size?:           Size
  flex?:           VBoxProps['flex']
  height?:         number
  objectFit?:      React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']

  round?:      boolean
  blocks?:     boolean
  lightbox?:   boolean
  classNames?: React.ClassNamesProp
}

const ImageView = memo('ImageView', (props: Props) => {

  const {
    source,
    densities = [1],
    alt = '',
    flex = false,
    size,
    height,
    round = false,
    objectFit = isCustomImage(source) && source.type === 'svg' ? 'contain' : 'cover',
    objectPosition,
    blocks = false,
    lightbox,
    classNames,
  } = props

  const {show} = useLightbox()

  const url = React.useMemo(() => {
    if (typeof source === 'string') {
      return source
    } else if (isCustomImage(source) && source.type === 'remote') {
      return source.url
    } else {
      return null
    }
  }, [source])

  const sourceSet = React.useMemo(() => {
    if (url == null) { return }

    const match = url.match(/^(.*?)(\..*)?$/)
    const base  = match?.[1] ?? url
    const ext   = match?.[2] ?? ''

    const sources = densities.map(density => {
      if (density === 1) {
        return `${url} ${density}x`
      } else {
        return `${base}@${density}x${ext} ${density}x`
      }
    })
    return sources.join(', ')
  }, [densities, url])

  const showInLightbox = React.useCallback(() => {
    if (!lightbox || url == null) { return }
    show({
      type:  'image',
      image: {type: 'remote', url},
      alt:   alt,
    })
  }, [alt, lightbox, show, url])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (lightbox) {
      return (
        <Tappable onTap={showInLightbox}>
          {renderImage()}
        </Tappable>
      )
    } else {
      return (
        renderImage()
      )
    }
  }

  function renderImage() {
    const style: React.CSSProperties = {
      ...size,
      objectFit,
      objectPosition,
    }
    if (height != null) {
      style.height = height
    }
    if (round) {
      style.borderRadius = (size?.height ?? height ?? 0) / 2
    }
    Object.assign(style, flexStyle(flex))

    if (source == null) {
      return (
        <div
          classNames={[$.imageView, 'empty', classNames]}
          style={style}
        />
      )
    } else if (typeof source === 'string') {
      return (
        <img
          classNames={[$.imageView, 'remote', {blocks}, classNames]}
          style={style}
          src={source}
          srcSet={sourceSet}
          alt={alt}
        />
      )
    } else if ('icon' in source) {
      return (
        <Center classNames={[$.imageView, 'icon', classNames]} style={style}>
          <SVG name={(source as any).icon}/>
        </Center>
      )
    } else if (source.type === 'svg') {
      return (
        <SVG
          classNames={[$.imageView, 'svg', {blocks}, classNames]}
          svg={atob(source.base64)}
          style={style}
          title={alt}
        />
      )
    } else {
      return (
        <img
          classNames={[$.imageView, 'remote', classNames]}
          style={style}
          src={source.url}
          alt={alt}
        />
      )
    }
  }

  return render()

})

export default ImageView

export const cardBorderRadius = 0.1

const useStyles = createUseStyles(theme => ({
  imageView: {
    background: theme.bg.alt,
    overflow:   'hidden',

    '&.icon, &.svg': {
      '& svg': {
        width:  '80%',
        height: '80%',
      },
    },

    '&.blocks': {
      background:     'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACPTkDJAAAAR0lEQVRYCe3WsQ0AIAgEQHUN9h+NOTTO8CY2R/8JOYpndvcewVTVDOJjJeEXWQsQIECAAAECUZffOk7/CScgQIAAAQIEvgscKScH7V2VrVQAAAAASUVORK5CYII=)',
      backgroundSize: ['20%', '20%'],
    },
    '&:not(.blocks)': {
      background: 'transparent',
    },
  },
}))