import { action, makeObservable, observable } from 'mobx'
import { Post } from '~/models'
import { NewsService } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class CommentFormModel implements FormModel {

  constructor(
    private readonly service: NewsService,
    private readonly postID: string,
    private readonly comment: Post | null,
  ) {
    makeObservable(this)
  }

  @observable
  public feed: string = this.comment?.feed.id ?? this.service.feeds[0]?.id ?? null

  @observable
  public body: string = this.comment?.body ?? ''

  @observable
  public replyToComment: Post | null = null

  @action
  public replyTo(comment: Post | null) {
    this.replyToComment = comment
  }

  //------
  // Submit

  public get maySubmit() {
    if (this.body.trim().length > 1) { return true }
    return false
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const document = this.service.posts.document(this.postID)
    const data = {
      parent: this.comment == null ? this.replyToComment?.id ?? null : null,
      body:   this.body,
    }

    if (this.comment == null) {
      return document.addComment(this.postID, data)
    } else {
      return document.updateComment(this.comment.id, data)
    }
  }

  @action
  public reset() {
    this.replyTo(null)
    this.body = this.comment?.body ?? ''
  }

}