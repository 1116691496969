import React from 'react'

export interface InlineFormContext {

  editingField: string | null
  startEditing: (field: string) => void
  stopEditing:  () => void

}

export const InlineFormContext = React.createContext<InlineFormContext | null>(null)