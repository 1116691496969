import socket from 'socket.io-react'
import { Database } from '~/lib/mobx-document'
import { Sender } from '~/models'
import { profileStore } from '~/stores'
import { ChatService, ChatServiceOptions } from './chat'
import SendersDocument from './chat/senders/SendersDocument'
import { register } from './support'

export class ChatStore {

  public chatService(profileID: string, options: ChatServiceOptions = {}) {
    return new ChatService(socket, profileID, options)
  }

  public readonly senders = new Database<SendersDocument>({
    getID: (sender) => sender.id,
    getDocument: sender => new SendersDocument(sender.id, {initialData: sender}),
    emptyDocument: id => new SendersDocument(id),
  })

  //------
  // Sender

  public get sender(): Sender | null {
    const {participant} = profileStore
    if (participant == null) { return null }

    return {
      type:      'participant',
      id:        participant.id,
      firstName: participant.firstName,
      lastName:  participant.lastName,
      photoURL:  participant.photoURL,
      headline:  participant.headline,
    }
  }
}

export default register(new ChatStore())