import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { Center, VBox, Dimple, Label, SVG } from '~/ui/components'
import { layout } from '~/ui/styling'

const ConnectionsHeader = observer('ConnectionsHeader', () => {

  const [t] = useTranslation('connections')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <Center>
          <SVG name='handshake' size={layout.icon.xxl} dim/>
        </Center>
        <Label dim truncate={false} align='center'>
          {t('preamble')}
        </Label>
        <Dimple horizontal/>
        <div/>
      </VBox>
    )
  }

  return render()

})

export default ConnectionsHeader