import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import i18next from 'i18next'
import { memo } from '~/ui/component'
import { Center, ClearButton, HBox, Spinner, VBox } from '~/ui/components'
import { animation, colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  open:         boolean
  url:          string | null
  requestClose:    () => any
  classNames?:  React.ClassNamesProp
}

const InAppBrowser = memo('InAppBrowser', (props: Props) => {

  const {open, requestClose} = props

  const [iframeLoaded, setIframeLoaded] = React.useState<boolean>(false)

  const url = React.useMemo(
    () => props.url == null ? null : new URL(props.url), [props.url],
  )

  const handleLoaded = React.useCallback(() => {
    setIframeLoaded(true)
  }, [])

  React.useEffect(() => {
    setIframeLoaded(false)
  }, [url])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <TransitionGroup>
          {open && (
            <CSSTransition timeout={inAppBrowserTransitionDuration}>
              <VBox flex='both' classNames={$.InAppBrowser}>
                {renderContent()}
              </VBox>
            </CSSTransition>
          )}
        </TransitionGroup>
      </ThemeProvider>
    )
  }

  function renderContent() {
    return (
      <VBox flex='both' classNames={$.content}>
        {renderCloseButton()}
        {renderIframe()}
      </VBox>
    )
  }

  function renderCloseButton() {
    return (
      <HBox justify='right' classNames={$.closeButton}>
        <ClearButton
          icon='cross'
          caption={i18next.t('buttons:close')}
          onTap={requestClose}
          padding='both'
          color={colors.white}
          backgroundColor={colors.shim.dark}
          large
        />
      </HBox>
    )
  }

  function renderIframe() {
    if (url == null) { return null }

    const source = url.toString()
    return (
      <VBox flex='both' classNames={[$.iframeWrapper, {loaded: iframeLoaded}]}>
        {!iframeLoaded && renderLoading()}
        <iframe
          title={source}
          src={source}
          classNames={$.iframe}
          onLoad={handleLoaded}
        />
      </VBox>
    )
  }

  function renderLoading() {
    return (
      <Center flex classNames={$.loading}>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

export default InAppBrowser

const inAppBrowserTransitionDuration = animation.durations.long

const useStyles = createUseStyles({
  InAppBrowser: {
    ...layout.overlay,
    position: 'fixed',
    zIndex: layout.z.inAppBrowser,
    background: colors.shim.dark,

    '&.enter-active, &.exit-active': {
       transition: animation.transition('background', inAppBrowserTransitionDuration),
      '& $content': {
        transition: animation.transition('transform', inAppBrowserTransitionDuration),
      },
    },
    '&.enter:not(.enter-active), &.exit-active': {
      background: colors.shim.dark.alpha(0),
      '& $content': {
        transform: 'translateY(100%)',
      },
    },
    '&.enter-active, &.exit:not(.exit-active)': {
      background: colors.shim.dark,
      '& $content': {
        transform: 'translateY(0)',
      },
    },
  },

  content: {
    position: 'relative',
  },

  iframeWrapper: {
    position: 'relative',
    '&.loaded': {
      '& $iframe': {
        opacity: 1,
      },
      '& $loading': {
        opacity: 0,
      },
    },
  },

  iframe: {
    position: 'relative',
    zIndex: 100,
    flex: 1,
    border: 0,
    opacity: 0,
    transition: animation.transition('opacity', animation.durations.medium),
  },

  closeButton: {
    position: 'absolute',
    top: layout.padding.inline.l,
    right: layout.padding.inline.xl,
    zIndex: 200,
  },

  loading: {
    ...layout.overlay,
    background: 'transparent',
    transition: animation.transition('opacity', animation.durations.medium),
    opacity: 1,
  },
})