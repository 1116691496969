import { ComponentBranding, ComponentBrandingBase } from '../components'
import { BrandingGuideDelegate } from '../types'
import { AppTabBarSpec } from './types'

export default class AppTabBarBranding extends ComponentBrandingBase<AppTabBarSpec> {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: AppTabBarBranding | null,
  ) {
    super(delegate,parent, {
      background:      'alt',
      border:          'none',
      shape:           'rectangle',
      depth:           0,
      margin:          0,
      padding:         8,
      iconSize:        28,
      iconColor:       'fg-dark-normal',
      activeIconColor: 'primary',
    })

    this.variant('ios')
    this.variant('android')
  }

  public readonly previewSize = {width: 320, height: 80}

  public cameraIcon = new ComponentBranding(this, null, {
    background: 'primary',
    border:     'none',
    shape:      'oval',
    depth:      2,
  })

  public badge = new ComponentBranding(this, this.rootGuide.badge, {
    previewSize: {width: 24, height: 24},
  })

  public resolveBarHeight(safeArea: number, flags: Record<string, boolean>) {
    const iconSize = this.resolve('iconSize', flags)
    const margin   = this.resolve('margin', flags)
    const padding  = this.resolve('padding', flags)

    if (margin > 0) {
      // The bar will be detached (float), so we don't need to take care of the safe area.
      return iconSize + 2 * padding
    } else {
      // The bar will be attached to the bottom of the screen, so the padding should include safe area.
      return iconSize + 2 * padding + safeArea
    }
  }

  public resolveTotalHeight(safeArea: number, flags: Record<string, boolean>) {
    const iconSize = this.resolve('iconSize', flags)
    const margin   = this.resolve('margin', flags)
    const padding  = this.resolve('padding', flags)

    return iconSize + 2 * padding + margin + safeArea
  }

  public resolveScreenPadding(safeArea: number, flags: Record<string, boolean>) {
    return this.resolveTotalHeight(safeArea, flags)
  }

}