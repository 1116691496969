import React from 'react'
import { useTranslation } from 'react-i18next'
import { notificationsStore } from '~/stores'
import { observer } from '~/ui/component'
import { PushButton, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import NotificationsList from './NotificationsList'

const NotificationsScreen = observer('NotificationsScreen', () => {

  const [t] = useTranslation('notifications')
  const endpoint = notificationsStore.myNotifications

  const markAllAsSeen = React.useCallback(() => {
    notificationsStore.markAllAsSeen()
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <AppLayoutConfig
          title={t('title')}
          ActionsComponent={renderActions}
        />
        <NotificationsList endpoint={endpoint} />
      </VBox>
    )
  }

  function renderActions() {
    return (
      <PushButton
        caption={t('mark_as_seen')}
        enabled={notificationsStore.hasUnseenNotifications}
        onTap={markAllAsSeen}
        small
      />
    )
  }

  return render()

})

export default NotificationsScreen