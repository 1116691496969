import React from 'react'
import { DragDropMonitor, DraggableItem, ElementConnect, useDrop, UseDropSpec } from 'react-dnd'
import { SortableItem } from './data'
import { useElementConnect } from './hooks'
import { calculateDropIndex, registerList, unregisterList } from './measurements'

export default function useSortableDrop<TItem extends DraggableItem & SortableItem>(
  spec: UseSortableDropSpec<TItem>,
): [ElementConnect, ElementConnect, ElementConnect] {
  const [connect] = useDrop<TItem>({
    ...spec,

    hover: (item, point, monitor) => {
      const list = getList() ?? getContainer()
      const top  = point.y - monitor.itemLayout.offsetSize.height / 2

      if (list) {
        const hoverIndex = calculateDropIndex(list, top, monitor.itemLayout.offsetSize.height)
        spec.hover?.(item, hoverIndex > item.index ? hoverIndex + 1 : hoverIndex, monitor)
      } else {
        spec.hover?.(item, 0, monitor)
      }
    },

    drop: (item, point, monitor) => {
      const list = getList() ?? getContainer()
      const top  = point.y - monitor.itemLayout.offsetSize.height / 2

      if (list) {
        const dropIndex = calculateDropIndex(list, top, monitor.itemLayout.offsetSize.height)
        return spec.drop?.(item, dropIndex, monitor)
      } else {
        return spec.drop?.(item, 0, monitor)
      }
    },
  })

  const [connectDropTarget, getContainer]    = useElementConnect(connect)
  const [connectList, getList]               = useElementConnect()
  const [connectPlaceholder, getPlaceholder] = useElementConnect()

  const prevListRef     = React.useRef<HTMLElement>()

  React.useEffect(() => {
    const list        = getList() ?? getContainer()
    const prevList    = prevListRef.current
    const placeholder = getPlaceholder()

    if (list == null) { return }
    if (list === prevList) { return }

    prevListRef.current = list

    registerList(list, placeholder)
    return () => {
      if (prevList != null) {
        unregisterList(prevList)
      }
    }
  })

  return [connectDropTarget, connectList, connectPlaceholder]
}

export interface UseSortableDropSpec<TItem extends SortableItem> extends Omit<UseDropSpec<TItem>, 'hover' | 'drop'> {
  list?:  string
  hover?: (item: TItem, index: number, monitor: DragDropMonitor) => any
  drop?:  (item: TItem, index: number, monitor: DragDropMonitor) => any | Promise<any>
}