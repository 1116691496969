import React from 'react'

const registry: Map<string, React.ComponentType<SearchResultProps<any>>> = new Map()

export function getSearchResultComponent(resource: string) {
  for (const [name, Component] of registry) {
    if (name === resource) {
      return Component
    }
  }

  return null
}

export function register<M>(resource: string, component: React.ComponentType<SearchResultProps<M>>) {
  registry.set(resource, component)
}

export interface SearchResultProps<M> {
  item: M
}