import React from 'react'
import { Key } from './types'

export interface VirtualizedChatContext {
  recalculateRowHeight: (key: Key) => any
}

export const VirtualizedChatContext = React.createContext<VirtualizedChatContext>({
  recalculateRowHeight: () => void 0,
})

export function useVirtualizedChat() {
  return React.useContext(VirtualizedChatContext)
}