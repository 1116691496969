import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useHistory } from '~/history'
import { ClientTab } from '~/models'
import { bewizrStore, connectionsStore, projectStore } from '~/stores'
import { useChatService } from '~/ui/app/ChatContainer'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import AppSideBarMoreButton from './AppSideBarMoreButton'
import AppSideBarNavigationButton from './AppSideBarNavigationButton'

const AppSideBarNavigationTabs = observer('AppSideBarNavigationTabs', () => {

  const [t] = useTranslation()

  const chatService = useChatService()
  const projectTabs = projectStore.tabs
  const connectionsRequestCount = connectionsStore.requestCount
  const chatUnreadCount = chatService?.totalUnreadCount

  const tabs = React.useMemo(
    () => projectTabs.filter(it => /^(?!\/\/chat).*/.test(it.link.href)),
    [projectTabs],
  )

  const location = useLocation()
  const history  = useHistory()

  const tabIsActive = React.useCallback((tab: ClientTab) => {
    const fallback  = projectStore.activeTabForPath(location.pathname)
    const activeTab = history.stack.length === 0
      ? fallback
      : projectStore.activeTabForPath(history.stack[0].pathname) ?? fallback
    return activeTab === tab
  }, [history.stack, location.pathname])

  const badgeForTab = React.useCallback((tab: ClientTab) => {
    if (/^\/\/connections/.test(tab.link.href)) {
      return connectionsRequestCount
    } else if (/^\/\/chat/.test(tab.link.href)) {
      return chatUnreadCount
    }
  }, [chatUnreadCount, connectionsRequestCount])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.xl} classNames={$.AppSideBarNavigation}>
        <VBox gap={layout.padding.inline.s}>
          {renderTabs()}
        </VBox>
        <VBox gap={layout.padding.inline.s}>
          {renderDashboardButton()}
          <AppSideBarMoreButton/>
        </VBox>
      </VBox>
    )
  }

  const renderTabs = React.useCallback(() => tabs.map(tab => (
      <AppSideBarNavigationButton
        key={tab.link.href}
        icon={tab.icon.value}
        link={tab.link}
        caption={tab.caption}
        badge={badgeForTab(tab)}
        active={tabIsActive(tab)}
      />
  )), [badgeForTab, tabIsActive, tabs])

  function renderDashboardButton() {
    if (!bewizrStore.profile?.hasDashboardAccess) { return null }

    return (
      <AppSideBarNavigationButton
        icon='column-chart'
        caption={t('app:nav.dashboard')}
        link={{href: 'https://be.wizr.eu/dashboard/'}}
      />
    )
  }

  return render()

})

export default AppSideBarNavigationTabs

const useStyles = createUseStyles(theme => ({
  AppSideBarNavigation: {
    margin: [0, layout.padding.inline.l * -1],
  },
}))