import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Participant } from '~/models'
import { profileStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class ProfileFormModel implements FormModel {

  constructor(
    public readonly participant: Participant,
  ) {
    makeObservable(this)
  }

  @observable
  public firstName: string = this.participant.firstName ?? ''

  @observable
  public lastName: string = this.participant.lastName ?? ''

  @observable
  public headline: string = this.participant.headline ?? ''

  @observable
  public bio: string = this.participant.bio ?? ''

  public async submit(): Promise<SubmitResult | undefined> {
    return await profileStore.updateProfile(this.buildUpdate())
  }

  private buildUpdate() {
    return {
      firstName: cleanTextValue(this.firstName),
      lastName:  cleanTextValue(this.lastName),
      headline:  cleanTextValue(this.headline),
      bio:       cleanTextValue(this.bio),
    }
  }

}