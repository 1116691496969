import ScrollManager from './ScrollManager'

export class DocumentScrollManager extends ScrollManager {

  public constructor() {
    super(null)
  }

}

export default new DocumentScrollManager()