import { makeAutoObservable } from 'mobx'
import socket, { Socket, SocketStatus } from 'socket.io-react'
import config from '~/config'
import authenticationStore from './authenticationStore'
import { register } from './support'

export class SocketStore {

  constructor() {
    makeAutoObservable(this)
  }

  public socketStatus: SocketStatus = 'disconnected'

  public connect(authToken: string | null) {
    this.disconnect()
    if (authToken == null) { return }

    socket.connect(config.api.socketURI, {
      query:      {token: authToken},
      transports: ['websocket', 'polling'],
    })
  }

  public disconnect() {
    socket.disconnect()
  }

  //------
  // Status

  public init() {
    socket.addStatusChangeListener(this.onSocketStatus)
    authenticationStore.on('logout', this.onLogOut)
  }

  private onSocketStatus = (_: Socket, status: SocketStatus) => {
    this.socketStatus = status
  }

  //------
  // App events

  public onLogOut() {
    socket.disconnect()
  }

  public onDeactivate() {
    socket.disconnect()
  }

}

const socketStore = register(new SocketStore())
export default socketStore