import React from 'react'
import { useTranslation } from 'react-i18next'
import { Question } from '~/models'
import { participantsStore, profileStore, qAndAsStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import {
  Chip,
  ClearButton,
  ConfirmBox,
  HBox,
  Label,
  Panel,
  PanelHeader,
  Spinner,
  Tappable,
  TextBlock,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  question: Question
}

const QuestionListItem = observer('QuestionListItem', (props: Props) => {

  const {question} = props

  const participantDocument = participantsStore.participants.document(question.participant)
  const participant         = participantDocument.data

  const [t] = useTranslation('q-and-as')

  React.useEffect(() => {
    participantDocument.fetchIfNeeded()
  }, [participantDocument])

  const upvote = React.useCallback(() => {
    const document = qAndAsStore.questions.document(question.id)
    return document.upvote()
  }, [question.id])

  const downvote = React.useCallback(() => {
    const document = qAndAsStore.questions.document(question.id)
    return document.downvote()
  }, [question.id])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('confirm_remove'),
      destructive: true,
    })
    if (!confirmed) { return }

    const document = qAndAsStore.questions.document(question.id)
    return document.delete()
  }, [question.id, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} header={renderHeader()}>
        {renderBody()}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={renderParticipant()}
        right={renderVotes()}
      />
    )
  }

  function renderParticipant() {
    if (participantDocument.fetchStatus === 'fetching') {
      return (
        <HBox>
          <Spinner size={8}/>
        </HBox>
      )
    }
    if (participant == null) { return null }

    return (
      <Tappable href={`/participants/-/${participant.id}`}>
        <HBox gap={layout.padding.inline.m}>
          <Avatar
            source={participant.photoURL}
            {...participant}
            size={avatarSize}
          />
          <Label flex small bold>
            {participant.name}
          </Label>
        </HBox>
      </Tappable>
    )
  }

  function renderVotes() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Chip>
          {t('vote_count', {count: question.voteCount})}
        </Chip>
        {question.participant === profileStore.participantID ? (
          renderRemoveButton()
        ) : (
          renderUpvoteButton()
        )}
      </HBox>
    )
  }

  function renderUpvoteButton() {
    return (
      <ClearButton
        caption={question.voted ? '★' : '☆'}
        onTap={question.voted ? downvote : upvote}
        padding='both'
      />
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        icon='trash'
        onTap={remove}
        padding='both'
        small
      />
    )
  }

  function renderBody() {
    return (
      <VBox classNames={$.body} gap={layout.padding.inline.s}>
        <Label h3 truncate={false}>
          {question.title}
        </Label>
        {question.body != null && (
          <TextBlock small dim markup>
            {question.body}
          </TextBlock>
        )}
      </VBox>
    )
  }

  return render()

})

export default QuestionListItem

export const avatarSize = {
  width:  24,
  height: 24,
}

const useStyles = createUseStyles({
  body: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})