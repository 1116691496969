import { CustomImage } from './CustomImage'
import { Link } from './Link'
import { Model } from './Model'

export abstract class Page extends Model {

  public title!: string
  public slug!:  string

  public static deserialize(raw: AnyObject) {
    const page = this.create(raw)
    page.deserialize()
    return page as any
  }

  private static create(raw: AnyObject): Page {
    const {type} = raw
    switch (type) {
    case 'menu':    return new MenuPage(raw)
    case 'index':   return new IndexPage(raw)
    case 'content': return new ContentPage(raw)
    default:        throw new Error(`Invalid page type: ${type}`)
    }
  }

}

//------
// Menu page

export class MenuPage extends Page {

  public type!:    'menu'
  public layout!:  MenuLayout
  public caption!: string | null
  public items?:   MenuPageItem[]

}

export type MenuLayout = 'list' | 'grid'

export interface MenuPageItem {
  image:   CustomImage | null
  caption: string
  detail:  string | null
  link:    Link
  extra:   Record<string, any>
}

//------
// Index page

export class IndexPage extends Page {

  public type!:    'index'
  public caption!: string | null

  public itemType!: string
  public index?:    IndexWithItems

}

export interface Index {
  entries:    Array<IndexEntry>
  total:      number
  nextOffset: number | null
}

export interface IndexWithItems<T = any> {
  entries:    Array<IndexEntry & {item: T}>
  total:      number
  nextOffset: number | null
}

export interface IndexEntry {
  id:      string
  image:   CustomImage | null
  caption: string
  href:    string | null
}

//------
// Content page

export class ContentPage extends Page {

  public type!: 'content'
  public body!: string

}