import config from './config'

let root: HTMLDivElement | null = null

export function getRoot() {
  return config.getRoot?.() ?? root ?? createRoot()
}

export function destroyRoot() {
  root?.remove()
  root = null
}

function createRoot() {
  root = document.createElement('div')
  root.classList.add('ModalPortal--root')

  document.body.appendChild(root)
  return root
}