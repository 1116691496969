import React from 'react'
import { createUseStyles } from '~/ui/styling'
import { Label, LabelProps } from './label'

export type Props = Omit<LabelProps, 'truncate'>

const TextBlock = (props: Props) => {

  const {classNames, convertNewlines = true, ...rest} = props

  const $ = useStyles()

  return (
    <Label
      classNames={[$.textBlock, classNames]}
      truncate={false}
      convertNewlines={convertNewlines}
      {...rest}
    />
  )

}

export default TextBlock

const useStyles = createUseStyles({
  textBlock: {
    lineHeight: 1.4,
  },
})