import React from 'react'
import { useTranslation } from 'react-i18next'
import { Answer, Challenge, ChallengeTask } from '~/models'
import { challengesStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Checkbox, Label, List, ListBar, SVG } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  challenge: Challenge
}

const ChallengeTaskList = memo('ChallengeTaskList', (props: Props) => {

  const {challenge} = props

  //------
  // Rendering

  function render() {
    return (
      <List
        flex='grow'
        data={challenge.tasks}
        keyExtractor={task => task.uuid}
        renderItem={renderTaskBar}
        itemGap={layout.padding.inline.s}
      />
    )
  }

  function renderTaskBar(task: ChallengeTask, index: number) {
    return (
      <TaskBar
        challengeID={challenge.id}
        task={task}
        previousTask={challenge.tasks[index - 1] ?? null}
      />
    )
  }

  return render()

})

export default ChallengeTaskList

interface TaskBarProps {
  challengeID:  string
  task:         ChallengeTask
  previousTask: ChallengeTask | null
}

const TaskBar = observer('TaskBar', (props: TaskBarProps) => {

  const theme = useTheme()
  const {challengeID, task, previousTask} = props

  const [t] = useTranslation('challenges')

  const document      = challengesStore.challenges.document(challengeID)
  const answers       = document.answers
  const completed     = challengesStore.taskCompleted(challengeID, task.uuid)
  const prevCompleted = previousTask == null ? true : challengesStore.taskCompleted(challengeID, previousTask.uuid)

  const answer   = answers[task.uuid] as Answer | null
  const score    = answer?.score ?? null
  const correct  = answer?.correct ?? null
  const inReview = task?.question?.review && (answer?.status === 'pending' || answer?.status === 'review')

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={renderCheck()}
        accessory={prevCompleted && renderChevron()}
        detail={renderDetail()}
        caption={renderCaption()}
        href={prevCompleted ? `/challenges/-/${challengeID}/tasks/${task.uuid}` : undefined}
      />
    )
  }

  function renderChevron() {
    return (
      <SVG
        size={{width: chevronSize, height: chevronSize}}
        color={theme.fg.dimmer}
        name='chevron-right'
      />
    )
  }

  function renderCaption() {
    return (
      <Label bold truncate dim={!prevCompleted}>
        {task.title}
      </Label>
    )
  }

  function renderDetail() {
    if (completed) {
      return (
        <Label small dim markup>
          {inReview ? t('task.status.in_review.summary') : `${getCompletedLabel()}${getScoreLabel()}`}
        </Label>
      )
    }
    else if (task.subtitle != null) {
      return (
        <Label small>
          {task.subtitle}
        </Label>
      )
    }
    else { return null }
  }

  function renderCheck() {
    return (
      <Checkbox
        enabled={prevCompleted}
        checked={completed}
      />
    )
  }

  function getCompletedLabel() {
    if (correct == null) {
      return t('task.status.completed.caption')
    } else if (correct) {
      return t('task.status.correct.caption')
    } else {
      return t('task.status.incorrect.caption')
    }
  }

  function getScoreLabel() {
    if (score == null) { return '' }

    if (task.maxScore != null) {
      return t('task.score_out_of.suffix', {score: score.formatted, maxScore: task.maxScore})
    } else {
      return t('task.score.suffix', {score: score.formatted})
    }
  }

  return render()
})

export const chevronSize = 15