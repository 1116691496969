import React from 'react'
import { useSize } from 'react-measure'
import { range } from 'lodash'
import { Document, Endpoint } from 'mobx-document'
import { BeWizrModel } from '~/models'
import { observer } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props<M extends BeWizrModel, D extends Document<M>> {
  endpoint:   Endpoint<D>
  renderItem: (item: M) => React.ReactNode
}

const _BeWizrGrid = <M extends BeWizrModel, D extends Document<M>>(props: Props<M,D>) => {

  const {endpoint, renderItem} = props

  const items = endpoint.data

  const gridRef = React.useRef<HTMLDivElement>(null)
  const [gridSize, setGridSize] = React.useState<Size>({width: 0, height: 0})
  useSize(gridRef, setGridSize)

  const availableWidth = React.useMemo(
    () => gridSize.width,
    [gridSize.width],
  )

  const columns = React.useMemo(
    () => gridSize.width === 0 ? 1 : Math.floor(availableWidth / (minTileWidth + itemGap)),
    [availableWidth, gridSize],
  )

  const rows = React.useMemo(
    () => Math.ceil(items.length / columns),
    [items.length, columns],
  )

  const columnWidth = availableWidth / columns

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={itemGap}>
        <VBox ref={gridRef} gap={itemGap}>
          {range(0, rows).map(renderRow)}
        </VBox>
      </VBox>
    )
  }

  function renderRow(row: number) {
    return (
      <HBox align='stretch' justify='left' key={row} gap={itemGap}>
        {items.slice(columns * row, columns * (row + 1)).map(item => (
          <VBox tag='article' key={item.id} flex style={{maxWidth: columnWidth}}>
            {renderItem(item)}
          </VBox>
        ))}
      </HBox>
    )
  }

  return render()

}

const BeWizrGrid = observer('BeWizrGrid', _BeWizrGrid) as typeof _BeWizrGrid
export default BeWizrGrid

export const gridPadding  = layout.padding.inline.l
export const itemGap      = layout.padding.inline.l
export const minTileWidth = 270
