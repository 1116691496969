import React from 'react'
import { Map as ImmutableMap } from 'immutable'
import { ToastItem, ToastItemProps, ToastProps } from './types'

type Props = ToastProps
type ToastItemElement = React.ReactElement<ToastItemProps>
type UID = number

let REF: Toast | null = null
let UID: UID = 0

interface State {
  uids:        UID[]
  items:       ImmutableMap<UID, ToastItemElement>
  transitions: ImmutableMap<UID, ToastItemElement>
}

export default class Toast extends React.Component<Props, State> {

  //------
  // State

  public state: State = {
    uids:        [],
    items:       ImmutableMap(),
    transitions: ImmutableMap(),
  }

  private uniques: Set<string> = new Set()

  private itemElements: Map<number, HTMLDivElement> = new Map()

  private itemRef = (uid: number) => (el: HTMLDivElement | null) => {
    if (el) {
      this.itemElements.set(uid, el)
    } else {
      this.itemElements.delete(uid)
    }
  }

  private placeholders: Map<number, HTMLDivElement> = new Map()

  private placeholderRef = (uid: number) => (el: HTMLDivElement | null) => {
    if (el) {
      this.placeholders.set(uid, el)
    } else {
      this.placeholders.delete(uid)
    }
  }

  //------
  // Interface

  public async addItem(item: ToastItem) {
    if (item.unique != null) {
      if (this.uniques.has(item.unique)) { return }
      this.uniques.add(item.unique)
    }

    const uid = UID++

    const placeholder = (
      <Placeholder
        key={uid}
        ref={this.placeholderRef(uid)}
      />
    )

    const element = (
      <div key={uid} style={{...$.itemContainer, paddingTop: (this.props.itemGap ?? 0)}}>
        <this.props.ItemComponent
          {...item}
          ref={this.itemRef(uid)}
          dismiss={() => { this.removeItem(uid) }}
        />
      </div>
    )

    this.setState({
      uids:        [uid],
      items:       this.state.items.set(uid, placeholder),
      transitions: this.state.transitions.set(uid, element),
    }, () => {
      this.animateIn(uid, () => {
        this.setState({
          items:       this.state.items.set(uid, element),
          transitions: this.state.transitions.delete(uid),
        })
      })

      this.queueRemove(uid, item.lifetime ?? 5000)
    })
  }

  public static show(item: ToastItem) {
    if (REF == null) {
      throw new Error("No <Toast> element found, have you added it to your application?")
    }

    REF.addItem(item)
  }

  public static success(item: Omit<ToastItem, 'type'>) {
    this.show({type: 'success', ...item})
  }

  public static warning(item: Omit<ToastItem, 'type'>) {
    this.show({type: 'warning', ...item})
  }

  public static error(item: Omit<ToastItem, 'type'>) {
    this.show({type: 'error', ...item})
  }

  //------
  // Item management

  private removeTimers: Map<UID, number> = new Map()

  public queueRemove(uid: number, delay: number) {
    this.removeTimers.set(uid, this.setTimeout(() => {
      this.removeItem(uid)
    }, delay))
  }

  public removeItem(uid: number) {
    const timer = this.removeTimers.get(uid)
    if (timer != null) { this.clearTimeout(timer) }
    this.removeTimers.delete(uid)

    const element = this.state.items.get(uid)
    if (element?.props?.unique != null) {
      this.uniques.delete(element.props.unique)
    }

    this.animateOut(uid, () => {
      this.setState({
        uids:        this.state.uids.filter(u => u !== uid),
        items:       this.state.items.delete(uid),
        transitions: this.state.transitions.delete(uid),
      })
    })
  }

  //------
  // Animation

  public animateIn(uid: number, callback: () => any) {
    const element     = this.itemElements.get(uid)
    const placeholder = this.placeholders.get(uid)
    if (element == null || placeholder == null) { return }

    const {duration} = this.props
    const height = element.offsetHeight

    element.style.transition = ''
    element.style.transform  = 'translateY(-100%)'
    element.style.opacity    = '0'

    placeholder.style.transition = ''
    placeholder.style.height = '0'

    this.setTimeout(() => {
      element.style.transition = ['transform', 'opacity'].map(prop => `${prop} ${duration}ms cubic-bezier(0, 0, 0.35, 1.61)`).join(', ')
      element.style.transform  = 'translateY(0)'
      element.style.opacity    = '1'

      placeholder.style.transition = `height ${duration}ms ease-in-out`
      placeholder.style.height = `${height}px`
    }, 16)

    this.setTimeout(callback, 16 + duration)
  }

  public animateOut(uid: UID, callback: () => any) {
    const element = this.itemElements.get(uid)
    if (element == null) { return }

    const {duration} = this.props

    element.style.transition = ''
    element.style.transform  = 'translateY(0)'
    element.style.opacity    = '1'

    this.setTimeout(() => {
      element.style.transition = ['transform', 'opacity'].map(prop => `${prop} ${duration}ms ease-out`).join(', ')
      element.style.transform  = 'translateY(-100%)'
      element.style.opacity    = '0'
    }, 16)

    this.setTimeout(callback, 16 + duration)
  }

  //------
  // Component lifecycle

  private timers: Set<number> = new Set()

  public setTimeout(fn: () => any, delay: number) {
    const timer = window.setTimeout(() => {
      this.clearTimeout(timer)
      fn()
    }, delay)
    this.timers.add(timer)
    return timer
  }

  public clearTimeout(timer: number) {
    window.clearTimeout(timer)
    this.timers.delete(timer)
  }

  public componentDidMount() {
    REF = this
  }

  public componentWillUnmount() {
    REF = null

    for (const timer of this.timers) {
      this.clearTimeout(timer)
    }
  }

  public render() {
    const {uids, items, transitions} = this.state

    return (
      <div className={this.props.className} style={$.Toast}>
        <div style={$.transitions}>
          {uids.map(uid => transitions.get(uid))}
        </div>
        <div style={$.content}>
          {uids.map(uid => items.get(uid))}
        </div>
      </div>
    )
  }

}

const Placeholder = React.forwardRef((props: {}, ref: React.Ref<HTMLDivElement>) => (
  <div style={$.placeholder} ref={ref}/>
))

const $: Record<string, React.CSSProperties> = {
  Toast: {
    pointerEvents: 'none',
    overflow:      'hidden',
  },

  transitions: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      0,
  },

  itemContainer: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      0,
  },

  content: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      0,

    display: 'flex',
    flexDirection: 'column',
  },
}