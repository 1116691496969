import React from 'react'
import { Message } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, PushButton } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { ChatChannelController } from './types'

export interface Props {
  message:           Message
  channelController: ChatChannelController
}

const ChatMessageModeration = memo('ChatMessageModeration', (props: Props) => {

  const {message, channelController} = props
  const needsModeration = message.approvedAt === null

  const theme = useTheme()

  const approve = React.useCallback(() => {
    channelController.approveMessage?.(message.id)
  }, [channelController, message.id])

  const redact = React.useCallback(() => {
    channelController.redactMessage?.(message.id)
  }, [channelController, message.id])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s}>
        {needsModeration && (
          <PushButton
            icon='check'
            color={theme.semantic.positive}
            onTap={approve}
            small
          />
        )}
        {needsModeration && (
          <PushButton
            icon='cross'
            color={theme.semantic.negative}
            onTap={redact}
            small
          />
        )}
        {!needsModeration && (
          <ClearButton
            icon='cross'
            color={theme.semantic.negative}
            onTap={redact}
            padding='both'
            small
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ChatMessageModeration