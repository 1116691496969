import React from 'react'
import { forwardRef } from '~/ui/component'
import { createUseStyles } from '~/ui/styling'
import { flexStyle, useGridGapStyles, usePaddingStyles } from './styles'
import { Props as VBoxProps } from './VBox'

export interface Props extends VBoxProps {
  rows?:      string
  columns?:   string

  rowGap?:    VBoxProps['gap']
  columnGap?: VBoxProps['gap']

  children?:  React.ReactNode
}

const Grid = forwardRef('Grid', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    rows    = 'auto',
    columns = 'auto',
    padding,
    paddingHorizontal,
    paddingVertical,
    gap,
    columnGap = gap,
    rowGap    = gap,
    flex,
    width,
    ...rest
  } = props

  const gap$     = useGridGapStyles(columnGap, rowGap)
  const padding$ = usePaddingStyles(padding, paddingHorizontal, paddingVertical)

  //------
  // Rendering

  const $ = useStyles({rows, columns})

  const classNames = [
    $.grid,
    gap$.gap,
    padding$.padding,
  ]

  const style = {
    ...flexStyle(flex),
    width,
    ...props.style,
  }

  function render() {
    return (
      <div
        ref={ref}
        {...rest}
        classNames={classNames}
        style={style}
      />
    )
  }

  return render()

})

export default Grid

const useStyles = createUseStyles({
  grid: (data: {rows: string, columns: string}) => ({
    display:             'grid',
    gridTemplateRows:    data.rows,
    gridTemplateColumns: data.columns,
  }),
})