import { ContentBlock } from 'draft-js'
import AtomicBlock from './AtomicBlock'

export default function renderBlock(block: ContentBlock) {
  switch (block.getType()) {
    case 'atomic':
      return {
        component: AtomicBlock,
        editable:  false,
      }
  }
}