import React from 'react'
import { useVirtualizedChat } from 'react-virtualized-chat'
import ms from 'ms'
import config from '~/config'
import { Channel, isPendingMessage, MessageList, MessageListItem } from '~/models'
import { useClock } from '~/socket/useClock'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useChat } from './ChatContext'
import ChatMessage from './ChatMessage'

export interface Props {
  index:        number
  channel:      Channel
  messageList:  MessageList
  timestamp:    boolean
  itemRef:      React.Ref<HTMLDivElement>

  render?: (message: MessageListItem, defaultRender: () => React.ReactNode) => React.ReactNode
  style?:  React.CSSProperties
}

const ChatMessageListItem = observer('ChatMessageListItem', (props: Props) => {

  const {messageList, index, style, itemRef, render: props_render, ...rest} = props
  const {tick} = useClock({interval: 'once'})

  const {chat} = useChat()

  //------
  // Data

  const message  = messageList.messagesDescending[index]
  const previous = index < messageList.messagesDescending.length - 1 ? messageList.messagesDescending[index + 1] : null
  const next     = index > 0 ? messageList.messagesDescending[index - 1] : null

  const getFrom = React.useCallback((message: MessageListItem | null) => {
    if (message == null) {
      return null
    } else if (isPendingMessage(message)) {
      return chat?.sender?.id
    } else {
      return message.from
    }
  }, [chat?.sender?.id])

  const from         = getFrom(message)
  const previousFrom = getFrom(previous)
  const nextFrom     = getFrom(next)

  const newGroup    = from !== previousFrom

  const showTimestamp = React.useMemo(() => {
    if (message == null) { return false }
    if (message.type === 'notice') { return false }
    if (!isPendingMessage(message) && message.feedback != null) { return false }
    if (next == null) { return true }
    if (from !== nextFrom) { return true }

    const difference = next.sentAt.diff(message.sentAt).milliseconds
    return difference >= ms(config.chat.timestampInterval)
  }, [from, message, next, nextFrom])

  //------
  // Layout update

  const list = useVirtualizedChat()

  const recalculateRowHeight = React.useCallback(() => {
    list.recalculateRowHeight(message.id)
  }, [list, message.id])

  // Automatically update if the timestamp has changed.

  const prevTimestamp     = usePrevious(showTimestamp)
  const timestampChanged  = prevTimestamp != null && prevTimestamp !== showTimestamp

  const redacted          = message.status === 'redacted'
  const prevRedacted      = usePrevious(redacted)
  const redactedChanged   = redacted !== prevRedacted

  React.useEffect(() => {
    if (timestampChanged || redactedChanged) {
      recalculateRowHeight()
    }
  }, [recalculateRowHeight, redactedChanged, timestampChanged])

  const onFeedbackChange = React.useCallback(() => {
    tick()
    recalculateRowHeight()
  }, [recalculateRowHeight, tick])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const classNames = [
      $.chatMessageListItem,
      {newGroup, empty: message.type === 'redact'},
    ]

    return (
      <VBox classNames={classNames} style={style} ref={itemRef}>
        {props_render != null ? (
          props_render(messageList.messagesDescending[index], defaultRender)
        ) : message.type !== 'redact' ? (
          defaultRender()
        ) : null}
      </VBox>
    )
  }

  const defaultRender = React.useCallback(() => {
    return (
      <ChatMessage
        messageList={messageList}
        index={index}
        onFeedbackChange={onFeedbackChange}
        {...rest}
      />
    )
  }, [index, messageList, onFeedbackChange, rest])

  return render()

})

export default ChatMessageListItem

const useStyles = createUseStyles({
  chatMessageListItem: {
    '&:not(.empty)': {
      padding: [layout.padding.inline.s / 2, 0],

      '&.newGroup': {
        paddingTop: layout.padding.inline.l,
      },
    },
  },
})