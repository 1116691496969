import React from 'react'
import { some } from 'lodash'
import { MenuPage } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, Scroller, VBox } from '~/ui/components'
import MenuListItem from './MenuListItem'

export interface Props {
  page:  MenuPage

  HeaderComponent?: React.ComponentType<any> | null
  EmptyComponent?:  React.ComponentType<any> | null
}

const MenuListView = memo('MenuListView', (props: Props) => {

  const {
    page,
    HeaderComponent,
    EmptyComponent,
  } = props

  const {items = []} = page
  const hasImages = React.useMemo(
    () => some(items, item => item.image != null),
    [items],
  )


  //------
  // Rendering

  function render() {
    return (
      <Scroller>
        {items == null || items.length === 0 ? (
          EmptyComponent && <EmptyComponent/>
        ) : (
          renderMenu()
        )}
      </Scroller>
    )
  }

  function renderMenu() {
    return (
      <VBox flex>
        {HeaderComponent && <HeaderComponent/>}
        {HeaderComponent && <Dimple horizontal/>}
        {items.map((item, index) => (
          <VBox key={index}>
            {index > 0 && <Dimple horizontal/>}
            <MenuListItem
              item={item}
              hasImages={hasImages}
            />
          </VBox>
        ))}
      </VBox>
    )
  }

  return render()

})

export default MenuListView