import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Chip } from '~/ui/components'
import { colors } from '~/ui/styling'
import BeWizrTile from '../BeWizrTile'

export interface Props {
  id:           string
  title:        string
  itemCount:    number
  image:        string | null
  horizontal?:  boolean
}

const BeWizrLearningTrackTile = memo('BeWizrLearningTrackTile', (props: Props) => {

  const {title, id, image, itemCount, horizontal} = props

  const [t] = useTranslation('bewizr')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={title}
        detail={renderDetail()}
        href={`//bewizr-learning-track/${id}`}
        buttonCaption={t('learning-tracks.view')}
        image={image}
        horizontal={horizontal}
      />
    )
  }

  function renderDetail() {
    return (
      <Chip
        backgroundColor={colors.white}
        small
        icon='unordered-list'
        children={t('learning-tracks.items.count', {count: itemCount})}
      />
    )
  }

  return render()

})

export default BeWizrLearningTrackTile