// Many thanks to Mathias Bynens for this research, and all the emoji code points.
// https://mathiasbynens.be/notes/javascript-unicode

import { isArray, some } from 'lodash'
import { Predicate, Token } from './'
import Range from './Range'
import UnicodeString from './UnicodeString'

const CHARS = {
  whitespace: [
    0x0009, 0x000B, 0x000C, 0x000D, 0x0020, 0x0085, 0x00A0, 0x1680, 0x2000,
    0x2001, 0x2002, 0x2003, 0x2004, 0x2005, 0x2006, 0x2007, 0x2008, 0x2009,
    0x200A, 0x2028, 0x2029, 0x202F, 0x205F, 0x3000,
  ],
  newline:    0x000A,
}

/**
 * Unicode-aware string stream.
 */
export default class StringStream {

  //------
  // Constructor & properties

  constructor(source: string | UnicodeString | StringStream, options: StringStreamOptions = {}) {
    if (source instanceof UnicodeString) {
      this.string = source
    } else if (source instanceof StringStream) {
      this.string = source.string
      this._pos = source.pos
      this._start = source.start
    } else {
      this.string = new UnicodeString(source)
    }
    this.characters = this.string.characters
    this.codePoints = this.string.codePoints

    this.config = {
      escapeChar: null,
      ...options,
    }
  }

  public string:     UnicodeString
  public characters: string[]
  public codePoints: number[]

  private _start: number = 0
  public get start() {
    return this._start
  }

  private _pos: number = 0
  public get pos() {
    return this._pos
  }

  private _escaped: boolean = false
  public get escaped() {
    return this._escaped
  }

  private _current: string = ''
  public current(raw: boolean = false) {
    if (raw) {
      return this.slice(this._start, this._pos)
    } else {
      return this._current
    }
  }

  private readonly config: Required<StringStreamOptions>

  /** Start of string. */
  public get sos(): boolean { return this._pos === 0 }

  /** End of string. */
  public get eos(): boolean { return this._pos >= this.characters.length }

  /** Start of line. */
  public get sol(): boolean { return this.sos || this.codePoints[this._pos - 1] === CHARS.newline }

  /** End of line. */
  public get eol(): boolean { return this.eos || this.codePoints[this._pos] === CHARS.newline }

  /**
   * Gets the current stream range.
   */
  public get range(): Range {
    return new Range(this._start, this._pos)
  }

  /**
   * Gets the remainder of the string from the current position.
   */
  public get remainder(): string {
    return this.slice(this._pos)
  }

  //-----
  // Positions

  public advance(count: number) {
    this.next(count)
  }

  public advanceTo(pos: number) {
    const count = pos - this.pos
    this.next(count)
  }

  /**
   * Marks the current position as the start position for slicing.
   */
  public markStart() {
    this._start = this._pos
    this._current = ''
  }

  /**
   * Returns the next (count) characters and advances, if possible.
   */
  public next(count = 1): string | null {
    if (this._pos > this.characters.length - count) {
      return null
    }

    let string = ''

    const append = (str: string) => {
      string += str
      this._current += str
    }

    for (let i = 0; i < count; i++) {
      const isEscape = this.characters[this._pos] === this.config.escapeChar
      if (isEscape && !this._escaped) {
        this._escaped = true
        i -= 1
      } else {
        append(this.characters[this._pos])
        this._escaped = false
      }
      this._pos += 1
    }
    return string
  }

  /**
   * Resets the stream back to its default.
   */
  public reset() {
    this._pos = 0
    this._start = 0
  }

  //------
  // Slices

  /**
   * Creates a slice of our string. Use this instead of `this.string.slice`, as this version supports
   * unicode.
   */
  public slice(base: number, extent = -1): string {
    if (extent < 0) {
      extent += this.characters.length + 1
    }

    let text = ''
    for (let i = base; i < extent; i++) {
      if (i >= this.characters.length) { break }

      text += this.characters[i]
    }
    return text
  }

  /**
   * Creates a substring of our string. Use this instead of `this.string.substr` as this version
   * supports unicode.
   */
  public substr(start: number, length: number): string {
    return this.slice(start, start + length)
  }

  //------
  // Value retrieval

  /**
   * Creates a token from the current stream state.
   */
  public token(extra: Record<string, any>): Token {
    return {
      range: this.range,
      text:  this.current(),
      ...extra,
    }
  }

  //------
  // Matching & peeking

  /**
   * Matches the given predicate, returning the content, but does not advance the position.
   *
   * @param predicate
   *   A string or regular expression to look for.
   * @returns
   *   The content of the match, or `null` if the match failed.
   */
  public match(predicate: Predicate, lookahead = 0): boolean {
    if (this._escaped) { return false }

    if (predicate instanceof RegExp) {
      const match = this.slice(this._pos + lookahead, -1).match(predicate)
      return match != null && match.index === 0
    } else if (predicate instanceof Function) {
      const nextChars = this.peek(lookahead + 1)
      const charToCheck = nextChars && nextChars[nextChars.length - 1]
      return charToCheck != null && predicate(charToCheck)
    } else if (isArray(predicate)) {
      return some(predicate, p => this.match(p, lookahead))
    } else {
      const slice = this.substr(this._pos + lookahead, predicate.length)
      return slice === predicate
    }
  }

  /**
   * Peeks forward.
   *
   * @param count
   *   The number of characters to peek.
   */
  public peek(count = 1): string | null {
    if (this._pos > this.characters.length - count) {
      return null
    } else {
      return this.substr(this._pos, count)
    }
  }

  //------
  // Eating & skipping

  /**
   * Eats the given predicate if found.
   *
   * @param predicate
   *   A string or regular expression to look for, or a function which is passed the next character, and
   *   should return a boolean if it matches.
   * @returns
   *   The eaten string, or `null` if there was no match.
   */
  public eat(predicate: Predicate): string | null {
    if (this._escaped) { return null }

    if (predicate instanceof RegExp) {
      const match = this.slice(this._pos, -1).match(predicate)
      if (match != null && match.index === 0) {
        this.next(match[0].length)
        return match[0]
      }
    } else if (isArray(predicate)) {
      for (const option of predicate) {
        const slice = this.substr(this._pos, option.length)
        if (slice === option) {
          this.next(option.length)
          return option
        }
      }
    } else if (predicate instanceof Function) {
      const nextChar = this.peek()
      if (nextChar != null && predicate(nextChar)) {
        this.next()
        return this.substr(this._pos - 1, 1)
      }
    } else {
      const slice = this.substr(this._pos, predicate.length)
      if (slice === predicate) {
        this.next(predicate.length)
        return slice
      }
    }

    return null
  }

  /**
   * Eats any whitespace from the current position.
   *
   * @returns
   *   Whether whitespace was found.
   */
  public eatSpace(): boolean {
    return this.eatCodePoints(...CHARS.whitespace)
  }

  /**
   * Eats any of the given characters.
   *
   * @param chars
   *   A string with all the characters to look for.
   * @returns
   *   Whether any of the given characters were found.
   */
  public eatChars(chars: string): boolean {
    const codes: number[] = []
    for (let i = 0; i < chars.length; i++) {
      codes.push(chars.charCodeAt(i))
    }
    return this.eatCodePoints(...codes)
  }

  /**
   * Eats any of the given characters.
   *
   * @param codes
   *   The character codes to look for.
   * @returns
   *   Whether any of the given characters were found.
   */
  public eatCodePoints(...codes: number[]): boolean {
    const prev = this._pos
    while (!this.eos && codes.indexOf(this.codePoints[this._pos]) !== -1) {
      this._pos++
    }
    return this._pos > prev
  }

  /**
   * Skips while the given predicate matches.
   */
  public eatWhile(predicate: Predicate): boolean {
    let found = false
    while (this.eat(predicate)) {
      found = true
    }
    return found
  }

  /**
   * Skips until the predicate matches.
   */
  public eatUntil(predicate: Predicate): boolean {
    let found = this.match(predicate, 0)
    while (!found && !this.eos) {
      this.next()
      found = this.match(predicate, 0)
    }
    return found
  }

  /**
   * Skips until EOL.
   */
  public eatUntilEOL() {
    while (!this.eol && !this.eos) {
      this.next()
    }
  }

  /**
   * Skips until EOS.
   */
  public eatUntilEos() {
    while (!this.eos) {
      this.next()
    }
  }

  //------
  // Assertions

  public expect(predicate: Predicate, name: string = `${predicate}`) {
    const value = this.eat(predicate)
    if (value != null) {
      return value
    } else {
      throw new ParseError(`expected ${name}`, this.pos)
    }
  }

  public expectEOS() {
    if (!this.eos) {
      throw new ParseError('expected <eos>', this.pos)
    }
  }

  //------
  // Save & restore

  private saved: Array<{pos: number, start: number}> = []

  public save() {
    const {pos, start} = this
    this.saved.push({pos, start})
  }

  public restore() {
    const snapshot = this.saved.pop()
    if (snapshot == null) { return }

    this._pos   = snapshot.pos
    this._start = snapshot.start
  }

  public discard() {
    this.saved.pop()
  }

}

export interface StringStreamOptions {
  escapeChar?: string | null
}

export class ParseError extends Error {

  constructor(message: string, public readonly pos: number) {
    super(message)
  }

}