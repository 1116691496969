import Color from 'color'
import I18next from 'i18next'
import { get, isObject, merge } from 'lodash'
import { CustomImage, Translations } from '~/models'
import * as fonts from '../fonts'
import * as layout from '../layout'
import ActionStateLabelBranding from './ActionStateLabelBranding'
import { AppBrandingGuide } from './app'
import ColorGuide from './ColorGuide'
import {
  AccordionBranding,
  ChatBubbleBranding,
  CheckBoxBranding,
  ComponentBranding,
  ComponentBrandingBase,
  SliderBranding,
  TileBranding,
} from './components'
import ChoiceRowBranding from './components/ChoiceRowBranding'
import ProgressBarBranding from './components/ProgressBarBranding'
import { BrandingManifest } from './deserializer'
import FontGuide from './FontGuide'
import { BackgroundSpec, BorderSpec, BrandingGuideDelegate } from './types'
import { WebBrandingGuide } from './web'

export default class BrandingGuide implements BrandingGuideDelegate {

  constructor(configure?: (guide: BrandingGuide) => void) {
    configure?.(this)
  }

  public get rootGuide() {
    return this
  }

  //------
  // Common stuff

  public readonly brandingContainer = true

  public allowDynamicBranding: boolean = true

  public readonly colors = new ColorGuide(this)

  public readonly fonts  = new FontGuide(this, fonts.defaultFamilies)

  public readonly texts: Translations = {}

  public readonly images: Record<string, CustomImage> = {}

  public backgrounds: Record<string, BackgroundSpec> = {
    none: {
      type:  'solid',
      color: 'transparent',
      theme: 'light',
    },
    primary: {
      type:   'gradient',
      colors: [this.colors.semantic.dark.primary, this.colors.semantic.dark.secondary],
      angle:  45,
      theme:  'dark',
    },
    secondary: {
      type:   'gradient',
      colors: [this.colors.semantic.dark.secondary, this.colors.semantic.dark.primary],
      angle:  45,
      theme:  'dark',
    },
    normal: {
      type: 'solid',
      color: 'bg-normal',
      theme: 'default',
    },
    alt: {
      type: 'solid',
      color: 'bg-alt',
      theme: 'default',
    },
    semi: {
      type: 'solid',
      color: 'bg-semi',
      theme: 'default',
    },
    solid: {
      type:  'solid',
      color: 'primary',
      theme: 'dark',
    },
    launch: {
      type:   'gradient',
      colors: [this.colors.semantic.dark.primary, this.colors.semantic.dark.secondary],
      angle:  45,
      theme:  'dark',
    },
    body: {
      type:  'solid',
      color: 'bg-normal',
      theme: 'default',
    },
    'in-app-browser': {
      type:   'solid',
      color:  'primary',
      theme:  'dark',
    },
  }

  public borders: Record<string, BorderSpec> = {
    none:           BorderSpec.none(),
    'light-thin':   BorderSpec.solid(this.colors.fg.light.normal, 1),
    'light-thick':  BorderSpec.solid(this.colors.fg.light.normal, 2),
    'medium-thin':  BorderSpec.solid(this.colors.fg.light.dimmer, 1),
    'medium-thick': BorderSpec.solid(this.colors.fg.light.dimmer, 2),
    'dark-thin':    BorderSpec.solid(this.colors.fg.dark.normal, 1),
    'dark-thick':   BorderSpec.solid(this.colors.fg.dark.normal, 2),
  }

  //------
  // Texts & images

  public image(key: string, variant: string | null = null) {
    if (variant != null) {
      return this.images[`${key}:${variant}`] ?? this.images[key] ?? null
    } else {
      return this.images[key] ?? null
    }
  }

  public setImage(key: string, variant: string | null, image: CustomImage) {
    if (variant != null) {
      this.images[`${key}:${variant}`] = image
    } else {
      this.images[key] = image
    }
  }

  public text(key: string, language: string = I18next.language) {
    return this.texts[language]?.[key] ?? this.texts.en?.[key] ?? null
  }

  public setText(key: string, language: string, text: string) {
    this.texts[language] ??= {}
    this.texts[language][key] = text
  }

  //------
  // Common components

  public avatar = new ComponentBranding(this, null, {
    background:  'secondary',
    border:      'light-thick',
    shape:       'oval',
    depth:       0,
    previewSize: {width: 48, height: 48},
  })

  public badge = new ComponentBranding(this, null, {
    shape:       'oval',
    background:  'secondary',
    border:      'light-thick',
    depth:       1,
    previewSize: {width: 24, height: 24},
  })

  public callToActionButton = new ComponentBranding(this, null, {
    background:  'primary',
    border:      'none',
    shape:       {rounded: layout.radius.s},
    depth:       2,
    previewSize: {width: 120, height: 40},
  })

  public actionStateLabel = new ActionStateLabelBranding(this, null)

  public notice = new ComponentBranding(this, null, {
    shape:      {rounded: layout.radius.m},
    background: 'secondary',
    border:     'none',
    depth:      0,
  })

  public tile = new TileBranding(this, null, {
    background:     'alt',
    border:         'none',
    shape:          {rounded: layout.radius.m},
    depth:          1,
    infoBackground: 'primary',
  })

  public slider = new SliderBranding(this, null)
  public progressBar = new ProgressBarBranding(this, null)

  public radioButton = new CheckBoxBranding(this, null, {
    shape:       'oval',
    background:  'alt',
    border:      'medium-thin',
    depth:       0,
    previewSize: {width: 24, height: 24},
  })

  public checkBox    = new CheckBoxBranding(this, null, {
    shape:       {rounded: 4},
    background:  'alt',
    border:      'medium-thin',
    depth:       0,
    previewSize: {width: 24, height: 24},
  })

  public choiceRow = new ChoiceRowBranding(this, null)

  public chat = {

    bubble: new ChatBubbleBranding(this, null),

    noticeBubble: new ComponentBranding(this, this.notice, {
      background: 'normal',
      depth:      0.5,
    }),

  }

  public feed = {

    post: new ComponentBranding(this, null, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.l},
      depth:      6,
    }),

    comment: new ComponentBranding(this, null, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.s},
      depth:      0,
    }),

  }

  public feedback = {
    slider: new SliderBranding(this, this.slider),

    button: new ComponentBranding(this, null, {
      background:  'alt',
      border:      'none',
      shape:       {rounded: layout.radius.s},
      depth:       2,
      previewSize: {width: 120, height: 40},
    }),

    choiceRow: new ChoiceRowBranding(this, this.choiceRow, {
      background:  'alt',
      border:      'none',
      shape:       'oval',
      depth:       0,
      previewSize: {width: 160, height: 32},
    }),

    choiceRowRadioButton: (() => {
      const branding = new CheckBoxBranding(this, this.radioButton, this.radioButton.defaults)
      branding.variant('checked', styles => {
        styles.background = 'alt'
      })
      return branding
    })(),
  }

  public menu = {
    tile: new TileBranding(this, this.tile),
  }

  public accordion = new AccordionBranding(this, null)

  public findComponentPath(branding: ComponentBrandingBase<any>) {
    const todo: Array<[any, string[]]> = [[this, []]]
    while (todo.length > 0) {
      const [current, parts] = todo.pop()!
      if (current === branding) {
        return parts.join('.')
      }

      for (const [key, child] of Object.entries(current)) {
        if (key === 'parent' || key === 'guide' || key === 'defaults' || key === 'rootGuide') { continue }
        if (!isObject(child)) { continue }
        todo.push([child, [...parts, key]])
      }
    }

    return null
  }

  //------
  // Web & app guide (must be placed last)

  public app = new AppBrandingGuide(this)
  public web = new WebBrandingGuide(this)

  public static deserialize(manifest: BrandingManifest | null): BrandingGuide {
    return new BrandingGuide(guide => {
      if (manifest != null) {
        guide.deserializeFrom(manifest)
      }
    })
  }

  public deserializeFrom(manifest: BrandingManifest) {

    //------
    // Colors

    for (const [name, hex] of Object.entries(manifest.colors)) {
      if (/^(bg|fg|border)-(light|dark)-(.+)$/.test(name)) {
        Object.assign((this.colors as any)[RegExp.$1][RegExp.$2], {[RegExp.$3]: new Color(hex)})
      } else if (name in this.colors.semantic.dark) {
        Object.assign(this.colors.semantic.dark, {[name]: new Color(hex)})
        Object.assign(this.colors.semantic.light, {[name]: new Color(hex)})
      } else {
        Object.assign(this.colors.named, {[name]: new Color(hex)})
      }
    }

    //------
    // Texts & images

    merge(this.images, manifest.images)
    merge(this.texts, manifest.texts)

    //------
    // Backgrounds & borders

    for (const [name, background] of Object.entries(manifest.backgrounds)) {
      this.backgrounds[name] = background
    }

    for (const [name, border] of Object.entries(manifest.borders)) {
      this.borders[name] = border
    }

    //-------
    // Components

    for (const [name, componentManifest] of Object.entries(manifest.components)) {
      const [base, variant] = name.split(':', 2)

      const component = get(this, base)
      if (component instanceof ComponentBrandingBase) {
        component.deserializeFrom(componentManifest, variant)
      } else {
        console.warn(`No component branding "${base}" defined`)
      }
    }

  }

}