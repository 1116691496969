import React from 'react'
import { memo } from '~/ui/component'
import { Panel, panelBorderRadius, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ListItem, { Props as ListItemProps } from './ListItem'

export interface Props extends ListItemProps {
  href?:  string
  onTap?: () => any
}

const ListBar = memo('ListBar', (props: Props) => {

  const {
    href,
    onTap,
    selected,
    classNames,
    contentClassNames,
    ...rest
  } = props

  const $ = useStyles()

  return (
    <VBox classNames={[$.resourceBar, {selected}, classNames]}>
      <Panel contentClassNames={[$.content, contentClassNames]} href={href} onTap={onTap} justify='middle'>
        <ListItem {...rest}/>
      </Panel>
    </VBox>
  )

})

export default ListBar

export const height = layout.barHeight.l

const useStyles = createUseStyles(theme => ({
  resourceBar: {
    borderRadius: panelBorderRadius(theme),

    '&.selected': {
      boxShadow: shadows.selected(theme),
    },
  },

  content: {
    minHeight: height,
  },
}))