import { action, when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrCourseItem } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrCourseItemDocument from './BeWizrCourseItemDocument'

export default class BeWizrCourseItemsEndpoint extends Endpoint<BeWizrCourseItemDocument> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  @action
  public async performFetch(params: SearchParams, options: CollectionFetchOptions) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('course_search/', {
      params: {
        q: params.query,
      },
    })
    if (response.status !== 200) { return null }

    const items  = response.data
    const total  = items.length
    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    if (offset > 0) {
      items.splice(0, offset)
    }
    if (items.length > limit) {
      items.splice(limit)
    }
    const nextOffset = offset + limit > total ? null : offset + limit

    const data = items.map((raw: any) => (
      BeWizrCourseItem.deserialize({
        ...raw,
        id: raw.courseitem_id,
      })
    ))

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}