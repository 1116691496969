import React from 'react'
import { pick } from 'lodash'
import { LayoutElement, PopupCrossAlignment, PopupSide } from './types'

export default class PopupLayout {

  constructor(
    private readonly preferredSide:   PopupSide,
    private readonly align:           'vertical' | 'horizontal',
    private readonly crossAlign:      PopupCrossAlignment,
    private readonly matchTargetSize: boolean,
    private readonly gap:             number,
    private readonly screenPadding:   number,
    private readonly targetRef:       React.RefObject<LayoutElement>,
    private readonly containerRef:    React.RefObject<HTMLDivElement>,
    private readonly bodyRef:         React.RefObject<HTMLDivElement>,
    private readonly getClientRect:   (() => LayoutRect | null) | null,
    private readonly setHasLayout:    (hasLayout: boolean) => void,
    private readonly setSide:         (side: PopupSide) => void,
  ) {}

  private get target()    { return this.targetRef.current }
  private get container() { return this.containerRef.current }
  private get body()      { return this.bodyRef.current }

  private prevBodyRect:   LayoutRect | null = null
  private prevTargetRect: LayoutRect | null = null

  private bodyRect:   LayoutRect | null = null
  private targetRect: LayoutRect | null = null

  //------
  // Interface

  /**
   * (Re)positions the popup if this is needed.
   */
  public positionIfNeeded() {
    this.measure()
    if (!this.needsPosition()) { return }

    this.prevBodyRect   = this.bodyRect
    this.prevTargetRect = this.targetRect

    this.position()
    this.setHasLayout(true)
  }


  //------
  // Measuring

  /**
   * Measures the current target and body rectangles.
   */
  public measure() {
    const {target, container, body} = this
    if (container == null || body == null) { return }

    // Get the target rectangle.
    this.targetRect = target?.getBoundingClientRect() ?? null

    // Temporarily reset explicit size styles.
    const savedContainer = this.resetStyles(container)

    this.bodyRect = body.getBoundingClientRect()

    // Restore styles.
    Object.assign(container.style, savedContainer)
  }

  /**
   * Determines whether the popup needs repositioning.
   */
  public needsPosition() {
    const {
      bodyRect,
      targetRect,
      prevBodyRect,
      prevTargetRect,
    } = this

    if (bodyRect == null) { return false }
    if (prevBodyRect == null || prevTargetRect == null) { return true }

    if (!rectEquals(prevBodyRect, bodyRect)) { return true }
    if (targetRect != null && !rectEquals(prevTargetRect, targetRect)) { return true }

    return false
  }

  //------
  // Positioning

  /**
   * Correctly positions the popup.
   */
  public async position() {
    this.positionAlongAxis()
    this.alignAlongCrossAxis()
  }

  /**
   * Positions the popup along its main axis.
   */
  private positionAlongAxis() {
    const preferredSide = this.preferredSide ?? 'far'
    const oppositeSide  = preferredSide === 'near' ? 'far' : 'near'

    // Try to position the popup on the preferred side.
    const preferredSideSize = this.tryPositionAlongAxis(preferredSide)
    if (preferredSideSize === true) { return }

    // If this doesn't fit, position the popup on the opposite side.
    const oppositeSideSize = this.tryPositionAlongAxis(oppositeSide)
    if (oppositeSideSize === true) { return }

    if (preferredSideSize === false || oppositeSideSize === false) {
      return
    }

    // If it still doesn't fit, force the popup on the side with the most room.
    const side = preferredSideSize > oppositeSideSize
      ? preferredSide
      : oppositeSide

    this.tryPositionAlongAxis(side, true)
  }

  private tryPositionAlongAxis(side: PopupSide, force: boolean = false): number | boolean {
    const {target, container, body} = this
    if (container == null || body == null) { return false }

    const {gap = defaultGap, screenPadding = defaultScreenPadding, align} = this
    const clientSize = align === 'horizontal'
      ? document.documentElement.clientWidth
      : document.documentElement.clientHeight

    const targetPositions = this.getTargetPositions()

    let size = this.getSize(body, false)
    let position: number
    if (side === 'far') {
      position = targetPositions[1] + this.getSize(target, false) + gap

      const availableSize = clientSize - screenPadding - position
      if (!force && position + size > clientSize - screenPadding) {
        return availableSize
      }

      size = availableSize
    } else {
      position = screenPadding

      const availableSize = targetPositions[0] - gap - position
      if (!force && targetPositions[0] - size - gap < screenPadding) {
        return availableSize
      }

      size = availableSize
    }

    this.setPosition(container, position)
    this.setSize(container, size)
    this.setSide(side)
    return true
  }

  /**
   * Aligns the popup along its cross-axis.
   */
  private alignAlongCrossAxis() {
    const {target, container, body} = this
    if (container == null || body == null) { return }

    const {matchTargetSize} = this

    const {screenPadding = defaultScreenPadding} = this
    const clientSize = this.align === 'horizontal'
      ? document.documentElement.clientHeight
      : document.documentElement.clientWidth

    const size = matchTargetSize
      ? Math.max(this.getSize(target, true), this.getSize(body, true))
      : this.getSize(body, true)

    // Use the cross align to position the container.
    let positionStart = this.getTargetPositions(true)[0] + this.getCrossAlign() * (this.getSize(target, true) - size)
    let positionEnd   = this.getTargetPositions(true)[1] + this.getCrossAlign() * (this.getSize(target, true) - size) + size

    // Make sure it fits.
    if (positionEnd > clientSize - screenPadding) {
      const shift = positionEnd - (clientSize - screenPadding)
      positionStart -= shift
      positionEnd   -= shift
    }

    if (positionStart < screenPadding) {
      const shift = screenPadding - positionStart
      positionStart += shift
      positionEnd = Math.min(positionEnd + shift, clientSize - screenPadding)
    }

    this.setPosition(container, positionStart, true)
    this.setSize(container, positionEnd - positionStart, true)
  }

  //------
  // Position helpers

  private getTargetPositions(cross: boolean = false): [number, number] {
    const rect = this.getClientRect?.()
    if (rect != null) {
      const positionProp = (this.align === 'horizontal') === !cross ? 'left' : 'top'
      const sizeProp     = (this.align === 'horizontal') === !cross ? 'width' : 'height'
      return [rect[positionProp], rect[positionProp] + rect[sizeProp]]
    } else if (this.target != null) {
      const position = this.getPosition(this.target, cross)
      return [position, position]
    } else {
      return [0, 0]
    }
  }

  private getPosition(element: LayoutElement, cross: boolean) {
    if (element === this.target) {
      const prop = (this.align === 'horizontal') === !cross ? 'left' : 'top'
      return this.targetRect?.[prop] ?? 0
    } else if (element === this.container) {
      const prop = (this.align === 'horizontal') === !cross ? 'offsetLeft' : 'offsetTop'
      return element[prop]
    } else {
      const prop = (this.align === 'horizontal') === !cross ? 'left' : 'top'
      return element.getBoundingClientRect()[prop]
    }
  }

  private getSize(element: HTMLElement | SVGElement | null, cross: boolean) {
    const rectProp = (this.align === 'horizontal') === !cross ? 'width' : 'height'

    if (element === this.target) {
      return this.targetRect?.[rectProp] ?? 0
    } else if (element === this.body) {
      return this.bodyRect?.[rectProp] ?? 0
    } else if (element instanceof HTMLElement) {
      const prop = (this.align === 'horizontal') === !cross ? 'offsetWidth' : 'offsetHeight'
      return element[prop]
    } else {
      const prop = (this.align === 'horizontal') === !cross ? 'clientWidth' : 'clientHeight'
      return element?.[prop] ?? 0
    }
  }

  private setPosition(element: HTMLElement | SVGElement, position: number | string, cross: boolean = false) {
    const prop = (this.align === 'horizontal') === !cross ? 'left' : 'top'
    const value = typeof position === 'number' ? `${Math.floor(position)}px` : position
    if (element.style[prop] === value) { return }
    element.style[prop] = value
  }

  private setSize(element: HTMLElement | SVGElement, size: number | string, cross: boolean = false) {
    const prop = (this.align === 'horizontal') === !cross ? 'width' : 'height'
    const value = typeof size === 'number' ? `${Math.ceil(size)}px` : size
    if (element.style[prop] === value) { return }
    element.style[prop] = value
  }

  private resetStyles(element: HTMLElement | SVGElement) {
    const saved = pick(element.style, 'visibility', 'width', 'height', 'transition')

    Object.assign(element.style, {
      visibility: 'hidden',
      width:      'auto',
      height:     'auto',
      transition: '',
    })

    return saved
  }

  //------
  // Derived properties

  private getCrossAlign(): number {
    const {crossAlign = 'center'} = this

    switch (crossAlign) {
    case 'near':   return 0
    case 'center': return 0.5
    case 'far':    return 1
    default:       return crossAlign
    }
  }


}

export const defaultScreenPadding = 24
export const defaultGap = 8

function rectEquals(left: LayoutRect, right: LayoutRect) {
  if (left.top !== right.top) { return false }
  if (left.left !== right.left) { return false }
  if (left.width !== right.width) { return false }
  if (left.height !== right.height) { return false }

  return true
}