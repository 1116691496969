import { DateTime } from 'luxon'
import { action, makeObservable, observable } from 'mobx'
import { GuestWindowController } from 'window-channel'
import config from '~/config'
import { authenticationStore } from '~/stores'
import { register } from './support'

const search     = new URLSearchParams(CLIENT ? document.location.search : undefined)
const PREVIEWING = CLIENT && search.has('preview')
const BRANDING   = CLIENT && search.get('preview') === 'branding'

export class PreviewStore {

  constructor() {
    makeObservable(this)
  }

  public init() {
    if (!PREVIEWING) { return }

    this.controller = new GuestWindowController(window.parent ?? window.opener, config.livePreview.namespace, {
      hostOrigin: config.livePreview.hostOrigin,
    })
    this.bind()
  }

  public deinit() {
    if (!PREVIEWING) { return }
    this.unbind()
  }

  public static readonly previewing = PREVIEWING
  public static readonly branding = BRANDING

  private controller!: GuestWindowController<LivePreviewEvents>

  //------
  // Session

  @observable
  public currentTime: DateTime = DateTime.local()

  @observable
  public previewToken: string | null = null

  //------
  // Binding

  private bind() {
    this.controller.addListener('login', this.handleLogin)
    this.controller.addListener('logout', this.handleLogout)
    this.controller.addListener('time', this.handleTime)
  }

  private unbind() {
    this.controller.dispose()
  }

  //------
  // Events

  private handleLogin = action((token: string) => {
    authenticationStore.authenticateWithToken(token)
  })

  private handleLogout = action(() => {
    authenticationStore.logOut()
  })

  private handleTime = action((timestamp: number) => {
    this.currentTime = DateTime.fromMillis(timestamp)
  })

  //------
  // Branding

  public selectForBranding(path: string, variant: string | null) {
    this.controller.sendToHost('branding', {path, variant})
  }

}

export type LivePreviewEvents = {
  login:    string
  logout:   never
  time:     number
  branding: {path: string, variant: string | null}
}

const previewStore = register(new PreviewStore())
export default previewStore