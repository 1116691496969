import React from 'react'
import YouTube, { YouTubeProps } from 'react-youtube'
import cn from 'classnames'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends YouTubeProps {
  classNames?: React.ClassNamesProp
}

const YouTubePlayer = memo('YouTubePlayer', (props: Props) => {

  const {
    classNames,
    ...rest
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.YouTubePlayer, classNames]}>
        <YouTube
          {...rest}
          className={cn($.youtube, rest.className)}
        />
      </VBox>
    )
  }

  return render()

})

export default YouTubePlayer

const useStyles = createUseStyles({
  YouTubePlayer: {
    position: 'relative',
    paddingTop: 9/16 * 100 + '%',
  },

  youtube: {
    ...layout.overlay,

    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  },
})