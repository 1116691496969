import React from 'react'
import { ElementConnect } from 'react-dnd'
import { isFunction } from 'lodash'
import { SortableItem } from './data'
import { getItemHeight } from './measurements'
import useSortableDrop from './useSortableDrop'

export interface Props<PL= {}> {
  /** An ID identifying this sortable list. */
  listID: symbol

  /** The items to accept. */
  accept?: string[]

  /**
   * Called when an item is hovered over the list.
   */
  onSortHover?: (item: SortableItem<any, PL>, index: number) => void

  /**
   * Called when an item is dropped.
   */
  onSortDrop?: (item: SortableItem<any, PL>, index: number) => any | Promise<any>

  onSortEnter?: (item: SortableItem<any, PL>) => void
  onSortLeave?: (item: SortableItem<any, PL>) => void

  /**
   * There's a bug in Chromium / WebKit which causes a `dragend` event to be fired immediately if the DOM
   * is changed during a dragstart event. As the monitor collect function is called during this event,
   * and we set the drag item, this may cause a DOM change in this event. Therefore, use a delay in setting
   * the item. What a horrible mess.
   *
   * See: https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
   */
  preventDragendBug?: boolean

  /**
   * The children, or a function that returns the children.
   */
  children?: React.ReactNode | ((props: ChildProps<PL>) => React.ReactNode)
}

export interface ChildProps<PL extends any> {

  /**
   * The currently dragged item. This value is `null` if nothing is hovered over the list.
   */
  item: SortableItem<any, PL> | null

  /**
   * Whether an item is currently dragged over the container.
   */
  isOver: boolean

  /**
   * When hovering over the list, the current drop index. This value is `null` if nothing is hovered over the list.
   */
  hoverIndex: number | null

  /**
   * Assign this as a ref to an optional placeholder element.
   */
  connectPlaceholder: ElementConnect

  /**
   * The height of the dragged item. Can be used to size the placeholder.
   */
  itemHeight: number

}

export type SortableContainerComponent<P = {}> = React.ComponentType<P & {
  ref:       SortableRef<any>
  children?: React.ReactNode
}> | string

export type SortableRef<E extends HTMLElement> = React.Ref<E>

export default function createSortableContainer<PL>(componentOrTag: string, accept?: string | string[]): React.ComponentType<React.HTMLAttributes<HTMLElement> & Props<PL>>
export default function createSortableContainer<P, PL>(componentOrTag: SortableContainerComponent<P>, accept?: string | string[]): React.ComponentType<P & Props<PL>>
export default function createSortableContainer(componentOrTag: SortableContainerComponent, accept: string | string[] = []) {
  return (props: React.HTMLAttributes<any> & Props<any>) => {
    const ref = React.useRef<HTMLElement | null>(null)

    const {listID, accept: props_accept, onSortHover, onSortDrop, onSortEnter, onSortLeave, children, preventDragendBug, ...rest} = props
    const [hoverIndex, setHoverIndex] = React.useState<number | null>(null)
    const [delayedItem, setDelayedItem] = React.useState<SortableItem | null>(null)

    const [isOver, setIsOver] = React.useState<boolean>(false)
    const [item, setItem]     = React.useState<SortableItem | null>(null)

    const accept = React.useCallback((item: SortableItem) => {
      if (props_accept != null) {
        return isFunction(props_accept) ? props_accept(item) : props_accept.includes(item.type)
      } else {
        return item.sourceList === listID
      }
    }, [listID, props_accept])

    const [connectDropTarget, connectList, connectPlaceholder] = useSortableDrop({
      id:     listID,
      accept: accept,

      start: item => {
        setItem(item)
      },
      end: () => {
        setItem(null)
        setHoverIndex(null)
      },

      enter: item => {
        setIsOver(true)
        onSortEnter?.(item)
      },
      leave: item => {
        setIsOver(false)
        onSortLeave?.(item)
      },

      hover: (item, index) => {
        if (index === hoverIndex) { return }
        setHoverIndex(index)
        onSortHover?.(item, index)
      },

      drop: (item, index) => {
        return onSortDrop?.(item, index)
      },
    })

    React.useLayoutEffect(() => {
      setTimeout(() => {
        setDelayedItem(item)
      }, 0)
    }, [item])

    React.useLayoutEffect(() => {
      if (!isOver) {
        setHoverIndex(null)
      }
    }, [isOver])

    const connect = (element: HTMLElement | null) => {
      connectDropTarget(element)
      connectList(element)
      ref.current = element
    }

    const itemToUse = preventDragendBug ? delayedItem : item

    return React.createElement(componentOrTag, {ref: connect, ...rest}, isFunction(children) ? children({
      item:       itemToUse,
      itemHeight: itemToUse == null ? 0 : getItemHeight(itemToUse),

      hoverIndex,
      isOver,
      connectPlaceholder,
    }) : children)
  }

}