import { isFunction } from 'lodash'

export interface PersistedStore<S> {
  persistenceKey: string
  rehydrate(state: S): void | Promise<void>
  persistedState: S
}

export function supportsPersistence(store: any): store is PersistedStore<any> {
  if (store == null) { return false }
  return isFunction(store.rehydrate) && typeof store.persistenceKey === 'string'
}