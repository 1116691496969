import { Document } from 'mobx-document'
import { BeWizrLibraryItem } from '~/models'

export default class BeWizrLibraryItemDocument extends Document<BeWizrLibraryItem> {

  protected async performFetch() {
    // Not supported
    return null
  }

}