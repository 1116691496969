import { Model } from './Model'

export class Competition extends Model {

  type!:  'individual' | 'team'
  name!:  string

}

export interface Ranking {
  rank:       number | null
  player:     CompetitionPlayer
  score:      Score
  normalized: Score | null
  isMe?:      boolean
}

export interface Score {
  points:    number
  formatted: string
}

export interface CompetitionPlayer {
  id:        string
  photoURL:  string | null
  name:      string
  firstName: string
}
