import { AxiosInstance } from 'axios'
import { createAxios } from '~/axios'
import config from '~/config'

let __bewizrAPI: AxiosInstance | null = null

export function bewizrAPI() {
  return __bewizrAPI
}

export function initBeWizrAPI(authToken: string) {
  __bewizrAPI = createAxios({
    baseURL:   `https://${config.environment === 'live' ? 'be' : 'acc-be'}.wizr.eu/frontend/api/`,
    authToken: authToken,
  })
}