import Color from 'color'
import { BrandingGuide } from '~/ui/styling'

export default function applyBewizrBranding(guide: BrandingGuide) {
  guide.colors.named.red = new Color('#CB1517')
  guide.colors.named.orange = new Color('#FF6600')

  const lightOrange = new Color('#FFFCFA')

  guide.colors.semantic.dark.primary = guide.colors.named.red
  guide.colors.semantic.dark.secondary = guide.colors.named.orange

  guide.colors.semantic.light.primary = guide.colors.named.red
  guide.colors.semantic.light.secondary = guide.colors.named.orange

  guide.colors.bg.light.normal = lightOrange
  guide.colors.bg.light.semi = lightOrange.mix(guide.colors.named.white, 0.5)
  guide.colors.bg.light.header1 = lightOrange
  guide.colors.bg.light.header2 = lightOrange

  Object.assign(guide.backgrounds, {
    primary: {
      type:   'gradient',
      colors: [guide.colors.semantic.dark.primary, guide.colors.semantic.dark.secondary],
      angle:  45,
      theme:  'dark',
    },
    secondary: {
      type:   'gradient',
      colors: [guide.colors.semantic.dark.secondary, guide.colors.semantic.dark.primary],
      angle:  45,
      theme:  'dark',
    },
    normal: {
      type: 'solid',
      color: 'bg-normal',
      theme: 'default',
    },
    alt: {
      type: 'solid',
      color: 'bg-alt',
      theme: 'default',
    },
    semi: {
      type: 'solid',
      color: 'bg-semi',
      theme: 'default',
    },
    solid: {
      type:  'solid',
      color: 'primary',
      theme: 'dark',
    },
    launch: {
      type:   'gradient',
      colors: [guide.colors.semantic.dark.primary, guide.colors.semantic.dark.secondary],
      angle:  45,
      theme:  'dark',
    },
    body: {
      type:  'solid',
      color: 'bg-normal',
      theme: 'default',
    },
    'in-app-browser': {
      type:   'solid',
      color:  'primary',
      theme:  'dark',
    },
  })

  guide.setText('title', 'nl', "LXP")
  guide.setText('title', 'en', "LXP")
}