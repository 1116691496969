import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import renderDynamicFieldAllowance from './allowances/index'
import { DynamicField, DynamicFormSchema } from './types'

export interface Props {
  schema: DynamicFormSchema
}

const DynamicFieldset = memo('DynamicFieldset', (props: Props) => {

  const {schema} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {schema.map(renderField)}
      </VBox>
    )
  }

  function renderField(field: DynamicField) {
    return (
      <FormField
        key={field.name}
        name={field.name}
        caption={field.caption}
        required={field.required}
        children={bind => renderDynamicFieldAllowance(field, bind)}
      />
    )
  }

  return render()

})

export default DynamicFieldset