import React from 'react'
import { useTimer } from 'react-timer'
import Color from 'color'
import { arrayEquals } from 'ytil'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenu, PopupMenuItem, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { FormError } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import {
  createUseStyles,
  fonts,
  layout,
  presets,
  shadows,
  ThemeProvider,
  useStyling,
} from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { Props as TextFieldProps } from './TextField'

export interface Props<T> {
  value:     T
  onChange?: (value: T) => any

  choices:      Choice<T>[]
  placeholder?: string | null
  enabled?:     boolean
  readOnly?:    boolean

  invalid?: boolean
  errors?:  FormError[]

  small?:            boolean
  tiny?:             boolean
  inputStyle?:       TextFieldProps['inputStyle']
  classNames?:       React.ClassNamesProp
  selectAttributes?: React.HTMLAttributes<HTMLSelectElement>
}

export type Choice<T = any> = LeafChoice<T> | GroupChoice<T> | CustomChoice<T> | SectionChoice

interface ChoiceCommon {
  key?:       string | number
  icon?:      SVGName
  color?:     Color
  dim?:       boolean
  enabled?:   boolean
  accessory?: React.ReactNode
}

export interface LeafChoice<T = any> extends ChoiceCommon {
  value:         T
  caption:       string
  valueCaption?: string
}

export interface CustomChoice<T = any> extends ChoiceCommon {
  value:  T
  render: (list: boolean) => React.ReactNode
}

export interface GroupChoice<T = any> extends ChoiceCommon {
  caption:  string
  children: Choice<T>[]
}

export interface SectionChoice {
  section: string
}

const _SelectField = <T extends any>(props: Props<T>) => {

  const {
    value,
    onChange,
    choices,
    invalid,
    enabled = true,
    readOnly,
    small,
    tiny,
    placeholder,
  } = props

  const [isOpen, open, close] = useBoolean()

  const {colors} = useStyling()

  //------
  // Data

  const indexPathForValue = React.useCallback((value: T) => {
    const findIn = (choices: Choice<T>[], path: number[]): number[] => {
      for (const [index, choice] of choices.entries()) {
        if (isGroupChoice(choice)) {
          const itemPath  = [...path, index]
          const childPath = findIn(choice.children, itemPath)
          if (childPath.length > 0) { return childPath }
        } else if (!isSectionChoice(choice) && choice.value === value) {
          return [...path, index]
        }
      }

      return []
    }
    return findIn(choices, [])
  }, [choices])

  const choiceAtIndexPath = React.useCallback((indexPath: number[]) => {
    const path = [...indexPath]
    let current: Choice<T>[] = choices

    while (path.length > 0) {
      const index  = path.shift()!
      const choice = current[index] ?? null
      if (choice != null && isGroupChoice(choice)) {
        current = choice.children
      } else if (choice == null || isLeafChoice(choice) || isCustomChoice(choice)) {
        return choice
      }
    }

    return null
  }, [choices])

  const [highlightedIndexPath, setHighlightedIndexPath] = React.useState<number[]>(
    indexPathForValue(value),
  )

  const resetHighlightedIndexPath = React.useCallback(() => {
    setHighlightedIndexPath([])
  }, [setHighlightedIndexPath])

  const indexPath = React.useMemo(
    () => indexPathForValue(value),
    [indexPathForValue, value],
  )

  const selectedChoice = React.useMemo(
    () => choiceAtIndexPath(indexPath),
    [choiceAtIndexPath, indexPath],
  )

  const highlightedChoice = React.useMemo(
    () => choiceAtIndexPath(highlightedIndexPath),
    [choiceAtIndexPath, highlightedIndexPath],
  )

  const selectedCaption = React.useMemo(() => {
    if (selectedChoice != null && isCustomChoice(selectedChoice)) {
      return selectedChoice.render(false)
    } else {
      return selectedChoice?.valueCaption ?? selectedChoice?.caption ?? null
    }
  }, [selectedChoice])

  const empty = selectedChoice == null

  const menuItems = React.useMemo(() => {
    const choiceToMenuItem = (choice: Choice<T>, path: number[]): PopupMenuItem => {
      const selected        = arrayEquals(path, indexPath)
      const highlighted     = arrayEquals(path, highlightedIndexPath)
      const backgroundColor = highlighted ? colors.semantic.primary : undefined

      if (isLeafChoice(choice)) {
        return {
          ...choice,
          backgroundColor,
          checked: selected,
        }
      } else if (isGroupChoice(choice)) {
        return {
          ...choice,
          backgroundColor,
          children: choice.children.map((it, idx) => choiceToMenuItem(it, [...path, idx])),
          checked: selected,
        }
      } else if (isCustomChoice(choice)) {
        return {
          ...choice,
          backgroundColor,
          render: () => choice.render(true),
        }
      } else {
        return {
          ...choice,
        }
      }
    }

    return choices.map((it, idx) => choiceToMenuItem(it, [idx]))
  }, [choices, colors.semantic.primary, highlightedIndexPath, indexPath])

  //------
  // Callbacks

  const select = React.useCallback((value: T) => {
    onChange?.(value)
  }, [onChange])

  //------
  // Keyboard

  const previousIndexPath = React.useCallback((indexPath: number[]) => {
    const head = [...indexPath]
    const tail = head.pop()
    if (tail != null) {
      return [...head, Math.max(0, tail - 1)]
    } else if (head.length === 0) {
      return [choices.length - 1]
    } else {
      const choice = choiceAtIndexPath(head)
      if (choice == null || !isGroupChoice(choice)) { return head }
      return [...head, choice.children.length - 1]
    }
  }, [choiceAtIndexPath, choices.length])

  const nextIndexPath = React.useCallback((indexPath: number[]) => {
    const head = [...indexPath]
    const tail = head.pop()
    if (tail == null) { return [0] }

    if (head.length === 0) {
      return [Math.min(choices.length - 1, tail + 1)]
    } else {
      const choice = choiceAtIndexPath(head)
      if (choice == null || !isGroupChoice(choice)) { return head }

      return [...head, Math.min(choice.children.length - 1, tail + 1)]
    }
  }, [choiceAtIndexPath, choices.length])

  const selectPrevious = React.useCallback(() => {
    const ip = previousIndexPath(highlightedIndexPath)
    setHighlightedIndexPath(ip)
  }, [highlightedIndexPath, previousIndexPath])

  const selectNext = React.useCallback(() => {
    const ip = nextIndexPath(highlightedIndexPath)
    setHighlightedIndexPath(ip)
  }, [highlightedIndexPath, nextIndexPath])

  const selectOut = React.useCallback(() => {
    if (indexPath.length <= 1) { return }
    const ip = indexPath.slice(0, -1)
    setHighlightedIndexPath(ip)
  }, [indexPath])

  const selectIn = React.useCallback(() => {
    const choice = choiceAtIndexPath(indexPath)
    if (choice == null || !isGroupChoice(choice)) { return }
    if (choice.children.length === 0) { return }
    setHighlightedIndexPath([...indexPath, 0])
  }, [choiceAtIndexPath, indexPath])

  const onKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      open()
      selectPrevious()
      event.preventDefault()
    } else if (event.key === 'ArrowDown') {
      open()
      selectNext()
      event.preventDefault()
    } else if (event.key === 'ArrowRight') {
      selectIn()
    } else if (event.key === 'ArrowLeft') {
      selectOut()
    } else if (event.key === 'Enter') {
      if (!isOpen) {
        open()
      } else {
        if (highlightedChoice != null) {
          select(highlightedChoice.value)
        }
        close()
      }
      event.preventDefault()
    }
  }, [close, highlightedChoice, isOpen, open, select, selectIn, selectNext, selectOut, selectPrevious])

  const blurTimer = useTimer()
  const preventBlurRef = React.useRef<boolean>(false)

  const onMouseDown = React.useCallback((event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.currentTarget.focus()
    open()
  }, [open])

  const onBlur = React.useCallback(() => {
    if (preventBlurRef.current) { return }
    close()
  }, [close])

  const preventBlur = React.useCallback(() => {
    preventBlurRef.current = true
    blurTimer.debounce(() => {
      preventBlurRef.current = false
    }, 0)
  }, [blurTimer])

  const handlers = React.useMemo(() => (enabled ? {
    onKeyDown,
    onMouseDown,
    onBlur,
  } : {}), [enabled, onBlur, onKeyDown, onMouseDown])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <PopupMenu
        matchTargetSize={true}
        children={renderContent}
        open={isOpen}
        requestClose={close}
        items={menuItems}
        onValueSelect={select}
        onMouseDown={preventBlur}
        onDidClose={resetHighlightedIndexPath}
      />
    )
  }

  function renderContent() {
    const classNames = [
      $.SelectField,
      props.inputStyle,
      {invalid, empty, small, tiny, disabled: !enabled, readOnly},
      props.classNames,
    ]
    return (
      <ThemeProvider light>
        <Tappable onTap={open} classNames={classNames} role='listbox' enabled={enabled && !readOnly} {...handlers}>
          <HBox flex gap={layout.padding.inline.m}>
            {renderValue()}
            <SVG name='chevron-down' size={tiny ? layout.icon.xs : layout.icon.s} dim/>
          </HBox>
        </Tappable>
      </ThemeProvider>
    )
  }

  function renderValue() {
    if (empty && placeholder != null) {
      return (
        <Label flex classNames={$.label} dimmer>
          {placeholder}
        </Label>
      )
    } else if (isReactText(selectedCaption)) {
      return (
        <Label flex classNames={$.label} dim={selectedChoice?.dim} color={selectedChoice?.color} dimmer={empty}>
          {selectedCaption}
        </Label>
      )
    } else {
      return (
        <VBox flex>
          {selectedCaption}
        </VBox>
      )
    }
  }

  return render()

}

export function isLeafChoice<T>(choice: Choice<T>): choice is LeafChoice<T> {
  return 'value' in choice && !('render' in choice)
}

export function isCustomChoice<T>(choice: Choice<T>): choice is CustomChoice<T> {
  return 'render' in choice
}

export function isGroupChoice<T>(choice: Choice<T>): choice is GroupChoice<T> {
  return 'children' in choice
}

export function isSectionChoice(choice: Choice<any>): choice is SectionChoice {
  return 'section' in choice
}

const SelectField = memo('SelectField', _SelectField) as typeof _SelectField
export default SelectField

const useStyles = createUseStyles(theme => ({
  SelectField: {
    ...presets.field(theme),
    minHeight: 0,

    height: presets.fieldHeight.normal,
    '$selectField.small &': {
      height: presets.fieldHeight.small,
    },
    '$selectField.tiny &': {
      height: presets.fieldHeight.tiny,
    },
    '&.disabled': {
      opacity: 0.6,
    },
    '&.invalid': {
      ...presets.invalidField(theme),
    },
    '&:focus': {
      boxShadow: shadows.focus.bold(theme),
    },
  },

  label: {
    ...fonts.responsiveFontStyle(theme.fonts.input),

    '$selectField.small &': {
      ...fonts.responsiveFontStyle(theme.fonts.small),
    },

    '$selectField.tiny &': {
      ...fonts.responsiveFontStyle(theme.fonts.tiny),
    },
  },
}))