import { BrandingGuide, presets } from '~/ui/styling'
import { BackgroundSpec, BorderSpec, ComponentShape } from '../branding/types'
import * as colors from '../colors'
import { ThemeProviderProps } from '../ThemeContext'

export function borderRadiusForShape(shape: ComponentShape, height: number) {
  switch (shape) {
    case 'rectangle':
      return 0
    case 'oval':
      return height / 2
    default:
      return shape.rounded
  }
}

export function themeOptionsForBackground(guide: BrandingGuide, background: BackgroundSpec | null): ThemeProviderProps {
  if (background?.theme === 'dark') {
    return {dark: true}
  } else if (background?.theme === 'light') {
    return {light: true}
  } else if (background?.theme === 'default') {
    return {
      dark:  guide.colors.darkMode ? true : undefined,
      light: !guide.colors.darkMode ? true : undefined,
    }
  } else {
    return {}
  }
}

export function themedBackgroundStyles(guide: BrandingGuide, spec: BackgroundSpec | null) {
  if (spec == null) { return {} }

  if (spec.type === 'solid') {
    return {
      backgroundColor: guide.colors.resolve(spec.color),
    }
  } else if (spec.type === 'image') {
    return {
      backgroundImage: spec.source,
    }
  } else {
    return {
      backgroundImage: colors.linearGradient(
        `${spec.angle}deg`,
        ...spec.colors.map(it => guide.colors.resolve(it)),
      ),
    }
  }
}

export function themedBorderStyles(guide: BrandingGuide, spec: BorderSpec, borderRadius: number): Record<string, any> {
  if (spec.position === 'inside' && spec.offset === 0) {
    // Simple case, just use a plain CSS border.
    return {
      borderWidth: `${spec.width}px`,
      borderStyle: 'solid',

      borderColor: spec.type === 'solid' ? guide.colors.resolve(spec.color).string() : undefined,
      borderImage: spec.type === 'solid' ? undefined : colors.linearGradient(
        `${spec.angle}deg`,
        ...spec.colors.map(it => guide.colors.resolve(it)),
      ) + ' 1',
    }
  } else {
    // Advanced case, use a ::after pseudo.
    const inset = spec.position === 'outside'
      ? -spec.offset - spec.width
      : spec.offset

    return {
      position: 'relative',
      ...presets.overlayAfter({
        top:    `${inset}px`,
        right:  `${inset}px`,
        bottom: `${inset}px`,
        left:   `${inset}px`,

        borderRadius: borderRadius - inset,
        ...themedBorderStyles(guide, {...spec, position: 'inside', offset: 0}, borderRadius),
      }),
    }
  }
}
