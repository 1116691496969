import React from 'react'
import { memo } from '~/ui/component'
import ClearButton, {Props as ClearButtonProps } from './ClearButton'
import { useTranslation } from 'react-i18next'

export interface Props extends Omit<ClearButtonProps, 'caption'> {
  caption?: string | null
}

const BackButton = memo('BackButton', (props: Props) => {

  const [t] = useTranslation()
  const {
    caption = t('buttons:back'),
    ...rest
  } = props

  //------
  // Rendering

  function render() {
    return (
      <ClearButton
        icon='arrow-left'
        caption={caption}
        {...rest}
      />
    )
  }

  return render()

})

export default BackButton