import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { FormError } from './types'

export interface Props {
  errors: FormError[]
}

const FormErrors = memo('FormErrors', (props: Props) => {

  const theme = useTheme()
  const errors = props.errors || []

  return (
    <VBox>
      {errors.map((error, index) => (
        <HBox key={index} gap={layout.padding.inline.s} align='baseline'>
          <SVG name='cross' size={layout.icon.xs} color={theme.fg.error} style={{paddingTop: '0.15em'}}/>
          <Label flex small color={theme.fg.error} truncate={false}>
            {error.message}
          </Label>
        </HBox>
      ))}
    </VBox>
  )
})

export default FormErrors