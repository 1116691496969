import React from 'react'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout, shadows, useStyling, useTheme } from '~/ui/styling'

export interface Props {
  checked:   boolean
  onChange?: (checked: boolean, event: React.SyntheticEvent<any>) => void

  enabled?:    boolean
  readOnly?:   boolean
  classNames?: React.ClassNamesProp
  small?:      boolean
}

const Checkbox = memo('Checkbox', (props: Props) => {

  const {
    checked,
    onChange,
    small    = false,
    enabled  = true,
    readOnly = false,
  } = props

  const {guide} = useStyling()

  const controlEnabled = enabled && !readOnly && onChange != null

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const classNames = [
      $.checkbox,
      {checked, enabled, small},
      props.classNames,
    ]

    const height = small ? size.small.height : size.normal.height

    return (
      <BrandedComponent branding={guide.checkBox} variant={{checked, enabled}} classNames={classNames} height={height}>
        {onChange != null ? (
          <Tappable flex enabled={controlEnabled} onTap={toggle}>
            {renderCheckbox()}
          </Tappable>
        ) : (
          renderCheckbox()
        )}
      </BrandedComponent>

    )
  }

  function renderCheckbox() {
    return (
      <Center flex>
        {checked && <CheckBoxCheck {...props}/>}
        <input
          type='checkbox'
          classNames={$.hideCheckBox}
          checked={checked !== false}
          onChange={change}
        />
      </Center>
    )
  }

  const toggle = React.useCallback((event: React.SyntheticEvent) => {
    onChange?.(!checked, event)
  }, [checked, onChange])

  const change = React.useCallback((event: React.SyntheticEvent) => {
    const input = event.currentTarget as HTMLInputElement
    onChange?.(input.checked, event)
  }, [onChange])

  return render()

})

export default Checkbox

const CheckBoxCheck = memo('RadioButtonCheck', (props: Props) => {

  const {small} = props

  const theme = useTheme()
  const color = !theme.isDark ? theme.semantic.primary : theme.fg.normal

  function render() {
    return (
      <SVG
        name='check'
        size={small ? checkSize.small : checkSize.normal}
        color={color}
      />
    )
  }

  return render()

})


export const size = {
  normal: {
    width:  20,
    height: 20,
  },
  small: {
    width:  16,
    height: 16,
  },
}

const checkSize = {
  normal: {
    width:  12,
    height: 12,
  },
  small: {
    width:  10,
    height: 10,
  },
}

const useStyles = createUseStyles(theme => ({
  checkbox: {
    ...size.normal,
    ...layout.flex.center,

    position: 'relative',
    overflow: 'hidden',

    '&:focus': {
      outline:     'none',
      borderColor: 'transparent',
      boxShadow:   shadows.focus.bold(theme),
    },

    '&:not(.enabled)': {
      opacity: 0.4,
      cursor: 'default',

      '&:focus': {boxShadow: 'none'},
    },

    '&.small': {
      ...size.small,
    },
  },

  hideCheckBox: {
    position: 'absolute',
    width:    0,
    height:   0,
    top:      -20,
    left:     -20,
  },

}))