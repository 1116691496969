import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrLibraryTopic } from '~/models'
import { bewizrStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import BeWizrGrid from './BeWizrGrid'
import BeWizrLibraryTopicTile from './library/BeWizrLibraryTopicTile'

const BeWizrLibraryScreen = observer('BeWizrLibraryScreen', () => {

  const [t] = useTranslation('bewizr')

  const topicsEndpoint = bewizrStore.myTopics

  const fetchStatus = topicsEndpoint.fetchStatus

  const fetchMore = React.useCallback(() => {
    topicsEndpoint?.fetchMore()
  }, [topicsEndpoint])

  const fetch = React.useCallback(() => {
    topicsEndpoint.fetch()
  }, [topicsEndpoint])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  const empty = fetchStatus !== 'done' || topicsEndpoint.empty

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={t('title')}
        />
        <Scroller contentPadding={layout.padding.m} onEndReached={fetchMore}>
          {empty ? renderEmpty() : renderBody()}
        </Scroller>
      </>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        {...t('library.topics.empty')}
        status={fetchStatus}
        flex
      />
    )
  }

  function renderBody() {
    return (
      <BeWizrGrid
        endpoint={topicsEndpoint}
        renderItem={rendertopic}
      />
    )
  }

  function rendertopic(topic: BeWizrLibraryTopic) {
    return (
      <BeWizrLibraryTopicTile topic={topic}/>
    )
  }

  return render()

})

export default BeWizrLibraryScreen