import React from 'react'
import { useLocation } from 'react-router-dom'
import * as H from 'history'

export function useLocationChange(listener: (location: H.Location) => any, deps?: any[]) {
  const location = useLocation()

  React.useEffect(() => {
    listener(location)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listener, location, ...deps ?? []])
}