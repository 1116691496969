import React from 'react'
import { useTranslation } from 'react-i18next'
import { useVirtualizedChat } from 'react-virtualized-chat'
import ms from 'ms'
import config from '~/config'
import { Channel, isPendingMessage, MessageList, MessageListItem } from '~/models'
import { useClock } from '~/socket/useClock'
import { observer } from '~/ui/component'
import { Center, HBox, Label, Notice, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, presets, useStyling } from '~/ui/styling'
import renderMessageBubble from './bubbles'
import { useChat } from './ChatContext'
import ChatMessageFeedback from './ChatMessageFeedback'
import ChatMessageModeration from './ChatMessageModeration'
import ChatMessageSender from './ChatMessageSender'
import MessageStatusIcon from './MessageStatusIcon'

export interface Props {
  index:        number
  channel:      Channel
  timestamp?:   boolean
  messageList:  MessageList

  onFeedbackChange: () => any
}

const ChatMessage = observer('ChatMessage', (props: Props) => {

  const {channel, messageList, index, timestamp = true, onFeedbackChange} = props
  const {currentTime} = useClock({interval: 'once'})

  const {chat, channel: channelController} = useChat()

  const [, i18n] = useTranslation('chat')

  const {guide} = useStyling()

  //------
  // Data

  const message  = messageList.messagesDescending[index]
  const previous = index < messageList.messagesDescending.length - 1 ? messageList.messagesDescending[index + 1] : null
  const next     = index > 0 ? messageList.messagesDescending[index - 1] : null

  const getFrom = React.useCallback((message: MessageListItem | null) => {
    if (message == null) {
      return null
    } else if (isPendingMessage(message)) {
      return chat?.sender?.id
    } else {
      return message.from
    }
  }, [chat?.sender?.id])

  const from         = getFrom(message)
  const previousFrom = getFrom(previous)
  const nextFrom     = getFrom(next)

  const newGroup    = from !== previousFrom
  const mine        = isPendingMessage(message) || (chat?.sender != null && from === chat.sender?.id)
  const align       = message.type === 'notice' ? 'stretch' : mine ? 'right' : 'left'

  const redacted            = message.status === 'redacted'
  const moderationSupported = channelController?.approveMessage != null
  const showModeration      = moderationSupported && channel.isModerated && channel.isModerator && message.redactedAt == null

  const sender = React.useMemo(() => {
    if (isPendingMessage(message)) {
      return chat?.sender
    } else {
      return channelController?.senders.get(message.from)
    }
  }, [channelController?.senders, chat?.sender, message])

  const showSender = React.useMemo(() => {
    if (mine) { return false }
    if (channel.type === 'participant' && sender?.type !== 'user') { return false }
    if (message.type === 'notice') { return false }

    return from !== previousFrom
  }, [channel.type, mine, sender?.type, message.type, from, previousFrom])

  const showDate = React.useMemo(() => {
    if (!timestamp) { return false }
    if (message == null) { return false }
    if (previous == null) { return true }

    return !message.sentAt.hasSame(previous.sentAt, 'days')
  }, [message, previous, timestamp])

  const showTimestamp = React.useMemo(() => {
    if (!timestamp) { return false }
    if (message == null) { return false }
    if (message.type === 'notice') { return false }
    if (showModeration) { return false }
    if (!isPendingMessage(message) && message.feedback != null) { return false }
    if (next == null) { return true }
    if (from !== nextFrom) { return true }

    const difference = next.sentAt.diff(message.sentAt).milliseconds
    return difference >= ms(config.chat.timestampInterval)
  }, [from, message, next, nextFrom, showModeration, timestamp])

  const replyTo = React.useMemo(() => {
    if (message.replyTo == null) { return null }

    if (typeof message.replyTo !== 'string') {
      return message.replyTo
    } else {
      const replyTo = messageList.get(message.replyTo) ?? null
      return replyTo == null || isPendingMessage(replyTo) ? null : replyTo
    }
  }, [message.replyTo, messageList])

  const inlineReply    = replyTo != null && replyTo.id !== previous?.id

  const feedbackExpired = React.useMemo(() => {
    if (isPendingMessage(message) || message.feedback == null) { return false }
    if (message.isAnswered) { return false }
    if (currentTime == null) { return false }
    return message.feedbackExpiredAt(currentTime)
  }, [currentTime, message])

  const showMessageStatus = mine || feedbackExpired || redacted

  //------
  // Layout update

  const list = useVirtualizedChat()

  const recalculateRowHeight = React.useCallback(() => {
    list.recalculateRowHeight(message.id)
  }, [list, message.id])

  // Automatically update if the timestamp has changed.

  const prevTimestamp     = usePrevious(showTimestamp)
  const timestampChanged  = prevTimestamp != null && prevTimestamp !== showTimestamp
  const prevRedactedAt    = usePrevious(message.redactedAt)
  const redactedChanged   = message.redactedAt !== prevRedactedAt

  React.useLayoutEffect(() => {
    if (timestampChanged || redactedChanged) {
      recalculateRowHeight()
    }
  }, [recalculateRowHeight, redactedChanged, timestampChanged])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const classNames = [
      $.chatMessage,
      {
        newGroup:   newGroup,
        moderation: showModeration,
        approve:    message.approvedAt === null,
        redacted:   redacted,
      },
    ]

    return (
      <VBox classNames={classNames} gap={layout.padding.inline.s}>
        {showDate && renderDate()}
        <HBox gap={layout.padding.inline.m}>
          <VBox flex align={align} gap={layout.padding.inline.xs}>
            {showSender && renderSender()}
            {renderMainRow()}
            {renderTimestamp()}
          </VBox>
          {renderModeration()}
        </HBox>
        {renderFeedback()}
      </VBox>
    )
  }

  function renderMainRow() {
    return (
      <HBox gap={layout.padding.inline.xs} align='bottom'>
        {mine && renderMessageStatus()}
        <VBox flex='both' classNames={$.bubble}>
          {renderMessageBubble(message, replyTo, inlineReply)}
        </VBox>
        {!mine && renderMessageStatus()}
      </HBox>
    )
  }

  function renderModeration() {
    if (!showModeration) { return null }
    if (isPendingMessage(message)) { return null }
    if (channelController == null) { return null }

    return (
      <ChatMessageModeration
        message={message}
        channelController={channelController}
      />
    )
  }

  function renderDate() {
    if (message == null) {return null}

    return (
      <Center>
        <Notice branding={guide.chat.bubble.date} classNames={$.date}>
          <Label dim tiny align='center'>
            {message.sentAt.toFormat('d MMM', {locale: i18n.language})}
          </Label>
        </Notice>
      </Center>
    )
  }

  function renderSender() {
    return (
      <VBox classNames={[$.sender, {mine}]}>
        <ChatMessageSender
          message={message}
        />
      </VBox>
    )
  }

  function renderMessageStatus() {
    if (!showMessageStatus) { return null }

    return (
      <MessageStatusIcon
        message={message}
      />
    )
  }

  function renderFeedback() {
    if (mine) { return null }
    if (isPendingMessage(message) || message.feedback == null) { return null }

    return (
      <VBox classNames={[$.feedback, {hasNext: next != null}]}>
        <ChatMessageFeedback
          message={message}
          onChange={onFeedbackChange}
        />
      </VBox>
    )
  }

  function renderTimestamp() {
    if (!showTimestamp) { return null }
    if (message?.sentAt == null) { return null }

    return (
      <VBox classNames={$.timestamp} align={align}>
        <Label tiny dimmer>
          {message.sentAt.toFormat('HH:mm')}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default ChatMessage

const useStyles = createUseStyles(theme => ({
  chatMessage: {
    position: 'relative',
    padding:  [0, layout.padding.inline.l],

    '&.moderation': {
      '&.approve': {
        background:       'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAAD4/042AAABDklEQVRoBdXYuQ3CQBCFYdYEJIguqIoaqIYaqIouEIkDBBoHlixfe8zx3hYw+38TTjoYvtv9dfn+TmerL46p/ySr4R7xz8f1bQLwipflqwM849UB3vGqgIh4NUBUvAogMr4ZEB3fBECIrwagxFcBkOKLAWjxRQDE+GwAanwWADl+F4AevwlgiF8FsMQvApjiZwC2+AmAMX4EsMYPAOZ4AXTWdxs5fchHFk+W31kMlplydLKOl+WbALziZVHqAM94dYB3vCogIl4NEBWvAoiMbwZExzcBEOKrASjxVQCk+GIAWnwRADE+G4AanwVAjt8FoMdvAhjiVwEs8YsApvgZgC1+AmCMHwGs8QOAOV4Af4qwfgmCnbvjAAAAAElFTkSuQmCC")',
        backgroundSize:   [24, 24],
        backgroundRepeat: 'repeat',
      },

      '&:hover': {
        ...presets.overlayBefore({
          background: theme.bg.subtle,
        }),
      },
    },
  },

  date: {
    padding:  [layout.padding.inline.s, layout.padding.inline.xl],
    minWidth: '30%',
  },

  sender: {
    '&:not(.mine)': {
      paddingLeft: theme.guide.chat.bubble.borderRadius(theme.guide.chat.bubble.minHeight) / 2,
    },
    '&.mine': {
      paddingRight: theme.guide.chat.bubble.borderRadius(theme.guide.chat.bubble.minHeight) / 2,
    },
  },

  timestamp: {
    padding: [0, layout.padding.inline.m],
  },

  bubble: {
    pointerEvents: 'auto',
    '$chatMessage.redacted &': {
      opacity: 0.3,
    },
  },

  feedback: {
    pointerEvents: 'auto',
    '&.hasNext': {
      paddingBottom: layout.padding.inline.m,
    },
  },
}))