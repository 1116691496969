import React from 'react'
import { useTranslation } from 'react-i18next'
import { FetchError } from 'mobx-document'
import { sparse } from 'ytil'
import { authenticationStore, AuthResetAccount } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Empty,
  PushButton,
  Spinner,
  TabPanel,
  TextBlock,
  VBox,
} from '~/ui/components'
import { Tab } from '~/ui/components/TabPanel'
import { Form, FormField, FormProps, useForm } from '~/ui/form'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import AuthForm from '../AuthForm'
import PinCodeField from '../login/PinCodeField'
import AuthResetAccountList from './AuthResetAccountList'
import AuthResetFormModel from './AuthResetFormModel'

export interface Props extends FormProps<AuthResetFormModel> {
  onBackTap?: () => any
}

const AuthResetForm = observer('AuthResetForm', (props: Props) => {

  const {model: formModel, onBackTap} = props
  const {token} = formModel

  const [t] = useTranslation('auth')

  //------
  // Accounts

  const endpoint = React.useMemo(() => {
    if (token == null) { return null }
    return authenticationStore.pinResetAccountEndpoint(token)
  }, [token])

  const accounts         = endpoint?.data
  const fetchStatus      = endpoint?.fetchStatus ?? 'idle'
  const hasSingleAccount = accounts?.length === 1
  const accountSelected  = formModel.account != null
  const hasAccountsTab   = !hasSingleAccount

  const selectAccount = React.useCallback((account: AuthResetAccount) => {
    formModel.selectAccount(account)
  }, [formModel])

  React.useEffect(() => {
    if (hasSingleAccount && !accountSelected) {
      selectAccount(accounts[0])
    }
  }, [accountSelected, accounts, formModel.account, hasSingleAccount, selectAccount])

  React.useEffect(() => {
    endpoint?.fetch()
  }, [endpoint])

  //------
  // In-form navigation

  const tabs = React.useMemo((): Tab<AuthResetFormTab>[] => sparse([
    hasAccountsTab && {
      name:    'accounts',
      caption: t('auth_reset.accounts_tab'),
    },
    {
      name:    'pin',
      caption: t('auth_reset.pin_tab'),
    },
  ]), [hasAccountsTab, t])

  const currentTab = (hasSingleAccount || accountSelected) ? 'pin' : 'accounts'

  const instruction =
    formModel.account != null ? t('auth_reset.reset_instruction') :
    fetchStatus === 'done' ? t('auth_reset.account_instruction') :
    null

  const backCaption = accounts?.length === 1 || formModel.account == null
    ? t('auth_reset.back_to_login')
    : t('auth_reset.back_to_account')

  const goBack = React.useCallback(() => {
    if (accountSelected && hasAccountsTab) {
      formModel.clearAccount()
    } else {
      onBackTap?.()
    }
  }, [accountSelected, formModel, hasAccountsTab, onBackTap])

  //------
  // Focus handling

  const pinCodeFieldRef = React.useRef<PinCodeField>(null)

  React.useLayoutEffect(() => {
    if (accountSelected) {
      pinCodeFieldRef.current?.focus()
    }
  }, [accountSelected])

  //------
  // Rendering

  const $ = useStyles()
  const theme = useTheme()

  function render() {
    return (
      <Form classNames={$.AuthResetForm} {...props}>
        <AuthForm preamble={instruction}>
          {token == null || endpoint == null ? (
            renderMissingToken()
          ) : endpoint.data.length === 0 ? (
            renderEmpty()
          ) : (
            renderTabPanel()
          )}
        </AuthForm>
      </Form>
    )
  }

  function renderMissingToken() {
    return (
      <VBox gap={layout.padding.l}>
        <Empty
          {...t('auth_reset.missing-token')}
          padded={false}
          color={theme.colors.fg.dark.error}
        />
        <AuthResetFormButtons
          onBackTap={onBackTap != null ? goBack : undefined}
          backCaption={backCaption}
        />
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <VBox gap={layout.padding.l}>
        {fetchStatus === 'fetching' ? (
          <Center>
            <Spinner/>
          </Center>
        ) : fetchStatus instanceof FetchError ? (
          <TextBlock tiny color={theme.colors.fg.dark.error} align='center'>
            {t([
              `auth_reset.error.${fetchStatus.status}`,
              `errors:${fetchStatus.status}.detail`,
              'errors:unknown.detail',
            ])}
          </TextBlock>
        ) : null}
        <AuthResetFormButtons
          onBackTap={onBackTap != null ? goBack : undefined}
          backCaption={backCaption}
        />
      </VBox>

    )
  }

  function renderTabPanel() {
    return (
      <VBox classNames={$.tabPanel}>
        <TabPanel<AuthResetFormTab>
          tabs={tabs}
          currentTab={currentTab}
          children={renderTab}
        />
      </VBox>
    )
  }

  const renderAccountList = React.useCallback(() => {
    return (
      <VBox gap={layout.padding.s}>
        <AuthResetAccountList
          availableAccounts={accounts ?? []}
          onSelectAccount={selectAccount}
        />
        <AuthResetFormButtons
          tab='accounts'
          backCaption={backCaption}
          onBackTap={onBackTap != null ? goBack : undefined}
        />
      </VBox>
    )
  }, [accounts, backCaption, goBack, selectAccount, onBackTap])

  const renderFields = React.useCallback(() => {
    return (
      <VBox classNames={$.fields} gap={layout.padding.s} flex='grow'>
        <VBox flex='grow'>
          <FormField name='pin'>
            {bind => <PinCodeField {...bind} ref={pinCodeFieldRef}/>}
          </FormField>
        </VBox>
        <AuthResetFormButtons
          tab='pin'
          backCaption={backCaption}
          onBackTap={onBackTap != null ? goBack : undefined}
        />
      </VBox>
    )
  }, [$.fields, backCaption, goBack, onBackTap])

  const renderTab = React.useCallback((tab: AuthResetFormTab) => {
    if (tab === 'accounts') {
      return renderAccountList()
    } else {
      return renderFields()
    }
  }, [renderAccountList, renderFields])

  return render()

})

export default AuthResetForm

interface AuthResetFormButtonsProps {
  tab?:        AuthResetFormTab
  backCaption: string
  onBackTap?:  () => any
}

const AuthResetFormButtons = observer('AuthResetFormButtons', (props: AuthResetFormButtonsProps) => {

  const {tab, backCaption, onBackTap} = props
  const {model: formModel, submitting} = useForm<AuthResetFormModel>()

  const [t] = useTranslation('auth')

  const accountSelected = formModel.account != null
  const maySubmit       = formModel.maySubmit && accountSelected

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {tab === 'pin' && (
          <PushButton
            icon='login'
            caption={t('auth_reset.submit')}
            enabled={maySubmit}
            working={submitting}
            submit
          />
        )}
        {onBackTap != null && (
          <Center>
            <ClearButton
              icon='arrow-left'
              caption={backCaption}
              onTap={onBackTap}
              align='center'
              padding='horizontal'
              dim
            />
          </Center>
        )}
      </VBox>
    )
  }

  return render()

})

type AuthResetFormTab = 'accounts' | 'pin'

const useStyles = createUseStyles({
  AuthResetForm: {},

  tabPanel: {
    ...layout.responsive(size => ({
      margin: [0, -layout.padding.l[size]],
    })),
  },

  fields: {
    ...layout.responsive(size => ({
      padding: [0, layout.padding.l[size]],
    })),
  },
})