import { every, isArray, repeat, some } from 'lodash'

export type Feedback = TextFeedback | ButtonFeedback | ChoiceFeedback | MediaFeedback | NumericFeedback | RatingFeedback

export interface TextFeedback {
  type:        'text'
  minLength:   number
  maxLength:   number
  multiline:   boolean
  placeholder: string | null
}

export interface ButtonFeedback {
  type:    'button'
  buttons: FeedbackButton[]
}

export interface FeedbackButton {
  value:   string | null
  caption: string
}

export interface ChoiceFeedback {
  type:       'choice'
  choices:    FeedbackChoice[]
  minAnswers: number | null
  maxAnswers: number | null
}

export interface FeedbackChoice {
  value:   string | null
  caption: string
}

export interface MediaFeedback {
  type:       'media'
  mediaTypes: FeedbackMediaType[]
}

export interface MediaFeedback {
  type:       'media'
  mediaTypes: FeedbackMediaType[]
}

export type FeedbackMediaType = 'image' | 'video'
export const FeedbackMediaType: {all: FeedbackMediaType[]} = {
  all: ['image', 'video'],
}

export function acceptedFeedbackMediaMimeTypes(types: FeedbackMediaType[]) {
  const mimeTypes: string[] = []

  if (types.includes('image')) {
    mimeTypes.push('image/jpeg', 'image/png', 'image/gif')
  }
  if (types.includes('video')) {
    mimeTypes.push('video/mp4', 'video/quicktime', 'video/x-msvideo')
  }

  return mimeTypes
}

export function feedbackMediaTypeForMimeType(mimeType: string, supported: boolean = true): FeedbackMediaType | null {
  if (!supported) {
    return /^image\//.test(mimeType) ? 'image' : /^video\//.test(mimeType) ? 'video' : null
  } else {
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return 'image'
      case 'video/mp4':
      case 'video/quicktime':
      case 'video/x-msvideo':
        return 'video'
      default:
        return null
    }
  }
}

export interface NumericFeedback {
  type:     'numeric'
  min:      number
  max:      number
  step:     number | null
  emoji:    string | null
  captions: NumericFeedbackCaption[]
}

export interface NumericFeedbackCaption {
  value: number
  caption: string
}

export interface RatingFeedback {
  type:      'rating'
  style:     'stars' | 'slider'

  maxRating:   number
  halfStars:  boolean

  emoji:      string | null
  scale:      string | null
  lowerLabel: string | null
  upperLabel: string | null
}

//------
// Results

export type FeedbackResult = TalliedResult | SampleResult

export interface TalliedResult {
  type:   'tallied'
  counts: TalliedCount[]
}

export interface TalliedCount {
  answer: Primitive
  count:  number
}

export interface SampleResult {
  type:   'sample'
  values: Primitive[]
}


export function feedbackTypes(): Array<Feedback['type']> {
  return ['text', 'button', 'choice', 'media', 'numeric', 'rating']
}

export function isSingleSelectChoiceFeedback(feedback: ChoiceFeedback) {
  if (feedback.minAnswers == null && feedback.maxAnswers == null) {
    return true
  } else {
    return feedback.minAnswers === 1 && feedback.maxAnswers === 1
  }
}

export function isMultiSelectChoiceFeedback(feedback: ChoiceFeedback) {
  return !isSingleSelectChoiceFeedback(feedback)
}

export function feedbackChoiceValue(choice: FeedbackChoice) {
  return choice.value ?? choice.caption
}

export function feedbackValueToCaption(feedback: Feedback, value: any) {
  if (feedback.type === 'choice') {
    const choice = feedback.choices.find(choice => value === feedbackChoiceValue(choice))
    return choice == null ? value.toString() : choice.caption
  }
  if (feedback.type === 'numeric') {
    return numericFeedbackCaption(feedback, value)
  }
  if (feedback.type === 'rating') {
    return formatStars(value)
  }

  return value.toString()
}

export function numericFeedbackCaption(feedback: NumericFeedback, value: number) {
  const caption = feedback.captions?.find(caption => caption.value === value)
  return caption?.caption ?? value.toString()
}

export function formatStars(value: number) {
  const integer = Math.floor(value)
  const half    = value > integer

  return repeat('⭐️', value) + (half ? '½' : '')
}

export function parseStars(text: string) {
  let starCount
  if (text.includes('⭐️')) {
    starCount = text.split('⭐️').length - 1
  } else {
    starCount = text.split(/[*☆★]/).length - 1
  }
  const halfCount = text.split('½').length - 1

  return starCount + Math.max(0, Math.min(1, halfCount)) / 2
}

export function validateFeedback(feedback: Feedback, answer: any): boolean {
  switch (feedback.type) {
  case 'text':
    if (typeof answer !== 'string') { return false }
    if (feedback.minLength != null && answer.length < feedback.minLength) { return false }
    if (feedback.maxLength != null && answer.length > feedback.maxLength) { return false }
    return true

  case 'choice':
    if (isArray(answer)) {
      if (feedback.minAnswers != null && answer.length < feedback.minAnswers) {
        return false
      }
      if (feedback.maxAnswers != null && answer.length > feedback.maxAnswers) {
        return false
      }

      return every(answer, ans => validateFeedback(feedback, ans))
    } else {
      return some(feedback.choices, choice => (choice.caption ?? (choice as any).label) === answer)
    }

  case 'numeric':
    if (typeof answer !== 'number') { return false }
    if (answer < feedback.min) { return false }
    if (answer > feedback.max) { return false }
    if (feedback.step != null && Math.floor(answer / feedback.step) * feedback.step !== answer) { return false }
    return true

  case 'rating':
    if (typeof answer !== 'number') { return false }
    if (answer < 0.5) { return false }
    if (answer > feedback.maxRating) { return false }
    if (!feedback.halfStars && Math.floor(answer) !== answer) { return false }
    if (feedback.halfStars && Math.floor(answer * 2) / 2 !== answer) { return false }
    return true

  case 'media':
    if (typeof answer !== 'string') { return false }
    return /^https?:\/\//.test(answer)

  default:
    return true

  }
}