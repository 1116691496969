import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDuration } from '~/formatting'
import { Message } from '~/models'
import { useClock } from '~/socket/useClock'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'

export interface Props {
  message:       Message
  requestRemove: () => any
}

const ChatMessageFeedbackCountdown = memo('ChatMessageFeedbackCountdown', (props: Props) => {

  const {message, requestRemove} = props
  const {currentTime} = useClock({interval: 'second'})

  const timeLeft = currentTime == null ? null : message.feedbackTimeLeftAt(currentTime)
  const fraction = timeLeft == null || message.feedback?.expiresAfter == null ? null : timeLeft / message.feedback.expiresAfter

  const theme = useTheme()

  const color = fraction == null || fraction > 0.2
    ? theme.fg.dim
    : theme.semantic.negative

  React.useEffect(() => {
    if (timeLeft == null || timeLeft < 0) {
      requestRemove()
    }
  }, [requestRemove, timeLeft])

  const [t] = useTranslation('chat')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (timeLeft == null) { return null }

    return (
      <ThemeProvider overrides={{fg: {normal: color}}}>
        <VBox classNames={$.feedbackCountdown} gap={layout.padding.inline.s}>
          {renderLabel()}
          {renderProgressBar()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderLabel() {
    if (timeLeft == null) { return null }

    return (
      <HBox justify='center' gap={layout.padding.inline.s}>
        <SVG name='message.pending' size={layout.icon.s}/>
        <Label caption>
          {t('feedback.time_left', {time: formatDuration(timeLeft)})}
        </Label>
      </HBox>
    )
  }

  function renderProgressBar() {
    if (fraction == null) { return null }

    return (
      <HBox classNames={$.progressBar}>
        <div
        style={{
          width:           `${fraction * 100}%`,
          backgroundColor: color.string(),
        }}
        />
      </HBox>
    )
  }

  return render()

})

export default ChatMessageFeedbackCountdown

const useStyles = createUseStyles(theme => ({
  feedbackCountdown: {
    width:        '80%',
    maxWidth:     theme.guide.chat.bubble.maxWidth,
  },

  progressBar: {
    background: theme.bg.alt,

    '&, & > *': {
      height:       6,
      borderRadius: 3,
    },
  },
}))