import React from 'react'
import { memo } from '~/ui/component'
import { SwitchField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { BooleanField } from '../types'

export interface Props {
  field: BooleanField
  bind:  FormFieldBindProps<boolean>
}

const BooleanAllowance = memo('BooleanAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <SwitchField
        {...bind}
        label={field.label}
      />
    )
  }

  return render()

})

export default BooleanAllowance