import { cloneDeep, pick } from 'lodash'
import * as SocketIOClient from 'socket.io-client'
import { SocketError } from 'socket.io-react'
import config from './config'
import { intercept } from './util'

const logStyles   = [{color: '#aaa'}]
const errorStyles = [{color: '#f00'}]

interface Packet {
  data: [string]
}

export default function setup(socket: SocketIOClient.Socket) {
  intercept(socket, 'onevent', onevent)
}

function onevent(this: SocketIOClient.Socket, packet: Packet) {
  const {data: [...args]} = packet
  const event = args.shift()
  if (event === 'ping' || event === 'pong') { return }

  config.logger.debug({text: `← ${event}`, styles: logStyles}, [`ID: ${this.id}`, 'ARGS:', ...args])
}

export function logEmit(socket: SocketIOClient.Socket, event: string, args: any[]) {
  const details = [
    `ID: ${socket.id}`,
    'ARGS:',
    ...args,
  ]

  config.logger.debug({text: `→ ${event}`, styles: logStyles}, details)
}

export function logSend(socket: SocketIOClient.Socket, event: string, args: any[], error: SocketError | null, result: any) {
  const details = [
    `ID: ${socket.id}`,
    'ARGS:',
    ...cloneDeep(args),
  ]

  if (error != null) {
    config.logger.error({text: `⨯ ${event}`, styles: errorStyles}, [...details, 'ERROR:', pick(error, 'status', 'message', 'extra'), 'STACK:', error.stack])
  } else {
    config.logger.debug({text: `✓ ${event}`, styles: logStyles}, [...details, 'RESULT:', result])
  }
}

export function logSendTimeout(socket: SocketIOClient.Socket, event: string, args: any[]) {
  const details = [
    `ID: ${socket.id}`,
    'ARGS:',
    ...cloneDeep(args),
  ]

  config.logger.error({text: `↩ ${event} TIMEOUT`, styles: errorStyles}, details)
}