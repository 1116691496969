import React from 'react'
import { isPlainObject } from 'lodash'
import { CustomImage, isCustomImage } from '~/models'
import { NotificationAvatar } from '~/stores'
import { Avatar, ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { HBox, Label, Panel, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  title?:     React.ReactNode
  body?:      React.ReactNode
  avatar?:    NotificationAvatar | React.ReactNode
  accessory?: CustomImage | React.ReactNode

  href?:  string | null
  onTap?: () => any
}

const NotificationPanel = memo('NotificationPanel', (props: Props) => {

  const {title, body, avatar, accessory, href, onTap} = props

  const $ = useStyles()

  function render() {
    return (
      <Panel depth={2} href={href} onTap={onTap}>
        <HBox classNames={$.body} gap={layout.padding.inline.m} flex align='stretch'>
          <HBox flex gap={layout.padding.inline.m}>
            {renderAvatar()}
            {renderMain()}
          </HBox>
          {renderAccessory()}
        </HBox>
      </Panel>
    )
  }

  function renderAvatar() {
    if (!isNotificationAvatar(avatar)) {
      return avatar
    }

    return (
      <Avatar
        source={avatar.photoURL}
        firstName={avatar.firstName}
        lastName={avatar.lastName}
        size={imageSize}
      />
    )
  }

  function renderAccessory() {
    if (!isCustomImage(accessory)) {
      return accessory
    }

    return (
      <ImageView
        source={accessory}
        size={imageSize}
        objectFit='cover'
        round
      />
    )
  }

  function renderMain() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        {renderTitle()}
        {renderBody()}
      </VBox>
    )
  }

  function renderTitle() {
    if (isReactText(title)) {
      return (
        <Label bold={body != null} markup>
          {title}
        </Label>
      )
    } else {
      return title
    }
  }

  function renderBody() {
    if (isReactText(body)) {
      return (
        <Label dim>
          {body}
        </Label>
      )
    } else {
      return body
    }
  }

  return render()

})

export default NotificationPanel

export function isNotificationAvatar(avatar: any): avatar is NotificationAvatar {
  if (!isPlainObject(avatar)) { return false }
  if (typeof avatar.firstName !== 'string') { return false }
  if (avatar.lastName != null && typeof avatar.lastName !== 'string') { return false }
  if (avatar.photoURL != null && typeof avatar.photoURL !== 'string') { return false }

  return true
}

export const imageSize = {
  width:  48,
  height: 48,
}

export const minHeight = imageSize.height + 2 * layout.padding.inline.m

const useStyles = createUseStyles({
  body: {
    minHeight: minHeight,
    padding:   layout.padding.inline.m,
  },
})