import React from 'react'
import { useTranslation } from 'react-i18next'
import { profileStore } from '~/stores'
import { observer } from '~/ui/component'
import { Scroller } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import ParticipantProfile from './ParticipantProfile'

const ProfileScreen = observer('ProfileScreen', () => {

  const participant = profileStore.participant

  const [t] = useTranslation('participants')

  //------
  // Rendering

  function render() {
    if (participant == null) { return null }

    return (
      <>
        <AppLayoutConfig
          title={t('profile.title')}
        />
        <Scroller contentAlign='center' contentPadding={layout.padding.m}>
          <ParticipantProfile
            participant={participant}
            editable={true}
          />
        </Scroller>
      </>
    )
  }

  return render()

})

export default ProfileScreen