import { action, when } from 'mobx'
import { Database, Document } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryCategory } from '~/models'
import bewizrStore from '../bewizrStore'
import BeWizrLibraryItemDocument from './BeWizrLibraryItemDocument'
import BeWizrLibraryItemsEndpoint from './BeWizrLibraryItemsEndpoint'

export default class BeWizrLibraryCategoryDocument extends Document<BeWizrLibraryCategory> {

  public get slug() {
    return this.id
  }

  //------
  // Items

  private items = new Database<BeWizrLibraryItemDocument>({
    getID:         item => item.id,
    getDocument:   item => new BeWizrLibraryItemDocument(item.id, {initialData: item}),
    emptyDocument: id => new BeWizrLibraryItemDocument(id),
  })

  public itemsEndpoint: BeWizrLibraryItemsEndpoint | null = null

  //------
  // Fetching

  @action
  protected async performFetch() {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('library_category/', {
      params: {
        q: this.slug,
      },
    })
    if (response.status !== 200) { return response }

    const raw = response.data.items.find((it: any) => it.slug === this.slug)
    if (raw == null) { return null }


    const category = BeWizrLibraryCategory.deserialize(raw)

    this.itemsEndpoint = new BeWizrLibraryItemsEndpoint(this.items, {
      category: category.slug,
      topic:    category.topic.slug,
    })

    return {
      data: category,
      meta: {},
    }
  }

}