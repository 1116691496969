import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'
import ComponentBranding from './ComponentBranding'

export default class ChoiceRowBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: ChoiceRowBranding | null,
    defaults?: BrandedComponentSpec,
  ) {
    super(delegate,parent, defaults ?? {
      background:  'alt',
      border:      'none',
      shape:       'oval',
      depth:       2,
      previewSize: {width: 160, height: 48},
    })

    this.variant('selected', {
      background: 'solid',
    })
  }

}