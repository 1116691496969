import Range from './Range'
import { isSpace } from './testers'

/**
 * String with unicode support.
 */
export default class UnicodeString {

  //------
  // Construction

  constructor(str: string | UnicodeString = '') {
    if (str instanceof UnicodeString) {
      this.string = str.string
      this.charactersCache = str.charactersCache
      this.codePointsCache = str.codePointsCache
    } else {
      this.string = str
    }
  }

  //------
  // Properties

  /// The (non-unicde supporting) string.
  public string: string

  /// The length of the string.
  public get length(): number {
    return this.characters.length
  }

  /// The individual unicode characters in the string.
  public get characters(): string[] {
    if (this.charactersCache == null) {
      this.charactersCache = [...this.string]
    }
    return this.charactersCache
  }

  /// The code points in the string.
  public get codePoints(): number[] {
    if (this.codePointsCache == null) {
      this.codePointsCache = this.characters.map(c => c.codePointAt(0) as number)
    }
    return this.codePointsCache
  }

  public charAt(pos: number): string {
    return this.characters[pos]
  }

  public codePointAt(pos: number): number {
    return this.codePoints[pos]
  }

  public charactersCache: string[] | null = null
  public codePointsCache: number[] | null = null

  //------
  // Position converting

  public ctpCache: number[] | null = null

  /// Converts a character index to a (native) string position.
  public characterIndexToPos(index: number): number | null {
    if (this.ctpCache == null) {
      this.ctpCache = []
      for (let i = 0, pos = 0; i < this.characters.length; i++) {
        this.ctpCache.push(pos)
        pos += this.characters[i].length
      }
    }

    if (index === this.ctpCache.length) {
      return this.string.length
    } else if (index < this.ctpCache.length) {
      return this.ctpCache[index]
    } else {
      return null
    }
  }

  //------
  // Methods

  /// Slices the string. See `String.slice`.
  public slice(base: number, extent?: number): UnicodeString {
    const chars = this.characters.slice(base, extent)
    return new UnicodeString(chars.join(''))
  }

  /// Takes a substring of the string. See `String.substr`.
  public substr(start: number, length: number): UnicodeString {
    const chars = this.characters.slice(start, start + length)
    return new UnicodeString(chars.join(''))
  }

  /// Splits the string up using the given separator. The result will also be a UnicodeString
  /// instance.
  public split(separator: string): UnicodeString[] {
    const separatorChars = [...separator]
    const result: UnicodeString[] = []

    let ch
    let start = 0
    let end = 0
    let separatorIndex = 0
    for (let i = 0; i < this.length; i++) {
      ch = this.characters[i]

      if (ch === separatorChars[separatorIndex]) {
        separatorIndex++
      } else {
        end = i + 1
      }

      if (separatorIndex === separatorChars.length) {
        // Split here.
        result.push(this.slice(start, end))
        start = i + 1
        end = start
        separatorIndex = 0
      }
    }
    result.push(this.slice(start, this.length))

    return result
  }

  /// Removes whitespace from the beginning and end of this string.
  public trim(): UnicodeString {
    return this.trimStart().trimEnd()
  }

  /// Removes whitespace from the start of this string.
  public trimStart(): UnicodeString {
    let start = 0
    while (isSpace(this.characters[start])) {
      start++
    }
    return this.slice(start)
  }

  /// Removes whitespace from the end of this string.
  public trimEnd(): UnicodeString {
    let length = this.length
    while (isSpace(this.characters[length - 1])) {
      length--
    }
    return this.slice(0, length)
  }

  /// Inserts text in this string.
  public insert(pos: number, str: string | UnicodeString): UnicodeString {
    const range = new Range(pos, pos)
    return this.replaceRange(range, str)
  }

  /// Replaces the range in this string.
  public replaceRange(range: Range, newValue: string | UnicodeString): UnicodeString {
    const pre = this.slice(0, range.start)
    const post = this.slice(range.end)
    return new UnicodeString(`${pre}${newValue}${post}`)
  }

  /// Determines if this unicode string is equal to the given string.
  public equals(other: string | UnicodeString): boolean {
    if (other instanceof UnicodeString) {
      return other.string === this.string
    } else {
      return other === this.string
    }
  }

  /// Replaces part of this string with another string.
  public replace(pattern: string | RegExp, replacement: string): UnicodeString {
    return new UnicodeString(this.string.replace(pattern, replacement))
  }

  public static concat(...strings: Array<string | UnicodeString>) {
    return new UnicodeString(strings.map(it => it.toString()).join(''))
  }

  //------
  // Conversions

  public toUpperCase() {
    return new UnicodeString(this.string.toLocaleUpperCase())
  }

  public toLowerCase() {
    return new UnicodeString(this.string.toLocaleLowerCase())
  }

  public normalize() {
    return new UnicodeString(
      this.string.normalize("NFD").replace(/[\u0300-\u036f]/g, ''),
    )
  }

  public [Symbol.toPrimitive]() {
    return this.string
  }

  public toString() {
    return this.string
  }

}