import React from 'react'
import { useTranslation } from 'react-i18next'
import { camelCase, mapKeys } from 'lodash'
import { memo } from '~/ui/component'
import { TextBlock, TextField, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import hostRef from './hostRef'
import MessageBox from './MessageBox'
import { ConfirmBoxOptions, ConfirmBoxProps, MessageBoxButtonProps } from './types'

const ConfirmBox = memo('ConfirmBox', (props: ConfirmBoxProps) => {

  const {
    message,
    destructive = false,
    confirmText,
    yesCaption,
    noCaption,
    ...rest
  } = props

  const [text, setText] = React.useState<string>('')
  const [t] = useTranslation()

  const buttons = React.useMemo((): MessageBoxButtonProps<boolean>[] => [{
    result:      true,
    caption:     yesCaption ?? t('misc:yes'),
    destructive: destructive,
    enabled:     confirmText == null || confirmText === text,
  }, {
    result:  false,
    clear:   true,
    dim:     true,
    icon:    'cross',
    align:   'center',
    caption: noCaption ?? t('misc:no'),
  }], [confirmText, destructive, noCaption, t, text, yesCaption])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <MessageBox<boolean>
        {...rest}
        cancelResult={false}
        buttons={buttons}
        message={renderMessage}
      />
    )
  }

  function renderMessage(resolve: (result: boolean) => void) {
    return (
      <VBox flex='both' gap={layout.padding.s} classNames={$.body}>
        <TextBlock caption markup>
          {message}
        </TextBlock>

        {confirmText != null && renderDangerousConfirmField(resolve)}
      </VBox>
    )
  }

  function renderDangerousConfirmField(resolve: (result: boolean) => void) {
    return (
      <TextField
        value={text}
        onChange={setText}
        autoFocus
        selectOnFocus={true}
        inputStyle='dark'
        onCommit={validateAndConfirm.bind(null, resolve)}
      />
    )
  }

  const validateAndConfirm = React.useCallback((resolve: (result: boolean) => void) => {
    if (confirmText != null && text !== confirmText) { return }
    resolve(true)
  }, [confirmText, text])

  return render()

})

const ConfirmBoxStatic = {
  show(props: ConfirmBoxOptions): Promise<boolean> {
    return new Promise(resolve => {
      if (hostRef.current == null) {
        console.error("MessageBox: No host registered")
      }

      hostRef.current?.push(
        <ConfirmBox
          {...mapKeys(props, (_, key) => camelCase(key)) as any}
          resolve={resolve}
        />)
    })
  },
}

Object.assign(ConfirmBox, ConfirmBoxStatic)
export default ConfirmBox as typeof ConfirmBox & typeof ConfirmBoxStatic

const useStyles = createUseStyles({
  body: {
    width: 480,

    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.m[size]],
    })),
  },

  buttons: {
    borderBottomLeftRadius:  layout.radius.m,
    borderBottomRightRadius: layout.radius.m,

    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
})