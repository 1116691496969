import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollManager, ScrollOptions, ScrollState } from 'react-scroll-manager'
import { ClearButton, VBox } from '~/ui/components'
import { animation, createUseStyles } from '~/ui/styling'

export interface Props {
  edge:          'top' | 'bottom'
  scrollManager: ScrollManager
  behavior?:     'instant' | 'smooth'

  classNames?: React.ClassNamesProp
}

export default function ScrollToEdgeButton(props: Props) {

  const {edge, behavior = 'smooth', scrollManager} = props
  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)

  const [t] = useTranslation('scroller')

  React.useEffect(() => {
    const listener = () => {
      setScrollState(scrollManager.getScrollState(false))
    }

    scrollManager.addScrollListener(listener)
    listener()

    return () => {
      scrollManager.removeScrollListener(listener)
    }
  }, [scrollManager])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const visible =
      edge === 'top' ? !scrollState.atStart :
      edge === 'bottom' ? !scrollState.atEnd :
      false

    return (
      <VBox classNames={[$.scrollToEdgeButton, {visible}, props.classNames]}>
        {renderButton()}
      </VBox>
    )
  }

  function renderButton() {
    return (
      <ClearButton
        caption={t(`scroll_to.${edge}`)}
        onTap={handleTap}
        icon={edge === 'top' ? 'chevron-up' : 'chevron-down'}
        padding='both'
        small
      />
    )
  }

  const handleTap = React.useCallback(() => {
    if (edge === 'top') {
      scrollManager.scrollTo({top: 0, behavior} as ScrollOptions)
    } else {
      scrollManager.scrollTo({top: scrollManager.maxScrollPosition.top, behavior} as ScrollOptions)
    }
  }, [edge, behavior, scrollManager])

  return render()

}

const useStyles = createUseStyles({
  scrollToEdgeButton: {
    willChange: ['opacity'],
    transition: animation.transitions.short(['opacity']),

    opacity: 0,
    '&.visible': {
      opacity: 1,
    },
  },
})