import React from 'react'
import { useHistory } from '~/history'
import { memo } from '~/ui/component'
import { Badge, HBox, Label, Tappable } from '~/ui/components'
import SVG, { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { useDrawer } from './DrawerContext'

export interface Props {
  href:    string
  icon?:    SVGName
  caption: React.ReactNode
  badge?:  number | string | null
}

const DrawerLink = memo('DrawerLink', (props: Props) => {

  const {
    href,
    icon,
    caption,
    badge = null,
  } = props

  const history = useHistory()

  const drawer = useDrawer()

  const subtle = icon == null

  const tap = React.useCallback(() => {
    drawer.close()
    history.reset()
  }, [drawer, history])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable href={href} onTap={tap}>
        <HBox classNames={[$.DrawerLink, {subtle}]} gap={layout.padding.inline.l}>
          {renderIcon()}
          {renderCaption()}
          {renderBadge()}
        </HBox>
      </Tappable>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }
    return <SVG name={icon} size={layout.icon.l}/>
  }

  function renderCaption() {
    if (isReactText(caption)) {
      return (
        <Label
          h3={!subtle}
          dim={subtle}
          small={subtle}
          children={caption}
        />
      )
    } else {
      return caption
    }
  }

  function renderBadge() {
    if (badge == null) { return null }

    return (
      <Badge value={badge}/>
    )
  }

  return render()

})

export default DrawerLink

export const drawerLinkHeight = 40

const useStyles = createUseStyles({
  DrawerLink: {
    height: drawerLinkHeight,
    padding: [layout.padding.inline.s, layout.padding.inline.l],
    '&.subtle': {
      height: 'auto',
      paddingTop: layout.padding.inline.s,
      paddingBottom: layout.padding.inline.s,
    },
  },
})