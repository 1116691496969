import React from 'react'
import { getSearchResultComponent } from './registry'
// @index(!registry): import ${relpath}
import './BeWizrCourseItemSearchResult'
import './BeWizrCourseSearchResult'
import './MenuPageItemSearchResult'
import './ParticipantSearchResult'
import './PostSearchResult'

// /index

export function renderSearchResult(resource: string, item: any) {
  const Component = getSearchResultComponent(resource)
  if (Component == null) {
    console.warn(`Cannot find search result component for resource "${resource}"`)
    return null
  }

  return React.createElement(Component, {item})
}