import { TransitionCallbacks } from './types'

const FRAME = 16

export default class Timer {

  //------
  // Lifecycle

  constructor(component?: React.Component<any>) {
    if (component) {
      this.hookUnmount(component)
    }
  }

  private hookUnmount(component: React.Component<any>) {
    const originalComponentWillUnmount = component.componentWillUnmount
    component.componentWillUnmount = () => {
      this.dispose()
      if (originalComponentWillUnmount) {
        originalComponentWillUnmount.call(component)
      }
    }
  }

  private disposed: boolean = false

  public get isDisposed() {
    return this.disposed
  }

  public dispose() {
    this.clearAll()
    this.cancelAllAnimationFrames()
    this.disposed = true
  }

  //------
  // Properties

  private readonly timeouts: Set<TimerHandle> = new Set()
  private readonly animationFrames: Set<TimerHandle> = new Set()

  public get isActive() {
    return this.timeouts.size > 0 || this.animationFrames.size > 0
  }

  //------
  // setTimeout / clearTimeout

  public setTimeout(fn: () => any, ms: number): TimerHandle {
    if (this.disposed) { return null }

    const timeout = setTimeout(() => {
      this.timeouts.delete(timeout)
      fn()
    }, ms)

    this.timeouts.add(timeout)
    return timeout
  }

  public clearTimeout(timeout: TimerHandle | null) {
    if (timeout == null) { return }
    clearTimeout(timeout)
    this.timeouts.delete(timeout)
  }

  //------
  // setInterval / clearInterval

  public setInterval(fn: () => any, ms: number) {
    if (this.disposed) { return null }

    const timeout = setInterval(fn, ms)
    this.timeouts.add(timeout)
    return timeout
  }

  public clearInterval(interval: number | null) {
    if (interval == null) { return }
    clearInterval(interval)
    this.timeouts.delete(interval)
  }

  //------
  // Animation frame

  public requestAnimationFrameAfter(fn: () => any, timeout: number) {
    if (this.disposed) { return null }
    this.setTimeout(() => {
      this.requestAnimationFrame(fn)
    }, timeout)
  }

  public requestAnimationFrame(fn: () => any) {
    if (this.disposed) { return null }

    const animationFrame = requestAnimationFrame(() => {
      this.animationFrames.delete(animationFrame)
      fn()
    })

    this.animationFrames.add(animationFrame)
    return animationFrame
  }

  public cancelAnimationFrame(animationFrame: number) {
    cancelAnimationFrame(animationFrame)
    this.animationFrames.delete(animationFrame)
  }

  public cancelAllAnimationFrames() {
    for (const animationFrame of this.animationFrames) {
      cancelAnimationFrame(animationFrame)
    }
    this.animationFrames.clear()
  }

  //------
  // Promises

  public await<T>(promise: PromiseLike<T> | T): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const retval = await promise
        if (!this.isDisposed) {
          resolve(retval)
        }
      } catch (error: any) {
        if (!this.isDisposed) {
          reject(error)
        }
      }
    })
  }

  public then<T, U>(promise: Promise<T>, callback: (result: T) => U): Promise<U | undefined> {
    return promise.then(result => {
      if (this.isDisposed) { return }
      return callback(result)
    })
  }

  //------
  // Throttle & debounce

  public throttle(fn: () => any, ms: number) {
    if (!this.isActive) {
      return this.setTimeout(fn, ms)
    }
  }

  public debounce(fn: () => any, ms: number) {
    this.clearAll()
    return this.setTimeout(fn, ms)
  }

  //------
  // Transitions

  public performTransition(duration: number, transition: TransitionCallbacks) {
    if (transition.onPrepare) {
      transition.onPrepare()
    }

    if (transition.onCommit) {
      this.setTimeout(transition.onCommit, FRAME)
    }

    if (transition.onCleanUp) {
      this.setTimeout(transition.onCleanUp, FRAME + duration)
    }
  }

  //------
  // Clear all

  public clearAll() {
    for (const timeout of this.timeouts) {
      clearTimeout(timeout)
    }
    this.timeouts.clear()
  }

}

type TimerHandle = any