import { ScopedSocket, Socket } from 'socket.io-react'
import { v4 as uuidV4 } from 'uuid'

export default class SocketOperation {

  constructor(
    private readonly socket: Socket | ScopedSocket,
    public readonly event: string,
    public readonly uid: string = uuidV4().slice(0, 4),
  ) {
    this.socket.addEventListener(`${event}:${uid}:progress`, this.handleProgress)
  }

  private handleProgress = (progress: number, ...args: any[]) => {
    for (const listener of this.progressListeners) {
      listener(progress, ...args)
    }
  }

  private progressListeners = new Set<ProgressListener>()

  public addProgressListener(listener: ProgressListener) {
    this.progressListeners.add(listener)
    return () => {
      this.progressListeners.delete(listener)
    }
  }

}

export type ProgressListener = (progress: number, ...args: any[]) => any