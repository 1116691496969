import React from 'react'
import { Post } from '~/models'
import { memo } from '~/ui/component'
import { Label, ListBar, SVG } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  item: Post
}

const PostSearchResult = memo('PostSearchResult', (props: Props) => {

  const {item} = props

  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={<SVG name='feed' size={layout.icon.l} color={colors.semantic.primary}/>}
        caption={item.author.name}
        detail={<Label truncate markup>{item.summary}</Label>}
        accessory={<SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        href={`/posts/-/${item.id}`}
      />
    )
  }

  return render()

})

register('posts', PostSearchResult)