import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'moment'
import { Challenge } from '~/models'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { Label, Tile, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  challenge: Challenge
  minHeight: number
}

const ChallengeTile = observer('ChallengeTile', (props: Props) => {

  const {challenge, minHeight} = props

  const document = challengesStore.challenges.document(challenge.id, false)
  const state    = document?.state

  const completedAt  = state?.completedAt
  const reviewedAt   = state?.reviewedAt
  const deadline     = state?.deadline
  const pastDeadline = deadline == null ? false : new Date(deadline) < new Date()

  const noStatus = completedAt == null && reviewedAt == null && deadline == null

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tile
        classNames={[$.challengeTile, {pastDeadline}]}
        image={challenge.image ?? null}
        label={challenge.title ?? challenge.name}
        detail={Moment(state?.publishedAt).format('ll')}
        accessory={renderStatus()}
        minHeight={minHeight}
        href={`/challenges/-/${challenge.id}`}
      />
    )
  }

  function renderStatus() {
    if (noStatus) { return null }
    return (
      <VBox classNames={$.status}>
        <Label tiny>
          {renderStatusLabel()}
        </Label>
      </VBox>
    )
  }

  function renderStatusLabel() {
    if (state?.completedAt != null) {
      return document?.data?.needsReview ?
        state.reviewedAt != null ? renderReviewed() : renderInReview() :
        renderCompleted()
    } else if (state?.deadline != null) {
      return renderDeadline()
    } else {
      return null
    }
  }

  function renderInReview() {
    return t('detail.in_review')
  }

  function renderReviewed() {
    const reviewedAt = state?.reviewedAt && Moment(state.reviewedAt).fromNow()
    if (reviewedAt == null) { return null }

    return t('detail.reviewed', {when: reviewedAt})
  }

  function renderDeadline() {
    const deadline = state?.deadline && Moment(state.deadline).fromNow()
    if (deadline == null) { return null }

    return t('detail.deadline', {when: deadline})
  }

  function renderCompleted() {
    const completedAt = state?.completedAt && Moment(state.completedAt).fromNow()
    if (completedAt == null) { return null }

    return t('detail.completed', {when: completedAt})
  }

  return render()

})

export default ChallengeTile

const useStyles = createUseStyles((theme) => ({
  challengeTile: {
    '&.pastDeadline': {
      opacity: 0.6,
    },
  },

  status: {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
  },
}))