import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { Post } from '~/models'
import { observer } from '~/ui/component'
import { EmptyOrFetching, VBox } from '~/ui/components'
import Scroller from '~/ui/components/scroller/Scroller'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import { useNewsService } from './NewsContext'
import PostView from './PostView'

export interface PostParams {
  id: string
}

export type Props = RouteComponentProps<PostParams>

const PostScreen = observer('PostScreen', (props: Props) => {

  const [t] = useTranslation('news')

  const {id} = props.match.params

  const service  = useNewsService()
  const document = service.posts.document(id)
  const post     = document.data

  const fetchStatus = service.starting ? 'fetching' : document.fetchStatus

  const fetch = React.useCallback(() => {
    if (service.starting) { return }
    document.fetch()
  }, [service.starting, document])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.PostScreen}>
        <AppLayoutConfig
          title={post?.summary}
          fetchStatus={document.fetchStatus}
        />

        {document.data == null ? (
          renderEmpty()
        ) : (
          renderBody(document.data)
        )}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        {...t('post.empty')}
        flex
      />
    )
  }

  function renderBody(post: Post) {
    return (
      <Scroller classNames={$.scroller} contentClassNames={$.scrollerContent} flex>
        <PostView
          post={post}
          commentsExpanded={true}
        />
      </Scroller>
    )
  }

  return render()

})

export default PostScreen

const useStyles = createUseStyles({
  PostScreen: {
  },

  scroller: {
    margin: [0, -8],
    marginRight: 0,
  },

  scrollerContent: {
    alignItems: 'center',
    width: '100%',
    ...layout.responsive(size => ({
      padding:      [layout.padding.m[size], layout.padding.m[size] + 8],
      paddingRight: layout.padding.m[size],
    })),
  },
})