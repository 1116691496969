export function jssPadding<T extends number | string>(...paddings: T[]): JSSPadding<T> {
  const padding = paddings.slice(0, 4) as JSSPadding<T>

  if (paddings.length === 1) {
    padding.vertical   = padding[0]
    padding.horizontal = padding[0]
    padding.top        = padding[0]
    padding.right      = padding[0]
    padding.bottom     = padding[0]
    padding.left       = padding[0]
  } else if (paddings.length === 2) {
    padding.vertical   = padding[0]
    padding.horizontal = padding[1]
    padding.top        = padding[0]
    padding.right      = padding[1]
    padding.bottom     = padding[0]
    padding.left       = padding[1]
  } else if (paddings.length === 3) {
    padding.vertical   = padding[0]
    padding.horizontal = padding[1]
    padding.top        = padding[0]
    padding.right      = padding[1]
    padding.bottom     = padding[2]
    padding.left       = padding[1]
  } else {
    padding.vertical   = padding[0]
    padding.horizontal = padding[1]
    padding.top        = padding[0]
    padding.right      = padding[1]
    padding.bottom     = padding[2]
    padding.left       = padding[3]
  }

  return padding
}

export type JSSPadding<T extends number | string> = T[] & JSSPaddingAccessors<T>

interface JSSPaddingAccessors<T extends number | string> {
  vertical:   T
  horizontal: T

  top:    T
  right:  T
  bottom: T
  left:   T
}