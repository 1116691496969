export default class BrowserNotificationManager {

  public static get enabled() {
    return 'Notification' in window
  }

  public async show(options: NotificationOptions & {title: string}) {
    if (!BrowserNotificationManager.enabled) { return }

    const permission = await BrowserNotificationManager.ensurePermission()
    if (permission !== 'granted') { return }

    new Notification(options.title, options)
  }

  public static get permission() {
    if (!this.enabled) { return null }
    return Notification.permission
  }

  public static async requestPermission() {
    if (!this.enabled) { return 'denied' }
    return await Notification.requestPermission()
  }

  public static async ensurePermission() {
    if (!this.enabled) { return 'denied' }
    if (Notification.permission !== 'default') {
      return Notification.permission
    } else {
      return this.requestPermission()
    }
  }

}