import React from 'react'
import { useTimer } from 'react-timer'
import Color from 'color'
import { isPromise } from 'ytil'
import { memo } from '~/ui/component'
import { HBox, Label, LabelProps, Spinner, SVG, Tappable, TappableProps } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, presets, shadows, useStyling, useTheme } from '~/ui/styling'

export interface Props extends Omit<TappableProps, keyof React.HTMLAttributes<any> | 'noFeedback'> {
  icon?:      SVGName
  caption?:   string | null
  children?:  React.ReactNode
  iconSide?: 'left' | 'right'

  tiny?:    boolean
  small?:   boolean
  large?:   boolean
  padding?: 'none' | 'horizontal' | 'vertical' | 'both'
  align?:   'left' | 'center'
  round?:   boolean

  backgroundColor?: Color
  color?:           Color
  active?:          boolean
  dim?:             boolean

  onDoubleClick?: TappableProps['onDoubleClick']
  target?:        string
  tabIndex?:      number
  working?:       boolean

  labelProps?: Partial<LabelProps>
  classNames?: React.ClassNamesProp
}

const ClearButton = memo('ClearButton', (props: Props) => {

  const theme = useTheme()
  const $     = useStyles()

  const {
    icon,
    caption,
    children,
    tiny,
    small,
    large,
    iconSide  = 'left',
    padding   = 'vertical',
    align     = 'left',
    round     = false,
    dim       = false,
    showFocus = true,
    active    = false,

    onTap,
    working: props_working,
    enabled: props_enabled = true,

    backgroundColor,
    color: props_color,
    labelProps,
    classNames: props_classNames,

    ...rest
  } = props

  const {colors} = useStyling()
  const color = React.useMemo(() => {
    if (props_color != null) {
      return props_color
    }
    if (backgroundColor != null && colors.isDark(backgroundColor)) {
      return dim ? theme.inverse.fg.dim : theme.inverse.fg.normal
    }

    if (dim) {
      return theme.fg.dim
    } else {
      return theme.isDark ? theme.fg.normal : theme.semantic.primary
    }
  }, [props_color, backgroundColor, colors, dim, theme.inverse.fg.dim, theme.inverse.fg.normal, theme.fg.dim, theme.fg.normal, theme.isDark, theme.semantic.primary])

  //------
  // Tap

  const timer = useTimer()
  const [state_working, startWorking, stopWorking] = useBoolean()
  const [state_spinnerVisible, showSpinner, hideSpinner] = useBoolean()

  const working        = props_working ?? state_working
  const enabled        = props_enabled && !working
  const spinnerVisible = props_working ?? state_spinnerVisible

  const tap = React.useCallback((event: React.SyntheticEvent<any>) => {
    const retval = onTap?.(event)
    if (!isPromise(retval)) { return }

    startWorking()
    timer.setTimeout(showSpinner, 200)

    retval.finally(() => {
      if (timer.isDisposed) { return }
      timer.clearAll()
      stopWorking()
      hideSpinner()
    })
  }, [hideSpinner, onTap, showSpinner, startWorking, stopWorking, timer])

  //------
  // Rendering

  function render() {
    const classNames = [
      $.clearButton,
      {iconOnly: caption == null && icon != null},
      {tiny, small, large, normal: !small && !large && !tiny},
      {round, active, showFocus},
      `padding-${padding}`,
      {disabled: !enabled},
      props_classNames,
    ]

    const style: React.CSSProperties = {
      backgroundColor: backgroundColor?.string(),
    }

    return (
      <Tappable classNames={classNames} style={style} enabled={enabled} onTap={tap} {...rest} noFeedback>
        {renderContent()}
        {renderSpinner()}
      </Tappable>
    )
  }

  function renderContent() {
    const gap =
      tiny ? layout.padding.inline.xs :
      small ? layout.padding.inline.s :
      large ? layout.padding.inline.l :
      layout.padding.inline.m

    return (
      <HBox classNames={[$.content, {spinnerVisible}]} gap={gap} justify={align}>
        {iconSide === 'left' && renderIcon()}
        {renderCaption()}
        {iconSide === 'right' && renderIcon()}
      </HBox>
    )
  }

  function renderSpinner() {
    if (!spinnerVisible) { return }

    return (
      <HBox flex classNames={$.spinner}>
        <Spinner
          color={color?.alpha(0.6)}
          size={(tiny || small) ? 8 : 12}
        />
      </HBox>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    const size = tiny ? iconSize.tiny : small ? iconSize.small : large ? iconSize.large : iconSize.normal
    return <SVG name={icon} size={size} color={color}/>
  }

  function renderCaption() {
    if (caption != null) {
      return (
        <Label flex='shrink' caption tiny={tiny} small={small} h3={large} align={align} color={color} {...labelProps}>
          {props.caption}
        </Label>
      )
    } else {
      return children
    }
  }

  return render()

})

export default ClearButton

export const padding = layout.padding.inline.m
export const iconSize = {
  tiny:   {width: 8, height: 8},
  small:  {width: 12, height: 12},
  normal: {width: 14, height: 14},
  large:  {width: 16, height: 16},
}
export const height = {
  tiny:   iconSize.tiny.height + 2 * padding,
  small:  iconSize.small.height + 2 * padding,
  normal: iconSize.normal.height + 2 * padding,
  large:  iconSize.large.height + 2 * padding,
}
export const width = {
  tiny:   iconSize.tiny.height + 2 * padding,
  small:  iconSize.small.height + 2 * padding,
  normal: iconSize.normal.height + 2 * padding,
  large:  iconSize.large.height + 2 * padding,
}

const useStyles = createUseStyles(theme => ({
  clearButton: {
    cursor:   'pointer',
    position: 'relative',

    ...presets.overlayBefore(),

    '&:not(.round), &:not(.round)::before': {
      borderRadius: layout.radius.s,
    },
    '&.round.normal, &.round.normal::before': {
      borderRadius: height.normal / 2,
    },
    '&.round.small, &.round.small::before': {
      borderRadius: height.small / 2,
    },
    '&.round.tiny, &.round.tiny::before': {
      borderRadius: height.tiny / 2,
    },
    '&.round.large, &.round.large::before': {
      borderRadius: height.large / 2,
    },

    '&.showFocus:focus-visible, &.showFocus:focus': {
      outline:   'none',
      boxShadow: shadows.focus.bold(theme),
    },

    '&.active::before': {
      background: theme.inverse.bg.alt.alpha(0.2),
    },

    '&.disabled': {
      opacity: 0.6,
    },

    '&.padding-vertical, &.padding-both': {
      '&.normal': {
        minHeight: height.normal,
      },
      '&.small': {
        minHeight: height.small,
      },
      '&.tiny': {
        minHeight: height.tiny,
      },
      '&.large': {
        minHeight: height.large,
      },
    },

    '&.padding-vertical': {
      padding: [padding, 0],
      '&::before': {
        left:  -padding,
        right: -padding,
      },
    },
    '&.padding-horizontal': {
      padding: [0, padding],
      '&::before': {
        top:    -padding,
        bottom: -padding,
      },
    },
    '&.padding-both': {
      padding: padding,
    },

    '&.padding-none, &.padding-horizontal': {
      '&.normal': {
        minHeight: height.normal - 2 * padding,
      },
      '&.tiny': {
        minHeight: height.tiny - 2 * padding,
      },
      '&.small': {
        minHeight: height.small - 2 * padding,
      },
      '&.large': {
        minHeight: height.large - 2 * padding,
      },
    },

    '&.padding-none::before': {
      left:   -padding,
      right:  -padding,
      top:    -padding,
      bottom: -padding,
    },

    '&:not(.disabled):hover::before': {
      background: theme.bg.hover,
    },
    '&:not(.disabled):active::before': {
      background: theme.bg.active,
    },
  },

  content: {
    '&.spinnerVisible': {
      opacity: 0,
    },
  },

  spinner: {
    ...layout.overlay,
  },
}))