import { createAxios } from '~/axios'
import config from '~/config'

let __gcAPI = createAPI(null)

export default function gcAPI() {
  return __gcAPI
}

export function initAPI(authToken: string | null) {
  __gcAPI = createAPI(authToken)
}

export function createAPI(authToken: string | null) {
  return createAxios({
    baseURL:   config.api.baseURL,
    authToken: authToken,
  })
}