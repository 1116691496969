import React from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'
import { MenuPageItem } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, Notice, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  item:   MenuPageItem
  layout: 'grid' | 'list'
}

const MenuItemSignup = memo('MenuItemSignup', (props: Props) => {

  const {item, layout} = props

  const signup = item.extra.signup as MenuPageItemSignup

  const [t, i18n] = useTranslation('widgets')
  const translations = React.useMemo(() => merge(
    {},
    t('signup'),
    signup.translations?.[i18n.language] ?? signup.translations?.en,
  ), [i18n.language, signup.translations, t])

  const showCapacity = !signup.signedUp && signup.placesLeft != null

  const label = React.useMemo(() => {
    if (signup.signedUp) {
      return translations.signed_up_list
    } else if (showCapacity && signup.placesLeft != null) {
      return (
        signup.placesLeft === 0 ? translations.places_left_0 :
        signup.placesLeft === 1 ? translations.places_left_1 :
        translations.places_left_n
      ).replace(/\{\{\}\}/g, signup.placesLeft)
    } else if (signup.closed) {
      return translations.closed
    } else if (signup.maxSignups) {
      return translations.max_signups
    } else {
      return translations.not_signed_up_list
    }
  }, [showCapacity, signup, translations.places_left_0, translations.places_left_1, translations.places_left_n, translations.signed_up_list, translations.closed, translations.max_signups, translations.not_signed_up_list])

  const $ = useStyles()

  function render() {
    if (layout === 'list') {
      return (
        <HBox classNames='--no-override-fg'>
          {renderContent()}
        </HBox>
      )
    } else {
      return (
        <VBox classNames='--no-override-fg'>
          {renderContent()}
        </VBox>
      )
    }
  }

  function renderContent() {
    if (signup.signedUp) {
      return (
        <Notice classNames={[$.menuListItemSignupContent, 'signedUp', layout]}>
          <Label small caption align={layout === 'grid' ? 'center' : 'left'}>
            {label}
          </Label>
        </Notice>
      )
    } else {
      return (
        <VBox classNames={$.menuListItemSignupContent}>
          <Label small caption dim align={layout === 'grid' ? 'center' : 'left'}>
            {label}
          </Label>
        </VBox>
      )
    }
  }

  return render()

})

export default MenuItemSignup

type MenuPageItemSignup = {
  signedUp:     true
  translations: Record<string, Record<string, string>>
} | {
  signedUp:     false
  placesLeft:   number | null
  closed:       boolean
  maxSignups:   boolean
  translations: Record<string, Record<string, string>>
}

const useStyles = createUseStyles({
  menuListItemSignupContent: {
    padding: [layout.padding.inline.xs, 0],

    '&.signedUp': {
      padding: [layout.padding.inline.xs, layout.padding.inline.s],
    },

    '&.grid': {
      borderTopLeftRadius:  0,
      borderTopRightRadius: 0,
    },
  },
})