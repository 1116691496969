import React from 'react'
import { Center, Label, SVG, TextBlock, TextBlockProps, VBoxProps } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<TextBlockProps, 'children'> {
  title:   string | null
  detail?: string
  icon?:   SVGName

  padded?: boolean
  gap?:    VBoxProps['gap']
  flex?:   VBoxProps['flex']

  children?:   React.ReactNode
  classNames?: React.ClassNamesProp
}

export default function Empty(props: Props) {
  const {
    title,
    detail,
    icon,
    dim = true,
    gap = layout.padding.l,
    padded = true,
    flex,
    h1,
    h2,
    h3 = !h1 && !h2,
    children,
    classNames,
    ...rest
  } = props

  const $ = useStyles()

  return (
    <Center classNames={[$.empty, {padded}, classNames]} gap={gap} flex={flex}>
      {icon != null && (
        <SVG name={icon} size={iconSize} dim={dim}/>
      )}

      <Center gap={layout.padding.inline.s}>
        <Label h1={h1} h2={h2} h3={h3} dim={dim} align='center'>
          {title}
        </Label>

        {detail && (
          <TextBlock small dim={dim} align='center' {...rest}>
            {detail}
          </TextBlock>
        )}
      </Center>

      {children && (
        <Center classNames={$.extra}>
          {children}
        </Center>
      )}
    </Center>
  )
}

export const iconSize = {
  width:  48,
  height: 48,
}

const useStyles = createUseStyles({
  empty: {
    '&.padded': {
      ...layout.responsiveProp({padding: layout.padding.xl}),
    },
  },

  extra: {
    ...layout.responsiveProp({marginTop: layout.padding.m}),
  },
})