import { makeObservable, observable } from 'mobx'
import { Document, DocumentOptions } from 'mobx-document'
import socket from 'socket.io-react'
import { IndexPage, Page } from '~/models'
import IndexEndpoint from './IndexEndpoint'

export default class PageDocument extends Document<Page, string> {

  constructor(id: string, options: DocumentOptions<Page> = {}) {
    super(id, options)
    makeObservable(this)
  }

  private get slug() {
    return this.id
  }

  //------
  // Related info

  @observable
  public indexEndpoint: IndexEndpoint | null = null

  protected onDidChange() {
    const page = this.data
    if (page instanceof IndexPage && page.index != null) {
      this.indexEndpoint = new IndexEndpoint(page)
      this.indexEndpoint.storeIndex(page.index)
    } else {
      this.indexEndpoint = null
    }
  }

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('pages:show', this.slug)
    if (response.ok) {
      return {
        data: Page.deserialize(response.body.page) as Page,
        meta: {},
      }
    }
  }

}

