import { Database } from '~/lib/mobx-document'
import { QAndADocument, QuestionDocument } from './q-and-as'
import { register } from './support'

export class QAndAsStore {

  public readonly qAndAs = new Database<QAndADocument>({
    getID:         qAndA => qAndA.id,
    getDocument:   qAndA => new QAndADocument(qAndA.id, {initialData: qAndA}),
    emptyDocument: id    => new QAndADocument(id),
  })

  public readonly questions = new Database<QuestionDocument>({
    getID:         question => question.id,
    getDocument:   question => new QuestionDocument(question.id, {initialData: question}),
    emptyDocument: id       => new QuestionDocument(id),
  })

}

export default register(new QAndAsStore())