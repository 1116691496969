import Color from 'color'
import * as colors from '../colors'

import type BrandingGuide from './BrandingGuide'

export interface BrandedComponentSpec {
  background: BackgroundSpec | string
  border:     BorderSpec | string
  shape:      ComponentShape
  depth:      number

  [key: string]: any
}

export type BackgroundSpec = SolidBackgroundSpec | GradientBackgroundSpec | ImageBackgroundSpec

export interface BackgroundSpecCommon {
  theme: 'light' | 'dark' | 'inherit' | 'default'
}

export interface SolidBackgroundSpec extends BackgroundSpecCommon {
  type:  'solid'
  color: string | Color
}

export interface GradientBackgroundSpec extends BackgroundSpecCommon {
  type:   'gradient'
  colors: Array<string | Color>
  angle:  number
}

export interface ImageBackgroundSpec extends BackgroundSpecCommon {
  type:      'image'
  source:    ImageBackgroundSource
  objectFit: 'cover' | 'contain'
}

export type ImageBackgroundSource =
  | string   // URL
  | any      // App-only: a require() source. Not exposed in Mission Control, only used for static branding.

export type BorderSpec = SolidBorderSpec | GradientBorderSpec

export interface BorderSpecCommon {
  width:    number
  offset:   number
  position: 'inside' | 'outside'
}

export interface SolidBorderSpec extends BorderSpecCommon {
  type:  'solid'
  color: string | Color
}

export interface GradientBorderSpec extends BorderSpecCommon {
  type:   'gradient'
  colors: Array<string | Color>
  angle:  number
}

export const BorderSpec: {
  none:  () => BorderSpec
  solid: (color: string | Color, width?: number, offset?: number) => BorderSpec
} = {
  none: () => ({
    type:     'solid',
    width:    0,
    color:    colors.transparent,
    position: 'inside',
    offset:   0,
  }),

  solid: (color, width = 1, offset = 0) => ({
    type:     'solid',
    color:    color,
    width:    width,
    position: 'inside',
    offset:   offset,
  }),
}

export type ComponentShape = 'rectangle' | 'oval' | {rounded: number}

export interface BrandingGuideDelegate {
  rootGuide: BrandingGuide
}