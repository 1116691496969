import React from 'react'
import { observer } from 'mobx-react'
import { pseudoRandom } from 'ytil'
import { isPendingMessage, MessageListItem } from '~/models'
import { Avatar } from '~/ui/app/media'
import { HBox, Label } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'
import { useChat } from './ChatContext'

export interface Props {
  message: MessageListItem
}

const ChatMessageSender = observer((props: Props) => {

  const {message} = props

  const {chat, channel} = useChat()
  const controller      = isPendingMessage(message) ? channel : chat?.channelController(message.channel)

  const sender = React.useMemo(() => {
    if (isPendingMessage(message)) {
      return chat?.sender
    } else {
      return controller?.senders.get(message.from)
    }
  }, [controller?.senders, chat?.sender, message])

  const {colors}   = useStyling()
  const colorIndex = pseudoRandom(sender?.id ?? 0, 0, colors.palettes.default.length)

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s}>
        {sender?.photoURL != null && (
          <Avatar
            {...sender}
            source={sender.photoURL}
            size={avatarSize}
          />
        )}
        <Label tiny bold color={colors.palette('default', colorIndex)}>
          {sender?.firstName ?? '\u202F'}
        </Label>
      </HBox>
    )
  }


  return render()

})

export default ChatMessageSender

export const avatarSize = {
  width:  12,
  height: 12,
}