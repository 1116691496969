import './styles.css'
import React from 'react'
import { useLayout } from 'react-measure'
import ModalPortal from 'react-modal-portal'
import cn from 'classnames'
import { memo } from '~/ui/component'
import PopupLayout from './PopupLayout'
import { LayoutElement, PopupSide, Props } from './types'

const Popup = memo('Popup', (props: Props) => {

  const [side, setSide] = React.useState<PopupSide>()
  const [hasLayout, setHasLayout] = React.useState<boolean>(false)

  const {
    open,
    requestClose,
    targetClassNames,
    targetStyle,
    style,
    shimClassNames,
    zIndex,

    tag,
    shim                = false,
    trapFocus           = false,
    autoFocus           = false,
    closeOnClickOutside = true,
    shouldCloseOnClick,
    closeOnEscape       = true,

    transitionName,
    transitionDuration,

    align      = 'vertical',
    crossAlign = 'center',
    autoScroll = true,

    preferredSide   = 'far',
    matchTargetSize = false,
    onMouseDown,

    gap           = 0,
    screenPadding = 0,
    getTargetRect = null,
  } = props

  const ownTargetRef = React.useRef<LayoutElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const bodyRef      = React.useRef<HTMLDivElement>(null)

  const layoutKey = props.layoutKey
  const targetRef = props.targetRef ?? ownTargetRef

  const layout = React.useMemo(() => new PopupLayout(
    preferredSide,
    align,
    crossAlign,
    matchTargetSize,
    gap,
    screenPadding,
    targetRef,
    containerRef,
    bodyRef,
    getTargetRect,
    setHasLayout,
    setSide,
  ), [align, crossAlign, gap, getTargetRect, matchTargetSize, preferredSide, screenPadding, targetRef])

  React.useLayoutEffect(() => {
    layout.needsPosition()
    layout.positionIfNeeded()
  }, [layout, layoutKey])

  //------
  // Rendering

  function render() {
    return (
      <>
        {renderTarget()}

        <ModalPortal
          open={open}
          requestClose={requestClose}
          children={renderPopup()}

          tag={tag}
          shim={shim}
          shimClassNames={shimClassNames}
          closeOnClickOutside={closeOnClickOutside}
          closeOnEscape={closeOnEscape}
          shouldCloseOnClick={shouldCloseOnClick}
          trapFocus={trapFocus}
          autoFocus={autoFocus}

          transitionName={transitionName}
          transitionDuration={transitionDuration}

          mayClose={props.mayClose}
          onWillOpen={onWillOpen}
          onDidOpen={props.onDidOpen}
          onWillClose={props.onWillClose}
          onDidClose={onDidClose}
          zIndex={zIndex}
        />
      </>
    )
  }

  function renderTarget() {
    if (props.children == null) { return null }

    if (props.targetRef == null) {
      return (
        <div ref={ownTargetRef as any} className={cn(targetClassNames)} style={targetStyle}>
          {props.children}
        </div>
      )
    } else {
      return props.children
    }
  }

  function renderPopup() {
    const containerClassNames = cn([
      'ReactPopup--container',
      `ReactPopup--container-${align}`,
      `ReactPopup--container-${side}`,
    ])

    const bodyClassNames = cn([
      'ReactPopup--body',
      autoScroll && 'ReactPopup--bodyAutoScroll',
      props.classNames,
    ])

    const containerStyle: React.CSSProperties = {
      visibility: hasLayout ? undefined : 'hidden',
    }

    return (
      <div ref={containerRef} className={containerClassNames} style={containerStyle} onMouseDown={onMouseDown}>
        <div
          ref={bodyRef}
          className={bodyClassNames}
          style={style}
          children={props.renderContent?.()}
        />
      </div>
    )
  }

  const onBodySize = React.useCallback(() => {
    layout.positionIfNeeded()
  }, [layout])

  useLayout(bodyRef, {throttle: 500}, onBodySize)
  React.useLayoutEffect(onBodySize, [onBodySize, open])

  const onWillOpen = React.useCallback(() => {
    onBodySize()
    props.onWillOpen?.()
  }, [onBodySize, props])

  //------
  // Callbacks

  const onDidClose = React.useCallback(() => {
    props.onDidClose?.()
    setHasLayout(false)
  }, [props])

  return render()

})

export default Popup