import React from 'react'
import { appStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import OAuthButton from './OAuthButton'

const OtherLoginOptionList = observer('OtherLoginOptionList', () => {

  const oAuthProviders = appStore.app?.auth.oAuthProviders ?? []

  //------
  // Rendering

  function render() {
    if (oAuthProviders.length === 0) { return null }

    return (
      <VBox gap={layout.padding.s}>
        {oAuthProviders.map(provider => (
          <OAuthButton
            key={provider.name}
            provider={provider}
          />
        ))}
      </VBox>
    )
  }

  return render()

})

export default OtherLoginOptionList