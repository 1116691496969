import React from 'react'
import { useTranslation } from 'react-i18next'
import { Connection } from '~/models'
import { ConnectionsEndpoint, connectionsStore } from '~/stores'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'
import { observer } from '~/ui/component'
import { ClearButton, EmptyOrFetching, List } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ConnectionRequestBar from './ConnectionRequestBar'

export interface Props {
  endpoint: ConnectionsEndpoint
  requests: boolean
}

const ConnectionList = observer('ConnectionList', (props: Props) => {

  const {endpoint, requests} = props

  const scrollable = !requests
  const participantsPage = connectionsStore.participantsPage

  const [t] = useTranslation('connections')

  const fetchMore = React.useCallback(() => {
    endpoint.fetchNextPage()
  }, [endpoint])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List
        classNames={[$.connectionList, {scrollable}]}
        contentClassNames={[$.content, {scrollable}]}
        data={endpoint.data}
        keyExtractor={keyExtractor}
        renderItem={renderConnectionItem}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.inline.s}
        flex={scrollable}
        scrollable={scrollable}
        onEndReached={!requests ? fetchMore : undefined}
      />
    )
  }

  const keyExtractor = React.useCallback((connection: Connection) => {
    return connection.id
  }, [])

  const renderParticipantsPageLink = React.useCallback(() => {
    if (participantsPage == null) { return null }

    return (
      <ClearButton
        icon='participant'
        caption={participantsPage.title}
        href={`/-${participantsPage.path}`}
      />
    )
  }, [participantsPage])

  const renderEmpty = React.useCallback(() => {
    if (requests) { return null }

    return (
      <EmptyOrFetching
        flex
        status={endpoint.fetchStatus}
        children={renderParticipantsPageLink()}
        {...t('empty')}
      />
    )
  }, [endpoint.fetchStatus, renderParticipantsPageLink, requests, t])

  const renderConnectionItem = React.useCallback((connection: Connection) => {
    if (requests) {
      return (
        <ConnectionRequestBar
          connection={connection}
        />
      )
    } else {
      return (
        <ParticipantBar
          participant={connection.other}
        />
      )
    }
  }, [requests])

  return render()

})

export default ConnectionList

const useStyles = createUseStyles({
  connectionList: {
    '&.scrollable': {
      margin: -4,
    },
  },

  content: {
    '&.scrollable': {
      padding: 4,
    },
  },
})