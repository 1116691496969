import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Label, Markdown, Scroller, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import ChallengeStatusNotice from './ChallengeStatusNotice'
import ChallengeTaskList from './ChallengeTaskList'
import ChallengeTimeline from './ChallengeTimeline'

export interface ChallengeParams {
  id: string
}

export type Props = RouteComponentProps<ChallengeParams>

const ChallengeScreen = observer('ChallengeScreen', (props: Props) => {

  const {id}        = props.match.params
  const document    = challengesStore.challenges.document(id)
  const fetchStatus = document.fetchStatus
  const challenge   = document.data
  const state       = document.state

  const fetch = React.useCallback(() => {
    document?.fetch()
  }, [document])

  const title = challenge?.title
  const introduction = challenge?.introduction

  React.useEffect(fetch, [fetch])

  const [t] = useTranslation('challenges')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={title}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (challenge == null) {
      return renderEmpty()
    }
    else {
      return renderBody()
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        {...t('empty')}
        status={fetchStatus}
        flex
      />
    )
  }

  function renderBody() {
    return (
      <Scroller flex contentPadding={layout.padding.s} contentClassNames={$.scrollerContent}>
        <VBox gap={layout.padding.m} classNames={$.challenge}>
          {renderStatusNotice()}
          {renderTitle()}
          {renderIntroduction()}
          {renderTaskList()}
          {renderTimeline()}
        </VBox>
      </Scroller>
    )
  }

  function renderStatusNotice() {
    if (challenge == null) { return null }
    if (state == null) { return null }

    return (
      <ChallengeStatusNotice
        challenge={challenge}
        state={state}
      />
    )
  }

  function renderTitle() {
    return (
      <Label h2>
        {title}
      </Label>
    )
  }

  function renderIntroduction() {
    if (introduction == null) { return null }

    return (
      <Markdown>
        {introduction}
      </Markdown>
    )
  }

  function renderTaskList() {
    if (challenge == null) { return null }
    if (challenge.tasks.length === 0) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <Label bold>
          {t('tasks.caption')}
        </Label>
        <ChallengeTaskList
          challenge={challenge}
        />
      </VBox>
    )
  }

  function renderTimeline() {
    if (challenge == null || state == null) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <Label bold>
          {t('timeline.caption')}
        </Label>
        <ChallengeTimeline
          challengeID={id}
        />
      </VBox>
    )
  }

  return render()

})

export default ChallengeScreen

const useStyles = createUseStyles({
  scrollerContent: {
    alignItems: 'center',
  },

  challenge: {
    width: layout.contentWidth,
    maxWidth: '100%',
  },
})