import './DragLayer.css'
import React from 'react'
import { observer } from './component'
import DefaultGhost from './DefaultGhost'
import { DragDropMonitorContext } from './DragDropContext'
import DragDropMonitor from './DragDropMonitor'
import { DraggableItem } from './types'

export interface Props<It extends DraggableItem> {
  renderGhost?:   (item: It, monitor: DragDropMonitor<It>) => React.ReactNode
  animatedGhost?: boolean
  zIndex?:        number
}

const _DragLayer = <It extends DraggableItem>(props: Props<It>) => {

  const {renderGhost, animatedGhost = true, zIndex} = props
  const {monitor} = React.useContext(DragDropMonitorContext)

  const item         = monitor?.item
  const center       = monitor?.dragging ? monitor.center : null
  const ghostVisible = monitor?.ghostVisible
  const ghostLayout  = monitor?.ghostLayout

  const ghostStyle  = React.useMemo((): React.CSSProperties => {
    if (!ghostVisible || center == null || ghostLayout == null) {
      return {
        visibility: 'hidden',
      }
    }

    return {
      transform: `translate(${center.x}px, ${center.y}px)`,
    }
  }, [center, ghostLayout, ghostVisible])

  const ghostSizeStyle  = React.useMemo((): React.CSSProperties => {
    if (ghostLayout == null) { return {} }

    return {
      transform: `scale(${ghostLayout?.scale ?? 1})`,
      left:      -ghostLayout.offsetSize.width / 2,
      top:       -ghostLayout.offsetSize.height / 2,
      width:     ghostLayout.offsetSize.width,
      height:    ghostLayout.offsetSize.height,
    }
  }, [ghostLayout])

  //------
  // Rendering

  function render() {
    return (
      <div className='react-dnd--DragLayer' style={{zIndex}}>
        {item != null && monitor != null && (
          <div className='react-dnd--DragLayer--ghost' style={{...ghostStyle}}>
            <div className={`react-dnd--DragLayer--ghost-size ${animatedGhost ? 'animated' : ''}`} style={{...ghostSizeStyle}}>
              {renderGhost?.(item, monitor) ?? renderDefaultGhost()}
            </div>
          </div>
        )}
      </div>
    )
  }

  function renderDefaultGhost() {
    return (
      <DefaultGhost/>
    )
  }

  return render()

}

const DragLayer = observer('DragLayer', _DragLayer) as typeof _DragLayer
export default DragLayer