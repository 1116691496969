import { DateTime } from 'luxon'
import { CustomImage } from './CustomImage'
import { Model } from './Model'
import { datetime } from './serialization'

export class Notification extends Model {

  public title!:     string
  public body!:      string

  public url!:       string | null

  public avatar!:    NotificationAvatar | null
  public image!:     CustomImage | null
  public threadID!:  string | null

  @datetime()
  public seenAt!:    DateTime | null

  public get seen() {
    return this.seenAt != null
  }

}

export interface NotificationAvatar {
  firstName: string
  lastName:  string | null
  photoURL:  string | null
}
