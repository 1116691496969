import { DateTime } from 'luxon'
import { Feed } from '~/models'
import { Media } from './Media'
import { Model } from './Model'
import { datetime } from './serialization'

export class Post extends Model {

  public feed!:   Feed
  public author!: PostAuthor
  public root!:   string | null

  public body!:  string | null
  public media!: Media[]

  public get summary() {
    if (this.body == null) { return null }

    // All text before an optional first linebreak
    const match = this.body.match(/(.*)/)
    if (match == null) { return null }

    return `${match[1].substring(0, 50)}${this.body.length > 50 ? '...' : ''}`
  }

  @datetime()
  public publishedAt!: DateTime | null

  @datetime()
  public approvedAt!: DateTime | null

  @datetime()
  public redactedAt!: DateTime | null

  public edited!: boolean

  public commentCount!: number
  public reactions!: Record<string, number>
  public myReaction!: string | null

}

export interface PostAuthor {
  type:      'user' | 'participant'
  id:        string
  name:      string
  firstName: string
  photoURL:  string | null
}