import { Document } from 'mobx-document'
import socket from 'socket.io-react'
import { Connection, Participant } from '~/models'
import connectionsStore from '../connectionsStore'

export default class ParticipantDocument extends Document<Participant> {

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('participants:show', this.id)
    if (!response.ok) { return response }

    if (response.body.connection != null) {
      const connection = Connection.deserialize(response.body.connection)
      connectionsStore.connections.store(connection)
    }

    return {
      data: Participant.deserialize(response.body.participant),
      meta: {},
    }
  }

}