import React from 'react'
import { ButtonFeedback, FeedbackChoice, MessageFeedback } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from '../ChatContext'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & ButtonFeedback
}

const ButtonFeedbackBubble = memo('ButtonFeedbackBubble', (props: Props) => {

  const {messageID, feedback} = props

  const {channel} = useChat()

  //------
  // Callbacks

  const submit = React.useCallback((caption: string) => {
    if (messageID == null) { return }

    channel?.sendMessage({
      type:    'text',
      text:    caption,
      replyTo: messageID,
    })
  }, [channel, messageID])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackBubble messageID={messageID} feedback={feedback}>
        {renderButtons()}
      </FeedbackBubble>
    )
  }

  function renderButtons() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <HBox wrap gap={layout.padding.inline.m}>
          {feedback.buttons.map((button, index) => (
            <VBox key={index} flex='grow'>
              <ChoiceButton
                button={button}
                onTap={submit}
              />
            </VBox>
          ))}
        </HBox>
      </VBox>
    )
  }

  return render()

})

interface ChoiceButtonProps {
  button: FeedbackChoice
  onTap:  (caption: string) => any
}

const ChoiceButton = memo('ChoiceButton', (props: ChoiceButtonProps) => {

  const {button, onTap} = props
  const caption = button.caption

  const tap = React.useCallback(() => {
    onTap(caption)
  }, [onTap, caption])

  const $ = useStyles()
  const {guide} = useStyling()

  return (
    <BrandedComponent branding={guide.feedback.button} height={buttonHeight}>
      <Tappable classNames={$.button} onTap={tap}>
        <HBox flex justify='center'>
          <Label align='center' caption>
            {caption}
          </Label>
        </HBox>
      </Tappable>
    </BrandedComponent>
  )

})

export default ButtonFeedbackBubble

const buttonHeight    = 36

const useStyles = createUseStyles({
  button: {
    overflow:     'hidden',
    padding:      [layout.padding.inline.m, layout.padding.inline.l],
    height:       buttonHeight,
  },
})