import React from 'react'
import { useTranslation } from 'react-i18next'
import { QAndADocument, QuestionsParams } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  HBox,
  PopupMenu,
  PopupMenuItem,
  PushButton,
  SearchField,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  document:   QAndADocument
  requestAdd: (title: string) => any
}

const QAndAHeader = observer('QAndAHeader', (props: Props) => {

  const {document, requestAdd} = props
  const endpoint = document.questionsEndpoint

  const [t] = useTranslation('q-and-as')

  const search       = endpoint.param('search')
  const hasSearch    = search != null

  const setSearch = React.useCallback((search: string | null) => {
    if (search === endpoint.param('search')) { return }
    endpoint.setParams({search})
  }, [endpoint])

  //------
  // Sorting

  const sort = endpoint.param('sort')

  const setSort = React.useCallback((sort: QuestionsParams['sort']) => {
    if (sort === endpoint.param('sort')) { return }
    endpoint.clear()
    endpoint.fetchWithParams({sort})
  }, [endpoint])

  const sortItems = React.useMemo((): PopupMenuItem<QuestionsParams['sort']>[] => [
    {value: 'date', caption: t('sort.date.long'), checked: sort === 'date'},
    {value: 'votes', caption: t('sort.votes.long'), checked: sort === 'votes'},
  ], [sort, t])

  //------
  // Actions

  const addQuestion = React.useCallback(() => {
    requestAdd(endpoint.param('search') ?? '')
  }, [endpoint, requestAdd])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.inline.l}>
        <HBox classNames={$.qAndAHeader} gap={layout.padding.inline.l}>
          <VBox flex>
            <SearchField
              placeholder={t('search.placeholder')}
              search={search}
              onSearch={setSearch}
              debounce={200}
            />
          </VBox>
          <PopupMenu items={sortItems} onValueSelect={setSort}>
            {toggle => (
              <ClearButton
                icon='sort-descending'
                caption={t(`sort.${sort}.short`)}
                onTap={toggle}
                small
              />
            )}
          </PopupMenu>
        </HBox>
        {renderAskQuestion()}
      </VBox>
    )
  }


  function renderAskQuestion() {
    return (
      <VBox classNames={$.askQuestion}>
        <PushButton
          icon='plus'
          caption={hasSearch ? t('create.button') : t('create.placeholder')}
          enabled={hasSearch}
          onTap={addQuestion}
        />
      </VBox>
    )
  }

  return render()

})

export default QAndAHeader

const useStyles = createUseStyles({
  qAndAHeader: {
    padding: [layout.padding.inline.m, layout.padding.inline.l, 0],
  },

  askQuestion: {
    padding: [0, layout.padding.inline.l],
  },
})