import React from 'react'
import { observer } from 'mobx-react'
import { flexStyle, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { FormContext } from './FormContext'
import FormErrors from './FormErrors'
import FormFieldHeader from './FormFieldHeader'
import { useFormField } from './hooks/useFormField'
import { FieldChangeCallback, FormError } from './types'

export interface Props<T> {
  name:         string
  caption?:     string | null
  instruction?: string | null
  required?:    boolean

  renderAsLabel?: boolean

  align?:   VBoxProps['align']
  flex?:    VBoxProps['flex']
  children: (bind: BindProps<T>) => React.ReactNode
}

export interface BindProps<T> {
  value:        T
  onChange:     FieldChangeCallback<T>
  invalid:      boolean
  enabled?:     boolean
  errors:       FormError[]
  form:         FormContext<any>
}

const _FormField = <T extends {} = any>(props: Props<T>) => {

  const {name, caption, instruction, required, align, flex, renderAsLabel = true, children} = props
  const [value, onChange, errors, form] = useFormField<T>(name)

  const bind = React.useMemo(() => ({
    value,
    onChange,
    invalid:     errors.length > 0,
    enabled:     form.submitting ? false : undefined,
    errors,
    form,
  }), [errors, form, onChange, value])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const el = (
      <VBox flex={flex} gap={layout.padding.inline.m}>
        {renderHeader()}
        <VBox flex={flex} gap={layout.padding.inline.s}>
          {renderBody()}
          <VBox align={align}>
            {renderErrors()}
          </VBox>
        </VBox>
      </VBox>
    )

    if (renderAsLabel) {
      return (
        <label classNames={$.formFieldLabel} style={flexStyle(flex)}>
          {el}
        </label>
      )
    } else {
      return el
    }
  }

  function renderHeader() {
    if (caption == null) { return null }

    return (
      <VBox align={align}>
        <FormFieldHeader
          caption={caption}
          instruction={instruction}
          required={required}
        />
      </VBox>
    )
  }

  function renderBody() {
    return (
      <VBox>
        {children(bind)}
      </VBox>
    )
  }

  function renderErrors() {
    if (errors.length === 0) { return null }

    return (
      <FormErrors errors={errors}/>
    )
  }

  return render()

}

const FormField = observer(_FormField) as typeof _FormField
export default FormField

const useStyles = createUseStyles({
  formFieldLabel: {
    ...layout.flex.column,
  },
})