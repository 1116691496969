import React from 'react'
import { createMemoryHistory } from 'history'
import Logger from 'logger'
import { select } from 'ytil'
import { augmentHistory, History } from '~/client/history'
import { assignGlobal } from '~/global'

const logger = new Logger('History')
const HistoryContext = React.createContext<History>(augmentHistory(createMemoryHistory()))

export interface HistoryProviderProps {
  history: History
  children?: React.ReactNode
}

export const HistoryProvider = (props: HistoryProviderProps) => {

  const history = React.useMemo(
    () => augmentHistory(props.history),
    [props.history],
  )

  React.useEffect(() => {

    if (process.env.NODE_ENV === 'development') {
      assignGlobal({appHistory: history})

      let disposer: () => void = () => null

      history.startTrace = function startTrace() {
        disposer = history.listen((location, action) => {
          const icon = select(action.toLowerCase(), {
            replace: '↧',
            push:    '↦',
            pop:     '↤',
            default: '?',
          })
          logger.info(`${icon} ${location.pathname}${location.search}${location.hash}`, [history.stack])
        })
      }

      history.stopTrace = function stopTrace() {
        disposer()
        disposer = () => null
      }
    }
  }, [history])

  return (
    <HistoryContext.Provider value={history}>
      {props.children}
    </HistoryContext.Provider>
  )

}

export function useHistory() {
  return React.useContext(HistoryContext)
}