import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { ClearButton, Dimple, HBox, Label, PushButton, TextField, VBox } from '~/ui/components'
import { Form, FormField, SubmitResult } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import CommentFormModel from './CommentFormModel'
import CommentView from './CommentView'

export interface Props {
  model: CommentFormModel
  requestCancel?: () => any
  afterSubmit?:   (result: SubmitResult) => any
}

const CommentForm = observer('CommentForm', (props: Props) => {

  const {model, requestCancel, afterSubmit} = props

  const [t] = useTranslation('news')

  const clearReplyTo = React.useCallback(() => {
    model.replyTo(null)
  }, [model])

  const bodyFieldRef = React.useRef<HTMLInputElement>(null)
  const replyToCommentID   = model.replyToComment?.id ?? null
  const prevReplyToComment = usePrevious(replyToCommentID)

  React.useEffect(() => {
    if (replyToCommentID !== prevReplyToComment) {
      bodyFieldRef.current?.focus()
    }
  }, [prevReplyToComment, replyToCommentID])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Form model={model} resetOnSuccess fieldGap={layout.padding.inline.s} afterSubmit={afterSubmit}>
        {renderReplyTo()}
        {renderFields()}
      </Form>
    )
  }

  function renderReplyTo() {
    if (model.replyToComment == null) { return null }

    return (
      <VBox classNames={$.replyTo}>
        <HBox classNames={$.replyToHeader} gap={layout.padding.inline.m}>
          <Label flex dim italic small>
            {t('comment_form.replying_to')}
          </Label>
          <ClearButton
            icon='cross'
            onTap={clearReplyTo}
            padding='none'
            tiny
          />
        </HBox>
        <Dimple horizontal/>
        <VBox classNames={$.replyToBody} >
          <CommentView
            comment={model.replyToComment}
            interactive={false}
          />
        </VBox>
      </VBox>
    )
  }

  function renderFields() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex>
          <FormField name='body'>
            {bind => (
              <TextField
                {...bind}
                placeholder={t('comment_form.placeholder')}
                inputStyle='dark'
                ref={bodyFieldRef}
                selectOnFocus
                autoFocus
                small
              />
            )}
          </FormField>
        </VBox>
        <HBox>
          <PushButton
            caption={t('comment_form.add')}
            submit
            small
          />
          {requestCancel != null && (
            <ClearButton
              icon='cross'
              onTap={requestCancel}
              padding='both'
            />
          )}
        </HBox>
      </HBox>
    )
  }

  return render()

})

export default CommentForm

const useStyles = createUseStyles(theme => ({
  replyTo: {
    background:    theme.bg.subtle,
    borderRadius:  layout.radius.s,
  },

  replyToHeader: {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
  },

  replyToBody: {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
  },
}))