import { SnapAction, SnapGuide } from './types'

export function snapToRects(rect: Rect, rects: Rect[], threshold: number): SnapAction | null {
  const snappedX = snapToRectsDim([rect.x, rect.width], rects.map(rect => [rect.x, rect.width]), threshold)
  const snappedY = snapToRectsDim([rect.y, rect.height], rects.map(rect => [rect.y, rect.height]), threshold)

  const snappedCenter = {
    x: snappedX == null ? rect.x + rect.width / 2 : snappedX[1] === 'near' ? snappedX[0] + rect.width / 2 : snappedX[0] - rect.width / 2,
    y: snappedY == null ? rect.y + rect.height / 2 : snappedY[1] === 'near' ? snappedY[0] + rect.height / 2 : snappedY[0] - rect.height / 2,
  }

  const guides: SnapGuide[] = []
  if (snappedX != null) {
    guides.push({orientation: 'vertical', base: snappedX[0]})
  }
  if (snappedY != null) {
    guides.push({orientation: 'horizontal', base: snappedY[0]})
  }

  return {
    center: snappedCenter,
    guides: guides,
  }
}

function snapToRectsDim(current: [number, number], rects: Array<[number, number]>, threshold: number): [number, 'near' | 'far'] | null {
  let nearest: number | null = null
  let nearestDiff: number | null = null
  let nearestSide: 'near' | 'far' = 'near'
  for (const rect of rects) {
    // Near side
    if (nearestDiff == null || Math.abs(rect[0] - current[0]) < nearestDiff) {
      nearest     = rect[0]
      nearestDiff = Math.abs(rect[0] - current[0])
      nearestSide = 'near'
    }
    if (nearestDiff == null || Math.abs((rect[0] + rect[1]) - current[0]) < nearestDiff) {
      nearest     = rect[0] + rect[1]
      nearestDiff = Math.abs(rect[0] + rect[1] - current[0])
      nearestSide = 'near'
    }

    // Far side
    if (nearestDiff == null || Math.abs(rect[0] - (current[0] + current[1])) < nearestDiff) {
      nearest     = rect[0]
      nearestDiff = Math.abs(rect[0] - (current[0] + current[1]))
      nearestSide = 'far'
    }
    if (nearestDiff == null || Math.abs((rect[0] + rect[1]) - (current[0] + current[1])) < nearestDiff) {
      nearest     = rect[0] + rect[1]
      nearestDiff = Math.abs(rect[0] + rect[1] - (current[0] + current[1]))
      nearestSide = 'far'
    }
  }

  if (nearest != null && (nearestDiff != null && nearestDiff <= threshold)) {
    return [nearest, nearestSide]
  } else {
    return null
  }
}