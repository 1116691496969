export interface WidgetProps<P> {
  params: P
}

const registry = new Map<string, React.ComponentType<WidgetProps<any>>>()

export function getWidgetComponent(widget: string) {
  for (const [name, Component] of registry) {
    if (name === widget) {
      return Component
    }
  }

  return null
}

export function register<P>(widget: string, target: React.ComponentType<WidgetProps<P>>) {
  registry.set(widget, target)
}