import { action, when } from 'mobx'
import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryCategory } from '~/models'
import bewizrStore from '../bewizrStore'
import { SearchParams } from '../search'
import BeWizrLibraryCategoryDocument from './BeWizrLibraryCategoryDocument'

export default class BeWizrLibraryCategoriesEndpoint extends Endpoint<BeWizrLibraryCategoryDocument, BeWirzLibraryCategoriesEndpointParams> {

  public async fetchMore() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({
      offset: nextOffset,
      append: true,
    })
  }

  @action
  protected async performFetch(
    params:  BeWirzLibraryCategoriesEndpointParams,
    options: CollectionFetchOptions,
  ) {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const offset = options.offset ?? 0
    const limit  = options.limit ?? 20

    const {query, topic} = params

    const response = await api.get('library_category/', {
      params: {
        q:      query,
        topic:  topic,
        limit:  limit,
        offset: offset,
      },
    })
    if (response.status !== 200) { return null }

    const {items, count: total} = response.data
    const nextOffset = offset + limit > total ? null : offset + limit

    const data = items.map(
      (raw: any) => BeWizrLibraryCategory.deserialize(raw),
    )

    return {
      data: data,
      meta: {total, nextOffset},
    }
  }
}

export interface BeWirzLibraryCategoriesEndpointParams extends SearchParams {
  topic: string
}