import React from 'react'
import { FileRejection } from 'react-dropzone'
import { acceptedFeedbackMediaMimeTypes, FeedbackMediaType, Media } from '~/models'
import { mediaStore, StoreMediaOptions } from '~/stores'
import { component } from '~/ui/component'
import Uploader, { Props as UploaderProps, UploaderState } from '~/ui/components/Uploader'
import { createUseStyles, layout } from '~/ui/styling'
import processMediaUpload from './processMediaUpload'

export interface Props extends Omit<UploaderProps<Media>, 'accept' | 'upload'> {
  types?:             FeedbackMediaType[]
  accept?:            string | string[]
  filename?:          string
  storeMediaOptions?: StoreMediaOptions
  onUploadComplete?:  (media: Media) => any
  objectFit?:         React.CSSProperties['objectFit']
  uploaderRef?:       React.Ref<Uploader>

  previewURL?: string
  previewAlt?: string | null
}

export interface UploadData {
  contentType: string
  base64:      string
  prefix?:     string
  filename?:   string
}

export type MediaUploaderState = UploaderState

export type RejectReason = 'invalid-type' | 'too-large'

const MediaUploader = component('MediaUploader', (props: Props) => {

  const {
    types = FeedbackMediaType.all,
    accept: props_accept,
    filename,
    storeMediaOptions,
    objectFit = 'contain',
    previewURL: props_previewURL,
    previewAlt = null,
    uploaderRef,
    ...rest
  } = props

  const accept = React.useMemo(() => {
    return props_accept ?? acceptedFeedbackMediaMimeTypes(types)
  }, [props_accept, types])

  //------
  // Uploading

  const upload = React.useCallback(async (file: File) => {
    const result = await mediaStore.storeMedia(filename ?? file.name, file, storeMediaOptions)
    return processMediaUpload(result)
  }, [filename, storeMediaOptions])

  const handleDropRejected = React.useCallback((rejections: FileRejection[]) => {
    const error = rejections[0]?.errors?.[0]
    if (error.code === 'file-too-large') {
      processMediaUpload({status: 'invalid', reason: 'too-large'})
    }
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Uploader
        {...rest}
        accept={accept}
        ref={uploaderRef}
        upload={upload}
        renderPreview={renderPreview}
        showIdleHint={props_previewURL == null}
        onDropRejected={handleDropRejected}
      />
    )
  }

  function renderPreview(uploadPreviewURLs: string[] | null) {
    const previewURL = uploadPreviewURLs?.[0] ?? props_previewURL
    if (previewURL == null) { return null }

    return (
      <img
        classNames={[$.preview, objectFit]}
        src={previewURL}
        alt={previewAlt ?? undefined}
      />
    )
  }

  return render()

})

export default MediaUploader

const useStyles = createUseStyles(theme => ({
  preview: {
    ...layout.overlay,
    width:  '100%',
    height: '100%',

    borderRadius: layout.radius.s,

    '&.contain': {objectFit: 'contain'},
    '&.cover':   {objectFit: 'cover'},
  },
}))