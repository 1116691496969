import { Model } from './Model'
import { Participant } from './Participant'
import { QAndA } from './QAndA'
import { Ref } from './types'

export class Question extends Model {

  public qAndA!:       Ref<QAndA>
  public participant!: Ref<Participant>
  public title!:       string
  public body!:        string
  public voteCount!:   number
  public voted!:       boolean

}