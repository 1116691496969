import React from 'react'
import { focusableSelectors } from 'react-autofocus'
import Color from 'color'
import { forwardRef } from '~/ui/component'
import { BrandedComponent, childFlex, HBox, Tappable, VBox, VBoxProps } from '~/ui/components'
import {
  animation,
  createUseStyles,
  layout,
  PanelBranding,
  shadows,
  ThemeProvider,
  ThemeProviderProps,
  useStyling,
} from '~/ui/styling'
import { panelBorderRadius } from './layout'
import PanelHeader from './PanelHeader'

export interface Props extends VBoxProps {
  onTap?:          () => any
  onSecondaryTap?: () => any
  onDoubleClick?:  () => any
  href?:           string | null
  interactive?:    boolean

  depth?:           number
  semi?:            boolean
  bandColor?:       Color | null
  backgroundColor?: Color

  header?:      React.ReactNode
  caption?:     React.ReactNode
  smallHeader?: boolean

  contentClassNames?: React.ClassNamesProp
  branding?:          PanelBranding
}

const Panel = forwardRef('Panel', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {guide} = useStyling()
  const {
    onTap,
    onSecondaryTap,
    onDoubleClick,
    href,
    interactive = onTap != null || href != null,
    depth,
    semi = !interactive,
    header,
    caption,
    smallHeader = false,
    bandColor,
    backgroundColor,
    classNames,
    contentClassNames,
    branding = guide.web.panel,
    style: props_style,
    ...columnProps
  } = props

  const contentFlex = childFlex(columnProps.flex)

  //------
  // Rendering

  const $ = useStyles({depth, semi, minHeight: branding.minHeight, bandWidth: branding.bandWidth})

  const style = React.useMemo((): React.CSSProperties => ({
    backgroundColor: backgroundColor?.string(),
    ...props_style,
  }), [backgroundColor, props_style])

  const themeProps = React.useMemo((): ThemeProviderProps => ({
    contrast: backgroundColor,
  }), [backgroundColor])

  function render() {
    return (
      <BrandedComponent
        classNames={[$.panel, {interactive, semi}, classNames]}
        style={style}
        branding={branding}
        variant={{semi}}
        height={branding.minHeight}
        flex={columnProps.flex}
        children={renderContent()}
        onDoubleClick={onDoubleClick}
        ref={ref}
      />
    )
  }

  function renderContent() {
    return (
      <ThemeProvider {...themeProps}>
        <VBox flex={contentFlex} classNames={$.contentContainer}>
          {renderButton()}
          <VBox classNames={$.content} flex={contentFlex}>
            {renderHeader()}
            <HBox flex={contentFlex} align='stretch'>
              {bandColor != null && (
                <div classNames={$.band}>
                  <div style={{background: bandColor.string()}}/>
                </div>
              )}
              <VBox flex>
                <VBox
                  classNames={contentClassNames}
                  flex={contentFlex}
                  {...columnProps}
                />
              </VBox>
            </HBox>
          </VBox>
        </VBox>
      </ThemeProvider>
    )
  }

  function renderHeader() {
    if (header != null) {
      return header
    } else if (caption != null) {
      return (
        <PanelHeader
          caption={caption}
          small={smallHeader}
        />
      )
    } else {
      return null
    }
  }

  function renderButton() {
    if (onTap == null && onSecondaryTap == null && href == null) {
      return null
    }

    return (
      <Tappable
        classNames={$.button}
        onTap={onTap}
        onSecondaryTap={onSecondaryTap}
        href={href}
        ref={ref}
        showFocus
        noFeedback
      />
    )

  }

  return render()

})

export default Panel


const useStyles = createUseStyles(theme => {
  const borderRadius = panelBorderRadius(theme)

  return {
    panel: (opts: {depth: number | undefined, minHeight: number}) => ({
      borderRadius: borderRadius,
      minHeight:    opts.minHeight,
      position:     'relative',

      ...opts.depth != null && {
        boxShadow: shadows.depth(opts.depth),
      },

      '&.interactive': {
        '&::after': {
          content:       '""',
          pointerEvents: 'none',
          borderRadius:  borderRadius,
          ...layout.overlay,

          willChange:   'background-color',
          transition:   animation.transitions.short('background-color'),
        },

        willChange:   'box-shadow',
        transition:   animation.transitions.short('box-shadow'),

        '&.active, &:hover': {
          boxShadow: shadows.depth((opts.depth ?? theme.guide.web.panel.resolve('depth') ?? 1) + 1),
        },
        '&:hover::after': {
          backgroundColor: theme.colors.bg.light.hover,
        },
        '&:active::after': {
          backgroundColor: theme.colors.bg.light.active,
        },
      },
    }),

    contentContainer: {
      position: 'relative',

      '$panel.interactive &': {
        pointerEvents: 'none',
        [focusableSelectors().map(it => `& ${it}`).join(', ')]: {
          pointerEvents: 'auto',
        },
      },
    },

    content: {
      position: 'relative',
    },

    button: {
      ...layout.overlay,
      pointerEvents: 'auto',
      borderRadius:  panelBorderRadius(theme),
    },

    band: (opts: {bandWidth: number}) => ({
      position: 'relative',
      overflow: 'hidden',
      width:    opts.bandWidth,

      '& > div': {
        position:     'absolute',
        top:          0,
        bottom:       0,
        left:         0,
        width:        borderRadius,
        borderRadius: [borderRadius, 0, 0, borderRadius],
      },
    }),
  }
})