import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Database } from 'mobx-document'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { arrayEquals } from 'ytil'
import { Feed, Media, Post } from '~/models'
import { SubmitResult } from '~/ui/form'
import searchStore from '../searchStore'
import { submitResultForResponse } from '../support/responses'
import PostDocument from './PostDocument'
import PostsEndpoint, { PostsMeta } from './PostsEndpoint'

export default class NewsService extends OnDemandService {

  constructor(
    socket: Socket,
    private readonly options: NewsServiceOptions = {},
  ) {
    super(socket)
    makeObservable(this)
  }

  public readonly posts: Database<PostDocument> = new Database<PostDocument>({
    getDocument:   post => new PostDocument(this.socket, this.posts, post.id, {initialData: post}),
    emptyDocument: id   => new PostDocument(this.socket, this.posts, id),
    getID:         post => post.id,
  })

  @observable
  public feeds: Feed[] = []

  @computed
  public get openFeeds() {
    return this.feeds.filter(it => it.open)
  }

  public readonly allPosts: PostsEndpoint = new PostsEndpoint(
    this.socket,
    this.posts,
    this.options.initialFilteredFeedIDs,
  )

  @computed
  private get filteredFeedIDs() {
    return this.allPosts.param('feedIDs') ?? []
  }

  @observable
  private incomingPosts: Post[] = []

  @computed
  private get filteredIncomingPosts() {
    if (this.filteredFeedIDs.length === 0) { return this.incomingPosts }
    return this.incomingPosts.filter(it => this.filteredFeedIDs.includes(it.feed.id))
  }

  @computed
  public get newPosts(): Post[] {
    return this.filteredIncomingPosts.filter(it => !this.allPosts.ids.includes(it.id))
  }

  public async start() {
    const feedIDs = this.filteredFeedIDs.length === 0 ? undefined : this.filteredFeedIDs
    return await super.startWithEvent('news:start', {feedIDs})
  }

  protected onStarted = (response: StartSuccess<InitialData>) => {
    this.socket.prefix = `news:${this.uid}:`
    this.socket.addEventListener('feeds:changed', this.onFeedsChanged)
    this.socket.addEventListener('feeds:updated', this.onFeedsChanged)
    this.socket.addEventListener('posts:published', this.onPostsPublished)
    this.socket.addEventListener('posts:updated', this.onPostsUpdated)
    this.socket.addEventListener('posts:deleted', this.onPostsDeleted)

    const {data, meta} = response.data
    this.feeds = data.feeds.map(it => Feed.deserialize(it))

    const enriched = this.allPosts.enrichPosts(data.posts, meta)
    this.allPosts.replace(enriched.data, enriched.meta)

    searchStore.registerEndpoint('posts', new PostsEndpoint(
      this.socket,
      this.posts,
    ))
  }

  @action
  private onFeedsChanged = (payload: {data: AnyObject[], meta: {}}) => {
    const feeds       = payload.data.map(it => Feed.deserialize(it))
    const prevFeedIDs = this.feeds.map(it => it.id)
    const nextFeedIDs = feeds.map(it => it.id)

    this.feeds = feeds

    if (!arrayEquals(prevFeedIDs, nextFeedIDs)) {
      this.allPosts.resetAndFetch()
    }
  }

  @action
  private onPostsPublished = (payload: {data: AnyObject[], meta: PostsMeta}) => {
    const all      = this.allPosts.enrichPosts(payload.data, payload.meta).data
    const posts    = all.filter(it => it.root == null)
    const comments = all.filter(it => it.root != null)

    // Sort posts and comments ascending by publishedAt as they're inserted in a reversed order.
    posts.sort((a, b) => (a.publishedAt?.valueOf() ?? 0) - (b.publishedAt?.valueOf() ?? 0))
    comments.sort((a, b) => (a.publishedAt?.valueOf() ?? 0) - (b.publishedAt?.valueOf() ?? 0))

    for (const post of posts) {
      const existingIndex = this.incomingPosts.findIndex(it => it.id === post.id)
      if (existingIndex >= 0) {
        this.incomingPosts.splice(existingIndex, 1, post)
      } else {
        this.incomingPosts.splice(0, 0, post)
      }
    }

    // Insert (or update if existing) the comments into the right post comments endpoint.
    for (const comment of comments) {
      const document = this.posts.document(comment.root!)
      if (document.comments.ids.includes(comment.id)) {
        this.posts.store(comment)
      } else {
        document.comments.insert(comment, 0)
      }
    }
  }

  @action
  private onPostsUpdated = (payload: {data: AnyObject[], meta: PostsMeta}) => {
    const posts = this.allPosts.enrichPosts(payload.data, payload.meta).data
    for (const post of posts) {
      this.posts.store(post)
    }
  }

  @action
  private onPostsDeleted = (payload: {data: string[], meta: {}}) => {
    this.allPosts.remove(payload.data)
  }

  //------
  // New posts

  @action
  public showNewPosts() {
    const filteredIncomingPostIDs = this.filteredIncomingPosts.map(it => it.id)

    // Insert the new posts at the beginning of the allPosts endpoint.
    this.allPosts.replace([
      ...this.newPosts,
      ...this.allPosts.data,
    ], {
      nextPageToken: null,
      ...this.allPosts.meta,
      total: (this.allPosts.meta?.total ?? 0) + this.newPosts.length,
    })

    // Remove all (filtered) incoming posts from the incoming posts list.
    this.incomingPosts = this.incomingPosts.filter(it => !filteredIncomingPostIDs.includes(it.id))
  }

  public async addPost(feedID: string, data: AnyObject, notify: boolean = false): Promise<SubmitResult | undefined> {
    const response = await this.socket.send('posts:add', feedID, data, notify)
    if (!response.ok) { return submitResultForResponse(response) }

    const {
      data: postRaw,
      meta: {author, feed: feedRaw, media: mediaRaw},
    } = response.body

    const feed  = Feed.deserialize(feedRaw)
    const media = mediaRaw.map((it: any) => Media.deserialize(it))
    const post  = Post.deserialize({...postRaw, feed, author, media})

    this.insertPost(post)

    return submitResultForResponse(response)
  }

  public async updatePost(postID: string, data: AnyObject): Promise<SubmitResult | undefined> {
    const response = await this.socket.send('post:update', postID, data)
    if (!response.ok) { return submitResultForResponse(response) }

    const {
      data: postRaw,
      meta: {author, feed: feedRaw, media: mediaRaw},
    } = response.body

    const feed  = Feed.deserialize(feedRaw)
    const media = mediaRaw.map((it: any) => Media.deserialize(it))
    const post  = Post.deserialize({...postRaw, feed, author, media})

    runInAction(() => {
      this.posts.store(post)
    })

    return submitResultForResponse(response)
  }

  public async removePost(postID: string): Promise<boolean> {
    const response = await this.socket.send('post:remove', postID)
    if (!response.ok) { return false }

    this.posts.delete(postID)
    this.allPosts.remove([postID])
    runInAction(() => {
      this.incomingPosts = this.incomingPosts.filter(it => it.id !== postID)
    })
    return true
  }

  @action
  private insertPost(post: Post) {
    if (this.filteredFeedIDs.length === 0 || this.filteredFeedIDs.includes(post.feed.id)) {
      this.allPosts.insert(post, 0)
    }
  }

  @action
  public onStop() {
    searchStore.unregisterEndpoint('posts')
  }

}

export interface NewsServiceOptions {
  initialFilteredFeedIDs?: string[]
}

export interface InitialData {
  data: {
    feeds: AnyObject[]
    posts: AnyObject[]
  }
  meta: PostsMeta
}