import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { ChatService, chatStore, profileStore } from '~/stores'
import { registerChatMessageNotification } from '~/ui/app/notifications/ChatMessageNotification'
import { observer } from '~/ui/component'

export interface ChatContext {
  service: ChatService | null
}

export const ChatContext = React.createContext<ChatContext>({
  service: null,
})

export interface ChatContainerProps {
  children: React.ReactNode
}

export const ChatContainer = observer('ChatContainer', (props: ChatContainerProps) => {

  const {children} = props

  const {participantID} = profileStore

  const service = React.useMemo(() => {
    if (participantID == null) { return null }

    return chatStore.chatService(participantID)
  }, [participantID])

  React.useEffect(() => {
    if (service == null) { return }
    return registerChatMessageNotification(service)
  }, [service])

  useOnDemandService(service)

  const context = React.useMemo((): ChatContext => ({
    service,
  }), [service])

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  )

})

export function useChatService() {
  const {service} = React.useContext(ChatContext)
  return service
}
