import React from 'react'
import { useTranslation } from 'react-i18next'
import { feedbackChoiceValue, Participant, ProfileDatum } from '~/models'
import { memo } from '~/ui/component'
import {
  Center,
  ClearButton,
  Dimple,
  HBox,
  Label,
  List,
  ListItem,
  Panel,
  SVG,
  Tooltip,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import ProfileDatumForm from './ProfileDatumForm'
import { iconForProfileItemVisibility } from './util'

export interface Props {
  participant: Participant
  editable?:   boolean
}

const ProfileTable = memo('ProfileTable', (props: Props) => {

  const {participant, editable = false} = props
  const {profileData = []} = participant

  const [t] = useTranslation('participants')

  //------
  // Item editing

  const [editingDatum, setEditingDatum] = React.useState<ProfileDatum | null>(null)
  const [datumFormOpen, openDatumForm, closeDatumForm] = useBoolean()

  const editDatum = React.useCallback((datum: ProfileDatum) => {
    setEditingDatum(datum)
    openDatumForm()
  }, [openDatumForm])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (profileData.length === 0) { return null }

    return (
      <VBox gap={layout.padding.inline.s}>
        <Panel classNames={$.profileTable} semi={false}>
          <List
            data={profileData}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            flex={false}
          />
        </Panel>

        {editingDatum != null && (
          <ProfileDatumForm
            open={datumFormOpen}
            requestClose={closeDatumForm}
            datum={editingDatum}
          />
        )}
      </VBox>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <Center classNames={$.empty}>
        <Label dim italic>
          {t('profile.empty')}
        </Label>
      </Center>
    )
  }, [$.empty, t])

  function renderItem(datum: ProfileDatum, index: number) {
    return (
      <VBox key={datum.item.name}>
        {index > 0 && <Dimple horizontal/>}
        <ProfileDatumRow
          datum={datum}
          requestEdit={editable ? editDatum : undefined}
        />
      </VBox>
    )
  }

  return render()

})

export default ProfileTable

interface ProfileDatumHBoxProps {
  datum:        ProfileDatum
  requestEdit?: (datum: ProfileDatum) => any
}

const ProfileDatumRow = memo('ProfileDatumRow', (props: ProfileDatumHBoxProps) => {

  const {datum, requestEdit} = props

  const [t] = useTranslation('participants')

  const {item, value} = datum
  const isMultiline = item.type === 'text' && item.multiline

  const label = item.label

  const editDatum = React.useCallback(() => {
    requestEdit?.(datum)
  }, [datum, requestEdit])

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <ListItem
        classNames={$.profileItemRow}
        caption={renderLabel()}
        accessory={isMultiline ? renderActions() : renderValueAndActions()}
        children={isMultiline ? renderValue(true) : null}
      />
    )
  }

  function renderLabel() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Label caption>
          {label}
        </Label>
        <Tooltip renderTooltip={renderLegend}>
          <SVG
            name={iconForProfileItemVisibility(item.visibility)}
            size={layout.icon.xs}
            dim
          />
        </Tooltip>
      </HBox>
    )
  }

  function renderValueAndActions() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderValue(false)}
        {renderActions()}
      </HBox>
    )
  }

  function renderValue(multiline: boolean) {
    return (
      <VBox classNames={multiline  && $.multilineDetail}>
        <Label bold={!multiline} color={colors.semantic.secondary}>
          {getLabel()}
        </Label>
      </VBox>
    )
  }

  function getLabel() {
    if (value == null) {
      return null
    } else if (item.type === 'choice') {
      const choice = item.choices.find(choice => feedbackChoiceValue(choice) === value)
      return choice == null ? value : choice.caption
    } else {
      return value.toString()
    }
  }

  function renderActions() {
    if (requestEdit == null) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='pencil'
          small
          onTap={editDatum}
        />
      </HBox>
    )
  }

  function renderLegend() {
    return (
      <VBox classNames={$.legend} gap={layout.padding.inline.s} align='center'>
        <Label small>
          {t('profile.legend_preamble')}
        </Label>
        <HBox gap={layout.padding.inline.m}>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='eye' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.public')}
            </Label>
          </HBox>
          <Dimple vertical/>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='handshake' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.connections')}
            </Label>
          </HBox>
          <Dimple vertical/>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='eye-off' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.private')}
            </Label>
          </HBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  profileTable: {
  },

  empty: {
    height:  layout.barHeight.m,
    padding: layout.padding.inline.m,
  },

  profileItemRow: {
    minHeight: layout.barHeight.m,
  },

  multilineDetail: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  legend: {
  },
})