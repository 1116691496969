import React from 'react'
import { Sender } from '~/models'
import { Avatar } from '~/ui/app/media'
import { avatarSize } from '~/ui/app/participants/ParticipantBar'
import { memo } from '~/ui/component'
import { ListBar, VBox } from '~/ui/components'

export interface Props {
  sender: Sender
}

const SendersRow = memo('SendersRow', (props: Props) => {

  const {sender} = props

  const href = React.useMemo(() => {
    if (sender.type !== 'participant') { return null }
    return `/participants/-/${sender.id}`
  }, [sender])

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={renderAvatar()}
        caption={`${sender.firstName} ${sender.lastName ?? ''}`}
        detail={sender.headline}
        href={href ?? undefined}
      />
    )
  }

  function renderAvatar() {
    return (
      <VBox>
        <Avatar
          source={sender.photoURL}
          firstName={sender.firstName}
          size={avatarSize}
        />
      </VBox>
    )
  }

  return render()

})

export default SendersRow