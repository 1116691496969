import { Document } from 'mobx-document'
import socket from 'socket.io-react'
import { Connection } from '~/models'

export default class ConnectionDocument extends Document<Connection> {

  //------
  // Fetching

  protected async performFetch() {
    const response = await socket.fetch('connections:show', this.id)
    if (!response.ok) { return response }

    const pack = response.body
    const data = pack.data
    const meta = pack.meta

    return {
      data: Connection.deserialize(data) as Connection,
      meta: meta,
    }
  }

}