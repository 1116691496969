import React from 'react'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, SVG, Tappable } from '~/ui/components'
import {
  CheckBoxBranding,
  createUseStyles,
  layout,
  shadows,
  useStyling,
  useTheme,
} from '~/ui/styling'

export interface Props {
  checked:   boolean
  onChange?: (checked: boolean, event: React.SyntheticEvent<any>) => void

  enabled?:    boolean
  classNames?: React.ClassNamesProp
  small?:      boolean

  branding?: CheckBoxBranding
}

const RadioButton = memo('RadioButton', (props: Props) => {

  const {guide} = useStyling()

  const {
    checked,
    onChange,
    small    = false,
    enabled  = true,
    branding = guide.radioButton,
  } = props

  const $ = useStyles()

  //------
  // Rendering

  function render() {
    const classNames = [
      $.radioButton,
      {checked, enabled, small},
      props.classNames,
    ]

    const height = small ? size.small.height : size.normal.height

    return (
      <BrandedComponent branding={branding} variant={{checked, enabled}} classNames={classNames} height={height}>
        {onChange != null ? (
          <Tappable flex enabled={enabled && onChange != null} onTap={toggle}>
            {renderRadioButton()}
          </Tappable>
        ) : (
          renderRadioButton()
        )}
      </BrandedComponent>

    )
  }

  function renderRadioButton() {
    return (
      <Center flex>
        {checked && <RadioButtonCheck {...props}/>}
        <input
          type='radio'
          classNames={$.hideRadioButton}
          checked={checked !== false}
          onChange={change}
        />
      </Center>
    )
  }

  const toggle = React.useCallback((event: React.SyntheticEvent) => {
    onChange?.(!checked, event)
  }, [checked, onChange])

  const change = React.useCallback((event: React.SyntheticEvent) => {
    const input = event.currentTarget as HTMLInputElement
    onChange?.(input.checked, event)
  }, [onChange])

  return render()

})

const RadioButtonCheck = memo('RadioButtonCheck', (props: Props) => {

  const {small} = props

  const theme = useTheme()
  const color = !theme.isDark ? theme.semantic.primary : theme.fg.normal

  function render() {
    return (
      <SVG
        name='check'
        size={small ? checkSize.small : checkSize.normal}
        color={color}
      />
    )
  }

  return render()

})

export default RadioButton

export const size = {
  normal: {
    width:  20,
    height: 20,
  },
  small: {
    width:  16,
    height: 16,
  },
}

const checkSize = {
  normal: {
    width:  12,
    height: 12,
  },
  small: {
    width:  10,
    height: 10,
  },
}

const useStyles = createUseStyles(theme => ({
  radioButton: {
    ...layout.flex.center,

    position: 'relative',
    overflow: 'hidden',

    '&:focus': {
      outline:     'none',
      borderColor: 'transparent',
      boxShadow:   shadows.focus.bold(theme),
    },

    '&:not(.enabled)': {
      opacity: 0.4,
      cursor: 'default',

      '&:focus': {boxShadow: 'none'},
    },

    '&:not(.small)': {
      ...size.normal,
    },

    '&.small': {
      ...size.small,
    },
  },

  hideRadioButton: {
    position: 'absolute',
    width:    0,
    height:   0,
    top:      -20,
    left:     -20,
  },

}))