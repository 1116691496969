import React from 'react'
import { useTranslation } from 'react-i18next'
import { Answer, AnswerResult, ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { VBox, Dimple, Label, Panel } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { renderAnswerOutcome } from '../answers'

export interface Props {
  task:           ChallengeTask
  answer:         Answer
  results:        AnswerResult[]
  correctAnswers: any[]
}

const ChallengeTaskAnswerOutcome = memo('ChallengeTaskAnswerOutcome', (props: Props) => {

  const {task, answer, results, correctAnswers} = props

  const theme    = useTheme()

  const [t] = useTranslation('challenges')
  const $   = useStyles()

  //------
  // Render util

  const renderCaption = React.useCallback((caption: string, highlight: boolean = false) => {
    return (
      <VBox classNames={$.caption}>
        <Label small bold align='center' color={highlight ? theme.fg.highlight : theme.fg.dim}>
          {caption}
        </Label>
      </VBox>
    )
  }, [$.caption, theme.fg.dim, theme.fg.highlight])

  const renderLine = React.useCallback(() => {
    return <Dimple horizontal/>
  }, [])

  const renderUtil = React.useMemo((): AnswerOutcomeRenderUtil => ({
    renderCaption,
    renderLine,
  }), [renderCaption, renderLine])

  //------
  // Rendering

  function render() {
    return (
      <Panel semi={false}>
        {renderBody()}
        {answer.notes != null && renderLine()}
        {answer.notes != null && renderNotes()}
      </Panel>
    )
  }

  function renderBody() {
    if (task.question == null) { return null }

    return (
      <VBox>
        {renderAnswerOutcome({
          question:       task.question,
          answer:         answer,
          result:         results.length > 0 ? results[0] : null,
          correctAnswers: correctAnswers,
          renderUtil:     renderUtil,
        })}
      </VBox>
    )
  }

  function renderNotes() {
    return (
      <>
        {renderCaption(t('task.notes'))}
        <VBox classNames={$.notes} gap={layout.padding.inline.l}>
          <Label>
            {answer.notes}
          </Label>
        </VBox>
      </>
    )
  }

  return render()

})

export interface AnswerOutcomeRenderUtil {
  renderCaption: (caption: string, highlight?: boolean) => React.ReactNode
  renderLine:    () => React.ReactNode
}

export default ChallengeTaskAnswerOutcome

const useStyles = createUseStyles({
  caption: {
    padding:         layout.padding.inline.m,
    paddingVertical: layout.padding.inline.s,
  },

  notes: {
    padding: layout.padding.inline.m,
  },
})