import React from 'react'
import { Message, MessageListItem } from '~/models'
import ImageBubble from './ImageBubble'
import NoticeBubble from './NoticeBubble'
import RatingBubble from './RatingBubble'
import TextBubble from './TextBubble'
import VideoBubble from './VideoBubble'
import VimeoBubble from './VimeoBubble'
import WidgetBubble from './WidgetBubble'
import YouTubeBubble from './YouTubeBubble'

export default function renderBubble(message: MessageListItem, replyTo: Message | null, inlineReply: boolean = false) {
  switch (message.type) {
    case 'text':
      if (RatingBubble.shouldUse(message.text!, replyTo)) {
        return (
          <RatingBubble
            message={message as MessageListItem & {type: 'text'}}
            feedback={replyTo.feedback}
            inlineReply={inlineReply ? replyTo : null}
          />
        )
      } else if (YouTubeBubble.shouldUse(message)) {
        return (
          <YouTubeBubble
            message={message as MessageListItem & {type: 'text'}}
            inlineReply={inlineReply ? replyTo : null}
          />
        )
      } else if (VimeoBubble.shouldUse(message)) {
        return (
          <VimeoBubble
            message={message as MessageListItem & {type: 'text'}}
            inlineReply={inlineReply ? replyTo : null}
          />
        )
      } else {
        return (
          <TextBubble
            message={message as MessageListItem & {type: 'text'}}
            inlineReply={inlineReply ? replyTo : null}
          />
        )
      }
    case 'notice':
      return (
        <NoticeBubble
          message={message as MessageListItem & {type: 'notice'}}
        />
      )
    case 'image':
      return (
        <ImageBubble
          message={message as MessageListItem & {type: 'image'}}
          inlineReply={inlineReply ? replyTo : null}
        />
      )
    case 'video':
      return (
        <VideoBubble
          message={message as MessageListItem & {type: 'video'}}
          inlineReply={inlineReply ? replyTo : null}
        />
      )
    case 'widget':
      return (
        <WidgetBubble
          message={message as MessageListItem & {type: 'widget'}}
          inlineReply={inlineReply ? replyTo : null}
        />
      )
  }
}