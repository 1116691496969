import Document from './Document'

//------
// Database

export interface DatabaseOptions<D extends Document<any, any, any, any>> {
  getID:         (item: DocumentData<D>) => D['id']
  getDocument:   (item: DocumentData<D>) => D
  emptyDocument: (id: D['id'])  => D
}

//------
// Endpoint

export interface EndpointOptions<D extends Document<any, any, any, any>, M = any> {
  initialData?: Array<DocumentData<D>>
  initialMeta?: M | null
}

export interface CollectionFetchOptions<P extends Record<string, any> = any> {
  offset?: number
  limit?:  number | null

  extraParams?: P

  append?: boolean
}

export interface AppendOptions {
  ignoreIfExists?: boolean
}

//------
// Document

export interface DocumentOptions<T = AnyObject, M = AnyObject> {
  initialData?: T | null
  initialMeta?: M | null
}

export type DocumentData<D extends Document<any, any, any, any>> =
  D extends Document<infer T, any, any, any> ? T : any

export interface FetchOptions {
  force?: boolean
}

//------
// Optimistic updates

export interface OptimisticUpdateSpec<D, M = any> {
  prepare:  (data: D) => D
  update:   () => Promise<DocumentFetchResponse<D, M>>
}

//------
// Responses

export type FetchStatus = 'idle' | 'fetching' | 'done' | Error

export type CollectionFetchResponse<T = AnyObject, M = any> = CollectionFetchResponseSuccess<T, M> | FetchResponseError
export type DocumentFetchResponse<T = AnyObject, M = any> = DocumentFetchResponseSuccess<T, M> | FetchResponseError

export interface CollectionFetchResponseSuccess<T, M = any> {
  data:  T[]
  meta?: M
}

export interface DocumentFetchResponseSuccess<T, M = any> {
  data:  T
  meta?: M
}

export interface FetchResponseError {
  error: Error
}

function isSuccessResponse<T, M = any>(response: CollectionFetchResponse<T, M>): response is CollectionFetchResponseSuccess<T, M>
function isSuccessResponse<T, M = any>(response: DocumentFetchResponse<T, M>): response is DocumentFetchResponseSuccess<T, M>
function isSuccessResponse(response: any) {
  return (response as any).data != null
}

function isErrorResponse(response: CollectionFetchResponse<any, any> | DocumentFetchResponse<any, any>): response is FetchResponseError {
  return (response as any).error != null
}

function isNotFoundResponse(response: CollectionFetchResponse<any, any> | DocumentFetchResponse<any, any>): response is FetchResponseError {
  return (response as any).error != null && (response as any).error?.status === 404
}

export {isSuccessResponse, isErrorResponse, isNotFoundResponse}