import React from 'react'
import { Link } from '~/models'
import { observer } from '~/ui/component'
import { Tappable, TappableProps } from '~/ui/components'

export interface Props extends Omit<TappableProps, 'href'> {
  link?: Link | null
}

const LinkTappable = observer('LinkTappable', (props: Props) => {

  const {
    link,
    ...rest
  } = props

  return <Tappable href={link?.href} target={link?.target} {...rest}/>
})

export default LinkTappable