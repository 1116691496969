import { BeWizrModel } from './BeWizrModel'

export class BeWizrLibraryTopic extends BeWizrModel {

  title!: string

  slug!: string

  itemCount!: number

  links!: {
    image: string | null
  }

}

export class BeWizrLibraryCategory extends BeWizrLibraryTopic {

  topic!: BeWizrLibraryTopic

  protected afterDeserialize() {
    this.id    = this.slug
    this.topic = BeWizrLibraryTopic.deserialize({
      title: this.raw.topicTitle,
      slug:  this.raw.topicSlug,
    })
  }
}

export class BeWizrLibraryItem extends BeWizrModel {

  type!: 'course' | 'learning_track'

  title!: string

  descriptionHtml!: string | null

  links!: {
    image: string | null
  }

  // Courses
  progress: number | null = null

  slug!: string

  // Learning tracks
  itemCount: number = 0

}