import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSize } from '~/lib/react-measure'
import { Challenge } from '~/models'
import { ChallengesEndpoint } from '~/stores/challenges'
import { observer } from '~/ui/component'
import { Chip, ClearButton, Grid, HBox, Label, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import ChallengeTile from './ChallengeTile'

export interface Props {
  endpoint:   ChallengesEndpoint

  caption?:     React.ReactNode
  detailLabel?: string
  detailHref?:  string
}

const ChallengesTileGrid = observer('ChallengesTileGrid', (props: Props) => {

  const [t] = useTranslation('tile_grid')

  const {
    caption,
    detailLabel = t('more'),
    detailHref,
    endpoint,
  } = props

  const total = endpoint.meta?.total ?? 0
  const data      = endpoint.data

  const gridRef = React.useRef<HTMLDivElement>(null)
  const [gridSize, setGridSize] = React.useState<Size>({width: 0, height: 0})
  useSize(gridRef, setGridSize)

  const columnWidth = React.useMemo(() => {
    if (gridSize.width === 0) { return 1 }
    const availableWidth = gridSize.width - gridPadding * 2 + itemGap
    const columns        = Math.floor(availableWidth / (minTileWidth + itemGap))

    return availableWidth / columns - itemGap
  }, [gridSize.width])

  const minTileHeight = columnWidth * 1.3

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderHeader()}
        {renderGrid()}
      </VBox>
    )
  }

  function renderHeader() {
    if (caption == null && detailHref == null) { return null }

    return (
      <HBox>
        <HBox flex gap={layout.padding.inline.m}>
          {renderCaption()}
          {renderTotal()}
        </HBox>
        <HBox>
          {renderDetailLink()}
        </HBox>
      </HBox>
    )
  }

  function renderCaption() {
    if (!isReactText(caption)) { return caption }

    return (
      <Label h2>
        {caption}
      </Label>
    )
  }

  function renderTotal() {
    if (total == null || total === 0) { return null }

    return (
      <Chip>{total}</Chip>
    )
  }

  function renderDetailLink() {
    if (detailHref == null) { return null }

    return (
      <ClearButton
        caption={detailLabel}
        icon='arrow-right'
        iconSide='right'
        href={detailHref}
        small
      />
    )
  }

  function renderGrid() {
    return (
      <VBox gap={itemGap}>
        <Grid ref={gridRef} columns={`repeat(auto-fill, minmax(${minTileWidth}px, 1fr))`} gap={itemGap} padding={gridPadding}>
          {data.map(renderTile)}
        </Grid>
      </VBox>
    )
  }

  function renderTile(challenge: Challenge) {
    return (
      <VBox key={challenge.id} flex>
        <ChallengeTile
          challenge={challenge}
          minHeight={minTileHeight}
        />
      </VBox>
    )
  }

  return render()

})

export default ChallengesTileGrid

export const gridPadding  = 0
export const itemGap      = layout.padding.inline.l
export const minTileWidth = 160