export function cleanTextValue(value: string | null | undefined, trim: boolean = false): string | null {
  if (value == null) { return null }

  if (trim) {
    value = value.replace(/^[\r\n\s\u{200B}]+|[\r\n\s\u{200B}]+$/gu, '')
  }

  return value === '' ? null : value
}

export function stringContains(string: string, word: string, unicode: boolean = true) {
  return slugify(string, unicode).includes(slugify(word, unicode))
}

export function slugify(text: string, unicode: boolean = false): string {
  text = text.toLocaleLowerCase()
  text = text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '') // Remove accents
  if (unicode) {
    text = text.replace(/[^\p{L}\p{N}]+/gu, '-')
  } else {
    text = text.replace(/[^a-z0-9]+/gi, '-')
  }

  return text.replace(/^-|-$/g, '')
}

export function stringHash(str: string) {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr
    // eslint-disable-next-line no-bitwise
    hash >>>= 0
  }

  return hash
}

export function truncate(text: string, length: number, options: TruncateOptions) {
  const {
    omission = ' … ',
    anchor   = 'start',
  } = options

  if (text.length <= length) { return text }

  const lengthPre =
    anchor === 'start' ? 0 :
    anchor === 'end' ? length :
    Math.ceil(length / 2 - omission.length / 2)

  const lengthPost =
    anchor === 'start' ? 0 :
    anchor === 'end' ? length :
    Math.floor(length / 2 - omission.length / 2)

  return text.slice(0, lengthPre) + omission + text.slice(-lengthPost)
}

export interface TruncateOptions {
  omission?: string
  anchor?:   'start' | 'middle' | 'end'
}