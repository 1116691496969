import React from 'react'
import { Notification } from '~/models'
import { notificationsStore } from '~/stores'
import { memo } from '~/ui/component'
import { Label, ListItem } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import { Avatar } from '../media'
import { imageSize, isNotificationAvatar } from './NotificationPanel'

export interface Props {
  notification: Notification
}

const NotificationListItem = memo('NotificationListItem', (props: Props) => {

  const {notification} = props

  const markAsSeen = React.useCallback(
    () => notificationsStore.markAsSeen(notification.id),
    [notification.id],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        image={renderAvatar()}
        caption={notification.title ?? notificationsStore.defaultTitle}
        detail={renderBody()}
        href={notification.url}
        classNames={[$.NotificationListItem, {unseen: !notification.seen}]}
        onTap={markAsSeen}
        spacious
      />
    )
  }

  function renderBody() {
    return notification.seen ? notification.body : (
      <Label
        small
        children={notification.body}
      />
    )
  }

  function renderAvatar() {
    if (!isNotificationAvatar(notification.avatar)) {
      return notification.avatar
    }

    return (
      <Avatar
        source={notification.avatar.photoURL}
        firstName={notification.avatar.firstName}
        lastName={notification.avatar.lastName}
        size={imageSize}
      />
    )
  }

  return render()

})

export default NotificationListItem

const useStyles = createUseStyles(theme => ({
  NotificationListItem: {
    '&.unseen': {
      backgroundColor: theme.semantic.primary.alpha(0.15),
    },
  },
}))