import React from 'react'
import { useSize } from 'react-measure'
import { Media } from '~/models'
import { memo } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import PostMediaThumbnail from './PostMediaThumbnail'

export interface Props {
  media: Media[]
  requestRemoveMedia: (media: Media) => any
}

const PostFormMediaGallery = memo('PostFormMediaGallery', (props: Props) => {

  const {media, requestRemoveMedia} = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [size, setSize] = React.useState<Size>({width: 0, height: 0})
  useSize(containerRef, setSize)

  const mediaThumbnailSize = React.useMemo(() => {
    return {
      width:  size.width / 2 - layout.padding.inline.s,
      height: size.width / 2 / 16 * 9,
    }
  }, [size.width])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox wrap gap={layout.padding.inline.s} ref={containerRef}>
        {media.map(media => (
          <VBox key={media.id} classNames={$.mediaThumbnail}>
            <PostMediaThumbnail
              media={media}
              size={mediaThumbnailSize}
              requestRemove={requestRemoveMedia}
            />
          </VBox>
        ))}
      </HBox>
    )
  }

  return render()

})

export default PostFormMediaGallery

const useStyles = createUseStyles(theme => ({
  mediaThumbnail: {
    outline:      [1, 'solid', theme.semantic.secondary],
    borderRadius: layout.radius.xs,
    overflow:     'hidden',
  },
}))