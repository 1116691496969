import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import Toast from 'react-toast'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import ForgotPinForm from './ForgotPinForm'
import ForgotPinFormModel from './ForgotPinFormModel'

export interface Props extends RouteComponentProps {}

const ForgotPinScreen = observer('ForgotPinScreen', (props: Props) => {

  const {history} = props

  const [t] = useTranslation('auth')

  const formModel = React.useMemo(() => {
    const search = new URLSearchParams(document.location.search)
    return new ForgotPinFormModel(search.get('email'))
  }, [])

  const goBack = React.useCallback(() => {
    history.replace('/login')
  }, [history])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      Toast.show({
        type: 'success',
        ...t('forgot_pin.success'),
      })
      goBack()
    }
  }, [goBack, t])

  //------
  // Rendering

  function render() {
    return (
      <ForgotPinForm
        model={formModel}
        afterSubmit={afterSubmit}
        onBackTap={goBack}
      />
    )
  }

  return render()

})

export default ForgotPinScreen