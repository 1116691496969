import React from 'react'
import { HBox, InfoIcon, Label, SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { layout, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  icon?:        SVGName
  caption?:     React.ReactNode
  instruction?: string | null
  required?:    boolean
}

const FormFieldHeader = (props: Props) => {

  const {icon, required} = props
  const caption     = props.caption
  const instruction = props.instruction

  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderCaption()}
        {renderInstruction()}
      </HBox>
    )
  }

  function renderCaption() {
    if (caption == null) { return null }

    return (
      <HBox flex gap={layout.padding.inline.s}>
        {icon && <SVG name={icon} size={layout.icon.xs} dim/>}
        {isReactText(caption) ? (
          <Label flex='shrink' caption dim truncate={false}>
            {caption}
          </Label>
        ) : (
          caption
        )}
        {required && (
          <Label small color={colors.semantic.secondary}>
            *
          </Label>
        )}
      </HBox>
    )
  }

  function renderInstruction() {
    if (instruction == null) { return null }

    return (
      <InfoIcon
        renderTooltip={instruction}
      />
    )
  }

  return render()

}

export default FormFieldHeader