import React from 'react'
import { useTranslation } from 'react-i18next'
import { connectionsStore } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import ConnectionList from './ConnectionList'
import ConnectionRequestsHeader from './ConnectionRequestsHeader'
import ConnectionsHeader from './ConnectionsHeader'

const ConnectionsScreen = observer('ConnectionsScreen', () => {

  const endpoint = connectionsStore.confirmed

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  React.useEffect(fetch, [fetch])

  const [t] = useTranslation('connections')

  //------
  // Rendering

  function render() {
    return (
      <VBox flex padding={layout.padding.m}>
        <AppLayoutConfig
          title={t('title')}
          fetchStatus={endpoint.fetchStatus}
        />
        <ConnectionRequestsHeader/>
        <ConnectionsHeader/>
        <ConnectionList
          endpoint={endpoint}
          requests={false}
        />
      </VBox>
    )
  }

  return render()

})

export default ConnectionsScreen