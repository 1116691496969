import React from 'react'
import { NotificationComponentProps, registerNotification } from 'react-notifications'
import { Notification as NotificationPayload } from '~/models'
import { isInternalHref } from '~/navigation'
import { notificationsStore } from '~/stores'
import { memo } from '~/ui/component'
import NotificationPanel from './NotificationPanel'

export interface Props {
  notification: NotificationPayload
  href:         string | null
}

const AppNotification = memo('MessageNotification', (props: Props & NotificationComponentProps) => {

  const {notification, href, hide} = props

  //------
  // Rendering

  function render() {
    return (
      <NotificationPanel
        title={notification.title}
        body={notification.body}
        avatar={notification.avatar}
        accessory={notification.image}
        href={href}
        onTap={hide}
      />
    )
  }

  return render()

})

export default AppNotification

registerNotification<Props>(AppNotification, show => {
  return notificationsStore.addListener(notification => {
    const href = notification.url
    if (href != null && isViewingHref(href)) { return }

    const key = notification.threadID ?? notification.id
    show(key, {notification, href})
  })
})

function isViewingHref(href: string) {
  if (!isInternalHref(href)) { return false }

  const url = new URL(document.location.href)
  return url.pathname === href
}