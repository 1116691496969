import { RegistrationManager } from './registration'
import { register } from './support'

export class RegistrationStore {

  private cachedManager?: RegistrationManager

  public manager(token: string | null, origin: string | null) {
    if (this.cachedManager != null) {
      if (token === this.cachedManager.token && origin === this.cachedManager.origin) {
        return this.cachedManager
      }
    }

    this.cachedManager = new RegistrationManager(token, origin)
    return this.cachedManager
  }

}

const registrationStore = new RegistrationStore()
register(registrationStore)
export default registrationStore