import React from 'react'

export function useObjectURL(binary: Blob): string
export function useObjectURL(binary: undefined | null): null
export function useObjectURL(binary: Blob | undefined | null): string | null
export function useObjectURL(binary: Blob | undefined | null): string | null {
  const url = React.useMemo(() => {
    if (binary == null) { return null }
    return URL.createObjectURL(binary)
  }, [binary])

  React.useEffect(() => {
    return () => {
      if (url != null) {
        URL.revokeObjectURL(url)
      }
    }
  }, [url])

  return url
}