export function resourceNameForItem(item: any): string | null {
  if ('name' in item) {
    return 'participants'
  } else if ('author' in item) {
    return 'posts'
  } else if ('link' in item) {
    return 'menu-items'
  } else if ('courseTitle' in item) {
    return 'bewizr-course-items'
  } else if ('links' in item) {
    return 'bewizr-courses'
  }

  return null
}