import React from 'react'
import { getClientPoint } from 'react-dnd'
import { clamp, range } from 'lodash'
import { memo } from '~/ui/component'
import { HBox, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  value:     number | null
  onChange?: (value: number) => any

  max:        number
  halfStars?: boolean

  enabled?:  boolean
}

const StarsSlider = memo('StarsSlider', (props: Props) => {

  const {
    value,
    onChange,
    max       = 5,
    halfStars = false,
    enabled   = true,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)

  const setValue = React.useCallback((newValue: number) => {
    if (newValue === value) { return }

    onChange?.(newValue)
  }, [onChange, value])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.starsSlider} {...enabled ? handlers : {}} align='center'>
        <HBox ref={containerRef}>
          {range(1, max + 1).map(renderStarView)}
        </HBox>
      </VBox>
    )
  }

  function renderStarView(stars: number) {
    const active =
      value == null ? false :
      value >= stars ? true :
      value >= stars - 0.5 ? 'half' :
      false

    return (
      <StarView
        key={stars}
        active={active}
        animated={enabled}
      />
    )

  }

  //-------
  // Touch & measurements

  const setValueForClientX = React.useCallback((clientX: number) => {
    const container = containerRef.current
    if (container == null) { return }

    const containerRect = container.getBoundingClientRect()
    const fraction      = (clientX - containerRect.left) / containerRect.width

    const round = halfStars ? 2 : 1
    const value = Math.round((fraction * max) * round) / round
    const min   = halfStars ? 0.5 : 1

    setValue(clamp(value, min, max))
  }, [halfStars, max, setValue])

  const move = React.useCallback((event: MouseEvent | TouchEvent) => {
    event.preventDefault()

    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    setValueForClientX(clientPoint.x)
  }, [setValueForClientX])

  const end = React.useCallback((event: MouseEvent | TouchEvent) => {
    event.preventDefault()

    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    setValueForClientX(clientPoint.x)

    window.removeEventListener('mousemove', move)
    window.removeEventListener('touchmove', move)
    window.removeEventListener('mouseup', end)
    window.removeEventListener('touchend', end)
  }, [move, setValueForClientX])

  const start = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault()

    window.addEventListener('mousemove', move)
    window.addEventListener('touchmove', move)
    window.addEventListener('mouseup', end)
    window.addEventListener('touchend', end)
  }, [end, move])

  const handlers = React.useMemo(() => ({
    onMouseDown:  start,
    onTouchStart: start,
  }), [start])

  return render()

})

export default StarsSlider

interface StarViewProps {
  active:   boolean | 'half'
  animated: boolean
}

const StarView = memo('StarView', (props: StarViewProps) => {

  const {active, animated} = props

  const iconName: SVGName =
    active === true   ? 'star-input-full' :
    active === 'half' ? 'star-input-half' :
    'star-input-empty'

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.star, {animated, active}]}>
        <SVG name={iconName}/>
      </VBox>
    )
  }

  return render()

})

export const gap      = layout.padding.inline.xs
export const starSize = {
  narrow: {width: 24, height: 24},
  wide  : {width: 32, height: 32},
}

const useStyles = createUseStyles({
  starsSlider: {
    alignItems: 'center',
  },

  star: {
    '& svg': {
      ...starSize.narrow,

      ...layout.breakpoint({minWidth: 1640})({
        ...starSize.wide,
      }),
    },
  },
})