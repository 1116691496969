import React from 'react'
import { FetchStatus } from 'mobx-document'
import { Breadcrumb } from '~/ui/app/navigation/types'

export interface AppLayoutConfig {
  title:              string | null
  breadcrumbs:        Breadcrumb[]
  fetchStatus:        FetchStatus | null
  ActionsComponent?:  React.ComponentType<{}>
}

export interface AppLayoutContext {
  title:             string | null
  breadcrumbs:       Breadcrumb[]
  fetchStatus:       FetchStatus | null
  collapsed:         boolean
  ActionsComponent?: React.ComponentType<{}>

  configure: (config: AppLayoutConfig) => any
}

const AppLayoutContext = React.createContext<AppLayoutContext>({
  title:             null,
  breadcrumbs:       [],
  fetchStatus:       null,
  collapsed:         true,

  configure: () => ({
    title:             null,
    breadcrumbs:       [],
    fetchStatus:       'idle',
  }),
})

export default AppLayoutContext