import React from 'react'
import { Connection } from '~/models'
import { connectionsStore } from '~/stores'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'
import { memo } from '~/ui/component'
import { ClearButton, HBox } from '~/ui/components'
import { useStyling } from '~/ui/styling'

export interface Props {
  connection: Connection
}

const ConnectionRequestBar = memo('ConnectionRequestBar', (props: Props) => {

  const {connection} = props

  const {colors} = useStyling()

  const confirm = React.useCallback(() => {
    connectionsStore.confirmConnection(connection.id, {})
  }, [connection.id])

  const reject = React.useCallback(() => {
    connectionsStore.rejectConnection(connection.id)
  }, [connection.id])

  //------
  // Rendering

  function render() {
    return (
      <ParticipantBar
        participant={connection.other}
        accessory={renderActions()}
      />
    )
  }

  function renderActions() {
    return (
      <HBox>
        <ClearButton
          icon='check'
          color={colors.semantic.positive}
          onTap={confirm}
          padding='both'
        />
        <ClearButton
          icon='cross'
          color={colors.semantic.negative}
          onTap={reject}
          padding='both'
        />
      </HBox>
    )
  }

  return render()

})

export default ConnectionRequestBar