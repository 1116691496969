import BrandingGuide from './BrandingGuide'

export interface FontFamilies {
  sans: string
  mono: string
  hand?: string
  chat?: string
}

export default class FontGuide {

  constructor(
    private readonly guide: BrandingGuide,
    families: FontFamilies,
  ) {
    this.family = {
      sans:  families.sans,
      mono:  families.mono,
      hand:  families.hand ?? families.sans,
      chat:  families.chat ?? families.sans,
    }
  }

  public readonly family: {
    sans: string
    mono: string
    hand: string
    chat: string
  }

  public get h1(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       28,
      lineHeight: 1.2,
    }
  }

  public get h2(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       20,
      lineHeight: 1.2,
    }
  }

  public get h3(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       16,
      lineHeight: 1.2,
    }
  }

  public get input(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     500,
      size:       16,
      lineHeight: 1.2,
    }
  }

  public get mono(): FontSpec {
    return {
      family:     this.family.mono,
      weight:     500,
      size:       14,
      lineHeight: 1.2,
    }
  }

  public get monoSmall(): FontSpec {
    return {
      family:     this.family.mono,
      weight:     500,
      size:       12,
      lineHeight: 1.2,
    }
  }

  public get monoTiny(): FontSpec {
    return {
      family:     this.family.mono,
      weight:     500,
      size:       10,
      lineHeight: 1.2,
    }
  }

  public get hand(): FontSpec {
    return {
      family:     this.family.hand,
      weight:     500,
      size:       16,
      lineHeight: 1.2,
    }
  }

  public get handSmall(): FontSpec {
    return {
      family:     this.family.hand,
      weight:     500,
      size:       12,
      lineHeight: 1.2,
    }
  }

  public get handTiny(): FontSpec {
    return {
      family:     this.family.mono,
      weight:     500,
      size:       10,
      lineHeight: 1.2,
    }
  }

  public get body(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     500,
      size:       16,
      lineHeight: 1.2,
    }
  }

  public get small(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     400,
      size:       14,
      lineHeight: 1.2,
    }
  }

  public get tiny(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     400,
      size:       12,
      lineHeight: 1.2,
    }
  }

  public get chat(): FontSpec {
    return {
      family:     this.family.chat,
      weight:     400,
      size:       15,
      lineHeight: 1.2,
    }
  }

  public get captionLarge(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       16,
      lineHeight: 1.2,
    }
  }

  public get caption(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       14,
      lineHeight: 1.2,
    }
  }

  public get captionSmall(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       13,
      lineHeight: 1.2,
    }
  }

  public get captionTiny(): FontSpec {
    return {
      family:     this.family.sans,
      weight:     700,
      size:       11,
      lineHeight: 1.2,
    }
  }

}

export interface FontSpec {
  family:     string
  weight:     number
  size:       number
  lineHeight: number
}