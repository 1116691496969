import './ModalPortal.css'
import React from 'react'
import { AutofocusProvider, AutofocusProviderProps, FocusTrap } from 'react-autofocus'
import ReactDOM from 'react-dom'
import Timer from 'react-timer'
import cn from 'classnames'
import { ensureCloseHooks } from './closeHooks'
import manager from './manager'
import { getRoot } from './root'
import { ModalPortalProps, OpenPortal } from './types'

interface State {
  active: boolean
}

export default class ModalPortal extends React.Component<ModalPortalProps, State> implements OpenPortal {

  public state: State = {
    active: false,
  }

  private portal: HTMLElement | null = null
  private portalRef = (el: HTMLElement | null) => { this.portal = el }
  private getPortal = () => this.portal

  private shim: HTMLElement | null = null
  private shimRef = (el: HTMLElement | null) => { this.shim = el }

  private timer = new Timer()

  //------
  // Component lifecycle

  public componentDidMount() {
    ensureCloseHooks()
    if (this.props.open) {
      this.performOpen(true)
    }
  }

  public componentWillUnmount() {
    this.timer.dispose()
    if (this.props.open) {
      this.performClose(false)
    }
  }

  public componentDidUpdate(prevProps: ModalPortalProps) {
    if (!prevProps.open && this.props.open) {
      this.performOpen()
    }
    if (prevProps.open && !this.props.open) {
      this.performClose()
    }
  }

  //------
  // OpenPortal interface

  public async close() {
    if (this.props.mayClose) {
      const mayClose = await this.props.mayClose()
      if (mayClose === false) { return }
    }
    this.props.requestClose?.()
  }

  public get closeOnEscape() {
    return this.props.closeOnEscape !== false
  }

  public shouldCloseOnClick(event: Event) {
    if (this.props.shouldCloseOnClick) {
      const shouldClose = this.props.shouldCloseOnClick(event)
      if (shouldClose !== undefined) { return shouldClose }
    }

    if (this.props.closeOnClickOutside && this.shim != null) {
      return this.shim.contains(event.target as Node)
    } else if (this.props.closeOnClickOutside && this.portal != null) {
      return !this.portal.contains(event.target as Node)
    } else {
      return false
    }
  }

  public get closeOnClickShim() {
    return this.props.closeOnClickOutside !== false
  }

  public get transitionDuration() {
    return this.props.transitionDuration
  }

  public containsElement(element: Element) {
    if (this.portal == null) { return false }
    return this.portal.contains(element)
  }

  //------
  // Open / close

  public get tag() { return this.props.tag ?? null }

  private performOpen(useTransitions: boolean = true) {
    this.timer.clearAll()

    if (useTransitions && this.props.transitionDuration != null) {
      this.setState({active: true}, () => {
        this.onWillOpen()
        this.transitionOpen()
      })
    } else {
      this.setState({active: true}, () => {
        this.onWillOpen()
        this.trapFocus()
        this.finishOpen()
      })
    }
  }

  private finishOpen() {
    this.onDidOpen()
    this.setInitialFocus()
  }

  private performClose(useTransitions: boolean = true) {
    this.releaseFocus()

    this.timer.clearAll()
    this.onWillClose()

    if (useTransitions && this.props.transitionDuration != null) {
      this.transitionClose()
    } else {
      this.finishClose(useTransitions)
    }
  }

  private finishClose(defer: boolean) {
    if (defer) {
      this.setState({active: false}, () => {
        this.onDidClose()
      })
    } else {
      this.onDidClose()
    }
  }

  private onWillOpen() {
    if (this.props.onWillOpen) {
      this.props.onWillOpen()
    }

    manager.triggerPortalListeners('willOpen', this)
    manager.addOpenPortal(this)
  }

  private onDidOpen() {
    if (this.props.onDidOpen) {
      this.props.onDidOpen()
    }

    manager.triggerPortalListeners('didOpen', this)
  }

  private onWillClose() {
    if (this.props.onWillClose) {
      this.props.onWillClose()
    }

    manager.triggerPortalListeners('willClose', this)
    manager.removeOpenPortal(this)
  }

  private onDidClose() {
    if (this.props.onDidClose) {
      this.props.onDidClose()
    }

    manager.triggerPortalListeners('didClose', this)
  }

  //------
  // Custom transitions

  private transitionOpen() {
    const {transitionDuration} = this.props
    if (transitionDuration == null) { return }

    this.timer.performTransition(transitionDuration, {
      onPrepare: () => {
        this.removeTransitionClassNames('close', 'close-active')
        this.appendTransitionClassNames('open')
        if (this.props.onPrepareTransition) {
          this.props.onPrepareTransition(true)
        }

        if (this.shim) {
          this.shim.classList.remove('hide', 'hide-active')
          this.shim.classList.add('show')
        }

        this.trapFocus()
      },

      onCommit: () => {
        // Force a reflow of the portal and the shim.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _1 = this.portal && this.portal.scrollTop
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _2 = this.shim && this.shim.scrollTop

        this.appendTransitionClassNames('open-active')

        if (this.shim) {
          this.shim.classList.add('show-active')
          this.shim.style.transitionDuration = `${this.props.transitionDuration}ms`
        }

        if (this.props.onCommitTransition) {
          this.props.onCommitTransition(true, transitionDuration)
        }
      },

      onCleanUp: () => {
        this.removeTransitionClassNames('open', 'open-active')

        if (this.shim) {
          this.shim.classList.remove('show', 'show-active')
        }

        this.props.onCleanUpTransition?.(true)
        this.finishOpen()
      },
    })
  }

  private transitionClose() {
    const {transitionDuration} = this.props
    if (transitionDuration == null) { return }

    this.timer.performTransition(transitionDuration, {
      onPrepare: () => {
        this.removeTransitionClassNames('open', 'open-active')
        this.appendTransitionClassNames('close')

        if (this.shim != null) {
          this.shim.classList.remove('show', 'show-active')
          this.shim.classList.add('hide')
        }

        this.props.onPrepareTransition?.(false)
      },

      onCommit: () => {
        this.appendTransitionClassNames('close-active')

        if (this.shim != null) {
          this.shim.classList.add('hide-active')
          this.shim.style.transitionDuration = `${this.props.transitionDuration}ms`
        }

        this.props.onCommitTransition?.(false, transitionDuration)
      },

      onCleanUp: () => {
        this.removeTransitionClassNames('close', 'close-active')
        this.shim?.classList.remove('hide', 'hide-active')

        this.props.onCleanUpTransition?.(false)
        this.finishClose(true)
      },
    })
  }

  private get transitionElements(): HTMLElement[] {
    if (this.portal == null) { return [] }

    const content = this.portal.querySelector('.ModalPortal--content')!
    return Array
      .from(content.children)
      .filter(el => el instanceof HTMLElement) as HTMLElement[]
  }

  private transitionClassNames(suffixes: string[]) {
    const {transitionName} = this.props
    if (transitionName == null) { return [] }

    return suffixes.map(suffix => `${transitionName}-${suffix}`)
  }

  private appendTransitionClassNames(...suffixes: string[]) {
    const classNames = this.transitionClassNames(suffixes)
    if (classNames.length === 0) { return }

    for (const element of this.transitionElements) {
      element.classList.add(...classNames)
    }
  }

  private removeTransitionClassNames(...suffixes: string[]) {
    const classNames = this.transitionClassNames(suffixes)
    if (classNames.length === 0) { return }

    for (const element of this.transitionElements) {
      element.classList.remove(...classNames)
    }
  }

  //------
  // Focus handling

  private releaseFocusFn: (() => void) | null = null

  private setInitialFocus() {
    if (this.portal == null) { return }
    if (this.props.trapFocus === false) { return }
    if (document.activeElement != null && this.portal.contains(document.activeElement)) { return }

    this.portal.focus()
  }

  private trapFocus() {
    if (this.portal == null) { return }
    if (this.props.trapFocus === false) { return }

    this.releaseFocusFn = FocusTrap.trap(this.portal)
  }

  private releaseFocus() {
    this.releaseFocusFn?.()
    this.releaseFocusFn = null
  }

  //------
  // Rendering

  public render() {
    const {active} = this.state
    if (!active) { return null }

    return ReactDOM.createPortal(
      this.renderPortal(),
      getRoot(),
    )
  }

  private renderPortal() {
    const {classNames, shim = true, shimClassNames, zIndex, children} = this.props

    const style: React.CSSProperties = {
      ...this.props.style,
    }
    if (zIndex != null) {
      style.zIndex = zIndex
    }

    const defaultFocus: AutofocusProviderProps['defaultFocus'] =
      this.props.autoFocus === undefined || this.props.autoFocus === true ? {buttons: false} :
      this.props.autoFocus

    return (
      <div
        ref={this.portalRef}
        style={style}
        className='ModalPortal'
        tabIndex={0}
      >
        {shim && (
          <div
            ref={this.shimRef}
            className={cn('ModalPortal--shim', shimClassNames)}
          />
        )}
        <AutofocusProvider
          enabled={this.props.open && this.props.autoFocus !== false}
          defaultFocus={defaultFocus}
          containerRef={this.getPortal}
        >
          <div className={cn('ModalPortal--content', classNames)}>
            {children}
          </div>
        </AutofocusProvider>
      </div>
    )
  }

}