import React from 'react'
import { omit } from 'lodash'
import { previewStore, PreviewStore } from '~/stores'
import { forwardRef } from '~/ui/component'
import { HBox, Label, SVG, Tappable, VBox, VBoxProps } from '~/ui/components'
import {
  animation,
  BackgroundSpec,
  BorderSpec,
  BrandingGuide,
  colors,
  ComponentBrandingBase,
  createUseStyles,
  layout,
  shadows,
  themedBackgroundStyles,
  themedBorderStyles,
  ThemeProvider,
  useStyling,
} from '~/ui/styling'

export interface Props extends VBoxProps {
  guide?:   BrandingGuide
  branding: ComponentBrandingBase<any>
  variant?: Record<string, boolean>
  height:   number

  flipShadow?: boolean
}

export interface RequiredStyles {
  background: BackgroundSpec | null
  border:     BorderSpec | null
  depth:      number
}

const BrandedComponent = forwardRef('BrandedComponent', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    branding,
    variant,
    height,
    flipShadow,
    guide: props_guide,
    classNames: props_classNames,
  } = props

  const {guide: defaultGuide} = useStyling()
  const guide = props_guide ?? defaultGuide

  //------
  // Rendering

  const background   = branding.background(variant)
  const border       = branding.border(variant)
  const depth        = branding.resolve('depth', variant)
  const borderRadius = branding.borderRadius(height, variant)

  //------
  // Rendering

  const $ = useStyles({guide, background, border, depth, borderRadius, flipShadow})

  const classNames = [
    $.brandedComponent,
    props_classNames,
  ]

  const selectorProps = omit(props, 'height', 'flipShadow')

  function render() {
    return (
      <ThemeProvider {...branding.themeProps(variant)}>
        <Selector {...selectorProps} classNames={classNames} ref={ref}/>
      </ThemeProvider>
    )
  }

  return render()

})

export type SelectorProps = Omit<Props, 'height' | 'flipShadow'>

const Selector = forwardRef('BrandedComponent.Selector', (props: SelectorProps, ref: React.Ref<HTMLDivElement>) => {

  const {
    branding,
    children,
    variant,
    guide: props_guide,
    classNames: props_classNames,
    ...rest
  } = props

  const {guide: defaultGuide} = useStyling()
  const guide = props_guide ?? defaultGuide

  const brandingPath = React.useMemo(() => {
    if (!PreviewStore.branding) { return null }
    return guide.findComponentPath(branding)
  }, [branding, guide])

  const selectForBranding = React.useCallback(() => {
    if (brandingPath == null) { return }

    const selectedVariant = Object.entries(variant ?? {}).find(it => it[1])?.[0]
    previewStore.selectForBranding(brandingPath, selectedVariant ?? null)
  }, [brandingPath, variant])

  //------
  // Rendering

  const $ = useStyles({})

  const classNames = [
    $.brandedComponentSelector,
    props_classNames,
  ]

  function render() {
    return (
      <VBox classNames={classNames} {...rest} ref={ref}>
        {children}
        {PreviewStore.branding && renderBrandingButton()}
      </VBox>
    )
  }

  function renderBrandingButton() {
    return (
      <VBox align='center' classNames={$.brandingButtonContainer}>
        <ThemeProvider dark>
          <Tappable onTap={selectForBranding} classNames={$.brandingButton}>
            <HBox gap={layout.padding.inline.s}>
              <SVG name='palette' size={layout.icon.xs}/>
              <Label small caption>
                {brandingPath}
              </Label>
            </HBox>
          </Tappable>
        </ThemeProvider>
      </VBox>
    )
  }

  return render()

})

Object.assign(BrandedComponent, {Selector})
export default BrandedComponent as typeof BrandedComponent & {
  Selector: typeof Selector
}

const useStyles = createUseStyles({
  brandedComponent: ({guide, background, border, depth, borderRadius, flipShadow}: StyleSheetProps) => {
    if (guide == null) { return {} }

    return {
      borderRadius: borderRadius,
      boxShadow:    shadows.depth(depth, {}, {flip: flipShadow}),

      position: 'relative',
      '&::after': {
        content: undefined,
        display: 'none',
      },

      ...themedBackgroundStyles(guide, background),
      ...border && themedBorderStyles(guide, border, borderRadius),
    }
  },

  brandedComponentSelector: {
    position: 'relative',
  },

  brandingButtonContainer: {
    ...layout.overlay,
    left:          -120,
    right:         -120,
    overflow:      'hidden',
    pointerEvents: 'none',
  },

  brandingButton: {
    background:   colors.shim.dark,
    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    borderRadius: [0, 0, layout.radius.m, layout.radius.m],
    zIndex:       layout.z.bodyTop,

    transform:     `translateY(-24px)`,
    transition:    animation.transitions.short('transform'),
    pointerEvents: 'auto',

    '$brandedComponentSelector:hover > * > &': {
      transform: 'translateY(0)',
    },
  },

})

interface StyleSheetProps {
  guide:        BrandingGuide
  background:   BackgroundSpec
  border:       BorderSpec
  depth:        number
  borderRadius: number
  flipShadow:   boolean
}