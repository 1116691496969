import React from 'react'
import I18Next from 'i18next'
import { ImageView } from '~/ui/app/media'
import { Center, SVG } from '~/ui/components'
import { useStyling } from '~/ui/styling'

export interface Props {
  size?:           Size
  objectPosition?: React.CSSProperties['objectPosition'],
}

const BrandedLogo = React.memo((props: Props) => {

  const {guide} = useStyling()
  const {size = logoSize, objectPosition} = props
  const logo = guide.image('logo', 'web')

  function render() {
    return (
      <Center>
        {logo != null ? (
          <ImageView
            source={logo}
            size={size}
            alt={I18Next.t('app:title')}
            objectFit='contain'
            objectPosition={objectPosition}
          />
        ) : (
          <SVG
            name='logo'
            size={size}
            title={I18Next.t('app:title')}
          />
        )}
      </Center>
    )
  }

  return render()

})

export default BrandedLogo

export const logoSize = {
  width:  56,
  height: 40,
}