import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import ScopedSocket from './ScopedSocket'
import Socket from './Socket'
import SocketError from './SocketError'
import { OnDemandServiceStatus, OnDemandServiceUID, StartFailure, StartSuccess } from './types'

export default abstract class OnDemandService<R = any> {

  constructor(
    socket: Socket,
  ) {
    this.socket = new ScopedSocket(socket)
    makeObservable(this)
  }

  protected socket: ScopedSocket

  @observable
  protected uid: OnDemandServiceUID | null = null

  @computed
  public get started() {
    return this.uid != null
  }

  @observable
  public starting: boolean = true

  @computed
  public get status(): OnDemandServiceStatus {
    if (this.starting) {
      return 'starting'
    } else if (this.started) {
      return 'started'
    } else if (this.startError != null) {
      return this.startError
    } else {
      return 'idle'
    }
  }

  @observable
  private stopped: boolean = false

  private disconnectDisposer?: () => void
  private reconnectDisposer?: () => void

  @observable
  public startError: SocketError | null = null

  @action
  public async startWithEvent(event: string, ...args: any[]): Promise<R | false> {
    if (this.started) { return false }

    this.starting = true
    this.stopped = false

    // Unbind disconnect & reconnect listeners from a previous start.
    this.disconnectDisposer?.(); delete this.disconnectDisposer
    this.reconnectDisposer?.(); delete this.reconnectDisposer

    await this.socket.socket.ready

    const response = await this.socket.sendWithOptions({silent: true}, event, ...args)

    return runInAction(() => {
      this.starting = false

      if (this.stopped) {
        if (response.ok) {
          // The service was stopped before the start call could finish. Immediately send a stop.
          this.uid = response.body.uid
          this.sendStop()
          this.unbind()
        }
        this.onStop()
        return false
      } else {
        // Handle connection events.
        this.disconnectDisposer = this.socket.on('disconnect', this.handleDisconnect)
        this.reconnectDisposer = this.socket.on('connect', this.handleReconnect)

        if (response.ok) {
          this.uid = response.body.uid
          return this.onStarted({ok: true, data: response.body.data})
        } else {
          this.startError = response.error
          const result = this.onStartError({ok: false, error: response.error})
          return result ?? false
        }
      }
    })
  }

  @action
  public stop() {
    // Mark the service as stopped.
    this.stopped = true

    if (this.starting) {
      // The service is still starting. The start call will see that it's stopped and not process the result.
      return
    }

    if (!this.started) {
      // If for some reason, we weren't started at all, just don't do anything.
      return
    }

    // Unbind event handlers.
    this.disconnectDisposer?.(); delete this.disconnectDisposer
    this.reconnectDisposer?.(); delete this.reconnectDisposer

    // Send the stop call to the server.
    this.sendStop()

    // Unbind this service.
    this.unbind()

    // Fire stop and disconnect events.
    this.stopListeners.forEach(it => it())
    this.onDisconnect()
    this.onStop()
  }

  private sendStop() {
    // Be sure to use the global socket.
    this.socket.socket.emit('service:stop', this.uid)
  }

  @action
  private unbind() {
    this.socket.prefix = ''
    this.uid = null
  }

  private handleDisconnect = () => {
    this.disconnectDisposer?.()
    delete this.disconnectDisposer

    this.onDisconnect()
  }

  private handleReconnect = () => {
    this.reconnectDisposer?.()
    delete this.reconnectDisposer

    // Unbind and restart.
    this.unbind()
    this.start()
  }

  public restart() {
    this.stop()
    return this.start()
  }

  public abstract start(): void

  protected abstract onStarted(response: StartSuccess<any>): R

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onStartError(response: StartFailure): R | undefined {
    return
  }

  protected onStop() {}
  protected onDisconnect() {}

  //------
  // Stop listeners

  private stopListeners = new Set<StopListener>()

  public addStopListener(listener: StopListener) {
    this.stopListeners.add(listener)
    return () => { this.stopListeners.delete(listener) }
  }

}

export type StopListener = () => any