import Timer from 'react-timer'
import { isFunction } from 'lodash'
import KeyCombination from './KeyCombination'
import { HotkeyOptions, KeyHandler, KeyHandlerMap, KeyStroke } from './types'
import { closest, isInputElement, isModifierKey } from './util'

export default class Binding {

  constructor(
    public readonly combinations: KeyCombination[],
    handler: KeyHandler | KeyHandlerMap,
    options: HotkeyOptions = {},
  ) {
    this.handlers = isFunction(handler)
      ? {down: handler, up: undefined}
      : handler

    this.options = {
      inputs:     'ignore',
      filter:     null,
      bindWindow: false,
      ...options,
    }
  }

  private handlers: KeyHandlerMap
  private options: Required<HotkeyOptions>

  private maxKeystrokeLength = Math.max(...this.combinations.map(combo => combo.strokes.length))

  // #region Layers

  private static layers: Binding[][] = [[]]

  private static get currentLayer() {
    return this.layers[this.layers.length - 1]
  }

  public static addLayer() {
    for (const binding of this.currentLayer) {
      binding._unbind()
    }
    this.layers.push([])

    const index = this.layers.length - 1
    return () => {
      this.removeLayer(index)
    }
  }

  public static removeLayer(handle: number) {
    if (this.layers.length <= 1) { return }
    if (handle > this.layers.length - 1) { return }

    const prevCurrentLayer = this.currentLayer
    this.layers.splice(handle, 1)
    if (prevCurrentLayer !== this.currentLayer) {
      for (const binding of this.currentLayer) {
        binding._bind()
      }
    }
  }

  private static addToCurrentLayer(binding: Binding) {
    if (this.currentLayer.includes(binding)) { return }
    this.currentLayer.push(binding)
  }

  private static removeFromCurrentLayer(binding: Binding) {
    const index = this.currentLayer.indexOf(binding)
    if (index === -1) { return }

    this.currentLayer.splice(index, 1)
  }

  // #endregion

  //------
  // Bind / unbind

  public element: HTMLElement | Window | null = null

  public bind(element: HTMLElement | Window = window) {
    if (this.element != null) { return }
    this.element = element

    this._bind()
    if (element === window) {
      Binding.addToCurrentLayer(this)
    }
  }

  public unbind() {
    if (this.element == null) { return }

    if (this.element === window) {
      Binding.removeFromCurrentLayer(this)
    }

    this._unbind()
    this.element = null
  }

  private _bind() {
    if (this.element == null) { return }

    const el = this.element as HTMLElement
    el.addEventListener('keydown', this.keyDownListener)
    el.addEventListener('keyup', this.keyUpListener)
  }

  private _unbind() {
    if (this.element == null) { return }

    const el = this.element as HTMLElement
    el.removeEventListener('keydown', this.keyDownListener)
    el.removeEventListener('keyup', this.keyUpListener)
  }

  private keyDownListener = (event: KeyboardEvent) => {
    const handler = this.handlers.down
    if (handler == null) { return }

    this.eventListener(event, handler)
  }

  private keyUpListener = (event: KeyboardEvent) => {
    const handler = this.handlers.up
    if (handler == null) { return }

    this.eventListener(event, handler)
  }

  private eventListener = (event: KeyboardEvent, handler: KeyHandler) => {
    if (event.repeat) { return }
    if (this.options.filter != null && !this.options.filter(event)) { return }

    if (this.options.inputs === 'ignore') {
      if (event.target instanceof Element && closest(event.target, isInputElement) != null) {
        return
      }
    }

    const stroke = this.keyStrokeFromEvent(event)
    this.addStroke(stroke)

    const combination = this.match()

    // Optimization: if no combinations exist with more keystrokes, we can immediately clear the strokes.
    // This way, we can set up for the next keystroke all over again.
    if (this.strokes.length >= this.maxKeystrokeLength && this.strokes[this.strokes.length - 1].key != null) {
      this.timer.clearAll()
      this.clearStrokes()
    }

    if (combination == null) { return }

    const retval = handler.call(this, combination.descriptor, event)
    if (retval !== false) {
      event.preventDefault()
    }
  }

  //------
  // Strokes

  private timer = new Timer()
  private strokes: KeyStroke[] = []

  private keyStrokeFromEvent(event: KeyboardEvent): KeyStroke {
    return {
      key:      this.keyFromEvent(event),
      shiftKey: event.key === 'Shift'   ? true : event.shiftKey,
      altKey:   event.key === 'Alt'     ? true : event.altKey,
      ctrlKey:  event.key === 'Control' ? true : event.ctrlKey,
      metaKey:  event.key === 'Meta'    ? true : event.metaKey,
    }
  }

  private keyFromEvent(event: KeyboardEvent) {
    if (isModifierKey(event.key)) {
      return null
    } else {
      return event.code
    }
  }

  private addStroke(stroke: KeyStroke) {
    const lastStroke = this.strokes[this.strokes.length - 1]
    if (lastStroke == null || lastStroke.key != null) {
      this.strokes.push(stroke)
    } else {
      this.strokes[this.strokes.length - 1] = stroke
    }

    this.timer.debounce(() => {
      this.clearStrokes()
    }, 300)
  }

  private clearStrokes() {
    this.strokes = []
  }

  private match() {
    for (const combination of this.combinations) {
      if (combination.match(this.strokes)) {
        return combination
      }
    }
    return null
  }

}