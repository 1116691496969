import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { safeParseFloat } from 'ytil'

export function useQueryString() {
  const location = useLocation()
  const history  = useHistory()

  const [modified, setModified] = React.useState<boolean>(false)

  const paramsRef = React.useRef<URLSearchParams>(
    new URLSearchParams(location.search),
  )

  const prevLocationSearchRef = React.useRef('')
  React.useEffect(() => {
    if (location.search !== prevLocationSearchRef.current) {
      paramsRef.current = new URLSearchParams(location.search)
      prevLocationSearchRef.current = location.search
    }
  }, [history.location, location, location.search])

  const setParams = React.useCallback((params: Record<string, any>) => {
    const nextParams = new URLSearchParams(paramsRef.current)
    for (const [name, value] of Object.entries(params)) {
      if (value == null) {
        nextParams.delete(name)
      } else {
        nextParams.set(name, value)
      }
    }
    paramsRef.current = nextParams
    setModified(true)
  }, [])

  React.useEffect(() => {
    if (modified) {
      const nextSearch = `?${paramsRef.current}`
      history.replace(`${nextSearch}${window.location.hash}`)
      paramsRef.current = new URLSearchParams(nextSearch)
      setModified(false)
    }
  }, [history, modified])

  React.useEffect(() => {
    return () => {
      paramsRef.current = new URLSearchParams('')
    }
  }, [])

  return {
    query:     location.search,
    params:    paramsRef.current,
    setParams: setParams,
  }
}

export function useQueryParam(name: string, type?: 'string'): QueryParamHook<string | null>
export function useQueryParam(name: string, type: 'string', defaultValue: string): QueryParamHook<string, string | null>
export function useQueryParam(name: string, type: 'number'): QueryParamHook<number | null>
export function useQueryParam(name: string, type: 'number', defaultValue: number): QueryParamHook<number, number | null>
export function useQueryParam(name: string, type: 'boolean'): QueryParamHook<boolean>
export function useQueryParam(name: string, type: 'string[]', defaultValue?: string[]): QueryParamHook<string[], string[]>
export function useQueryParam(name: string, ...args: any[]): QueryParamHook<any> {
  const type         = args.shift() ?? 'string'
  const defaultValue = args.shift()

  const {params, setParams} = useQueryString()

  const parse = React.useCallback((raw: string | null) => {
    switch (type) {
      case 'string':   return raw ?? defaultValue ?? null
      case 'string[]': return raw?.split(',').map(it => it.trim()).filter(Boolean) ?? defaultValue ?? []
      case 'number':   return safeParseFloat(raw, defaultValue)
      case 'boolean':  return raw != null && raw !== '0' && raw !== 'false' && raw !== 'no'
    }
  }, [defaultValue, type])

  const format = React.useCallback((value: any) => {
    if (value == null) { return null }

    switch (type) {
      case 'string':   return value.toString()
      case 'string[]': return value.length === 0 ? null : [...value].sort().join(',')
      case 'number':   return value.toString()
      case 'boolean':  return value ? '1' : null
    }
  }, [type])

  const value = React.useMemo(() => {
    return parse(params.get(name))
  }, [params, name, parse])

  const setValue = React.useCallback((value: string | null) => {
    setParams({[name]: format(value)})
  }, [format, name, setParams])

  return [value, setValue]
}

export type QueryParamType    = 'string' | 'number' | 'boolean' | 'string[]'
export type QueryParamHook<Out, In = Out> = [Out, (value: In) => void]