import { Document } from 'mobx-document'
import { BeWizrCourseItem } from '~/models'

export default class BeWizrCourseItemDocument extends Document<BeWizrCourseItem> {

  protected async performFetch() {
    // Not supported
    return null
  }

}