import React from 'react'
import cn from 'classnames'
import { observer } from './component'
import { DragDropMonitorContext } from './DragDropContext'
import { SnapGuide } from './types'

export interface Props {
  convertBase?: (guide: SnapGuide) => number | null
  guideColor?:  string
  classNames?:  React.ClassNamesProp
}

const SnapGuidesOverlay = observer('SnapGuidesOverlay', (props: Props) => {

  const {monitor} = React.useContext(DragDropMonitorContext)
  const guides    = monitor?.snapGuides ?? []

  const containerRef = React.useRef<HTMLDivElement>(null)

  const defaultConvert = React.useCallback((guide: SnapGuide) => {
    const rect = containerRef.current?.getBoundingClientRect()
    if (rect == null) { return null }

    const baseOffset = guide.orientation === 'vertical' ? rect.left : rect.top
    return guide.base - baseOffset
  }, [])

  const {convertBase = defaultConvert, guideColor = 'red', classNames} = props

  //------
  // Rendering

  function render() {
    return (
      <div className={cn('react-dnd--SnapGuidesOverlay', classNames)} ref={containerRef}>
        {guides.map(renderGuide)}
      </div>
    )
  }

  function renderGuide(guide: SnapGuide) {
    const baseProp = guide.orientation === 'vertical' ? 'left' : 'top'
    const base     = convertBase(guide)
    if (base == null) { return null }

    return (
      <div
        key={`${guide.orientation}-${guide.base}`}
        className={cn('react-dnd--SnapGuidesOverlay--guide', guide.orientation)}
        style={{
          [baseProp]:      base,
          backgroundColor: guideColor,
        }}
      />
    )
  }

  return render()

})

export default SnapGuidesOverlay