import React from 'react'
import { MenuPageItem } from '~/models'
import { memo } from '~/ui/component'
import { ListBar, SVG } from '~/ui/components'
import { layout } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  item: MenuPageItem
}

const MenuPageItemSearchResult = memo('MenuPageItemSearchResult', (props: Props) => {

  const {item} = props

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={item.image}
        caption={item.caption}
        detail={item.detail}
        accessory={<SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        {...item.link}
      />
    )
  }

  return render()

})

register('menu-items', MenuPageItemSearchResult)