import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationComponentProps, registerNotification } from 'react-notifications'
import { reaction } from 'mobx'
import { ProfileScore } from '~/models'
import { profileStore } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { animation, createUseStyles, useTheme } from '~/ui/styling'
import { ImageView } from '../media'
import NotificationPanel, { imageSize as notifcationPanelImageSize } from './NotificationPanel'

export interface Props {
  score:     ProfileScore
  prevScore: ProfileScore | null
}

const ScoreNotification = memo('ScoreNotification', (props: Props & NotificationComponentProps) => {

  const {score, prevScore} = props
  const [t] = useTranslation('participants')

  const delta = score.score.points - (prevScore?.score.points ?? 0)

  //------
  // Rendering

  function render() {
    return (
      <NotificationPanel
        title={score.caption}
        avatar={renderAvatar()}
        body={t(`profile.score_notification.detail.${delta < 0 ? 'lost' : 'won'}`)}
        accessory={<ChangingScore score={score} prevScore={prevScore}/>}
        href='/profile'
      />
    )
  }

  function renderAvatar() {
    if (score.image == null) { return null }

    return (
      <ImageView
        source={score.image}
        size={notifcationPanelImageSize}
        objectFit='contain'
      />
    )
  }

  return render()

})

export default ScoreNotification

interface ChangingScoreProps {
  score:     ProfileScore
  prevScore: ProfileScore | null
}

const ChangingScore = memo('ChangingScore', (props: ChangingScoreProps) => {

  const {score, prevScore} = props
  const delta = prevScore == null ? 0 :  score.score.points - prevScore.score.points

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox align='middle' justify='right' classNames={[$.ChangingScore, {negative: delta < 0}]}>
        {renderScoreLabel(score, true)}
        {renderScoreLabel(prevScore, false)}
      </HBox>
    )
  }

  function renderScoreLabel(score: ProfileScore | null, actual: boolean) {
    const formatted = score == null ? 0 : score.normalized?.formatted ?? score.score.formatted
    return (
      <VBox flex justify='middle' classNames={[$.scoreLabel, {actual}]}>
        <Label
          bold
          color={theme.semantic.primary}
          children={formatted}
        />
      </VBox>
    )
  }

  return render()

})

registerNotification<Props>(ScoreNotification, show => {
  let prevScores = profileStore.participant?.scores
  return reaction(() => profileStore.participant?.scores, nextScores => {
    if (prevScores != null && nextScores != null) {
      deriveChangedScores(prevScores, nextScores)
    }
    prevScores = nextScores
  })

  function deriveChangedScores(prevScores: ProfileScore[], nextScores: ProfileScore[]) {
    for (const nextScore of nextScores) {
      const prevScore = prevScores.find(score => score.competitionID === nextScore.competitionID)
      if (prevScore == null || prevScore.score.points === nextScore.score.points) { continue }

      const key = `${nextScore.competitionID}-${nextScore.score.points}`
      show(key, {
        score:     nextScore,
        prevScore: prevScore ?? null,
      })
    }
  }
})

const useStyles = createUseStyles({
  ChangingScore: {
   '&.negative': {
    '& $scoreLabel': {
      animationName:'$fadeOutDown',
      '&.actual': {
        animationName: '$fadeInDown',
      },
    },
   },
  },

  scoreLabel: {
    animationDelay: '1s',
    animation: `$fadeOutUp ${animation.durations.long}ms ease-in-out`,
    animationFillMode: 'forwards',
    position: 'absolute',
    '&.actual': {
      animationName: '$fadeInUp',
      transform: `translateY(100%)`,
      opacity: 0,
    },
  },

  '@keyframes fadeOutUp': {
    '0%': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
  },

  '@keyframes fadeOutDown': {
    '0%': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
  },

  '@keyframes fadeInUp': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },

  '@keyframes fadeInDown': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
})