import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeObservable, observable } from 'mobx'
import { ProfileDatum } from '~/models'
import { profileStore } from '~/stores'
import { observer } from '~/ui/component'
import { TextBlock } from '~/ui/components'
import { FormDialog, FormModel, SubmitResult } from '~/ui/form'
import { DynamicFieldset, DynamicFormSchema } from '../dynamic-form'
import { DynamicFieldCommon } from '../dynamic-form/types'

export interface Props {
  open:         boolean
  requestClose: () => any

  datum: ProfileDatum
}

const ProfileDatumForm = observer('ProfileDatumForm', (props: Props) => {

  const {open, requestClose, datum} = props

  const formModel = React.useMemo(
    () => new ProfileDatumFormModel(datum),
    [datum],
  )

  const [t] = useTranslation('participants')
  const title = t('profile.datum_form.title', {
    ...datum.item,
    label: datum.item.label,
    prompt: datum.item.prompt,
  })

  const schema = React.useMemo((): DynamicFormSchema => {
    // Social items not yet supported.
    if (datum.item.type === 'social') { return [] }

    const common: DynamicFieldCommon = {
      name:      'value',
      caption:   null,
      required:  true,
    }

    if (datum.item.type === 'text') {
      return [{
        ...common,
        type:        'string',
        placeholder: datum.item.placeholder ?? undefined,
        minLength:   datum.item.minLength,
        maxLength:   datum.item.maxLength,
        multiline:   datum.item.multiline,
      }]
    } else {
      return [{
        ...common,
        type:   'choice',
        choices: datum.item.choices,
      }]
    }
  }, [datum])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} title={title}>
        <TextBlock caption>
          {datum.item.prompt}
        </TextBlock>

        <DynamicFieldset
          schema={schema}
        />
      </FormDialog>
    )
  }

  return render()

})

export default ProfileDatumForm

class ProfileDatumFormModel implements FormModel {

  constructor(
    public readonly datum: ProfileDatum,
  ) {
    makeObservable(this)
  }

  @observable
  public value: any = this.datum.value

  public async submit(): Promise<SubmitResult | undefined> {
    return await profileStore.updateProfile({
      [this.datum.name]: this.value,
    })
  }

}