import React from 'react'
import { IndexPage, Model, Participant } from '~/models'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'

export default function renderIndexItem(item: Model, page: IndexPage) {
  if (item instanceof Participant) {
    return <ParticipantBar participant={item}/>
  } else {
    console.warn("Unknown index item", item)
    return null
  }
}
