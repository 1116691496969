import React from 'react'
import { isFunction, range } from 'lodash'
import { forwardRef } from '~/ui/component'
import HBox from './HBox'
import VBox, { Props as VBoxProps } from './VBox'

export interface Props extends VBoxProps {
  rows:       number
  columns:    number
  crossFlex?: VBoxProps['flex']
  children?:  React.ReactNode | CellRenderFunction
}

type CellRenderFunction = (row: number, column: number) => React.ReactNode

const AutoGrid = forwardRef('AutoGrid', (props: Props, ref: React.Ref<HTMLDivElement>) => {

  const {
    rows,
    columns,
    gap = 0,
    crossFlex,
    children,
    ...rest
  } = props

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={gap} {...rest} ref={ref}>
        {range(0, rows).map(renderRow)}
      </VBox>
    )
  }

  function renderRow(row: number) {
    return (
      <HBox flex={props.flex} align='stretch' key={row} gap={gap}>
        {range(0, columns).map(column => (
          <VBox flex={crossFlex} key={column}>
            {isFunction(children) ? children(row, column) : children}
          </VBox>
        ))}
      </HBox>
    )
  }

  return render()

})

export default AutoGrid