import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPendingMessage, MessageListItem } from '~/models'
import { useClock } from '~/socket/useClock'
import { memo } from '~/ui/component'
import { SVG, SVGProps, Tooltip } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  message: MessageListItem
}

const MessageStatusIcon = memo('MessageStatusIcon', (props: Props) => {

  const {message} = props
  const theme     = useTheme()

  const [t]     = useTranslation('chat')
  const tooltip = t(`message_status.${message.status}`)

  const {currentTime} = useClock({interval: 'once'})

  //------
  // Rendering

  function render() {
    return (
      <Tooltip renderTooltip={tooltip}>
        {isPendingMessage(message) ? (
          renderPending()
        ) : (
          renderSent()
        )}
      </Tooltip>
    )
  }

  function renderPending() {
    const {status} = message
    if (status === 'pending') {
      return renderIcon('message.pending', {dim: true})
    } else if (status === 'error' || status === 'timeout') {
      return renderIcon('message.error', {dim: true})
    } else {
      return renderIcon('message.sent', {dim: true})
    }
  }

  function renderSent() {
    if (message.status === 'stale' || message.status === 'invalid') {
      return renderIcon('message.error', {dim: true})
    } else if (message.status === 'redacted') {
      return renderIcon('message.redacted', {dim: true})
    } if (message.status === 'read') {
      return renderIcon('message.read', {color: theme.fg.highlight})
    } else if (message.status === 'read-by-some') {
      return renderIcon('message.read', {dim: true})
    } else if (message.feedback != null && !message.isAnswered && message.feedbackExpiredAt(currentTime)) {
      return renderIcon('message.pending', {dim: true})
    } else {
      return renderIcon('message.sent', {dim: true})
    }
  }

  function renderIcon(icon: SVGName, extra: Omit<SVGProps, 'name'>) {
    return (
      <SVG
        name={icon}
        size={layout.icon.m}
        {...extra}
      />
    )
  }

  return render()

})

export default MessageStatusIcon