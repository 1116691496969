import React from 'react'
import { memo } from '~/ui/component'
import { SelectField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { ChoiceField } from '../types'

export interface Props {
  field: ChoiceField<any>
  bind:  FormFieldBindProps<string>
}

const ChoiceAllowance = memo('ChoiceAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <SelectField
        {...bind}
        choices={field.choices}
      />
    )
  }

  return render()

})

export default ChoiceAllowance