import React from 'react'
import { memo } from '~/ui/component'
import { FlipNavigator } from '~/ui/components'
import { AuthLayout } from '~/ui/layouts'
import AuthResetScreen from '../auth/auth-reset/AuthResetScreen'
import ForgotPinScreen from '../auth/forgot-pin/ForgotPinScreen'
import LoginScreen from '../auth/login/LoginScreen'

export interface Props {

}

const AuthNavigator = memo('AuthNavigator', (props: Props) => {

  return (
    <AuthLayout>
      <FlipNavigator>
        <FlipNavigator.Route
          side='front'
          component={LoginScreen}
          path='/login'
          exact
        />
        <FlipNavigator.Route
          side='back'
          component={ForgotPinScreen}
          path='/auth/forgot-pin'
          exact
        />
        <FlipNavigator.Route
          side='back'
          component={AuthResetScreen}
          path='/auth/reset'
          exact
        />
      </FlipNavigator>
    </AuthLayout>
  )

})

export default AuthNavigator