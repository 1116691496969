import Logger from 'logger'
import { pack, unpack } from 'msgpackr'
import { Listener, ReadyCallback, WindowChannelOptions } from './types'
import WindowChannelLogger from './WindowChannelLogger'

const logger = new Logger('WindowChannel')

export default class WindowChannel<Events extends Record<string, any> = {}> {

  constructor(
    public readonly namespace: string,
    options: WindowChannelOptions = {},
  ) {
    this.hostWindow  = options.hostWindow ?? global.window
    this.guestOrigin = options.guestOrigin ?? this.hostWindow.origin
    this.bind()
  }

  public dispose() {
    this.unbind()
  }

  private readonly logger = new WindowChannelLogger(logger)

  private readonly hostWindow: Window
  private readonly guestOrigin: string

  private readonly guestWindows = new Set<Window>()

  public get hasGuestWindows() {
    return this.guestWindows.size > 0
  }

  //------
  // Listeners

  private readonly listeners = new Map<string, Set<Listener<any>>>()

  public addListener<E extends string & keyof Events>(type: E, listener: Listener<Events[E]>) {
    let listeners = this.listeners.get(type)
    if (listeners == null) {
      this.listeners.set(type, listeners = new Set())
    }
    listeners.add(listener)

    return this.removeListener.bind(this, type as any, listener as any)
  }

  public removeListener<E extends string & keyof Events>(type: E, listener: Listener<Events[E]>) {
    const listeners = this.listeners.get(type)
    if (listeners == null) { return }

    listeners.delete(listener)
    if (listeners.size === 0) {
      this.listeners.delete(type)
    }
  }

  //------
  // Bind / unbind

  private bind() {
    this.hostWindow.addEventListener('message', this.handleMessage)
  }

  private unbind() {
    this.hostWindow.removeEventListener('message', this.handleMessage)
  }

  //------
  // Connecting

  private readyCallbacks = new Set<ReadyCallback>()

  public connect(window: Window) {
    this.guestWindows.add(window)
    return this.disconnect.bind(this, window)
  }

  public disconnect(window: Window) {
    if (!this.guestWindows.has(window)) { return }
    this.guestWindows.delete(window)
  }

  public ready(callback: ReadyCallback) {
    this.readyCallbacks.add(callback)
  }

  private emitReady(window: Window) {
    this.to(window, () => {
      for (const callback of this.readyCallbacks) {
        callback(window)
      }
    })
  }

  private onWindowDisconnect(window: Window) {
    this.guestWindows.delete(window)
  }

  //------
  // Directed messages

  private directedTo: Window | null = null

  public to(window: Window, callback: () => any) {
    if (!this.guestWindows.has(window)) {
      throw new Error("Given window must be a connected guest window")
    }

    try {
      this.directedTo = window
      callback()
    } finally {
      this.directedTo = null
    }
  }

  //------
  // Messages

  public send<E extends string & EventsWithoutArgument<Events>>(type: E): void
  public send<E extends string & keyof Events>(type: E, payload: Events[E]): void
  public send<E extends string & keyof Events>(type: E, payload?: Events[E]) {
    if (this.directedTo == null) {
      return this.broadcast(type, payload as any)
    } else {
      this.logger.logSend(type, payload)
      this.sendToWindow(this.directedTo, type, payload)
    }
  }

  public broadcast<E extends string & EventsWithoutArgument<Events>>(type: E): void
  public broadcast<E extends string & keyof Events>(type: E, payload: Events[E]): void
  public broadcast<E extends string & keyof Events>(type: E, payload?: Events[E]) {
    this.logger.logBroadcast(type, payload)
    for (const window of this.guestWindows) {
      this.sendToWindow(window, type, payload)
    }
  }

  private sendToWindow(window: Window, type: string, payload?: any) {
    const packed = pack({
      namespace: this.namespace,
      type:      type,
      payload:   payload,
    })
    window.postMessage(packed, this.guestOrigin)
  }

  private handleMessage = (event: MessageEvent) => {
    if (!(event.source instanceof Window)) { return }
    if (event.currentTarget !== this.hostWindow) { return }
    if (!this.guestWindows.has(event.source)) { return }
    if (event.origin !== this.guestOrigin) { return }

    try {
      const {namespace, type, payload} = unpack(event.data)
      if (namespace !== this.namespace) { return }

      this.logger.logIncoming(type, payload)
      this.handleEventFromGuestWindow(event.source, type, payload)
    } catch (error: any) {
      console.warn("Unable to unpack message: ", error)
      return
    }
  }

  private handleEventFromGuestWindow(window: Window, type: string, payload: any) {
    if (type === '$ready') {
      this.emitReady(window)
      return
    }

    if (type === '$disconnect') {
      this.onWindowDisconnect(window)
      return
    }

    // Handle any other event.
    const listeners = this.listeners.get(type)
    for (const listener of listeners ?? []) {
      listener(payload, window)
    }
  }

}

type EventsWithoutArgument<E extends Record<string, any>> = {
  [K in keyof E]: E[K] extends never ? K : never
}[keyof E]