import React from 'react'
import { Challenge, ChallengeTask } from '~/models'
import { challengesStore } from '~/stores'
import { observer } from '~/ui/component'
import { renderAnswerEntry } from '../answers'
import { useChallengeTaskNavigation } from './ChallengeTaskNavigationContext'

export interface Props {
  challenge: Challenge
  task:      ChallengeTask
}

const ChallengeTaskAnswerEntry = observer('ChallengeTaskAnswerEntry', (props: Props) => {

  const {
    challenge,
    task,
  } = props

  const {question} = task
  const value = challengesStore.getCurrentAnswer(challenge.id, task.uuid)

  const {completeTask} = useChallengeTaskNavigation()

  //------
  // Callbacks

  const handleChange = React.useCallback((answer: any) => {
    challengesStore.setCurrentAnswer(challenge.id, task.uuid, answer)
  }, [challenge.id, task])

  const commit = React.useCallback(() => {
    completeTask()
  }, [completeTask])

  //------
  // Rendering

  function render() {
    if (question == null) { return null }

    return renderAnswerEntry({
      question: question,
      value:    value,
      onChange: handleChange,
      onCommit: commit,
    })
  }

  return render()

})

export default ChallengeTaskAnswerEntry