import React from 'react'
import classNames from 'classnames'
import { omit } from 'lodash'

// Flatten the classNames prop into a classNames prop for all HTML elements. Not for regular components.s

const originalCreateElement = React.createElement
const context = React as any

context.createElement = function(type: any, ...args: any[]) {
  let props = args.length > 0 ? args.shift() : null
  if (typeof type === 'string' && props != null && 'classNames' in props) {
    props = Object.assign({}, omit(props, 'classNames'), {
      className: classNames(props.className, props.classNames).toString(),
    })
  }
  return originalCreateElement.call(this, type, props, ...args)
}