import { configure, LogLevel, transports } from 'logger'

function setLogLevel(logLevel: LogLevel) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('logging.level', logLevel)
  }
  configure({
    transports: buildTransports(logLevel),
  })
}

function getInitialLogLevel(): LogLevel | null {
  if (SERVER) { return 'info' }

  try {
    if (typeof localStorage === 'undefined') {
      throw new Error("No local storage present")
    }

    return localStorage.getItem('logging.level') as LogLevel
  } catch (error: any) {
    console.warn("Could not read log level from local storage: " + error.message)
    return 'info'
  }
}

function buildTransports(level: LogLevel) {
  return [
    new transports.ConsoleTransport(level),
  ]
}

const initialLevel = getInitialLogLevel()
if (initialLevel != null) {
  configure({
    transports: buildTransports(initialLevel),
  })
}

Object.assign(global, {
  setLogLevel,
})