import { some } from 'lodash'
import ClipboardQuery from './ClipboardQuery'
import { ClipboardItem } from './types'

export default class Clipboard {

  public write(items: ClipboardItem<any>[]) {
    localStorage.setItem('clipboard', JSON.stringify(items))

    for (const {type, data} of items) {
      if (type === 'text/plain' && typeof data === 'string') {
        navigator.clipboard.writeText(data)
        break
      }
    }
  }

  public read(): ClipboardItem<any>[] {
    const serialized = localStorage.getItem('clipboard') ?? '[]'
    return JSON.parse(serialized)
  }

  public hasType(type: string) {
    const items = this.read()
    return some(items, item => item.type === type)
  }

  public getData<T>(type: string, remove: boolean = true): ClipboardItem<T> | null {
    const items = this.read()
    const index = items.findIndex(item => item.type === type)
    if (index < 0) { return null }

    const item = items[index]
    if (remove) {
      items.splice(index, 1)
      this.write(items)
    }
    return item
  }

  public query<T>(type: string) {
    return new ClipboardQuery<T>(this, type)
  }

}