import React from 'react'
import { useTimer } from 'react-timer'
import { useContinuousRef } from './hooks'
import { NotificationKey } from './types'

export interface Props {
  notificationKey: NotificationKey

  padding:  number | number[]
  lifetime: number

  showDuration?: number
  hideDuration?: number

  requestRemove: (notificationKey: NotificationKey) => any
  children?:     React.ReactNode
}

const NotificationContainer = React.forwardRef(function NotificationContainer(props: Props, ref: React.Ref<NotificationContainer>) {

  const {
    notificationKey,
    padding,
    lifetime,
    showDuration = 300,
    hideDuration = 300,
    requestRemove,
    children,
  } = props

  //------
  // Layout

  const getPadding = React.useCallback((side: 'top' | 'bottom' | 'left' | 'right'): number => {
    if (typeof padding === 'number') {
      return padding
    } else if (padding.length === 1) {
      return padding[0]
    } else if (padding.length === 2) {
      return side === 'left' || side === 'right' ? padding[1] : padding[0]
    } else if (padding.length === 3) {
      return side === 'left' || side === 'right' ? padding[0] : side === 'bottom' ? padding[2] : padding[1]
    } else {
      return padding[side === 'top' ? 0 : side === 'right' ? 1 : side === 'bottom' ? 2 : 3]
    }
  }, [padding])

  const positionStyle = React.useMemo((): React.CSSProperties => {
    return {
      position: 'absolute',
      left:     getPadding('left'),
      right:    getPadding('right'),
      top:      getPadding('top'),
      bottom:   getPadding('bottom'),
    }
  }, [getPadding])

  //------
  // Show / hide

  const hideTimer      = useTimer()
  const animationTimer = useTimer()

  const showDurationRef  = useContinuousRef(showDuration)
  const hideDurationRef  = useContinuousRef(hideDuration)
  const requestRemoveRef = useContinuousRef(requestRemove)

  const [animationClassName, setAnimationClassName] = React.useState<string | null>('show')
  const [animationStyles, setAnimationStyles] = React.useState<React.CSSProperties>({})

  const animate = React.useCallback((transition: 'show' | 'hide', duration: number, callback?: () => any) => {
    setAnimationClassName(transition)
    setAnimationStyles({
      transitionDuration: `${transition === 'show' ? showDurationRef.current : hideDurationRef.current}ms`,
    })

    animationTimer.setTimeout(() => {
      setAnimationClassName(`${transition} ${transition}-active`)
    }, 16)
    animationTimer.setTimeout(() => {
      setAnimationClassName(null)
      setAnimationStyles({})
      callback?.()
    }, duration + 16)
  }, [animationTimer, hideDurationRef, showDurationRef])

  const remove = React.useCallback(() => {
    requestRemoveRef.current?.(notificationKey)
  }, [notificationKey, requestRemoveRef])

  const show = React.useCallback(() => {
    animate('show', showDurationRef.current)
  }, [animate, showDurationRef])

  const hide = React.useCallback(() => {
    hideTimer.clearAll()
    animate('hide', hideDurationRef.current, remove)
  }, [animate, hideDurationRef, hideTimer, remove])

  React.useEffect(() => {
    hideTimer.setTimeout(hide, lifetime)
    return () => { hideTimer.clearAll() }
  }, [hide, hideTimer, lifetime])

  React.useEffect(show, [show])

  React.useImperativeHandle(ref, () => ({
    hide,
  }), [hide])

  //------
  // Rendering

  function render() {
    const className = [
      'react-notifications--NotificationContainer',
      animationClassName,
    ].filter(Boolean).join(' ')

    return (
      <div className={className} style={{...positionStyle, ...animationStyles}}>
        {children}
      </div>
    )
  }

  return render()

})

interface NotificationContainer {
  hide(): void
}

export default NotificationContainer