import DragDropMonitor from './DragDropMonitor'

export interface ItemLayout {
  sourceRect: LayoutRect
  offsetSize: Size
  scale:      number
}

export interface LayoutRect {
  top:    number
  left:   number
  width:  number
  height: number
}

export const LayoutRect: {
  zero(): LayoutRect
} = {
  zero: () => ({
    top:    0,
    left:   0,
    width:  0,
    height: 0,
  }),
}

export interface Point {
  x: number
  y: number
}

export interface Size {
  width:  number
  height: number
}

export interface DraggableItem<T extends string = string> {
  type: T
}

export interface DragGestureHandlers<T extends DraggableItem = any> {
  shake?: (item: T, monitor: DragDropMonitor<T>) => void
}

export type DropZone = [symbol, DropZoneAccept, LayoutRect, number]

export type DropZoneAccept<T extends DraggableItem = any> = Array<T['type']> | ((item: T) => boolean)

export interface ItemHandlers<T extends DraggableItem = any> {
  enter?: (item: T, zoneID: symbol, monitor: DragDropMonitor<T>) => any
  leave?: (item: T, zoneID: symbol, monitor: DragDropMonitor<T>) => any
}

export interface DropZoneHandlers<T extends DraggableItem = any> {
  getBounds:  () => LayoutRect | null
  getZIndex?: () => number

  start?: (item: T, monitor: DragDropMonitor<T>) => any
  end?:   (item: T, monitor: DragDropMonitor<T>) => any
  enter?: (item: T, monitor: DragDropMonitor<T>) => any
  leave?: (item: T, monitor: DragDropMonitor<T>) => any
  hover?: (item: T, center: Point, monitor: DragDropMonitor<T>) => any
  drop?:  (item: T, center: Point, monitor: DragDropMonitor<T>) => any | Promise<any>
  snap?:  (center: Point, monitor: DragDropMonitor<T>) => SnapAction | null
}

export interface SnapAction {
  center: Point
  guides: SnapGuide[]
}

export interface SnapGuide {
  orientation: 'horizontal' | 'vertical'
  base:        number
}

export type ElementConnect = (element: HTMLElement | null) => void