export type ScrollingElement = HTMLElement & {
  scrollTop:  number
  scrollLeft: number
}

export type ScrollToOptions = Partial<ScrollPosition> & ScrollOptions

export interface ScrollPosition {
  left: number
  top:  number
}

export type ScrollOptions = InstantScrollOptions | SmoothScrollOptions

export interface InstantScrollOptions {
  behavior: 'instant'
  except?:  ScrollingElement[]
}

export interface SmoothScrollOptions extends ScrollAnimationOptions {
  behavior: 'smooth'
  except?:  ScrollingElement[]
}

export type ScrollEndCallback    = (complete: boolean) => any
export type ScrollStepCallback   = (position: Partial<ScrollPosition>) => any
export type ScrollEasingFunction = (x: number) => number
export type ScrollDuration       = number | ((distance: number) => number)

export interface ScrollAnimationOptions {
  duration?: ScrollDuration
  easing?:   ScrollEasingFunction
  onStep?:   ScrollStepCallback | null
  onEnd?:    ScrollEndCallback | null
}

export interface Size {
  width:  number
  height: number
}

export type ScrollListener = (scrollPosition: ScrollPosition, source: ScrollingElement) => any

interface ScrollState {
  atStart?:        boolean
  atEnd?:          boolean
  position?:       number
  scrollbarWidth?: number
}

interface ScrollStateStatic {
  default: ScrollState
}

const ScrollState: ScrollStateStatic = {
  default: {atStart: true, atEnd: true, scrollbarWidth: 0},
}

export { ScrollState}