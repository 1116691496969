import Color from 'color'
import { DateTime } from 'luxon'
import ModelSerialization from './ModelSerialization'

function serializer<T, R>(deserialize: (raw: R) => T, serialize: (value: T) => R): SerializerDecorator {
  return (path?: string) => (target, key) => {
    if (typeof key !== 'string') { return }

    ModelSerialization.for(target).modify(key, info => {
      info.serializers.push({path, deserialize, serialize})
    })
  }
}

type SerializerDecorator = (path?: string) => PropertyDecorator

export const datetime = serializer<DateTime, string>(
  raw  => DateTime.isDateTime(raw) ? raw : DateTime.fromISO(raw),
  date => date.toISO(),
)

export const color = serializer<Color, string>(
  raw   => new Color(raw),
  color => color.hex(),
)