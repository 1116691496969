import React from 'react'
import clipboard, { ClipboardItem, ClipboardQuery } from 'rich-clipboard'

export default function useClipboardQuery<T>(type: string): [ClipboardQuery<T>, ClipboardItem<T> | null] {
  const query = React.useMemo(() => clipboard.query<T>(type), [type])

  const [preview, setPreview] = React.useState<ClipboardItem<T> | null>(query.preview)

  React.useEffect(() => {
    return query.start((_, preview) => {
      setPreview(preview)
    })
  }, [query])

  return [query, preview]
}