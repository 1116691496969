import Toast from 'react-toast'
import I18next from 'i18next'
import { humanFileSize } from 'ytil'
import { MediaUploadResult } from '~/stores'

export default function processMediaUpload(result: MediaUploadResult | null, maxSize?: number) {
  if (result == null || result.status === 'canceled') { return null }

  if (result.status !== 'ok') {
    const reason = result.status === 'invalid' ? result.reason : 'unknown'
    const title  = I18next.t([`media_upload:error.${reason}.title`, 'media_upload:error.unknown.title'])
    const detail = reason === 'too-large' && maxSize != null
      ? I18next.t('media_upload:error.too-large.detail_size', {size: humanFileSize(maxSize)})
      : I18next.t([`media_upload:error.${reason}.detail`, 'media_upload:error.unknown.detail'])

    Toast.show({
      type:   'error',
      title:  title,
      detail: detail,
    })
    return null
  }

  return result.media

}