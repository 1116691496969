import React from 'react'
import { BeWizrCourse } from '~/models'
import { memo } from '~/ui/component'
import { ListBar, SVG } from '~/ui/components'
import { layout } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  item: BeWizrCourse
}

const BeWizrCourseSearchResult = memo('BeWizrCourseSearchResult', (props: Props) => {

  const {item} = props

  //------
  // Rendering

  const image = item.links.image == null ? undefined : {
    type: 'remote',
    url:   item.links.image,
  }

  function render() {
    return (
      <ListBar
        image={image}
        caption={item.title}
        accessory={<SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        href={`/bewizr-courses/-/${item.slug}`}
      />
    )
  }

  return render()

})

register('bewizr-courses', BeWizrCourseSearchResult)