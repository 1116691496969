import React from 'react'
import Color from 'color'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { panelBorderRadius } from './layout'

export interface Props {
  icon?:     SVGName | React.ReactNode
  caption?:  React.ReactNode
  right?:    React.ReactNode
  children?: React.ReactNode

  small?:   boolean
  large?:   boolean

  roundTop?: boolean

  backgroundColor?: Color
}

const PanelHeader = memo('PanelHeader', (props: Props) => {

  const {
    icon,
    caption,
    right,
    children,
    small = false,
    large = false,
    roundTop = true,
    backgroundColor,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const classNames = [$.PanelHeader, {small, large, roundTop}]
    const gap        = large ? layout.padding.inline.l : layout.padding.inline.m
    const style      = {backgroundColor: backgroundColor?.string()}

    return (
      <VBox>
        <ThemeProvider contrast={backgroundColor}>
          <HBox classNames={classNames} gap={gap} style={style}>
            {typeof icon === 'string' ? (
              <SVG
                name={icon as SVGName}
                size={small ? layout.icon.s : layout.icon.m}
              />
            ) : (
              icon
            )}
            {isReactText(caption) ? (
              <Label h3={large} flex caption={small} small={small}>
                {caption}
              </Label>
            ) : caption != null ? (
              <VBox flex>
                {caption}
              </VBox>
            ) : null}
            {right}
            {children}
          </HBox>
        </ThemeProvider>
        <Dimple horizontal/>
      </VBox>
    )
  }

  return render()

})

export default PanelHeader

export const height = {
  small:  layout.barHeight.s,
  normal: layout.barHeight.m,
  large:  layout.barHeight.l,
}

const useStyles = createUseStyles(theme => {
  const borderRadius = panelBorderRadius(theme)

  return {
    PanelHeader: {
      '&:not(.small):not(.large)': {
        minHight:     height.normal,
        padding:      [layout.padding.inline.s, layout.padding.inline.l],
        paddingRight: layout.padding.inline.m,
      },
      '&.small': {
        minHight:     height.small,
        padding:      [layout.padding.inline.s, layout.padding.inline.m],
        paddingRight: layout.padding.inline.s,
      },
      '&.large': {
        minHight:     height.large,
        padding:      [layout.padding.inline.s, layout.padding.inline.xl],
        paddingRight: layout.padding.inline.m,
      },

      background:    theme.semantic.secondary.mix(theme.bg.alt, 0.95),

      '&.roundTop': {
        borderRadius: [borderRadius, borderRadius, 0, 0],
      },
    },
  }
})