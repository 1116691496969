import React from 'react'
import { Participant, ProfileScore } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, List, ListItem, Panel, panelBorderRadius, SVG } from '~/ui/components'
import { imageSize as listItemImageSize } from '~/ui/components/ListItem'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { ImageView } from '../media'

export interface Props {
  participant: Participant
}

const ScoresTable = memo('ScoresTable', (props: Props) => {

  const {participant} = props

  //------
  // Rendering

  function render() {
    if (participant.scores == null) { return null }
    return (
      <Panel>
        <List
          data={participant.scores}
          renderItem={renderScore}
          SeparatorComponent={renderSeparator}
          flex={false}
        />
      </Panel>
    )
  }

  const renderScore = React.useCallback((score: ProfileScore, index: number) => (
    <ScoreRow
      score={score}
      isFirst={index === 0}
      isLast={index === participant.scores?.length! - 1}
    />
  ), [participant.scores])

  const renderSeparator = React.useCallback(() => (
    <Dimple horizontal/>
  ), [])

  return render()

})

export default ScoresTable

export interface ScoreRowProps {
  score:   ProfileScore
  isFirst: boolean
  isLast:  boolean
}

const ScoreRow = memo('ScoreRow', (props: ScoreRowProps) => {

  const {score, isFirst, isLast} = props

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        href={`/rankings/-/${score.competitionID}`}
        caption={score.caption}
        image={renderImage()}
        accessory={renderScore()}
        classNames={[$.ScoreRow, {first: isFirst, last: isLast}]}
      />
    )
  }

  function renderImage() {
    if (score.image == null) { return null }

    return (
      <ImageView
        source={score.image}
        size={listItemImageSize}
        objectFit='contain'
      />
    )
  }

  function renderScore() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Label bold color={theme.semantic.primary}>
          {score.normalized?.formatted ?? score.score.formatted}
        </Label>
        {score.showRankings && (
          <SVG name='chevron-right' size={layout.icon.m} dimmer/>
        )}
      </HBox>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  ScoreRow: {
    minHeight: layout.barHeight.m,
    '&.first': {
      borderTopRightRadius: panelBorderRadius(theme),
      borderTopLeftRadius: panelBorderRadius(theme),
    },
    '&.last': {
      borderBottomRightRadius: panelBorderRadius(theme),
      borderBottomLeftRadius: panelBorderRadius(theme),
    },
  },
}))