import React from 'react'
import { ChangeCallback, FieldChangeCallback, isFieldChangeCallback } from '../types'

export function useChainedFieldChangeCallback<Out, In = any>(
  upstream: ChangeCallback<Out> | FieldChangeCallback<Out> | undefined,
  handler: (value: In, invoke: FieldChangeCallback<Out>) => any,
  deps: any[],
) {
  const invoke = React.useMemo(() => {
    const invoke = ((value: Out) => {
      upstream?.(value)
    }) as FieldChangeCallback<Out>

    invoke.partial = (value: Out) => {
      if (upstream != null && isFieldChangeCallback(upstream)) {
        upstream?.partial(value)
      } else {
        upstream?.(value)
      }
    }

    return invoke
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upstream, ...deps])

  const invokePartial = React.useMemo(() => {
    const invoke = ((value: Out) => {
      if (upstream != null && isFieldChangeCallback(upstream)) {
        upstream?.partial(value)
      } else {
        upstream?.(value)
      }
    }) as FieldChangeCallback<Out>

    invoke.partial = invoke
    return invoke
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upstream, ...deps])

  return React.useMemo(() => {
    const callback   = (value: In) => handler(value, invoke) as FieldChangeCallback<In>
    callback.partial = (value: In) => handler(value, invokePartial)
    return callback
  }, [handler, invoke, invokePartial])
}