import React from 'react'
import { useTimer } from 'react-timer'
import { Matrix3D } from 'ytil'
import { memo } from '~/ui/component'
import { usePrevious } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'

export interface Props {
  flipped:   boolean
  duration?: number
  timing?:   string
  children?: React.ReactNode
}

const Flipper = memo('Flipper', (props: Props) => {

  const {
    flipped,
    duration = animation.durations.long,
    timing   = defaultTiming,
    children,
  } = props

  const timer = useTimer()
  const containerRef = React.useRef<HTMLDivElement>(null)

  const prevFlippedRef = React.useRef<boolean>(flipped)
  const prevFlipped    = prevFlippedRef.current

  // Signal used to start the transition.
  const startRef = React.useRef<boolean>(false)

  const [phase, setPhase] = React.useState<FlipperTransitionPhase | null>(null)
  const [frontIsShown, setFrontIsShown] = React.useState<boolean>(true)

  const prevChildren  = usePrevious(children)
  const backSideRef   = React.useRef<React.ReactNode>(null)
  const directionRef  = React.useRef<'forward' | 'reverse'>('forward')

  // Use keys to make sure that content is never re-mounted during transition.
  const frontKeyRef = React.useRef<number>(currentKey)
  const backKeyRef  = React.useRef<number>(currentKey)

  //------
  // Flip

  // When the content changes, there's one render where the transition is not yet active, but the
  // content is already changed. The actual transition starts in a layout effect, but already prep
  // the refs so that the content is correct.

  if (prevFlipped !== undefined && prevFlipped !== flipped) {
    // Store the previous children to render on the back side.
    backSideRef.current    = prevChildren
    prevFlippedRef.current = flipped
    directionRef.current   = !prevFlipped && flipped ? 'forward' : 'reverse'

    // Update the keys.
    backKeyRef.current  = frontKeyRef.current
    frontKeyRef.current = nextKey()

    // Activate the transition. This will also kick the layout effect into action.
    startRef.current = true
  }

  const calculateFrontIsShown = React.useCallback(() => {
    if (containerRef.current == null) { return true }

    const {transform} = window.getComputedStyle(containerRef.current)
    const matrix = Matrix3D.parseTransform(transform)
    if (matrix == null) { return true }

    return Math.abs(matrix.rotateY) <= Math.PI / 2
  }, [])

  const frameCallbackRef = React.useCallback(() => {
    const frontIsShown = calculateFrontIsShown()
    if (frontIsShown) {
      setFrontIsShown(true)
    } else {
      timer.requestAnimationFrame(frameCallbackRef)
    }
  }, [calculateFrontIsShown, timer])

  const flip = React.useCallback(() => {
    // Start the animation. Set it in two phases.
    setPhase(FlipperTransitionPhase.prepare)
    setFrontIsShown(false)

    timer.clearAll()
    timer.requestAnimationFrame(frameCallbackRef)

    timer.setTimeout(() => {
      setPhase(FlipperTransitionPhase.commit)
    }, frameDuration)

    timer.setTimeout(() => {
      setPhase(null)
      timer.clearAll()
    }, duration)
  }, [duration, frameCallbackRef, timer])

  React.useLayoutEffect(() => {
    if (!startRef.current) { return }
    flip()
    startRef.current = false
  }, [children, duration, flip, flipped, prevChildren, prevFlipped, timer])

  //------
  // Body class

  const active = startRef.current || phase != null

  React.useLayoutEffect(() => {
    const addClass    = () => { document.body.classList.add('Flipper-active') }
    const removeClass = () => { document.body.classList.remove('Flipper-active') }

    if (active) {
      addClass()
    } else {
      removeClass()
    }
    return removeClass
  }, [active, phase])

  //------
  // Rendering

  const $ = useStyles({duration, timing})

  function render() {
    return (
      <div classNames={[$.Flipper, {active}, directionRef.current, phase]}>
        <div classNames={$.container} ref={containerRef}>
          {renderFront()}
          {renderBack()}
        </div>
      </div>
    )
  }

  function renderFront() {
    return (
      <div key={frontKeyRef.current} classNames={[$.face, {front: true, shown: frontIsShown}]}>
        {children}
      </div>
    )
  }

  function renderBack() {
    if (!active) { return null }

    return (
      <div key={backKeyRef.current} classNames={[$.face, {back: true, shown: !frontIsShown}]}>
        {backSideRef.current}
      </div>
    )
  }

  return render()

})

export default Flipper

enum FlipperTransitionPhase {
  prepare = 'prepare',
  commit  = 'commit',
}

const frameDuration  = 16
const defaultTiming = 'cubic-bezier(0.500, 0.390, 0.385, 1.280)'

let currentKey = 0
const nextKey = () => ++currentKey

const useStyles = createUseStyles({
  Flipper: {
    position:   'relative',
    perspective: 1200,

    '&.active': {
      overflow:      'visible',
      pointerEvents: 'none',
    },
  },

  '@global': {
    'body.Flipper-active': {
      overflow: 'hidden',
    },
  },

  container: ({duration, timing}: {duration: number, timing: string}) => ({
    willChange:  'transform',

    '$Flipper.forward.prepare &': {
      transform: 'rotateY(180deg)',
    },
    '$Flipper.reverse.prepare &': {
      transform: 'rotateY(-180deg)',
    },

    '$Flipper.commit &': {
      transform: 'rotateY(0deg)',
      transition: animation.transition(
        'transform',
        duration,
        timing,
      ),
    },
  }),

  face: {
    '&:not(.shown)': {
      ...layout.overlay,
      visibility: 'hidden',
    },

    '&.back': {
      transform: 'rotateY(180deg)',
    },

    '&.front': {
    },
  },
})