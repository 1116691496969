import React from 'react'

export function useFormOpen<T>(value: T | null, close: () => any): FormOpenHook<T> {
  const [open, setOpen] = React.useState<boolean>(value != null)
  const [actual, setActual] = React.useState<T | null>(null)

  React.useEffect(() => {
    if (value == null) {
      setOpen(false)
    } else {
      setActual(value)
      setOpen(true)
    }
  }, [value])

  const requestClose = React.useCallback(() => {
    setOpen(false)
    close()
  }, [close])

  return [open, actual, requestClose]
}

export type FormOpenHook<T> = [
  boolean,    // open
  T | null,   // current
  () => void  // onReuestClose
]