import React from 'react'

export function isModifierKey(key: string) {
  return [
    'Alt',
    'Meta',
    'Control',
    'Shift',
    'Dead',
  ].includes(key)
}

const DIGIT0  = '0'.charCodeAt(0)
const DIGIT9  = '9'.charCodeAt(0)
const LETTERA = 'A'.charCodeAt(0)
const LETTERZ = 'Z'.charCodeAt(0)
const WELL_KNOWN: Record<string, string> = {
  '`': 'Backquote',
  ' ': 'Space',
  '-': 'Minus',
  '_': 'Minus', // Note: in KeyCombination.parse(), - is changed to _ to properly split the keystroke.
  '=': 'Equal',
  '⌫': 'Backspace',
  '[': 'BracketLeft',
  ']': 'BracketRight',
  '\\': 'Backslash',
  ';': 'Semicolon',
  '\'': 'Quote',
  ',': 'Comma',
  '.': 'Period',
  '/': 'Slash',
  'Left': 'ArrowLeft',
  'Right': 'ArrowRight',
  'Up': 'ArrowUp',
  'Down': 'ArrowDown',
  '←': 'ArrowLeft',
  '→': 'ArrowRight',
  '↑': 'ArrowUp',
  '↓': 'ArrowDown',
}

export function keyToKeyCode(key: string) {
  key = key.toUpperCase()

  if (key.length === 1 && key.charCodeAt(0) >= DIGIT0 && key.charCodeAt(0) <= DIGIT9) {
    return `Digit${key}`
  }
  if (key.length === 1 && key.charCodeAt(0) >= LETTERA && key.charCodeAt(0) <= LETTERZ) {
    return `Key${key}`
  }

  return WELL_KNOWN[key] ?? key
}

export function isMac() {
  if (!('navigator' in global)) { return false }
  return navigator.userAgent.toUpperCase().includes('MAC')
}

export function isWin() {
  if (!('navigator' in global)) { return false }
  return navigator.userAgent.toUpperCase().includes('WIN')
}

export function isUnix() {
  if (!('navigator' in global)) { return false }

  const ua = navigator.userAgent.toUpperCase()
  return ua.includes('UNIX') || ua.includes('LINUX')
}

const inputTags = ['input', 'select', 'textarea']

export function isInputElement(target: EventTarget | null): target is HTMLElement {
  if (!(target instanceof HTMLElement)) { return false }

  const tagName = target.tagName.toLowerCase()
  if (tagName === 'label' && target.hasAttribute('for')) { return true }
  if (tagName === 'label' && target.querySelector(inputTags.join(', ')) != null) { return true }
  if (target.isContentEditable) { return true }
  return inputTags.includes(tagName)
}

interface ClosestOptions {
  until?: Element
}

export function closest(element: Element | EventTarget, predicate: (element: Element) => boolean, options: ClosestOptions = {}): Element | null {
  if (!(element instanceof Node)) { return null }

  for (
    let current: Node | null = element;
    current !== null;
    current = current.parentNode
  ) {
    if (current === options.until) {
      return null
    }
    if (current instanceof Element && predicate(current)) {
      return current
    }
  }

  return null
}

export function useContinuousRef<T>(current: T): React.MutableRefObject<T> {
  const ref = React.useRef<T>(current)
  ref.current = current
  return ref
}