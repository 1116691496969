import React from 'react'
import { MessageFeedback, NumericFeedback } from '~/models'
import { HBox, Label, Slider, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useChat } from '../ChatContext'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & NumericFeedback
}

const NumericFeedbackBubble = React.memo((props: Props) => {

  const {messageID, feedback} = props

  const {channel} = useChat()
  const [value, setValue] = React.useState<number | null>(null)

  //------
  // Callbacks

  const submit = React.useCallback(() => {
    if (messageID == null) { return }
    if (value == null) { return }

    channel?.sendMessage({
      type:    'text',
      text:    value?.toString(),
      replyTo: messageID,
    })
    setValue(null)
  }, [channel, messageID, value])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackBubble messageID={messageID} feedback={feedback} maySubmit={value != null} requestSubmit={submit}>
        <HBox padding={layout.padding.inline.xs} gap={layout.padding.inline.s}>
          <VBox flex={5}>
            <Slider
              value={value}
              onChange={setValue}
              min={feedback.min}
              max={feedback.max}
              step={feedback.step}
              emoji={feedback.emoji}
            />
          </VBox>
          <VBox flex={1}>
            {renderCaption()}
          </VBox>
        </HBox>
      </FeedbackBubble>
    )
  }

  function renderCaption() {
    if (feedback.step == null) { return null }

    const caption = value == null ? null : feedback.captions.find(l => l.value === value)
    const text    = caption?.caption ?? value?.toString() ?? '\u202F'

    return (
      <Label tiny align='center' box>
        {text}
      </Label>
    )
  }

  return render()

})

export default NumericFeedbackBubble