import React from 'react'
import cn from 'classnames'
import Color from 'color'
import { camelCase, mapKeys, mapValues, omit } from 'lodash'
import svgsRaw from '~/res/svgs'
import { forwardRef } from '~/ui/component'
import { createUseStyles, useTheme } from '~/ui/styling'

export interface Props extends Omit<React.SVGAttributes<any>, 'name' | 'color'> {
  name?:    SVGName
  svg?:     any

  size?:    Size | string
  inline?:  boolean

  title?:   string

  color?:     Color | null
  dim?:       boolean
  dimmer?:    boolean
  primary?:   boolean
  secondary?: boolean

  classNames?: React.ClassNamesProp
}

export type SVGName = keyof typeof svgsRaw & string

const svgs = mapValues(svgsRaw, parseSVG)

const SVG = forwardRef('SVG', (props: Props, ref: React.Ref<SVGSVGElement>) => {

  const {
    name,
    size,
    inline,
    color: props_color,
    dim,
    dimmer,
    primary,
    secondary,
    style = {},
    classNames,
    ...rest
  } = props

  const $ = useStyles()
  const theme = useTheme()

  if (props.svg == null && name == null) {
    throw new Error('Need either name or svg')
  }

  const svg = React.useMemo(() => {
    if (props.svg == null) {
      return svgs[name!]
    } else if (typeof props.svg === 'string') {
      return parseSVG(props.svg)
    }

    return props.svg
  }, [name, props.svg])

  const color = React.useMemo(() => {
    if (props_color != null) {
      return props_color
    } else if (primary) {
      return theme.semantic.primary
    } else if (secondary) {
      return theme.semantic.secondary
    } else if (dim) {
      return theme.fg.dim
    } else if (dimmer) {
      return theme.fg.dimmer
    } else {
      return theme.fg.normal
    }
  }, [dim, dimmer, primary, props_color, secondary, theme.fg.dim, theme.fg.dimmer, theme.fg.normal, theme.semantic.primary, theme.semantic.secondary])

  //------
  // Rendering

  function render() {
    if (svg == null) {
      return renderNotFound()
    }

    const styles = {
      color: color.string(),
      ...style,
    }

    const element = (
      <svg
        classNames={cn($.svg, classNames, inline ? $.inline : $.block)}
        style={styles}
        dangerouslySetInnerHTML={{__html: svg.content}}
        {...omit(mapKeys(svg.attributes, (_, key) => camelCase(key)), 'xmlns:xlink', 'style')}
        {...omit(rest, 'color', 'svg')}
        width={width}
        height={height}
        ref={ref}
      />
    )

    return element
  }

  const [width, height] = React.useMemo((): [number | string | undefined, number | string | undefined] => {
    if (size === undefined) {
      return [undefined, undefined]
    } else if (typeof size === 'number' || typeof size === 'string') {
      return [size, size]
    } else {
      return [size.width, size.height]
    }
  }, [size])

  function renderNotFound() {
    return (
      <svg
        classNames={cn($.svg, classNames, inline ? $.inline : $.block)}
        style={style}
        viewBox='0 0 64 64'
        {...omit(rest, 'color', 'svg')}
        width={width}
        height={height}
      >
        <rect fill='#D30000'  stroke='#FFFFFF' strokeWidth='2' x='0' y='0' width='64' height='64'/>
        <path d='M1,1 L63,63' stroke='#FFFFFF' strokeWidth='2' strokeLinecap='square'/>
        <path d='M63,1 L1,63' stroke='#FFFFFF' strokeWidth='2' strokeLinecap='square'/>
      </svg>
    )
  }

  return render()

})

export default SVG

export function svgExists(name: string): name is SVGName {
  return (svgs as any)[name] != null
}

export function listSVGs() {
  return Object.entries(svgs).map(([name]) => name) as SVGName[]
}

function parseSVG(svgString: string): {attributes: Record<string, any>, content: string} {
  if (typeof document === 'undefined') { return {attributes: {}, content: ''} }

  const div = document.createElement('div')
  div.innerHTML = svgString

  const svg = div.querySelector('svg')!
  return {
    attributes: Array.from(svg.attributes).reduce((attrs, attr) => ({...attrs, [attr.name]: attr.value}), {}),
    content:    svg.innerHTML,
  }
}

const useStyles = createUseStyles(theme => ({
  svg: {
    position: 'relative',

    color: theme.fg.normal,
    '&, & :not([fill])': {
      fill: 'currentcolor',
    },
  },

  block: {
    display: 'block',
  },

  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}))