import { makeObservable, observable, runInAction } from 'mobx'
import { FetchStatus } from 'mobx-document'
import config from '~/config'
import { register } from './support'

export class LanguagesStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  public all: Language[] = []

  @observable
  public common: string[] = []

  @observable
  public status: FetchStatus = 'idle'

  public async init() {
    await this.loadLanguages()
  }

  public async loadLanguages() {
    const response = await fetch(config.urls.api + '/languages.json')
    if (response.status !== 200) {
      runInAction(() => {
        this.status = new Error(`Unable to load languages: ${response.status}`)
        this.all = []
        this.common = []
      })
    }

    try {
      const json = await response.json()
      runInAction(() => {
        this.status = 'done'
        this.all = json.all
        this.common = json.common
      })
    } catch (error) {
      runInAction(() => {
        this.status = error instanceof Error ? error : new Error(`${error}`)
        this.all = []
        this.common = []
      })
    }
  }

  public getLanguage(code: string, fakeIfNotFound: true): Language
  public getLanguage(code: string, fakeIfNotFound?: boolean): Language | null
  public getLanguage(code: string, fakeIfNotFound: boolean = false) {
    const language = this.all.find(it => it.code === code)
    if (language != null) {
      return language
    } else if (fakeIfNotFound) {
      return {code, name_en: code, name_loc: code}
    } else {
      return null
    }
  }

  public getLanguageName(code: string, localName: boolean = true) {
    const language = this.getLanguage(code)
    if (language == null) { return null }

    return localName ? language.name_loc : language.name_en
  }

}

export interface Language {
  code: string
  name_en: string
  name_loc: string
}

const languagesStore = register(new LanguagesStore())
export default languagesStore