import React from 'react'
import { BeWizrCourseItem } from '~/models'
import { memo } from '~/ui/component'
import { ListBar, SVG } from '~/ui/components'
import { layout } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  item: BeWizrCourseItem
}

const BeWizrCourseItemSearchResult = memo('BeWizrCourseItemSearchResult', (props: Props) => {

  const {item} = props

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        caption={item.title}
        detail={item.courseTitle}
        accessory={<SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        href={item.links.elearning}
      />
    )
  }

  return render()

})

register('bewizr-course-items', BeWizrCourseItemSearchResult)