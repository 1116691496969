import React from 'react'
import { isPlainObject } from 'lodash'
import { memo } from '~/ui/component'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { Label } from './label'
import { HBox, VBox } from './layout'
import SVG, { SVGName } from './SVG'

export interface Props {
  items:  TimelineItem[]
  large?: boolean
}

const Timeline = memo('Timeline', (props: Props) => {

  const {items, large = false} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.container, {large}]} gap={large ? gap.large : gap.normal}>
        {renderList()}
      </VBox>
    )
  }

  function renderList() {
    if (items.length === 0) { return null }
    return items.map((item, index) => (
      <TimelineRow
        key={index}
        item={item}
        nextCompleted={items[index + 1]?.completed ?? false}
        large={large}
      />
    ))
  }

  return render()

})

export default Timeline

export interface TimelineRowProps {
  item:          TimelineItem
  nextCompleted: boolean
  large:         boolean
}

const TimelineRow = memo('TimelineRow', (props: TimelineRowProps) => {

  const {item, large, nextCompleted} = props

  const {completed = false, icon = 'check'} = item

  const {colors} = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={[$.row, {large, completed, nextCompleted}]} gap={layout.padding.inline.l} align='middle'>
        {renderCheck()}
        {renderContent()}
      </HBox>
    )
  }

  function renderCheck() {
    return (
      <VBox classNames={$.check} align='center' justify='middle'>
        <SVG
          name={icon}
          size={large ? layout.icon.m : layout.icon.s}
          color={completed ? colors.named.white : colors.fg.light.dim}
        />
      </VBox>
    )
  }

  function renderContent() {
    if ('render' in item) {
      return (
        <VBox flex>
          {item.render(completed)}
        </VBox>
      )
    }
    return (
      <VBox gap={layout.padding.inline.xs}>
        {renderLabel(item.caption, false)}
        {item.detail && renderLabel(item.detail, true)}
      </VBox>
    )
  }

  function renderLabel(label: TimelineItemLabel, detail: boolean) {
    if (isPlainObject(label)) {
      label = (label as any)[completed ? 'complete' : 'incomplete']
    }
    if (!isReactText(label)) { return label }

    return (
      <Label
        bold={!detail && completed && large}
        small={(!large && !detail) || (large && detail)}
        tiny={!large && detail}
        dim={detail || !completed}>
        {label}
      </Label>
    )
  }

  return render()

})

export type TimelineItem = StandardTimelineItem | CustomTimelineItem

export interface StandardTimelineItem extends TimelineItemCommon {
  caption?:   TimelineItemLabel
  detail?:    TimelineItemLabel
}

export interface CustomTimelineItem extends TimelineItemCommon {
  render: (completed: boolean) => React.ReactNode
}

export interface TimelineItemCommon {
  completed?: boolean
  icon?:      SVGName
}

export type TimelineItemLabel = React.ReactNode | {incomplete?: React.ReactNode, complete?: React.ReactNode}

export const height = 60

export const checkboxSize = {
  normal: {
    width:  20,
    height: 20,
  },
  large: {
    width:  30,
    height: 30,
  },
}

export const gap = {
  normal: layout.padding.inline.m,
  large:  layout.padding.inline.l,
}

const useStyles = createUseStyles(theme => ({
  container:  {
  },

  row: {
    minHeight:  height,
    position: 'relative',

    '&:before, &:after': {
      content: '""',
      width: 2,
      left: checkboxSize.normal.width / 2 -1,
      position: 'absolute',
      backgroundColor: theme.colors.grayScale.dark[3],
    },

    '&:before': {
      top: 0,
      bottom: '50%',
    },

    '&:after': {
      top: '50%',
      bottom: gap.normal * -1,
    },

    '&:first-child:before': {
      top: '50%',
    },

    '&:first-child:before, &:last-child:after': {
      display: 'none',
    },

    '&.large': {
      '&:before, &:after': {
        left:  checkboxSize.large.width / 2 -1,
        width: 3,
      },

      '&:after': {
        bottom: gap.large * -1,
      },

      '& $check': {
        ...checkboxSize.large,
      },
    },

    '&.completed': {
      '& $check, &:before': {
        backgroundColor: theme.colors.semantic.positive,
      },
    },

    '&.nextCompleted:after': {
      backgroundColor: theme.colors.semantic.positive,
    },
  },

  check: {
    ...checkboxSize.normal,
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: theme.colors.grayScale.dark[3],
    zIndex: 10,
  },
}))