import React from 'react'
import { ContentBlock, ContentState } from 'draft-js'
import { memo } from '~/ui/component'
import ImageBlock from './ImageBlock'
import WidgetBlock from './WidgetBlock'

export interface Props {
  block:        ContentBlock
  contentState: ContentState
}

const AtomicBlock = memo('AtomicBlock', (props: Props) => {

  const {block, contentState} = props

  const key    = block.getEntityAt(0)
  const entity = key == null ? null : contentState.getEntity(key)
  const type   = entity?.getType()

  switch (type) {
    case 'IMAGE':
      return <ImageBlock {...props}/>
    case 'WIDGET':
      return <WidgetBlock {...props}/>
    default:
      return null
  }

})

export default AtomicBlock