import React from 'react'
import { some } from 'lodash'
import {
  ChoiceFeedback,
  FeedbackChoice,
  feedbackChoiceValue,
  isMultiSelectChoiceFeedback,
  MessageFeedback,
  validateFeedback,
} from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, RadioButton, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useChat } from '../ChatContext'
import FeedbackBubble from './FeedbackBubble'

export interface Props {
  messageID: string | null
  feedback:  MessageFeedback & ChoiceFeedback
}

const ChoiceFeedbackBubble = React.memo((props: Props) => {

  const {messageID, feedback} = props
  const multiSelect = isMultiSelectChoiceFeedback(feedback)

  const [choices, setChoices] = React.useState<FeedbackChoice[]>([])
  const [choice, setChoice]   = React.useState<FeedbackChoice | null>(null)

  const {channel} = useChat()

  //------
  // Callbacks

  const selectValue = React.useCallback((choice: FeedbackChoice) => {
    if (!multiSelect) {
      setChoice(choice)
    } else if (some(choices, it => feedbackChoiceValue(it) === feedbackChoiceValue(choice))) {
      setChoices(choices.filter(it => feedbackChoiceValue(it) !== feedbackChoiceValue(choice)))
    } else {
      setChoices([...choices, choice])
    }
  }, [choices, multiSelect])

  const choiceSelected = React.useCallback((test: FeedbackChoice) => {
    if (multiSelect) {
      return some(choices, it => feedbackChoiceValue(it) === feedbackChoiceValue(test))
    } else {
      return choice != null &&  feedbackChoiceValue(test) === feedbackChoiceValue(choice)
    }
  }, [choice, choices, multiSelect])

  const submit = React.useCallback(() => {
    if (messageID == null) { return }

    channel?.sendMessage({
      type:    'text',
      text:    multiSelect ? choices.map(it => (it.caption ?? (it as any).label)).join('\n') : choice?.caption ?? (choice as any)?.label,
      replyTo: messageID,
    })
  }, [channel, choice, choices, messageID, multiSelect])

  const maySubmit = React.useMemo(() => {
    if (multiSelect) {
      return validateFeedback(feedback, choices.map(it => it.caption ?? (it as any).label))
    } else {
      return choice != null && validateFeedback(feedback, choice.caption ?? (choice as any).label)
    }
  }, [choice, choices, feedback, multiSelect])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackBubble messageID={messageID} feedback={feedback} requestSubmit={submit} maySubmit={maySubmit}>
        {renderChoices()}
      </FeedbackBubble>
    )
  }

  function renderChoices() {
    return (
      <VBox gap={layout.padding.inline.s}>
        {feedback.choices.map((choice, index) => (
          <ChoiceRow
            key={index}
            choice={choice}
            selected={choiceSelected(choice)}
            onSelect={selectValue}
          />
        ))}
      </VBox>
    )
  }

  return render()

})

interface ChoiceRowProps {
  choice:   FeedbackChoice
  selected: boolean
  onSelect: (choice: FeedbackChoice) => any
}

interface ChoiceRowProps {
  choice:   FeedbackChoice
  selected: boolean
  onSelect: (choice: FeedbackChoice) => any
}

const ChoiceRow = memo('ChoiceRow', (props: ChoiceRowProps) => {

  const {choice, selected, onSelect} = props
  const {guide} = useStyling()

  const select = React.useCallback(() => {
    onSelect(choice)
  }, [onSelect, choice])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent branding={guide.feedback.choiceRow} variant={{selected}} height={choiceRowMinHeight}>
        <Tappable onTap={select} classNames={$.button}>
          {renderContent()}
        </Tappable>
      </BrandedComponent>
    )
  }

  function renderContent() {
    return (
      <HBox gap={layout.padding.inline.m} flex='grow'>
        <RadioButton
          checked={selected}
          branding={guide.feedback.choiceRowRadioButton}
        />
        <VBox flex>
          <Label bold truncate={false}>
            {choice.caption}
          </Label>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ChoiceFeedbackBubble

const choiceRowMinHeight = 32

const useStyles = createUseStyles({
  button: {
    padding: [layout.padding.inline.m, choiceRowMinHeight / 3],
  },
})