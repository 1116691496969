import React from 'react'
import YouTube from 'react-youtube'
import { MessageListItem } from '~/models'
import { createUseStyles } from '~/ui/styling'
import ChatBubble from './ChatBubble'

export interface Props {
  message:     MessageListItem & {type: 'text'}
  inlineReply: MessageListItem | null
}

const YouTubeBubble = React.memo((props: Props) => {

  const {message, inlineReply} = props

  const text  = message.text ?? ''
  const videoID = React.useMemo(() => extractYouTubeVideoID(text), [text])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ChatBubble message={message} inlineReply={inlineReply}>
        {videoID != null && (
          <YouTube
            videoId={videoID}
            className={$.youtube}
          />
        )}
      </ChatBubble>
    )
  }

  return render()

})

const videoSize = {
  width: 260,
  height: 146,
}

const shouldUse = (message: MessageListItem): message is MessageListItem & {type: 'text'} => {
  const id = extractYouTubeVideoID(message.text ?? '')
  return id != null
}

export function extractYouTubeVideoID(text: string) {
  const match = text.trim().match(/^https:\/\/[^/]*(?:youtube\.com|youtu\.be)\/watch\?(.+)/i)
  const params = new URLSearchParams(match?.[1] ?? '')
  return params.get('v') ?? null
}

Object.assign(YouTubeBubble, {shouldUse})
export default YouTubeBubble as typeof YouTubeBubble & {
  shouldUse: typeof shouldUse
}

const useStyles = createUseStyles({
  youtube: {
    ...videoSize,

    '& iframe': {
      display: 'block',
      ...videoSize,
    },
  },
})