// @index(!registry): import ${relpath}
import './SignupWidget'
import React from 'react'
import { getWidgetComponent } from './registry'

// /index

export * from './parse'

export default function renderWidget(widget: string, params: Record<string, any>) {
  const Component = getWidgetComponent(widget)
  if (Component == null) {
    console.warn(`Cannot find widget component for widget "${widget}"`)
    return null
  }

  return React.createElement(Component, {params})
}