import React from 'react'
import { acceptedFeedbackMediaMimeTypes, Media, MediaFeedback } from '~/models'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { Panel } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  feedback: MediaFeedback

  value?:    string
  onChange?: (value: string) => any
  onCommit?: () => any
}

const MediaAnswerEntry = memo('MediaAnswerEntry', (props: Props) => {

  const {feedback, value, onChange} = props

  const accept = acceptedFeedbackMediaMimeTypes(feedback.mediaTypes)

  const handleUploadComplete = React.useCallback((media: Media) => {
    onChange?.(media.url)
  }, [onChange])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <MediaUploader
        accept={accept}
        previewURL={value}
        renderContent={renderContent}
        onUploadComplete={handleUploadComplete}
      />
    )
  }

  function renderContent(state: MediaUploaderState) {
    return (
      <Panel classNames={$.mediaAnswerEntry} semi={false}>
        {state.renderDropHint()}
        {state.renderPreview()}
        {state.renderUploading()}
      </Panel>
    )
  }

  return render()

})

export default MediaAnswerEntry

export const mediaAnswerEntrySize = {
  width:  layout.columnWidth - 2 * layout.padding.inline.l,
  height: layout.columnWidth - 2 * layout.padding.inline.l,
}

const useStyles = createUseStyles({
  mediaAnswerEntry: {
    maxWidth: '100%',
    ...mediaAnswerEntrySize,
  },
})