import React from 'react'
import { DragDropMonitorContext } from './DragDropContext'

const DefaultGhost = React.memo(() => {

  const {monitor} = React.useContext(DragDropMonitorContext)

  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const elementRef = React.useRef<Node | null>(null)

  const setUp = React.useCallback(() => {
    if (monitor == null) { return }

    elementRef.current = monitor.cloneSourceElement()
    containerRef.current?.appendChild(elementRef.current)
  }, [monitor])

  const tearDown = React.useCallback(() => {
    if (monitor == null) { return }
    if (elementRef.current == null) { return }

    containerRef.current?.removeChild(elementRef.current)
    elementRef.current = null
  }, [monitor])

  const connectContainer = React.useCallback((element: HTMLDivElement | null) => {
    if (element == null) {
      tearDown()
      containerRef.current = null
    } else {
      containerRef.current = element
      setUp()
    }
  }, [setUp, tearDown])

  //------
  // Rendering

  function render() {
    return (
      <div
        className='react-dnd--DefaultGhost'
        ref={connectContainer}
      />
    )
  }

  return render()

})

export default DefaultGhost