import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { Media, Model, Participant } from '~/models'
import { StoreMediaOptions } from '~/stores'
import { Avatar } from '~/ui/app/media'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import {
  Center,
  ClearButton,
  VBox,
  ConfirmBox,
  Label,
  PopupMenu,
  Spinner,
  Uploader,
} from '~/ui/components'
import { PopupMenuItem } from '~/ui/components/popup-menu'
import { colors, createUseStyles, layout, shadows, useStyling } from '~/ui/styling'

export interface Props {
  participant: Participant
  size:        Size

  requestUpdate: (media: Media | null) => Promise<any>
}

export interface ProfilePhotoModel extends Model {
  name:       string
  photoURL:   string | null

  firstName?: string
  lastName?:  string
  email?:     string
}

const ProfilePhotoField = memo('ProfilePhotoField', (props: Props) => {

  const {participant, size, requestUpdate} = props

  const [t] = useTranslation('participants')

  const uploaderRef = React.useRef<Uploader>(null)

  const {colors} = useStyling()

  const options = React.useMemo((): StoreMediaOptions => ({
    convert: {'image/*': 'image/jpeg'},
  }), [])

  //------
  // Rendering

  const $ = useStyles(size)

  function render() {
    return (
      <VBox align='center' gap={layout.padding.inline.s}>
        <MediaUploader
          uploaderRef={uploaderRef}
          accept='image/*'
          storeMediaOptions={options}
          onUploadComplete={handleUploadComplete}
          renderContent={renderContent}
          noClick
        />
        <PopupMenu items={actionMenuItems} crossAlign='center'>
          {toggle => (
            <ClearButton
              icon='pencil'
              caption={t('profile.edit_photo')}
              onTap={toggle}
            />
          )}
        </PopupMenu>
      </VBox>
    )
  }

  function renderContent(state: MediaUploaderState) {
    return (
      <VBox classNames={$.dropzoneContent}>
        <Avatar
          source={participant.photoURL}
          firstName={participant.firstName}
          lastName={participant.lastName}
          size={size}
        />
        {state.isDragAccept ? (
          renderHint('accept')
        ) : state.isDragReject ? (
          renderHint('reject')
        ) : state.isDragActive ? (
          renderHint('active')
        ) : null}
        {(state.uploading || updating) && (
          <Center classNames={$.working}>
            <Spinner/>
          </Center>
        )}
      </VBox>
    )
  }

  function renderHint(type: string) {
    return (
      <VBox justify='middle' classNames={[$.dropHint, type]} flex gap={layout.padding.inline.m}>
        <Label h2 align='center'>
          {t(`profile.photo_drop_hint.${type}.title`)}
        </Label>
        <Label dim small align='center' truncate={false}>
          {t(`profile.photo_drop_hint.${type}.detail`)}
        </Label>
      </VBox>
    )
  }

  //------
  // Popup menu

  const timer = useTimer()
  const [updating, setUpdating] = React.useState<boolean>(false)

  const updatePhoto = React.useCallback(async (media: Media | null) => {
    try {
      setUpdating(true)
      await timer.await(requestUpdate(media))
    } finally {
      setUpdating(false)
    }
  }, [requestUpdate, timer])

  const handleUploadComplete = React.useCallback((media: Media | null) => {
    if (media == null) { return }
    return updatePhoto(media)
  }, [updatePhoto])

  const upload = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('profile.remove_photo.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    return updatePhoto(null)
  }, [t, updatePhoto])

  const actionMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'camera',
      caption:  t('profile.upload_photo'),
      onSelect: upload,
    })

    if (participant.photoURL != null) {
      items.push({
        icon:     'trash',
        caption:  t('profile.remove_photo.caption'),
        color:    colors.semantic.negative,
        onSelect: remove,
      })
    }

    return items
  }, [colors.semantic.negative, participant.photoURL, remove, t, upload])

  return render()

})

export default ProfilePhotoField

const useStyles = createUseStyles(theme => ({
  dropzoneContent: (size: Size) => ({
    ...size,
    borderRadius: size.height / 2,

    boxShadow: shadows.depth(1),
  }),

  dropHint: (size: Size) => ({
    ...layout.overlay,
    borderRadius: size.height / 2,

    background: colors.white.alpha(0.2),
    padding:    layout.padding.inline.l,

    '&.accept': {
      background: theme.colors.semantic.positive.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
    '&.reject': {
      background: theme.colors.semantic.negative.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
  }),

  working: (size: Size) => ({
    ...layout.overlay,
    borderRadius: size.height / 2,
    background:   colors.shim.white,
  }),
}))