import * as MIME from 'mime-types'

export function isVideoUrl(url: string) {
  const mimeType = MIME.lookup(url)
  if (mimeType === false) { return false }

  return mimeType.startsWith('video/')
}

export function extractVideoPoster(video: Blob | string, size: Size, options: ExtractVideoPosterOptions = {}) {
  const {
    atTime,
    quality,
  } = {
    ...ExtractVideoPosterOptions.defaults,
    ...options,
  }

  let source = typeof video === 'string' ? video : URL.createObjectURL(video)

  return new Promise<Blob>((resolve, reject) => {
    const video = document.createElement('video')
    video.src   = source

    video.crossOrigin = 'anonymous'
    video.load()

    video.addEventListener('error', reject)
    video.addEventListener('loadedmetadata', () => {
      const time = Math.min(atTime, video.duration)
      video.currentTime = time
    })
    video.addEventListener('seeked', () => {
      const canvas  = document.createElement('canvas')
      canvas.width  = size.width
      canvas.height = size.height

      const context = canvas.getContext('2d')
      if (context != null) {
        context?.drawImage(
          video,
          0, 0,
          video.videoWidth, video.videoHeight,
          0, 0,
          canvas.width, canvas.height,
        )
      }

      canvas.addEventListener('error', reject)
      canvas.toBlob(blob => {
        if (blob != null) {
          resolve(blob)
        } else {
          reject(new Error("Could not extract poster image"))
        }
      }, 'image/jpeg', quality)
    })
  }).finally(() => {
    if (typeof video !== 'string') {
      URL.revokeObjectURL(source)
    }
  })
}

export interface ExtractVideoPosterOptions {
  atTime?:  number
  quality?: number
}

const ExtractVideoPosterOptions: {
  defaults: Required<ExtractVideoPosterOptions>
} = {
  defaults: {
    atTime:  0.0,
    quality: 0.75,
  },
}