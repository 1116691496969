import React from 'react'
import { DraftBlockType, DraftInlineStyleType } from 'draft-js'
import { observer } from '~/ui/component'
import { ClearButton, Dimple, HBox, Toolbar } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import { useRichTextBackend } from './RichTextFieldContext'
import { EditorMode, RichTextScope } from './types'

export interface Props {
  editorMode:    EditorMode | null
  setEditorMode: ((mode: EditorMode) => void) | null

  renderRight?:       () => React.ReactNode

  scope:               RichTextScope

  headingButtons?:     boolean
  listButtons?:        boolean
  requestInsertMedia?: () => any
  requestInsertLink?:  () => any
}

const RichTextFieldToolbar = observer('RichTextFieldToolbar', (props: Props) => {

  const {
    renderRight,
    editorMode,
    setEditorMode,
    scope,
    headingButtons = scope === 'block',
    listButtons = scope === 'block',
    requestInsertMedia,
    requestInsertLink,
  } = props

  const backend = useRichTextBackend()

  //------
  // Actions

  const toggleBlockType = React.useCallback((type: DraftBlockType) => {
    backend?.toggleBlockType(type)
  }, [backend])

  const toggleInlineStyle = React.useCallback((style: DraftInlineStyleType) => {
    backend?.toggleInlineStyle(style)
  }, [backend])

  const switchToMarkdown = React.useCallback(() => {
    setEditorMode?.('markdown')
  }, [setEditorMode])

  const switchToWYSIWYG = React.useCallback(() => {
    setEditorMode?.('wysiwyg')
  }, [setEditorMode])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Toolbar>
        <HBox>
          {renderStyleButtons()}
        </HBox>
        <HBox gap={layout.padding.inline.m}>
          {renderModeButton()}
          {renderRight?.()}
        </HBox>
      </Toolbar>
    )
  }

  function renderStyleButtons() {
    return(
      <>
        {renderHeadingButtons()}
        {renderInlineStyleButtons()}
        {renderListButtons()}
        {renderInsertButtons()}
      </>
    )
  }

  function renderHeadingButtons() {
    if (!headingButtons) { return null }

    return (
      <HBox>
        {renderBlockTypeButton('paragraph', 'p')}
        {renderBlockTypeButton('header-one', 'h1')}
        {renderBlockTypeButton('header-two', 'h2')}
        <Dimple vertical/>
      </HBox>
    )
  }

  function renderInlineStyleButtons() {
    return (
      <HBox>
        {renderInlineStyleButton('BOLD', 'bold')}
        {renderInlineStyleButton('ITALIC', 'italic')}
      </HBox>
    )
  }

  function renderListButtons() {
    if (!listButtons) { return null }

    return (
      <HBox>
        <Dimple vertical/>
        {renderBlockTypeButton('unordered-list-item', 'unordered-list')}
        {renderBlockTypeButton('ordered-list-item', 'ordered-list')}
      </HBox>
    )
  }

  function renderInsertButtons() {
    const mediaButton = requestInsertMedia == null ? null : (
      <ClearButton
        icon='image'
        onTap={requestInsertMedia}
        classNames={$.toolbarButton}
        padding='both'
      />
    )
    const linkButton = requestInsertLink == null ? null : (
      <ClearButton
        icon='link'
        onTap={requestInsertLink}
        classNames={$.toolbarButton}
        padding='both'
      />
    )

    if (mediaButton == null && linkButton == null) {
      return null
    } else {
      return (
        <HBox>
          <Dimple vertical/>
          {mediaButton}
          {linkButton}
        </HBox>
      )
    }
  }

  function renderBlockTypeButton(type: DraftBlockType, icon: SVGName) {
    return (
      <Toolbar.Button
        icon={icon}
        onTap={toggleBlockType.bind(null, type)}
        active={backend?.currentBlockType === type}
      />
    )
  }

  function renderInlineStyleButton(style: DraftInlineStyleType, icon: SVGName) {
    return (
      <Toolbar.Button
        icon={icon}
        onTap={toggleInlineStyle.bind(null, style)}
        active={backend?.currentInlineStyle.has(style)}
      />
    )
  }

  function renderModeButton() {
    if (editorMode == null || setEditorMode == null) { return null }

    return (
      <HBox>
        {editorMode === 'wysiwyg' ? (
          <Toolbar.Button
            icon='code'
            onTap={switchToMarkdown}
          />
        ) : (
          <Toolbar.Button
            icon='eye'
            onTap={switchToWYSIWYG}
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default RichTextFieldToolbar

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  toolbarButton: {
    '&.active': {
      background: theme.inverse.bg.subtle,
      boxShadow:  ['inset', 1, 1, 1, shadows.shadowColor.alpha(0.2)],
    },
  },
}))