import { action, makeObservable, observable } from 'mobx'
import config from '~/config'
import { Media, Post } from '~/models'
import { NewsService } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class PostFormModel implements FormModel {

  constructor(
    private readonly service: NewsService,
    private readonly post: Post | null,
  ) {
    if (this.post?.feed.id != null) {
      this.feed = this.post.feed.id
    } else if (this.service.openFeeds.length === 1) {
      this.feed = this.service.openFeeds[0].id
    }

    makeObservable(this)
  }

  @observable
  public feed: string | null = null

  @observable
  public body: string = this.post?.body ?? ''

  @observable
  public notify: boolean = false

  //------
  // Media

  public get mayAddMedia() {
    return this.media.length < config.news.maxMediaCount
  }

  @observable
  public media: Media[] = this.post?.media ?? []

  @action
  public addMedia(media: Media) {
    if (!this.mayAddMedia) { return }
    this.media = [...this.media, media]
  }

  @action
  public removeMedia(media: Media) {
    this.media = this.media.filter(it => it.id !== media.id)
  }

  //------
  // Submit

  public get maySubmit() {
    if (this.body.trim().length > 1) { return true }
    if (this.media.length > 0) { return true }
    if (this.feed != null) { return true }

    return false
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const data = {
      body:  this.body,
      media: this.media.map(it => it.id),
    }

    if (this.feed == null) { return }

    if (this.post == null) {
      return this.service.addPost(this.feed, data, this.notify)
    } else {
      return this.service.updatePost(this.post.id, data)
    }
  }

}