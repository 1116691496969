import * as layout from '../../layout'
import { ComponentBranding } from '../components'
import { BrandedComponentSpec, BrandingGuideDelegate } from '../types'

export default class PanelBranding extends ComponentBranding {

  constructor(
    delegate: BrandingGuideDelegate,
    parent: PanelBranding | null,
    defaults: Partial<BrandedComponentSpec> = {},
  ) {
    super(delegate,parent, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
      ...defaults,
    })

    this.header = new ComponentBranding(delegate, parent?.header ?? null, {
      background: 'alt',
      border:     'none',
      shape:      {rounded: layout.radius.m},
      depth:      1,
    })

    if (parent == null) {
      this.variant('semi', {
        background: 'semi',
      })
    }
  }

  public depth(flags?: Record<string, boolean>) {
    return this.resolve('depth', flags)
  }

  public header: ComponentBranding

  public minHeight: number = 32
  public bandWidth: number = 5

}