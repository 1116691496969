import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { Post } from '~/models'
import { observer } from '~/ui/component'
import {
  BrandedComponent,
  ClearButton,
  HBox,
  Label,
  SVG,
  Tappable,
  TextBlock,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout, presets, useStyling } from '~/ui/styling'
import { findLinks } from '~/ui/util'
import CommentList from './CommentList'
import { useNewsService } from './NewsContext'
import PostAttachmentList from './PostAttachmentList'
import PostHeader from './PostHeader'
import PostMediaGallery from './PostMediaGallery'
import PostReactionButton from './PostReactionButton'

export interface Props {
  post: Post

  commentsExpanded?:      boolean
  requestToggleComments?: (post: Post) => any

  requestEdit?: (post: Post) => any
}

const PostView = observer('PostView', (props: Props) => {

  const {
    post,
    commentsExpanded,
    requestToggleComments,
    requestEdit,
  } = props

  const {guide} = useStyling()

  const [t] = useTranslation('news')

  const service  = useNewsService()
  const document = service?.posts.document(post.id)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const scrollIntoView = React.useCallback(() => {
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block:    'start',
    })
  }, [])

  const toggleComments = React.useCallback(() => {
    if (!commentsExpanded) {
      scrollIntoView()
    }
    requestToggleComments?.(post)
  }, [commentsExpanded, post, requestToggleComments, scrollIntoView])

  const links = React.useMemo(
    () => findLinks(post.body ?? ''),
    [post],
  )

  const galleryMedia = React.useMemo(
    () => post.media.filter(it => /^(image|video)\//.test(it.contentType)),
    [post],
  )

  const attachmentMedia = React.useMemo(
    () => post.media.filter(it => !/^(image|video)\//.test(it.contentType)),
    [post],
  )

  const reactions = React.useMemo(() => (
    Object.entries(post.reactions)
      .filter(
        ([reaction]) => config.news.allowedReactions.includes(reaction),
      )
      .sort(
        (a, b) => b[1] - a[1],
      )
  ), [post.reactions])

  const reactionCount = React.useMemo(
    () => reactions.reduce((total, [_, count]) => total + count, 0),
    [reactions],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent branding={guide.feed.post} height={minPostHeight} classNames={$.PostView} >
        <VBox gap={layout.padding.l} ref={containerRef}>
          <VBox gap={layout.padding.s}>
            <PostHeader
              post={post}
              requestEdit={requestEdit}
            />
            {renderBody()}
            {renderMedia()}
            {renderFooter()}
          </VBox>
          {commentsExpanded && renderComments()}
        </VBox>
      </BrandedComponent>
    )
  }

  function renderBody() {
    if (post.body == null) { return null }

    return (
      <VBox>
        <span>
          <TextBlock markup links={links} tag='span'>
            {post.body}
          </TextBlock>
          {post.edited && (
            <Label tag='span' small dim>
              {` ${t('edited')}`}
            </Label>
          )}
        </span>
      </VBox>
    )
  }

  function renderMedia() {
    return (
      <>
        {galleryMedia.length > 0 && (
          <VBox classNames={$.mediaContainer}>
            <PostMediaGallery
              media={galleryMedia}
            />
          </VBox>
        )}
        {attachmentMedia.length > 0 && (
          <PostAttachmentList
            media={attachmentMedia}
          />
        )}
      </>
    )
  }

  function renderFooter() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <HBox gap={layout.padding.inline.m} justify='space-between'>
          {renderReactions()}
          {requestToggleComments != null ? (
            <ClearButton onTap={toggleComments} small>
              <Label small dim>
                {t('post.comments', {count: post.commentCount})}
              </Label>
            </ClearButton>
          ) : (
            <Label small dim>
              {t('post.comments', {count: post.commentCount})}
            </Label>
          )}
        </HBox>
        {renderInteractionButtons()}
      </VBox>
    )
  }

  function renderReactions() {
    if (reactions.length === 0) { return null }

    return (
      <HBox gap={layout.padding.inline.s}>
        <HBox gap={layout.padding.inline.s}>
          {reactions.map(([reaction]) => (
            <span key={reaction}>{reaction}</span>
          ))}
        </HBox>
        <Label dim tiny children={reactionCount} />
      </HBox>
    )
  }

  function renderInteractionButtons() {
    return (
      <HBox classNames={[$.interactionButtons, {commentsExpanded}]}  align='stretch'>
        {renderReactionButtons()}
        {renderCommentButton()}
      </HBox>
    )
  }

  function renderReactionButtons() {
    return (
      <HBox flex>
        {config.news.allowedReactions.map(reaction => (
          <VBox key={reaction} flex classNames={$.reactionButton}>
            <PostReactionButton post={post} reaction={reaction} />
          </VBox>
        ))}
      </HBox>
    )
  }

  function renderCommentButton() {
    return (
      <Tappable classNames={$.commentButton} onTap={toggleComments} flex>
        <HBox gap={layout.padding.inline.m} justify='center' flex align='middle'>
          <SVG name='comment' dim size={layout.icon.m} />
          <Label caption dim children={t('post.comment')} />
        </HBox>
      </Tappable>
    )
  }

  function renderComments() {
    if (document == null) { return null }

    return (
      <CommentList
        post={post}
        endpoint={document.comments}
      />
    )
  }

  return render()

})

export default PostView

export const avatarSize = {
  width:  24,
  height: 24,
}

export const minPostHeight = 72

const useStyles = createUseStyles(theme => ({
  PostView: {
    padding:  [layout.padding.inline.m, layout.padding.inline.l],
    overflow: 'hidden',
    width: layout.contentWidth,
    maxWidth: '100%',
  },

  mediaContainer: {
    margin: [0, -layout.padding.inline.l],
  },

  edited: {
    fontStyle: 'italic',
  },

  interactionButtons: {
    borderTop:    [1, 'solid', theme.colors.fg.light.dim],
    marginLeft:   -layout.padding.inline.l,
    marginRight:  -layout.padding.inline.l,
    marginBottom: -layout.padding.inline.m,
    '&.commentsExpanded': {
      borderBottom: [1, 'solid', theme.colors.fg.light.dim],
    },
  },

  reactionButton: {
    borderRight: [1, 'solid', theme.colors.fg.light.dim],
  },

  commentButton: {
    position: 'relative',
    ...presets.overlayBefore(),
    '&:hover::before': {
      background: theme.bg.hover,
    },
  },
}))