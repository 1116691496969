// @index(d:!,!types): export { default as ${variable} } from ${relpath}
export { default as Avatar } from './Avatar'
export { default as ImageView } from './ImageView'
export { default as MediaUploader } from './MediaUploader'
export { default as VideoView } from './VideoView'
export { default as processMediaUpload } from './processMediaUpload'
// /index

// @index(d:!,!types): export type { Props as ${variable}Props } from ${relpath}
export type { Props as AvatarProps } from './Avatar'
export type { Props as ImageViewProps } from './ImageView'
export type { Props as MediaUploaderProps } from './MediaUploader'
export type { Props as VideoViewProps } from './VideoView'
// /index