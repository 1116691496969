import React from 'react'
import { memo } from '~/ui/component'
import { Center, Label, LabelProps, SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'children'> {
  icon?:        SVGName
  value?:       string | number | boolean | null
  hideOnEmpty?: boolean

  children?:    React.ReactNode

  size?:  Size
  depth?: number
}

const Badge = memo('Badge', (props: Props) => {

  const {
    icon,
    value,
    hideOnEmpty = true,
    classNames,
    color,
    children,
    depth = 2,
    small,
    tiny,
    size = tiny ? badgeSize.tiny : small ? badgeSize.small : badgeSize.normal,
    ...rest
  } = props

  const isEmpty = React.useMemo(() => {
    if (value === undefined) {
      return children == null && icon == null
    } else {
      return !value
    }
  }, [children, icon, value])

  const {colors} = useStyling()

  const backgroundColor = color ?? colors.semantic.secondary
  const borderColor     = colors.contrast(backgroundColor)

  const style: React.CSSProperties = {
    backgroundColor: backgroundColor.string(),
    borderColor:     borderColor.string(),
    borderRadius:    size == null ? 'undefined' : size.height / 2,
    ...size,
  }

  const $ = useStyles(depth)

  function render() {
    if (hideOnEmpty && isEmpty) { return null }

    return (
      <ThemeProvider contrast={backgroundColor}>
        <Center classNames={[$.badge, {small, tiny}, classNames]} style={style}>
          {icon != null && (
            <SVG
              name={icon}
              size={tiny ? layout.icon.xxs : small ? layout.icon.xs : layout.icon.s}
              classNames='--no-override-fg'
            />
          )}
          {value !== true && (
            <Label caption bold classNames='--no-override-fg' tiny={small || tiny} {...rest}>
              {value}
            </Label>
          )}
          {children}
        </Center>
      </ThemeProvider>
    )
  }

  return render()

})

export default Badge

export const badgeSize = {
  normal: {
    height:   24,
    minWidth: 24,
  },
  small: {
    height:   20,
    minWidth: 20,
  },
  tiny: {
    height:   14,
    minWidth: 14,
  },
}

const useStyles = createUseStyles({
  badge: (depth: number) => ({
    border:       [2, 'solid'],
    boxShadow:    depth === 0 ? undefined : shadows.depth(depth),

    '&:not(.small):not(.tiny)': {
      padding:      [layout.padding.inline.xs, layout.padding.inline.s],
      height:       badgeSize.normal.height,
      borderRadius: badgeSize.normal.height / 2,
      minWidth:     badgeSize.normal.minWidth,
    },

    '&.small': {
      padding:      layout.padding.inline.xs,
      height:       badgeSize.small.height,
      borderRadius: badgeSize.small.height / 2,
      minWidth:     badgeSize.small.minWidth,
    },

    '&.tiny': {
      padding:      layout.padding.inline.xs,
      height:       badgeSize.tiny.height,
      borderRadius: badgeSize.tiny.height / 2,
      minWidth:     badgeSize.tiny.minWidth,
      borderWidth:  1,
    },

  }),
})