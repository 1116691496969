import { isFunction } from 'lodash'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import socket from 'socket.io-react'
import gcAPI from '~/apis/gc'
import config from '~/config'
import { ClientApp } from '~/models'
import { allStores, register, supportsPersistence } from './support'

export class AppStore {

  constructor() {
    if (CLIENT) {
      window.addEventListener('online', action(() => { this.online = true }))
      window.addEventListener('offline', action(() => { this.online = false }))
    }

    makeObservable(this)
  }

  @observable
  public readyState: AppReadyState = 'initializing'

  @computed
  public get ready() {
    return this.readyState === 'ready'
  }

  @observable
  public online: boolean = CLIENT ? window.navigator.onLine : false

  @observable
  public app: ClientApp | null = null

  //------
  // Config

  @observable
  public serverVersion: string | null = null

  @observable
  public serverEnvironment: string | null = null

  @observable
  public configDialogOpen: boolean = false

  @action
  public openConfigDialog() {
    this.configDialogOpen = true
  }

  @action
  public closeConfigDialog() {
    this.configDialogOpen = false
  }

  //------
  // Initialization

  @action
  public init() {
    socket.addEventListener('config', action((config: {environment: string, version: string}) => {
      this.serverEnvironment = config.environment
      this.serverVersion = config.version
    }))

    return restorePersistedState()
      .then(this.fetchClientApp.bind(this))
      .then(this.onLoadComplete)
  }

  @action
  private async fetchClientApp() {
    if (config.domain == null) { return }

    const params = {domain: config.domain}
    return gcAPI().get('client-app', {params}).then(action(response => {
      if (response.status !== 200) { return }
      this.app = ClientApp.deserialize(response.data.clientApp)
    }))
  }

  @action
  private onLoadComplete = async () => {
    reaction(() => this.persistedState, state => this.save(state))
    this.save(this.persistedState)

    this.initStores()
  }

  @action
  private initStores() {
    initStores().then(action(result => {
      if (result) {
        this.readyState = 'ready'
      } else {
        this.readyState = 'error'
      }
    }))
  }

  @action
  public deinit() {
    socket.disconnect()
    return deinitStores().then(action(() => {
      this.readyState = 'initializing'
    }))
  }

  //------
  // Persistence

  private save(state: AnyObject) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  }

  @computed
  private get persistedState(): AnyObject {
    const state: AnyObject = {}

    for (const store of allStores()) {
      if (store instanceof AppStore) { continue }
      if (!supportsPersistence(store)) { continue }

      state[store.persistenceKey] = store.persistedState
    }

    return state
  }

}

async function restorePersistedState() {
  try {
    const raw    = localStorage.getItem(STORAGE_KEY)
    const state = raw == null ? {} : JSON.parse(raw)

    for (const store of allStores()) {
      if (store instanceof AppStore) { continue }
      if (!supportsPersistence(store)) { continue }

      const storeState = state[store.persistenceKey]
      if (storeState == null) { continue }

      await store.rehydrate(storeState)
    }
  } catch (error: any) {
    console.warn("Error while loading persisted state: ", error.stack)
  }
}

async function initStores() {
  for (const store of allStores()) {
    if (store === appStore) { continue }
    if (isFunction((store as any).init)) {
      const result = await (store as any).init()
      if (result === false) {
        return false
      }
    }
  }

  return true
}

async function deinitStores() {
  for (const store of allStores()) {
    if (store === appStore) { continue }
    if (isFunction((store as any).deinit)) {
      const result = await (store as any).deinit()
      if (result === false) {
        return false
      }
    }
  }

  return true
}

export type AppReadyState = 'initializing' | 'ready' | 'error'

const STORAGE_KEY = 'persist'

const appStore = register(new AppStore())
export default appStore